<template>
  <div class="sidebar-wrapper d-flex justify-content-between flex-column flex-grow-1">
    <div class="p-2">
      <div class="mt-2">
        <h5 class="app-label section-label mb-1">
          <span class="align-middle">{{ $t("Calendars") }}</span>
        </h5>
        <b-form-checkbox v-model="checkAll" class="mb-1">
          {{ $t("View All") }}
        </b-form-checkbox>
        <b-form-group>
          <b-form-checkbox-group v-model="selectedCalendars" name="event-filter" stacked>
            <b-form-checkbox
              v-for="item in calendarOptions"
              :key="item.label"
              name="event-filter"
              :value="item.param"
              class="mb-1"
              :class="`custom-control-${item.color}`"
            >
              {{ item.label }}
            </b-form-checkbox>
          </b-form-checkbox-group>
        </b-form-group>
        <b-form-group label="Etkinlik Tipine Göre">
          <b-form-checkbox-group name="event-filter-type" stacked v-model="selectedCalendarType" @change="fetchEvents">
            <b-form-checkbox
              v-for="item in $store.state.calendar.calendarTypes"
              :key="item.id"
              name="event-filter-type"
              :value="item.id"
              class="mb-1"
              :class="`custom-control-${item.color}`"
            >
              {{ $t(item.text) }}
            </b-form-checkbox>
          </b-form-checkbox-group>
        </b-form-group>
      </div>
    </div>
    <b-img :src="require('@/assets/images/pages/calendar-illustration.png')" />
  </div>
</template>

<script>
  import { BButton, BFormGroup, BFormCheckboxGroup, BFormCheckbox, BImg } from "bootstrap-vue"
  import Ripple from "vue-ripple-directive"
  import useCalendarSidebar from "./useCalendarSidebar"

  export default {
    directives: {
      Ripple,
    },
    components: {
      BButton,
      BImg,
      BFormCheckbox,
      BFormGroup,
      BFormCheckboxGroup,
    },
    props: {
      fetchEvents: {
        type: Function,
        require: true,
      },
      isEventHandlerSidebarActive: {
        type: Boolean,
        require: true,
      },
    },
    created() {},
    setup(props, { root }) {
      const { calendarOptions, selectedCalendars, selectedCalendarType, checkAll } = useCalendarSidebar()

      return {
        calendarOptions,
        selectedCalendarType,
        selectedCalendars,
        checkAll,
      }
    },
  }
</script>

<style></style>
