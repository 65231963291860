export default [
  {
    path: "/analysis-file-manage",
    name: "analysis-file-manage",
    component: () => import("@/views/analysis-file/index.vue"),
    meta: {
      resource: "ACL",
      action: "read",
    },
  },
]
