<template>
  <b-overlay variant="white" :show="showLoading" spinner-variant="primary" blur="0" opacity=".75" rounded="sm">
    <b-row v-if="staff">
      <b-col xl="12" class="player-top-bar-info">
        <b-card class="w-100">
          <div class="card-header p-0 m-0">
            <div>
              <div
                class="d-flex flex-sm-row flex-column justify-content-start justify-content-lg-center align-items-lg-center align-items-start"
              >
                <div class="p-0">
                  <b-avatar
                    size="120"
                    :src="staff != undefined ? staff.avatar : null"
                    :text="avatarText(staff.firstName)"
                    :variant="`light-primary`"
                    rounded
                  />
                </div>
                <div>
                  <b-link class="mt-1 ml-1 d-block h4">{{ staff.firstName + ` ` + staff.lastName }} </b-link>
                </div>
                <div class="mt-1 ml-4">
                  <div class="d-flex justify-content-start">
                    <p class="mr-1">{{ $t("Job") }} :</p>
                    <span class="font-weight-bold">
                      <span class="badge badge-primary mr-1 col">{{ staff.job ? staff.job.name : "" }}</span></span
                    >
                  </div>
                </div>
                <div class="mt-1 ml-4">
                  <div class="d-flex justify-content-start">
                    <p class="mr-1">{{ $t("Active Club") }} :</p>
                    <b-avatar
                      style="margin-top: -4px"
                      size="25"
                      :src="staff != undefined ? staff.team[0].club.logo : null"
                      :text="avatarText(staff.team[0].club.name)"
                      :variant="`white`"
                      rounded
                    />
                    <span class="font-weight-bold"> {{ staff.team[0].club.name }}</span>
                  </div>
                </div>
                <div class="mt-1 ml-4">
                  <div class="d-flex justify-content-start">
                    <p class="mr-1">{{ $t("Active Team") }} :</p>
                    <span class="font-weight-bold"> {{ staff.team[0].name }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <b-button variant="primary" :to="{ name: 'edit-staff', params: { staffID: staffId } }">
                <i class="code-toggler feather icon-edit cursor-pointer"></i>
              </b-button>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>

    <b-row v-if="staff">
      <b-col lg="4">
        <b-card>
          <h5>{{ $t("About") }}</h5>
          <div class="d-flex justify-content-start">
            <p>{{ $t("Nation") }} :</p>
            <span class="font-weight-bold"> {{ staff.nation }}</span>
          </div>
          <div class="d-flex justify-content-start">
            <p>{{ $t("Nation Id") }} :</p>
            <span class="font-weight-bold"> {{ staff.nationId }}</span>
          </div>
          <div class="d-flex justify-content-start">
            <p>{{ $t("Department") }} :</p>
            <span class="font-weight-bold"> {{ staff.department ? staff.department.name : "" }}</span>
          </div>
          <div class="d-flex justify-content-start">
            <p>{{ $t("Birth Date") }} :</p>
            <span class="font-weight-bold"> {{ staff.birthDate | nativeDate }}</span>
          </div>
          <div class="d-flex justify-content-start">
            <p>{{ $t("Email") }} :</p>
            <span class="font-weight-bold"> {{ staff.email }}</span>
          </div>
          <div class="d-flex justify-content-start">
            <p>{{ $t("Phone") }} :</p>
            <span class="font-weight-bold"> {{ staff.phoneNumber }}</span>
          </div>
        </b-card>
      </b-col>
      <b-col lg="8">
        <b-card>
          <h5>{{ $t("Jobs") }}</h5>
          <div v-if="staff.careers && staff.careers.length > 0">
            <b-table
              ref="selectableTable"
              selectable
              sticky-header="500px"
              :items="staff.careers"
              :fields="fields"
              class="mb-0"
            >
              <template #cell(team)="data">
                {{ data.item.team.name }}
              </template>
              <template #cell(season)="data">
                {{ data.item.season.name }}
              </template>
              <template #cell(department)="data">
                {{ data.item.department.name }}
              </template>
              <template #cell(job)="data">
                {{ data.item.job.name }}
              </template>
              <template #cell(jobEndDate)="data">
                {{ data.item.jobEndDate | nativeDate }}
              </template>
            </b-table>
          </div>
          <div v-else>
            {{ $t("Careers Not Found") }}
          </div>
        </b-card>
      </b-col>
      <b-col lg="4">
        <b-card>
          <h5>{{ $t("Contact") }}</h5>
          <div v-if="staff.contacts && staff.contacts.length > 0">
            <b-table
              ref="selectableTable"
              selectable
              sticky-header="500px"
              :items="staff.contacts"
              :fields="contactFields"
              class="mb-0"
            >
              <template #cell(team)="data">
                {{ data.item.team.name }}
              </template>
              <template #cell(season)="data">
                {{ data.item.season.name }}
              </template>
              <template #cell(department)="data">
                {{ data.item.department.name }}
              </template>
              <template #cell(job)="data">
                {{ data.item.job.name }}
              </template>
              <template #cell(jobEndDate)="data">
                {{ data.item.jobEndDate | nativeDate }}
              </template>
            </b-table>
          </div>
          <div v-else>
            {{ $t("Contact Not Found") }}
          </div>
        </b-card>
      </b-col>
    </b-row>
  </b-overlay>
</template>

<script>
  import { avatarText } from "@core/utils/filter"
  import { ref, onUnmounted, onMounted, computed } from "@vue/composition-api"
  import { useRouter } from "@core/utils/utils"
  import staffService from "@/services/staffService"
  import i18n from "@/libs/i18n"
  import Summary from "./Summary.vue"
  export default {
    components: {
      Summary,
    },
    async beforeMount() {},
    setup(props, { root }) {
      const { router, route } = useRouter()
      const staffSer = new staffService()
      const showLoading = ref(false)
      const userData = ref(null)

      onMounted(async () => {
        await getStaff()
      })

      const contactFields = computed({
        get: () => [
          {
            key: "address",
            label: i18n.t("Address"),
            sortable: false,
            thStyle: { width: "200px", "min-width": "200px", "max-width": "220px" },
          },
          {
            key: "phone",
            label: i18n.t("Phone"),
            sortable: false,
            thStyle: { width: "200px", "min-width": "200px", "max-width": "220px" },
          },
        ],
      })
      const fields = computed({
        get: () => [
          {
            key: "team",
            label: i18n.t("Team"),
            sortable: false,
            thStyle: { width: "200px", "min-width": "200px", "max-width": "220px" },
          },
          {
            key: "season",
            label: i18n.t("Season"),
            sortable: false,
            thStyle: { width: "200px", "min-width": "200px", "max-width": "220px" },
          },
          {
            key: "department",
            label: i18n.t("Department"),
            sortable: false,
            thStyle: { width: "200px", "min-width": "200px", "max-width": "220px" },
          },
          {
            key: "job",
            label: i18n.t("Job"),
            sortable: false,
            thStyle: { width: "200px", "min-width": "200px", "max-width": "220px" },
          },
          {
            key: "jobEndDate",
            label: i18n.t("Job End Date"),
            sortable: false,
            thStyle: { width: "200px", "min-width": "200px", "max-width": "220px" },
          },
        ],
      })

      const staffId = ref(Number(router.currentRoute.params.staffID))
      const staff = ref(null)

      const getStaff = () => {
        staffSer.get(staffId.value).then((res) => {
          staff.value = res.data
        })
      }

      return {
        avatarText,
        showLoading,
        userData,
        staffId,
        history,
        fields,
        contactFields,
        staff,
      }
    },
  }
</script>

<style>
  .tabs > [class="col-md-12 col-12 tab-title-for-player"] {
    padding-left: 0;
  }

  .player-top-bar-info,
  .player-top-bar-phy {
    .card-body {
      padding-bottom: 0.3rem;
    }
  }
</style>

<style scoped lang="scss">
  @media (min-width: 700px) {
    .topbar > .col-md-4 {
      border-left: 1px solid #ddd;

      &:first-child {
        border-left: 0;
      }
    }

    .p-0 > div {
      padding: 0;
    }
  }
</style>

<style lang="scss">
  @import "@core/scss/vue/libs/vue-good-table.scss";
</style>
