<template>
  <div>
    <comment-sidebar :is-sidebar-active.sync="isSidebarActive" :player="player" :form.sync="form" />

    <div class="my-2">
      <b-row>
        <b-col lg="12">
          <div v-if="player.reviews && player.reviews.length > 0">
            <b-table
              ref="selectableTable"
              selectable
              sticky-header="400px"
              :items="player.reviews"
              :fields="fields"
              class="mb-0"
            >
              <template #cell(id)="data">
                {{ data.item.id }}
              </template>
              <template #cell(created_at)="data">
                {{ data.item.created_at | longDate }}
              </template>
              <template #cell(comment)="data">
                {{ data.item.comment }}
              </template>

              <!-- Column: Actions -->
              <template #cell(actions)="data">
                <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
                  <template #button-content>
                    <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
                  </template>
                  <b-dropdown-item @click="goSidebar(data.item)">
                    <feather-icon icon="EditIcon" />
                    <span class="align-middle ml-50">{{ $t("Edit") }}</span>
                  </b-dropdown-item>
                </b-dropdown>
              </template>
            </b-table>
          </div>

          <div v-else>
            <span> {{ $t("No records found") }}</span>
          </div>
        </b-col>
      </b-row>
    </div>

    <b-button variant="primary" type="button" class="mb-1 mb-sm-0 mr-0 mr-sm-1" @click="goSidebar({})">{{
      $t("Add")
    }}</b-button>
  </div>
</template>

<script>
  import { ref, computed } from "@vue/composition-api"
  import { useRouter } from "@core/utils/utils"
  import http from "@/libs/axios"
  import i18n from "@/libs/i18n"
  import CommentSidebar from "@/views/scout/player/edit/components/comment-sidebar.vue"

  export default {
    components: {
      CommentSidebar,
    },
    props: {
      player: {
        default: null,
      },
    },
    setup(props, { root }) {
      const { router, route } = useRouter()
      const isSidebarActive = ref(false)
      const form = ref({
        id: null,
        comment: null,
        player: Number(router.currentRoute.params.playerId),
      })

      const playerId = ref(Number(router.currentRoute.params.playerId))
      const seasonId = ref(Number(router.currentRoute.params.seasonId))
      const goSidebar = (data) => {
        form.value = data
        isSidebarActive.value = true
      }

      const fields = computed({
        get: () => [
          {
            key: "id",
            label: i18n.t("Id"),
            sortable: false,
            thStyle: { width: "50px", "min-width": "50px", "max-width": "50px" },
          },
          {
            key: "created_at",
            label: i18n.t("Date"),
            sortable: false,
            thStyle: { width: "200px", "min-width": "200px", "max-width": "220px" },
          },
          {
            key: "comment",
            label: i18n.t("Comment"),
            sortable: false,
            thStyle: { width: "200px", "min-width": "200px", "max-width": "220px" },
          },
          {
            key: "actions",
            label: i18n.t("Actions"),
            sortable: false,
            thStyle: { width: "200px", "min-width": "200px", "max-width": "220px" },
          },
        ],
      })

      return {
        goSidebar,
        fields,
        playerId,
        seasonId,
        isSidebarActive,
        form,
      }
    },
  }
</script>

<style lang="scss">
  @import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
