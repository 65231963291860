<template>
  <div>
    <div v-if="$store.getters['app/currentBreakPoint'] !== 'xs' && $store.getters['app/currentBreakPoint'] !== 'sm'">
      <horizontal-nav-menu-items :items="teamMenuItems" :dashboard-items="dashboardMenuItems" />
    </div>
    <div v-else class="w-100">
      <!-- <b-select :options="teamMenuItems" text-field="title" value-field="route"></b-select> -->
      <!-- {{ !router.currentRoute.params.seasonId && "Takım Seçiniz" }} -->
      <v-select
        v-if="routerParams.seasonId"
        class="style-chooser"
        v-model="selected"
        :autocomplete="`on`"
        :options="teamMenuItems"
        :reduce="(option) => option.route"
        :getOptionLabel="(option) => $t(option.title).toString().substr(0, 7)"
        :clearable="false"
      >
      </v-select>
    </div>
  </div>
</template>

<script>
  import navMenuItems from "@/navigation/horizontal"
  import HorizontalNavMenuItems from "./components/horizontal-nav-menu-items/HorizontalNavMenuItems.vue"
  import { ref, watch, computed } from "@vue/composition-api"
  import vSelect from "vue-select"
  import router from "@/router"
  import store from "@/store"
  import i18n from "@/libs/i18n"
  export default {
    components: {
      HorizontalNavMenuItems,
      vSelect,
    },
    setup() {
      const dashboardMenuItems = computed({
        get: () => [
          {
            title: i18n.t("Matches"),
            route: "dashboard-antrenor-competitions",
            icon: "DribbbleIcon",
            action: "read",
            resource: "ACL",
          },
          {
            title: i18n.t("Calendar"),
            route: "dashboard-antrenor-calendar",
            icon: "CalendarIcon",
            action: "read",
            resource: "ACL",
          },
        ],
      })

      const teamMenuItems = computed({
        get: () => [
          {
            title: i18n.t("Players"),
            route: "team-players",
            icon: "UsersIcon",
            action: "read",
            resource: "Player",
          },
          {
            header: i18n.t("Training"),
            title: i18n.t("Training"),
            icon: "RadioIcon",
            route: "team-trainings",
            action: "read",
            resource: "Training",
            children: [
              {
                title: "Trainings",
                route: "team-trainings",
                icon: "RadioIcon",
                action: "read",
                resource: "Training",
              },
              {
                title: "Training Library",
                route: "team-training-library",
                icon: "RadioIcon",
                action: "read",
                resource: "Training",
              },
            ],
          },
          {
            header: i18n.t("Matches"),
            title: i18n.t("Matches"),
            route: "team-competitions",
            icon: "DribbbleIcon",
            action: "read",
            resource: "Competition",
            children: [
              {
                title: i18n.t("Competitions History"),
                route: "team-competitions",
                icon: "DribbbleIcon",
                action: "read",
                resource: "Competition",
              },
              {
                title: i18n.t("Fixture Schedule"),
                route: "team-competitions-chart",
                icon: "DribbbleIcon",
                action: "read",
                resource: "Competition",
              },
            ],
          },
          {
            header: i18n.t("Performance"),
            title: i18n.t("Performance"),
            icon: "BatteryChargingIcon",
            route: "team-perfs-evulate",
            action: "read",
            resource: "Performance",
            children: [
              {
                title: "Overall performance",
                route: "team-perfs-evulate",
                icon: "BatteryChargingIcon",
                action: "read",
                resource: "Performance",
              },
              {
                title: "Report log",
                route: "team-perfs-log",
                icon: "BatteryChargingIcon",
                action: "read",
                resource: "Performance",
              },
            ],
          },
          {
            header: i18n.t("Physical capacities"),
            title: i18n.t("Physical capacities"),
            icon: "BarChartIcon",
            route: "team-latest-test",
            action: "read",
            resource: "Test",
            children: [
              {
                title: "General Report",
                route: "team-latest-test",
                icon: "BarChartIcon",
                action: "read",
                resource: "Test",
              },
              {
                title: "Evaluation log",
                route: "team-tests",
                icon: "BarChartIcon",
                action: "read",
                resource: "Test",
              },
            ],
          },
        ],
      })

      const selected = ref({
        title: "Dashboard",
        route: "team-players",
        icon: "UsersIcon",
      })
      const season = store.getters["app/season"]
      watch(selected, (val) => {
        router.push({
          name: val,
          params: {
            seasonId: router.currentRoute.params.seasonId,
            id: router.currentRoute.params.id,
          },
        })
        location.reload()
      })

      return {
        selected,
        navMenuItems,
        teamMenuItems,
        dashboardMenuItems,
      }
    },
  }
</script>

<style lang="scss">
  @import "~@core/scss/base/core/menu/menu-types/horizontal-menu.scss";
  .style-chooser .vs__search::placeholder,
  .style-chooser .vs__dropdown-toggle {
    max-width: 90px;
    font-size: 10px;
    height: 40px !important;
    max-height: 40px !important;
  }
</style>
