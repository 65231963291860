import { onMounted, ref, watch, computed } from "@vue/composition-api"
import { useRouter } from "@core/utils/utils"
// Notification
import { useToast } from "vue-toastification/composition"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
import store from "@/store"

export default function useTeamList() {
  onMounted(() => {
    store
      .dispatch("app-team/fetchTeamPlayers", {
        teamId: router.currentRoute.params.id,
        seasonId: router.currentRoute.params.seasonId,
      })
      .catch((error) => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching team players list",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        })
      })
  })

  // Use toast
  const toast = useToast()

  const { router } = useRouter()

  const refListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: "id", label: "#", sortable: true },
    { key: "avatar", sortable: true },
    { key: "firstName", sortable: true },
    { key: "lastName", sortable: true },
    { key: "age", sortable: true },
    { key: "height", sortable: true },
    { key: "position", sortable: true },
    { key: "actions" },
  ]

  const perPage = ref(localStorage.getItem("perPage") || 10)
  const totalItems = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref("")
  const sortBy = ref("id")
  const isSortDirDesc = ref(true)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalItems.value,
    }
  })

  const refFetchData = () => {
    refListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, statusFilter], () => {
    refFetchData()
  })

  watch(perPage, (perPage) => {
    localStorage.setItem("perPage", perPage)
  })

  const members = computed(() => store.getters["app-team/players"])

  const players = computed(() => store.getters["app-team/playersByBranc"])

  return {
    members,
    players,
    tableColumns,
    perPage,
    currentPage,
    totalItems,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refListTable,
    statusFilter,
    refFetchData,
  }
}
