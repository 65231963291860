import axios from "@axios"

export default {
  namespaced: true,
  state: {
    items: [],
    loading: false,
  },
  getters: {
    items: (state) => state.items,
  },
  mutations: {
    // TEAMS
    ADD_DATA(state, item) {
      state.items.unshift(item)
    },
    SET_DATA(state, itemData) {
      state.items = itemData
    },
    UPDATE_DATA(state, item) {
      const itemIndex = state.items.findIndex((i) => i.id === item.id)
      Object.assign(state.items[itemIndex], item)
    },
    REMOVE_DATA(state, itemId) {
      const ItemIndex = state.items.findIndex((i) => i.id === itemId)
      state.items.splice(ItemIndex, 1)
    },
    CLEAR_STATE(state) {
      state.items = []
    },
    SET_LOADING(state, value) {
      state.loading = value
    },
  },
  actions: {
    fetch({ commit }, { id, date }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`test/team/${id}/date`, {
            date,
          })
          .then((response) => {
            commit("SET_DATA", response.data)
            resolve(response)
          })
          .catch((error) => reject(error))
      })
    },
    delete({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`test/${id}`)
          .then((response) => {
            commit("REMOVE_DATA", id)
            resolve("")
          })
          .catch((error) => reject(error))
      })
    },
    update({ commit }, { id, object }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`test/${id}`, object)
          .then((response) => {
            commit("UPDATE_DATA", response.data)
            resolve(response)
          })
          .catch((error) => reject(error))
      })
    },
    add({ commit }, { season, object }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/season/${season}/test`, object)
          .then((response) => {
            resolve(response)
            commit("ADD_DATA", response.data)
          })
          .catch((error) => reject(error))
      })
    },
  },
}
