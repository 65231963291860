import http from "@/libs/axios"
import toastService from "./toastService"
import i18n from "@/libs/i18n"

export default class testService {
  constructor() {}

  async groupByDate(seasonId, teamId, params = null) {
    return http
      .get(`/season/${seasonId}/team/${teamId}/test/group-by-date`, { params })
      .then((response) => new Promise((resolve) => resolve(response.data)))
      .catch((error) => new Promise((reject) => reject(error)))
  }

  async updateDate(seasonId, teamId, date, data) {
    return new Promise((resolve, reject) => {
      http
        .put(`/season/${seasonId}/team/${teamId}/test/date/${date}`, data)
        .then((response) => {
          toastService(i18n.t("Successfully Updated"), "PlusIcon", "success")
          return resolve(response)
        })
        .catch((error) => reject(error))
    })
  }
}
