<template>
  <!-- TODO : TRANSLATE -->
  <div>
    <div class="alert alert-primary p-2" v-if="getMainSubs.length == 0">
      <p>{{ $t("Not found any player") }}</p>
    </div>
    <div class="table-responsive" v-if="getMainSubs.length > 0">
      <table class="table table-bordered" v-if="getMainSubs">
        <thead>
          <tr>
            <td>#</td>
            <td>{{ $t("Player") }}</td>
            <!--<td>
              <div class="d-flex flex-column font-weight-bold">
                <span>{{ $t('Serp')+" ("+$t('Player')+")" }}</span>
              </div>
            </td>-->
            <td v-b-tooltip.hover :title="$t('Yellow Card')">
              <img :src="require('@/assets/images/icons/yellow-card.png')" height="32" alt />
            </td>
            <td v-b-tooltip.hover :title="$t('Red Card from 2nd Yellow')">
              <img :src="require('@/assets/images/icons/yellow-red-card.png')" height="32" alt />
            </td>
            <td v-b-tooltip.hover :title="$t('Red Card')">
              <img :src="require('@/assets/images/icons/red-card.png')" height="32" alt />
            </td>
            <td v-b-tooltip.hover :title="$t('Assist')">
              <img :src="require('@/assets/images/icons/foot.png')" height="32" alt />
            </td>
            <td v-b-tooltip.hover :title="$t('Goal!')">
              <img :src="require('@/assets/images/icons/ball.svg')" height="32" alt />
            </td>
            <td
              v-if="!userRoles.includes('coach') && !userRoles.includes('gk_coach')"
              class="text-center"
              v-b-tooltip.hover
              :title="$t('Coach Review')"
            >
              <span style="display: inlice-block"
                ><span style="display: table-caption">{{ $t("Coach Review Short") }}</span></span
              >
            </td>
            <td
              v-if="!userRoles.includes('assistant_coach') && !userRoles.includes('gk_coach')"
              class="text-center"
              v-b-tooltip.hover
              :title="$t('Assistant Coach Review')"
            >
              <span style="display: inlice-block"
                ><span style="display: table-caption">{{ $t("Assistant Coach Review Short") }}</span></span
              >
            </td>
            <td
              v-if="!userRoles.includes('conditioner') && !userRoles.includes('gk_coach')"
              class="text-center"
              v-b-tooltip.hover
              :title="$t('Conditioner Coach Review')"
            >
              <span style="display: inline-block"
                ><span style="display: table-caption">{{ $t("Physical Score") }}</span></span
              >
            </td>
            <td
              v-if="userRoles.includes('coach') || userRoles.includes('gk_coach')"
              class="text-center"
              v-b-tooltip.hover
              :title="$t('Coach Review')"
            >
              <span
                ><span>{{ $t("Coach Review Short") }}</span></span
              >
            </td>
            <td
              v-if="userRoles.includes('assistant_coach') || userRoles.includes('gk_coach')"
              class="text-center"
              v-b-tooltip.hover
              :title="$t('Assistant Coach Review')"
            >
              <span
                ><span>{{ $t("Assistant Coach Review Short") }}</span></span
              >
            </td>
            <td
              v-if="userRoles.includes('conditioner') || userRoles.includes('gk_coach')"
              class="text-center"
              v-b-tooltip.hover
              :title="$t('Conditioner Coach Review')"
            >
              <span
                ><span>{{ $t("Physical Score") }}</span></span
              >
            </td>
            <td v-b-tooltip.hover :title="$t('Comment')" v-if="teamItem == 'awayTeam'">
              <feather-icon icon="MessageSquareIcon" size="32"></feather-icon>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(pl, index) in getMainSubs" :key="index">
            <td>
              {{ pl.id }}
            </td>
            <td>
              <div class="d-flex justify-content-start">
                <div>
                  <b-avatar :text="avatarText(pl.name)" :src="pl.avatar ? pl.avatar : null"></b-avatar>
                </div>
                <div class="ml-1">
                  {{ pl.name }}
                  <br />
                  {{ $t(pl.position.name) }}
                </div>
              </div>
            </td>
            <!-- <td>
              <div class="d-flex align-items-center justify-content-center">
                <input
                  :value="pl.playerReview"
                  disabled
                  type="number"
                  class="form-control w-75 text-center"
                />
              </div>
            </td> -->
            <td class>
              <span class="badge badge-warning" v-b-tooltip.hover :title="`Sarı Kart`" v-if="pl.stats.yellowCard">
                {{ pl.stats.yellowCard }}
              </span>
            </td>
            <td class>
              <span
                class="badge badge-warning"
                v-b-tooltip.hover
                :title="`Sarıdan Kırmızı`"
                v-if="pl.stats.yellowRedCard"
              >
                {{ pl.stats.yellowRedCard }}
              </span>
            </td>
            <td class>
              <span class="badge badge-warning" v-b-tooltip.hover :title="`Kırmızı Kart`" v-if="pl.stats.redCard">
                {{ pl.stats.redCard }}
              </span>
            </td>
            <td class>
              <div class="d-flex justify-content-between">
                <div style="align-self: center">
                  <span
                    class="badge badge-primary"
                    v-b-tooltip.hover
                    :title="`${asist.minute}. dk`"
                    v-for="(asist, plgIndex) in getAsists(pl)"
                    :key="plgIndex"
                    >{{ asist.minute }}</span
                  >
                </div>
              </div>
            </td>
            <td class>
              <div class="d-flex justify-content-between">
                <div style="align-self: center">
                  <span
                    class="badge badge-primary ml-1"
                    v-b-tooltip.hover
                    :title="`${playerGoals.dk}. dk (${playerGoals.type})`"
                    v-for="(playerGoals, plgIndex) in pl.stats.goals"
                    :key="plgIndex"
                    >{{ playerGoals.dk }}</span
                  >
                </div>
              </div>
            </td>
            <td v-if="!userRoles.includes('coach') && !userRoles.includes('gk_coach')">
              <span class="d-flex justify-content-center">{{ pl.coachReview }}</span>
            </td>
            <td v-if="!userRoles.includes('assistant_coach') && !userRoles.includes('gk_coach')">
              <span class="d-flex justify-content-center">{{ pl.assistantCoachReview }}</span>
            </td>
            <td v-if="!userRoles.includes('conditioner') && !userRoles.includes('gk_coach')">
              <span class="d-flex justify-content-center">{{ pl.athleticPerformanceCoachReview }}</span>
            </td>
            <td v-if="userRoles.includes('coach') || userRoles.includes('gk_coach')">
              <b-input-group>
                <b-form-rating
                  variant="warning"
                  :stars="getSetting('star')"
                  @change="helperFuncs.submitFormationCoach(pl, competition.homeTeam.team.id, pl.coachReview)"
                  v-model="pl.coachReview"
                ></b-form-rating>
              </b-input-group>
            </td>
            <td v-if="userRoles.includes('assistant_coach') || userRoles.includes('gk_coach')">
              <b-input-group>
                <b-form-rating
                  variant="warning"
                  :stars="getSetting('star')"
                  @change="
                    helperFuncs.submitFormationCoach(
                      pl,
                      competition.homeTeam.team.id,
                      null,
                      null,
                      pl.assistantCoachReview,
                    )
                  "
                  v-model="pl.assistantCoachReview"
                ></b-form-rating>
              </b-input-group>
            </td>
            <td v-if="userRoles.includes('conditioner') || userRoles.includes('gk_coach')">
              <b-input-group>
                <b-form-rating
                  variant="warning"
                  :stars="getSetting('star')"
                  @change="
                    helperFuncs.submitFormationCoach(
                      pl,
                      competition.homeTeam.team.id,
                      null,
                      null,
                      null,
                      pl.athleticPerformanceCoachReview,
                    )
                  "
                  v-model="pl.athleticPerformanceCoachReview"
                ></b-form-rating>
              </b-input-group>
            </td>
            <td class v-if="teamItem == 'awayTeam'">
              <b-form-input
                v-model="pl.coachComment"
                @change="
                  helperFuncs.submitFormationCoach(pl, competition.homeTeam.team.id, pl.coachReview, pl.coachComment)
                "
              />
            </td>
          </tr>
        </tbody>
        <!-- Readonly -->
        <tbody>
          <tr v-for="(pl, index) in readonlyPlayers" :key="index" class="table-active">
            <td>
              {{ pl.id }}
            </td>
            <td>
              <div class="d-flex justify-content-start">
                <div>
                  <b-avatar :text="avatarText(pl.name)" :src="pl.avatar ? pl.avatar : null"></b-avatar>
                </div>
                <div class="ml-1">
                  {{ pl.name }}
                  <br />
                  {{ $t(pl.position.name) }}
                </div>
              </div>
            </td>
            <!--<td>
              <div class="d-flex align-items-center justify-content-center">
                <input
                  :value="pl.playerReview"
                  disabled
                  type="number"
                  class="form-control w-75 text-center"
                />
              </div>
            </td>-->
            <td class>
              <span class="badge badge-warning" v-b-tooltip.hover :title="`Sarı Kart`" v-if="pl.stats.yellowCard">
                {{ pl.stats.yellowCard }}
              </span>
            </td>
            <td class>
              <span
                class="badge badge-warning"
                v-b-tooltip.hover
                :title="`Sarıdan Kırmızı`"
                v-if="pl.stats.yellowRedCard"
              >
                {{ pl.stats.yellowRedCard }}
              </span>
            </td>
            <td class>
              <span class="badge badge-warning" v-b-tooltip.hover :title="`Kırmızı Kart`" v-if="pl.stats.redCard">
                {{ pl.stats.redCard }}
              </span>
            </td>
            <td class>
              <div class="d-flex justify-content-between">
                <div style="align-self: center">
                  <span
                    class="badge badge-primary"
                    v-b-tooltip.hover
                    :title="`${asist.minute}. dk`"
                    v-for="(asist, plgIndex) in getAsists(pl)"
                    :key="plgIndex"
                    >{{ asist.minute }}</span
                  >
                </div>
              </div>
            </td>
            <td class>
              <div class="d-flex justify-content-between">
                <div style="align-self: center">
                  <span
                    class="badge badge-primary ml-1"
                    v-b-tooltip.hover
                    :title="`${playerGoals.dk}. dk (${playerGoals.type})`"
                    v-for="(playerGoals, plgIndex) in pl.stats.goals"
                    :key="plgIndex"
                    >{{ playerGoals.dk }}</span
                  >
                </div>
              </div>
            </td>
            <td v-if="!userRoles.includes('coach') && !userRoles.includes('gk_coach')">
              <span class="d-flex justify-content-center">{{ pl.coachReview }}</span>
            </td>
            <td v-if="!userRoles.includes('assistant_coach') && !userRoles.includes('gk_coach')">
              <span class="d-flex justify-content-center">{{ pl.assistantCoachReview }}</span>
            </td>
            <td v-if="!userRoles.includes('conditioner') && !userRoles.includes('gk_coach')">
              <span class="d-flex justify-content-center">{{ pl.athleticPerformanceCoachReview }}</span>
            </td>
            <td v-if="userRoles.includes('coach') || userRoles.includes('gk_coach')">
              <b-input-group>
                <b-form-rating
                  variant="warning"
                  :stars="getSetting('star')"
                  readonly
                  @change="helperFuncs.submitFormationCoach(pl, competition.homeTeam.team.id, pl.coachReview)"
                  v-model="pl.coachReview"
                ></b-form-rating>
              </b-input-group>
            </td>
            <td v-if="userRoles.includes('assistant_coach') || userRoles.includes('gk_coach')">
              <b-input-group>
                <b-form-rating
                  variant="warning"
                  :stars="getSetting('star')"
                  readonly
                  @change="
                    helperFuncs.submitFormationCoach(
                      pl,
                      competition.homeTeam.team.id,
                      null,
                      null,
                      pl.assistantCoachReview,
                    )
                  "
                  v-model="pl.assistantCoachReview"
                ></b-form-rating>
              </b-input-group>
            </td>
            <td v-if="userRoles.includes('conditioner') || userRoles.includes('gk_coach')">
              <b-input-group>
                <b-form-rating
                  variant="warning"
                  :stars="getSetting('star')"
                  readonly
                  @change="
                    helperFuncs.submitFormationCoach(
                      pl,
                      competition.homeTeam.team.id,
                      null,
                      null,
                      null,
                      pl.athleticPerformanceCoachReview,
                    )
                  "
                  v-model="pl.athleticPerformanceCoachReview"
                ></b-form-rating>
              </b-input-group>
            </td>
            <td class v-if="teamItem == 'awayTeam'">
              <b-form-input readonly v-model="pl.coachComment" />
            </td>
          </tr>
        </tbody>
        <!-- Yedek -->
        <tbody>
          <tr class="bg-light">
            <td colspan="100%" style="font-weight: bold; font-size: larger">{{ $t("Substitute Players") }}</td>
          </tr>
          <tr v-for="(pl, index) in yedek" :key="index">
            <td>
              {{ pl.id }}
            </td>
            <td>
              <div class="d-flex justify-content-start">
                <div>
                  <b-avatar :text="avatarText(pl.name)" :src="pl.avatar ? pl.avatar : null"></b-avatar>
                </div>
                <div class="ml-1">
                  {{ pl.name }}
                  <br />
                  {{ $t(pl.position.name) }}
                </div>
              </div>
            </td>
            <td class>
              <span class="badge badge-warning" v-b-tooltip.hover :title="`Sarı Kart`" v-if="pl.stats.yellowCard">
                {{ pl.stats.yellowCard }}
              </span>
            </td>
            <td class>
              <span
                class="badge badge-warning"
                v-b-tooltip.hover
                :title="`Sarıdan Kırmızı`"
                v-if="pl.stats.yellowRedCard"
              >
                {{ pl.stats.yellowRedCard }}
              </span>
            </td>
            <td class>
              <span class="badge badge-warning" v-b-tooltip.hover :title="`Kırmızı Kart`" v-if="pl.stats.redCard">
                {{ pl.stats.redCard }}
              </span>
            </td>
            <td class>
              <div class="d-flex justify-content-between">
                <div style="align-self: center">
                  <span
                    class="badge badge-primary"
                    v-b-tooltip.hover
                    :title="`${asist.minute}. dk`"
                    v-for="(asist, plgIndex) in getAsists(pl)"
                    :key="plgIndex"
                    >{{ asist.minute }}</span
                  >
                </div>
              </div>
            </td>
            <td class>
              <div class="d-flex justify-content-between">
                <div style="align-self: center">
                  <span
                    class="badge badge-primary ml-1"
                    v-b-tooltip.hover
                    :title="`${playerGoals.dk}. dk (${playerGoals.type})`"
                    v-for="(playerGoals, plgIndex) in pl.stats.goals"
                    :key="plgIndex"
                    >{{ playerGoals.dk }}</span
                  >
                </div>
              </div>
            </td>
            <td v-if="!userRoles.includes('coach') && !userRoles.includes('gk_coach')">
              <span class="d-flex justify-content-center">{{ pl.coachReview }}</span>
            </td>
            <td v-if="!userRoles.includes('assistant_coach') && !userRoles.includes('gk_coach')">
              <span class="d-flex justify-content-center">{{ pl.assistantCoachReview }}</span>
            </td>
            <td v-if="!userRoles.includes('conditioner') && !userRoles.includes('gk_coach')">
              <span class="d-flex justify-content-center">{{ pl.athleticPerformanceCoachReview }}</span>
            </td>
            <td v-if="userRoles.includes('coach') || userRoles.includes('gk_coach')">
              <b-input-group>
                <b-form-rating
                  variant="warning"
                  :stars="getSetting('star')"
                  @change="helperFuncs.submitFormationCoach(pl, competition.homeTeam.team.id, pl.coachReview)"
                  v-model="pl.coachReview"
                ></b-form-rating>
              </b-input-group>
            </td>
            <td v-if="userRoles.includes('assistant_coach') || userRoles.includes('gk_coach')">
              <b-input-group>
                <b-form-rating
                  variant="warning"
                  :stars="getSetting('star')"
                  @change="
                    helperFuncs.submitFormationCoach(
                      pl,
                      competition.homeTeam.team.id,
                      null,
                      null,
                      pl.assistantCoachReview,
                    )
                  "
                  v-model="pl.assistantCoachReview"
                ></b-form-rating>
              </b-input-group>
            </td>
            <td v-if="userRoles.includes('conditioner') || userRoles.includes('gk_coach')">
              <b-input-group>
                <b-form-rating
                  variant="warning"
                  :stars="getSetting('star')"
                  @change="
                    helperFuncs.submitFormationCoach(
                      pl,
                      competition.homeTeam.team.id,
                      null,
                      null,
                      null,
                      pl.athleticPerformanceCoachReview,
                    )
                  "
                  v-model="pl.athleticPerformanceCoachReview"
                ></b-form-rating>
              </b-input-group>
            </td>
            <td class v-if="teamItem == 'awayTeam'">
              <b-form-input
                v-model="pl.coachComment"
                @change="
                  helperFuncs.submitFormationCoach(pl, competition.homeTeam.team.id, pl.coachReview, pl.coachComment)
                "
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
  import { avatarText } from "@core/utils/filter"
  import { ref, onMounted } from "@vue/composition-api"
  import router from "@/router"
  import axios from "@axios"

  export default {
    components: {},
    name: "CompetitionAddCoachReview",
    props: {
      competition: null,
      helper: null,
      showSection: null,
      helperFuncs: null,
      teamItem: null,
      user: null,
    },
    data() {
      return {
        isLoading: false,
        readonlyPlayers: [],
      }
    },
    methods: {
      goSubmitEvent(object) {
        this.helper.submitEventForm(object)
      },
      getAsists(Player) {
        return Object.values(this.competition.competitionEvents).filter((event) => {
          if (event.competitionEventType === "GOAL" && event.subPlayer && event.subPlayer.id === Player.id) {
            return event.minute
          }
        })
      },
    },
    computed: {
      getMainSubs() {
        var teamPlayers = []
        this.readonlyPlayers = []
        this.yedek = []
        teamPlayers = this.competition[this.teamItem].team.teamPlayers

        return Object.values(teamPlayers).filter((pl) => {
          // gk: kaleci
          if (
            pl.isStarterPlayer !== undefined &&
            (Number(pl.isStarterPlayer) == 1 || (Number(pl.isStarterPlayer) == 0 && pl.stats.changedk))
          ) {
            if (this.user && this.user.role == "gk_coach") {
              if (pl.position.availablePositions.includes("GK")) {
                if (pl.isStarterPlayer != 0) return pl
                this.yedek.push(pl)
              } else {
                this.readonlyPlayers.push(pl)
              }
            } else {
              if (!pl.position.availablePositions.includes("GK")) {
                if (pl.isStarterPlayer != 0) return pl
                this.yedek.push(pl)
              } else {
                this.readonlyPlayers.push(pl)
              }
            }
          }
        })
      },
    },
    async beforeMount() {
      this.competition.formationTrigger = 0
    },
    created() {},
    setup(props, { root }) {
      const seasonId = ref(Number(router.currentRoute.params.seasonId))
      const teamId = ref(Number(router.currentRoute.params.id))
      const userRoles = ref([])

      onMounted(async () => {
        await getUserRoles()
      })

      const getUserRoles = async () => {
        await axios
          .get(`/user_manage/role/me/roles?team=${teamId.value}&season=${seasonId.value}&isActive=true`)
          .then((response) => {
            userRoles.value = response.data.map((i) => i.role.name)
            console.log(userRoles.value)
          })
      }

      return {
        avatarText,
        userRoles,
      }
    },
  }
</script>
<style>
  input.formationSelect {
    width: 30px;
    height: 30px;
  }

  .add-match-formation .nav-pills .nav-link.active {
    /* background: unset; */
  }
</style>
