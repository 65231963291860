var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"variant":"white","show":_vm.loadingPlayerCompetition,"spinner-variant":"primary","blur":"0","opacity":".75","rounded":"sm"}},[(_vm.player)?_c('b-row',[_c('b-col',{staticClass:"player-top-bar-info",attrs:{"xl":"12"}},[_c('b-card',{staticClass:"pb-0"},[_c('b-row',{staticClass:"topbar"},[_c('b-col',{attrs:{"lg":"4"}},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"p-0"},[_c('b-avatar',{attrs:{"size":"120","src":_vm.player != undefined ? _vm.player.avatar : null,"text":_vm.avatarText(_vm.player.name),"variant":"light-primary","rounded":""}})],1),_c('div',[_c('b-link',{staticClass:"mt-0 ml-1 d-block h4"},[_vm._v(_vm._s(_vm.player.name))]),_c('span',{staticClass:"mt-0 ml-1 d-block"},[_vm._v(_vm._s(_vm._f("nativeDate")(_vm.player.birthdate))+" ("+_vm._s(_vm.getAge(_vm.player.birthdate))+")")]),_c('div',{staticClass:"p-1 d-flex"},[_c('span',{staticClass:"badge badge-primary mr-1 col"},[_vm._v(_vm._s(_vm.player.position ? _vm.$t(_vm.player.position.name) : " - "))]),_c('span',{staticClass:"badge badge-dark col"},[_vm._v(_vm._s(_vm.player.foot === 1 ? _vm.$t("Right Foot") : _vm.player.foot === 0 ? _vm.$t("Left Foot") : _vm.$t("Both Feet"))+" ")])])],1)]),_c('div',{staticClass:"mt-1"},[_c('span',{staticClass:"badge badge-info px-1"},[_vm._v(_vm._s(_vm.player.team ? _vm.player.team.club.name : "")+" / "+_vm._s(_vm.player.team ? _vm.player.team.name : ""))])])]),_c('b-col',{attrs:{"lg":"4"}},[_c('b-card',{staticClass:"p-0"},[_c('physics-chart',{attrs:{"user":_vm.player,"tests":_vm.tests}})],1)],1),_c('b-col',{attrs:{"lg":"3"}},[_c('b-input-group',{staticClass:"pt-3 pr-4"},[_c('b-form-rating',{attrs:{"readonly":"","variant":"warning","stars":_vm.getSetting('star')},model:{value:(_vm.reviewAvg),callback:function ($$v) {_vm.reviewAvg=$$v},expression:"reviewAvg"}})],1),_c('div',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:(
                _vm.$t('scout_player_review_detail', {
                  rev: _vm.reviewCount,
                  pts: _vm.reviewAvg ? parseFloat(parseFloat(_vm.reviewAvg).toFixed(2)).toFixed(1) : '',
                })
              ),expression:"\n                $t('scout_player_review_detail', {\n                  rev: reviewCount,\n                  pts: reviewAvg ? parseFloat(parseFloat(reviewAvg).toFixed(2)).toFixed(1) : '',\n                })\n              ",modifiers:{"hover":true,"top":true}}],staticClass:"h5 p-1"},[_vm._v(" ("+_vm._s(_vm.reviewCount)+") "+_vm._s(_vm.$t("Rate"))+" * ")])],1),_c('b-col',{staticClass:"text-right",attrs:{"lg":"1"}},[_c('div',[(_vm.loading)?_c('div',[_c('b-button',{staticClass:"my-1",attrs:{"variant":"info"}},[_c('b-spinner',{attrs:{"small":"","label":"Small Spinner","type":"grow"}})],1)],1):_c('div',[(!_vm.watching)?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"my-1",attrs:{"title":_vm.$t('Add watch list'),"variant":"success"},on:{"click":function($event){return _vm.watchPlayer(1, _vm.playerId)}}},[_c('feather-icon',{attrs:{"icon":"UserPlusIcon","size":"16"}})],1):_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"my-1",attrs:{"title":_vm.$t('Remove from watch list'),"variant":"danger"},on:{"click":function($event){return _vm.watchPlayer(0, _vm.playerId)}}},[_c('feather-icon',{attrs:{"icon":"UserXIcon","size":"16"}})],1)],1)]),_c('b-button',{attrs:{"title":_vm.$t('Edit'),"variant":"primary","to":{
                name: 'scout-player-edit',
                params: { seasonId: _vm.seasonId, playerId: _vm.playerId },
              }}},[_c('i',{staticClass:"code-toggler feather icon-edit cursor-pointer"})])],1)],1)],1)],1)],1):_vm._e(),(_vm.player)?_c('b-row',[_c('b-col',{attrs:{"lg":"12"}},[_c('b-card',{staticStyle:{"min-height":"206px"}},[_c('h5',[_vm._v(_vm._s(_vm.$t("Reviews")))]),(_vm.player.reviews && _vm.player.reviews.length > 0)?_c('div',[_c('b-table',{ref:"selectableTable",staticClass:"mb-0",attrs:{"selectable":"","sticky-header":"400px","items":_vm.player.reviews,"fields":_vm.fields},scopedSlots:_vm._u([{key:"cell(id)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.id)+" ")]}},{key:"cell(created_at)",fn:function(data){return [_vm._v(" "+_vm._s(_vm._f("nativeDate")(data.item.created_at))+" ")]}},{key:"cell(comment)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.comment)+" ")]}}],null,false,193891106)})],1):_c('div',{staticClass:"alert alert-warning p-2 m-2"},[_vm._v(" "+_vm._s(_vm.$t("No comment"))+" ")])])],1),_c('b-col',{attrs:{"lg":"12"}},[_c('b-card',[_c('h5',[_vm._v(_vm._s(_vm.$t("Matches")))]),(_vm.competitions != null)?_c('div',[_c('player-matches',{staticClass:"pt-75",attrs:{"competitions":_vm.competitions}})],1):_c('div',[_vm._v(" "+_vm._s(_vm.$t("Data not found"))+" ")])])],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }