import http from "@/libs/axios"
import toastService from "./toastService"
import i18n from "@/libs/i18n"

export default class jobService {
  constructor() {}

  async get() {
    return new Promise((resolve, reject) => {
      http
        .get("/job")
        .then((response) => {
          return resolve(response)
        })
        .catch((error) => reject(error))
    })
  }

  async event({ data, dataId }) {
    if (data && dataId) {
      return new Promise((resolve, reject) => {
        http
          .put(`/job/${dataId}`, data)
          .then((response) => {
            toastService(i18n.t("Successfully Updated"), "PlusIcon", "success")
            return resolve(response)
          })
          .catch((error) => reject(error))
      })
    } else if (dataId) {
      return new Promise((resolve, reject) => {
        http
          .delete(`/job/${dataId}`)
          .then((response) => {
            toastService(i18n.t("Successfully Deleted"), "PlusIcon", "success")
            return resolve(response)
          })
          .catch((error) => reject(error))
      })
    } else if (data) {
      return new Promise((resolve, reject) => {
        http
          .post("/job", data)
          .then((response) => {
            toastService(i18n.t("Successfully Created"), "PlusIcon", "success")
            return resolve(response)
          })
          .catch((error) => reject(error))
      })
    }
  }
}
