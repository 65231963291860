<template>
  <div>
    <b-row class="d-flex justify-content-between my-2 mx-1">
      <div class="w-50">
        <!-- button on both side -->
        <b-input-group>
          <b-form-input v-model="searchQuery" :placeholder="$t('Search an Season')" />
          <b-input-group-append>
            <b-button variant="outline-primary">
              {{ $t("Search") }}
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </div>
      <div>
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          type="button"
          variant="primary"
          @click="openSidebar(false)"
        >
          {{ $t("Add New") }}
        </b-button>
      </div>
    </b-row>
    <b-row v-if="filteredSeasons">
      <b-col lg="3" v-for="(item, index) in filteredSeasons" :key="index">
        <b-card action-collapse>
          <div class="d-flex justify-content-start align-items-center">
            <span
              v-b-tooltip.hover.v-primary
              :title="$t('Season: ') + item.seasonName"
              class="badge p-1 ml-1 badge-warning"
            >
              {{ item.seasonName }}
            </span>
            <feather-icon
              v-b-tooltip.hover.v-success
              @click="openSidebar(item)"
              :title="$t('Edit Season')"
              class="ml-1"
              icon="EditIcon"
              size="22"
            />
            <feather-icon
              v-b-tooltip.hover.v-dark
              @click="openSidebar(item)"
              :title="$t('Show Season')"
              class="ml-1"
              icon="EyeIcon"
              size="22"
            />
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-row v-else>
      <b-col lg="3" v-for="(item, index) in seasons" :key="index">
        <b-card action-collapse>
          <div class="d-flex justify-content-start align-items-center">
            <span
              v-b-tooltip.hover.v-primary
              :title="$t('Season: ') + item.seasonName"
              class="badge p-1 ml-1 badge-warning"
            >
              {{ item.seasonName }}
            </span>
            <feather-icon
              v-b-tooltip.hover.v-success
              @click="openSidebar(item)"
              :title="$t('Edit Season')"
              class="ml-1"
              icon="EditIcon"
              size="22"
            />
            <feather-icon
              v-b-tooltip.hover.v-dark
              @click="openSidebar(item)"
              :title="$t('Show Season')"
              class="ml-1"
              icon="EyeIcon"
              size="22"
            />
          </div>
        </b-card>
      </b-col>
    </b-row>
    <season-sidebar @is-sidebar-active="isSidebarClosed" :isSidebarActive="isSidebarActive" :item="tempItem" />
  </div>
</template>
<script>
  import {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    VBTooltip,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
  } from "bootstrap-vue"
  import Ripple from "vue-ripple-directive"
  import vSelect from "vue-select"
  import seasonSidebar from "./seasonSidebar.vue"
  import { ref, watch } from "@vue/composition-api"
  import BCardActions from "@core/components/b-card-actions/BCardActions.vue"
  export default {
    components: {
      BCardActions,
      BCard,
      BRow,
      BCol,
      BFormInput,
      BButton,
      BTable,
      BMedia,
      BAvatar,
      BLink,
      BBadge,
      BDropdown,
      BDropdownItem,
      BPagination,
      seasonSidebar,
      BInputGroup,
      BInputGroupAppend,
      BInputGroupPrepend,
      vSelect,
    },
    directives: {
      "b-tooltip": VBTooltip,
      Ripple,
    },
    methods: {
      openSidebar(item) {
        this.isSidebarActive = !this.isSidebarActive
        this.tempItem = item
      },
      isSidebarClosed(event) {
        this.isSidebarActive = event
      },
    },
    setup(props, { root }) {
      const isSidebarActive = ref(false)
      const tempItem = ref(null)
      const searchQuery = ref(null)
      const filteredSeasons = ref(null)
      watch(searchQuery, (val) => {
        filteredList(val)
      })
      const seasons = [
        {
          id: 1,
          seasonName: "2018-2019",
          year: 2018,
        },
        {
          id: 2,
          seasonName: "2019-2020",
          year: 2019,
        },
        {
          id: 3,
          seasonName: "2020-2021",
          year: 2020,
        },
        {
          id: 4,
          seasonName: "2021-2022",
          year: 2021,
        },
      ]
      const filteredList = (input) => {
        return (filteredSeasons.value = seasons.filter((item) =>
          item.seasonName.toLowerCase().includes(input.toLowerCase()),
        ))
      }
      return {
        seasons,
        filteredSeasons,
        isSidebarActive,
        tempItem,
        searchQuery,
      }
    },
  }
</script>
<style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }
</style>
<style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
</style>
