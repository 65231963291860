import jwtDefaultConfig from "./jwtDefaultConfig"
import i18n from "@/libs/i18n"
import toastService from "@/services/toastService"

export default class JwtService {
  // Will be used by this service for making API calls
  axiosIns = null

  // jwtConfig <= Will be used by this service
  jwtConfig = {
    ...jwtDefaultConfig,
  }

  // For Refreshing Token
  isAlreadyFetchingAccessToken = false

  // For Refreshing Token
  subscribers = []

  constructor(axiosIns, jwtOverrideConfig) {
    this.axiosIns = axiosIns
    this.jwtConfig = {
      ...this.jwtConfig,
      ...jwtOverrideConfig,
    }

    // Request Interceptor
    this.axiosIns.interceptors.request.use(
      (config) => {
        // Get token from localStorage
        const accessToken = this.getToken()

        // If token is present add it to request's Authorization Header
        if (accessToken) {
          // eslint-disable-next-line no-param-reassign
          config.headers.Token = `${accessToken}`
        }
        return config
      },
      (error) => Promise.reject(error),
    )

    // Add request/response interceptor
    this.axiosIns.interceptors.response.use(
      (response) => Promise.resolve(response),
      (error) => {
        // const { config, response: { status } } = error
        console.log(error)

        const { response, status } = error

        // this.showAlert()
        if (error && error.response && error.response.data.message) {
          new toastService(i18n.t(error.response.data.message), "AlertCircleIcon", "danger")
          // TODO : REFRESH TOKEN
          if (error.response.status === 401) {
            var season = localStorage.getItem("season")
            localStorage.clear()
            setTimeout(() => localStorage.setItem("season", season), 500)
            setTimeout(() => (location.href = "/login"), 1000)
          }
        }

        if (error && error.response && error.response.data.detail && error.response.data.title) {
          console.log(i18n.t(error.response.data.title))
          new toastService(i18n.t(error.response.data.title), "AlertCircleIcon", "danger", error.response.data.detail)
          // TODO : REJECT ERROR NOT WORKING
        }

        // if (status === 401) {
        if (response && response.status === 401) {
          // TODO: REFRESH TOKEN
          // if (!this.isAlreadyFetchingAccessToken) {
          //   this.isAlreadyFetchingAccessToken = true
          //   this.refreshToken().then(r => {
          //     this.isAlreadyFetchingAccessToken = false
          //
          //     // Update accessToken in localStorage
          //     this.setToken(r.data.accessToken)
          //     this.setRefreshToken(r.data.refreshToken)
          //
          //     this.onAccessTokenFetched(r.data.accessToken)
          //   })
          // }
          // return new Promise(resolve => {
          //   this.addSubscriber(accessToken => {
          //     // Make sure to assign accessToken according to your response.
          //     // Check: https://pixinvent.ticksy.com/ticket/2413870
          //     // Change Authorization header
          //     originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
          //     resolve(this.axiosIns(originalRequest))
          //   })
          // })
        }
        return Promise.reject(error)
      },
    )
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter((callback) => callback(accessToken))
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
  }

  getRefreshToken() {
    return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName)
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
  }

  setRefreshToken(value) {
    localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value)
  }

  login(...args) {
    return this.axiosIns.post(this.jwtConfig.loginEndpoint, ...args)
  }

  register(...args) {
    return this.axiosIns.post(this.jwtConfig.registerEndpoint, ...args)
  }

  refreshToken() {
    return this.axiosIns.post(this.jwtConfig.refreshEndpoint, {
      refreshToken: this.getRefreshToken(),
    })
  }

  showAlert() {
    //console.log(vue.$toast)
  }
}
