<template>
  <div class="py-2">
    <b-modal
      @ok="handleOk"
      :ok-title="$t('Add')"
      :cancel-title="$t('Cancel')"
      id="modal-copy"
      ref="isCopyModalOpen"
      :title="$t('Copy')"
      centered
      :ok-disabled="copying"
      :cancel-disabled="copying"
      :no-close-on-backdrop="copying"
      :hide-header-close="copying"
      modal-header-close="false"
    >
      <b-overlay :show="copying" rounded="sm">
        <div>
          <b-form>
            <b-row>
              <b-col md="12">
                <label>{{ $t("Training Name") }}</label>
                <b-form-group>
                  <b-input-group>
                    <b-form-input v-model="trainingModalData.title" type="text" :placeholder="$t('Training Name')" />
                  </b-input-group>
                </b-form-group>
              </b-col>

              <b-col md="8">
                <label>{{ $t("Training Date") }}</label>
                <b-form-group>
                  <b-input-group>
                    <b-form-datepicker
                      :placeholder="$t('Training Date')"
                      :obj="trainingModalData.startDate"
                      v-model="trainingModalData.startDate"
                      :locale="lang"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>

              <b-col md="4">
                <label>{{ $t("Start Time") }}</label>
                <b-form-group>
                  <b-input-group>
                    <b-form-input v-model="trainingModalData.startTime" type="text" placeholder="HH:mm" />
                    <b-input-group-append>
                      <b-form-timepicker
                        v-model="trainingModalData.startTime"
                        no-close-button
                        button-only
                        button-variant="outline-info"
                        left
                        size="sm"
                        :locale="lang"
                      />
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <label>{{ $t("Season") }}</label>
                <b-form-group>
                  <v-select
                    v-model="trainingModalData.season"
                    :options="avaibleSeasons"
                    :reduce="(option) => option.id"
                    :getOptionLabel="(option) => option.name"
                    :clearable="false"
                  >
                  </v-select>
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </div>
      </b-overlay>
    </b-modal>
    <add-antrenmans :trainingData="training" :key="training.id"></add-antrenmans>
    <b-overlay :show="loading" rounded="sm">
      <!--  Training Data --->
      <div class="card card-body">
        <div class="card-header p-0 m-0 mb-2">
          <div>
            <b-card-title> </b-card-title>
          </div>
          <TrainingNavigation />
        </div>
        <b-row v-if="!showEditForm">
          <b-col lg="4">
            <div class="block">
              <p class="text-">{{ $t("Training Name") }}</p>
              <p class="h5">{{ training.title }}</p>
            </div>
          </b-col>

          <b-col lg="4">
            <div class="block">
              <p class="">{{ $t("Training Date") }}</p>
              <p class="h5" v-if="training.startDate">
                {{
                  new Date(training.startDate).toLocaleDateString($i18n.locale, {
                    weekday: "short",
                    month: "short",
                    day: "numeric",
                  })
                }}
                {{
                  new Date(training.startDate).toLocaleTimeString($i18n.locale, { hour: "numeric", minute: "numeric" })
                }}
                -
                {{
                  new Date(training.endDate).toLocaleTimeString($i18n.locale, { hour: "numeric", minute: "numeric" })
                }}
              </p>
            </div>
          </b-col>

          <b-col lg="4">
            <div class="block">
              <p>{{ $t("Location") }}</p>
              <p class="h5">
                {{ training.location ? training.location.name : null }}
              </p>
            </div>
          </b-col>
        </b-row>

        <validation-observer ref="trainingInitialDataForm">
          <b-row v-if="showEditForm">
            <b-col md="4" xl="2">
              <label>{{ $t("Training Name") }}</label>
              <validation-provider #default="{ errors }" name="name" rules="required">
                <b-form-group>
                  <b-input-group>
                    <b-form-input
                      v-model="trainingInitForm.title"
                      :state="errors.length > 0 ? false : null"
                      type="text"
                      :placeholder="$t('Training Name')"
                    />
                  </b-input-group>
                </b-form-group>

                <small class="text-danger mt-1">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>

            <b-col md="4" xl="3">
              <label>{{ $t("Training Date") }}</label>
              <validation-provider #default="{ errors }" name="startDate" rules="required">
                <b-form-group>
                  <b-input-group>
                    <b-form-datepicker
                      :placeholder="$t('Training Date')"
                      :state="errors.length > 0 ? false : null"
                      :obj="trainingInitForm.startDate"
                      v-model="trainingInitForm.startDate"
                      :locale="lang"
                    />
                  </b-input-group>
                </b-form-group>

                <small class="text-danger mt-1">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>

            <b-col md="4" xl="2">
              <label>{{ $t("Start Time") }}</label>
              <validation-provider #default="{ errors }" name="startTime" rules="required">
                <b-form-group>
                  <b-input-group>
                    <b-form-input
                      v-model="trainingInitForm.startTime"
                      :state="errors.length > 0 ? false : null"
                      type="text"
                      placeholder="HH:mm"
                    />
                    <b-input-group-append>
                      <b-form-timepicker
                        v-model="trainingInitForm.startTime"
                        :state="errors.length > 0 ? false : null"
                        no-close-button
                        button-only
                        button-variant="outline-info"
                        left
                        size="sm"
                        :locale="lang"
                      />
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>

                <small class="text-danger mt-1">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>

            <b-col md="6" xl="3" class="mb-1">
              <label>{{ $t("Location") }}</label>
              <validation-provider #default="{ errors }" name="location" rules="required">
                <b-form-group>
                  <v-select
                    v-model="trainingInitForm.location"
                    @search="searchLocation"
                    :autocomplete="`on`"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="locations"
                    :reduce="(option) => option.id"
                    :getOptionLabel="(option) => option.name"
                  >
                    <template #option="{ name }">
                      <div v-if="name">
                        <span v-if="name"> {{ name }}</span>
                      </div>
                    </template>

                    <template #no-options="{ search }">
                      <div @click="addThisLocation(search)">
                        <feather-icon icon="PlusCircleIcon" size="14" />
                        <span> {{ $t("Do you want to add this item?") }}</span>
                      </div>
                    </template>

                    <template #selected-option="{ name }">
                      <div v-if="name">
                        <span class="text-nowrap" v-b-tooltip.hover :title="name">{{ name.substring(0, 25) }}...</span>
                      </div>
                    </template>
                  </v-select>
                </b-form-group>

                <small class="text-danger mt-1">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
          </b-row>
        </validation-observer>
      </div>

      <!--  Training Block & Items --->
      <div v-if="training" :set="(tmpTitles = [])">
        <b-card
          no-body
          v-for="(item, blockIndex) in training.trainingBlocks"
          :key="blockIndex"
          class="my-1"
          :aria-controls="`collapse-${item.id}`"
        >
          <b-card-header>
            <div :set="tmpTitles[blockIndex]= item.title.split(' ')" >
              <b-card-title>{{ tmpTitles[blockIndex][0]+' '+ $t('Block') }}</b-card-title>
            </div>
            <div class="heading-elements">
              <ul class="list-inline mb-0">
                <li>
                  <a v-b-toggle="`collapse-${item.id}`" class="downIcon">
                    <feather-icon icon="ChevronDownIcon" size="16" />
                  </a>
                </li>
                <li>
                  <a @click="removeBlock(blockIndex, item.id)" data-action="close">
                    <feather-icon icon="XIcon" size="14" />
                  </a>
                </li>
                <li>
                  <a @click="moveBlock(blockIndex, blockIndex + 1)">
                    <feather-icon v-b-tooltip.hover :title="$t('Move Down')" icon="ArrowDownIcon" size="14" />
                  </a>
                </li>
                <li>
                  <a @click="moveBlock(blockIndex, blockIndex - 1)">
                    <feather-icon v-b-tooltip.hover :title="$t('Move Up')" icon="ArrowUpIcon" size="14" />
                  </a>
                </li>
              </ul>
            </div>
          </b-card-header>
          <b-collapse :id="`collapse-${item.id}`" :visible="blockIndex==0||item.trainingItems.length==0" class="card-content">
            <b-card-body>
              <div v-if="item.trainingItems.length == 0">
                <div class="d-flex justify-content-center align-items-center">
                  <b-button
                    class="mx-1"
                    variant="success"
                    v-b-modal="'select-library-modal-' + blockIndex"
                    v-b-tooltip.hover.top="$t('Add Training Item From Library')"
                  >
                    <feather-icon icon="BookIcon" />
                    {{ $t("Add Training Item From Library") }}
                  </b-button>
                  <b-button
                    class="mx-1"
                    variant="primary"
                    @click="addNewTrainingItem(blockIndex)"
                    v-b-tooltip.hover.top="$t('Add New Training Item')"
                  >
                    <feather-icon icon="PlusIcon" />
                    {{ $t("Add New Training Item") }}
                  </b-button>
                </div>
              </div>

              <div class="" v-for="(trainingItem, itemIndex) in item.trainingItems" :key="itemIndex">
                <!-- REMOVE & COPY ICONS-->
                <b-overlay :show="sectionLoadingId == trainingItem.id || sectionLoadingId == null" rounded="sm">
                  <div class="d-flex">
                    <b-table-simple borderless>
                      <b-thead>
                        <b-tr>
                          <!-- <b-th @dblclick="editableName = !editableName" -->
                          <b-th
                            >#{{ trainingItem.id ? trainingItem.id : 0 }} -
                            {{ trainingItem.name }}
                          </b-th>

                          <b-th class="text-right">
                            <feather-icon
                              class="hover-icon mx-1"
                              v-b-tooltip.hover.top="$t('Remove Training Item')"
                              @click="removeTrainingItem(blockIndex, itemIndex, trainingItem)"
                              icon="XIcon"
                              size="16"
                            />

                            <feather-icon
                              class="hover-icon mx-1"
                              v-b-tooltip.hover.top="$t('Add New Training Item')"
                              @click="addNewTrainingItem(blockIndex)"
                              icon="PlusIcon"
                              size="16"
                            />
                            <feather-icon
                              class="hover-icon mx-1"
                              v-b-tooltip.hover.top="$t('Add Training Item From Library')"
                              v-b-modal="'select-library-modal-' + blockIndex"
                              icon="BookIcon"
                              size="16"
                            />
                          </b-th>
                        </b-tr>
                      </b-thead>
                    </b-table-simple>
                  </div>

                  <!-- NEW ITEM ADD -->
                  <div>
                    <validation-observer ref="editTrainingData">
                      <b-form>
                        <b-row>
                          <b-col class="mt-1 mb-1" sm="12" lg="3">
                            <div class="border-primary rounded p-1">
                              <validation-provider #default="{ errors }" name="name" rules="required">
                                <b-form-group :label="$t('Drill Name')" label-for="name">
                                  <b-form-input
                                    v-model.lazy="trainingItem.name"
                                    :state="errors.length > 0 ? false : null"
                                    id="name"
                                    :placeholder="$t('Drill Name')"
                                  />
                                  <small class="text-danger mt-1 mb-1">{{ errors[0] }}</small>
                                </b-form-group>
                              </validation-provider>

                              <validation-provider #default="{ errors }" name="duration" rules="required">
                                <span class="mr-25 small">{{ $t("Planned Duration") }}</span>
                                <b-input-group :append="$t('dk')" class="mb-1">
                                  <b-form-input
                                    class="w-75"
                                    v-model.number="trainingItem.duration"
                                    :state="errors.length > 0 ? false : null"
                                    id="time"
                                    type="number"
                                  />
                                </b-input-group>

                                <small class="text-danger mt-1">{{ errors[0] }}</small>
                              </validation-provider>

                              <validation-provider #default="{ errors }" name="learningOutcomes" rules="required">
                                <b-form-group :label="$t('Purpose')" label-for="time">
                                  <v-select
                                    v-model="trainingItem.learningOutcomes"
                                    :state="errors.length > 0 ? false : null"
                                    :autocomplete="`on`"
                                    multiple
                                    push-tags
                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                    :options="Object.values(learningOutcomes)"
                                    :reduce="(option) => option"
                                    :getOptionLabel="(option) => option.name"
                                    class="fit-dropdown"
                                  >
                                  </v-select>
                                </b-form-group>

                                <small class="text-danger mt-1">{{ errors[0] }}</small>
                              </validation-provider>
                              <validation-provider #default="{ errors }" name="description" rules="required">
                                <b-form-group :label="$t('Description')" label-for="description">
                                  <div class="d-flex">
                                    <b-form-textarea
                                      id="description"
                                      :state="errors.length > 0 ? false : null"
                                      v-model="trainingItem.description"
                                      :placeholder="$t('Enter Description')"
                                      rows="2"
                                    />
                                  </div>
                                </b-form-group>

                                <small class="text-danger mt-1">{{ errors[0] }}</small>
                              </validation-provider>
                            </div>
                          </b-col>

                          <b-col lg="9" v-if="trainingItem.trainingItemMedia">
                            <b-row>
                              <b-col
                                v-for="(mediaItem, mediaItemIndex) in trainingItem.trainingItemMedia.filter(
                                  (i) => i.type != 'video',
                                )"
                                :key="mediaItemIndex"
                                md="12"
                              >
                                <b-card id="ck-editor-recursive">
                                  <ckeditor
                                    @blur="onBlur(trainingItem.id, $event)"
                                    @paste="
                                      onPaste(...arguments, trainingItem.id, blockIndex, itemIndex, mediaItem.path)
                                    "
                                    :id="trainingItem.id"
                                    :name="mediaItemIndex"
                                    class="ck-editor-style"
                                    @contentDom="onContentDom($event)"
                                    @ready="onEditorReady"
                                    data-inline-inject="true"
                                    :editor="editor"
                                    v-model="mediaItem.path"
                                    :config="editorConfig"
                                  >
                                  </ckeditor>
                                </b-card>
                                <b-card :title="$t('Videos')" class="m-1 w-100" v-if="!trainingItem.id">
                                  {{ $t("Please save item then upload the video") }}
                                </b-card>
                                <b-card :title="$t('Videos')" v-else>
                                  <div class="d-flex align-items-center justify-content-end mr-1 mt-n3">
                                    <b-button
                                      @click="openTrainigItemModal(blockIndex, itemIndex, trainingItem.id)"
                                      variant="info"
                                    >
                                      <feather-icon icon="PlusIcon" size="12" />
                                      {{ $t("Add New Video") }}
                                    </b-button>
                                  </div>
                                  <b-row v-if="trainingItem.trainingItemMedia.filter((i) => i.type == 'video')">
                                    <b-col
                                      lg="4"
                                      class="my-1"
                                      v-for="video in trainingItem.trainingItemMedia.filter((i) => i.type == 'video')"
                                      :key="video.id"
                                    >
                                      <div class="border-primary rounded p-1 block">
                                        <div class="d-flex align-items-center justify-content-end">
                                          <b-button
                                            @click="
                                              goDeleteVideo(video.id, true, blockIndex, itemIndex, trainingItem.id)
                                            "
                                            variant="danger"
                                            class="btn-icon rounded-circle mt-n3 mr-n2"
                                          >
                                            <feather-icon icon="TrashIcon" />
                                          </b-button>
                                        </div>
                                        <div class="embed-responsive embed-responsive-16by9">
                                          <span v-html="video.path"></span>
                                        </div>
                                      </div>
                                    </b-col>
                                  </b-row>
                                </b-card>
                              </b-col>
                            </b-row>
                          </b-col>

                          <b-col lg="9" v-else>
                            <b-row v-if="trainingItem.trainingItemTemplateMedia.length > 0">
                              <b-col
                                v-for="(mediaItem, mediaItemIndex) in trainingItem.trainingItemTemplateMedia.filter(
                                  (i) => i.type != 'video',
                                )"
                                :key="mediaItemIndex"
                                :lg="
                                  12 / trainingItem.trainingItemTemplateMedia.filter((i) => i.type != 'video').length
                                "
                              >
                                <b-card>
                                  <ckeditor
                                    class="ck-editor-style"
                                    @paste="
                                      onPaste(...arguments, trainingItem.id, blockIndex, itemIndex, mediaItem.path)
                                    "
                                    :id="trainingItem.id"
                                    :editor="editor"
                                    @ready="onEditorReady"
                                    v-model="mediaItem.path"
                                    :config="editorConfig"
                                  >
                                  </ckeditor>
                                </b-card>
                                <b-card :title="$t('Videos')">
                                  <b-row
                                    v-if="
                                      trainingItem.trainingItemTemplateMedia.filter((i) => i.type == 'video').length > 0
                                    "
                                  >
                                    <b-col
                                      lg="4"
                                      class="my-1"
                                      v-for="video in trainingItem.trainingItemTemplateMedia.filter(
                                        (i) => i.type == 'video',
                                      )"
                                      :key="video.id"
                                    >
                                      <div class="border-primary rounded p-1 block">
                                        <div class="d-flex align-items-center justify-content-end"></div>
                                        <div class="embed-responsive embed-responsive-16by9">
                                          <span v-html="video.path"></span>
                                        </div>
                                      </div>
                                    </b-col>
                                  </b-row>
                                  <p v-else>
                                    {{ $t("Please save item then upload the video") }}
                                  </p>
                                </b-card>
                              </b-col>
                            </b-row>

                            <b-row v-else>
                              <b-col class="mt-2">
                                <ckeditor
                                  class="ck-editor-style"
                                  :id="trainingItem.id"
                                  :editor="editor"
                                  @input="emptyMediaData(blockIndex, itemIndex)"
                                  @ready="onEditorReady"
                                  v-model="emptyEditorData"
                                  :config="editorConfig"
                                >
                                </ckeditor>
                              </b-col>
                              <b-card :title="$t('Videos')">
                                <!-- ADD VIDEO HTML... -->
                              </b-card>
                            </b-row>
                          </b-col>
                        </b-row>
                        <div class="d-flex mb-2 justify-content-end mx-1">
                          <b-button
                            @click.prevent="saveTrainingItem(blockIndex, itemIndex, item.id)"
                            :data-ref="blockIndex"
                            class="mx-1"
                            variant="primary"
                          >
                            <feather-icon icon="SaveIcon" size="14" />
                            {{ trainingItem.id && !trainingItem.type ? $t("Update") : $t("Save") }}
                          </b-button>
                          <b-button
                            v-show="
                              (trainingItem.trainingItemTemplate
                                ? !trainingItem.trainingItemTemplate.id
                                : trainingItem.type != 'Template') && trainingItem.id
                            "
                            @click.prevent="saveTrainingItemToLibrary(blockIndex, itemIndex, item.id)"
                            :data-ref="blockIndex"
                            class="mx-1"
                            variant="secondary"
                          >
                            <feather-icon icon="CopyIcon" size="14" />
                            {{ $t("Save to Library") }}
                          </b-button>
                        </div>
                      </b-form>
                    </validation-observer>
                  </div>
                </b-overlay>
              </div>

              <b-modal
                v-model="trainingItemLibraryModal"
                :id="`select-library-modal-${blockIndex}`"
                :title="$t('Add Training Item From Library')"
                hide-footer
              >
                <b-form-group :label="$t('Drill Name')">
                  <v-select
                    v-model="item.trainingItems"
                    multiple
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="trainingItems"
                    :reduce="(option) => option"
                    :getOptionLabel="(option) => option.name"
                    :filterable="false"
                    @search="onSearch"
                    :clearSearchOnSelect="true"
                  >
                    <li slot="list-footer" class="pagination">
                      <b-pagination
                        @mouseup.native.capture.stop
                        v-model="currentPage"
                        :total-rows="totalItems"
                        :per-page="perPage"
                      ></b-pagination>
                    </li>
                  </v-select>
                </b-form-group>
              </b-modal>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>

      <!--  Add New Block Button --->
      <div class="d-flex align-items-center justify-content-end mx-1 mr-n1">
        <b-button v-if="localLoading === false" @click="AddNewBlock(training)" class="mx-1" variant="warning">
          <feather-icon icon="PlusIcon" size="12" />
          {{ $t("Add New Block") }}
        </b-button>
        <b-button v-else class="mx-1" variant="warning">
          <feather-icon icon="PlusIcon" size="12" />
          {{ $t("Please wait ...") }}
        </b-button>
        <b-button class="mx-1" variant="primary" @click="saveAll">
          <feather-icon icon="SaveIcon" size="12" />
          {{ $t("Save All") }}
        </b-button>
      </div>

      <!-- Participants --->
      <b-card-actions :title="$t('Participants')" class="mt-2" action-collapse>
        <send-notify ref="notifyComp" :trainingAttenders="training.trainingAttenders" :trainingData="training" />
      </b-card-actions>

      <!-- VIDEOS --->
      <b-card :title="$t('Videos')" class="mt-2">
        <div class="d-flex align-items-center justify-content-end mr-1 mt-n3">
          <b-button v-b-modal.add-new-video-modal variant="info">
            <feather-icon icon="PlusIcon" size="12" />
            {{ $t("Add New Video") }}
          </b-button>
        </div>
        <b-row v-if="training.trainingMedia">
          <b-col lg="4" class="my-1" v-for="video in getVideos(training.trainingMedia, 'video')" :key="video.id">
            <div class="border-primary rounded p-1 block">
              <div class="d-flex align-items-center justify-content-end">
                <b-button @click="goDeleteVideo(video.id)" variant="danger" class="btn-icon rounded-circle mt-n3 mr-n2">
                  <feather-icon icon="TrashIcon" />
                </b-button>
              </div>
              <div class="embed-responsive embed-responsive-16by9">
                <span v-html="video.path"></span>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-card>

      <!--  Video Modal --->
      <b-modal id="add-new-video-modal" ref="addNewVideoModal" hide-footer centered :title="$t('Add New Video')">
        <b-tabs>
          <b-tab :title="$t('Video Embed Code')" active>
            <b-form-group>
              <b-input-group>
                <b-form-input v-model="video" type="text" :placeholder="$t('Video Link')" />
              </b-input-group>
              <small class="form-text text-danger">{{ $t("Please enter the embed link") }}</small>
            </b-form-group>
          </b-tab>
          <b-tab :title="$t('Video Upload')" @click=";(video = ''), (videoFile = null)">
            <b-form-file
              accept="video/*"
              :placeholder="$t('You can select video from here or just move to here.')"
              :drop-placeholder="$t('Move to here...')"
              v-model="videoFile"
            />
            <small class="form-text text-danger">{{ $t("Maximum file limit 1 GB") }}</small>
          </b-tab>
        </b-tabs>
        <div class="d-flex justify-content-end">
          <b-button @click="addVideo" variant="success" class="mt-1" v-if="!loading">
            {{ $t("Add Video") }}
          </b-button>
          <b-button variant="success" class="mt-1" v-else>
            {{ $t("Adding ...") }}
          </b-button>
        </div>
      </b-modal>

      <!--  Video Training Item Modal --->
      <b-modal
        id="add-new-training-item-video-modal"
        ref="addNewTrainingItemVideoModal"
        hide-footer
        centered
        :title="$t('Add New Video')"
      >
        <b-tabs>
          <b-tab :title="$t('Video Embed Code')" active>
            <b-form-group>
              <b-input-group>
                <b-form-input v-model="trainingItemVideo" type="text" :placeholder="$t('Video Link')" />
              </b-input-group>
              <small class="form-text text-danger">{{ $t("Please enter the embed link") }}</small>
            </b-form-group>
          </b-tab>
          <b-tab :title="$t('Video Upload')" @click=";(trainingItemVideo = ''), (trainingItemVideoFile = null)">
            <b-form-file
              :browseText="$t('Browse')"
              accept="video/*"
              :placeholder="$t('You can select video from here or just move to here.')"
              :drop-placeholder="$t('Move to here...')"
              v-model="trainingItemVideoFile"
            />
            <small class="form-text text-danger">{{ $t("Maximum file limit 1 GB") }}</small>
            <b-progress
              class="mt-1"
              height="1rem"
              v-if="loading"
              max="100"
              :value.sync="uploadPercentage"
              show-progress
              animated
            ></b-progress>
          </b-tab>
        </b-tabs>
        <div class="d-flex justify-content-end">
          <b-button @click="addTrainingItemVideo" variant="success" class="mt-1" v-if="!loading">
            {{ $t("Add Video") }}
          </b-button>
          <b-button variant="success" class="mt-1" v-else>
            {{ $t("Adding ...") }}
          </b-button>
        </div>
      </b-modal>
      <b-button variant="primary" class="mt-1" :to="{ name: 'team-trainings' }">
        {{ $t("Back") }}
      </b-button>
    </b-overlay>
  </div>
</template>

<script>
  import BCardActions from "@core/components/b-card-actions/BCardActions.vue"
  import AppCollapse from "@core/components/app-collapse/AppCollapse.vue"
  import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue"

  //CKEDITOR5
  import CKEditor from "@/libs/custom-ck-editor"
  import InlineEditor from "@ckeditor/ckeditor5-editor-inline/src/inlineeditor"
  import SimpleUploadAdapter from "@ckeditor/ckeditor5-upload/src/adapters/simpleuploadadapter"
  import Base64UploadAdapter from "@ckeditor/ckeditor5-upload/src/adapters/base64uploadadapter"
  import MediaEmbed from "@ckeditor/ckeditor5-media-embed/src/mediaembed"
  import EssentialsPlugin from "@ckeditor/ckeditor5-essentials/src/essentials"
  import BoldPlugin from "@ckeditor/ckeditor5-basic-styles/src/bold"
  import ItalicPlugin from "@ckeditor/ckeditor5-basic-styles/src/italic"
  import LinkPlugin from "@ckeditor/ckeditor5-link/src/link"
  import ParagraphPlugin from "@ckeditor/ckeditor5-paragraph/src/paragraph"

  import Image from "@ckeditor/ckeditor5-image/src/image"
  import ImageToolbar from "@ckeditor/ckeditor5-image/src/imagetoolbar"
  import ImageCaption from "@ckeditor/ckeditor5-image/src/imagecaption"
  import ImageUpload from "@ckeditor/ckeditor5-image/src/imageupload"
  import AutoImage from "@ckeditor/ckeditor5-image/src/autoimage"
  import ImageInsert from "@ckeditor/ckeditor5-image/src/imageinsert"
  import PasteFromOffice from "@ckeditor/ckeditor5-paste-from-office/src/pastefromoffice"
  import ClipBoard from "@ckeditor/ckeditor5-clipboard/src/clipboard"

  //CUSTOM
  import UploadAdapter from "@/libs/uploader"

  import TrainingBasicTable from "./components/TrainingBasicTable.vue"
  import TrainingPlugin from "@/views/team/team-sub-pages/training/TrainingPlugin"
  import TrainingStoreModule from "@/views/team/team-sub-pages/training/TrainingStoreModule"
  import vSelect from "vue-select"
  import flatPickr from "vue-flatpickr-component"
  import { ValidationProvider, ValidationObserver } from "vee-validate"
  import { required, email, confirmed, password } from "@validations"
  import { toRefs, onUnmounted, onMounted, ref, watch } from "@vue/composition-api"
  import store from "@/store"
  import { useRouter } from "@core/utils/utils"
  import i18n from "@/libs/i18n"

  import axios from "@/libs/axios"
  import { useToast } from "vue-toastification/composition"
  import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
  import SendNotify from "@/views/team/team-sub-pages/training/detail/training-send-notify/SendNotify.vue"
  import { constants } from "fs"
  import AddAntrenmans from "../add-training/AddAntrenmans.vue"
  import uploadAdapter from "@/services/uploadAdapterService"
  import TrainingNavigation from "./TrainingNavigation"
  import _ from "lodash"

  export default {
    components: {
      TrainingNavigation,
      AddAntrenmans,
      BCardActions,
      flatPickr,
      SendNotify,
      AppCollapse,
      AppCollapseItem,
      TrainingBasicTable,
      vSelect,
      ValidationProvider,
      ValidationObserver,
      ckeditor: CKEditor.component,
    },
    directives: {},
    data() {
      return {
        editableName: false,
        trainingBasicTableFields: [
          { field: "name", label: "İsim" },
          { field: "duration", label: "Süre", width: "90px" },
        ],
        trainingItemLibraryModal: false,
        editor: InlineEditor,
        editorData: "<p></p>",
        editorConfig: {
          plugins: [
            EssentialsPlugin,
            BoldPlugin,
            ItalicPlugin,
            LinkPlugin,
            ParagraphPlugin,
            Image,
            ImageToolbar,
            ImageCaption,
            ImageUpload,
            AutoImage,
            ImageInsert,
            PasteFromOffice,
            ClipBoard,
            //Base64UploadAdapter,
            //MediaEmbed,
            //SimpleUploadAdapter,
            //  ImageUploadProgress,
          ],
          // extraPlugins: [this.uploader],
          toolbar: {
            items: ["bold", "italic", "link", "undo", "redo", "insertImage"],
          },
        },
      }
    },
    methods: {
      onContentDom(evt) {
        console.log(evt)
      },
      saveAll() {
        document.querySelectorAll("[data-ref]").forEach((item) => {
          let ref = Number(item.getAttribute("data-ref"))
          item.click()
        })
      },
      onBlur(i, e) {
        //  console.log(e); // This instance of Ckeditor
      },
      handleOk(bvModalEvt) {
        // Prevent modal from closing
        bvModalEvt.preventDefault()
        this.copyTraining(this.trainingModalData)
      },

      onSearch(search, loading) {
        loading(true)
        this.searchPlayer(loading, search, this)
      },
      searchPlayer: _.debounce(async (loading, search, vm) => {
        await vm.getTrainingItemTemplates(search)
        loading(false)
      }, 350),
    },
    watch: {
      trainingItemLibraryModal: function (newValue, oldValue) {
        if (newValue === false && oldValue === true) {
          this.getTrainingItemTemplates()
        }
      },
    },
    setup(props, { emit, root }) {
      const { router, route } = useRouter()
      const trainingId = ref(router.currentRoute.params.trainingId)
      const STORE_MODULE_NAME = "teamTraining"
      const lang = ref(i18n.locale)

      const toast = useToast()
      // Register module
      if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, TrainingStoreModule)

      // UnRegister on leave
      onUnmounted(() => {
        if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
      })

      const sectionLoadingId = ref({})
      const emptyEditorData = ref(null)

      onMounted(async () => {
        //getTeamPlayers();
        await getTrainingItemTemplates()
        await getLearningOutcomes()
        await getLocations()
        await getTraining(trainingId.value)
      })

      const emptyMediaData = (blockIndex, itemIndex) => {
        training.value.trainingBlocks[blockIndex].trainingItems[itemIndex].trainingItemTemplateMedia.push({
          path: emptyEditorData.value,
          id: null,
          is_cover_image: false,
          type: "image",
        })
      }

      const getDate = (date = null) => {
        return new Date(date)
          .toLocaleString("en", {
            timeZone: "Europe/Istanbul",
            hour12: false,
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
          })
          .replace(",", "")
      }

      const uploader = (editor) => {
        editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
          if (editor.sourceElement.id) {
            sectionLoadingId.value = editor.sourceElement.id
          } else {
            sectionLoadingId.value = null
          }
          loader.on("change:uploadResponse", (evt, name, val, oldval) => {
            if (val) {
              sectionLoadingId.value = "-889-/7*"
            }
          })
          return new UploadAdapter(loader, process.env.VUE_APP_CDN_UPLOAD_API)
        }
      }

      const onPaste = async (evt, data, id, blockIndex, itemIndex, value) => {
        const item = training.value.trainingBlocks[blockIndex].trainingItems[itemIndex]
        const files = data.dataTransfer.files

        if (files.length > 0) {
          if (id) {
            sectionLoadingId.value = id
          } else {
            sectionLoadingId.value = null
          }
          let htmlData = data.dataTransfer.getData("text/html")
          if (htmlData) {
            const sources = htmlData
              .match(/<img [^>]*src="[^"]*"[^>]*>/gm)
              .map((x) => x.replace(/.*src="([^"]*)".*/, "$1"))
            const oldUrl = sources[0]
            UploadPastedMedia(files[0], item, value)
          } else {
            UploadPastedMedia(files[0], item, value)
          }
        }
      }

      const UploadPastedMedia = (file, item, value) => {
        value === null ? (value = "") : value
        let axiosData = new FormData()
        axiosData.append("upload", file)
        axios
          .post(process.env.VUE_APP_CDN_UPLOAD_API, axiosData)
          .then((response) => {
            item.trainingItemMedia[0].path = value + `<figure class="image"><img src="${response.data.url}"></figure>`
            sectionLoadingId.value = "-889-/7*"
          })
          .catch((error) => {
            sectionLoadingId.value = "-889-/7*"
          })
      }

      const parseTime = (date) => {
        if (!date) return ""
        let dateFormat = new Date(date)
        let dateFormat1 = dateFormat.toLocaleTimeString("en", {
          timeZone: "Europe/Istanbul",
          hour12: false,
          hour: "numeric",
          minute: "numeric",
        })

        return dateFormat1
      }

      const getVideos = (media, type) => {
        return media.filter((item) => {
          return item.type == type
        })
      }

      const getVideoDuration = (file) =>
        new Promise((resolve, reject) => {
          let video = document.createElement("video")
          video.preload = "metadata"
          video.onloadedmetadata = () => resolve(video.duration)
          video.onerror = (error) => reject()
          video.src = window.URL.createObjectURL(file)
        })

      const addVideo = () => {
        if (video.value || videoFile.value) {
          let formData
          if (video.value) {
            formData = {
              video: video.value,
            }
          } else {
            let status = root.validateSize(videoFile.value, 1024)
            if (!status) return false
            formData = new FormData()
            formData.append("video", videoFile.value)
          }
          setLoading(true, "Adding Video")
          axios
            .post(`/training/${trainingId.value}/video`, formData)
            .then((response) => {
              training.value = response.data
              addNewVideoModal.value.hide()
              setLoading(false, "Added Video")
            })
            .catch((error) => console.log(error))
        } else {
          toast({
            component: ToastificationContent,
            props: {
              title: i18n.t("Please fill the blanks"),
              icon: "AlertIcon",
              variant: "warnıng",
            },
          })
        }
      }
      const goDeleteVideo = (id, isDrill = false, blockIndex = null, itemIndex = null) => {
        root.$bvModal
          .msgBoxConfirm(i18n.t("Please confirm that you want to delete this."), {
            title: i18n.t("Please Confirm"),
            size: "sm",
            okVariant: "primary",
            okTitle: i18n.t("Yes"),
            cancelTitle: i18n.t("Cancel"),
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          })
          .then((value) => {
            if (value) {
              isDrill ? deleteDrillVideo(id, blockIndex, itemIndex) : deleteVideo(id)
            }
          })
      }

      const deleteVideo = (id) => {
        axios
          .delete(`/training/video/${id}`)
          .then((response) => {
            let index = training.value.trainingMedia.findIndex((i) => i.id == id)
            training.value.trainingMedia.splice(index, 1)
            root.toast(i18n.t("Successfully Deleted"), "EditIcon", "success")
          })
          .catch((error) => console.log(error))
      }

      const deleteDrillVideo = (drillVideoId, blockIndex, itemIndex) => {
        axios
          .delete(`/training-item/video/${drillVideoId}`)
          .then((response) => {
            training.value.trainingBlocks[blockIndex].trainingItems[itemIndex].trainingItemMedia =
              training.value.trainingBlocks[blockIndex].trainingItems[itemIndex].trainingItemMedia.filter(
                (d) => d.id != drillVideoId,
              )
            root.toast(i18n.t("Successfully Deleted"), "EditIcon", "success")
          })
          .catch((error) => console.log(error))
      }

      const addTrainingItemVideo = () => {
        if (trainingItemVideo.value || trainingItemVideoFile.value) {
          let formData
          if (trainingItemVideo.value) {
            formData = {
              video: trainingItemVideo.value,
            }
          } else {
            let status = root.validateSize(trainingItemVideoFile.value, 1024)
            if (!status) return false

            formData = new FormData()
            formData.append("video", trainingItemVideoFile.value)
          }

          loading.value = true
          axios
            .post(`/training-item/${trainigItemModalData.value.trainingItemId}/video`, formData, {
              //headers: { 'Content-Type': 'multipart/form-data' },
              onUploadProgress: (progressEvent) =>
                (uploadPercentage.value = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100))),
            })
            .then((response) => {
              addNewTrainingItemVideoModal.value.hide()
              loading.value = false
              let { blockIndex, itemIndex } = trainigItemModalData.value
              training.value.trainingBlocks[blockIndex].trainingItems[itemIndex].trainingItemMedia.push(response.data)
              uploadPercentage.value = 0
              //getTraining(trainingId.value)
            })
            .catch((error) => {
              loading.value = false
              console.log(error)
            })
        } else {
          root.toast(i18n.t("Please fill the blanks"), "AlertIcon", "warning")
        }
      }

      const addNewVideoModal = ref(null)
      const video = ref(null)
      const videoFile = ref(null)

      const addNewTrainingItemVideoModal = ref(null)
      const trainingItemVideo = ref(null)
      const trainingItemVideoFile = ref(null)

      const uploadPercentage = ref(0)

      const trainigItemModalData = ref({})

      const openTrainigItemModal = (blockIndex, itemIndex, trainingItemId) => {
        trainigItemModalData.value = { blockIndex, itemIndex, trainingItemId }
        addNewTrainingItemVideoModal.value.show()
      }

      const onEditorReady = (editor) => {
        editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
          return new uploadAdapter(loader)
        }
      }

      const moveBlock = (from, to) => {
        training.value.trainingBlocks.splice(to, 0, training.value.trainingBlocks.splice(from, 1)[0])
        let priorties = training.value.trainingBlocks.map((item, index) => item.id + "-" + index)
        axios
          .put(`/training-block/priorty`, { priority: priorties })
          .then((response) => {
            root.toast(i18n.t("Successfully Updated"), "CheckIcon", "success")
          })
          .catch((error) => console.log(error))
      }

      const {
        blocks,
        positions,
        learningOutcomes,
        removeBlock,
        AddNewBlock,
        removeTrainingItem,
        AddNewBlockItem,
        editTrainingData,
        addNewTrainingItem,
        getTraining,
        getTeamPlayers,
        training,
        trainingItems,
        getTrainingItemTemplates,
        saveTrainingItem,
        saveTrainingItemToLibrary,
        getLearningOutcomes,
        trainingInitForm,
        addInitialTraining,
        trainingInitialDataForm,
        locations,
        setLoading,
        addThisLocation,
        searchLocation,
        getLocations,
        saveTraining,
        showEditForm,
        loading,
        localLoading,
        isCopyModalOpen,
        copying,
        copyTraining,
        showTrainingCopyModal,
        trainingModalData,
        avaibleSeasons,
        notifyComp,
        totalItems,
        perPage,
        currentPage,
      } = TrainingPlugin(toRefs(props), emit)

      watch([currentPage], () => {
        getTrainingItemTemplates()
      })

      return {
        onEditorReady,

        blocks,
        positions,
        learningOutcomes,
        editTrainingData,
        removeBlock,
        AddNewBlock,
        removeTrainingItem,
        AddNewBlockItem,
        addNewTrainingItem,
        getTraining,
        getTeamPlayers,
        training,
        required,
        trainingItems,
        getTrainingItemTemplates,
        saveTrainingItem,
        saveTrainingItemToLibrary,
        notifyComp,
        getLearningOutcomes,
        getDate,
        parseTime,
        trainingInitForm,
        addInitialTraining,
        trainingInitialDataForm,
        locations,
        addThisLocation,
        searchLocation,
        getLocations,
        saveTraining,
        showEditForm,
        loading,
        video,
        getVideos,
        addNewVideoModal,
        addVideo,
        goDeleteVideo,
        uploader,
        sectionLoadingId,
        emptyMediaData,
        emptyEditorData,
        setLoading,
        localLoading,
        onPaste,
        videoFile,
        isCopyModalOpen,
        copying,
        copyTraining,
        showTrainingCopyModal,
        trainingModalData,
        avaibleSeasons,
        addTrainingItemVideo,
        trainingItemVideo,
        trainingItemVideoFile,
        addNewTrainingItemVideoModal,
        uploadPercentage,
        openTrainigItemModal,
        moveBlock,
        lang,
        totalItems,
        perPage,
        currentPage,
      }
    },
    mounted() {
      this.$nextTick(() => {
        // InlineEditor.on("paste", function (evt) {
        //   console.log(evt);
        // });
      })
    },
  }
</script>

<style lang="scss">
  .training-player-list {
    height: 60vh;
    overflow-y: scroll;
  }

  .hover-icon:hover {
    color: rgb(20, 22, 112);
  }

  .ck-editor-style {
    border: 1px solid rgb(221, 216, 216) !important;
    min-height: 330px;
  }

  .fit-dropdown .vs__dropdown-menu {
    width: fit-content;
    li {
      padding-right: 35px;
      white-space: normal;
    }
  }
  .downIcon[aria-expanded="false"] svg {
    transition: all 0.3s ease;
    transform: rotate(180deg);
  }
  .downIcon[aria-expanded="true"] svg {
    transition: all 0.3s ease;
    transform: rotate(0deg);
  }
</style>

<style lang="css" scoped>
  .pagination {
    display: flex;
    margin: 0 auto;
    width: fit-content;
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }
</style>
