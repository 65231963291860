<template>
  <div class="auth-wrapper auth-v1 px-2" :style="{ 'background-image': 'url(\'' + elementBg + '\')' }">
    <div class="auth-inner py-2 invite-link">
      <!-- Login v1 -->
      <b-card class="mb-0" v-if="showInvite">
        <div class="d-flex justify-content-start">
          <div class>
            <img height="50px" :src="invite.team.club.logo" />
          </div>
          <div class="ml-2">
            <div class="h2">{{ invite.team.name }}</div>
            <div class="d6">{{ invite.training.title }}</div>
          </div>
        </div>

        <b-card-text class="text-left mt-2">
          <span class="h4 d-block text-capitalize">{{
            new Date(invite.training.startDate).toLocaleDateString($i18n.locale, {
              hour12: false,
              day: "2-digit",
              month: "long",
              year: "numeric",
            })
          }}</span>
          <span class="h5 d-block">
            <span class="text-capitalize">{{
              new Date(invite.training.startDate).toLocaleDateString($i18n.locale, { hour12: false, weekday: "long" })
            }}</span>
            <span class="ml-1">{{
              new Date(invite.training.startDate).toLocaleTimeString($i18n.locale).substr(0, 5)
            }}</span>
          </span>

          <span class="d-block small-text">
            <feather-icon icon="MapPinIcon" />
            {{ invite.training.location.name }}
          </span>

          <div class="py-1 px-1">
            <div class="d-flex justify-content-between">
              <span class="h1 emj" :class="[invite.player.score ? 'disable-click' : '']" @click="sliderValue = 1"
                >😊</span
              >
              <span class="h1 emj" :class="[invite.player.score ? 'disable-click' : '']" @click="sliderValue = 3"
                >🙂</span
              >
              <span class="h1 emj" :class="[invite.player.score ? 'disable-click' : '']" @click="sliderValue = 5"
                >😑</span
              >
              <span class="h1 emj" :class="[invite.player.score ? 'disable-click' : '']" @click="sliderValue = 7"
                >😠</span
              >
              <span class="h1 emj" :class="[invite.player.score ? 'disable-click' : '']" @click="sliderValue = 10"
                >😡</span
              >
            </div>
            <vue-slider
              :disabled="invite.player.score > 0"
              v-model="sliderValue"
              :direction="direction"
              style="width: 100%; height: 5px"
              :adsorb="true"
              :data="[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
              :interval="1"
              :marks="true"
            />
            <div class="d-block mt-4 text-center">
              <div>
                <span
                  class="h2 mb-0 text-primary badge p-1 font-large"
                  style="font-size: 20pt"
                  :class="{
                    'badge-light-danger': sliderValue > 7,
                    'badge-light-warning': sliderValue <= 7 && sliderValue >= 3,
                    'badge-light-success': sliderValue < 3,
                  }"
                  >{{ sliderValue }}</span
                >
              </div>

              <div class="mt-2 d-block">
                <textarea
                  name=""
                  :disabled="invite.player.score > 0"
                  :placeholder="$t('Enter comment')"
                  v-model="playerComment"
                  class="form-control"
                  id=""
                  style="height: 100px"
                ></textarea>
              </div>

              <button
                v-if="!invite.player.score"
                :disabled="invite.player.score > 0 || loading"
                class="mt-2 btn btn-primary"
                @click="submitSure"
              >
                {{ $t("Submit") }}
              </button>
              <div v-else class="mt-2">
                <b-alert show variant="success">
                  <p class="py-2">{{ $t("Your response to the physical ability survey has been sent") }}</p>
                </b-alert>
              </div>
            </div>
          </div>

          <div class="d-block mt-2 profile">
            <div class="d-flex justify-content-start mb-1">
              <b-avatar
                class="mr-2"
                size="40"
                :src="invite.player.avatar"
                :text="avatarText(invite.player.first_name + ' ' + invite.player.last_name)"
              ></b-avatar>
              <div class="profile-user-info">
                <h6 v-text="invite.player.first_name"></h6>
                <h6 v-text="invite.player.last_name.trim()"></h6>
              </div>
            </div>
          </div>
        </b-card-text>
      </b-card>
      <!-- /Login v1 -->
    </div>
  </div>
</template>

<script>
  import VueSlider from "vue-slider-component"
  import { ValidationProvider, ValidationObserver } from "vee-validate"

  import { required, email } from "@validations"
  import { togglePasswordVisibility } from "@core/mixins/ui/forms"
  import { getHomeRouteForLoggedInUser } from "@/auth/utils"
  import useJwt from "@/auth/jwt/useJwt"
  import axios from "axios"
  import { avatarText } from "@/@core/utils/filter"

  export default {
    components: {
      // BSV
      VueSlider,
      ValidationProvider,
      ValidationObserver,
    },
    mixins: [togglePasswordVisibility],
    data() {
      return {
        // validation rules
        required,
        email,
        invite: {},
        showInvite: false,
        elementBg: require("@/assets/images/banner/heading_bg.png"),
        sliderValue: 10,
        value22: 50,
        value23: 60,
        data: ["a", "b", "c", "d", "e"],
        dir: "ltr",
        loading: false,
        playerComment: "",
      }
    },
    beforeMount() {
      if (location.href.includes("lang=fr")) this.$i18n.locale = "fr"
      if (location.href.includes("lang=en")) this.$i18n.locale = "en"
      if (location.href.includes("lang=tr")) this.$i18n.locale = "tr"
    },
    created() {
      axios
        // .get("https://run.mocky.io/v3/86dd1929-7ddf-4df8-8dc1-a751d7b7a9d8")
        .get(`${process.env.VUE_APP_API}/invite/training/${this.routerParams.hash}`)
        .then((succ) => {
          this.invite = succ.data
          this.sliderValue = succ.data.player.score || 10
          this.showInvite = true
          this.playerComment = succ.data.player.comment
        })
        .catch((fail) => {
          // TODO: TRANSLATE
          this.$bvToast.toast("Request Fail", {
            variant: "danger",
          })
          this.invite = []
          this.showInvite = false
        })
    },
    computed: {
      direction() {
        this.dir = "ltr"
        return this.dir
      },
    },
    methods: {
      getDayName(dateData) {
        let dd = new Date(dateData).getDay()
        let ddn = ["Pazar", "Pazartesi", "Salı", "Çarşamba", "Perşembe", "Cuma", "Cumartesi"]
        return ddn[dd]
      },
      submitSure() {
        this.$bvModal
          .msgBoxConfirm(`yük değerini ${this.sliderValue} olarak göndermek istediğinizden emin misiniz?`, {
            title: this.$t("Please Confirm"),
            size: "sm",
            okVariant: "primary",
            okTitle: this.$t("Yes"),
            cancelTitle: this.$t("Cancel"),
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          })
          .then((value) => {
            if (value) {
              this.submitSerp()
            }
          })
      },
      submitSerp() {
        this.loading = true
        let hash = this.routerParams.hash
        let rate = this.sliderValue
        let serpURL = `${process.env.VUE_APP_API}/invite/performance/${hash}/value/${rate}/`
        axios
          .post(serpURL, {
            comment: this.playerComment,
          })
          .then((succ) => {
            this.invite.player.score = succ.data.playerSerpScore
            this.loading = false
            this.$bvToast.toast("Performans bilgisi kaydedildi.", {
              title: "Kaydedildi",
            })
          })
          .catch((err) => {
            console.log(err.response.message)
            this.loading = false
            this.$bvToast.toast(err.response.message || "Bilinmeyen hata kodu.", {
              title: "Bir hata oluştu",
            })
          })
      },
    },
    setup(props, { root }) {
      return {
        avatarText,
      }
    },
  }
</script>

<style lang="scss">
  @import "@core/scss/vue/pages/page-auth.scss";

  .auth-wrapper {
    background-position: bottom;
    background-repeat: no-repeat;
  }

  .brand-logo svg {
    height: 120px;
    width: auto !important;
  }

  .invite-link {
    .hour {
      padding: 2rem;
      max-width: 120px;
      margin: 0 auto;
      font-size: 1.5rem;
    }

    .profile {
      .badge {
        float: left;
      }
    }
  }
  .vue-slider-process {
    background: unset !important;
    // background: linear-gradient(88deg, #df0809, #ffc107, #28c76f) !important;
  }
  [dir] .vue-slider-rail {
    background: linear-gradient(88deg, #28c76f, #ffc107, #df0809);
  }
  [dir] .vue-slider-dot-handle {
    border-color: #ddd !important;
    background: white !important;
  }
  [dir] .vue-slider-mark-step {
    background: unset !important;
  }
  [dir] .vue-slider-dot-tooltip-inner {
    background: rgba(6, 189, 6, 0.7) !important;
  }
  .emj {
    font-size: xx-large;
    cursor: pointer;

    &:hover {
      transform: scale(1.1);
    }
  }
  .disable-click {
    pointer-events: none;
  }
</style>

<style lang="scss">
  @import "@core/scss/vue/libs/vue-slider.scss";
</style>
