<template>
  <div>
    <season-sidebar
      :is-season-active.sync="isSeasonActive"
      :season-form-data="season"
      :submit-season="submitSeason"
      :update-season="updateSeason"
    />
    <team-sidebar
      :is-team-active.sync="isTeamActive"
      :team-form-data="team"
      :submit-team="submitTeam"
      :update-team="updateTeam"
    />
    <league-sidebar
      :is-league-active.sync="isLeagueActive"
      :league-form-data="league"
      :submit-league="submitLeague"
      :update-league="updateLeague"
    />
    <position-sidebar
      :is-position-active.sync="isPositionActive"
      :position-form-data="position"
      :submit-position="submitPosition"
      :update-position="updatePosition"
    />
    <club-sidebar
      :is-club-active.sync="isClubActive"
      :club-form-data="club"
      :submit-club="submitClub"
      :update-club="updateClub"
    />
    <location-sidebar
      :is-location-active.sync="isLocationActive"
      :location-form-data="location"
      :submit-location="submitLocation"
      :update-location="updateLocation"
    />
    <department-sidebar
      :is-department-active.sync="isDepartmentActive"
      :department-form-data="department"
      :submit-department="submitDepartment"
      :update-department="updateDepartment"
    />
    <job-sidebar
      :is-job-active.sync="isJobActive"
      :job-form-data="job"
      :submit-job="submitJob"
      :update-job="updateJob"
    />
    <ability-item-sidebar
      :is-active.sync="isAbilityItemActive"
      :form-data="abilityItem"
      :submit="submitAbilityItem"
      :update="updateAbilityItem"
    />
    <test-item-sidebar
      :is-active.sync="isTestItemActive"
      :form-data="testItem"
      :submit="submitTestItem"
      :update="updateTestItem"
    />
    <learning-outcome-sidebar
      :is-active.sync="isLearningOutcomeActive"
      :form-data="learningOutcome"
      :submit="submitLearningOutcome"
      :update="updateLearningOutcome"
    />
    <learning-outcome-category-sidebar
      :is-active.sync="isLearningOutcomeCategoryActive"
      :form-data="learningOutcomeCategory"
      :submit="submitLearningOutcomeCategory"
      :update="updateLearningOutcomeCategory"
    />
    <b-row>
      <b-col lg="6">
        <b-card action-collapse>
          <b-card-title> {{ $t("Seasons") }}</b-card-title>
          <div class="d-block">
            <div class="float-right">
              <b-button
                variant="primary"
                v-b-toggle.sidebar-season
                type="button"
                class="btn btn-primary right-top"
                @click="season = {}"
              >
                {{ $t("Add") }}
              </b-button>
            </div>
          </div>
          <div class="d-flex justify-content-start align-items-center">
            <setting-table
              class="w-100"
              :table-columns="[
                { key: 'id', label: '#' },
                { key: 'name', label: $t('Season'), sortable: true },
                { key: 'actions', label: $t('Action') },
              ]"
              :table-items="getSeasons"
              @goSidebar="goSidebarSeason($event)"
              :delete-data="deleteSeason"
              table-id="seasonTable"
              ref="SeasonTable"
            ></setting-table>
          </div>
        </b-card>
      </b-col>
      <b-col lg="6">
        <b-card action-collapse>
          <b-card-title> {{ $t("Teams") }}</b-card-title>
          <div class="d-block">
            <div class="float-right">
              <b-button
                variant="primary"
                v-b-toggle.sidebar-team
                type="button"
                class="btn btn-primary right-top"
                @click="team = {}"
              >
                {{ $t("Add") }}
              </b-button>
            </div>
          </div>
          <div class="d-flex justify-content-start align-items-center">
            <setting-table
              class="w-100"
              :table-columns="[
                { key: 'id', label: '#' },
                { key: 'name', label: $t('Team'), sortable: true },
                { key: 'club.name', label: $t('Club'), sortable: true },
                { key: 'actions', label: $t('Action') },
              ]"
              :table-items="getTeams"
              @goSidebar="goSidebarTeam($event)"
              :delete-data="deleteTeam"
              table-id="teamTable"
              ref="TeamTable"
            ></setting-table>
          </div>
        </b-card>
      </b-col>
      <b-col lg="6">
        <b-card action-collapse>
          <b-card-title> {{ $t("Leagues") }}</b-card-title>
          <div class="d-block">
            <div class="float-right">
              <b-button
                variant="primary"
                v-b-toggle.sidebar-league
                type="button"
                class="btn btn-primary right-top"
                @click="league = {}"
              >
                {{ $t("Add") }}
              </b-button>
            </div>
          </div>
          <div class="d-flex justify-content-start align-items-center">
            <setting-table
              class="w-100"
              :table-columns="[
                { key: 'id', label: '#' },
                { key: 'name', label: $t('League'), sortable: true },
                { key: 'actions', label: $t('Action') },
              ]"
              :table-items="getLeagues"
              @goSidebar="goSidebarLeague($event)"
              :delete-data="deleteLeague"
              table-id="leagueTable"
              ref="LeagueTable"
            ></setting-table>
          </div>
        </b-card>
      </b-col>
      <b-col lg="6">
        <b-card action-collapse>
          <b-card-title> {{ $t("Positions") }}</b-card-title>
          <div class="d-block">
            <div class="float-right">
              <b-button
                variant="primary"
                v-b-toggle.sidebar-position
                type="button"
                class="btn btn-primary right-top"
                @click="position = {}"
              >
                {{ $t("Add") }}
              </b-button>
            </div>
          </div>
          <div class="d-flex justify-content-start align-items-center">
            <setting-table
              class="w-100"
              :table-columns="[
                { key: 'id', label: '#' },
                { key: 'name', label: $t('Position'), sortable: true },
                { key: 'actions', label: $t('Action') },
              ]"
              :table-items="getPositions"
              @goSidebar="goSidebarPosition($event)"
              :delete-data="deletePosition"
              table-id="positionTable"
              ref="PositionTable"
            ></setting-table>
          </div>
        </b-card>
      </b-col>
      <b-col lg="6">
        <b-card action-collapse>
          <b-card-title> {{ $t("Clubs") }}</b-card-title>
          <div class="d-block">
            <div class="float-right">
              <b-button
                variant="primary"
                v-b-toggle.sidebar-club
                type="button"
                class="btn btn-primary right-top"
                @click="club = {}"
              >
                {{ $t("Add") }}
              </b-button>
            </div>
          </div>
          <div class="d-flex justify-content-start align-items-center">
            <setting-table
              class="w-100"
              :table-columns="[
                { key: 'id', label: '#' },
                { key: 'name', label: $t('Club'), sortable: true },
                { key: 'actions', label: $t('Action') },
              ]"
              :table-items="getClubs"
              @goSidebar="goSidebarClub($event)"
              :delete-data="deleteClub"
              table-id="clubTable"
              ref="ClubTable"
            ></setting-table>
          </div>
        </b-card>
      </b-col>
      <b-col lg="6">
        <b-card action-collapse>
          <b-card-title> {{ $t("Locations") }}</b-card-title>
          <div class="d-block">
            <div class="float-right">
              <b-button
                variant="primary"
                v-b-toggle.sidebar-location
                type="button"
                class="btn btn-primary right-top"
                @click="location = {}"
              >
                {{ $t("Add") }}
              </b-button>
            </div>
          </div>
          <div class="d-flex justify-content-start align-items-center">
            <setting-table
              class="w-100"
              :table-columns="[
                { key: 'id', label: '#' },
                { key: 'name', label: $t('Location'), sortable: true },
                { key: 'actions', label: $t('Action') },
              ]"
              :table-items="getLocations"
              @goSidebar="goSidebarLocation($event)"
              :delete-data="deleteLocation"
              table-id="locationTable"
              ref="LocationTable"
            ></setting-table>
          </div>
        </b-card>
      </b-col>
      <b-col lg="6">
        <b-card action-collapse>
          <b-card-title> {{ $t("Departments") }}</b-card-title>
          <div class="d-block">
            <div class="float-right">
              <b-button
                variant="primary"
                v-b-toggle.sidebar-department
                type="button"
                class="btn btn-primary right-top"
                @click="department = {}"
              >
                {{ $t("Add") }}
              </b-button>
            </div>
          </div>
          <div class="d-flex justify-content-start align-items-center">
            <setting-table
              class="w-100"
              :table-columns="[
                { key: 'id', label: '#' },
                { key: 'name', label: $t('Department'), sortable: true },
                { key: 'actions', label: $t('Action') },
              ]"
              :table-items="getDepartments"
              @goSidebar="goSidebarDepartment($event)"
              :delete-data="deleteDepartment"
              table-id="departmentTable"
              ref="DepartmentTable"
            ></setting-table>
          </div>
        </b-card>
      </b-col>
      <b-col lg="6">
        <b-card action-collapse>
          <b-card-title> {{ $t("Jobs") }}</b-card-title>
          <div class="d-block">
            <div class="float-right">
              <b-button
                variant="primary"
                v-b-toggle.sidebar-job
                type="button"
                class="btn btn-primary right-top"
                @click="job = {}"
              >
                {{ $t("Add") }}
              </b-button>
            </div>
          </div>
          <div class="d-flex justify-content-start align-items-center">
            <setting-table
              class="w-100"
              :table-columns="[
                { key: 'id', label: '#' },
                { key: 'name', label: $t('Job'), sortable: true },
                { key: 'actions', label: $t('Action') },
              ]"
              :table-items="getJobs"
              @goSidebar="goSidebarJob($event)"
              :delete-data="deleteJob"
              table-id="jobTable"
              ref="JobTable"
            ></setting-table>
          </div>
        </b-card>
      </b-col>
      <b-col lg="6">
        <b-card action-collapse>
          <b-card-title> {{ $t("Skills Items") }}</b-card-title>
          <div class="d-block">
            <div class="float-right">
              <b-button
                variant="primary"
                v-b-toggle.sidebar-ability-item
                type="button"
                class="btn btn-primary right-top"
                @click="abilityItem = {}"
              >
                {{ $t("Add") }}
              </b-button>
            </div>
          </div>
          <div class="d-flex justify-content-start align-items-center">
            <setting-table
              class="w-100"
              :table-columns="[
                { key: 'id', label: '#' },
                { key: 'ability.name', label: $t('Category') },
                { key: 'name', label: $t('Skill Item') },
                { key: 'actions', label: $t('Action') },
              ]"
              :table-items="getAbilityItems"
              @goSidebar="goSidebarAbilityItem($event)"
              :delete-data="deleteAbilityItem"
              table-id="abilityItemTable"
              ref="AbilityItemTable"
            ></setting-table>
          </div>
        </b-card>
      </b-col>
      <!-- Test Items -->
      <b-col lg="6">
        <b-card action-collapse>
          <b-card-title> {{ $t("Test Items") }}</b-card-title>
          <div class="d-block">
            <div class="float-right">
              <b-button
                variant="primary"
                v-b-toggle.sidebar-test-item
                type="button"
                class="btn btn-primary right-top"
                @click="testItem = {}"
              >
                {{ $t("Add") }}
              </b-button>
            </div>
          </div>
          <div class="d-flex justify-content-start align-items-center">
            <setting-table
              class="w-100"
              :table-columns="[
                { key: 'id', label: '#' },
                { key: 'name', label: $t('Test Item') },
                { key: 'actions', label: $t('Action') },
              ]"
              :table-items="getTestItems"
              @goSidebar="goSidebarTestItem($event)"
              :delete-data="deleteTestItem"
              table-id="testItemTable"
              ref="TestItemTable"
            ></setting-table>
          </div>
        </b-card>
      </b-col>
      <!-- Learning Outcome -->
      <b-col lg="6">
        <b-card action-collapse>
          <b-card-title> {{ $t("Training Exercices") }}</b-card-title>
          <div class="d-block">
            <div class="float-right">
              <b-button
                variant="primary"
                v-b-toggle.sidebar-learning-outcome
                type="button"
                class="btn btn-primary right-top"
                @click="learningOutcome = {}"
              >
                {{ $t("Add") }}
              </b-button>
            </div>
          </div>
          <div class="d-flex justify-content-start align-items-center">
            <setting-table
              class="w-100"
              :table-columns="[
                { key: 'id', label: '#' },
                { key: 'learningOutcomeCategory.name', label: $t('Category') },
                { key: 'name', label: $t('Training Exercice') },
                { key: 'actions', label: $t('Action') },
              ]"
              :table-items="getLearningOutcomes"
              @goSidebar="goSidebarLearningOutcome($event)"
              :delete-data="deleteLearningOutcome"
              :un-delete="unDeleteLearningOutcome"
              table-id="learningOutcomeTable"
              ref="LearningOutcomeTable"
            ></setting-table>
          </div>
        </b-card>
      </b-col>
      <!-- Learning Outcome Category -->
      <b-col lg="6">
        <b-card action-collapse>
          <b-card-title> {{ $t("Training Exercice Categories") }}</b-card-title>
          <div class="d-block">
            <div class="float-right">
              <b-button
                variant="primary"
                v-b-toggle.sidebar-learning-outcome-category
                type="button"
                class="btn btn-primary right-top"
                @click="learningOutcomeCategory = {}"
              >
                {{ $t("Add") }}
              </b-button>
            </div>
          </div>
          <div class="d-flex justify-content-start align-items-center">
            <setting-table
              class="w-100"
              :table-columns="[
                { key: 'id', label: '#' },
                { key: 'name', label: $t('Category') },
                { key: 'actions', label: $t('Action') },
              ]"
              :table-items="getLearningOutcomeCategories"
              @goSidebar="goSidebarLearningOutcomeCategory($event)"
              :delete-data="deleteLearningOutcomeCategory"
              table-id="learningOutcomeCategoryTable"
              ref="LearningOutcomeCategoryTable"
            ></setting-table>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import Ripple from "vue-ripple-directive"
  import vSelect from "vue-select"

  import { toRefs, ref } from "@vue/composition-api"
  import settingTable from "./settingTable"
  import settingsComponent from "./settingsComponent"
  import SettingPlugin from "./SettingPlugin"
  import seasonSidebar from "./season/seasonSidebar.vue"
  import teamSidebar from "./team/teamSidebar.vue"
  import leagueSidebar from "./league/leagueSidebar.vue"
  import positionSidebar from "./position/positionSidebar.vue"
  import clubSidebar from "./club/clubSidebar.vue"
  import locationSidebar from "./location/locationSidebar.vue"
  import departmentSidebar from "./department/departmentSidebar.vue"
  import jobSidebar from "./job/jobSidebar.vue"
  import abilityItemSidebar from "./abilityItem/abilityItemSidebar.vue"
  import testItemSidebar from "./testItem/testItemSidebar.vue"
  import learningOutcomeSidebar from "./training/learningOutcomeSidebar.vue"
  import learningOutcomeCategorySidebar from "./training/learningOutcomeCategorySidebar.vue"

  import {
    seasonService,
    teamService,
    leagueService,
    positionService,
    clubService,
    locationService,
    departmentService,
    jobService,
    abilityItemService,
    testItemService,
    learningOutcomeService,
    learningOutcomeCategoryService,
  } from "@/services"

  export default {
    components: {
      settingsComponent,
      settingTable,
      vSelect,
      seasonSidebar,
      teamSidebar,
      leagueSidebar,
      positionSidebar,
      clubSidebar,
      locationSidebar,
      departmentSidebar,
      jobSidebar,
      abilityItemSidebar,
      testItemSidebar,
      learningOutcomeSidebar,
      learningOutcomeCategorySidebar,
    },
    directives: {
      Ripple,
    },
    data() {
      return {
        season: null,
        team: null,
        league: null,
        position: null,
        club: null,
        location: null,
        department: null,
        job: null,
        abilityItem: null,
        testItem: null,
        learningOutcome: null,
        learningOutcomeCategory: null,
        isSeasonActive: false,
        isTeamActive: false,
        isLeagueActive: false,
        isPositionActive: false,
        isClubActive: false,
        isLocationActive: false,
        isDepartmentActive: false,
        isJobActive: false,
        isAbilityItemActive: false,
        isTestItemActive: false,
        isLearningOutcomeActive: false,
        isLearningOutcomeCategoryActive: false,
        filter: "delete_at",
      }
    },
    methods: {
      goSidebarSeason(seasonData) {
        this.season = seasonData
        this.isSeasonActive = true
      },
      goSidebarTeam(teamData) {
        console.log(teamData)
        this.team = teamData
        this.isTeamActive = true
      },
      goSidebarLeague(leagueData) {
        this.league = leagueData
        this.isLeagueActive = true
      },
      goSidebarPosition(positionData) {
        this.position = positionData
        this.isPositionActive = true
      },
      goSidebarClub(clubData) {
        this.club = clubData
        this.isClubActive = true
      },
      goSidebarLocation(locationData) {
        this.location = locationData
        this.isLocationActive = true
      },
      goSidebarDepartment(departmentData) {
        this.department = departmentData
        this.isDepartmentActive = true
      },
      goSidebarJob(data) {
        this.job = data
        this.isJobActive = true
      },
      goSidebarAbilityItem(data) {
        this.abilityItem = data
        this.isAbilityItemActive = true
      },
      goSidebarTestItem(data) {
        this.testItem = data
        this.isTestItemActive = true
      },
      goSidebarLearningOutcome(data) {
        this.learningOutcome = data
        this.isLearningOutcomeActive = true
      },
      goSidebarLearningOutcomeCategory(data) {
        this.learningOutcomeCategory = data
        this.isLearningOutcomeCategoryActive = true
      },
    },
    setup(props, { emit, root }) {
      const {
        getSeasons,
        getTeams,
        getLeagues,
        getPositions,
        getClubs,
        getLocations,
        getDepartments,
        getJobs,
        getAbilityItems,
        getTestItems,
        getLearningOutcomes,
        getLearningOutcomeCategories,
      } = SettingPlugin(toRefs(props), emit)

      const SeasonTable = ref(null)
      const TeamTable = ref(null)
      const LeagueTable = ref(null)
      const PositionTable = ref(null)
      const ClubTable = ref(null)
      const LocationTable = ref(null)
      const DepartmentTable = ref(null)
      const JobTable = ref(null)
      const AbilityItemTable = ref(null)
      const TestItemTable = ref(null)
      const LearningOutcomeTable = ref(null)
      const LearningOutcomeCategoryTable = ref(null)

      const seasonSer = new seasonService()
      const teamSer = new teamService()
      const leagueSer = new leagueService()
      const positionSer = new positionService()
      const clubSer = new clubService()
      const locationSer = new locationService()
      const departmentSer = new departmentService()
      const jobSer = new jobService()
      const abilityItemSer = new abilityItemService()
      const testItemSer = new testItemService()
      const learningOutcomeSer = new learningOutcomeService()
      const learningOutcomeCategorySer = new learningOutcomeCategoryService()

      // SEASON
      const submitSeason = (seasonData) => {
        seasonSer.event({ data: seasonData }).then((res) => {
          SeasonTable.value.$refs.Table.refresh()
        })
      }
      const updateSeason = (seasonData) => {
        seasonSer
          .event({
            data: seasonData,
            dataId: seasonData.id,
          })
          .then((res) => {
            SeasonTable.value.$refs.Table.refresh()
          })
      }
      const deleteSeason = (seasonDataId) => {
        seasonSer.event({ dataId: seasonDataId }).then((res) => {
          SeasonTable.value.$refs.Table.refresh()
        })
      }

      // TEAM
      const submitTeam = (teamData) => {
        teamSer.event({ data: teamData }).then((res) => {
          TeamTable.value.$refs.Table.refresh()
        })
      }
      const updateTeam = (teamData) => {
        teamSer
          .event({
            data: teamData,
            dataId: teamData.id,
          })
          .then((res) => {
            TeamTable.value.$refs.Table.refresh()
          })
      }
      const deleteTeam = (teamDataId) => {
        teamSer.event({ dataId: teamDataId }).then((res) => {
          TeamTable.value.$refs.Table.refresh()
        })
      }

      // LEAGUE
      const submitLeague = (leagueData) => {
        leagueSer.event({ data: leagueData }).then((res) => {
          LeagueTable.value.$refs.Table.refresh()
        })
      }
      const updateLeague = (leagueData) => {
        leagueSer
          .event({
            data: leagueData,
            dataId: leagueData.id,
          })
          .then((res) => {
            LeagueTable.value.$refs.Table.refresh()
          })
      }
      const deleteLeague = (leagueDataId) => {
        leagueSer.event({ dataId: leagueDataId }).then((res) => {
          LeagueTable.value.$refs.Table.refresh()
        })
      }

      // POSİTİON
      const submitPosition = (positionData) => {
        positionSer.event({ data: positionData }).then((res) => {
          PositionTable.value.$refs.Table.refresh()
        })
      }
      const updatePosition = (positionData) => {
        positionSer
          .event({
            data: positionData,
            dataId: positionData.id,
          })
          .then((res) => {
            PositionTable.value.$refs.Table.refresh()
          })
      }
      const deletePosition = (positionDataId) => {
        positionSer.event({ dataId: positionDataId }).then((res) => {
          PositionTable.value.$refs.Table.refresh()
        })
      }

      // CLUB
      const submitClub = (clubData) => {
        clubSer.event({ data: clubData }).then((res) => {
          ClubTable.value.$refs.Table.refresh()
        })
      }
      const updateClub = (clubData) => {
        clubSer
          .event({
            data: clubData,
            dataId: clubData.id,
          })
          .then((res) => {
            ClubTable.value.$refs.Table.refresh()
          })
      }
      const deleteClub = (clubDataId) => {
        clubSer.event({ dataId: clubDataId }).then((res) => {
          ClubTable.value.$refs.Table.refresh()
        })
      }

      // LOCATİON
      const submitLocation = (locationData) => {
        locationSer.event({ data: locationData }).then((res) => {
          LocationTable.value.$refs.Table.refresh()
        })
      }
      const updateLocation = (locationData) => {
        locationSer
          .event({
            data: locationData,
            dataId: locationData.id,
          })
          .then((res) => {
            LocationTable.value.$refs.Table.refresh()
          })
      }
      const deleteLocation = (ocationDataId) => {
        locationSer.event({ dataId: ocationDataId }).then((res) => {
          LocationTable.value.$refs.Table.refresh()
        })
      }

      // DEPARTMENT
      const submitDepartment = (departmentData) => {
        departmentSer.event({ data: departmentData }).then((res) => {
          DepartmentTable.value.$refs.Table.refresh()
        })
      }
      const updateDepartment = (departmentData) => {
        departmentSer
          .event({
            data: departmentData,
            dataId: departmentData.id,
          })
          .then((res) => {
            DepartmentTable.value.$refs.Table.refresh()
          })
      }
      const deleteDepartment = (departmentDataId) => {
        departmentSer.event({ dataId: departmentDataId }).then((res) => {
          DepartmentTable.value.$refs.Table.refresh()
        })
      }

      // JOB
      const submitJob = (data) => {
        jobSer.event({ data: data }).then((res) => {
          JobTable.value.$refs.Table.refresh()
        })
      }
      const updateJob = (data) => {
        jobSer
          .event({
            data: data,
            dataId: data.id,
          })
          .then((res) => {
            JobTable.value.$refs.Table.refresh()
          })
      }
      const deleteJob = (dataId) => {
        jobSer.event({ dataId: dataId }).then((res) => {
          JobTable.value.$refs.Table.refresh()
        })
      }

      // AbilityItem
      const submitAbilityItem = (data) => {
        abilityItemSer.event({ data: data }).then((res) => {
          AbilityItemTable.value.$refs.Table.refresh()
        })
      }
      const updateAbilityItem = (data) => {
        abilityItemSer
          .event({
            data: data,
            dataId: data.id,
          })
          .then((res) => {
            AbilityItemTable.value.$refs.Table.refresh()
          })
      }
      const deleteAbilityItem = (dataId) => {
        abilityItemSer.event({ dataId: dataId }).then((res) => {
          AbilityItemTable.value.$refs.Table.refresh()
        })
      }

      // Test Item
      const submitTestItem = (data) => {
        testItemSer.event({ data: data }).then((res) => {
          TestItemTable.value.$refs.Table.refresh()
        })
      }
      const updateTestItem = (data) => {
        testItemSer
          .event({
            data: data,
            dataId: data.id,
          })
          .then((res) => {
            TestItemTable.value.$refs.Table.refresh()
          })
      }
      const deleteTestItem = (dataId) => {
        testItemSer.event({ dataId: dataId }).then((res) => {
          TestItemTable.value.$refs.Table.refresh()
        })
      }

      // Learning Outcome
      const submitLearningOutcome = (data) => {
        learningOutcomeSer.event({ data: data }).then((res) => {
          LearningOutcomeTable.value.$refs.Table.refresh()
        })
      }
      const updateLearningOutcome = (data) => {
        learningOutcomeSer
          .event({
            data: data,
            dataId: data.id,
          })
          .then((res) => {
            LearningOutcomeTable.value.$refs.Table.refresh()
          })
      }
      const deleteLearningOutcome = (dataId) => {
        learningOutcomeSer.event({ dataId: dataId }).then((res) => {
          LearningOutcomeTable.value.$refs.Table.refresh()
        })
      }
      const unDeleteLearningOutcome = (dataId) => {
        learningOutcomeSer.unDelete({ dataId: dataId }).then((res) => {
          LearningOutcomeTable.value.$refs.Table.refresh()
        })
      }

      // Learning Outcome Category
      const submitLearningOutcomeCategory = (data) => {
        learningOutcomeCategorySer.event({ data: data }).then((res) => {
          LearningOutcomeCategoryTable.value.$refs.Table.refresh()
          LearningOutcomeTable.value.$refs.Table.refresh()
        })
      }
      const updateLearningOutcomeCategory = (data) => {
        learningOutcomeCategorySer
          .event({
            data: data,
            dataId: data.id,
          })
          .then((res) => {
            LearningOutcomeCategoryTable.value.$refs.Table.refresh()
            LearningOutcomeTable.value.$refs.Table.refresh()
          })
      }
      const deleteLearningOutcomeCategory = (dataId) => {
        learningOutcomeCategorySer.event({ dataId: dataId }).then((res) => {
          LearningOutcomeCategoryTable.value.$refs.Table.refresh()
          LearningOutcomeTable.value.$refs.Table.refresh()
        })
      }

      return {
        SeasonTable,
        submitSeason,
        updateSeason,
        deleteSeason,
        getSeasons,

        TeamTable,
        submitTeam,
        updateTeam,
        deleteTeam,
        getTeams,

        LeagueTable,
        submitLeague,
        updateLeague,
        deleteLeague,
        getLeagues,

        PositionTable,
        submitPosition,
        updatePosition,
        deletePosition,
        getPositions,

        ClubTable,
        submitClub,
        updateClub,
        deleteClub,
        getClubs,

        LocationTable,
        submitLocation,
        updateLocation,
        deleteLocation,
        getLocations,

        DepartmentTable,
        submitDepartment,
        updateDepartment,
        deleteDepartment,
        getDepartments,

        JobTable,
        submitJob,
        updateJob,
        deleteJob,
        getJobs,

        AbilityItemTable,
        submitAbilityItem,
        updateAbilityItem,
        deleteAbilityItem,
        getAbilityItems,

        TestItemTable,
        submitTestItem,
        updateTestItem,
        deleteTestItem,
        getTestItems,

        LearningOutcomeTable,
        submitLearningOutcome,
        updateLearningOutcome,
        deleteLearningOutcome,
        unDeleteLearningOutcome,
        getLearningOutcomes,

        LearningOutcomeCategoryTable,
        submitLearningOutcomeCategory,
        updateLearningOutcomeCategory,
        deleteLearningOutcomeCategory,
        getLearningOutcomeCategories,
      }
    },
  }
</script>

<style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }

  .right-top {
    position: absolute;
    right: 2rem;
    top: 1rem;
  }
</style>

<style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
</style>
