<template>
  <!-- Table Container Card -->
  <b-card>
    <b-row>
      <b-col>
        <span> team Health here </span>
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
  import { BCard, BRow, BCol } from "bootstrap-vue"
  export default {
    components: {
      BCard,
      BRow,
      BCol,
    },
    setup(props, { root }) {
      return {}
    },
  }
</script>
