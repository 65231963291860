<template>
  <div>
    <b-sidebar
      id="sidebar-task-handler"
      sidebar-class="sidebar-lg"
      :visible="isTaskHandlerSidebarActive"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="(val) => $emit('update:is-task-handler-sidebar-active', val)"
      @hidden="clearForm"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-end align-items-end content-sidebar-header px-2 py-1">
          <div class="float-right">
            <feather-icon
              v-show="taskLocal.id"
              icon="TrashIcon"
              class="cursor-pointer"
              @click="
                $emit('remove-task')
                hide()
              "
            />
            <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
          </div>
        </div>

        <!-- Body -->
        <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
          <!-- Form -->
          <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
            <!-- Title -->
            <validation-provider #default="validationContext" name="Bildirim Başlığı" rules="required">
              <b-form-group label="Bildirim Başlığı" label-for="task-title">
                <b-form-input
                  disabled
                  id="task-title"
                  v-model="taskLocal.title"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Task Title"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- due Date -->
            <validation-provider #default="validationContext" name="Bildirim tarihi">
              <b-form-group label="Bildirim tarihi" label-for="due-date">
                <flat-pickr disabled v-model="taskLocal.created_at" class="form-control" />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Description -->
            <b-form-group label="Description" label-for="task-content">
              <b-form-textarea disabled v-model="taskLocal.content" />
            </b-form-group>
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
  import { BSidebar, BForm, BFormGroup, BFormInput, BAvatar, BButton, BFormInvalidFeedback } from "bootstrap-vue"
  import vSelect from "vue-select"
  import flatPickr from "vue-flatpickr-component"
  import Ripple from "vue-ripple-directive"
  import { ValidationProvider, ValidationObserver } from "vee-validate"
  import { required, email, url } from "@validations"
  import { avatarText } from "@core/utils/filter"
  import formValidation from "@core/comp-functions/forms/form-validation"
  import { toRefs } from "@vue/composition-api"
  import { quillEditor } from "vue-quill-editor"
  import useTaskHandler from "./useTaskHandler"

  export default {
    components: {
      // BSV
      BButton,
      BSidebar,
      BForm,
      BFormGroup,
      BFormInput,
      BAvatar,
      BFormInvalidFeedback,

      // 3rd party packages
      vSelect,
      flatPickr,
      quillEditor,

      // Form Validation
      ValidationProvider,
      ValidationObserver,
    },
    directives: {
      Ripple,
    },
    model: {
      prop: "isTaskHandlerSidebarActive",
      event: "update:is-task-handler-sidebar-active",
    },
    props: {
      isTaskHandlerSidebarActive: {
        type: Boolean,
        required: true,
      },
      task: {
        type: Object,
        required: true,
      },
      clearTaskData: {
        type: Function,
        required: true,
      },
    },
    data() {
      return {
        required,
        email,
        url,
      }
    },
    setup(props, { emit }) {
      const {
        taskLocal,
        resetTaskLocal,

        // UI
        assigneeOptions,
        onSubmit,
        tagOptions,
        resolveAvatarVariant,
      } = useTaskHandler(toRefs(props), emit)

      const { refFormObserver, getValidationState, resetForm, clearForm } = formValidation(
        resetTaskLocal,
        props.clearTaskData,
      )

      return {
        // Add New
        taskLocal,
        onSubmit,
        assigneeOptions,
        tagOptions,

        // Form Validation
        resetForm,
        clearForm,
        refFormObserver,
        getValidationState,

        // UI
        resolveAvatarVariant,

        // Filter/Formatter
        avatarText,
      }
    },
  }
</script>

<style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
  @import "@core/scss/vue/libs/vue-flatpicker.scss";
  @import "@core/scss/vue/libs/quill.scss";
</style>

<style lang="scss" scoped>
  @import "@core/scss/base/bootstrap-extended/include";

  .assignee-selector {
    ::v-deep .vs__dropdown-toggle {
      padding-left: 0;
    }
  }

  #quil-content ::v-deep {
    > .ql-container {
      border-bottom: 0;
    }

    + #quill-toolbar {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom-left-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
    }
  }
</style>
