<template>
  <div class="d-flex flex-column justify-content-center">
    <!-- BODY -->
    <b-card>
      <h4>{{ $t("Add New Staff") }}</h4>
    </b-card>
    <div class="d-flex align-items-center flex-row">
      <div class="col-12">
        <b-card>
          <validation-observer #default="{ handleSubmit }" ref="formTrigger" :key="1">
            <!-- Form -->
            <b-form v-if="form" class="p-2" @submit.prevent="handleSubmit(submit())">
              <b-row>
                <b-col lg="6">
                  <validation-provider #default="{ errors }" name="firstName" rules="required">
                    <b-form-group :label="$t('First Name')" label-for="firstName">
                      <b-input
                        id="firstName"
                        :placeholder="$t('Please enter first name')"
                        v-model="form.firstName"
                        :state="errors.length > 0 ? false : null"
                      />

                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col lg="6">
                  <validation-provider #default="{ errors }" name="lastName" rules="required">
                    <b-form-group :label="$t('Last Name')" label-for="lastName">
                      <b-input
                        id="lastName"
                        :placeholder="$t('Please enter last name')"
                        v-model="form.lastName"
                        :state="errors.length > 0 ? false : null"
                      />

                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- Staff Department -->
                <b-col lg="6">
                  <!-- Staff User -->
                  <validation-provider #default="{ errors }" name="department" rules="required">
                    <b-form-group :label="$t('Staff Department')" label-for="staffRole">
                      <v-select
                        id="staffRole"
                        :placeholder="$t('Please select a department')"
                        v-model="form.department"
                        :state="errors.length > 0 ? false : null"
                        :options="departments"
                        :reduce="(option) => option.id"
                        :getOptionLabel="(option) => option.name"
                      >
                      </v-select>

                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col lg="6">
                  <!-- Staff Role -->
                  <validation-provider #default="{ errors }" name="job" rules="required">
                    <b-form-group :label="$t('Staff Job')" label-for="staffRole">
                      <v-select
                        id="staffRole"
                        :placeholder="$t('Please select a job')"
                        v-model="form.job"
                        :state="errors.length > 0 ? false : null"
                        :options="jobs"
                        :reduce="(option) => option.id"
                        :getOptionLabel="(option) => option.name"
                      >
                      </v-select>

                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>

              <!-- Form Actions -->
              <div class="d-flex mt-2 float-right">
                <b-button variant="primary" type="submit">{{ $t("Add") }}</b-button>
              </div>
            </b-form>
          </validation-observer>
        </b-card>
      </div>
    </div>
  </div>
</template>
<script>
  import { ValidationProvider, ValidationObserver } from "vee-validate"
  import { ref, onMounted, computed } from "@vue/composition-api"
  import { required } from "@validations"
  import formValidation from "@core/comp-functions/forms/form-validation"
  import Ripple from "vue-ripple-directive"
  import flatPickr from "vue-flatpickr-component"
  import vSelect from "vue-select"
  import seasonService from "@/services/seasonService"
  import teamService from "@/services/teamService"
  import staffService from "@/services/staffService"
  import http from "@/libs/axios"
  import store from "@/store"
  import { useRouter } from "@core/utils/utils"
  import { Turkish } from "flatpickr/dist/l10n/tr.js"
  import { French } from "flatpickr/dist/l10n/fr.js"
  import countries from "@/libs/country.json"
  import SelectableDate from "@/@core/components/global-components/SelectableDate"

  export default {
    components: {
      flatPickr,
      vSelect,
      countries,
      SelectableDate,

      ValidationProvider,
      ValidationObserver,
    },

    setup(props, { emit, root }) {
      const { getValidationState } = formValidation()
      const { router, route } = useRouter()
      const passwordFieldType = ref("password")
      const formTrigger = ref(null)
      const seasons = ref([])
      const jobs = ref([])
      const users = ref([])
      const departments = ref([])
      const user = JSON.parse(localStorage.getItem("userData"))

      const form = ref({
        firstName: null,
        lastName: null,
        nation: null,
        nationId: null,
        birthDate: "01-01-1978",
        email: null,
        phoneNumber: null,
        status: 1,
        season: null,
        team: null,
        department: null,
        job: null,
        jobEndDate: "01-01-2025",
      })

      const seasonSer = new seasonService()
      const staffSer = new staffService()

      onMounted(async () => {
        //await getSeasons();
        await getJobs()
        //await getUsers();
        await getDepartments()
      })

      const getSeasons = () => {
        seasonSer
          .get()
          .then((response) => {
            seasons.value = response.data
          })
          .catch((error) => {
            console.log(error)
            root.toast("Error fetching season list", "AlertTriangleIcon", "danger")
          })
      }

      const teams = computed(() => {
        const teams = store.getters["app/teams"]
        const localTeams = JSON.parse(localStorage.getItem("teams"))
        if (teams.length > 0) {
          return teams
        } else {
          return localTeams
        }
        //
      })

      const getJobs = () => {
        http
          .get(`/job`)
          .then((res) => {
            jobs.value = res.data
          })
          .catch((error) => console.log(error))
      }

      const getUsers = () => {
        http
          .get(`/user`)
          .then((res) => {
            users.value = res.data
          })
          .catch((error) => reject(error))
      }

      const getDepartments = () => {
        http
          .get(`/department`)
          .then((res) => {
            departments.value = res.data
          })
          .catch((error) => console.log(error))
      }

      const togglePassword = () => {
        passwordFieldType.value = passwordFieldType.value === "password" ? "text" : "password"
      }

      const passwordToggleIcon = computed(() => {
        return passwordFieldType.value === "password" ? "EyeIcon" : "EyeOffIcon"
      })

      const submit = () => {
        formTrigger.value.validate().then((success) => {
          if (success) {
            staffSer.add(form.value).then((res) => {
              router.push({
                name: "edit-staff",
                params: {
                  staffID: res.data.id,
                },
              })
            })
          }
        })
      }

      return {
        getValidationState,
        togglePassword,
        passwordFieldType,
        passwordToggleIcon,
        seasons,
        teams,
        users,
        jobs,
        departments,
        formTrigger,
        form,
        submit,
        user,
        Turkish,
        French,

        countries,
      }
    },
  }
</script>

<style lang="scss">
  @import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
