<template>
  <div>
    <div class="alert alert-warning p-2" v-if="attends == null">
      <p>{{ $t("Data Waiting..") }}</p>
    </div>
    <div class="alert alert-danger p-2" v-if="attends == false">
      <p>{{ $t("No records found") }}</p>
    </div>
    <div class="table-responsive" v-if="attends && attends.length > 0">
      <div>
        <table class="table table-bordered">
          <thead>
            <tr>
              <td colspan="0">
                <div class="font-weight-bold" style="line-height: 3">{{ $t("Player") }}</div>
              </td>
              <td style="">
                <div class="d-flex flex-column font-weight-bold">
                  <span>{{ $t("Serp") }}</span>
                </div>
              </td>
              <td style="">
                <div class="d-flex flex-column font-weight-bold">
                  <span>{{ $t("General Impression") }}</span>
                </div>
              </td>
              <td>
                <div class="d-flex flex-column font-weight-bold">
                  <span>{{ $t("Additional Comments") }}</span>
                </div>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(attend, index) in attends" :key="index">
              <td>
                <div class="d-flex align-items-center justify-content-start">
                  <b-avatar
                    size="40"
                    :text="avatarText(attend.player.name)"
                    :src="attend.player.avatar ? attend.player.avatar : null"
                  ></b-avatar>
                  <div class="d-block ml-1 mt-0">
                    <span class="mt-0 text-left">
                      {{ attend.player.name }}
                      <br />
                      {{ $t(attend.player.position.name) }}
                    </span>
                  </div>
                </div>
              </td>
              <td>
                <div class="d-flex align-items-center justify-content-center">
                  <span v-text="attend.playerSerpScore"></span>
                </div>
              </td>
              <td class>
                <div class="d-flex">
                  <div class="mr-1">
                    {{ attend.trainerSerpScore }}
                  </div>
                </div>
              </td>
              <td>
                <span v-text="attend.trainerComment"></span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
  import vSelect from "vue-select"
  import { avatarText } from "@core/utils/filter"
  import BCardActions from "@core/components/b-card-actions/BCardActions.vue"
  import { ref, onBeforeMount } from "@vue/composition-api"
  import axios from "@axios"
  import { useRouter } from "@core/utils/utils"

  export default {
    components: { vSelect, BCardActions },
    props: {},
    setup() {
      const attends = ref(null)
      const { route, router } = useRouter()

      onBeforeMount(() => {
        // let attendsUrl = "https://run.mocky.io/v3/237aeb3c-a024-4b5b-ab48-abfb15e25397";
        let attendsUrl = `/training-attendant/training/${router.currentRoute.params.trainingId}`
        axios
          .get(attendsUrl)
          .then((resp) => {
            attends.value = resp.data
          })
          .catch((err) => {
            attends.value = false
          })
      })

      return { avatarText, attends }
    },
  }
</script>
