<template>
  <div>
    <staff-duty-history-new
      :staffData="staffData"
      :teams="teams"
      :seasons="seasons"
      :departments="departments"
      :jobs="jobs"
      :is-add-duty-active.sync="isAddDutyActive"
      :submit-history="submitHistory"
      :update-history="updateHistory"
      :staff-duty="duty"
    />

    <div class="my-2">
      <b-row>
        <b-col lg="12">
          <div v-if="staffData.careers.length > 0">
            <b-table
              ref="selectableTable"
              selectable
              sticky-header="400px"
              :items="staffData.careers"
              :fields="fields"
              class="mb-0"
            >
              <template #cell(team)="data">
                <span v-if="data.item.team">{{ data.item.team.name }}<br />{{ data.item.team.club.name }}</span>
              </template>
              <template #cell(season)="data">
                {{ data.item.season.name }}
              </template>
              <template #cell(department)="data">
                {{ data.item.department.name }}
              </template>
              <template #cell(job)="data">
                {{ data.item.job.name }}
              </template>
              <template #cell(jobEndDate)="data">
                {{ data.item.jobEndDate | longDate }}
              </template>
              <!-- Column: Actions -->
              <template #cell(actions)="data">
                <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
                  <template #button-content>
                    <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
                  </template>
                  <b-dropdown-item @click="goSidebar(data.item)">
                    <feather-icon icon="EditIcon" />
                    <span class="align-middle ml-50">{{ $t("Edit") }}</span>
                  </b-dropdown-item>
                </b-dropdown>
              </template>
            </b-table>
          </div>

          <div v-else>
            <span> {{ $t("No records found") }} </span>
          </div>
        </b-col>
      </b-row>
    </div>

    <b-button
      variant="primary"
      v-b-toggle.sidebar-add-cont
      type="button"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      @click=";(duty = {}), (duty.staff = staffData.id)"
      >{{ $t("Add New") }}</b-button
    >
  </div>
</template>

<script>
  import flatPickr from "vue-flatpickr-component"
  import vSelect from "vue-select"
  import { ref, computed } from "@vue/composition-api"
  import Cleave from "vue-cleave-component"
  // eslint-disable-next-line import/no-extraneous-dependencies
  import "cleave.js/dist/addons/cleave-phone.us"
  import StaffDutyHistoryNew from "./StaffDutyHistoryNew.vue"
  import staffContactService from "@/services/staffContactService"
  import staffService from "@/services/staffService"
  import { useRouter } from "@core/utils/utils"
  import AppTimeline from "@core/components/app-timeline/AppTimeline.vue"
  import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue"
  import i18n from "@/libs/i18n"
  export default {
    components: {
      vSelect,
      flatPickr,
      Cleave,
      StaffDutyHistoryNew,
      AppTimeline,
      AppTimelineItem,
    },
    props: {
      staffData: {
        default: null,
      },
      teams: {
        default: null,
      },
      seasons: {
        default: null,
      },
      departments: {
        default: null,
      },
      jobs: {
        default: null,
      },
    },
    data() {
      return {
        duty: {
          staff: null,
          season: null,
          team: null,
          job: null,
          jobEndDate: null,
        },
        isAddDutyActive: false,
      }
    },
    methods: {
      goSidebar(staffDutyData) {
        this.duty = { ...staffDutyData }
        this.duty.staff = this.staffData.id
        this.isAddDutyActive = true
      },
    },
    beforeMount() {},
    setup(props, { root }) {
      const { router, route } = useRouter()
      const contactSer = new staffContactService()
      const staffSer = new staffService()
      const staffId = ref(Number(router.currentRoute.params.staffID))

      const submitHistory = (staffHistoryData) => {
        staffSer
          .addCareer(staffHistoryData)
          .then((res) => {
            //todo: table refresh
            location.reload()
          })
          .catch((error) => {
            console.log("error", error)
          })
      }

      const updateHistory = (staffHistoryData) => {
        staffSer
          .updateCareer(staffHistoryData)
          .then((res) => {
            //todo: table refresh
            location.reload()
          })
          .catch((error) => {
            console.log("error", error)
          })
      }

      const fields = computed({
        get: () => [
          {
            key: "team",
            label: i18n.t("Team"),
            sortable: false,
            thStyle: { width: "200px", "min-width": "200px", "max-width": "220px" },
          },
          {
            key: "season",
            label: i18n.t("Season"),
            sortable: false,
            thStyle: { width: "200px", "min-width": "200px", "max-width": "220px" },
          },
          {
            key: "department",
            label: i18n.t("Department"),
            sortable: false,
            thStyle: { width: "200px", "min-width": "200px", "max-width": "220px" },
          },
          {
            key: "job",
            label: i18n.t("Job"),
            sortable: false,
            thStyle: { width: "200px", "min-width": "200px", "max-width": "220px" },
          },
          {
            key: "jobEndDate",
            label: i18n.t("Job End Date"),
            sortable: false,
            thStyle: { width: "200px", "min-width": "200px", "max-width": "220px" },
          },
          {
            key: "actions",
            label: i18n.t("Actions"),
            sortable: false,
            thStyle: { width: "200px", "min-width": "200px", "max-width": "220px" },
          },
        ],
      })

      return {
        submitHistory,
        updateHistory,
        fields,
      }
    },
  }
</script>

<style lang="scss">
  @import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
