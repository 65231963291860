<template>
  <div>
    <div v-if="staffData.user">
      <b-row>
        <div class="ml-1">
          <div class="d-flex justify-content-start">
            <p>{{ $t("User") }} :</p>
            <span class="font-weight-bold">
              {{ staffData.user.firstName + ` ` + staffData.user.lastName }}
            </span>
          </div>
          <div class="d-flex justify-content-start">
            <p>{{ $t("Permissions") }}:</p>
          </div>
        </div>
        <b-table
          responsive
          :items="permissionsData"
          :fields="[
            { key: 'module', label: $t('Module') },
            { key: 'read', label: $t('Read') },
            { key: 'write', label: $t('Write') },
            { key: 'create', label: $t('Create') },
            { key: 'delete', label: $t('delete') },
          ]"
          class="m-1 border"
        >
          <template #cell(module)="data">
            {{ data.value }}
          </template>
          <template #cell()="data">
            <b-form-checkbox disabled :checked="data.value" />
          </template>
        </b-table>
      </b-row>
    </div>
    <div v-else>
      <b-form-group :label="$t('Assign a User To Staff')" label-for="user">
        <v-select
          id="user"
          :placeholder="$t('Please select a user')"
          v-model="user"
          :options="users"
          :reduce="(option) => option.id"
          :getOptionLabel="(option) => option.firstName"
        >
        </v-select>
      </b-form-group>
      <b-button @click="assign" variant="primary" class="mr-2" type="submit">{{ $t("Add") }}</b-button>
    </div>
  </div>
</template>

<script>
  import vSelect from "vue-select"
  import { ref, onUnmounted, onMounted, computed } from "@vue/composition-api"
  import http from "@/libs/axios"
  import i18n from "@/libs/i18n"
  export default {
    components: { vSelect },
    props: {
      staffData: {
        default: null,
      },
      users: {
        default: null,
      },
    },
    setup(props, { emit, root }) {
      const permissionsData = [
        {
          module: "Takım Yönetimi",
          read: true,
          write: false,
          create: false,
          delete: false,
        },
        {
          module: "Antrenman Yönetimi",
          read: false,
          write: true,
          create: false,
          delete: false,
        },
        {
          module: "Oyuncu Yönetimi",
          read: true,
          write: false,
          create: true,
          delete: false,
        },
        {
          module: "Maç Yönetimi",
          read: false,
          write: false,
          create: false,
          delete: false,
        },
        {
          module: "Sistem Yönetimi",
          read: false,
          write: false,
          create: false,
          delete: true,
        },
      ]
      const user = ref(null)
      const assign = () => {
        http
          .put(`/staff-user-assign/${props.staffData.id}`, { user: user.value })
          .then((res) => {
            console.log(res.data)
            root.toast(i18n.t("User Assignment Performed"), "PlusIcon", "success")
            location.reload()
          })
          .catch((error) => console.log(error))
      }
      return {
        permissionsData,
        user,
        assign,
      }
    },
  }
</script>

<style lang="scss"></style>
