export default [
  {
    path: "/settings",
    name: "settings",
    component: () => import("@/views/settings/settingIndex.vue"),
    meta: {
      resource: "Setting",
      action: "manage",
    },
  },
]
