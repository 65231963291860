<template>
  <div>
    <!-- <b-row class="d-flex justify-content-end mx-1">
      <b-button class="mb-2" type="button" variant="primary" @click="create">
        {{ $t("Add New") }}
      </b-button>
    </b-row> -->
    <div class="d-flex justify-content-end pr-2">
      <b-button @click="create()" variant="primary" :disabled="creating">
        <b-spinner v-if="creating" small style="margin-bottom: 3px"></b-spinner>
        {{ $t("Add New") }}
      </b-button>
    </div>
    <b-card v-if="playerPerformances.performance" no-body>
      <div>
        <vue-good-table
          :columns="tableColumns"
          :rows="Object.values(playerPerformances.performance)"
          :search-options="{
            enabled: false,
          }"
          :pagination-options="{
            enabled: false,
            perPage: pageLength,
          }"
          :sort-options="{
            enabled: true,
            initialSortBy: { field: 'date', type: 'desc' },
          }"
        >
          >
          <template slot="table-row" slot-scope="props">
            <div class="d-flex align-items-center justify-content-center" v-if="props.column.field == 'user'">
              <p>
                {{ props.row.user && props.row.user.firstName && props.row.user.firstName }}
                {{ props.row.user && props.row.user.lastName && props.row.user.lastName.substr(0, 1) + "." }}
              </p>
            </div>
            <div class="d-flex align-items-center justify-content-center" v-if="props.column.field == 'date'">
              <b-link
                :to="{
                  name: 'team-player-performance-rating',
                  params: {
                    seasonId: seasonId,
                    id: teamId,
                    performanceId: props.row.id,
                    playerId: routerParams.playerId,
                  },
                }"
              >
                {{ props.row.date | nativeDate }}
              </b-link>
            </div>
            <div class="d-flex align-items-center justify-content-center" v-if="props.column.field == 'physical'">
              <p v-b-tooltip.hover :title="$t('Performance Average')">
                {{ getAvg(props.row.physical) }}
              </p>
            </div>
            <div class="d-flex align-items-center justify-content-center" v-if="props.column.field == 'mental'">
              <p v-b-tooltip.hover :title="$t('Performance Average')">
                {{ getAvg(props.row.mental) }}
              </p>
            </div>
            <div class="d-flex align-items-center justify-content-center" v-if="props.column.field == 'tasteful'">
              <p v-b-tooltip.hover :title="$t('Performance Average')">
                {{ getAvg(props.row.tasteful) }}
              </p>
            </div>
            <div class="d-flex align-items-center justify-content-center" v-if="props.column.field == 'technical'">
              <p v-b-tooltip.hover :title="$t('Performance Average')">
                {{ getAvg(props.row.technical) }}
              </p>
            </div>
            <div
              class="d-flex align-items-center justify-content-center"
              v-if="props.column.field == 'technicalGoalKeeper'"
            >
              <p v-b-tooltip.hover :title="$t('Performance Average')">
                {{ getAvg(props.row.technicalGoalKeeper) }}
              </p>
            </div>
            <div class="d-flex align-items-center justify-content-center" v-if="props.column.field == 'tactical'">
              <p v-b-tooltip.hover :title="$t('Performance Average')">
                {{ getAvg(props.row.tactical) }}
              </p>
            </div>
            <div class="d-flex align-items-center justify-content-center" v-if="props.column.field == 'avg'">
              <p v-b-tooltip.hover :title="$t('Performance Average')">
                <b-form-rating
                  variant="warning"
                  precision="3"
                  no-border
                  readonly
                  :stars="getSetting('star')"
                  :value="getAllAvg(props.row)"
                ></b-form-rating>
              </p>
            </div>
          </template>

          <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap">
                  {{ $i18n.t("Showing") }}
                </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['3', '5', '10']"
                  class="mx-1"
                  @input="(value) => props.perPageChanged({ currentPerPage: value })"
                />
                <span class="text-nowrap"> {{ props.total }} {{ $i18n.t("Has records") }} </span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value) => props.pageChanged({ currentPage: value })"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </div>
    </b-card>
  </div>
</template>

<script>
  import { onMounted, onUnmounted, ref, watch, computed } from "@vue/composition-api"

  import { VueGoodTable } from "vue-good-table"
  import i18n from "@/libs/i18n"
  import { useRouter } from "@core/utils/utils"
  import axios from "@/libs/axios"

  export default {
    components: {
      VueGoodTable,
    },
    props: {
      playerPerformances: {
        type: [Object, null],
        required: true,
      },
      player: {
        type: [Object, null],
        require: true,
      },
    },

    setup(props, { root }) {
      const { router, route } = useRouter()

      const pageLength = ref(5)
      const searchTerm = ref("")
      const teamId = ref(router.currentRoute.params.id || router.currentRoute.params.tId)
      const seasonId = ref(router.currentRoute.params.seasonId || router.currentRoute.params.sId)
      const playerId = ref(router.currentRoute.params.playerId)
      const creating = ref(false)

      const tableColumns = computed({
        get: () => [
          {
            field: "date",
            label: i18n.t("Date"),
            filterOptions: {
              enabled: false,
              placeholder: `${i18n.t("Search")} `,
            },
          },
          {
            field: "user",
            label: i18n.t("Reviewer"),
            filterOptions: {
              enabled: false,
              placeholder: `${i18n.t("Search")} `,
            },
          },
          {
            field: "physical",
            label: i18n.t("Physical"),
            filterOptions: {
              enabled: false,
              placeholder: `${i18n.t("Search")} `,
            },
            sortFn: sorFn,
          },
          {
            field: "mental",
            label: i18n.t("Mental"),
            filterOptions: {
              enabled: false,
              placeholder: `${i18n.t("Search")} `,
            },
            sortFn: sorFn,
          },
          {
            field: "tasteful",
            label: i18n.t("Tasteful"),
            filterOptions: {
              enabled: false,
              placeholder: `${i18n.t("Search")} `,
            },
            sortFn: sorFn,
          },
          {
            field: "technical",
            label: i18n.t("Technical"),
            filterOptions: {
              enabled: false,
              placeholder: `${i18n.t("Search")} `,
            },
            sortFn: sorFn,
          },
          {
            field: "technicalGoalKeeper",
            label: i18n.t("Technical Goal Keeper"),
            filterOptions: {
              enabled: false,
              placeholder: `${i18n.t("Search")} `,
            },
            sortFn: sorFn,
            hidden: !props.player?.position.availablePositions.includes("GK"),
          },
          {
            field: "tactical",
            label: i18n.t("Tactical"),
            filterOptions: {
              enabled: false,
              placeholder: `${i18n.t("Search")} `,
            },
            sortFn: sorFn,
          },
          {
            field: "avg",
            label: i18n.t("Performance Average"),
            filterOptions: {
              enabled: false,
              placeholder: `${i18n.t("Search")} `,
            },
          },
        ],
      })

      const sorFn = (x, y, col, rowX, rowY) => {
        let xSum = x ? x.rate.reduce((a, b) => a + b, 0) : 0
        let ySum = y ? y.rate.reduce((a, b) => a + b, 0) : 0
        let xAvg = xSum != 0 ? (xSum / x.rate.length).toFixed(1) : 0
        let yAvg = ySum != 0 ? (ySum / y.rate.length).toFixed(1) : 0
        return xAvg < yAvg ? -1 : xAvg > yAvg ? 1 : 0
      }

      const create = async () => {
        try {
          creating.value = true
          let perfID = null

          let nowDate = new Date().toLocaleDateString("en", {
            timeZone: "Europe/Istanbul",
            hour12: false,
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          })
          let performance = (
            await axios.get(`/season/${seasonId.value}/performance/team/${teamId.value}?date=${nowDate}`)
          ).data

          if (performance.length == 0) {
            let { data } = await axios.post(`/season/${seasonId.value}/performance`, { team: Number(teamId.value) })
            perfID = data.id
          } else {
            perfID = performance[0].id
          }

          let url = `/season/${seasonId.value}/team/${teamId.value}/performance/rating/${perfID}/player/${playerId.value}`
          console.log(url)
          router.push(url)
          creating.value = false
        } catch (error) {
          creating.value = false
          console.log("Create error")
        }
      }

      return {
        pageLength,
        searchTerm,
        teamId,
        create,
        seasonId,
        creating,
        tableColumns,
      }
    },
  }
</script>
<style lang="scss">
  @import "@core/scss/vue/libs/vue-good-table.scss";
</style>
