<template>
  <div class="">
    <b-modal id="modal-video" ref="isVideoModalOpen" hide-footer centered title="Video">
      <div style="height: 50vh; overflow: scroll" class="text-center">
        <div v-for="video in tempVideoData" :key="video.id" class="my-1 px-md-3">
          <div class="border-primary rounded p-1 block">
						<div class="embed-responsive embed-responsive-16by9">
							<span v-html="video.path"></span>
						</div>
					</div>
        </div>
      </div>
    </b-modal>

    <b-modal id="modal-participant" ref="isAttendanceModalOpen" hide-footer centered :title="modalTitle">
      <div
        v-for="(data, index) in tempParticipantList"
        :key="index"
        class="d-flex justify-content-start align-items-center player_team_list mt-1"
      >
        <div class="mr-1">
          <b-avatar size="40" :src="data.player.avatar" />
        </div>
        <div class="profile-user-info">
          <h6 class="mb-0">
            {{ data.player.name }}
          </h6>
          <small class="text-muted">{{ $t(data.player.position.name) }}</small>
        </div>
      </div>
    </b-modal>

    <b-modal id="modal-serp" ref="isSerpOpen" hide-footer centered :title="$t('Evaluation Notice')">
      <b-tabs content-class="mt-3">
        <b-tab :title="$t('Sent')" active>
          <div>
            <div
              v-for="(data) in tempSerpData.filter(item => item.review_send_date)"
              :key="data.id"
              class="d-flex justify-content-start align-items-center player_team_list mt-1"
            >
              <div class="mr-1">
                <b-avatar size="40" :src="data.player.avatar" />
              </div>
              <div class="profile-user-info">
                <h6 class="mb-0">
                  {{ data.player.name }}
                </h6>
                <small class="text-muted">{{ $t(data.player.position.name) }}</small>
              </div>
              <div class="h3 ml-auto mr-1">
                <b>{{ data.playerSerpScore }}</b>
              </div>
            </div>
          </div>
        </b-tab>
        <b-tab :title="$t('Not sent')">
          <div>
            <div
              v-for="(data) in tempSerpData.filter(item => !item.review_send_date)"
              :key="data.id"
              class="d-flex justify-content-start align-items-center player_team_list mt-1"
            >
              <div class="mr-1">
                <b-avatar size="40" :src="data.player.avatar" />
              </div>
              <div class="profile-user-info">
                <h6 class="mb-0">
                  {{ data.player.name }}
                </h6>
                <small class="text-muted">{{ $t(data.player.position.name) }}</small>
              </div>
            </div>
          </div>
        </b-tab>
      </b-tabs>
    </b-modal>

    <b-modal @ok="handleOk" :ok-title="$t('Add')" :cancel-title="$t('Cancel')" id="modal-copy" ref="isCopyModalOpen" :title="$t('Copy')" centered :ok-disabled="copying" :cancel-disabled="copying" :no-close-on-backdrop="copying"  :hide-header-close="copying" modal-header-close="false">
      <b-overlay :show="copying" rounded="sm" >
        <div>
          <b-form>
            <b-row>
              <b-col md="12">
                <label>{{ $t("Training Name") }}</label>
                <b-form-group>
                  <b-input-group>
                    <b-form-input
                      v-model="trainingModalData.title"
                      type="text"
                      :placeholder="$t('Training Name')"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>

              <b-col md="8">
                <label>{{ $t("Training Date") }}</label>
                <b-form-group>
                  <b-input-group>
                    <b-form-datepicker
                      :placeholder="$t('Training Date')"
                      :obj="trainingModalData.startDate"
                      v-model="trainingModalData.startDate"
                      :locale="lang"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>

              <b-col md="4">
                <label>{{ $t("Start Time") }}</label>
                <b-form-group>
                  <b-input-group>
                    <b-form-input
                      v-model="trainingModalData.startTime"
                      type="text"
                      placeholder="HH:mm"
                    />
                    <b-input-group-append>
                      <b-form-timepicker
                        v-model="trainingModalData.startTime"
                        no-close-button
                        button-only
                        button-variant="outline-info"
                        left
                        size="sm"
                        locale="tr"
                      />
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <label>{{ $t("Season") }}</label>
                <b-form-group>
                  <v-select
                    v-model="trainingModalData.season"
                    :options="avaibleSeasons"
                    :reduce="(option) => option.id"
                    :getOptionLabel="(option) => option.name"
                    :clearable="false"
                  >
                  </v-select>
                </b-form-group>
              </b-col>
              
            </b-row>
          </b-form>
        </div>
      </b-overlay>
    </b-modal>

    <b-overlay variant="white" :show="loading" spinner-variant="primary" blur="0" opacity=".75" rounded="sm">
      <b-card no-body>
        <b-card-header class="pb-50">
          <h5>{{ $t("Filter") }}</h5>
        </b-card-header>
        <b-card-body>
          <b-row>
            <b-col cols="12" md="4" xl="3" class="mb-md-0 mb-2">
              <label>{{ $t("Antrenman Date") }}</label>
              <b-form-datepicker
                :placeholder="$t('Training Date')"
                v-model="filterStartDate"
                :hide-header="true"
                today-button
                reset-button
                :label-today-button="$t('Select today')"
                :label-reset-button="$t('Reset')"
                :locale="lang"
                @input="refTrainingTable.refresh()"
              />
            </b-col>

          </b-row>
        </b-card-body>
      </b-card>

      <b-card no-body>
        <b-card-title class="pt-2 pl-2">{{ $t("Trainings") }}</b-card-title>
        <div class="d-block ml-2">
          <div class="float-left">
            <label>{{ $t("Show") }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
          </div>
          <div class="float-right">
            <b-button :to="{ name: 'team-training-add', params: { id: teamId } }" variant="primary" class="right-top">{{
              $t("Add Training")
            }}</b-button>
          </div>
        </div>
        <div class="table-responsive">
          <p>
            <ul id="example-1">
              
            </ul>
          </p>
          <b-table 
            :fields="fields"
            class="position-relative"
            :empty-text="$t('No records found')"
            responsive
            primary-key="id"
            show-empty
            :per-page="perPage"
            :current-page="currentPage"
            striped 
            :items="getTrainings" 
            :sort-by.sync="sortBy"
            :sort-desc.sync="isSortDirDesc"
            ref="refTrainingTable"
            no-sort-reset="true"
          >
            <!-- general table td   class="border"    responsive="lg"  -->
            <template #cell(startDate)="data" style="width: 120px">
              <div @click="goToItem(data.item.id)">
                <b-link class="text-dark">
                  {{ data.value|nativeDateTime }}
                </b-link>
              </div>
            </template>

            <template #cell(trainingBlocks)="data">
              <div v-for="block in data.value" :key="block.id" class="outcome-div">
                <div v-for="trainingItem in block.trainingItems" :key="trainingItem.id">
                  <ul class="outcome-ul ml-n2" v-for="(loc, index) in trainingItem.learningOutcomes" :key="index">
                    <li style="list-style: inside" class="text-truncate text-uppercase" v-b-tooltip.hover :title="loc.name">{{ loc.name }}</li>
                  </ul>
                </div>
              </div>
            </template>

            <template #cell(location)="data">
              <span class="d-block text-truncate" v-b-tooltip.hover :title="data.value.name">{{ data.value.name }}</span>
            </template>

            <template #cell(title)="data">
              <b-link @click="goToItem(data.item.id)" class="text-dark">
                <span class="d-block text-truncate font-weight-bold text-uppercase" v-b-tooltip.hover :title="data.value">{{ data.value }}</span>
              </b-link>
            </template>

            <template #cell(trainingAttenders)="data">
              <b-link @click="showAttendanceModal(getAttenders(data.value, false), $t('Absents'))">
                <b-badge variant="danger" v-b-tooltip.hover :title="$t('Absents') + ` :` + getAttendersCount(data.value, false)">{{
                  getAttendersCount(data.value, false)
                }}</b-badge>
              </b-link>
              /
              <b-link @click="showAttendanceModal(getAttenders(data.value, true), $t('Attendant'))">
                <b-badge variant="success" v-b-tooltip.hover :title="$t('Attendant') + ` :` + getAttendersCount(data.value, true)">{{
                  getAttendersCount(data.value, true)
                }}</b-badge>
              </b-link>
            </template>

            <template #cell(trainingMedia)="data">
              <b-link @click="showVideoModal(getVideos(data.value, 'video'))" class="text-dark" style="margin-left: 3px">
                <feather-icon icon="VideoIcon" size="22" :badge="getVideoCount(data.value, 'video')" />
              </b-link>
            </template>

            <template #cell(participant)="data">
              <b-badge
                v-b-modal.modal-participant
                @click="participantListModal(data.value)"
                v-b-tooltip.hover
                class="pull-up"
                :title="$t('Participant') + ` : ` + data.value.length"
                variant="success"
                rounded
              >
                {{ data.value.length }}
              </b-badge>
            </template>

            <template #cell(serp)="data">
              <b-link v-if="data.item.status != 'pending' || data.item.trainingAttenders.filter(i => i.playerSerpDate).length" @click="showSerpModal(data.item.trainingAttenders)">
                <b-badge :set="result = playerSerpAvg(data.item.trainingAttenders)" :variant="result.isAllSent ? 'success' : 'danger' " v-b-tooltip.hover >
                  {{ result.avg }}
                </b-badge>
              </b-link>
              <feather-icon v-else
                v-b-tooltip.hover
                :title="data.item.status === 'pending' ? 'Henüz Gerçekleşmedi' : 'Tamamlandı'"
                size="22"
                :icon="data.item.status === 'pending' ? 'ClockIcon' : `CheckIcon`"
              />
            </template>

            <template #cell(action)="data">
              <div class="d-flex">
                <b-link class="text-dark" style="margin-left: 5px">
                  <feather-icon v-b-tooltip.hover @click="editItem(data.item.id)" :title="$t('Edit')" size="21" icon="EditIcon" />
                </b-link>
                <b-link class="text-dark" style="margin-left: 5px">
                  <feather-icon v-b-tooltip.hover @click="goToAttenders(data.item.id)" :title="$t('Attenders')" size="21" icon="UsersIcon" />
                </b-link>
                <b-link class="text-dark" style="margin-left: 5px">
                  <feather-icon v-b-tooltip.hover :title="$t('Copy')" @click="showTrainingCopyModal(data.item)" size="21" icon="CopyIcon" />
                </b-link>
                <b-link class="text-dark" style="margin-left: 5px">
                  <feather-icon v-b-tooltip.hover :title="$t('Details')" @click="goToItem(data.item.id)" size="21" icon="EyeIcon" />
                </b-link>
                <b-link class="text-dark" style="margin-left: 5px">
                  <feather-icon v-b-tooltip.hover :title="$t('Test')" @click="goToTest(data.item.id)" size="21" icon="ActivityIcon" />
                </b-link>
                <b-link class="text-dark" v-if="$can('manage', 'Training')">
                  <feather-icon v-b-tooltip.hover @click="removeItem(data.item.id)" :title="$t('Delete')" size="21" icon="TrashIcon" />
                </b-link>
              </div>
            </template>
          </b-table>
           <div class="mx-2 mb-2">
            <b-row>
              <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
                <span class="text-muted"
                  >{{ $t("format.showingData", {from: dataMeta.from, to: dataMeta.to, of: dataMeta.of}) }}</span
                >
              </b-col>
              <!-- Pagination -->
              <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalItems"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import vSelect from "vue-select";
import TrainingPlugin from "@/views/team/team-sub-pages/training/TrainingPlugin";
import TrainingStoreModule from "@/views/team/team-sub-pages/training/TrainingStoreModule";
import { useRouter } from "@core/utils/utils";
import store from "@/store";
import i18n from "@/libs/i18n";
import { ref, reactive, watch, computed, toRefs, onUnmounted, onBeforeMount } from "@vue/composition-api";
import http from "@/libs/axios";
import axios from "@axios";
import localService from "@/services/localService";

export default {
  components: {
    vSelect,
  },
  directives: {},
  methods: {
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      this.copyTraining(this.trainingModalData);
    },
  },
  setup(props, { emit, root }) {
    const { router, route } = useRouter();
    const lang = ref(i18n.locale);

    const fields = computed({
      get: () => [
        {
          key: "startDate",
          label: i18n.t("Date"),
          sortable: true,
          thStyle: { width: "200px", "min-width": "200px", "max-width": "220px" },
        },
        // A column that needs custom formatting
        {
          key: "title",
          label: i18n.t("Training Title"),
          tdClass: "responsiveWidth",
        },

        // A regular column
        {
          key: "trainingBlocks",
          label: i18n.t("Exercises"),
          tdClass: "responsiveWidth",
        },
        {
          key: "trainingAttenders",
          label: i18n.t("Attendance"),
          thStyle: { width: "120px", "min-width": "120px", "max-width": "140px" },
        },
        {
          key: "trainingMedia",
          label: i18n.t("Video"),
          thStyle: { width: "80px", "min-width": "80px", "max-width": "100px" },
        },

        {
          key: "location",
          label: i18n.t("Location"),
          tdClass: "responsiveWidth",
        },

        {
          key: "serp",
          label: i18n.t("sERP"),
          sortable: false,
          thStyle: { width: "80px", "min-width": "80px", "max-width": "120px" },
        },
        {
          key: "action",
          label: i18n.t("Action"),
          sortable: false,
          thStyle: { width: "190px", "min-width": "190px", "max-width": "250px" },
        },
      ],
    });

    const modalTitle = ref(null);

    const goToItem = (id) => {
      router.push({
        name: "team-training-preview",
        params: { id: teamId.value, trainingId: id },
      });
    };

    const goToTest = (id) => {
      router.push({
        name: "team-serp-score",
        params: { id: teamId.value, trainingId: id },
      });
    };

    const editItem = (id) => {
      router.push({
        name: "team-training-edit",
        params: { id: teamId.value, trainingId: id },
      });
    };

    const goToAttenders = (id) => {
      router.push({
        name: "team-training-attenders",
        params: { id: teamId.value, trainingId: id },
      });
    };

    const removeItem = (id) => {
			root.$bvModal
				.msgBoxConfirm(
					i18n.t("Please confirm that you want to delete this."),
					{
						title: i18n.t("Please Confirm"),
						size: "sm",
						okVariant: "primary",
						okTitle: i18n.t("Yes"),
						cancelTitle: i18n.t("Cancel"),
						cancelVariant: "outline-secondary",
						hideHeaderClose: false,
						centered: true,
					}
				)
				.then((value) => {
					if (value) {
						removeTraining(id);
					}
				});
		};

    const dataMeta = computed(() => {
      const localItemsCount = trainings.value.length > 0 ? trainings.value.length : 0;
      return {
        from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalItems.value,
      };
    });

    onBeforeMount(() => {
      // getTrainings();
      filterStartDate.value = new localService("training_filter_date").get() || ""
    });

    const showVideoModal = (data) => {
      tempVideoData.value = data;
      if (data.length > 0) {
        isVideoModalOpen.value.show();
      } else {
        root.toast(i18n.t("No video available"), "AlertCircleIcon", "warning");
      }
    };
    const showAttendanceModal = (data, text) => {
      modalTitle.value = text;
      tempParticipantList.value = data;
      if (data.length > 0) {
        isAttendanceModalOpen.value.show();
      }
    };
    const showSerpModal = (data) => {
      tempSerpData.value = data.filter(i => i.attendant);
      if (data.length > 0) {
        isSerpOpen.value.show();
      }
    };

    const getAttendersCount = (trainingAttenders, attendant) => {
      let array = trainingAttenders.filter((item) => {
        return item.attendant == attendant && item.invited == true;
      });

      return array.length;
    };

    const getAttenders = (trainingAttenders, attendant) => {
      return trainingAttenders.filter((item) => {
        return item.attendant == attendant && item.invited == true;
      });
    };

    const getVideoCount = (media, type) => {
      let array = media.filter((item) => {
        return item.type == type;
      });

      return array.length;
    };

    const getVideos = (media, type) => {
      return media.filter((item) => {
        return item.type == type;
      });
    };

    const playerSerpAvg = (obj) => {
      let arr = [];
      let avgSerp = 0;
      Object.values(obj).forEach((item) => {
        if (item.playerSerpDate && item.attendant) {
          arr.push(item.playerSerpScore);
        }
      })
      avgSerp = arr.length > 0 ? (arr.reduce((a, b) => a + b) / arr.length).toFixed(1) : 0 ;
      return { avg: avgSerp, isAllSent: obj.filter(item => item.attendant).length == arr.length };
    };

    const isVideoModalOpen = ref(false);
    const isAttendanceModalOpen = ref(false);
    const isSerpOpen = ref(false);
    const tempVideoData = ref([]);
    const tempParticipantList = ref([]);
    const tempSerpData = ref([]);
    const filterStartDate = ref(null);
    const isSortDirDesc = ref(true);
    const sortBy = ref("startDate");

    watch([filterStartDate], () => {
      // refTrainingTable.value.refresh();
      new localService("training_filter_date", filterStartDate.value);
    });

    const getVideoTypeCount = () => {};

    const {
      isCopyModalOpen,
      copying,
      copyTraining,
      showTrainingCopyModal,
      trainingModalData,
      avaibleSeasons,
      trainings,
      teamId,
      loading,
      perPage,
      currentPage,
      totalItems,
      perPageOptions,
      seasonId,
      refTrainingTable,
      removeTraining,
    } = TrainingPlugin(toRefs(props), emit);

    const getTrainings = () => {
      loading.value = true;
      return http
        .get(
          `season/${seasonId.value}/team/${teamId.value}/training?row=${perPage.value}&page=${currentPage.value}&date=${
            filterStartDate.value || ""
          }`,{
            params: {
              sortBy: sortBy.value ? sortBy.value : "startDate",
              isDesc: isSortDirDesc.value,
            }
          }
        )
        .then((res) => {
          loading.value = false;
          trainings.value = res.data.data;
          totalItems.value = res.data.count;

          return trainings.value;
        })
        .catch((error) => {
          console.log(error);
          loading.value = false;
        });
    };
    return {
      tempVideoData,
      tempParticipantList,
      showVideoModal,
      isVideoModalOpen,
      isAttendanceModalOpen,
      showAttendanceModal,
      modalTitle,
      trainings,
      fields,
      getTrainings,
      teamId,
      goToItem,
      editItem,
      goToTest,
      goToAttenders,
      getVideoTypeCount,
      getAttendersCount,
      getAttenders,
      getVideoCount,
      getVideos,
      loading,
      perPage,
      currentPage,
      totalItems,
      perPageOptions,
      dataMeta,
      getTrainings,
      isCopyModalOpen,
      showTrainingCopyModal,
      copyTraining,
      trainingModalData,
      avaibleSeasons,
      copying,
      filterStartDate,
      refTrainingTable,
      isSortDirDesc,
      sortBy,
      lang,
      playerSerpAvg,
      showSerpModal,
      isSerpOpen,
      tempSerpData,
      removeItem,
    };
  },
};
</script>

<style lang="scss">
.per-page-selector {
  width: 90px;
}

.right-top {
  position: absolute;
  right: 2rem;
  top: 1rem;
}

.embed-video {
  iframe {
    max-width: 100%;
    max-height: 30vh;
  }
}
.outcome-ul {
  margin-top: 1px;
  margin-bottom: 0px;
  font-size: 13px;
}
.outcome-div {
  padding-right: 0px;
  margin-left: -25px;
}
.responsiveWidth{
  min-width: 2em;
  width: 2em;
  max-width: 15em;
}
</style>