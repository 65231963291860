<template>
  <b-overlay variant="white" :show="loading" spinner-variant="primary" blur="0" opacity=".75" rounded="sm">
    <b-card>
      <div class="row" style="row-gap: 5px;">
        <b-col md="3" sm="6">
          <v-select
            :placeholder="$t('Player')"
            multiple
            :close-on-select="false"
            v-model="playersFilter"
            :options="playerOptions"
            :reduce="(d) => d.id"
            :getOptionLabel="
              (player) =>
                player.name
                  .trim()
                  .split(' ')
                  .map((n, i) => (i == 0 ? n : n[0] + '.'))
                  .join(' ')
            "
            :selectable="() => pageTable == 'last' || groupTableMode != 'chart' || playersFilter.length < 3"
          >
            <template v-slot:option="option">
              <b-avatar class="mr-1" :src="option.avatar" size="18" />
              {{ option.name }}
            </template>
          </v-select>
        </b-col>

        <b-col md="2" sm="6">
          <v-select
            style="min-width: 150px"
            :placeholder="$t('Test')"
            multiple
            :close-on-select="false"
            v-model="testItemsFilter"
            :options="testItemOptions"
            :reduce="(d) => d.field"
            :getOptionLabel="(item) => item.label"
            :selectable="() => pageTable == 'last' || groupTableMode != 'chart' || testItemsFilter.length < 1"
          />
        </b-col>

        <b-col md="5" sm="6">
          <div class="d-flex" style="gap: 5px">
            <b-form-datepicker
              v-model="startDateFilter"
              :locale="lang"
              reset-button
              :hide-header="true"
              :label-reset-button="$t('Reset')"
              :placeholder="''"
            />
            <b-form-datepicker
              v-model="endDateFilter"
              :locale="lang"
              reset-button
              :hide-header="true"
              :label-reset-button="$t('Reset')"
              :placeholder="''"
            />
          </div>
        </b-col>

        <b-col md="2" sm="6">
          <b-button class="w-100" variant="primary" size="md" @click="filterTests">
            {{ $t("Filter") }}
          </b-button>
        </b-col>
      </div>
    </b-card>

    <template v-if="tableItems.length > 0">
      <!-- LAST Table -->
      <b-card v-if="pageTable == 'last'">
        <!-- SET CARD HEADER -->
        <div class="card-header p-0 m-0">
          <div>
            <b-card-title class="mt-0">
              {{ $t("Last Tests") }}
            </b-card-title>
          </div>
          <div>
            <!-- ADD BUTTON -->
          </div>
        </div>
        <vue-good-table
          ref="table"
          :columns="tableColumns"
          :rows="tableItems"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm,
          }"
        >
          <template slot="table-column" slot-scope="props">
            <span
              v-b-tooltip.hover.v-primary
              :title="`${props.column.label} ${props.column.field}`"
              style="min-width: 125px !important; display: block; font-size: 12px !important"
              variant="outline-primary"
            >
              {{ props.column.label }}
            </span>
          </template>

          <template slot="table-row" slot-scope="props">
            <div v-if="props.column.field == 'player'">
              <player-item-box :player="props.row.player" small />
            </div>

            <div v-else-if="props.column.field == 'position'">
              <b-link
                :to="{
                  name: 'team-player',
                  params: {
                    id: teamId,
                    playerId: props.row.player.id,
                  },
                }"
              >
                <div class="d-flex align-items-center justify-content-start">
                  <div class="">
                    <p class="text-left">
                      <b-badge :variant="`light-${props.row.player.position.color}`">
                        {{ $t(props.row.player.position.name) }}
                      </b-badge>
                    </p>
                  </div>
                </div>
              </b-link>
            </div>

            <div v-else-if="props.column.field == 'comment'" class="text-truncate">
              {{ props.row.comment }}
            </div>

            <div v-else>
              {{ props.formattedRow[props.column.field] }}
            </div>
          </template>
        </vue-good-table>
      </b-card>

      <!-- GROUP Table -->
      <b-card v-if="pageTable == 'group'">
        <div class="d-flex justify-content-end mb-1">
          <b-button-group>
            <b-button variant="info" :class="{ active: groupTableMode === 'list' }" @click="groupTableMode = 'list'">
              {{ $t("Tablo") }}
            </b-button>
            <b-button variant="info" :class="{ active: groupTableMode === 'chart' }" @click="groupTableMode = 'chart'">
              {{ $t("Grafik") }}
            </b-button>
          </b-button-group>
        </div>

        <b-table
          v-if="groupTableMode === 'list'"
          id="group-test-table"
          small
          :fields="tableColumns"
          :items="tableItems"
          bordered
          responsive="sm"
        >
          <template #thead-top="data" v-if="topColumns.length > 0" no-border-collapse>
            <b-tr :key="data.columns">
              <b-th></b-th>
              <b-th class="text-center" v-for="(item, index) in topColumns" :key="index" :colspan="item.colspan">
                {{ item.title }}
              </b-th>
            </b-tr>
          </template>

          <!-- ROWS -->
          <template #cell(player)="data">
            <player-item-box :player="data.value" small />
          </template>

          <template #cell()="data">
            <div class="d-flex justify-content-between position-relative">
              <span class="position-absolute">
                <i
                  class="feather icon-arrow-up text-success mr-auto"
                  style="font-size: large; font-weight: bold"
                  v-if="data.value.arrow == 'up'"
                ></i>
                <i
                  class="feather icon-arrow-down text-danger mr-auto"
                  style="font-size: large; font-weight: bold"
                  v-if="data.value.arrow == 'down'"
                ></i>
                <i
                  class="feather icon-minus text-warning mr-auto"
                  style="font-size: large; font-weight: bold"
                  v-if="data.value.arrow == 'equal'"
                ></i>
              </span>
              <span style="margin: 0 auto" v-if="data.value.value">{{ data.value.value }}</span>
              <span style="margin: 0 auto" v-else>-</span>
            </div>
          </template>
        </b-table>

        <div v-if="groupTableMode === 'chart'" style="display: inline-block;position: absolute;top: 10px;left: 34px;">
          <small style="color:#00E396" v-if="maxRes">Max: {{ maxRes }}</small><br />  
          <small style="color:#FEB019" v-if="minRes">Min: {{ minRes }}</small><br />  
          <small style="color:#775DD0" v-if="teamAverageRes">Team Avg: {{ teamAverageRes }}</small><br />  
          <small style="color:#F86624" v-if="positionAverageRes">Position Avg: {{ positionAverageRes }}</small>  
        </div>    

        <vue-apex-charts
          v-if="groupTableMode === 'chart'"
          :key="triggerChart"
          type="line"
          height="400"
          :options="lineChartSimple.chartOptions"
          :series="lineChartSimple.series"
        />
      </b-card>
    </template>

    <b-card v-else>
      <span> {{ $t("Data not found") }} </span>
    </b-card>
  </b-overlay>
</template>

<script>
  import { ref, reactive, onMounted, watch } from "@vue/composition-api"
  import flatPickr from "vue-flatpickr-component"
  import PlayerItemBox from "@core/components/global-components/PlayerItemBox"
  import { VueGoodTable } from "vue-good-table"
  import vSelect from "vue-select"
  import { BTable } from "bootstrap-vue"
  import VueApexCharts from "vue-apexcharts"
  import { $themeColors } from "@themeConfig"
  import en from "apexcharts/dist/locales/en.json"
  import tr from "apexcharts/dist/locales/tr.json"
  import fr from "apexcharts/dist/locales/fr.json"
  import es from "apexcharts/dist/locales/es.json"
  import it from "apexcharts/dist/locales/it.json"

  import i18n from "@/libs/i18n"
  import axios from "@axios"
  import router from "@/router"
  export default {
    components: {
      VueGoodTable,
      flatPickr,
      PlayerItemBox,
      vSelect,
      BTable,
      VueApexCharts,
    },
    data() {
      return {
        pageLength: 10,
        dir: false,
        rows: [],
        searchTerm: "",
      }
    },
    setup(props, { root }) {
      onMounted(async () => {
        await getColumns()
        await getPlayers()
        await fetchTests()
      })

      const pageTable = ref("last") // group, last
      const groupTableMode = ref("list") // list, chart

      const topColumns = ref([])
      const tableColumns = ref([])
      const itemColumns = ref([])
      const tableDefaultColumns = ref([
        {
          field: "player",
          key: "player",
          label: i18n.t("Player"),
        },
        {
          field: "position",
          label: i18n.t("Position"),
        },
      ])
      const loading = ref(false)
      const table = ref(null)
      const pageLength = ref(36)
      const tableItems = ref([])
      const tableMountedItems = ref([])
      const seasonId = ref(Number(router.currentRoute.params.seasonId))
      const teamId = ref(Number(router.currentRoute.params.id))
      const lang = ref(i18n.locale)
      const playersFilter = ref([])
      const testItemsFilter = ref([])
      const startDateFilter = ref(null)
      const endDateFilter = ref(null)
      const playerOptions = ref([])
      const testItemOptions = ref([])
      const triggerChart = ref(0)

      const lineChartSimple = reactive({
        series: [],
        chartOptions: {
          chart: {
            zoom: {
              enabled: false,
            },
            toolbar: {
              show: false,
            },
            locales: [en, tr, fr, es, it],
            defaultLocale: lang.value,
          },
          markers: {
            strokeWidth: 7,
            strokeOpacity: 1,
            strokeColors: [$themeColors.light],
            colors: [$themeColors.warning],
          },
          colors: [
            $themeColors.warning,
            $themeColors.dark,
            $themeColors.primary,
            $themeColors.secondary,
            $themeColors.info,
          ],
          dataLabels: {
            enabled: true,
          },
          stroke: {
            curve: "straight",
          },
          title: {
            text: "",
            align: "center",
          },
          grid: {
            borderColor: "#e7e7e7",
            row: {
              colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
              opacity: 0.5,
            },
          },
          xaxis: {
            type: "datetime",
          },
          yaxis: {
            // opposite: isRtl,
          },
          legend: {
            position: "top",
            horizontalAlign: "right",
            floating: true,
            offsetY: -25,
            offsetX: -5,
          },
          annotations: {
            yaxis: [],
          }
        },
      })

      const getColumns = async () => {
        axios.get("test-item").then((response) => {
          let formattedResponse = response.data.map((obj) => {
            return { field: obj.slug, key: obj.slug, label: i18n.t(obj.name), type: obj.type }
          })

          // comment move to end
          let commentIndex = formattedResponse
            .map(function (e) {
              return e.field
            })
            .indexOf("comment")
          if (commentIndex > -1) formattedResponse.push(formattedResponse.splice(commentIndex, 1)[0])

          itemColumns.value = formattedResponse
          tableColumns.value = [...tableDefaultColumns.value, ...itemColumns.value]

          testItemOptions.value = itemColumns.value
        })
      }

      const fetchTests = (params = null) => {
        loading.value = true
        axios
          .get(`/season/${seasonId.value}/team/${teamId.value}/test/last`, { params })
          .then((response) => {
            tableItems.value = Object.values(response.data)
            if(tableMountedItems.value.length == 0) tableMountedItems.value = tableItems.value
            loading.value = false
            pageTable.value = "last"

            let columns = itemColumns.value
            if (testItemsFilter.value.length > 0) {
              columns = columns.filter((column) => testItemsFilter.value.includes(column.field))
            }

            tableColumns.value = [...tableDefaultColumns.value, ...columns]
          })
          .catch((error) => {
            console.log(error)
            loading.value = false
          })
      }

      const fetchGroupTests = (params = null) => {
        loading.value = true
        axios
          .get(`/season/${seasonId.value}/team/${teamId.value}/test/group-by-item`, { params })
          .then((response) => {
            pageTable.value = "group"
            loading.value = false
            let columns = []
            topColumns.value = []

            tableItems.value = Object.values(response.data).map((item) => {
              const transformedItem = {}

              for (const key in item) {
                if (key !== "player") {
                  transformedItem[key] = {}

                  for (const date in item[key]) {
                    const value = item[key][date]
                    transformedItem[key][date] = {
                      value: value,
                      arrow: getArrow(item[key], date),
                    }
                  }
                } else {
                  transformedItem[key] = item[key]
                }
              }

              return transformedItem
            })

            if (tableItems.value.length > 0) {
              const firstPlayer = tableItems.value[0]
              for (const key in firstPlayer) {
                if (key !== "player") {
                  // TOP HEADER COLUMNS
                  topColumns.value.push({
                    title: itemColumns.value.find((item) => item.key == key)?.label,
                    colspan: Object.keys(firstPlayer[key]).length ?? 1,
                  })

                  for (const date in firstPlayer[key]) {
                    columns.push({
                      key: `${key}.${date}`,
                      label: `${date}`,
                      thClass: "text-center",
                      tdClass: "text-center",
                    })
                  }
                }
              }
            }

            tableColumns.value = [...tableDefaultColumns.value, ...columns]

            if (groupTableMode.value == "chart" && testItemsFilter.value.length == 1) {
              setChartData()
            }
          })
          .catch((error) => {
            console.log(error)
            loading.value = false
          })
      }

      function getArrow(data, date) {
        const dateList = Object.keys(data)
        const index = dateList.indexOf(date)

        if (index > 0) {
          const previousValue = parseFloat(data[dateList[index - 1]])
          const currentValue = parseFloat(data[date])

          if (!isNaN(previousValue) && !isNaN(currentValue)) {
            if (currentValue > previousValue) {
              return "up"
            } else if (currentValue < previousValue) {
              return "down"
            } else {
              return "equal"
            }
          }
        }

        return null
      }

      const getPlayers = async () => {
        axios
          .get(`season/${router.currentRoute.params.seasonId}/team/${router.currentRoute.params.id}`)
          .then((response) => {
            playerOptions.value = response.data.players
          })
      }

      function findMostDataPlayers(count) {
        // Oyuncuları ve veri sayılarını depolamak için bir nesne oluşturun
        const playerDataCounts = {}

        // Veriyi döngüye alarak oyuncuların toplam veri sayısını hesaplayın
        tableItems.value.forEach((player) => {
          let totalDataCount = 0

          for (const key in player) {
            const keyData = player[key]
            const isKeyNumber = key == "player" ? false : (itemColumns.value.find((item) => item.key == key)?.type == "number" ?? false)
            if (typeof keyData === "object" && isKeyNumber) {
              for (const date in keyData) {
                if (keyData[date] && keyData[date].value) {
                  totalDataCount++
                }
              }
            }
          }

          playerDataCounts[player.player.id] = totalDataCount
        })

        // Oyuncuları veri sayısına göre sıralayın
        const sortedPlayers = Object.keys(playerDataCounts).sort((a, b) => playerDataCounts[b] - playerDataCounts[a])

        // İstenen sayıda en çok veriye sahip oyuncuyu seçin
        const topPlayers = sortedPlayers.slice(0, count)

        return topPlayers.map((playerId) => parseInt(playerId))
      }

      function findMostUsedKey(playerIds) {
        const testItemKeys = []

        playerIds.forEach((playerId) => {
          const player = tableItems.value.find((player) => player.player.id == playerId)
          for (const key in player) {
            const isKeyNumber = key == "player" ? false : (itemColumns.value.find((item) => item.key == key)?.type == "number" ?? false)
            if (key !== "player" && isKeyNumber) {
              Object.keys(player[key]).forEach((date) => {
                if (player[key][date].value) {
                  testItemKeys.push(key)
                }
              })
            }
          }
        })

        const testItemKeysCount = {}
        testItemKeys.forEach((key) => {
          if (testItemKeysCount[key]) {
            testItemKeysCount[key]++
          } else {
            testItemKeysCount[key] = 1
          }
        })

        const sortedTestItemKeys = Object.keys(testItemKeysCount).sort(
          (a, b) => testItemKeysCount[b] - testItemKeysCount[a],
        )

        return sortedTestItemKeys[0]
      }

      function findMaxAndMinValue() {
        const values = []
        const key = testItemsFilter.value[0]

        tableMountedItems.value.forEach((player) => {
          const value = player[key]
          if (value != '-' && value != null) {
            values.push(value)
          }
        })

        return {
          max: Math.max(...values),
          min: Math.min(...values),
        }
      }

      function findTeamAverage() {
        const values = []
        const key = testItemsFilter.value[0]

        tableMountedItems.value.forEach((player) => {
          const value = player[key]
          if (value != '-' && value != null) {
            values.push(parseInt(value))
          }
        })

        return (values.reduce((a, b) => a + b, 0) / values.length).toFixed(0)
      }

      function findPositionAverage(playerIds) {
        const positions = []

        playerIds.forEach((playerId) => {
          const player = tableItems.value.find((player) => player.player.id == playerId)
          positions.push(player.player.position.id)
        })

        const uniquePositions = [...new Set(positions)]

        if (uniquePositions.length == 1) {
          const values = []
          const key = testItemsFilter.value[0]

          tableMountedItems.value.filter((player) => player.player.position.id == uniquePositions[0]).forEach((player) => {
            const value = player[key]
            if (value != '-' && value != null) {
              values.push(parseInt(value))
            }
          })

          return (values.reduce((a, b) => a + b, 0) / values.length).toFixed(0)
        }

        return null
        
      }

      const maxRes = ref(null);
      const minRes = ref(null);
      const teamAverageRes = ref(null);
      const positionAverageRes = ref(null);

      const setChartData = async () => {
        lineChartSimple.series = []
        lineChartSimple.chartOptions.annotations.yaxis = []
        lineChartSimple.chartOptions.title.text = testItemOptions.value.find(
          (item) => item.field == testItemsFilter.value[0],
        ).label

        const { max, min } = await findMaxAndMinValue()
        let maxY = max
        const teamAverage = findTeamAverage()
        const positionAverage = findPositionAverage(playersFilter.value)
        maxRes.value = max
        minRes.value = min
        teamAverageRes.value = teamAverage
        positionAverageRes.value =positionAverage

        tableItems.value.forEach((player) => {
          const series = {
            name: player.player.name,
            data: [],
          }

          for (const key in player) {
            if (key !== "player") {
              for (const date in player[key]) {
                const value = player[key][date].value
                if (value) {
                  series.data.push({
                    x: date,
                    y: value,
                  })

                  if (maxY && value > maxY.toString()) {
                    maxY = parseInt(value)
                  }
                }
              }
            }
          }

          lineChartSimple.series.push(series)
        })


        if (max && min) {
          lineChartSimple.chartOptions.yaxis = {
            max: maxY,
          }
          lineChartSimple.chartOptions.annotations.yaxis = [
            {
              y: max,
              borderColor: "#00E396",
              label: {
                borderColor: "#00E396",
                style: {
                  color: "#fff",
                  background: "#00E396",
                },
              },
            },
            {
              y: min,
              borderColor: "#FEB019",
              label: {
                borderColor: "#FEB019",
                style: {
                  color: "#fff",
                  background: "#FEB019",
                },
              },
            },
          ]
        }

        if (teamAverage) {
          lineChartSimple.chartOptions.annotations.yaxis.push({
            y: teamAverage,
            borderColor: "#775DD0",
            label: {
              borderColor: "#775DD0",
              style: {
                color: "#fff",
                background: "#775DD0",
              },
            },
          })
        }

        if (positionAverage) {
          lineChartSimple.chartOptions.annotations.yaxis.push({
            y: positionAverage,
            borderColor: "#F86624",
            label: {
              borderColor: "#F86624",
              style: {
                color: "#fff",
                background: "#F86624",
              },
            },
          })
        }

        triggerChart.value++
      }

      const filterTests = () => {
        let params = {
          players: playersFilter.value.join(","),
          tests: Array.isArray(testItemsFilter.value) ? testItemsFilter.value : [testItemsFilter.value],
          start_date: startDateFilter.value,
          end_date: endDateFilter.value,
        }

        if (startDateFilter.value || endDateFilter.value) fetchGroupTests(params)
        else fetchTests(params)
      }

      watch(groupTableMode, () => {
        if (groupTableMode.value != "chart") {
          testItemOptions.value = itemColumns.value
          return
        }

        testItemOptions.value = itemColumns.value.filter((item) => item.type == "number")
        playersFilter.value = findMostDataPlayers(2)
        testItemsFilter.value = [findMostUsedKey(playersFilter.value)]
        filterTests()
      })

      watch(pageTable, () => {
        if (pageTable.value == "last") {
          testItemOptions.value = itemColumns.value
        }
      })

      return {
        table,
        tableItems,
        tableColumns,
        topColumns,
        pageLength,
        loading,
        teamId,
        lang,
        pageTable,
        groupTableMode,
        filterTests,
        playersFilter,
        testItemsFilter,
        startDateFilter,
        endDateFilter,
        playerOptions,
        testItemOptions,
        lineChartSimple,
        triggerChart,
        positionAverageRes,
        teamAverageRes,
        maxRes,
        minRes,
      }
    },
  }
</script>
<style lang="scss">
  @import "@core/scss/vue/libs/vue-good-table.scss";
  @import "@core/scss/vue/libs/vue-flatpicker.scss";
  .vgt-inner-wrap {
    margin-top: 10px;
  }

  #group-test-table.table-bordered thead th {
    border-width: 2px !important;
  }
  .dropdown.b-form-datepicker > label {
    overflow: hidden;
  }
</style>
