export default [
  {
    path: "/tasks",
    name: "tasks",
    component: () => import("@/views/task/index.vue"),
    meta: {
      contentRenderer: "sidebar-left",
      contentClass: "todo-application",
    },
  },
  {
    path: "/tasks/:filter",
    name: "apps-task-filter",
    component: () => import("@/views/task/index.vue"),
    meta: {
      contentRenderer: "sidebar-left",
      contentClass: "todo-application",
      navActiveLink: "apps-todo",
    },
  },
  {
    path: "/tasks/tag/:tag",
    name: "apps-task-tag",
    component: () => import("@/views/task/index.vue"),
    meta: {
      contentRenderer: "sidebar-left",
      contentClass: "todo-application",
      navActiveLink: "apps-todo",
    },
  },
]
