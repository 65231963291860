<template>
  <b-overlay variant="white" :show="loading" spinner-variant="primary" blur="0" opacity=".75" rounded="sm">
    <b-card v-if="tableItems.length > 0">
      <!-- SET CARD HEADER -->
      <div class="card-header p-0 m-0">
        <div>
          <b-card-title class="mt-0">
            {{ $t("Tests") }}
          </b-card-title>
        </div>
        <div>
          <b-button @click="create" :disabled="creating" variant="primary">
            <b-spinner v-if="creating" small style="margin-bottom: 3px"></b-spinner>
            {{ $t("Add New") }}</b-button
          >
          <!-- ADD BUTTON -->
        </div>
      </div>
      <div>
        <vue-good-table
          ref="table"
          :columns="tableColumns"
          :rows="tableItems"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm,
          }"
          :pagination-options="{
            enabled: true,
            perPage: pageLength,
          }"
          mode="remote"
          @on-per-page-change="onPerPageChange"
          @on-page-change="onPageChange"
          :totalRows="totalItems"
        >
          <template slot="table-column" slot-scope="props">
            <span
              style="min-width: 125px !important; display: block; font-size: 12px !important"
              variant="outline-primary"
            >
              {{ props.column.label }}
            </span>
          </template>

          <template slot="table-row" slot-scope="props">
            <div class="d-flex align-items-start justify-content-start" v-if="props.column.field == 'user'">
              <p>
                {{ props.row.user && props.row.user.firstName && props.row.user.firstName }}
                {{ props.row.user && props.row.user.lastName && props.row.user.lastName.substr(0, 1) + "." }}
              </p>
            </div>

            <div class="d-flex align-items-start justify-content-start" v-if="props.column.field == 'date'">
              <b-link
                :to="{
                  name: 'team-tests-enter-date',
                  params: {
                    id: routerParams.id,
                    date: getFormattedDate(props.row.date),
                  },
                }"
              >
                <p>
                  {{ props.row.date | nativeDate }}
                </p>
              </b-link>
            </div>

            <div class="d-flex align-items-start justify-content-start" v-if="props.column.field == 'title'">
              <b-link
                :to="{
                  name: 'team-tests-enter-date',
                  params: {
                    id: routerParams.id,
                    date: getFormattedDate(props.row.date),
                  },
                }"
              >
                <p>
                  {{ props.row.title }}
                </p>
              </b-link>
            </div>

            <div class="d-flex align-items-start justify-content-center" v-if="props.column.field == 'playersCount'">
              <p>
                {{ Object.values(props.row.players).length }}
              </p>
            </div>

            <div class="d-flex justify-content-center" style="font-size: 1rem" v-if="props.column.field == 'average'">
              <span>
                {{
                  numberTestItems
                    .filter((item) => props.row.hasOwnProperty(item.slug))
                    .map((item) => `${item.name}: ${sumAllData(props.row[item.slug])}`)
                    .join(", ")
                }}
              </span>
            </div>
          </template>

          <!-- pagination -->
          <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <b-form-select
                  v-model="pageLength"
                  :options="[25, 50, 100]"
                  class="mx-1"
                  @input="(value) => props.perPageChanged({ currentPerPage: value })"
                />
                <span class="text-nowrap"> {{ props.total }} {{ $t("Has records") }} </span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value) => props.pageChanged({ currentPage: value })"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </div>
    </b-card>
    <b-card v-else>
      <div class="custom-search">
        <b-row>
          <b-col md="6"> </b-col>
          <b-col md="6" class="d-flex align-items-start justify-content-end">
            <b-button
              :to="{
                name: 'team-tests-enter',
                params: { id: routerParams.id },
              }"
              variant="primary"
              >{{ $t("Add New") }}</b-button
            >
          </b-col>
        </b-row>
      </div>
      <span> {{ $t("No records found") }} </span>
    </b-card>
  </b-overlay>
</template>

<script>
  import { ref, onMounted, watch } from "@vue/composition-api"
  import flatPickr from "vue-flatpickr-component"
  import { tr } from "flatpickr/dist/l10n/tr.js"

  import { VueGoodTable } from "vue-good-table"

  import i18n from "@/libs/i18n"
  import axios from "@axios"
  import router from "@/router"
  import { testService } from "@/services"
  import MediaVerticalAlignVue from "@/views/components/media/MediaVerticalAlign.vue"
  export default {
    components: {
      VueGoodTable,
      flatPickr,
    },
    data() {
      return {
        dir: false,
        rows: [],
        searchTerm: "",
      }
    },
    setup(props) {
      onMounted(async () => {
        await getColumns()
        await fetchTests()
      })
      const tableColumns = ref([])
      const tableDefaultColumns = [
        {
          field: "user",
          label: i18n.t("Reviewer"),
          width: "150px",
          filterOptions: {
            enabled: false,
            placeholder: `${i18n.t("Search")} `,
          },
        },
        {
          field: "date",
          label: i18n.t("Measurement Date"),
          width: "190px",
          filterOptions: {
            enabled: false,
            placeholder: `${i18n.t("Search")} `,
          },
        },
        {
          field: "title",
          label: i18n.t("Test Title"),
          width: "250px",
          filterOptions: {
            enabled: false,
            placeholder: `${i18n.t("Search")} `,
          },
        },
        {
          field: "playersCount",
          label: i18n.t("Test Grubu/kişi"),
          width: "150px",
          filterOptions: {
            enabled: false,
            placeholder: `${i18n.t("Search")} `,
          },
          sortable: false,
          thClass: "text-center",
        },
        {
          field: "average",
          label: i18n.t("Ortalama Sonuçlar"),
          filterOptions: {
            enabled: false,
            placeholder: `${i18n.t("Search")} `,
          },
          sortable: false,
          thClass: "text-center",
        },
      ]
      const table = ref(null)
      const loading = ref(false)
      const pageLength = ref(25)
      const tableItems = ref([])
      const seasonId = ref(Number(router.currentRoute.params.seasonId))
      const teamId = ref(Number(router.currentRoute.params.id))
      const currentPage = ref(1)
      const totalItems = ref(0)
      const queyParams = ref({ page: currentPage.value, row: pageLength.value })
      const testSer = new testService()
      const creating = ref(false)
      const numberTestItems = ref([])

      const onPerPageChange = (params) => {
        queyParams.value.row = params.currentPerPage
        fetchTests()
      }
      const onPageChange = (params) => {
        queyParams.value.page = params.currentPage
        fetchTests()
      }

      const getColumns = async () => {
        axios.get("test-item?type=number").then((response) => {
          numberTestItems.value = response.data

          tableColumns.value = [...tableDefaultColumns]
        })
      }

      const fetchTests = () => {
        loading.value = true
        testSer
          .groupByDate(seasonId.value, teamId.value, queyParams.value)
          .then((response) => {
            tableItems.value = Object.values(response.data)
            totalItems.value = response.count
            loading.value = false
          })
          .catch((error) => {
            loading.value = false
            console.log(error)
          })
      }

      const getFormattedDate = (date) => {
        date = new Date(date)
        let tempMonth = date.getMonth() + 1
        let month = tempMonth < 10 ? "0" + tempMonth.toString() : tempMonth
        let day = date.getDate()
        let year = date.getFullYear()
        return year + `-` + month + `-` + day
      }

      const sumAllData = (data) => {
        let sum = 0
        if (data && data.length > 0) {
          const num = data.reduce((a, b) => parseInt(a) + parseInt(b)) / data.length
          return num.toFixed(2)
        }

        return sum
      }

      const create = async () => {
        creating.value = true
        let nowDate = getFormattedDate(new Date())
        let testData = (await axios.post(`test/team/${teamId.value}/date`, { date: nowDate })).data
        if (testData.length == 0) {
          router.push({
            name: "team-tests-enter",
            params: {
              id: teamId.value,
            },
          })
        } else {
          router.push({
            name: "team-tests-enter-date",
            params: {
              id: teamId.value,
              date: nowDate,
            },
          })
        }
        creating.value = false
      }

      return {
        table,
        tableItems,
        tableColumns,
        pageLength,
        numberTestItems,
        sumAllData,
        getFormattedDate,
        loading,
        onPerPageChange,
        totalItems,
        onPageChange,
        create,
        creating,
      }
    },
  }
</script>
<style lang="scss">
  @import "@core/scss/vue/libs/vue-good-table.scss";
  @import "@core/scss/vue/libs/vue-flatpicker.scss";
  .vgt-inner-wrap {
    margin-top: 10px;
  }
</style>
