// import Vue from 'vue'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default class localService {
  constructor(key, value = undefined) {
    var dbName = "cacheDB"
    this.key = key
    this.data = JSON.parse(localStorage.getItem(dbName)) || {}

    if (value != undefined || value === null) {
      this.data[key] = value
      localStorage.setItem(dbName, JSON.stringify(this.data))
    }
    if (value == null) return this.data[key]
    if (value != null) return null
  }

  get() {
    return this.data[this.key]
  }
}
