import { ref, computed, watch } from "@vue/composition-api"
import store from "@/store"
import router from "@/router"

export default function useCalendarEventHandler(props, clearForm, emit) {
  // ------------------------------------------------
  // eventLocal
  // ------------------------------------------------
  const eventLocal = ref(JSON.parse(JSON.stringify(props.event.value)))
  const resetEventLocal = () => {
    eventLocal.value = JSON.parse(JSON.stringify(props.event.value))
  }
  watch(props.event, () => {
    // resetEventLocal()
    const shortEvent = props.event.value

    // TODO GET ACTIVE SEASON FOR THIS EVENT
    if (shortEvent.extendedProps.calendar == "training") {
      router.push({
        name: "team-training-preview",
        params: {
          seasonId: Number(localStorage.getItem("season")),
          id: props.event.value.extendedProps.eventTeamId,
          trainingId: props.event.value.extendedProps.eventTypeId,
        },
      })
    }
    if (shortEvent.extendedProps.calendar == "competition") {
      router.push({
        name: "competition-edit",
        params: {
          seasonId: Number(localStorage.getItem("season")),
          id: props.event.value.extendedProps.eventTeamId,
          competitionId: props.event.value.extendedProps.eventTypeId,
        },
      })
    }
  })

  // ------------------------------------------------
  // isEventHandlerSidebarActive
  // * Clear form if sidebar is closed
  // ------------------------------------------------
  watch(props.isEventHandlerSidebarActive, (val) => {
    // ? Don't reset event till transition is finished
    if (!val) {
      setTimeout(() => {
        clearForm.value()
      }, 350)
    }
  })
  // ------------------------------------------------
  // calendarOptions
  // ------------------------------------------------
  const calendarOptions = computed(() => store.state.calendar.calendarTypes)

  const onSubmit = () => {
    const eventData = JSON.parse(JSON.stringify(eventLocal))

    // * If event has id => Edit Event
    // Emit event for add/update event
    if (props.event.value.id) emit("update-event", eventData.value)
    else emit("add-event", eventData.value)

    // Close sidebar
    emit("update:is-event-handler-sidebar-active", false)
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  // ------------------------------------------------
  // guestOptions
  // ------------------------------------------------

  /* eslint-disable global-require */
  const guestsOptions = [
    // GET STAFFS
    // {
    //   avatar: require('@/assets/images/avatars/3-small.png'),
    //   name: 'Donna Frank'
    // },
  ]
  /* eslint-enable global-require */

  return {
    eventLocal,
    resetEventLocal,
    calendarOptions,

    // UI
    guestsOptions,
    onSubmit,
  }
}
