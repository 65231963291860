<template>
  <div>
    <b-card no-body>
      <b-card-header class="pb-50">
        <h5>{{ $t("Pass Network") }}</h5>
        <div style="min-width: 20rem">
          <v-select
            v-model="fileItem"
            :reduce="(option) => option.id"
            :getOptionLabel="(option) => option.name"
            :options="fileItems"
            :placeholder="$t('Select Analysis File')"
          />
        </div>
      </b-card-header>
      <div class="table-analysis">
        <b-overlay variant="white" :show="loading" spinner-variant="primary" blur="0" opacity=".75" rounded="sm">
          <b-table v-if="!loading" striped bordered responsive small :items="items" :fields="tableColumns">
            <template slot="empty">
              <div class="text-center">No rows to display!</div>
            </template>
            <template #head()="data">
              <span class="text-nowrap d-block">{{ data.label.substr(0, data.label.lastIndexOf(" ")) }}</span>
              <span class="text-center d-block">{{ data.label.substr(data.label.lastIndexOf(" ") + 1) }}</span>
            </template>
            <template #cell()="data">
              <span
                :class="[
                  { 'bg-dark': data.item.player == data.field.label },
                  { 'text-dark': data.item.player == data.field.label },
                ]"
              >
                {{ data.value }}
              </span>
            </template>
          </b-table>
        </b-overlay>
      </div>
    </b-card>
  </div>
</template>
<script>
  import vSelect from "vue-select"

  import { ref, reactive, watch, onBeforeMount, onMounted, computed } from "@vue/composition-api"
  import axios from "@axios"

  export default {
    components: { vSelect },
    props: {},
    data() {
      return {}
    },
    methods: {},
    computed: {},
    setup(props, { root }) {
      const fileItem = ref(null)
      const fileItems = ref([])
      const analysisItem = ref(null)
      const items = ref([])
      const loading = ref(false)
      const defaulColumns = [
        {
          label: "oyuncu",
          key: "player",
          stickyColumn: true,
        },
      ]
      const tableColumns = ref([])

      onMounted(async () => {
        await getFileItems()

        root.$on("reloadAnalysisFileItems", (data) => {
          getFileItems()
        })
      })

      const getFileItems = async () => {
        axios
          .get(`/competition/analysis-filter`, {
            params: {
              competition: root.routerParams.competitionId,
              isFile: 1,
            },
          })
          .then((response) => {
            fileItems.value = response.data.data
            // select last item
            if (fileItems.value.length > 0) fileItem.value = fileItems.value.at(-1).id
          })
      }

      const getFileContent = async () => {
        loading.value = true
        axios.get(`/competition/analysis/${fileItem.value}/content`).then((response) => {
          analysisItem.value = response.data
          readContent()
        })
      }

      watch(fileItem, (val) => {
        if (val) getFileContent()
      })

      const readContent = () => {
        let content = analysisItem.value.decodedContent
        let column = analysisItem.value.columnPlayers

        let columnData = column.map((data) => {
          return { label: "(" + data.id + ") " + data.name, key: data.id.toString() }
        })
        tableColumns.value = [...defaulColumns, ...columnData]

        items.value = Object.values(content).map((obj) => {
          return { ...obj, player: columnData.find((x) => x.key == obj.player.id).label }
        })
        loading.value = false
      }

      return {
        items,
        tableColumns,
        defaulColumns,
        loading,
        fileItem,
        fileItems,
      }
    },
  }
</script>
<style>
  .table-analysis tr td {
    white-space: nowrap !important;
  }
</style>
<style scoped>
  .bg-dark {
    display: block !important;
  }
  .bg-dark::before {
    content: "&nbsp;";
  }
</style>
