<template>
  <div>
    <transfer-history-sidebar :is-sidebar-active.sync="isSidebarActive" :player="player" :form.sync="form" />

    <b-row>
      <b-col md="4" v-for="pd in player.playerTransferHistories" :key="pd.id">
        <b-card style="border: 1px solid #ddd">
          <div class="card-header p-0 m-0 mb-2">
            <div class="d-flex align-items-center">
              <b-img v-if="pd.team.club && pd.team.club.logo" :src="pd.team.club.logo" rounded="0" width="50"></b-img>
              <div class="text-center" style="max-width: 150px">
                {{ pd.team.club.name }}<br />
                {{ pd.team.name }}
              </div>
            </div>
            <div>
              <span class="btn btn-light" @click="goSidebar(pd)">
                <feather-icon icon="EditIcon"></feather-icon>
              </span>
              <span class="btn btn-light ml-1" @click="goDeleteTransferHistory(pd.id)">
                <feather-icon icon="Trash2Icon"></feather-icon>
              </span>
            </div>
          </div>
          <div class="mb-2">
            <div>
              <span class="badge badge-light text-dark">
                {{ new Date(pd.startDate).toLocaleDateString() }}
              </span>
              <span class="badge badge-light text-dark ml-1">
                {{ pd.endDate ? new Date(pd.endDate).toLocaleDateString() : "-" }}
              </span>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-button
      variant="primary"
      v-b-toggle.sidebar-add-transfer-history
      type="button"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      @click="form = {}"
      >{{ $t("Add New") }}</b-button
    >
  </div>
</template>

<script>
  import { ref } from "@vue/composition-api"
  import { useRouter } from "@core/utils/utils"
  import TransferHistorySidebar from "@/views/scout/player/edit/components/transferHistory-sidebar.vue"

  export default {
    components: {
      TransferHistorySidebar,
    },
    props: {
      player: {
        default: null,
      },
    },

    methods: {
      goDeleteTransferHistory(transferHistoryId) {
        this.$bvModal
          .msgBoxConfirm(this.$t("Please confirm that you want to delete this."), {
            title: this.$t("Please Confirm"),
            size: "sm",
            okVariant: "primary",
            okTitle: this.$t("Yes"),
            cancelTitle: this.$t("No"),
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          })
          .then((value) => {
            if (value) {
              this.$http.delete(`scout/player/transfer-history/${transferHistoryId}`).then((res) => {
                this.toast(this.$t("Successfully Deleted"), "PlusIcon", "success")
                // TODO: REMOVE ID TO SETUSERGROUP OBJECT
                location.reload()
              })
            }
          })
      },
    },
    setup(props, { root }) {
      const { router, route } = useRouter()
      const isSidebarActive = ref(false)
      const form = ref({
        id: null,
        season: null,
        club: null,
        team: null,
        startDate: null,
        endDate: null,
        player: Number(router.currentRoute.params.playerId),
      })

      const playerId = ref(Number(router.currentRoute.params.playerId))
      const goSidebar = (data) => {
        form.value = { ...data }
        form.value.club = form.value.club.id
        form.value.team = form.value.team.id
        form.value.season = form.value.season.id
        isSidebarActive.value = true
      }

      return {
        goSidebar,
        playerId,
        isSidebarActive,
        form,
      }
    },
  }
</script>

<style lang="scss"></style>
