<template>
  <b-sidebar
    id="sidebar-location"
    :visible="isLocationActive"
    bg-variant="white"
    @shown="$emit('update:isLocationActive', true)"
    @hidden="
      $emit('update:isLocationActive', false)
      formTrigger++
    "
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
  >
    <template #default="{ hide }">
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">{{ $t("Location") }}</h5>
        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver" :key="formTrigger">
        <!-- Form -->
        <b-form v-if="locationFormData" class="p-2" @submit.prevent="handleSubmit(goSubmitLocation())">
          <!-- Location Name -->
          <validation-provider #default="{ errors }" name="locationName" rules="required">
            <b-form-group :label="$t('Location Name')" label-for="locationName">
              <b-form-input id="locationName" v-model="locationFormData.name" :state="errors[0] ? false : null" />

              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Location Address -->
          <validation-provider #default="{ errors }" name="address">
            <b-form-group :label="$t('Address')" label-for="address">
              <b-form-textarea
                id="address"
                rows="4"
                type="textarea"
                v-model="locationFormData.address"
                :state="errors[0] ? false : null"
              />

              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" type="submit">{{
              $t("Add")
            }}</b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              ref="hideClick"
              @click="hide"
              >{{ $t("Cancel") }}</b-button
            >
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>
<script>
  import { ValidationProvider, ValidationObserver } from "vee-validate"
  import { required } from "@validations"
  import formValidation from "@core/comp-functions/forms/form-validation"
  import Ripple from "vue-ripple-directive"
  import flatPickr from "vue-flatpickr-component"
  import vSelect from "vue-select"

  export default {
    components: {
      flatPickr,
      vSelect,

      // Form Validation
      ValidationProvider,
      ValidationObserver,
    },
    directives: {
      Ripple,
    },
    model: {
      prop: "isLocationActive",
      event: "update:is-location-active",
    },
    props: {
      isLocationActive: {
        type: Boolean,
        require: true,
      },
      submitLocation: {
        required: true,
      },
      updateLocation: {
        require: true,
      },
      locationFormData: {
        default: null,
      },
    },
    data() {
      return {
        required,
        formTrigger: 0,
      }
    },
    methods: {
      goSubmitLocation() {
        this.$refs.refFormObserver.validate().then((success) => {
          if (success) {
            if (this.locationFormData.id) {
              this.updateLocation(this.locationFormData)
            } else {
              this.submitLocation(this.locationFormData)
            }
            this.$refs.hideClick.click()
          }
        })
      },
    },
    setup(props, { emit }) {
      const { getValidationState } = formValidation()

      return {
        getValidationState,
      }
    },
  }
</script>

<style lang="scss">
  @import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
