import i18n from "@/libs/i18n"
import toastService from "@/services/toastService"
import store from "@/store"
// import VueLoading from 'vue-loading-overlay';
// import 'vue-loading-overlay/dist/vue-loading.css';
// Vue.use(VueLoading);
import Vue from "vue"
// avatarText

// We haven't added icon's computed property because it makes this mixin coupled with UI
export const globalMixins = {
  metaInfo: {
    head: [],
    bodyScript: [],
  },
  data() {
    return {
      footOptions: [
        {
          label: i18n.t("Right Foot"),
          value: 1,
          id: 1,
        },
        {
          label: i18n.t("Left Foot"),
          value: 0,
          id: 0,
        },
        {
          label: i18n.t("Both Feet"),
          value: 2,
          id: 2,
        },
      ],
      _loading: null,
      settings_: "",
    }
  },
  computed: {
    getSettings() {
      return (window && window._settings) || false
    },
    routerParams() {
      return this.$router.currentRoute.params
    },
    auth() {
      return localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData"))
        ? JSON.parse(localStorage.getItem("userData"))
        : false
    },
  },
  methods: {
    async setupCDN() {
      var script = document.createElement("script")
      script.onload = function () {
        Vue.use(window.VueLoading)
      }
      script.src = "https://cdn.jsdelivr.net/npm/vue-loading-overlay@3.4.3"
      await document.head.appendChild(script)
      var script = document.createElement("script")
      script.src = "https://cdn.jsdelivr.net/npm/notiflix@3.2.6/dist/notiflix-aio-3.2.6.min.js"
      await document.head.appendChild(script)

      var head = document.head
      var link = document.createElement("link")
      link.type = "text/css"
      link.rel = "stylesheet"
      link.href = "https://unpkg.com/vue-loading-overlay@3.4.3/dist/vue-loading.css"
      await head.appendChild(link)
      var head = document.head
      var link = document.createElement("link")
      link.type = "text/css"
      link.rel = "stylesheet"
      link.href = "https://cdn.jsdelivr.net/npm/notiflix@3.2.6/dist/notiflix-3.2.6.min.css"
      await head.appendChild(link)
    },
    setupAppConfig() {
      return new Promise((res) => {
        res(this.$http.get("/settings").then((resp) => (window._settings = resp.data)))
      })
    },
    validateSize(input, mb) {
      const fileSize = input.size / 1024 / 1024 // in MiB
      if (fileSize > mb) {
        this.toast(this.$t("File size must be less than") + " " + mb + "MB", "EditIcon", "error")
        return false
      }
      return true
    },
    getSetting(key) {
      if (!this.getSettings) return false
      let find = this.getSettings.find((item) => item.key === key)
      return find ? find.value : false
    },
    isNullOrEmptyCheck(value) {
      return value == null || value == "" || value == undefined
    },
    toast(title, icon = "EditIcon", variant = "success", text = null) {
      return toastService(title, icon, variant, text)
    },
    _setLoading(refId) {
      this._loading = this.$loading.show({
        // Optional parameters
        container: document.querySelector(refId),
      })
    },
    _clearLoading() {
      this._loading.hide()
    },
    confirmModal(message = this.$t("Please confirm that you want to delete this.")) {
      return new Promise((resolve, reject) => {
        this.$bvModal
          .msgBoxConfirm(message, {
            title: this.$t("Please Confirm"),
            size: "sm",
            okVariant: "primary",
            okTitle: this.$t("Yes"),
            cancelTitle: this.$t("Cancel"),
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          })
          .then((value) => {
            return value ? resolve(true) : reject(false)
          })
      })
    },
  },
  filters: {
    shortDate(date) {
      return date != null
        ? new Date(date).toLocaleDateString(i18n.locale, {
            year: "numeric",
            month: "numeric",
            day: "numeric",
          })
        : null
    },
    nativeDate(date) {
      return date != null
        ? new Date(date).toLocaleDateString(i18n.locale, {
            weekday: "short",
            year: "numeric",
            month: "short",
            day: "numeric",
          })
        : null
    },
    nativeTime(time) {
      return time != null
        ? new Date(time).toLocaleTimeString(i18n.locale, {
            hour: "numeric",
            minute: "numeric",
          })
        : null
    },
    nativeDateTime(dateTime) {
      return dateTime != null
        ? new Date(dateTime).toLocaleDateString(i18n.locale, {
            weekday: "short",
            year: "numeric",
            month: "short",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
          })
        : null
    },
    longDate(date) {
      return date != null
        ? new Date(date).toLocaleDateString(i18n.locale, {
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
          })
        : null
    },
    longDateTime(dateTime) {
      return dateTime != null
        ? new Date(dateTime).toLocaleDateString(i18n.locale, {
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
          })
        : null
    },
    upperCase(value) {
      return value.toUpperCase()
    },
  },
  setup() {
    const round = (value, step) => {
      step || (step = 1.0)
      var inv = 1.0 / step
      return Math.round(value * inv) / inv
    }
    const getAvg = (obj) => {
      let avg = 0
      let num = 0
      if (obj) {
        if (Array.isArray(obj.rate)) {
          obj.rate.filter((item) => {
            avg += item
          })
          num = avg / obj.rate.length
          return num.toFixed(1)
        } else {
          return ""
        }
      } else {
        return ""
      }
    }
    const getAllAvg = (obj) => {
      let avg = 0
      let arr = []
      const data = {
        physical: obj.physical,
        mental: obj.mental,
        tasteful: obj.tasteful,
        technical: obj.technical,
        technicalGoalKeeper: obj.technicalGoalKeeper,
        tactical: obj.tactical,
      }
      if (Object.values(data).length > 0) {
        Object.values(data).forEach((item) => {
          if (item && item.rate) {
            if (item.rate.length > 1) {
              avg = item.rate.reduce((a, b) => a + b) / item.rate.length
              arr.push(avg)
            } else {
              avg = item.rate[0]
              arr.push(avg)
            }
          }
        })
        if (arr.length > 1) {
          let num = arr.reduce((a, b) => a + b) / arr.length
          return round(num.toFixed(2), 0.5)
        } else {
          return arr[0]
        }
      } else {
        return ""
      }
    }
    const getClosestSeason = () => {
      const seasons = JSON.parse(store.getters["app/seasons"])
      const today = new Date()

      const season = seasons.reduce((closestSeason, currentSeason) => {
        if (currentSeason.startDate !== null) {
          const currentStartDate = new Date(currentSeason.startDate)

          // Bugünkü tarihten küçük ve en yakın olanı seç
          if (currentStartDate < today && (!closestSeason || currentStartDate > new Date(closestSeason.startDate))) {
            return currentSeason
          }
        }

        return closestSeason
      }, null)

      return season
    }
    const getVideoDuration = (file) =>
      new Promise((resolve, reject) => {
        let video = document.createElement("video")
        video.preload = "metadata"
        video.onloadedmetadata = () => resolve(video.duration)
        video.onerror = (error) => reject()
        video.src = window.URL.createObjectURL(file)
      })

    return {
      getAvg,
      getAllAvg,
      getVideoDuration,
      getClosestSeason,
    }
  },
}
