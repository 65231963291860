<template>
  <b-overlay :show="loading" rounded="sm">
    <div v-if="items.length > 0 && performance">
      <b-card class="w-100">
        <b-row>
          <b-col lg="11">
            <div class="d-flex mt-n1">
              <div class="custom-header-text d-flex align-items-center">
                <b-link
                  :to="{
                    name: 'team-player',
                    params: {
                      id: routerParams.id,
                    },
                  }"
                  class="d-flex"
                >
                  <b-avatar :src="items[0].avatar" size="55px" />
                  <div class="d-block ml-1">
                    <p class="mt-0 mb-0 text-left">
                      <b>{{ items[0].name }}</b>
                    </p>
                    <b-badge pill :variant="items[0].position.color" style="font-size: 11px">
                      {{ $t(items[0].position.name) }}
                    </b-badge>
                  </div>
                </b-link>
                <div class="ml-1">
                  {{ $t("Ability Evulate") }} {{ $t("Date") }} {{ selectedDate }}
                  <b-form-datepicker
                    id="performanceDate"
                    class="w-100"
                    v-model="selectedDate"
                    :date-disabled-fn="dateDisabled"
                    :locale="lang"
                    :disabled="localUser.id != performance.user.id"
                  />
                </div>
              </div>
            </div>
          </b-col>
          <b-col lg="1" sm="12" :class="$store.getters['app/currentBreakPoint'] === 'xs' ? 'my-2' : null"> </b-col>
          <b-col lg="6" v-if="$store.getters['app/currentBreakPoint'] === 'xs'">
            <b-form-group>
              <v-select
                v-model="ability"
                :autocomplete="`on`"
                :options="abilities"
                :reduce="(option) => option.id"
                :getOptionLabel="(option) => option.name"
              >
              </v-select>

              <b-form-checkbox
                class="my-2"
                v-model="showComment"
                name="checkbox-1"
                :value="false"
                :unchecked-value="true"
              >
                {{ $t("Comment") }}
              </b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>

      <b-row>
        <b-col lg="12" sm="12" order="2" order-md="1" v-if="!loading">
          <vue-good-table
            v-if="false"
            ref="table"
            :columns="fields"
            :rows="items"
            :isLoading.sync="loading"
            max-height="500px"
            :search-options="{
              enabled: false,
            }"
            :pagination-options="{
              enabled: false,
              perPage: pageLength,
            }"
          >
            <template slot="table-row" slot-scope="props">
              <div v-if="props.column.field == 'player'">
                <b-link
                  :to="{
                    name: 'team-player',
                    params: {
                      id: teamId,
                      playerId: props.row.id,
                    },
                  }"
                >
                  <div class="d-flex flex-column align-items-start">
                    <div class="d-flex align-items-start">
                      <b-avatar :src="props.row.avatar" size="35px" />
                      <div class="d-block ml-1">
                        <p class="small mt-0 text-left">{{ props.row.name }}</p>
                        <p class="mt-n1 text-left">
                          <b-badge pill :variant="props.row.position.color">
                            {{ $t(props.row.position.name) }}
                          </b-badge>
                        </p>
                      </div>
                    </div>
                  </div>
                </b-link>
              </div>

              <div v-else-if="props.column.field == 'ability'">
                <div v-if="performance">
                  <ability
                    :player.sync="props.row"
                    :ability.sync="ability"
                    :items.sync="performance.performanceAbilityItems"
                    :disabled="localUser.id != performance.user.id"
                  />
                  <p v-show="false">
                    {{ (props.column.label = findAbility(ability)) }}
                  </p>
                </div>
              </div>
              <div v-else-if="props.column.field == 'comment'">
                <div v-if="performance">
                  <comment
                    :player="props.row"
                    :items="performance.performanceComments"
                    :disabled="localUser.id != performance.user.id"
                  />
                </div>
              </div>
              <div v-else>
                {{ props.formattedRow[props.column.field] }}
              </div>
            </template>

            <template slot="pagination-bottom" slot-scope="props">
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap">
                    {{ $t("Showing") }}
                  </span>
                  <b-form-select
                    v-model="pageLength"
                    :options="['3', '5', '10']"
                    class="mx-1"
                    @input="(value) => props.perPageChanged({ currentPerPage: value })"
                  />
                  <span class="text-nowrap"> {{ props.total }} {{ $t("Has records") }} </span>
                </div>
                <div>
                  <b-pagination
                    :value="1"
                    :total-rows="props.total"
                    :per-page="pageLength"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @input="(value) => props.pageChanged({ currentPage: value })"
                  >
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18" />
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18" />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
          <span v-for="x in abilities" :key="Math.floor(Math.random() * 100 + 1) + '_' + x.id">
            <b-card-actions
              :title="$t(x.name)"
              v-if="x.key == 'technicalGoalKeeper' ? items[0].position.availablePositions.includes('GK') : true"
              action-collapse
            >
              <span v-for="y in x.abilityItems" :key="y.id + '_' + x.id">
                <vue-good-table
                  ref="table"
                  :columns="[
                    {
                      field: 'player',
                      label: $t('Player'),
                      sortable: false,
                      width: '250px',
                    },
                    {
                      field: 'ability',
                      label: y.name,
                      sortable: false,
                      width: '300px',
                      hidden: false,
                      thClass: 'text-center',
                    },
                    {
                      field: 'comment',
                      label: $t('Comment'),
                      sortable: false,
                      width: '190px',
                      hidden: true,
                    },
                  ]"
                  :rows="items"
                  :isLoading.sync="loading"
                  max-height="500px"
                  :search-options="{
                    enabled: false,
                  }"
                  :pagination-options="{
                    enabled: false,
                    perPage: pageLength,
                  }"
                  class="mt-1 only-player"
                >
                  <template slot="table-row" slot-scope="props">
                    <div v-if="props.column.field == 'player'" style="width: 240px">
                      <div class="d-flex flex-column align-items-start">
                        <div class="d-flex align-items-start">
                          {{ $t(y.name) }}
                        </div>
                      </div>
                    </div>

                    <div v-else-if="props.column.field == 'ability'">
                      <div v-if="performance">
                        <ability
                          :player.sync="props.row"
                          :ability.sync="y.id"
                          :items.sync="performance.performanceAbilityItems"
                          :disabled="localUser.id != performance.user.id"
                        />
                        <p v-show="false">
                          {{ (props.column.label = y.name) }}
                        </p>
                      </div>
                    </div>
                    <div v-else-if="props.column.field == 'comment'">
                      <div v-if="performance">
                        <comment
                          :player="props.row"
                          :items="performance.performanceComments"
                          :disabled="localUser.id != performance.user.id"
                        />
                      </div>
                    </div>
                    <div v-else>
                      {{ props.formattedRow[props.column.field] }}
                    </div>
                  </template>

                  <template slot="pagination-bottom" slot-scope="props">
                    <div class="d-flex justify-content-between flex-wrap">
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap">
                          {{ $t("Showing") }}
                        </span>
                        <b-form-select
                          v-model="pageLength"
                          :options="['3', '5', '10']"
                          class="mx-1"
                          @input="(value) => props.perPageChanged({ currentPerPage: value })"
                        />
                        <span class="text-nowrap"> {{ props.total }} {{ $t("Has records") }} </span>
                      </div>
                      <div>
                        <b-pagination
                          :value="1"
                          :total-rows="props.total"
                          :per-page="pageLength"
                          first-number
                          last-number
                          align="right"
                          prev-class="prev-item"
                          next-class="next-item"
                          class="mt-1 mb-0"
                          @input="(value) => props.pageChanged({ currentPage: value })"
                        >
                          <template #prev-text>
                            <feather-icon icon="ChevronLeftIcon" size="18" />
                          </template>
                          <template #next-text>
                            <feather-icon icon="ChevronRightIcon" size="18" />
                          </template>
                        </b-pagination>
                      </div>
                    </div>
                  </template>
                </vue-good-table>
              </span>
            </b-card-actions>
          </span>
        </b-col>

        <b-col v-if="false && $store.getters['app/currentBreakPoint'] !== 'xs'" lg="4" sm="12" order="1" order-md="1">
          <b-card style="overflow: scroll; max-height: 520px">
            <p class="h4 mb-1">{{ $t("Abilities") }}</p>
            <div v-for="(parent, parentIndex) in abilities" :key="parentIndex">
              <b-form-radio
                v-for="(item, index) in parent.abilityItems"
                :key="parentIndex + '_' + index"
                v-model="ability"
                :value="item.id"
                stacked
                name="radio-options"
              >
                <div class="d-flex" :class="showAbilityValue(item.id) ? 'text-primary' : ''">
                  {{ item.name }}
                  <p class="small" style="margin-left: 5px">
                    ({{ parent.name }} )
                    {{ showAbilityValue(item.id) ? "(" + showAbilityValue(item.id) + ")" : "" }}
                  </p>
                </div>
              </b-form-radio>
            </div>
            <b-form-checkbox
              id="checkbox-1"
              v-model="showComment"
              name="checkbox-1"
              :value="false"
              :unchecked-value="true"
            >
              {{ $t("Comment") }}
            </b-form-checkbox>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </b-overlay>
</template>

<script>
  import { useRouter } from "@core/utils/utils"
  import i18n from "@/libs/i18n"
  import vSelect from "vue-select"
  import { VueGoodTable } from "vue-good-table"
  import { useToast } from "vue-toastification/composition"

  import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
  import { ref, reactive, watch, onBeforeMount, onMounted } from "@vue/composition-api"

  import axios from "@axios"
  import ability from "./Ability"
  import comment from "./Comment"
  import { BCardActions } from "@/@core/components/b-card-actions"

  export default {
    components: {
      BCardActions,
      vSelect,
      VueGoodTable,
      ability,
      comment,
    },
    setup(props, { emit, root }) {
      const { router, route } = useRouter()
      const toast = useToast()

      onMounted(async () => {
        await getPlayers()
        await getPlayerPerformances()
      })

      let localUser = JSON.parse(localStorage.getItem("userData"))
      const now = new Date()
      const maxDate = new Date(now.getFullYear(), now.getMonth(), now.getDate())
      const teamId = ref(router.currentRoute.params.id)
      const routerParams = ref(router.currentRoute.params)
      const table = ref(null)
      const ability = ref(8)
      const items = ref([])
      const abilities = ref([])
      const performance = ref(null)
      const team = ref({})
      const loading = ref(false)
      const showLogs = ref(false)
      const showEditForm = ref(false)
      const showComment = ref(true)
      const pageLength = ref(10)
      const playerPerformances = ref(null)
      const selectedDate = ref(null)
      const lang = ref(i18n.locale)
      const fields = ref([
        {
          field: "player",
          label: i18n.t("Player"),
          sortable: false,
          width: "250px",
        },
        {
          field: "ability",
          label: i18n.t("Ability"),
          sortable: false,
          width: "300px",
          hidden: false,
          thClass: "text-center",
        },
        {
          field: "comment",
          label: i18n.t("Comment"),
          sortable: false,
          width: "190px",
          hidden: true,
        },
      ])

      const getPlayers = async () => {
        setLoading(true, "Fetching Data...")
        await axios.get(`/player/${routerParams.value.playerId}`).then((response) => {
          items.value = [response.data]
          team.value = response.data
          getAbilities()
          getPerformance()
        })
      }

      const getAbilities = () => {
        axios.get(`/ability`).then((response) => {
          abilities.value = response.data
        })
      }

      const getPerformance = () => {
        axios.get(`/performance/${routerParams.value.performanceId}`).then((response) => {
          performance.value = response.data
          selectedDate.value = getFormattedDate(new Date(response.data.date))
          //setItem();
          setTimeout(() => {
            setLoading(false, "Fetched Data")
          }, 1000)
        })
      }

      const getDate = (date) => {
        date = new Date(date)
        let month = date.getMonth() + 1
        let day = date.getDate()
        let year = date.getFullYear()
        return day + `/` + month + `/` + year
      }

      const dateDisabled = (ymd, date) => {
        return playerPerformances.value && playerPerformances.value.find((i) => i.date == ymd) ? false : true
      }

      const getFormattedDate = (date) => {
        let tempMonth = date.getMonth() + 1
        let month = tempMonth < 10 ? "0" + tempMonth.toString() : tempMonth
        let day = date.getDate() < 10 ? "0" + date.getDate().toString() : date.getDate()
        let year = date.getFullYear()
        return year + `-` + month + `-` + day
      }

      const setLoading = (value, text = null) => {
        loading.value = value
        if (showLogs.value === true && text !== null) {
          console.log(text)
        }
      }

      watch(showComment, (val) => {
        toggleColumn(2, val)
        //I SENT 2 FOR INDEX - IF YOU CHANGE ITEMS COLUMNS YOU NEED SET NEW INDEX TO HERE.
      })

      const handleScroll = (event) => {
        console.log(event)
      }

      watch(ability, (val) => {
        setLoading(true, "Fetching Data...")
        const scrollEl = document.getElementsByClassName("vgt-responsive")[0]
        scrollEl.scrollTo({
          top: 0,
          behavior: "smooth",
        })
        getPerformance()
      })

      watch(selectedDate, (val) => {
        let filteredPerformance = playerPerformances.value ? playerPerformances.value.find((i) => i.date == val) : null
        if (filteredPerformance && filteredPerformance.id != routerParams.value.performanceId) {
          getPlayerPerformances()
          router.push({ name: "team-player-performance-rating", params: { performanceId: filteredPerformance.id } })
          setLoading(true, "Fetching Data...")
          getPerformance()
        }
      })

      const findAbility = (id, label) => {
        let data = null
        abilities.value.filter((parent) => {
          parent.abilityItems.find((item) => {
            if (item.id === id) {
              data = item
            }
          })
        })
        if (data) return data.name
      }

      const toggleColumn = (index, val) => {
        // if (val) {
        //   ability.value = null;
        // }
        fields.value[index].hidden = val
        fields.value[index - 1].hidden = !val
      }
      const savePerformance = () => {
        axios
          .put(`/performance/${routerParams.value.performanceId}`, {
            date: performance.value.date,
            team: parseInt(routerParams.value.id),
          })
          .then((response) => {
            performance.value = response.data
            showEditForm.value = false
            toast({
              component: ToastificationContent,
              position: "bottom-right",
              props: {
                title: i18n.t("Successfully Saved"),
                icon: "CheckIcon",
                variant: "success",
              },
            })
          })
      }

      const showAbilityValue = (id) => {
        let data = null
        performance.value.performanceAbilityItems.find((item) => {
          if (item.id === id) {
            data = item
          }
        })
        if (data) return data.rate
      }

      const getPlayerPerformances = async () => {
        axios
          .get(
            `/season/${routerParams.value.seasonId}/performance/team/${routerParams.value.id}/player/${routerParams.value.playerId}`,
          )
          .then((response) => {
            playerPerformances.value = Object.values(response.data).map((i) => ({
              id: i.id,
              date: getFormattedDate(new Date(i.date)),
            }))
          })
          .catch((error) => {
            console.log(error)
          })
      }
      return {
        table,
        fields,
        items,
        ability,
        abilities,
        performance,
        getDate,
        team,
        loading,
        pageLength,
        showComment,
        findAbility,
        toggleColumn,
        showEditForm,
        savePerformance,
        maxDate,
        handleScroll,
        teamId,
        showAbilityValue,
        dateDisabled,
        selectedDate,
        lang,
        localUser,
      }
    },
  }
</script>

<style>
  .custom-header-text {
    margin-top: 10px;
    margin-right: 5px;
    font-size: 18px;
  }
  .vgt-left-align {
    @media (max-width: 767px) {
      max-width: 120px;
    }
  }
  /* .custom-radio {
  font-size: 28px;
}
.custom-control-label {
  font-size: 28px;
} */
</style>
<style lang="scss">
  @import "@core/scss/vue/libs/vue-good-table.scss";
  .only-player {
    table {
      thead {
        tr {
          display: none;
        }
      }
      tbody {
        tr {
          td {
            padding-bottom: 0.75em !important;
          }
        }
      }
    }
  }
  #performanceDate__dialog_ div[aria-disabled] {
    background-color: #cacaca73 !important;
  }
</style>
