var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex justify-content-end pr-2"},[_c('b-button',{attrs:{"to":'/season/' +
        _vm.seasonId +
        '/team/' +
        _vm.teamId +
        '/test/enter/' +
        new Date().toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }) +
        '/player/' +
        _vm.routerParams.playerId,"variant":"primary"}},[_vm._v(_vm._s(_vm.$t("Add New")))])],1),(_vm.tableItems.length > 0)?_c('b-card',{attrs:{"no-body":""}},[_c('div',[_c('vue-good-table',{ref:"table",attrs:{"columns":_vm.tableColumns,"rows":_vm.tableItems,"search-options":{
          enabled: true,
          externalQuery: _vm.searchTerm,
        },"pagination-options":{
          enabled: true,
          perPage: _vm.pageLength,
        }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'date')?_c('div',{staticClass:"d-flex align-items-start justify-content-start"},[_c('b-link',{attrs:{"to":{
                name: 'team-player-tests-enter-date',
                params: {
                  seasonId: _vm.seasonId,
                  id: _vm.teamId,
                  date: _vm.getFormattedDate(props.row.date),
                  playerId: _vm.routerParams.playerId,
                },
              }}},[_c('p',[_vm._v(" "+_vm._s(_vm._f("nativeDate")(props.row.date))+" ")])])],1):_vm._e()]}},{key:"pagination-bottom",fn:function(props){return [_c('div',{staticClass:"d-flex justify-content-between flex-wrap"},[_c('div',{staticClass:"d-flex align-items-center mb-0 mt-1"},[_c('b-form-select',{staticClass:"mx-1",attrs:{"options":['3', '5', '10']},on:{"input":function (value) { return props.perPageChanged({ currentPerPage: value }); }},model:{value:(_vm.pageLength),callback:function ($$v) {_vm.pageLength=$$v},expression:"pageLength"}}),_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(props.total)+" "+_vm._s(_vm.$t("Has records"))+" ")])],1),_c('div',[_c('b-pagination',{staticClass:"mt-1 mb-0",attrs:{"value":1,"total-rows":props.total,"per-page":_vm.pageLength,"first-number":"","last-number":"","align":"right","prev-class":"prev-item","next-class":"next-item"},on:{"input":function (value) { return props.pageChanged({ currentPage: value }); }},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}],null,true)})],1)])]}}],null,false,4219343763)})],1)]):_c('b-card',[_c('span',[_vm._v(" "+_vm._s(_vm.$t("No records found"))+" ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }