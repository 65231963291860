<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="closeSidebar"
    @change="(val) => $emit('is-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">{{ item ? $t("Save League") : $t("New League") }}</h5>
        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
      </div>

      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
          <!-- League Name -->
          <validation-provider #default="validationContext" :name="$t('Season Name')" rules="required">
            <b-form-group :label="$t('Season Name')" :label-for="$t('Season Name')">
              <b-form-input id="seasonName" v-model="formData.seasonName" />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" type="submit">
              {{ item ? $t("Save") : $t("Add") }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              {{ $t("Cancel") }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
  import {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BImg,
    BFormFile,
    BCardText,
  } from "bootstrap-vue"
  import { ValidationProvider, ValidationObserver } from "vee-validate"
  import { ref, watch } from "@vue/composition-api"
  import { required, alphaNum, email } from "@validations"
  import formValidation from "@core/comp-functions/forms/form-validation"
  import Ripple from "vue-ripple-directive"
  import vSelect from "vue-select"
  import store from "@/store"

  export default {
    components: {
      BSidebar,
      BForm,
      BCardText,
      BFormGroup,
      BFormInput,
      BFormInvalidFeedback,
      BButton,
      vSelect,
      BMedia,
      BMediaAside,
      BMediaBody,
      BLink,
      BImg,
      BFormFile,
      // Form Validation
      ValidationProvider,
      ValidationObserver,
    },
    directives: {
      Ripple,
    },
    model: {
      prop: "isSidebarActive",
      event: "is-sidebar-active",
    },
    props: {
      isSidebarActive: {
        type: Boolean,
        required: true,
      },
      item: {
        type: [Object, Boolean],
        required: false,
      },
    },
    data() {
      return {
        required,
        alphaNum,
        email,
      }
    },
    setup(props, { emit, root }) {
      const formData = {
        seasonName: null,
      }

      watch(
        () => props.item,
        (val) => {
          if (val !== false) {
            formData.seasonName = val.seasonName
          }
        },
      )

      const closeSidebar = () => {
        formData.seasonName = null
      }

      const onSubmit = () => {
        store.dispatch("app-player/addUser", userData.value).then(() => {
          emit("refetch-data")
          emit("update:is-add-new-user-sidebar-active", false)
        })
      }

      const userData = ref(JSON.parse(JSON.stringify(formData)))

      const resetuserData = () => {
        userData.value = JSON.parse(JSON.stringify(formData))
      }

      const { refFormObserver, getValidationState, resetForm } = formValidation(resetuserData)

      return {
        onSubmit,
        refFormObserver,
        getValidationState,
        resetForm,
        formData,
        closeSidebar,
      }
    },
  }
</script>

<style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";

  #add-new-user-sidebar {
    .vs__dropdown-menu {
      max-height: 200px !important;
    }
  }
</style>
