const competition = {
  id: 1,
  date: "23/03/2021 14:00",
  league: {
    id: 1,
    title: "",
  },
  location: {
    id: 1,
    title: "",
  },
  homeScore: 2,
  awayScore: 0,
  homeTeam: {
    id: 1,
    team: {
      id: 1,
      name: "",
      teamPlayers: [],
      logo: "",
      staff: {
        antrenor: {
          id: 1,
          role: "",
          name: "",
        },
        conditioer: {
          id: 1,
          role: "",
          name: "",
        },
      },
    },
  },
  awayTeam: {
    id: 1,
    team: {
      id: 1,
      name: "",
      teamPlayers: "",
      logo: "",
      staff: {
        antrenor: {
          id: 1,
          role: "",
          name: "",
        },
        conditioer: {
          id: 1,
          role: "",
          name: "",
        },
      },
    },
  },
  competitionEvents: [],
  competitionFormation: [],
  competitionStats: [],
}

const competitionForm = {
  season: {
    option: JSON.parse(localStorage.getItem("seasons")) || {},
    select:
      JSON.parse(localStorage.getItem("seasons")).find((data) => data.id == localStorage.getItem("season")) || null,
  },
  goal: {
    option: [
      { id: "FF", name: "Ayak" },
      { id: "HH", name: "Kafa Golü" },
      { id: "KK", name: "Kendi Kalesine Gol" },
      { id: "PP", name: "Penaltı" },
      { id: "SS", name: "Seri Penaltı" },
      { id: "FK", name: "Frikik" },
    ],
    type: "FF",
  },
  league: {
    select: null,
    option: [],
  },
  teams: {
    option: [],
  },
  homeTeam: {
    select: null,
  },
  awayTeam: {
    select: null,
  },
  competition: {
    date: {
      time: "12:00",
      date: new Date().toLocaleDateString("en"),
    },
    time: {
      select: 30,
      option: [30, 45, 50, 60, 70, 80, 90],
    },
  },
  location: {
    select: null,
    option: [],
  },
  formationStatTrigger: 0,
}

module.exports = {
  competitionStatic: competition,
  competitionFormStatic: competitionForm,
}
