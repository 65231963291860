<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Forgot Password v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <!-- logo -->
          <img :src="require('@teamLogoPath')" height="90" />
        </b-link>

        <b-card-title class="mb-1">
          {{ $t("Parental Form") }}
        </b-card-title>

        <b-overlay variant="white" :show="loading" spinner-variant="primary" blur="0" opacity=".75" rounded="sm">
          <!-- form -->
          <validation-observer ref="simpleRules">
            <b-form class="auth-forgot-password-form mt-2" @submit.prevent="validationForm">
              <!-- Name & Surname -->
              <b-form-group :label="$t('Your Name & Surname')" class="required" label-for="full-name">
                <validation-provider #default="{ errors }" name="FullName" rules="required">
                  <b-form-input
                    id="full-name"
                    v-model="formData.name"
                    :state="errors.length > 0 ? false : null"
                    name="full-name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- Subject -->
              <b-form-group :label="$t('Subject')" class="required" label-for="subject">
                <validation-provider #default="{ errors }" name="Subject" rules="required">
                  <b-form-input
                    id="subject"
                    v-model="formData.subject"
                    :state="errors.length > 0 ? false : null"
                    name="subject"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- Player's Name -->
              <b-form-group :label="$t('Player\'s Name')" class="required" label-for="player-name">
                <validation-provider #default="{ errors }" name="playerName" rules="required">
                  <b-form-input
                    id="player-name"
                    v-model="formData.playerName"
                    :state="errors.length > 0 ? false : null"
                    name="player-name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- Age Section -->
              <b-form-group :label="$t('Age')" class="required" label-for="age">
                <validation-provider #default="{ errors }" name="age" rules="required|integer">
                  <b-form-input
                    id="age"
                    v-model.number="formData.age"
                    :state="errors.length > 0 ? false : null"
                    name="age"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- Coach Name -->
              <b-form-group :label="$t('Coach Name')" label-for="coach-name">
                <validation-provider #default="{ errors }" name="coachName">
                  <b-form-input
                    id="coach-name"
                    v-model="formData.coachName"
                    :state="errors.length > 0 ? false : null"
                    name="coach-name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- Your Message -->
              <b-form-group :label="$t('Your message')" class="required" label-for="message">
                <validation-provider #default="{ errors }" name="message" rules="required">
                  <b-form-textarea
                    id="message"
                    v-model="formData.message"
                    :state="errors.length > 0 ? false : null"
                    name="message"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- submit button -->
              <b-button variant="primary" block type="submit">
                {{ $t("Send") }}
              </b-button>
              <small><span style="color: red">*</span> {{ $t("Required Field") }}</small>
            </b-form>
          </validation-observer>
        </b-overlay>

        <b-card-text class="text-center mt-2">
          <b-link :to="{ name: 'login' }"> <feather-icon icon="ChevronLeftIcon" /> {{ $t("Home Page") }} </b-link>
        </b-card-text>
      </b-card>
      <!-- /Forgot Password v1 -->
    </div>
  </div>
</template>

<script>
  import { ValidationProvider, ValidationObserver } from "vee-validate"
  import { BCard, BLink, BCardText, BCardTitle, BFormGroup, BFormInput, BForm, BButton } from "bootstrap-vue"
  import { required, integer } from "@validations"
  import axios from "@/libs/axios"
  import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
  import i18n from "@/libs/i18n"

  export default {
    components: {
      BCard,
      BLink,
      BCardText,
      BCardTitle,
      BFormGroup,
      BFormInput,
      BButton,
      BForm,

      ValidationProvider,
      ValidationObserver,
    },
    data() {
      return {
        formData: {},
        loading: false,
        integer,
        required,
      }
    },
    methods: {
      validationForm() {
        this.$refs.simpleRules.validate().then((success) => {
          if (success) {
            this.loading = true
            axios
              .post("/communication/request-form", this.formData)
              .then((res) => {
                this.loading = false
                console.log(res)
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: i18n.t("Form Submitted"),
                    icon: "EditIcon",
                    variant: "success",
                  },
                })
                this.formData = {}
                this.$router.push("/login")
              })
              .catch((err) => {
                this.loading = false
              })
          }
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import "@core/scss/vue/pages/page-auth.scss";

  .brand-logo svg {
    height: 120px;
    width: auto !important;
  }
</style>

<style>
  .auth-forgot-password-form .form-group.required label:after {
    content: "*";
    color: red;
  }
</style>
