<template>
  <div>
    <b-row class="d-flex justify-content-between my-2 mx-1">
      <div class="w-50">
        <!-- button on both side -->
        <b-input-group>
          <b-form-input v-model="searchQuery" :placeholder="$t('Search an League')" />
          <b-input-group-append>
            <b-button variant="outline-primary">
              {{ $t("Search") }}
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </div>

      <div>
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          type="button"
          variant="primary"
          @click="openSidebar(false)"
        >
          {{ $t("Add New") }}
        </b-button>
      </div>
    </b-row>

    <b-row v-if="filteredLeagues">
      <b-col lg="3" v-for="(item, index) in filteredLeagues" :key="index">
        <b-card action-collapse>
          <div class="d-flex justify-content-start playerCard">
            <div class="p-0">
              <div class="media-aside">
                <b-avatar :src="item.avatar" size="80px" />
              </div>
            </div>
            <div class="p-0">
              <span class="mt-0 ml-1 d-block">{{ item.leagueName }}</span>
              <span
                v-b-tooltip.hover.v-primary
                :title="$t('Team Count: ') + item.teamCount"
                class="badge mt-1 ml-1 badge-primary"
              >
                {{ item.teamCount }}
              </span>
              <feather-icon
                v-b-tooltip.hover.v-success
                @click="openSidebar(item)"
                :title="$t('Edit League')"
                class="ml-1"
                icon="EditIcon"
                size="18"
              />
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>

    <b-row v-else>
      <b-col cols="12" lg="3" v-for="(item, index) in leagues" :key="index">
        <b-card action-collapse>
          <div class="d-flex justify-content-start playerCard">
            <div class="p-0">
              <div class="media-aside">
                <b-avatar :src="item.avatar" size="80px" />
              </div>
            </div>
            <div class="p-0">
              <span class="mt-0 ml-1 d-block">{{ item.leagueName }}</span>
              <span
                v-b-tooltip.hover.v-primary
                :title="$t('Team Count: ') + item.teamCount"
                class="badge mt-1 ml-1 badge-primary"
              >
                {{ item.teamCount }}
              </span>
              <feather-icon
                v-b-tooltip.hover.v-success
                @click="openSidebar(item)"
                :title="$t('Edit League')"
                class="ml-1"
                icon="EditIcon"
                size="18"
              />
            </div>
          </div>
        </b-card>
      </b-col>

      <league-sidebar
        @is-sidebar-active="isSidebarClosed"
        :isAddNewUserSidebarActive="isSidebarActive"
        :item="tempItem"
      />
    </b-row>

    <league-sidebar @is-sidebar-active="isSidebarClosed" :isSidebarActive="isSidebarActive" :item="tempItem" />
  </div>
</template>

<script>
  import {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    VBTooltip,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
  } from "bootstrap-vue"
  import Ripple from "vue-ripple-directive"
  import vSelect from "vue-select"
  import leagueSidebar from "./leagueSidebar.vue"
  import { ref, watch } from "@vue/composition-api"
  import BCardActions from "@core/components/b-card-actions/BCardActions.vue"
  export default {
    components: {
      BCardActions,
      BCard,
      BRow,
      BCol,
      BFormInput,
      BButton,
      BTable,
      BMedia,
      BAvatar,
      BLink,
      BBadge,
      BDropdown,
      BDropdownItem,
      BPagination,
      leagueSidebar,
      BInputGroup,
      BInputGroupAppend,
      BInputGroupPrepend,

      vSelect,
    },
    directives: {
      "b-tooltip": VBTooltip,
      Ripple,
    },
    methods: {
      openSidebar(item) {
        this.isSidebarActive = !this.isSidebarActive
        this.tempItem = item
      },
      isSidebarClosed(event) {
        this.isSidebarActive = event
      },
    },
    setup(props, { root }) {
      const isSidebarActive = ref(false)
      const tempItem = ref(null)
      const searchQuery = ref(null)
      const filteredLeagues = ref(null)

      watch(searchQuery, (val) => {
        filteredList(val)
      })

      const leagues = [
        {
          id: 1,
          leagueName: "U-19",
          teamCount: 3,
          avatar: require("@/assets/images/teams/fenerbahce-a-s.png"),
        },
        {
          id: 2,
          leagueName: "U-17",
          teamCount: 5,
          avatar: require("@/assets/images/teams/fenerbahce-a-s.png"),
        },
        {
          id: 3,
          leagueName: "U-16",
          teamCount: 5,
          avatar: require("@/assets/images/teams/fenerbahce-a-s.png"),
        },
        {
          id: 4,
          leagueName: "U-15",
          teamCount: 5,
          avatar: require("@/assets/images/teams/fenerbahce-a-s.png"),
        },
        {
          id: 5,
          leagueName: "U-14",
          teamCount: 5,
          avatar: require("@/assets/images/teams/fenerbahce-a-s.png"),
        },
      ]

      const filteredList = (input) => {
        return (filteredLeagues.value = leagues.filter((item) =>
          item.leagueName.toLowerCase().includes(input.toLowerCase()),
        ))
      }

      return {
        leagues,
        filteredLeagues,
        isSidebarActive,
        tempItem,
        searchQuery,
      }
    },
  }
</script>

<style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }
</style>

<style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
</style>
