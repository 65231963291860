<template>
  <b-tabs>
    <!-- <b-tab>
      <template #title>
        <span @click="createorgchart('org')">{{ $t("Department") }}</span>
      </template>

      <div id="tree" ref="org"></div>
    </b-tab> -->
    <b-tab active>
      <template #title @click="createorgchart('tea')">
        <span @click="createorgchart('tea')">{{ $t("Team") }}</span>
      </template>

      <div id="tree" ref="tea"></div>
    </b-tab>
  </b-tabs>
</template>
<script>
  // import OrgChart from "@balkangraph/orgchart.js";
  export default {
    data() {
      return {
        nodes: [],
      }
    },

    metaInfo: {
      script: [
        {
          // rel: "preload",
          src: "https://balkan.app/js/latest/OrgChart.js",
        },
      ],
    },

    beforeMount() {
      this.createorgchart("tea")
    },

    methods: {
      createorgchart(el) {
        this.$http("/season/1/organization")
          .then((response) => {
            // this.nodes = response.data;
            OrgChart.templates.group.link =
              '<path stroke-linejoin="round" stroke="#aeaeae" stroke-width="1px" fill="none" d="M{xa},{ya} {xb},{yb} {xc},{yc} L{xd},{yd}" />'
            OrgChart.templates.group.nodeMenuButton = ""
            OrgChart.templates.group.min = Object.assign({}, OrgChart.templates.group)
            OrgChart.templates.group.min.imgs = "{val}"
            OrgChart.templates.group.min.description =
              '<text width="230" text-overflow="multiline" style="font-size: 14px;" fill="#aeaeae" x="125" y="100" text-anchor="middle">{val}</text>'

            this.oc(this.$refs[el], this.nodes)
            this.chart.on("click", function (sender, args) {
              if (args.node.tags.indexOf("group") != -1) {
                if (args.node.min) {
                  sender.maximize(args.node.id)
                } else {
                  sender.minimize(args.node.id)
                }
              }
              return false
            })

            this.chart.on("drop", function (sender, draggedNodeId, droppedNodeId) {
              var draggedNode = sender.getNode(draggedNodeId)
              var droppedNode = sender.getNode(droppedNodeId)

              if (droppedNode.tags.indexOf("group") != -1 && draggedNode.tags.indexOf("group") == -1) {
                var draggedNodeData = sender.get(draggedNode.id)
                draggedNodeData.pid = null
                draggedNodeData.stpid = droppedNode.id
                sender.updateNode(draggedNodeData)
                return false
              }
            })
          })
          .catch((error) => {
            // TODO: LOCAL
          })
      },
      oc: function (domEl, x) {
        this.chart = new OrgChart(domEl, {
          nodes: x,
          template: "ana", //base
          layout: OrgChart.treeRightOffset, //OrgChart.tree,
          enableDragDrop: false,
          nodeMouseClick: OrgChart.action.edit,
          // nodeMenu: {
          // 	// details: { text: "Details" },
          // 	// edit: { text: "Edit" },
          // 	// add: { text: "Add" },
          // 	// remove: { text: "Remove" },
          // },
          dragDropMenu: {
            addInGroup: { text: "Add in group" },
            addAsChild: { text: "Add as child" },
          },
          nodeBinding: {
            imgs: function (sender, node) {
              if (node.min) {
                var val = ""
                var count = node.stChildrenIds.length > 5 ? 5 : node.stChildrenIds.length
                var x = node.w / 2 - (count * 32) / 2

                for (var i = 0; i < count; i++) {
                  var data = sender.get(node.stChildrenIds[i])
                  val +=
                    '<image xlink:href="' +
                    data.img +
                    '" x="' +
                    (x + i * 32) +
                    '" y="45" width="32" height="32" ></image>'
                }
                return val
              }
            },
            description: "description",
            field_0: "name",
            field_1: "title",
            img_0: "img",
          },
          tags: {
            group: {
              template: "group",
            },
            "ply-group": {
              subTreeConfig: {
                columns: 3,
              },
            },
            "sales-group": {
              subTreeConfig: {
                columns: 1,
              },
            },
            "hrs-group": {
              min: true,
              subTreeConfig: {
                columns: 2,
              },
            },
          },

          // nodeMenu: {
          // 	details: { text: "Details" },
          // 	edit: { text: "Edit" },
          // 	add: { text: "Add" },
          // 	remove: { text: "Remove" },
          // },
        })
      },
      async getStaff() {
        await this.$http("/staff").then((response) => {
          response.data.filter((item) => {
            if (item && item.department) {
              this.nodes.push({
                id: item.id + `-` + item.firstName,
                stpid: item.department.id,
                name: item.firstName + ` ` + item.lastName,
                title: item.job.name,
                img: item.avatar ? item.avatar : require("@/assets/images/avatars/user.png"),
              })
            }
          })
        })
      },
      async getDepartments() {
        await this.$http("/department").then((response) => {
          response.data.filter((item) => {
            if (item) {
              this.nodes.push({
                id: item.id,
                pid: item.parent ? item.parent.id : 0,
                name: item.name,
                tags: ["hrs-group", "group"],
                description: item.description,
              })
            }
          })
        })
      },
    },

    mounted() {},
    created() {
      this.getDepartments()
      this.getStaff()
    },
  }
</script>

<style scoped>
  html,
  body {
    margin: 0px;
    padding: 0px;
    width: 100%;
    height: 100%;
    font-family: Helvetica;
    overflow: hidden;
  }

  #tree {
    width: 100%;
    height: 100%;
  }

  /*partial*/
  [lcn="hr-team"] > rect {
    fill: #ffca28;
  }

  [lcn="sales-team"] > rect {
    fill: #f57c00;
  }

  [lcn="top-management"] > text,
  .assistant > text {
    fill: #aeaeae;
  }

  [lcn="top-management"] circle,
  [lcn="assistant"] {
    fill: #aeaeae;
  }

  .assistant > rect {
    fill: #ffffff;
  }

  .assistant [control-node-menu-id] > circle {
    fill: #aeaeae;
  }

  .it-team > rect {
    fill: #b4ffff;
  }

  .it-team > text {
    fill: #039be5;
  }

  .it-team > [control-node-menu-id] line {
    stroke: #039be5;
  }

  .it-team > g > .ripple {
    fill: #00efef;
  }

  .hr-team > rect {
    fill: #fff5d8;
  }

  .hr-team > text {
    fill: #ecaf00;
  }

  .hr-team > [control-node-menu-id] line {
    stroke: #ecaf00;
  }

  .hr-team > g > .ripple {
    fill: #ecaf00;
  }

  .sales-team > rect {
    fill: #ffeedd;
  }

  .sales-team > text {
    fill: #f57c00;
  }

  .sales-team > [control-node-menu-id] line {
    stroke: #f57c00;
  }

  .sales-team > g > .ripple {
    fill: #f57c00;
  }

  html,
  body {
    margin: 0px;
    padding: 0px;
    width: 100%;
    height: 100%;
    font-family: Helvetica;
    overflow: hidden;
  }

  #tree {
    width: 100%;
    height: 100%;
  }

  /*partial*/
  [lcn="hr-team"] > rect {
    fill: #ffca28;
  }

  [lcn="sales-team"] > rect {
    fill: #f57c00;
  }

  [lcn="top-management"] > rect {
    fill: #f2f2f2;
  }

  [lcn="top-management"] > text,
  .assistant > text {
    fill: #aeaeae;
  }

  [lcn="top-management"] circle,
  [lcn="assistant"] {
    fill: #aeaeae;
  }

  .assistant > rect {
    fill: #ffffff;
  }

  .assistant [control-node-menu-id] > circle {
    fill: #aeaeae;
  }

  .it-team > rect {
    fill: #b4ffff;
  }

  .it-team > text {
    fill: #039be5;
  }

  .it-team > [control-node-menu-id] line {
    stroke: #039be5;
  }

  .it-team > g > .ripple {
    fill: #00efef;
  }

  .hr-team > rect {
    fill: #fff5d8;
  }

  .hr-team > text {
    fill: #ecaf00;
  }

  .hr-team > [control-node-menu-id] line {
    stroke: #ecaf00;
  }

  .hr-team > g > .ripple {
    fill: #ecaf00;
  }

  .sales-team > rect {
    fill: #ffeedd;
  }

  .sales-team > text {
    fill: #f57c00;
  }

  .sales-team > [control-node-menu-id] line {
    stroke: #f57c00;
  }

  .sales-team > g > .ripple {
    fill: #f57c00;
  }
</style>
