import http from "@/libs/axios"
import toastService from "./toastService"
import i18n from "@/libs/i18n"

export default class scoutPlayerService {
  constructor() {}

  async get(filters) {
    return new Promise((resolve, reject) => {
      http
        .get("/scout/players", { params: { ...filters } })
        .then((response) => resolve(response))
        .catch((error) => reject(error))
    })
  }
}
