<template>
  <div class="auth-wrapper auth-v1 px-2" :style="{ 'background-image': 'url(\'' + elementBg + '\')' }">
    <div class="auth-inner py-2 invite-link">
      <!-- Login v1 -->
      <b-card class="mb-0" v-if="showInvite">
        <div class="d-flex justify-content-start">
          <div class>
            <img height="50px" :src="invite.team.club.logo" />
          </div>
          <div class="ml-2">
            <div class="h2">{{ invite.team.name }}</div>
            <div class="d6">{{ invite.training.title }}</div>
          </div>
        </div>

        <b-card-text class="text-center mt-2">
          <span class="h4 d-block">{{ new Date(invite.training.startDate).toLocaleDateString() }}</span>
          <span class="h5 d-block">{{ getDayName(invite.training.startDate) }}</span>
          <span class="text-primary d-block h2">{{
            new Date(invite.training.startDate).toLocaleTimeString().substr(0, 5)
          }}</span>
          <span class="d-block small-text" v-text="invite.training.location.name"></span>

          <div class="mt-1 d-flex justify-content-between">
            <div>
              <b-button-group>
                <b-dropdown variant="danger" right text="Katılmıyorum">
                  <b-dropdown-item v-for="rsx in invite.reasons" :key="rsx.id" @click="submitInvite(rsx.id)">{{
                    rsx.name
                  }}</b-dropdown-item>
                </b-dropdown>
              </b-button-group>
            </div>

            <div>
              <button class="btn btn-success" @click="submitInvite(true)">Katılıyorum</button>
            </div>
          </div>
          <div class="d-block mt-2 profile">
            <div class="d-flex justify-content-start mb-1">
              <b-avatar
                class="mr-2"
                size="40"
                :src="invite.player.avatar"
                :text="avatarText(invite.player.first_name + ' ' + invite.player.last_name)"
              ></b-avatar>
              <div class="profile-user-info">
                <h6 v-text="invite.player.first_name"></h6>
                <h6 style="margin-left: -5px" v-text="invite.player.last_name.trim()"></h6>
              </div>
            </div>
          </div>
        </b-card-text>
      </b-card>
      <!-- /Login v1 -->
    </div>
  </div>
</template>

<script>
  import { ValidationProvider, ValidationObserver } from "vee-validate"
  import {
    BButtonGroup,
    BButton,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BLink,
    BCardTitle,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
  } from "bootstrap-vue"
  import { required, email } from "@validations"
  import { togglePasswordVisibility } from "@core/mixins/ui/forms"
  import { getHomeRouteForLoggedInUser } from "@/auth/utils"
  import axios from "axios"
  import { avatarText } from "@/@core/utils/filter"

  export default {
    components: {
      // BSV
      BButtonGroup,
      BButton,
      BDropdown,
      BDropdownItem,
      BDropdownDivider,
      BForm,
      BFormInput,
      BFormGroup,
      BCard,
      BCardTitle,
      BLink,
      BCardText,
      BInputGroup,
      BInputGroupAppend,
      BFormCheckbox,
      ValidationProvider,
      ValidationObserver,
    },
    mixins: [togglePasswordVisibility],
    data() {
      return {
        userEmail: "",
        password: "",
        status: "",
        // validation rules
        required,
        email,
        invite: {},
        showInvite: false,
        elementBg: require("@/assets/images/banner/heading_bg.png"),
      }
    },
    beforeMount() {
      if (location.href.includes("lang=fr")) this.$i18n.locale = "fr"
      if (location.href.includes("lang=en")) this.$i18n.locale = "en"
      if (location.href.includes("lang=tr")) this.$i18n.locale = "tr"
    },
    created() {
      axios
        .get(`${process.env.VUE_APP_API}/invite/training/${this.routerParams.hash}`)
        // .get("https://run.mocky.io/v3/86dd1929-7ddf-4df8-8dc1-a751d7b7a9d8")
        .then((succ) => {
          this.invite = succ.data
          this.showInvite = true
        })
        .catch((err) => {
          // TODO: TRANSLATE
          this.$bvToast.toast("Bilinmeyen hata kodu.", {
            title: "Bir hata oluştu",
          })
          this.showInvite = false
        })
    },
    computed: {},
    methods: {
      getDayName(dateData) {
        let dd = new Date(dateData).getDay()
        let ddn = ["Pazar", "Pazartesi", "Salı", "Çarşamba", "Perşembe", "Cuma", "Cumartesi"]
        return ddn[dd]
      },
      submitInvitePost({ answer, reason }) {
        axios
          .post(`${process.env.VUE_APP_API}/invite/training/${this.routerParams.hash}`, { answer, reason })
          .then((succ) => {
            this.$bvToast.toast("Antrenman katılımınız kaydedildi", {
              title: "Kaydedildi",
            })
          })
          .catch((err) => {
            this.$bvToast.toast("Bilinmeyen hata kodu.", {
              title: "Bir hata oluştu",
            })
          })
      },
      submitInvite(reasonType) {
        if (reasonType === true) {
          this.submitInvitePost({ answer: 1, reason: null })
        } else {
          this.submitInvitePost({ answer: 0, reason: reasonType })
        }
      },
    },
    setup(props, { root }) {
      return {
        avatarText,
      }
    },
  }
</script>

<style lang="scss">
  @import "@core/scss/vue/pages/page-auth.scss";

  .auth-wrapper {
    background-position: bottom;
    background-repeat: no-repeat;
    min-height: 90vh;
  }

  .brand-logo svg {
    height: 120px;
    width: auto !important;
  }

  .invite-link {
    .hour {
      padding: 2rem;
      max-width: 120px;
      margin: 0 auto;
      font-size: 1.5rem;
    }

    .profile {
      .badge {
        float: left;
      }
    }
  }
</style>
