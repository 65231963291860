<template>
  <div>
    <b-sidebar
      id="sidebar-task-handler"
      sidebar-class="sidebar-lg"
      :visible="isTaskHandlerSidebarActive"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="(val) => $emit('update:is-task-handler-sidebar-active', val)"
      @hidden="clearForm"
      width="73%"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <b-button
            v-if="taskLocal.id"
            size="sm"
            :variant="taskLocal.isCompleted ? 'outline-success' : 'outline-secondary'"
            @click="taskLocal.isCompleted = !taskLocal.isCompleted"
          >
            {{ taskLocal.isCompleted ? $t("Completed") : $t("Mark Complete") }}
          </b-button>
          <h5 v-else class="mb-0">
            {{ $t("Add Task") }}
          </h5>
          <div>
            <feather-icon
              v-show="taskLocal.id && !taskLocal.isDeleted"
              icon="TrashIcon"
              class="cursor-pointer"
              @click="
                $emit('remove-task')
                hide()
              "
            />
            <!--<feather-icon
              class="ml-1 cursor-pointer"
              icon="StarIcon"
              size="16"
              :class="{ 'text-warning': taskLocal.isImportant }"
              @click="taskLocal.isImportant = !taskLocal.isImportant"
            />-->
            <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
          </div>
        </div>

        <!-- Body -->
        <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
          <!-- Form -->
          <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
            <!-- Title -->
            <validation-provider #default="validationContext" name="title" rules="required">
              <b-form-group :label="$t('Title')" label-for="task-title">
                <b-form-input
                  id="task-title"
                  v-model="taskLocal.title"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Assignee -->
            <validation-provider #default="validationContext" name="assignee" rules="required">
              <b-form-group :label="$t('Assignee')" label-for="assignee">
                <v-select
                  v-model="taskLocal.assignee"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="assigneeOptions"
                  :reduce="(option) => option.id"
                  :getOptionLabel="(option) => `${option.firstName} ${option.lastName}`"
                  label="id"
                  class="assignee-selector"
                  input-id="assignee"
                  multiple
                >
                  <template #option="{ avatar, firstName, lastName }">
                    <b-avatar size="26" :src="avatar" :text="avatarText(firstName + ' ' + lastName)" />
                    <span class="ml-50 d-inline-block align-middle"> {{ firstName + " " + lastName }} </span>
                  </template>

                  <template #selected-option="{ avatar, firstName, lastName }">
                    <b-avatar size="26" :src="avatar" :text="avatarText(firstName + ' ' + lastName)" />

                    <span class="ml-50 d-inline-block align-middle"> {{ firstName + " " + lastName }} </span>
                  </template>
                </v-select>

                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- due Date -->
            <b-form-group :label="$t('Due Date')" label-for="due-date">
              <b-input-group class="input-group-merge">
                <flat-pickr v-model="taskLocal.endDate" class="form-control" />

                <b-input-group-append v-if="taskLocal.endDate">
                  <b-link class="btn default" @click="taskLocal.endDate = null">
                    <i class="code-toggler feather icon-x cursor-pointer"></i>
                  </b-link>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>

            <!--Tag -->

            <b-form-group :label="$t('Tags')" label-for="tag">
              <v-select
                v-model="taskLocal.tags"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                multiple
                :close-on-select="false"
                :options="tagOptions"
                :reduce="(option) => option.value"
                input-id="tags"
              />
            </b-form-group>

            <!-- Description -->
            <validation-provider #default="validationContext" name="description" rules="required">
              <b-form-group :label="$t('Description')" label-for="task-description">
                <quill-editor
                  id="quil-content"
                  v-model="taskLocal.description"
                  :options="editorOption"
                  class="border-bottom-0"
                  @ready="onEditorReady($event)"
                />
                <div id="quill-toolbar" class="d-flex justify-content-end border-top-0">
                  <!-- Add a bold button -->
                  <button class="ql-bold" />
                  <button class="ql-italic" />
                  <button class="ql-underline" />
                  <button class="ql-align" />
                  <button class="ql-link" />
                  <button class="ql-image" />
                </div>
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <b-form-group :label="$t('Completed')">
              <b-form-checkbox v-model="taskLocal.isCompleted" switch></b-form-checkbox>
            </b-form-group>

            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" type="submit">
                {{ taskLocal.id ? $t("Update") : $t("Add") }}
              </b-button>
              <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="reset" variant="outline-secondary">
                {{ $t("Reset") }}
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
  import { BSidebar, BForm, BFormGroup, BFormInput, BAvatar, BButton, BFormInvalidFeedback } from "bootstrap-vue"
  import vSelect from "vue-select"
  import flatPickr from "vue-flatpickr-component"
  import Ripple from "vue-ripple-directive"
  import { ValidationProvider, ValidationObserver } from "vee-validate"
  import { required, email, url } from "@validations"
  import { avatarText } from "@core/utils/filter"
  import formValidation from "@core/comp-functions/forms/form-validation"
  import { toRefs, ref, onMounted } from "@vue/composition-api"
  import { quillEditor } from "vue-quill-editor"
  import useTaskHandler from "./useTaskHandler"
  import axios from "@axios"

  export default {
    components: {
      // BSV
      BButton,
      BSidebar,
      BForm,
      BFormGroup,
      BFormInput,
      BAvatar,
      BFormInvalidFeedback,

      // 3rd party packages
      vSelect,
      flatPickr,
      quillEditor,

      // Form Validation
      ValidationProvider,
      ValidationObserver,
    },
    directives: {
      Ripple,
    },
    model: {
      prop: "isTaskHandlerSidebarActive",
      event: "update:is-task-handler-sidebar-active",
    },
    props: {
      isTaskHandlerSidebarActive: {
        type: Boolean,
        required: true,
      },
      task: {
        type: Object,
        required: true,
      },
      clearTaskData: {
        type: Function,
        required: true,
      },
    },
    data() {
      return {
        required,
        email,
        url,
      }
    },
    methods: {
      uploadFunction(e) {
        var form = new FormData()
        form.append("file[]", e.target.files[0])
      },
      onEditorReady(editor) {
        editor.getModule("toolbar").addHandler("image", () => this.imageHandler(editor))
      },
      imageHandler: async function (editor) {
        const input = document.createElement("input")

        input.setAttribute("type", "file")
        input.setAttribute("accept", "image/*")
        input.click()

        input.onchange = async () => {
          const file = input.files[0]
          const formData = new FormData()

          formData.append("file", file)

          // Save current cursor state
          const range = editor.getSelection(true)

          await axios
            .post("/todo/imageUpload", formData)
            .then(async ({ data }) => {
              // Insert uploaded image
              await editor.insertEmbed(range.index, "image", data)
              console.log(data)
            })
            .catch(({ response }) => {
              alert("error")
            })
        }
      },
    },
    setup(props, { emit, root }) {
      const assigneeOptions = ref([])

      onMounted(async () => {
        await getUser()
      })

      const getUser = () => {
        axios.get("/user").then((response) => {
          assigneeOptions.value = response.data
        })
      }

      const {
        taskLocal,
        resetTaskLocal,

        // UI
        onSubmit,
        tagOptions,
        resolveAvatarVariant,
      } = useTaskHandler(toRefs(props), emit)

      const { refFormObserver, getValidationState, resetForm, clearForm } = formValidation(
        resetTaskLocal,
        props.clearTaskData,
      )

      const editorOption = {
        modules: {
          toolbar: "#quill-toolbar",
        },
        placeholder: "",
      }

      return {
        // Add New
        taskLocal,
        onSubmit,
        assigneeOptions,
        tagOptions,

        // Form Validation
        resetForm,
        clearForm,
        refFormObserver,
        getValidationState,

        // UI
        editorOption,
        resolveAvatarVariant,

        // Filter/Formatter
        avatarText,
      }
    },
  }
</script>

<style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
  @import "@core/scss/vue/libs/vue-flatpicker.scss";
  @import "@core/scss/vue/libs/quill.scss";
</style>

<style lang="scss" scoped>
  @import "~@core/scss/base/bootstrap-extended/include";

  .assignee-selector {
    ::v-deep .vs__dropdown-toggle {
      padding-left: 0;
    }
  }

  #quil-content ::v-deep {
    > .ql-container {
      border-bottom: 0;
    }

    + #quill-toolbar {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom-left-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
    }
  }
  .default {
    border-color: #d8d6de;
    border-left-color: white;
  }
</style>
