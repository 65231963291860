import { ref, onMounted } from "@vue/composition-api"
import { isVerticalNavLinkActive, navLinkProps } from "@core/layouts/utils"
import { useRouter } from "@core/utils/utils"

export default function useVerticalNavMenuLink(item) {
  const { router, route } = useRouter()

  const isActive = ref(false)

  const linkProps = navLinkProps(item)

  const updateIsActive = () => {
    isActive.value = isVerticalNavLinkActive(item)
  }

  onMounted(() => {
    updateIsActive()
  })

  const checkWhenPageReloadedIsActive = () => {
    const currentRouteId = router.currentRoute.params.id
    const currentRouteName = router.currentRoute.name

    //console.log(router.currentRoute)
  }

  onMounted(() => {
    checkWhenPageReloadedIsActive()
  })

  return {
    isActive,
    linkProps,
    updateIsActive,
  }
}
