<template>
  <b-sidebar
    id="sidebar-add-edu"
    :visible="isAddEduActive"
    bg-variant="white"
    @shown="$emit('update:isAddEduActive', true)"
    @hidden="
      $emit('update:isAddEduActive', false)
      formTrigger++
    "
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
  >
    <template #default="{ hide }">
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">{{ $t("Education") }}</h5>
        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver" :key="formTrigger">
        <!-- Form -->
        <b-form
          v-if="playerEduForm"
          class="p-2"
          @submit.prevent="handleSubmit(goSubmitEdu())"
          @reset.prevent="resetForm"
        >
          <validation-provider #default="{ errors }" name="schoolName" rules="required">
            <b-form-group :label="$t('School Name')" label-for="schoolName">
              <b-form-input
                id="school-name"
                v-model="playerEduForm.schoolName"
                autofocus
                :state="getValidationState(errors)"
                trim
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- {{ $t('Player Full Name') }} -->
          <validation-provider #default="{ errors }" name="schoolLevel" rules="required">
            <b-form-group :label="$t('School Level')" label-for="schoolLevel">
              <v-select
                :placeholder="$t('School Level')"
                v-model="playerEduForm.schoolLevel"
                :reduce="(option) => option.value"
                :state="getValidationState(errors)"
                :options="[
                  { label: $t('High school'), value: 'Lise' },
                  { label: $t('Middle School'), value: 'Orta okul' },
                ]"
              ></v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <validation-provider #default="{ errors }" name="mobile" rules="required">
            <b-form-group :label="$t('Mobile')" label-for="schoolPhone">
              <cleave
                id="phone"
                v-model="playerEduForm.schoolPhone"
                class="form-control"
                :raw="false"
                :options="options.delimiter"
              />

              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <validation-provider #default="{ errors }" name="schoolClass" rules="required">
            <b-form-group :label="$t('School Class')" label-for="schoolClass">
              <b-form-input
                id="schoolClass"
                placeholder
                type="text"
                :state="getValidationState(errors)"
                v-model="playerEduForm.schoolLevelClass"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <validation-provider #default="{ errors }" name="status" rules="required">
            <b-form-group :label="$t('Status')" label-for="schoolGender" label-class="mb-1">
              <b-form-radio-group
                id="schoolStatus"
                v-model="playerEduForm.schoolStatus"
                :state="getValidationState(errors)"
                :options="schoolStatus"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" type="submit">{{
              $t("Add")
            }}</b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              ref="hideClick"
              @click="hide"
              >{{ $t("Cancel") }}</b-button
            >
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
  import { ValidationProvider, ValidationObserver } from "vee-validate"
  import { ref } from "@vue/composition-api"
  import { required, alphaNum, email } from "@validations"
  import formValidation from "@core/comp-functions/forms/form-validation"
  import Ripple from "vue-ripple-directive"
  import vSelect from "vue-select"
  import store from "@/store"
  import Cleave from "vue-cleave-component"
  // eslint-disable-next-line import/no-extraneous-dependencies
  import "cleave.js/dist/addons/cleave-phone.us"

  export default {
    components: {
      vSelect,

      // Form Validation
      ValidationProvider,
      ValidationObserver,
      Cleave,
    },
    directives: {
      Ripple,
    },
    model: {
      prop: "isAddEduActive",
      event: "update:is-add-edu-active",
    },
    props: {
      isAddEduActive: {
        type: Boolean,
        required: true,
      },
      playerEdu: {
        default: null,
      },
      submitEdu: {
        required: true,
      },
      updateEdu: {
        default: null,
      },
      playerEduForm: {
        default: null,
      },
      schoolStatus: "",
    },
    data() {
      return {
        required,
        formTrigger: 0,
      }
    },
    watch: {
      Data: function (newData, oldData) {
        if (newData) {
          this.playerEduForm = newData
        }
      },
    },
    methods: {
      goSubmitEdu() {
        this.$refs.refFormObserver.validate().then((success) => {
          if (success) {
            if (this.playerEduForm.id) {
              this.updateEdu(this.playerEduForm)
            } else {
              this.submitEdu(this.playerEduForm)
            }
            this.$refs.hideClick.click()
          }
        })
      },
    },
    setup(props, { emit, root }) {
      const blankUserData = {}

      const { refFormObserver, getValidationState, resetForm } = formValidation()

      const options = {
        creditCard: {
          creditCard: true,
        },
        date: {
          date: true,
          delimiter: "/",
          datePattern: ["d", "m", "Y"],
        },
        phone: {
          phone: true,
          phoneRegionCode: "TR",
        },
        time: {
          time: true,
          timePattern: ["h", "m", "s"],
        },
        number: {
          numeral: true,
          numeralThousandsGroupStyle: "thousand",
        },
        block: {
          blocks: [4, 3, 3],
          uppercase: true,
        },
        delimiter: {
          delimiter: "·",
          blocks: [3, 3, 4],
          uppercase: true,
        },
        customDelimiter: {
          delimiters: [".", ".", "-"],
          blocks: [3, 3, 3, 2],
          uppercase: true,
        },
        prefix: {
          prefix: "+63",
          blocks: [3, 3, 3, 4],
          uppercase: true,
        },
      }

      return {
        refFormObserver,
        getValidationState,
        resetForm,
        options,
      }
    },
  }
</script>

<style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";

  #add-new-user-sidebar {
    .vs__dropdown-menu {
      max-height: 200px !important;
    }
  }
</style>
