var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v1 px-2"},[_c('div',{staticClass:"auth-inner py-2"},[_c('b-card',{staticClass:"mb-0"},[_c('b-link',{staticClass:"brand-logo"},[_c('img',{attrs:{"src":require('@teamLogoPath'),"height":"90"}})]),_c('b-card-title',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(_vm.$t("Parental Form"))+" ")]),_c('b-overlay',{attrs:{"variant":"white","show":_vm.loading,"spinner-variant":"primary","blur":"0","opacity":".75","rounded":"sm"}},[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',{staticClass:"auth-forgot-password-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.validationForm($event)}}},[_c('b-form-group',{staticClass:"required",attrs:{"label":_vm.$t('Your Name & Surname'),"label-for":"full-name"}},[_c('validation-provider',{attrs:{"name":"FullName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"full-name","state":errors.length > 0 ? false : null,"name":"full-name"},model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"required",attrs:{"label":_vm.$t('Subject'),"label-for":"subject"}},[_c('validation-provider',{attrs:{"name":"Subject","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"subject","state":errors.length > 0 ? false : null,"name":"subject"},model:{value:(_vm.formData.subject),callback:function ($$v) {_vm.$set(_vm.formData, "subject", $$v)},expression:"formData.subject"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"required",attrs:{"label":_vm.$t('Player\'s Name'),"label-for":"player-name"}},[_c('validation-provider',{attrs:{"name":"playerName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"player-name","state":errors.length > 0 ? false : null,"name":"player-name"},model:{value:(_vm.formData.playerName),callback:function ($$v) {_vm.$set(_vm.formData, "playerName", $$v)},expression:"formData.playerName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"required",attrs:{"label":_vm.$t('Age'),"label-for":"age"}},[_c('validation-provider',{attrs:{"name":"age","rules":"required|integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"age","state":errors.length > 0 ? false : null,"name":"age"},model:{value:(_vm.formData.age),callback:function ($$v) {_vm.$set(_vm.formData, "age", _vm._n($$v))},expression:"formData.age"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":_vm.$t('Coach Name'),"label-for":"coach-name"}},[_c('validation-provider',{attrs:{"name":"coachName"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"coach-name","state":errors.length > 0 ? false : null,"name":"coach-name"},model:{value:(_vm.formData.coachName),callback:function ($$v) {_vm.$set(_vm.formData, "coachName", $$v)},expression:"formData.coachName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"required",attrs:{"label":_vm.$t('Your message'),"label-for":"message"}},[_c('validation-provider',{attrs:{"name":"message","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"message","state":errors.length > 0 ? false : null,"name":"message"},model:{value:(_vm.formData.message),callback:function ($$v) {_vm.$set(_vm.formData, "message", $$v)},expression:"formData.message"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-button',{attrs:{"variant":"primary","block":"","type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t("Send"))+" ")]),_c('small',[_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t("Required Field")))])],1)],1)],1),_c('b-card-text',{staticClass:"text-center mt-2"},[_c('b-link',{attrs:{"to":{ name: 'login' }}},[_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon"}}),_vm._v(" "+_vm._s(_vm.$t("Home Page"))+" ")],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }