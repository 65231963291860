<template>
  <b-sidebar
    id="sidebar-team"
    :visible="isTeamActive"
    bg-variant="white"
    @shown="$emit('update:isTeamActive', true)"
    @hidden="
      $emit('update:isTeamActive', false)
      formTrigger++
    "
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
  >
    <template #default="{ hide }">
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">{{ $t("Team") }}</h5>
        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver" :key="formTrigger">
        <!-- Form -->
        <b-form v-if="teamFormData" class="p-2" @submit.prevent="handleSubmit(goSubmitTeam())">
          <!-- Club -->
          <validation-provider #default="{ errors }" name="club" rules="required">
            <b-form-group :label="$t('Club')" label-for="teamClub">
              <v-select
                id="teamClub"
                :placeholder="$t('Club Select')"
                v-model="teamFormData.club"
                :state="errors.length > 0 ? false : null"
                :options="clubs"
                :reduce="(option) => option.id"
                :getOptionLabel="(option) => option.name"
              >
                <template v-slot:option="option">
                  <img :src="option.logo" height="18" class="mr-1" />
                  {{ option.name }}
                </template>
              </v-select>

              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Team Name -->
          <validation-provider #default="{ errors }" name="teamName" rules="required">
            <b-form-group :label="$t('Team Name')" label-for="teamName">
              <b-form-input
                id="teamName"
                placeholder="U-"
                v-model="teamFormData.name"
                :state="errors[0] ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Seasons -->
          <validation-provider #default="{ errors }" name="season" rules="required">
            <b-form-group :label="$t('Seasons')" label-for="seasons">
              <v-select
                multiple
                v-model="teamFormData.season"
                :state="errors.length > 0 ? false : null"
                :options="seasons"
                :reduce="(option) => option.id"
                :getOptionLabel="(option) => option.name"
              >
              </v-select>

              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" type="submit">{{
              $t("Add")
            }}</b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              ref="hideClick"
              @click="hide"
              >{{ $t("Cancel") }}</b-button
            >
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>
<script>
  import { ValidationProvider, ValidationObserver } from "vee-validate"
  import { ref, onMounted } from "@vue/composition-api"
  import { required } from "@validations"
  import Ripple from "vue-ripple-directive"
  import flatPickr from "vue-flatpickr-component"
  import vSelect from "vue-select"
  import axios from "@axios"

  export default {
    components: {
      flatPickr,
      vSelect,

      // Form Validation
      ValidationProvider,
      ValidationObserver,
    },
    directives: {
      Ripple,
    },
    model: {
      prop: "isTeamActive",
      event: "update:is-team-active",
    },
    props: {
      isTeamActive: {
        type: Boolean,
        require: true,
      },
      submitTeam: {
        required: true,
      },
      updateTeam: {
        require: true,
      },
      teamFormData: {
        default: null,
      },
    },
    data() {
      return {
        required,
        formTrigger: 0,
      }
    },
    methods: {
      goSubmitTeam() {
        this.$refs.refFormObserver.validate().then((success) => {
          if (success) {
            if (this.teamFormData.id) {
              this.teamFormData.season = this.teamFormData.season.map((i) => i.id || i)
              this.teamFormData.club = this.teamFormData.club.id ?? this.teamFormData.club
              this.updateTeam(this.teamFormData)
            } else {
              // TODO: organization
              //this.teamFormData.organization = 1;
              this.submitTeam(this.teamFormData)
            }
            this.$refs.hideClick.click()
          }
        })
      },
    },
    setup(props, { emit, root }) {
      const clubs = ref([])
      const seasons = ref([])

      onMounted(async () => {
        await getClubs()
        await getSeasons()
      })

      const getClubs = () => {
        axios.get("/club").then((response) => {
          clubs.value = response.data
        })
      }

      const getSeasons = () => {
        axios.get("/season").then((response) => {
          seasons.value = response.data
        })
      }

      return {
        clubs,
        seasons,
      }
    },
  }
</script>

<style lang="scss">
  @import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
