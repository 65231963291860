<template>
  <div class="dashboard-team-players d-flex flex-column">
    <b-card>
      <b-card-header class="px-0 pt-0">
        <b-card-title class="align-self-start">{{ $t("Squad Management") }}</b-card-title>
        <div class="position-absolute" style="right: 0">
          <b-button-group>
            <b-button
              variant="primary"
              :class="{ active: showTable === 'activePlayers' }"
              @click="showTable = 'activePlayers'"
            >
              {{ $t("Active") }}
            </b-button>
            <b-button
              variant="primary"
              :class="{ active: showTable === 'archivePlayers' }"
              @click="showTable = 'archivePlayers'"
            >
              {{ $t("Archive") }}
            </b-button>
          </b-button-group>
        </div>
      </b-card-header>

      <div class="filters-cotainer d-flex flex-column">
        <div class="mb-1" v-if="false">
          <b-button-group>
            <b-button
              variant="primary"
              :class="{ active: showTable === 'activePlayers' }"
              @click="showTable = 'activePlayers'"
            >
              {{ $t("Active") }}
            </b-button>
            <b-button
              variant="primary"
              :class="{ active: showTable === 'archivePlayers' }"
              @click="showTable = 'archivePlayers'"
            >
              {{ $t("Archive") }}
            </b-button>
          </b-button-group>
        </div>

        <div class="d-flex">
          <b-form-group :label="$t('Player Name')" class="ml-md-n1 col-md-3">
            <b-input v-model="filters.q" debounce="500" :placeholder="$t('Search')" type="search" />
          </b-form-group>

          <b-form-group :label="$t('Team')" class="ml-md-n1 col-md-2">
            <v-select
              class="filter-team"
              v-model="filters.team"
              :options="option.myTeams"
              :reduce="(option) => option.id"
              :getOptionLabel="(option) => option.name"
            >
              <template v-slot:option="option">
                <span v-if="option.club">
                  <img :src="option.club.logo" height="18" />
                  {{ option.club.name }}
                </span>
                {{ option.name }}
              </template>
            </v-select>
          </b-form-group>
        </div>
      </div>

      <!-- TAB: ACTIVE PLAYERS -->
      <template v-if="showTable == 'activePlayers'">
        <div class="actions-container d-flex flex-wrap" style="gap: 1rem">
          <b-button
            @click="toogleCreatePlayerModal()"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            size="sm"
          >
            {{ $t("Add Player") }}
          </b-button>

          <b-button
            @click="toogleTeamChangeModal()"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            size="sm"
            :disabled="selectedPlayers.length < 1"
          >
            {{ $t("Team Change") }}
          </b-button>

          <b-button
            @click="toogleTransferModal()"
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-primary"
            size="sm"
            :disabled="selectedPlayers.length < 1"
          >
            {{ $t("Transfer") }}
          </b-button>

          <b-button
            @click="toogleNationalTransferModal()"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            size="sm"
            :disabled="selectedPlayers.length < 1"
          >
            {{ $t("National Team") }}
          </b-button>

          <b-button
            @click="toogleEndTrialModal()"
            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
            variant="outline-primary"
            size="sm"
            :disabled="selectedPlayers.length < 1"
          >
            {{ $t("End Player Test") }}
          </b-button>

          <b-button
            @click="toogleRemoveModal()"
            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
            variant="outline-danger"
            size="sm"
            :disabled="selectedPlayers.length < 1"
          >
            {{ $t("Termination") }}
          </b-button>
        </div>
        <div class="mt-2 mx-n2 position-relative">
          <div v-show="teamPlayers.length" class="position-absolute" style="right: 0; padding: 0.4rem; top: -2rem">
            {{ $t("Total Players") }} : {{ teamPlayers.length }}
          </div>
          <b-table
            striped
            hover
            responsive
            small
            selectable
            select-mode="multi"
            @row-selected="onRowSelected"
            sort-icon-left
            :busy="isLoading"
            ref="selectableTable"
            :empty-text="$t('No records found')"
            :empty-filtered-text="$t('There are no records matching your request')"
            :items="teamPlayers"
            :fields="[
              { key: 'selected', label: $t('Select'), thClass: 'py-1' },
              { key: 'type', label: $t('Status'), sortable: true, thClass: 'py-1' },
              {
                key: 'name',
                label: $t('Player'),
                sortable: true,
                thClass: 'py-1',
                sortByFormatted: true,
                formatter: (val, key, item) => item.player.name,
              },
              {
                key: 'birthdate',
                label: $t('Age'),
                sortable: true,
                thClass: 'py-1',
                sortByFormatted: true,
                formatter: (val, key, item) => findAge(item.player.birthdate),
              },
              {
                key: 'position',
                label: $t('Position'),
                sortable: true,
                thClass: 'py-1',
                sortByFormatted: true,
                formatter: (val, key, item) => item.player.position,
              },
              { key: 'team', label: $t('Team'), sortable: true, thClass: 'py-1' },
              { key: 'startDate', label: $t('Transfer Date'), sortable: true, thClass: 'py-1' },
            ]"
          >
            <template #table-busy>
              <div class="text-center my-2">
                <b-spinner class="align-middle"></b-spinner>
              </div>
            </template>
            <template #cell(selected)="{ rowSelected, index }">
              <b-form-checkbox :checked="rowSelected" @change="toogleItemSelect(index, rowSelected)" />
            </template>
            <template #cell(type)="data">
              {{ $t(data.value) }}
            </template>
            <template #cell(name)="data">
              <player-item-box
                :player="{
                  player: {
                    ...data.item.player,
                    playerTransferHistories: [{ ...data.item, player: undefined }],
                  },
                  teamId: data.item.team.id,
                  seasonId: data.item.season.id,
                }"
                class="align-items-center"
              />
            </template>
            <template #cell(birthdate)="data">
              {{ data.value }}
            </template>
            <template #cell(position)="data">
              {{ $t(data.item.player.position.name) }}
            </template>
            <template #cell(team)="data">
              {{ data.value.name }}
            </template>
            <template #cell(startDate)="data">
              {{ new Date(data.value).toLocaleDateString() }}
            </template>
          </b-table>
        </div>
      </template>

      <!-- TAB: ARCHIVE PLAYERS -->
      <template v-else-if="showTable == 'archivePlayers'">
        <div class="mt-2 mx-n2 position-relative">
          <div v-show="teamPlayers.length" class="position-absolute" style="right: 0; padding: 0.4rem; top: -2rem">
            {{ $t("Total Players") }} : {{ teamPlayers.length }}
          </div>
          <b-table
            striped
            hover
            responsive
            small
            sort-icon-left
            :busy="isLoading"
            ref="archivePlayers"
            :empty-text="$t('No records found')"
            :empty-filtered-text="$t('There are no records matching your request')"
            :items="teamPlayers"
            :fields="[
              {
                key: 'name',
                label: $t('Player'),
                sortable: true,
                thClass: 'py-1',
                thStyle: { minWidth: '240px' },
                sortByFormatted: true,
                formatter: (val, key, item) => item.player.name,
              },
              {
                key: 'deletedDate',
                label: $t('Action Date'),
                sortable: true,
                thClass: 'py-1',
                thStyle: { minWidth: '100px' },
                sortByFormatted: true,
                formatter: (val, key, item) =>
                  getLastDeleteAction(item.player.actions) ? getLastDeleteAction(item.player.actions).created_at : '',
              },
              {
                key: 'reason',
                label: $t('Reason'),
                sortable: true,
                thClass: 'py-1',
                sortByFormatted: true,
                formatter: (val, key, item) =>
                  getLastDeleteAction(item.player.actions) ? $t(getLastDeleteAction(item.player.actions).reason) : '',
              },
              { key: 'description', label: $t('Description'), thClass: 'py-1' },
              {
                key: 'user',
                label: $t('User'),
                sortable: true,
                thClass: 'py-1',
                sortByFormatted: true,
                formatter: (val, key, item) =>
                  getLastDeleteAction(item.player.actions)
                    ? getLastDeleteAction(item.player.actions).user.firstName +
                      getLastDeleteAction(item.player.actions).user.lastName
                    : '',
              },
            ]"
          >
            <template #table-busy>
              <div class="text-center my-2">
                <b-spinner class="align-middle"></b-spinner>
              </div>
            </template>
            <template #cell(name)="data">
              <player-item-box
                :player="{
                  player: {
                    ...data.item.player,
                    playerTransferHistories: [{ ...data.item, player: undefined }],
                  },
                  teamId: data.item.team.id,
                  seasonId: data.item.season.id,
                }"
                class="align-items-center"
              />
            </template>
            <template #cell(deletedDate)="data">
              <span v-if="data.value">
                {{ data.value | nativeDateTime }}
              </span>
            </template>
            <template #cell(reason)="data">
              {{ data.value }}
            </template>
            <template #cell(description)="data" class="px-n2">
              <p
                v-if="getLastDeleteAction(data.item.player.actions)"
                class="maxLine"
                v-b-tooltip.hover
                :title="getLastDeleteAction(data.item.player.actions).description"
              >
                {{ getLastDeleteAction(data.item.player.actions).description }}
              </p>
            </template>
            <template #cell(user)="data">
              <span v-if="getLastDeleteAction(data.item.player.actions)">
                {{ getLastDeleteAction(data.item.player.actions).user.firstName }}
                {{ getLastDeleteAction(data.item.player.actions).user.lastName.substr(0, 1) + "." }}
              </span>
            </template>
          </b-table>
        </div>
      </template>
    </b-card>

    <!-- Transfer for Team Change -->
    <b-modal
      v-model="modal.teamChangeShow"
      ref="transferTemaChangeModal"
      :title="$t('Team Change')"
      no-close-on-backdrop
      :hide-header-close="modalLoading"
      :cancel-disabled="modalLoading"
      hide-footer
      @hidden="resetTransferModal"
    >
      <b-card-text>
        <span v-if="selectedPlayers.length > 0">
          <b-form-group :label="$t('Selected Players')" label-align="center">
            <div class="d-flex justify-content-center" style="gap: 0.5rem">
              <b-avatar-group>
                <b-avatar
                  v-b-tooltip.hover
                  :title="item.player.name"
                  v-for="(item, index) in selectedPlayers"
                  :key="index"
                  size="40"
                  :src="item.player.avatar"
                  :text="avatarText(item.player.name)"
                />
              </b-avatar-group>
            </div>
          </b-form-group>
        </span>

        <validation-observer v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(submitTransfer)">
            <validation-provider #default="{ errors }" name="team" rules="required">
              <b-form-group :label="$t('Team')">
                <v-select
                  :placeholder="$t('Please select a team')"
                  v-model="form.transfer.team"
                  :state="errors.length > 0 ? false : null"
                  :options="option.myTeams"
                  :reduce="(option) => option.id"
                  :getOptionLabel="(option) => option.name"
                >
                  <template v-slot:option="option">
                    <span v-if="option.club">
                      <img :src="option.club.logo" height="18" />
                      {{ option.club.name }}
                    </span>
                    {{ option.name }}
                  </template>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>

            <!-- Dates-->
            <b-row>
              <b-col md="12">
                <validation-provider #default="{ errors }" name="dateChange" rules="required">
                  <b-form-group :label="$t('Date of change')">
                    <div class="d-flex justify-content-between">
                      <flat-pickr
                        v-model="form.transfer.startDate"
                        class="form-control"
                        :config="{
                          enableTime: false,
                          locale: user.lang.value == 'fr' ? French : user.lang.value == 'tr' ? Turkish : null,
                          altFormat: 'd-m-Y',
                          altInput: true,
                          allowInput: true,
                        }"
                        placeholder="DD-MM-YYYY"
                      />
                    </div>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

            <!-- Description -->
            <b-row>
              <b-col md="12">
                <validation-provider #default="{ errors }" name="description">
                  <b-form-group :label="$t('Description')">
                    <b-form-textarea
                      id="description"
                      v-model="form.transfer.description"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('Enter Description')"
                      rows="4"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

            <div class="d-flex" style="gap: 1rem">
              <button type="submit" class="btn btn-primary" :disabled="modalLoading">
                <b-spinner v-if="modalLoading" small style="margin-bottom: 3px" />
                {{ $t("Save") }}
              </button>
              <button @click="toogleTeamChangeModal()" type="button" class="btn btn-secondary" :disabled="modalLoading">
                {{ $t("Cancel") }}
              </button>
            </div>
          </form>
        </validation-observer>
      </b-card-text>
    </b-modal>

    <!-- Transfer for National Team -->
    <b-modal
      v-model="modal.nationalTransferShow"
      ref="nationalTransferModal"
      :title="$t('National Team')"
      no-close-on-backdrop
      :hide-header-close="modalLoading"
      :cancel-disabled="modalLoading"
      hide-footer
      @hidden="resetTransferModal"
    >
      <b-card-text>
        <span v-if="selectedPlayers.length > 0">
          <b-form-group :label="$t('Selected Players')" label-align="center">
            <div class="d-flex justify-content-center" style="gap: 0.5rem">
              <b-avatar-group>
                <b-avatar
                  v-b-tooltip.hover
                  :title="item.player.name"
                  v-for="(item, index) in selectedPlayers"
                  :key="index"
                  size="40"
                  :src="item.player.avatar"
                  :text="avatarText(item.player.name)"
                />
              </b-avatar-group>
            </div>
          </b-form-group>
        </span>

        <validation-observer v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(submitTransfer)">
            <!-- Season -->
            <validation-provider v-if="false" #default="{ errors }" name="season" rules="required">
              <b-form-group :label="$t('Season')">
                <v-select
                  :placeholder="$t('Please select a season')"
                  v-model="form.transfer.season"
                  :state="errors.length > 0 ? false : null"
                  :options="option.seasons"
                  :reduce="(option) => option.id"
                  :getOptionLabel="(option) => option.name"
                  disabled
                >
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>

            <!-- Dates -->
            <b-row>
              <b-col md="6">
                <validation-provider #default="{ errors }" name="startDate" rules="required">
                  <b-form-group :label="$t('Start Date')">
                    <div class="d-flex justify-content-between">
                      <flat-pickr
                        v-model="form.transfer.startDate"
                        class="form-control"
                        :config="{
                          enableTime: false,
                          locale: user.lang.value == 'fr' ? French : user.lang.value == 'tr' ? Turkish : null,
                          altFormat: 'd-m-Y',
                          altInput: true,
                          allowInput: true,
                        }"
                        placeholder="DD-MM-YYYY"
                      />
                    </div>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col md="6">
                <validation-provider #default="{ errors }" name="endDate">
                  <b-form-group :label="$t('End Date')">
                    <div class="d-flex justify-content-between">
                      <flat-pickr
                        v-model="form.transfer.endDate"
                        class="form-control"
                        :config="{
                          enableTime: false,
                          locale: user.lang.value == 'fr' ? French : user.lang.value == 'tr' ? Turkish : null,
                          altFormat: 'd-m-Y',
                          altInput: true,
                          allowInput: true,
                        }"
                        placeholder="DD-MM-YYYY"
                      />
                    </div>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

            <div class="d-flex" style="gap: 1rem">
              <button type="submit" class="btn btn-primary" :disabled="modalLoading">
                <b-spinner v-if="modalLoading" small style="margin-bottom: 3px" />
                {{ $t("Save") }}
              </button>
              <button
                @click="toogleNationalTransferModal()"
                type="button"
                class="btn btn-secondary"
                :disabled="modalLoading"
              >
                {{ $t("Cancel") }}
              </button>
            </div>
          </form>
        </validation-observer>
      </b-card-text>
    </b-modal>

    <!-- End Trial -->
    <b-modal
      v-model="modal.endTrialShow"
      ref="endTrialModal"
      :title="$t('End Player Test')"
      no-close-on-backdrop
      :hide-header-close="modalLoading"
      :cancel-disabled="modalLoading"
      hide-footer
      @hidden="resetEndTrialModal"
    >
      <b-card-text>
        <span v-if="selectedPlayers.length > 0">
          <b-form-group :label="$t('Selected Players')" label-align="center">
            <div class="d-flex justify-content-center" style="gap: 0.5rem">
              <b-avatar-group>
                <b-avatar
                  v-b-tooltip.hover
                  :title="item.player.name"
                  v-for="(item, index) in selectedPlayers"
                  :key="index"
                  size="40"
                  :src="item.player.avatar"
                  :text="avatarText(item.player.name)"
                />
              </b-avatar-group>
            </div>
          </b-form-group>
        </span>

        <validation-observer v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(handleEndTrialModal)">
            <!-- Result-->
            <validation-provider #default="{ errors }" name="result" rules="required">
              <b-form-group :label="$t('Test Result')">
                <b-form-radio-group
                  id="result"
                  v-model="form.endTrial.result"
                  :state="errors.length > 0 ? false : null"
                  :options="option.trialResults"
                  text-field="label"
                  value-field="id"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>

            <!-- Accept: Team -->
            <validation-provider
              v-show="form.endTrial.result == 'accept'"
              #default="{ errors }"
              name="team"
              :rules="form.endTrial.result === 'accept' ? 'required' : null"
            >
              <b-form-group :label="$t('Recruited Team')">
                <v-select
                  :placeholder="$t('Please select a team')"
                  v-model="form.endTrial.team"
                  :state="errors.length > 0 ? false : null"
                  :options="option.myTeams"
                  :reduce="(option) => option.id"
                  :getOptionLabel="(option) => option.name"
                >
                  <template v-slot:option="option">
                    <span v-if="option.club">
                      <img :src="option.club.logo" height="18" />
                      {{ option.club.name }}
                    </span>
                    {{ option.name }}
                  </template>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>

            <!-- Reject: Reason -->
            <validation-provider
              v-show="form.endTrial.result == 'reject'"
              #default="{ errors }"
              name="reason"
              :rules="form.endTrial.result === 'reject' ? 'required' : null"
            >
              <b-form-group :label="$t('Reason for Rejection')">
                <v-select
                  :placeholder="$t('Select')"
                  v-model="form.endTrial.reason"
                  :state="errors.length > 0 ? false : null"
                  :options="option.transfer_reasons"
                  :reduce="(option) => option.id"
                  :getOptionLabel="(option) => option.label"
                >
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>

            <!-- Reject: Description -->
            <b-row v-if="form.endTrial.result == 'reject'">
              <b-col md="12">
                <validation-provider #default="{ errors }" name="description" rules="required|min:20">
                  <b-form-group :label="$t('Description')">
                    <b-form-textarea
                      id="description"
                      v-model="form.endTrial.description"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('Enter Description')"
                      rows="4"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

            <div class="d-flex" style="gap: 1rem">
              <button type="submit" class="btn btn-primary" :disabled="modalLoading">
                <b-spinner v-if="modalLoading" small style="margin-bottom: 3px" />
                {{ $t("Save") }}
              </button>
              <button @click="toogleEndTrialModal()" type="button" class="btn btn-secondary" :disabled="modalLoading">
                {{ $t("Cancel") }}
              </button>
            </div>
          </form>
        </validation-observer>
      </b-card-text>
    </b-modal>

    <!-- Transfer -->
    <b-modal
      v-model="modal.transferShow"
      ref="transferModal"
      :title="$t('Transfer')"
      no-close-on-backdrop
      :hide-header-close="modalLoading"
      :cancel-disabled="modalLoading"
      hide-footer
      @hidden="resetTransferModal"
    >
      <b-card-text>
        <span v-if="selectedPlayers.length > 0">
          <b-form-group :label="$t('Selected Players')" label-align="center">
            <div class="d-flex justify-content-center" style="gap: 0.5rem">
              <b-avatar-group>
                <b-avatar
                  v-b-tooltip.hover
                  :title="item.player.name"
                  v-for="(item, index) in selectedPlayers"
                  :key="index"
                  size="40"
                  :src="item.player.avatar"
                  :text="avatarText(item.player.name)"
                />
              </b-avatar-group>
            </div>
          </b-form-group>
        </span>

        <validation-observer v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(submitTransfer)">
            <!-- Type & Reason-->
            <b-row>
              <b-col md="6">
                <validation-provider #default="{ errors }" name="transferType" rules="required">
                  <b-form-group :label="$t('Transfer Type')">
                    <v-select
                      :placeholder="$t('Select')"
                      v-model="form.transfer.type"
                      :state="errors.length > 0 ? false : null"
                      :options="option.transfer_types"
                      :reduce="(option) => option.id"
                      :getOptionLabel="(option) => option.label"
                    >
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col md="6">
                <validation-provider #default="{ errors }" name="transferReason" rules="required">
                  <b-form-group :label="$t('Transfer Reason')">
                    <v-select
                      :placeholder="$t('Select')"
                      v-model="form.transfer.reason"
                      :state="errors.length > 0 ? false : null"
                      :options="option.transfer_reasons"
                      :reduce="(option) => option.id"
                      :getOptionLabel="(option) => option.label"
                    >
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

            <validation-provider #default="{ errors }" name="season" rules="required">
              <b-form-group :label="$t('Season')">
                <v-select
                  :placeholder="$t('Please select a season')"
                  v-model="form.transfer.season"
                  :state="errors.length > 0 ? false : null"
                  :options="option.seasons"
                  :reduce="(option) => option.id"
                  :getOptionLabel="(option) => option.name"
                  disabled
                >
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>

            <validation-provider #default="{ errors }" name="team" rules="required">
              <b-form-group :label="$t('Team')">
                <v-select
                  :placeholder="$t('Please select a team')"
                  v-model="form.transfer.team"
                  :state="errors.length > 0 ? false : null"
                  :options="option.allTeams"
                  :reduce="(option) => option.id"
                  :getOptionLabel="(option) => option.name"
                >
                  <template v-slot:option="option">
                    <span v-if="option.club">
                      <img :src="option.club.logo" height="18" />
                      {{ option.club.name }}
                    </span>
                    {{ option.name }}
                  </template>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>

            <!-- Dates-->
            <b-row>
              <b-col md="12">
                <validation-provider #default="{ errors }" name="transferDate" rules="required">
                  <b-form-group :label="$t('Transfer Date')">
                    <div class="d-flex justify-content-between">
                      <flat-pickr
                        v-model="form.transfer.startDate"
                        class="form-control"
                        :config="{
                          enableTime: false,
                          locale: user.lang.value == 'fr' ? French : user.lang.value == 'tr' ? Turkish : null,
                          altFormat: 'd-m-Y',
                          altInput: true,
                          allowInput: true,
                        }"
                        placeholder="DD-MM-YYYY"
                      />
                    </div>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

            <!-- Description -->
            <b-row>
              <b-col md="12">
                <validation-provider #default="{ errors }" name="description">
                  <b-form-group :label="$t('Description')">
                    <b-form-textarea
                      id="description"
                      v-model="form.transfer.description"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('Enter Description')"
                      rows="4"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

            <div class="d-flex" style="gap: 1rem">
              <button type="submit" class="btn btn-primary" :disabled="modalLoading">
                <b-spinner v-if="modalLoading" small style="margin-bottom: 3px" />
                {{ $t("Save") }}
              </button>
              <button @click="toogleTransferModal()" type="button" class="btn btn-secondary" :disabled="modalLoading">
                {{ $t("Cancel") }}
              </button>
            </div>
          </form>
        </validation-observer>
      </b-card-text>
    </b-modal>

    <!-- Player Create -->
    <b-modal
      v-model="modal.playerCreateShow"
      modal-class="player-fast-create-modal"
      ref="playerCreateModal"
      :title="$t('Add Player')"
      no-close-on-backdrop
      :hide-header-close="modalLoading"
      :cancel-disabled="modalLoading"
      hide-footer
      @hidden="resetPlayerCreateModal"
      size="xl"
    >
      <b-card-text>
        <validation-observer v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(submitNewPlayer)">
            <b-overlay :show="modalLoading" rounded="sm">
              <b-table
                striped
                hover
                responsive
                small
                :items="form.players"
                :fields="[
                  { key: 'avatar', label: $t('Avatar'), thClass: 'py-1', thStyle: { 'min-width': '175px' } },
                  { key: 'status', label: $t('Status'), thClass: 'py-1', thStyle: { 'min-width': '160px' } },
                  { key: 'name', label: $t('Name'), thClass: 'py-1', thStyle: { 'min-width': '240px' } },
                  { key: 'phone', label: $t('Phone'), thClass: 'py-1', thStyle: { 'min-width': '144px' } },
                  { key: 'email', label: $t('Email'), thClass: 'py-1', thStyle: { 'min-width': '196px' } },
                  { key: 'birthdate', label: $t('Birth Date'), thClass: 'py-1', thStyle: { 'min-width': '160px' } },
                  { key: 'team', label: $t('Team'), thClass: 'py-1', thStyle: { 'min-width': '130px' } },
                  { key: 'position', label: $t('Position'), thClass: 'py-1', thStyle: { 'min-width': '190px' } },
                  { key: 'foot', label: $t('Strong Foot'), thClass: 'py-1', thStyle: { 'min-width': '190px' } },
                  {
                    key: 'recommender',
                    label: $t('Recommended by'),
                    thClass: 'py-1',
                    thStyle: { 'min-width': '252px' },
                  },
                  { key: 'action', label: '', thClass: 'py-1' },
                ]"
                :tbody-tr-class="playerCreateRowClassFunction"
                class="mb-1 player-create-modal"
                style="min-height: 400px"
              >
                <template #cell(avatar)="{ index }">
                  <b-form-group>
                    <b-form-file
                      :browse-text="$t('Browse')"
                      :drop-placeholder="$t('Move to here...')"
                      :placeholder="$t('Select')"
                      accept=".jpg, .png, .gif"
                      v-model="form.players[index].avatarFile"
                      :disabled="form.players[index]._playerId != null"
                    />
                  </b-form-group>
                </template>

                <!-- STATUS -->
                <template #cell(status)="{ index }">
                  <validation-provider :vid="`status_${index}`" #default="{ errors }" name="status" rules="required">
                    <b-form-group class="position-relative" :class="{ 'mb-3': errors.length > 0 }">
                      <v-select
                        v-model="form.players[index].status"
                        :state="errors.length > 0 ? false : null"
                        :options="option.playerStatus"
                        :reduce="(option) => option.id"
                        :getOptionLabel="(option) => option.label"
                        :clearable="false"
                        :disabled="form.players[index]._playerId != null"
                      >
                      </v-select>
                      <small class="text-danger position-absolute">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </template>

                <!-- NAME -->
                <template #cell(name)="{ index }">
                  <validation-provider :vid="`name_${index}`" #default="{ errors }" name="name" rules="required">
                    <b-form-group class="position-relative" :class="{ 'mb-3': errors.length > 0 }">
                      <v-select
                        class="player-search-select"
                        v-model="form.players[index].name"
                        :state="errors.length > 0 ? false : null"
                        :options="excludesSelectedScoutPlayers(index)"
                        :clearable="false"
                        single
                        @input="(data) => changedLabel(data, index)"
                        @search="(query) => search(query, index)"
                        :disabled="form.players[index]._playerId != null"
                      >
                        <template slot="no-options">
                          <p></p>
                        </template>
                        <template slot="option" slot-scope="option">
                          <div class="d-center">
                            {{
                              option.name
                                ? `${option.name} / ${option.team.club.name} ${option.team.name} / ${new Date(
                                    option.birthdate,
                                  ).toLocaleDateString()} `
                                : option
                            }}
                          </div>
                        </template>
                      </v-select>
                      <small class="text-danger position-absolute">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </template>

                <!-- PHONE -->
                <template #cell(phone)="{ index }">
                  <validation-provider :vid="`phone_${index}`" #default="{ errors }" name="phone" rules="required">
                    <b-form-group class="position-relative" :class="{ 'mb-3': errors.length > 0 }">
                      <b-input
                        v-model="form.players[index].phone"
                        class="pPhone"
                        placeholder="555 444 33 22"
                        :disabled="form.players[index]._playerId != null"
                      ></b-input>
                      <small class="text-danger position-absolute">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </template>

                <!-- E-MAIL -->
                <template #cell(email)="{ index }">
                  <validation-provider
                    :vid="`email_${index}`"
                    #default="{ errors }"
                    name="email"
                    rules="required|email"
                  >
                    <b-form-group class="position-relative" :class="{ 'mb-3': errors.length > 0 }">
                      <b-input
                        v-model="form.players[index].email"
                        type="email"
                        placeholder="example@sportserp.com"
                        :disabled="form.players[index]._playerId != null"
                      ></b-input>
                      <small class="text-danger position-absolute">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </template>

                <!-- BIRTHDATE -->
                <template #cell(birthdate)="{ index }">
                  <validation-provider
                    :vid="`birthdate_${index}`"
                    #default="{ errors }"
                    name="birthdate"
                    rules="required"
                  >
                    <b-form-group class="position-relative" :class="{ 'mb-3': errors.length > 0 }">
                      <div class="d-flex justify-content-between">
                        <flat-pickr
                          v-model="form.players[index].birthdate"
                          class="form-control"
                          :config="{
                            enableTime: false,
                            locale: user.lang.value == 'fr' ? French : user.lang.value == 'tr' ? Turkish : null,
                            altFormat: 'd-m-Y',
                            altInput: true,
                            allowInput: true,
                          }"
                          placeholder="DD-MM-YYYY"
                          :disabled="form.players[index]._playerId != null"
                        />
                      </div>
                      <small class="text-danger position-absolute">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </template>

                <!-- TEAM -->
                <template #cell(team)="{ index }">
                  <validation-provider :vid="`team_${index}`" #default="{ errors }" name="team" rules="required">
                    <b-form-group class="position-relative" :class="{ 'mb-3': errors.length > 0 }">
                      <v-select
                        class="player-team-select"
                        v-model="form.players[index].teamId"
                        :state="errors.length > 0 ? false : null"
                        :options="option.myTeams"
                        :reduce="(option) => option.id"
                        :clearable="false"
                        :getOptionLabel="(option) => option.name"
                        :disabled="form.players[index]._playerId != null"
                      >
                        <template v-slot:option="option">
                          <span v-if="option.club">
                            <img :src="option.club.logo" height="18" />
                            {{ option.club.name }}
                          </span>
                          {{ option.name }}
                        </template>
                      </v-select>
                      <small class="text-danger position-absolute">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </template>

                <!-- POSITION -->
                <template #cell(position)="{ index }">
                  <validation-provider
                    :vid="`position_${index}`"
                    #default="{ errors }"
                    name="position"
                    rules="required"
                  >
                    <b-form-group class="position-relative" :class="{ 'mb-3': errors.length > 0 }">
                      <v-select
                        v-model="form.players[index].position"
                        :getOptionLabel="(option) => option.name"
                        :options="option.positions"
                        :reduce="(val) => val.id"
                        :clearable="false"
                        :disabled="form.players[index]._playerId != null"
                      />
                      <small class="text-danger position-absolute">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </template>

                <!-- FOOT -->
                <template #cell(foot)="{ index }">
                  <validation-provider :vid="`foot_${index}`" #default="{ errors }" name="foot" rules="required">
                    <b-form-group class="position-relative" :class="{ 'mb-3': errors.length > 0 }">
                      <v-select
                        v-model="form.players[index].foot"
                        :options="option.foots"
                        :reduce="(val) => val.id"
                        :clearable="false"
                        :disabled="form.players[index]._playerId != null"
                      />
                      <small class="text-danger position-absolute">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </template>

                <!-- RECOMMENDER -->
                <template #cell(recommender)="{ index }">
                  <validation-provider :vid="`recommender_${index}`" #default="{ errors }" name="recommender">
                    <b-form-group>
                      <v-select
                        v-model="form.players[index].recommend"
                        :options="option.recommenders"
                        :reduce="(val) => val.id"
                        :getOptionLabel="(option) => option.firstName + ' ' + (option.lastName ? option.lastName : '')"
                        :disabled="form.players[index]._playerId != null"
                        :loading="recommenderLoading"
                      >
                        <template #no-options="{ search }">
                          <div @click="recommenderSubmitModal(search, index)" style="cursor: pointer">
                            <feather-icon icon="PlusCircleIcon" size="14" />
                            <span class="text-primary">{{ $t("Do you want to add this item?") }}</span>
                          </div>
                        </template>
                      </v-select>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </template>

                <!-- ACTION -->
                <template #cell(action)="{ index }">
                  <b-form-group>
                    <b-button
                      variant="link"
                      :disabled="form.players[index]._playerId != null || form.players.length < 2"
                    >
                      <feather-icon
                        v-b-tooltip.hover
                        @click="() => form.players.splice(index, 1)"
                        :title="$t('Delete')"
                        size="24"
                        icon="TrashIcon"
                      />
                    </b-button>
                  </b-form-group>
                </template>

                <template #custom-foot>
                  <tr>
                    <td colspan="100%">
                      <div style="width: 100vw" class="d-flex justify-content-center">
                        <b-button @click="addNewPlayer()" variant="secondary"> {{ $t("Add New Row") }} </b-button>
                      </div>
                    </td>
                  </tr>
                </template>
              </b-table>
            </b-overlay>

            <div class="d-flex" style="gap: 1rem">
              <button type="submit" class="btn btn-primary" :disabled="modalLoading">
                <b-spinner v-if="modalLoading" small style="margin-bottom: 3px" />
                {{ $t("Save") }}
              </button>
              <button
                @click="toogleCreatePlayerModal()"
                type="button"
                class="btn btn-secondary"
                :disabled="modalLoading"
              >
                {{ $t("Cancel") }}
              </button>
            </div>
          </form>
        </validation-observer>
      </b-card-text>
    </b-modal>

    <!-- Player Remove -->
    <b-modal
      v-model="modal.playerRemoveShow"
      ref="playerRemoveModal"
      :title="$t('Termination')"
      no-close-on-backdrop
      :hide-header-close="modalLoading"
      :cancel-disabled="modalLoading"
      hide-footer
      @hidden="resetEndTrialModal"
    >
      <b-card-text>
        <span v-if="selectedPlayers.length > 0">
          <b-form-group :label="$t('Selected Players')" label-align="center">
            <div class="d-flex justify-content-center" style="gap: 0.5rem">
              <b-avatar-group>
                <b-avatar
                  v-b-tooltip.hover
                  :title="item.player.name"
                  v-for="(item, index) in selectedPlayers"
                  :key="index"
                  size="40"
                  :src="item.player.avatar"
                  :text="avatarText(item.player.name)"
                />
              </b-avatar-group>
            </div>
          </b-form-group>
        </span>

        <validation-observer v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(deletePlayer)">
            <validation-provider #default="{ errors }" name="reason" rules="required">
              <b-form-group :label="$t('Reason')">
                <v-select
                  :placeholder="$t('Select')"
                  v-model="form.endTrial.reason"
                  :state="errors.length > 0 ? false : null"
                  :options="option.transfer_reasons"
                  :reduce="(option) => option.id"
                  :getOptionLabel="(option) => option.label"
                >
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>

            <!-- Description -->
            <b-row>
              <b-col md="12">
                <validation-provider #default="{ errors }" name="description" rules="required|min:20">
                  <b-form-group :label="$t('Description')">
                    <b-form-textarea
                      id="description"
                      v-model="form.endTrial.description"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('Enter Description')"
                      rows="4"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

            <div class="d-flex" style="gap: 1rem">
              <button type="submit" class="btn btn-primary" :disabled="modalLoading">
                <b-spinner v-if="modalLoading" small style="margin-bottom: 3px" />
                {{ $t("Save") }}
              </button>
              <button @click="toogleRemoveModal()" type="button" class="btn btn-secondary" :disabled="modalLoading">
                {{ $t("Cancel") }}
              </button>
            </div>
          </form>
        </validation-observer>
      </b-card-text>
    </b-modal>
  </div>
</template>
<script>
  import {
    teamService,
    seasonService,
    clubService,
    playerService,
    positionService,
    scoutPlayerService,
    recommenderService,
  } from "@/services"
  import PlayerItemBox from "@core/components/global-components/PlayerItemBox"
  import vSelect from "vue-select"
  import flatPickr from "vue-flatpickr-component"
  import "flatpickr/dist/flatpickr.css"
  import { Turkish } from "flatpickr/dist/l10n/tr.js"
  import { French } from "flatpickr/dist/l10n/fr.js"
  import { ValidationProvider, ValidationObserver } from "vee-validate"
  import Ripple from "vue-ripple-directive"
  import { required } from "@validations"
  import i18n from "@/libs/i18n"
  import Cleave from "cleave.js"
  import { avatarText } from "@core/utils/filter"

  export default {
    data() {
      return {
        teamId: this.$route.params.id || 1,
        seasonId: null,
        user: JSON.parse(localStorage.getItem("userData")),
        teamPlayers: [],
        selectedPlayers: [],
        isLoading: false,
        showTable: "activePlayers", // activePlayers || archivePlayers
        filters: {
          q: undefined,
          team: undefined,
          deleted: undefined,
        },
        modalLoading: false,
        recommenderLoading: false,
        modal: {
          transferShow: false,
          teamChangeShow: false,
          nationalTransferShow: false,
          playerCreateShow: false,
          endTrialShow: false,
          playerRemoveShow: false,
        },
        option: {
          seasons: [],
          clubs: [],
          allTeams: [],
          positions: [],
          scoutPlayers: [],
          recommenders: [],
          myTeams: JSON.parse(localStorage.getItem("teams")),
          transfer_types: [
            { id: "permanent", label: i18n.t("permanent") },
            { id: "loan", label: i18n.t("loan") },
          ],
          transfer_reasons: [
            { id: "performance", label: i18n.t("Performance") },
            { id: "physical", label: i18n.t("Physical") },
            { id: "discipline", label: i18n.t("Discipline") },
            { id: "offer", label: i18n.t("Offer") },
            { id: "other", label: i18n.t("Other") },
          ],
          playerStatus: [
            { id: "permanent", label: i18n.t("squad") },
            { id: "loan", label: i18n.t("loan") },
            { id: "testing", label: i18n.t("testing") },
          ],
          foots: [
            {
              id: 0,
              label: i18n.t("Left Foot"),
            },
            {
              id: 1,
              label: i18n.t("Right Foot"),
            },
            {
              id: 2,
              label: i18n.t("Both Feet"),
            },
          ],
          trialResults: [
            { id: "accept", label: i18n.t("Accept") },
            { id: "reject", label: i18n.t("Reject") },
          ],
        },
        form: {
          transfer: {
            season: null,
            club: null,
            team: null,
            startDate: new Date(),
            endDate: null,
            type: null,
            reason: null,
            description: null,
          },
          players: [],
          endTrial: {
            result: null,
            clubId: null,
            reason: null,
            description: null,
          },
        },
        defaultPlayerField: {
          avatarFile: null,
          status: null,
          name: null,
          phone: null,
          email: null,
          birthdate: null,
          teamId: null,
          position: null,
          foot: null,
          recommend: null,
          scoutPlayer: null,
          season: null,
          _rowVariant: null,
          _playerId: null,
        },
      }
    },
    components: {
      PlayerItemBox,
      vSelect,
      flatPickr,
      ValidationProvider,
      ValidationObserver,
    },
    directives: {
      Ripple,
    },
    watch: {
      $route() {
        location.reload()
      },
      "modal.nationalTransferShow"(newValue) {
        // backend transferin national olduğunu bu değerlerin 0 olması ile anlıyor
        if (newValue == true) {
          this.form.transfer.team = 0
          this.form.transfer.club = 0
        }
      },
      "form.players"() {
        this.$nextTick(() => {
          this.initializePhoneCleave()
        })
      },
      filters: {
        async handler() {
          this.isLoading = true
          await this.getTeamPlayers()
          this.isLoading = false
        },
        deep: true,
      },
      showTable() {
        if (this.showTable == "archivePlayers") this.filters.deleted = true
        if (this.showTable == "activePlayers") this.filters.deleted = undefined
      },
    },
    async beforeMount() {
      this.seasonId = this.getClosestSeason()?.id
      if (this.seasonId == null) return false

      this.isLoading = true
      await Promise.all([
        this.getTeamPlayers(),
        this.getSeasons(),
        this.getClubs(),
        this.getAllTeams(),
        this.getPositions(),
        this.getScoutPlayers(),
        this.getRecommenders(),
      ])
      this.isLoading = false
    },
    mounted() {
      this.form.transfer.season = this.seasonId
      this.form.players = [{ ...this.defaultPlayerField }]
      this.initializePhoneCleave()
    },
    computed: {
      excludesSelectedScoutPlayers() {
        return (tableIndex) => {
          return this.option.scoutPlayers.filter(
            (scoutPlayer) =>
              !this.form.players.find((player, i) => i !== tableIndex && player.scoutPlayer === scoutPlayer.id),
          )
        }
      },
    },
    methods: {
      async getTeamPlayers() {
        let { teamId, seasonId } = this
        // testing: -2 kadrodaki oyuncuları denen oyuncular ile birlikte getirmek için
        const res = await new teamService().getAllTeamsPlayersBySeason(seasonId, {
          ...this.filters,
          testing: -2,
        })
        this.teamPlayers = res.data
      },
      async getSeasons() {
        const res = await new seasonService().get()
        this.option.seasons = res.data
      },
      async getClubs() {
        const res = await new clubService().get()
        this.option.clubs = res.data
      },
      async getAllTeams() {
        const res = await new teamService().get()
        this.option.allTeams = res.data.data
      },
      async getPositions() {
        const res = await new positionService().get()
        this.option.positions = res.data
      },
      async getScoutPlayers() {
        const res = await new scoutPlayerService().get({ on_scout: 1, row: 100000 })
        this.option.scoutPlayers = res.data.data
      },
      async getRecommenders() {
        const res = await new recommenderService().get()
        this.option.recommenders = res.data
      },
      findAge(birthdate) {
        return Math.abs(new Date(Date.now() - new Date(birthdate).getTime()).getUTCFullYear() - 1970)
      },
      getLastDeleteAction(actions) {
        const deleteActions = actions.filter((i) => i.action === "delete")
        return deleteActions.length > 0 ? deleteActions.at(-1) : null
      },
      toogleItemSelect(index, checked) {
        if (checked) return this.$refs.selectableTable.unselectRow(index)
        return this.$refs.selectableTable.selectRow(index)
      },
      onRowSelected(tableItems) {
        this.selectedPlayers = tableItems
      },
      resetTransferModal() {
        this.form.transfer = {
          season: this.seasonId,
          club: null,
          team: null,
          startDate: new Date(),
          endDate: null,
          type: null,
          description: null,
          reason: null,
        }
      },
      resetPlayerCreateModal() {
        this.form.players = [{ ...this.defaultPlayerField }]
      },
      resetEndTrialModal() {
        this.form.endTrial = {
          result: null,
          clubId: null,
          reason: null,
          description: null,
        }
      },
      initializePhoneCleave() {
        Array.from(document.getElementsByClassName("pPhone")).forEach((el) => {
          new Cleave(el, {
            numericOnly: true,
            delimiter: " ",
            blocks: [3, 3, 2, 2],
          })
        })
      },
      toogleTransferModal(force = null) {
        if (typeof force == "boolean") return (this.modal.transferShow = force)
        // modal açıldığında çalışacak
        if (!this.modal.transferShow) {
          // Tipi test olmayan oyuncu seçildi ise uyarı ver
          const haveIsTesting = this.selectedPlayers.some((player) => player.isTesting == 1)
          if (haveIsTesting) {
            this.toast(i18n.t("Players whose type is Testing should be deselected."), "AlertTriangleIcon", "danger")
            return
          }
        }

        this.modal.transferShow = !this.modal.transferShow
      },
      toogleTeamChangeModal(force = null) {
        if (typeof force == "boolean") return (this.modal.teamChangeShow = force)
        // modal açıldığında çalışacak
        if (!this.modal.teamChangeShow) {
          // Tipi test olmayan oyuncu seçildi ise uyarı ver
          const haveIsTesting = this.selectedPlayers.some((player) => player.isTesting == 1)
          if (haveIsTesting) {
            this.toast(i18n.t("Players whose type is Testing should be deselected."), "AlertTriangleIcon", "danger")
            return
          }
        }

        this.modal.teamChangeShow = !this.modal.teamChangeShow
      },
      toogleNationalTransferModal(force = null) {
        if (typeof force == "boolean") return (this.modal.nationalTransferShow = force)
        // modal açıldığında çalışacak
        if (!this.modal.nationalTransferShow) {
          // Tipi test olmayan oyuncu seçildi ise uyarı ver
          const haveIsTesting = this.selectedPlayers.some((player) => player.isTesting == 1)
          if (haveIsTesting) {
            this.toast(i18n.t("Players whose type is Testing should be deselected."), "AlertTriangleIcon", "danger")
            return
          }
        }

        this.modal.nationalTransferShow = !this.modal.nationalTransferShow
      },
      toogleCreatePlayerModal(force = null) {
        if (typeof force == "boolean") return (this.modal.playerCreateShow = force)
        this.modal.playerCreateShow = !this.modal.playerCreateShow
      },
      toogleEndTrialModal(force = null) {
        if (typeof force == "boolean") return (this.modal.endTrialShow = force)
        // Modal açıldığında şartı
        if (!this.modal.endTrialShow) {
          // Tipi test olmayan oyuncu seçildi ise uyarı ver
          const isFullIsTesting = this.selectedPlayers.some((player) => !player.isTesting || player.isTesting != 1)
          if (isFullIsTesting) {
            this.toast(i18n.t("Only Tested Players Should Be Selected."), "AlertTriangleIcon", "danger")
            return
          }
        }

        this.modal.endTrialShow = !this.modal.endTrialShow
      },
      toogleRemoveModal(force = null) {
        if (typeof force == "boolean") return (this.modal.playerRemoveShow = force)
        this.modal.playerRemoveShow = !this.modal.playerRemoveShow
      },
      addNewPlayer() {
        this.form.players.push({ ...this.defaultPlayerField })
      },
      search(text, index) {
        if (!text.length) return
        //console.log('@search:', text, index)
        this.form.players[index].name = text
        this.form.players[index].scoutPlayer = null
      },
      changedLabel(player, index) {
        //console.log('@input:', player, index)
        this.form.players[index].scoutPlayer = player.id
        this.form.players[index].name = player.name
        this.form.players[index].phone = player.phone
        this.form.players[index].email = player.email
        this.form.players[index].birthdate = player.birthdate
        this.form.players[index].position = player.position?.id
        this.form.players[index].foot = player.foot

        if (player.team.id && this.option.myTeams.find((team) => team.id == player.team.id)) {
          this.form.players[index].teamId = player.team.id
        }
      },
      playerCreateRowClassFunction(item, type) {
        if (type == "row" && item._rowVariant === "success") {
          return "success-player-row"
        }

        return
      },
      handleEndTrialModal() {
        if (this.form.endTrial.result == "reject") {
          this.deletePlayer()
        }
        if (this.form.endTrial.result == "accept") {
          this.toSquad()
        }
      },
      async recommenderSubmitModal(searchRecommender, index) {
        let confirm = await this.confirmModal(i18n.t("Do you want to add this item?"))
        if (!confirm) return false

        try {
          const recommenderSer = new recommenderService()
          this.recommenderLoading = true

          const data = await recommenderSer.event({ data: { firstName: searchRecommender } })

          this.form.players[index].recommend = null
          this.option.recommenders.push(data.data)
          this.recommenderLoading = false
        } catch (err) {
          console.log("err-recommender", err)
          this.recommenderLoading = false
        }
      },
      async submitTransfer() {
        try {
          const playerSer = new playerService()
          this.modalLoading = true

          if (this.form.transfer.endDate <= 0) this.form.transfer.endDate = null
          if (this.form.transfer.club === null) {
            this.form.transfer.club = this.option.allTeams.find((team) => team.id == this.form.transfer.team)?.club.id
          }

          await Promise.all(
            this.selectedPlayers.map(async (transferH) => {
              await playerSer.postTransfer(this.form.transfer, transferH.player.id)
            }),
          )

          this.toast(i18n.t("Transfers Successfully Created"), "PlusIcon", "success")
          this.modalLoading = false
          this.toogleTransferModal(false)
          this.toogleTeamChangeModal(false)
          this.toogleNationalTransferModal(false)

          this.isLoading = true
          await this.getTeamPlayers()
          this.isLoading = false
        } catch (err) {
          console.log("err", err)
          this.modalLoading = false
        }
      },
      async submitNewPlayer() {
        const playerSer = new playerService()
        this.modalLoading = true

        try {
          for (const playerData of this.form.players.filter((player) => player._rowVariant !== "success")) {
            // SET DEFAULTS
            playerData.season = this.seasonId

            // PLAYER CREATE
            if (!playerData._playerId) {
              await playerSer
                .createPlayer({
                  ...playerData,
                  avatarFile: undefined,
                  _playerId: undefined,
                  _rowVariant: undefined,
                })
                .then((response) => {
                  playerData._playerId = response.data.id
                  return response.data
                })
                .catch((error) => {
                  console.log("Player creation error:", error)
                  playerData._rowVariant = "danger"
                  throw error
                })
            }

            // AVATAR UPLOAD
            if (playerData.avatarFile && playerData._playerId) {
              await playerSer
                .uploadAvatar(playerData.avatarFile, playerData._playerId)
                .then(() => {})
                .catch((error) => {
                  console.log("Player avatar upload error:", error)
                  playerData._rowVariant = "danger"
                  throw error
                })
            }

            playerData._rowVariant = "success"
          }
          this.toast(i18n.t("Players Successfully Created"), "PlusIcon", "success")
          this.modalLoading = false

          setTimeout(() => {
            this.toogleCreatePlayerModal(false)
          }, 2000)

          this.isLoading = true
          await Promise.all([this.getTeamPlayers(), this.getScoutPlayers()])
          this.isLoading = false
        } catch (err) {
          this.modalLoading = false
        }
      },
      async deletePlayer() {
        try {
          const reasonData = { reason: this.form.endTrial.reason, description: this.form.endTrial.description }
          const playerSer = new playerService()
          this.modalLoading = true

          await Promise.all(
            this.selectedPlayers.map(async (transferH) => {
              await playerSer.deletePlayer(reasonData, transferH.player.id)
            }),
          )

          this.toast(i18n.t("Successfully Deleted"), "PlusIcon", "success")
          this.modalLoading = false
          this.toogleEndTrialModal(false)
          this.toogleRemoveModal(false)

          this.isLoading = true
          await this.getTeamPlayers()
          this.isLoading = false
        } catch (err) {
          console.log("err", err)
          this.modalLoading = false
        }
      },
      // denenen oyuncuları kadroya ekler
      async toSquad() {
        try {
          const squadTransferData = {
            club: null,
            team: this.form.endTrial.team,
            season: this.seasonId,
            startDate: new Date(),
          }
          squadTransferData.club = this.option.allTeams.find((team) => team.id == squadTransferData.team)?.club.id
          const playerSer = new playerService()
          this.modalLoading = true

          await Promise.all(
            this.selectedPlayers.map(async (transferH) => {
              await playerSer.postTransfer(squadTransferData, transferH.player.id).then(async () => {
                await playerSer.putPlayerTesting({ isTesting: 0 }, transferH.player.id)
              })
            }),
          )

          this.toast(i18n.t("Player Successfully Recruited"), "PlusIcon", "success")
          this.toogleEndTrialModal(false)
        } catch (err) {
          console.log("err", err)
        } finally {
          this.modalLoading = false
          this.isLoading = true
          await this.getTeamPlayers()
          this.isLoading = false
        }
      },
    },
    setup() {
      return {
        Turkish,
        French,
        required,
        avatarText,
      }
    },
  }
</script>
<style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
  @import "@core/scss/vue/libs/vue-flatpicker.scss";

  .dashboard-team-players {
    .maxLine {
      overflow: hidden;
      display: -webkit-box;
      -moz-box-orient: vertical;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }

    .v-select {
      &.filter-team {
        .vs__dropdown-menu {
          min-width: 250px;
        }
      }
    }
  }

  .player-fast-create-modal {
    .v-select {
      &:not(.vs--disabled) {
        .vs__dropdown-toggle {
          background-color: #fff;
        }
      }

      &.player-search-select {
        .vs__dropdown-menu {
          min-width: 450px;
        }
      }

      &.player-team-select {
        .vs__dropdown-menu {
          min-width: 250px;
        }
      }
    }
    input {
      &.flatpickr-input {
        background-color: #fff;

        &:disabled {
          background-color: #efefef;
        }
      }
    }

    small {
      &.text-danger {
        font-size: 0.7rem;
      }
    }

    .success-player-row {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  .flatpickr-current-month .numInputWrapper span {
    display: inline-block !important;
  }
</style>
