import axios from "@axios"

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchTasks(ctx, { taskType }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/notification/${taskType}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    addTask(ctx, taskData) {
      return new Promise((resolve, reject) => {
        axios
          .post("/apps/todo/tasks", { task: taskData })
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    updateTask(ctx, { task }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/notification/wait/${task.id}`, { task })
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    removeTask(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/apps/todo/tasks/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    fetchUserTasks(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get("/notifications")
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    readNotification(ctx, { notificationIds }) {
      return new Promise((resolve, reject) => {
        axios
          .put("/notification/read", { notificationIds })
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
  },
}
