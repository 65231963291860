<template>
  <b-nav-item-dropdown id="dropdown-grouped" variant="link" class="dropdown-language" right>
    <template #button-content>
      <b-img :src="currentLocale.img" height="14px" width="22px" :alt="currentLocale.locale" />
      <!-- <span class="ml-50 text-body">{{ currentLocale.name }}</span> -->
    </template>
    <b-dropdown-item v-for="localeObj in locales" :key="localeObj.locale" @click="changeLanguage(localeObj)">
      <b-img :src="localeObj.img" height="14px" width="22px" :alt="localeObj.locale" />
      <span class="ml-50">{{ localeObj.name }}</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
  import { BNavItemDropdown, BDropdownItem, BImg } from "bootstrap-vue"
  import i18n from "@/libs/i18n"
  import http from "@/libs/axios"
  import { mounted } from "vue-echarts"
  import { onMounted, beforeOnMounted } from "vue-demi"
  import store from "@/store"
  import router from "@/router"

  export default {
    components: {
      BNavItemDropdown,
      BDropdownItem,
      BImg,
    },
    // ! Need to move this computed property to comp function once we get to Vue 3
    computed: {
      currentLocale() {
        return this.locales.find((l) => l.locale === this.$i18n.locale)
      },
    },
    setup() {
      /* eslint-disable global-require */
      const locales = [
        {
          locale: "tr",
          id: 1,
          img: require("@/assets/images/flags/tr.png"),
          name: "Türkçe",
        },
        {
          locale: "en",
          id: 2,
          img: require("@/assets/images/flags/en.png"),
          name: "English",
        },
        {
          locale: "fr",
          id: 3,
          img: require("@/assets/images/flags/fr.png"),
          name: "French",
        },
        {
          locale: "es",
          id: 4,
          img: require("@/assets/images/flags/es.png"),
          name: "Spanish",
        },
        {
          locale: "it",
          id: 5,
          img: require("@/assets/images/flags/it.png"),
          name: "Italian",
        },
      ]
      /* eslint-disable global-require */

      const user = JSON.parse(localStorage.getItem("userData"))

      const changeLanguage = (lang) => {
        i18n.locale = lang.locale
        i18n.fallbackLocale = lang.locale
        setUserLanguage(lang.id)
        document.documentElement.setAttribute("lang", i18n.locale)
      }

      const setUserLanguage = (id) => {
        http
          .put("/user-lang", { lang: id })
          .then((response) => {
            localStorage.setItem("userData", JSON.stringify(response.data))
            let redirectPath = router.currentRoute.path
            localStorage.setItem("redirect", redirectPath)
            router.push("/blank-page")
            return response
          })
          .catch((error) => {
            console.log(error)
          })
      }

      onMounted(() => {
        if (user && user.lang) {
          i18n.locale = user.lang.value
          i18n.fallbackLocale = user.lang.value
        } else {
          i18n.locale = "tr"
          i18n.fallbackLocale = "tr"
        }
        document.documentElement.setAttribute("lang", i18n.locale)
      })

      // const setLoading = (val) => {
      //   store.commit("app/SET_LOADING", val);
      // };

      return {
        locales,
        changeLanguage,
      }
    },
  }
</script>

<style></style>
