import { render, staticRenderFns } from "./addMatchFormationStat.vue?vue&type=template&id=2aaac9ba&scoped=true&"
import script from "./addMatchFormationStat.vue?vue&type=script&lang=js&"
export * from "./addMatchFormationStat.vue?vue&type=script&lang=js&"
import style0 from "./addMatchFormationStat.vue?vue&type=style&index=0&id=2aaac9ba&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2aaac9ba",
  null
  
)

export default component.exports