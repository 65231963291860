<template>
  <b-overlay>
    <div class="auth-wrapper auth-v1 px-2" :style="{ 'background-image': 'url(\'' + elementBg + '\')' }">
      <div class="auth-inner py-2">
        <!-- Login v1 -->
        <b-card class="mb-0">
          <b-link class="brand-logo">
            <img :src="require('@teamLogoPath')" height="90" />
          </b-link>
          <!-- form -->
          <validation-observer ref="loginForm" #default="{ handleSubmit }">
            <b-form class="auth-login-form mt-2" @submit.prevent="handleSubmit(login())">
              <!-- email -->
              <b-form-group label-for="Username">
                <label>{{ $t("Username") }} / {{ $t("Email") }}</label>
                <validation-provider #default="{ errors }" name="usernameEmail" rules="required">
                  <b-form-input
                    id="Username"
                    v-model="userName"
                    name="login-Username"
                    :state="errors.length > 0 ? false : null"
                    autofocus
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="password">{{ $t("Password") }}</label>
                </div>
                <validation-provider #default="{ errors }" name="Password" rules="required">
                  <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                    <b-form-input
                      id="Password"
                      v-model="password"
                      :type="passwordFieldType"
                      class="form-control-merge"
                      :state="errors.length > 0 ? false : null"
                      name="login-password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- submit button -->
              <b-button variant="primary" type="submit" block :disabled="loading">
                <b-spinner v-if="loading" small style="margin-bottom: 3px"></b-spinner>
                {{ $t("Sign in") }}</b-button
              >
            </b-form>
          </validation-observer>
          <p class="text-center mt-2">
            <b-link :to="{ name: 'forgot-password' }">
              <feather-icon icon="ChevronLeftIcon" /> {{ $t("Forgot Password") }}
            </b-link>
          </p>
          <p class="text-center mt-2" v-if="getSetting('communication')">
            <b-link class="btn btn-primary d-block" :to="{ name: 'parent-access' }">
              {{ $t("Parental Form") }}
            </b-link>
          </p>
          <p class="text-end text-right mt-2 text-primary mb-n1">Version: 2.1.01</p>
        </b-card>
        <!-- /Login v1 -->
      </div>
    </div>
  </b-overlay>
</template>
<script>
  import { ValidationProvider, ValidationObserver } from "vee-validate"
  import { required, email } from "@validations"
  import { togglePasswordVisibility } from "@core/mixins/ui/forms"
  import { getHomeRouteForLoggedInUser } from "@/auth/utils"
  import useJwt from "@/auth/jwt/useJwt"
  import userService from "@/services/userService"

  export default {
    components: {
      ValidationProvider,
      ValidationObserver,
    },
    mixins: [togglePasswordVisibility],
    data() {
      return {
        userName: "",
        password: "",
        status: "",
        platform: "client_web",
        minRole: "ROLE_USER",
        // validation rules
        elementBg: require("@/assets/images/banner/heading_bg.png"),
        loading: false,
        required,
        email,
        data: false,
      }
    },
    computed: {
      passwordToggleIcon() {
        return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon"
      },
      invalid() {},
    },
    methods: {
      login() {
        this.$refs.loginForm.validate().then((success) => {
          if (success) {
            this.loading = true
            useJwt
              .login({
                userName: this.userName,
                password: this.password,
                platform: this.platform,
                minRole: this.minRole,
              })
              .then((response) => {
                const userData = response.data

                var authAclControl = Object.values(userData.user.ability).filter(
                  (d) => d.subject == "ACL" || d.subject == "Auth",
                )

                if (userData.user.ability.length == 0 || authAclControl.length == 0) {
                  this.toast("Login Error", "UserIcon", "danger", "Authorization error")
                  this.loading = false
                  this.password = ""
                  setTimeout(location.reload(), 2500)
                } else {
                  useJwt.setToken(response.data.token)
                  useJwt.setRefreshToken(response.data.refreshToken)

                  // ignore accpetTerms modal
                  if (!userData.user.acceptTerms) {
                    this.redirectLoggedInUserToHomepage(userData)
                    /*const service = new userService();
                  const h = this.$createElement;
                  const contentVNode = h('div', { domProps: { innerHTML: `<h1>What is Lorem Ipsum?</h1>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.` } });
                  this.$bvModal
                    .msgBoxConfirm(contentVNode, {
                      title: this.$t("Please Confirm"),
                      size: "lg",
                      okVariant: "primary",
                      okTitle: this.$t("Accept"),
                      cancelTitle: this.$t("Cancel"),
                      cancelVariant: "outline-secondary",
                      hideHeaderClose: false,
                      centered: true,
                    })
                    .then(async(value) => {
                      if (value) {
                        await service.acceptTerms();
                        this.redirectLoggedInUserToHomepage(userData)
                      } else {
                        localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
                        localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName);
                        this.loading = false;
                      }
                    });*/
                  } else {
                    this.redirectLoggedInUserToHomepage(userData)
                  }
                }

                /*this.$router
                .replace(getHomeRouteForLoggedInUser(userData.user.role))
                .then((resp) => {
                  this.setTeams(userData.teams);
                  this.setSeason(userData.seasons);
                  // TODO: IF TEAM AND SEASON STORAGE NOT FOUND
                  // ERROR ON ENDPOINT REQUESTS
                })
                .catch((error) => {
                  this.loading = false;
                  console.log("login error!", error);
                  this.$refs.loginForm.setErrors(error.response.data.error);
                });*/
              })
              .catch((error) => {
                console.log(error)
                this.loading = false
              })
          }
        })
      },
      redirectLoggedInUserToHomepage(userData) {
        localStorage.setItem("userData", JSON.stringify(userData.user))
        localStorage.setItem("seasons", JSON.stringify(userData.seasons))
        localStorage.setItem("season", userData.seasons[userData.seasons.length - 1].id)
        localStorage.setItem("teams", JSON.stringify(userData.teams))
        this.$ability.update(userData.user.ability)
        location.href = "/"
      },
      setTeams(data) {
        this.$store.dispatch("app/fetchTeams", data).then((response) => {})
      },
      setSeason(data) {
        this.$store.dispatch("app/fetchSeasons", data).then((response) => {})
      },
      setSeasons(data) {
        this.$store.dispatch("app/fetchSeasonsList", data).then((response) => {})
      },
      setPerms(data) {
        this.$store.dispatch("app/setPerms", data).then((response) => {})
      },
    },
  }
</script>
<style lang="scss">
  @import "@core/scss/vue/pages/page-auth.scss";
  .brand-logo svg {
    height: 120px;
    width: auto !important;
  }
  .auth-wrapper {
    background-position: bottom;
    background-repeat: no-repeat;
  }
</style>
