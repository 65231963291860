<template>
  <component :is="player === undefined ? 'div' : 'b-card'">
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="player === undefined">
      <h4 class="alert-heading">
        {{ $t("Error Fetching Data") }}
      </h4>
      <div class="alert-body">
        {{ $t("No user found with this user id") }} {{ $t("Check") }}
        <b-link class="alert-link" :to="{ name: 'apps-users-list' }">
          {{ $t("User List") }}
        </b-link>
      </div>
    </b-alert>

    <div v-if="player !== undefined && player == null" class="m-n2 p-5">
      <b-overlay :show="player !== undefined && player == null" rounded="lg"></b-overlay>
    </div>

    <b-tabs v-if="player" pills>
      <!-- Tab: Account -->
      <b-tab active>
        <template #title>
          <feather-icon icon="UserIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">{{ $t("Account") }}</span>
        </template>
        <account-tab class="mt-2 pt-75" :player="player" :positions="positions" />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="PenToolIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">{{ $t("Comments") }}</span>
        </template>
        <comment-tab class="mt-2 pt-75" :player="player" />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="FileTextIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">{{ $t("Player Transfer History") }}</span>
        </template>
        <transfer-history-tab class="mt-2 pt-75" :player="player" />
      </b-tab>
    </b-tabs>
  </component>
</template>

<script>
  import { ref, onUnmounted, onMounted, computed } from "@vue/composition-api"
  import store from "@/store"
  import AccountTab from "@/views/scout/player/edit/components/account-tab.vue"
  import CommentTab from "@/views/scout/player/edit/components/comment-tab.vue"
  import TransferHistoryTab from "@/views/scout/player/edit/components/transferHistory-tab.vue"
  import http from "@/libs/axios"
  import { useRouter } from "@core/utils/utils"
  export default {
    components: {
      AccountTab,
      CommentTab,
      TransferHistoryTab,
    },
    setup(props, { emit, root }) {
      const { router, route } = useRouter()
      const player = ref(null)
      const playerId = ref(Number(router.currentRoute.params.playerId))
      const positions = ref(null)

      onMounted(async () => {
        await getPlayer()
        await getPositions()
      })

      const getPlayer = () => {
        http
          .get(`/scout/player/${playerId.value}`)
          .then((res) => {
            player.value = res.data
          })
          .catch((error) => console.log(error))
      }

      const getPositions = () => {
        http
          .get(`/position`)
          .then((res) => {
            positions.value = res.data
          })
          .catch((error) => console.log(error))
      }

      return {
        player,
        positions,
      }
    },
  }
</script>

<style></style>
