<template>
  <div>
    <b-tabs lazy pills content-class="mt-3">
      <b-tab :title="$t('Key Config')" active>
        <setting-list />
      </b-tab>
      <b-tab :title="$t('General Config')">
        <settings-component />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
  import SettingList from "@/views/settings/settingList"
  import SettingsComponent from "@/views/settings/settingsComponent"
  export default {
    components: { SettingsComponent, SettingList },
  }
</script>
