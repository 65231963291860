<template>
  <div>
    <b-card no-body>
      <b-card-header class="pb-50">
        <h5>{{ $t("Filter") }}</h5>
        <b-button-group>
          <b-button variant="info" :class="{ active: showTable === 'player' }" @click="showTable = 'player'">
            {{ $t("Academy") }}
          </b-button>
          <b-button variant="info" :class="{ active: showTable === 'scout' }" @click="showTable = 'scout'">
            {{ $t("Scout") }}
          </b-button>
          <b-button variant="info" @click="doit()"> {{ $t("Export") }} </b-button>
        </b-button-group>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col cols="12" md="3" class="mb-md-0 mb-2">
            <label>{{ $t("Name") }}</label>
            <b-input v-model="searchQuery" :placeholder="$t('Search')" type="search" />
          </b-col>

          <b-col cols="12" md="3" class="mb-md-0 mb-2">
            <label>{{ $t("User") }}</label>
            <v-select
              v-model="user"
              :reduce="(item) => item.id"
              :getOptionLabel="(item) => `${item.firstName} ${item.lastName}`"
              :options="users"
              class="w-100"
            />
          </b-col>

          <b-col cols="12" md="2" class="mb-md-0 mb-2">
            <label>{{ $t("Reason") }}</label>
            <v-select
              v-model="reason"
              :reduce="(item) => item.value"
              :options="[
                { label: $t('Performance'), value: 'Performance' },
                { label: $t('Discipline'), value: 'Disiplin' },
                { label: $t('Transfer'), value: 'Transfer' },
                { label: $t('Other'), value: 'Diğer' },
              ]"
              class="w-100"
            />
          </b-col>

          <b-col cols="12" md="3" class="mb-md-0 mb-2">
            <label>{{ $t("Action Date") }}</label>
            <b-form-datepicker
              :placeholder="$t('Action Date')"
              v-model="deletedDate"
              today-button
              reset-button
              :locale="lang"
            />
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <b-card no-body>
      <div class="">
        <b-table
          id="excelTable"
          ref="refPlayerTable"
          :fields="fields"
          :items="getPlayers"
          class="position-relative"
          :empty-text="$t('No records found')"
          responsive
          primary-key="id"
          show-empty
          busy.sync="false"
          style="min-height: 400px"
          :sort-by.sync="sortBy"
          :sort-desc.sync="isSortDirDesc"
          :per-page="perPage"
          :current-page="currentPage"
        >
          <template #table-busy>
            <div class="text-center my-2">
              <b-spinner class="align-middle"></b-spinner>
            </div>
          </template>

          <template #cell(name)="data">
            <b-link
              class="d-flex"
              :to="{
                name: showTable === 'player' ? 'scout-deleted-player' : 'scout-player-view',
                params: {
                  sId: data.item.playerTransferHistories[0].season.id,
                  tId: data.item.playerTransferHistories[0].team.id,
                  playerId: data.item.id,
                },
              }"
            >
              <b-avatar :src="data.item.avatar" class="mr-1" style=""></b-avatar>
              {{ data.item.name }}
            </b-link>
          </template>
          <template #cell(team)="data">
            {{ data.value.club ? data.value.club.name : "" }} {{ data.value.name }}
          </template>
          <template #cell(deletedDate)="data">
            {{ data.item.action.created_at | nativeDateTime }}
          </template>
          <template #cell(reason)="data">
            {{ $t(data.item.action.reason) }}
          </template>
          <template #cell(description)="data">
            {{ data.item.action.description }}
          </template>
          <template #cell(user)="data">
            {{ data.item.action.user.firstName }}
            {{ data.item.action.user.lastName.substr(0, 1) + "." }}
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
              <label>{{ $t("Show") }}</label>
              <v-select
                v-model="perPage"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
              />

              <span class="text-muted">{{
                $t("format.showingData", { from: dataMeta.from, to: dataMeta.to, of: dataMeta.of })
              }}</span>
            </b-col>

            <!-- Pagination -->
            <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalItems"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
  import vSelect from "vue-select"
  import i18n from "@/libs/i18n"
  import { ref, watch, computed, onMounted } from "@vue/composition-api"
  import axios from "@/libs/axios"

  export default {
    components: {
      vSelect,
    },
    methods: {
      updateVisible(val = true) {
        this.visible = val
        this.$emit("visible", val)
      },
      doit() {
        var wscols = [{ wch: 25 }, { wch: 25 }, { wch: 25 }, { wch: 25 }, { wch: 25 }, { wch: 25 }]
        var elt = document.getElementById("excelTable")

        var wb = XLSX.utils.book_new()
        var ws = XLSX.utils.table_to_sheet(elt)
        ws = JSON.parse(JSON.stringify(ws).replace(/\((.*?)\)/g, ""))
        ws["!cols"] = wscols

        XLSX.utils.book_append_sheet(wb, ws, "Sheet 1")
        return XLSX.writeFile(wb, `${this.showTable}TableExport.xlsx`)
      },
    },
    mounted() {
      let themejs = document.createElement("script")
      themejs.setAttribute("src", "https://unpkg.com/xlsx/dist/xlsx.full.min.js")
      document.head.appendChild(themejs)
    },
    setup(props, { root }) {
      const isSortDirDesc = ref(true)
      const perPage = ref(localStorage.getItem("perPage") || 10)
      const totalItems = ref(24)
      const searchQuery = ref("")
      const currentPage = ref(1)
      const sortBy = ref("id")
      const perPageOptions = [10, 25, 50, 100]
      const players = ref([])
      const showTable = ref("player")
      const refPlayerTable = ref(null)
      const reason = ref(null)
      const users = ref([])
      const user = ref(null)
      const deletedDate = ref(null)
      const lang = ref(i18n.locale)

      const fields = computed({
        get: () => [
          { key: "name", label: i18n.t("Player"), sortable: true },
          { key: "team", label: i18n.t("Team") },
          { key: "deletedDate", label: i18n.t("Action Date") },
          { key: "reason", label: i18n.t("Reason") },
          { key: "description", label: i18n.t("Description") },
          { key: "user", label: i18n.t("User") },
        ],
      })

      const getPlayers = () => {
        let url = showTable.value == "player" ? "/deleted-player" : "/deleted-scout-player"
        return axios
          .get(url, {
            params: {
              page: currentPage.value,
              row: perPage.value,
              q: searchQuery.value,
              reason: reason.value,
              user: user.value,
              startDate: deletedDate.value,
            },
          })
          .then((res) => {
            players.value = res.data.data
            // custom sort
            for (var i = 0; i < players.value.length; i++) {
              players.value[i].playerTransferHistories.sort(function (a, b) {
                return new Date(b.startDate) - new Date(a.startDate)
              })
              players.value[i].action = players.value[i].actions[players.value[i].actions.length - 1]
            }
            totalItems.value = res.data.count

            return players.value
          })
          .catch((error) => {
            console.log(error)
          })
      }

      const getUsers = async () => {
        const { data } = await axios.get(`/user`)
        users.value = data
      }

      onMounted(async () => {
        await getUsers()
      })

      watch([searchQuery, reason, user, showTable, deletedDate], () => {
        refPlayerTable.value.refresh()
      })

      watch(perPage, (perPage) => {
        localStorage.setItem("perPage", perPage)
      })

      const dataMeta = computed(() => {
        let localItemsCount = players.value.length > 0 ? players.value.length : 0
        return {
          from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
          to: perPage.value * (currentPage.value - 1) + localItemsCount,
          of: totalItems.value,
        }
      })

      const findAge = (birthdate) => {
        return Math.abs(new Date(Date.now() - new Date(birthdate).getTime()).getUTCFullYear() - 1970)
      }

      return {
        findAge,

        isSortDirDesc,
        perPage,
        totalItems,
        searchQuery,
        currentPage,
        sortBy,
        perPageOptions,
        dataMeta,
        fields,
        getPlayers,
        showTable,
        refPlayerTable,
        reason,
        user,
        users,
        deletedDate,
        lang,
      }
    },
  }
</script>
