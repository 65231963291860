<template>
  <b-overlay variant="white" :show="showLoading" spinner-variant="primary" blur="0" opacity=".75" rounded="sm">
    <b-card action-collapse>
      <div class="mt-2 mx-n2">
        <b-table
          ref="refSmsTable"
          :items="refetchData"
          class="position-relative"
          :empty-text="$t('No records found')"
          responsive
          primary-key="id"
          show-empty
          busy.sync="false"
          style="min-height: 400px"
          :sort-by.sync="sortBy"
          :sort-desc.sync="isSortDirDesc"
          :per-page="perPage"
          :current-page="currentPage"
        >
          <template #table-busy>
            <div class="text-center my-2">
              <b-spinner class="align-middle"></b-spinner>
            </div>
          </template>

          <template #cell(createdAt)="data">
            <span>{{ data.value | nativeDateTime }}</span>
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
              <label>{{ $t("Show") }}</label>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
              />

              <span class="text-muted">{{
                $t("format.showingData", { from: dataMeta.from, to: dataMeta.to, of: dataMeta.of })
              }}</span>
            </b-col>

            <!-- Pagination -->
            <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalItems"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-card>
  </b-overlay>
</template>

<script>
  import vSelect from "vue-select"
  import i18n from "@/libs/i18n"
  import { ref, watch, onMounted, computed } from "@vue/composition-api"
  import http from "@/libs/axios"

  export default {
    components: {
      vSelect,
    },
    setup() {
      const showLoading = ref(false)
      const isSortDirDesc = ref(true)
      const perPage = ref(localStorage.getItem("perPage") || 10)
      const totalItems = ref(24)
      const currentPage = ref(1)
      const sortBy = ref("createdAt")
      const perPageOptions = [10, 25, 50, 100]

      const fields = computed({
        get: () => [
          { key: "league", label: i18n.t("League"), sortable: true },
          { key: "season", label: i18n.t("Season"), sortable: true },
          { key: "file", label: i18n.t("File") },
          { key: "status", label: i18n.t("Status"), sortable: true },
          { key: "Match", label: i18n.t("Match"), sortable: true },
          { key: "actions", label: i18n.t("Actions") },
        ],
      })

      const analysisFiles = ref([])

      onMounted(async () => {})

      const refetchData = () => {
        showLoading.value = true
        return http
          .get(`/sms-log`, {
            params: {
              page: currentPage.value,
              row: perPage.value,
            },
          })
          .then((res) => {
            analysisFiles.value = res.data.data
            totalItems.value = res.data.count
            showLoading.value = false
            return analysisFiles.value
          })
          .catch((error) => {
            showLoading.value = false
            console.log(error)
          })
      }

      watch([currentPage], () => {
        refetchData()
      })

      watch(perPage, (perPage) => {
        localStorage.setItem("perPage", perPage)
      })

      const dataMeta = computed(() => {
        const localItemsCount = analysisFiles.value.length > 0 ? analysisFiles.value.length : 0
        return {
          from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
          to: perPage.value * (currentPage.value - 1) + localItemsCount,
          of: totalItems.value,
        }
      })

      return {
        analysisFiles,

        fields,

        showLoading,

        isSortDirDesc,
        perPage,
        totalItems,
        currentPage,
        sortBy,
        perPageOptions,
        dataMeta,
        refetchData,
      }
    },
  }
</script>
