<template>
  <!-- TODO : TRANSLATE -->
  <div>
    <b-modal
      v-model="showgoalmodal"
      id="modal-goal-add"
      ok-only
      :ok-title="$t('Save')"
      centered
      :title="$t('Save Goal')"
    >
      <b-card-text>
        <validation-observer ref="addGoalModal">
          <form action @submit.prevent="goSubmitGoal()" v-if="formGoal">
            <b-form-group :label="$t('Player')">
              <span class="bg-light text-dark d-block w-100 p-1">
                <feather-icon icon="UserIcon" size="24"></feather-icon><span class="ml-1">{{ formGoal.name }}</span>
              </span>
            </b-form-group>
            <b-form-group :label="$t('Assist')">
              <b-form-select
                label="title"
                value-field="id"
                text-field="name"
                :placeholder="$t('Select Assist Player')"
                v-model="competitionForm.goal.assist"
                :options="getMainSubs(getTeamPlayers).filter((i) => i.id != formGoal.id)"
              />
            </b-form-group>
            <b-form-group :label="$t('Minute')">
              <validation-provider #default="{ errors }" name="minute" rules="required">
                <b-form-input type="number" v-model="competitionForm.goal.dk" :placeholder="$t('dk')" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group :label="$t('Goal')">
              <b-form-select
                label="title"
                value-field="id"
                text-field="name"
                :placeholder="$t('Select Goal Type')"
                v-model="competitionForm.goal.type"
                :options="competitionForm.goal.option"
              />
            </b-form-group>
          </form>
        </validation-observer>
      </b-card-text>
      <template #modal-footer>
        <button type="button" class="btn btn-danger" v-if="competitionForm.goal.remove" @click="goRemoveGoal()">
          {{ $t("Delete Goal") }}
        </button>
        <button type="button" class="btn btn-primary" @click="goSubmitGoal()">{{ $t("Save") }}</button>
      </template>
    </b-modal>
    <b-modal
      v-model="showgoalmodalwp"
      id="modal-goal-add-wp"
      ok-only
      :ok-title="$t('Save')"
      centered
      :title="$t('Save Goal')"
    >
      <b-card-text>
        <validation-observer ref="addGoalModalWP">
          <form action @submit.prevent="goSubmitGoalWithoutplayer()">
            <b-form-group :label="$t('Player Name')">
              <b-form-input v-model="competitionForm.goal.pName" :label="$t('Player Name')"></b-form-input>
            </b-form-group>
            <b-form-group :label="$t('Minute')">
              <validation-provider #default="{ errors }" name="minute" rules="required">
                <b-form-input type="number" v-model="competitionForm.goal.dk" :placeholder="$t('dk')" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group :label="$t('Goal')">
              <b-form-select
                label="title"
                value-field="id"
                text-field="name"
                :placeholder="$t('Select Goal Type')"
                v-model="competitionForm.goal.type"
                :options="competitionForm.goal.option"
              />
            </b-form-group>
          </form>
        </validation-observer>
      </b-card-text>
      <template #modal-footer>
        <button type="button" class="btn btn-danger" v-if="competitionForm.goal.remove" @click="goRemoveGoal()">
          {{ $t("Delete Goal") }}
        </button>
        <button type="button" class="btn btn-primary" @click="goSubmitGoalWithoutplayer()">{{ $t("Save") }}</button>
      </template>
    </b-modal>
    <div class="alert alert-primary p-2" v-if="getMainSubs(getTeamPlayers).length == 0">
      <p>{{ $t("Not found any player") }}</p>
      <b-button
        :tabindex="`goal_btn_`"
        variant="outline-primary"
        @click="showgoalmodalwp = true"
        class="btn-icon btn-success align-self-center"
      >
        {{ $t("Add Goal") }}
        <feather-icon icon="PlusIcon" />
      </b-button>
    </div>
    <div class="table-responsive">
      <b-button
        v-if="competition[teamItem].team.id && routerParams.id.toString() != competition[teamItem].team.id.toString()"
        :tabindex="`goal_btn_001`"
        variant="outline-primary"
        @click="showgoalmodalwp = true"
        class="btn-icon btn-success align-self-center"
      >
        {{ $t("Add Goal") }}
        <feather-icon icon="PlusIcon" />
      </b-button>
      <table class="table table-bordered mt-2 formation-stat" v-if="getMainSubs(getTeamPlayers).length !== 0">
        <thead>
        <tr>
          <td style="width: 25rem">{{ $t("Player") }}</td>
          <td v-b-tooltip.hover :title="$t('Yellow Card')">
            <img :src="require('@/assets/images/icons/yellow-card.png')" height="32" alt />
          </td>
          <td v-b-tooltip.hover :title="$t('Red Card from 2nd Yellow')">
            <img :src="require('@/assets/images/icons/yellow-red-card.png')" height="32" alt />
          </td>
          <td v-b-tooltip.hover :title="$t('Red Card')">
            <img :src="require('@/assets/images/icons/red-card.png')" height="32" alt />
          </td>
          <td v-b-tooltip.hover :title="$t('Assist')">
            <img :src="require('@/assets/images/icons/foot.png')" height="32" alt />
          </td>
          <td v-b-tooltip.hover :title="$t('Goal!')">
            <img :src="require('@/assets/images/icons/ball.svg')" height="32" alt />
          </td>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(pl, index) in getMainSubs(getTeamPlayers)" :key="index">
          <td>
            <div class="d-flex justify-content-start">
              <div>
                <b-avatar :text="avatarText(pl.name)" :src="pl.avatar ? pl.avatar : null"></b-avatar>
              </div>
              <div class="ml-1">
                {{ pl.name }}
                <br />
                {{ $t(pl.position.name) }}
              </div>
            </div>
          </td>
          <td class>
            <input
              :tabindex="`yellow_` + index"
              placeholder="dk"
              @change="
                  goSubmitEvent({
                    id: pl.stats.yellowCard,
                    team: competition[teamItem].team.id,
                    player: pl,
                    dk: pl.stats.yellowCard,
                    id: pl.stats.yellowCardId,
                    type: 'YELLOW_CARD',
                  })
                "
              v-model="pl.stats.yellowCard"
              min="0"
              max="150"
              type="number"
              class="form-control"
            />
          </td>
          <td class>
            <input
              :tabindex="'yr_' + index"
              v-if="!pl.stats.redCard"
              @change="
                  goSubmitEvent({
                    id: pl.stats.yellowRedCard,
                    team: competition[teamItem].team.id,
                    player: pl,
                    dk: pl.stats.yellowRedCard,
                    id: pl.stats.yellowRedCardId,
                    type: 'YELLOW_RED_CARD',
                  })
                "
              v-model="pl.stats.yellowRedCard"
              placeholder="dk"
              min="0"
              max="150"
              type="number"
              class="form-control"
            />
          </td>
          <td class>
            <input
              :tabindex="'red' + index"
              v-if="!pl.stats.yellowRedCard"
              @change="
                  goSubmitEvent({
                    id: pl.stats.redCardId,
                    team: competition[teamItem].team.id,
                    id: pl.stats.redCardId,
                    player: pl,
                    dk: pl.stats.redCard,
                    type: 'RED_CARD',
                  })
                "
              v-model="pl.stats.redCard"
              placeholder="dk"
              min="0"
              max="150"
              type="number"
              class="form-control"
            />
          </td>
          <td class>
            <div class="d-flex justify-content-between">
              <div style="align-self: center">
                  <span
                    class="badge badge-primary"
                    v-b-tooltip.hover
                    :title="`${asist.minute}. dk`"
                    v-for="(asist, plgIndex) in getAsists(pl)"
                    :key="plgIndex"
                  >{{ asist.minute }}</span
                  >
              </div>
            </div>
          </td>
          <td class>
            <div class="d-flex">
              <div style="align-self: center">
                  <span
                    class="badge badge-primary ml-1"
                    v-b-tooltip.hover
                    :title="`${playerGoals.dk}. dk (${playerGoals.type})`"
                    v-for="(playerGoals, plgIndex) in pl.stats.goals"
                    :key="plgIndex"
                    @click="
                      showgoalmodal = true
                      formGoal = pl
                      competitionForm.goal.mainPlayer = pl
                      competitionForm.goal.id = playerGoals.id
                      competitionForm.goal.dk = playerGoals.dk
                      competitionForm.goal.type = playerGoals.type
                      competitionForm.goal.assist = playerGoals.subPlayerId
                      competitionForm.goal.remove = true
                    "
                  >{{ playerGoals.dk }}</span
                  >
              </div>
              <b-button
                :tabindex="`goal_btn_` + index"
                variant="outline-primary"
                @click="
                    formGoal = pl
                    competitionForm.goal.mainPlayer = pl
                    showgoalmodal = true
                  "
                class="btn-icon btn-success rounded-circle align-self-center"
              >
                <feather-icon icon="PlusIcon" />
              </b-button>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="table-responsive">
      <div v-if="getTeamPlayers.length != 0 && getSubs(getTeamPlayers) != 0">
        <div class="mt-0 py-2">
          <span class="h4">{{ $t("Substitute Players") }}</span>
        </div>
        <table class="table table-bordered mt-n1" v-if="getTeamPlayers.length !== 0">
          <thead>
          <tr>
            <td>{{ $t("Player") }}</td>
            <td v-b-tooltip.hover :title="$t('Game Login Minute')">
              <img :src="require('@/assets/images/icons/foot.png')" height="32" alt />
            </td>
            <td v-b-tooltip.hover :title="$t('Leaving Player')">
              <img :src="require('@/assets/images/icons/change-player.png')" height="32" />
            </td>
            <td>{{ $t("Change Reason") }}</td>
            <td>{{ $t("Action") }}</td>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(pl, index) in getSubs(getTeamPlayers)" :key="index">
            <td>
              <div class="d-flex justify-content-start">
                <div>
                  <b-avatar :text="avatarText(pl.name)" :src="pl.avatar ? pl.avatar : null"></b-avatar>
                </div>
                <div class="ml-1">
                  {{ pl.name }}
                  <br />
                  {{ $t(pl.position.name) }}
                </div>
              </div>
            </td>
            <td style="padding:0 7.5px">
              <input
                :tabindex="index"
                placeholder="dk"
                v-model="pl.stats.changedk"
                min="0"
                max="150"
                type="number"
                class="form-control"
              />
            </td>
            <td style="padding:0 7.5px">
              <div class="d-flex" style="gap: 10px">
                <b-form-select
                  v-model="pl.stats.changeplayer"
                  value-field="id"
                  text-field="name"
                  :options="getChangePlayerList(pl)"
                  placeholder="Seçiniz"
                >
                  <template #first>
                    <b-form-select-option
                      v-if="
                          getChangedPlayer(pl) != undefined &&
                          getChangedPlayer(pl).subPlayer != undefined &&
                          getChangedPlayer(pl).subPlayer.hasOwnProperty('id')
                        "
                      :value="getChangedPlayer(pl).subPlayer.id"
                    >{{ getChangedPlayer(pl) && getChangedPlayer(pl).subPlayer.name }}
                    </b-form-select-option
                    >
                    <b-form-select-option v-if="!pl.stats.changeplayer" value="" disabled>{{
                        $t("Select")
                      }}
                    </b-form-select-option>
                  </template>
                </b-form-select>
              </div>
            </td>
            <td style="padding:0 7.5px">
                  <span class="hidden d-none">
                    {{
                      Object.hasOwnProperty(getChangedPlayer(pl), "reason")
                        ? (pl.stats.changeplayer_reason = getChangedPlayer(pl).reason)
                        : (pl.stats.changeplayer_reason = "Tactical")
                    }}
                  </span>

              <b-form-select
                class="custom-select"
                v-model="pl.stats.changeplayer_reason"
                :options="[
                      { value: 'Tactical', text: $t('Tactical') },
                      { value: 'Disability', text: $t('Disability') },
                      { value: 'Tiredness', text: $t('Tiredness') },
                    ]"
              >
              </b-form-select>
            </td>
            <td style="padding:0 7.5px">


              <b-button
                variant="primary"
                class="ml-1"
                @click="
                      goSubmitEvent({
                        id: pl.stats.changeId,
                        team: competition[teamItem].team.id,
                        player: pl,
                        dk: pl.stats.changeplayer == '-1' ? 0 : pl.stats.changedk,
                        reason: pl.stats.changeplayer_reason,
                        type: 'CHANGE_PLAYER',
                        sub: pl.stats.changeplayer,
                      })
                    "
              >
                <feather-icon icon="SaveIcon"></feather-icon>
              </b-button>

              <b-button
              v-show="pl.stats.changeId"
                variant="outline-danger"
                class="ml-1"
                @click="
                      goSubmitEvent({
                        id: pl.stats.changeId,
                        team: competition[teamItem].team.id,
                        player: pl,
                        dk: 0,
                        reason: pl.stats.changeplayer_reason,
                        type: 'CHANGE_PLAYER',
                        sub: pl.stats.changeplayer,
                      })
                    "
              >
                <feather-icon icon="TrashIcon"></feather-icon>
              </b-button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
  import vSelect from "vue-select"
  import { avatarText } from "@core/utils/filter"
  import { ValidationProvider, ValidationObserver } from "vee-validate"
  import { required } from "@validations"

  export default {
    components: {
      vSelect,

      ValidationProvider,
      ValidationObserver,
    },
    props: {
      competitionForm: null,
      competition: null,
      helper: null,
      teamItem: null,
    },
    data() {
      return {
        formGoal: false,
        showgoalmodal: false,
        showgoalmodalwp: false,
        compStatsLoading: false,
      }
    },
    watch: {
      showgoalmodal: function(newShowGoalModal, oldShowGoalModal) {
        if (newShowGoalModal == false) {
          this.competitionForm.goal.assist = null
          this.competitionForm.goal.type = "FF"
          this.competitionForm.goal.dk = null
          this.competitionForm.goal.team = null
          this.competitionForm.goal.id = null
        }
      },
    },
    computed: {
      getTeamPlayers() {
        return this.competition[this.teamItem].team.teamPlayers
      },
    },
    methods: {
      getChangedPlayer(pl) {
        return this.competition.competitionEvents.find(
          (d) => d.competitionEventType == "CHANGE_PLAYER" && d.mainPlayer.id == pl.id,
        )
      },
      getChangePlayerList(pl) {
        var data = this.competition.competitionEvents
          .filter((d) => d.competitionEventType == "CHANGE_PLAYER")
          .map((d) => d.subPlayer && d.subPlayer.id)
        return Object.values(this.getMainSubs(this.getTeamPlayers)).filter((d) => d.id != pl.id && !data.includes(d.id))
      },
      goSubmitGoal() {
        this.$refs.addGoalModal.validate().then((success) => {
          if (success) {
            this.competitionForm.goal.team = this.competition[this.teamItem].team.id
            var ctMain = this.controlForLogic(this.competitionForm.goal.mainPlayer, this.competitionForm.goal.dk)
            var ctSub = this.controlForLogic(this.competitionForm.goal.assist, this.competitionForm.goal.dk)
            if (ctMain && ctSub) {
              this.helper
                .submitEventForm({
                  id: this.competitionForm.goal.id,
                  sub: this.competitionForm.goal.assist,
                  team: this.competitionForm.goal.team,
                  player: this.competitionForm.goal.mainPlayer,
                  dk: this.competitionForm.goal.dk,
                  type: "GOAL",
                  subType: this.competitionForm.goal.type,
                })
                .then((dt) => {
                  this.showgoalmodal = false
                  this.formGoal = false
                })
            }
          }
        })
      },
      goSubmitGoalWithoutplayer() {
        this.$refs.addGoalModalWP.validate().then((success) => {
          if (success) {
            this.competitionForm.goal.team = this.competition[this.teamItem].team.id
            var ctMain = this.controlForLogic(this.competitionForm.goal.mainPlayer, this.competitionForm.goal.dk)
            var ctSub = this.controlForLogic(this.competitionForm.goal.assist, this.competitionForm.goal.dk)
            if (ctMain && ctSub) {
              this.helper
                .submitEventFormWithoutplayer({
                  id: this.competitionForm.goal.id,
                  team: this.competitionForm.goal.team,
                  dk: this.competitionForm.goal.dk,
                  playerName: this.competitionForm.goal.pName,
                  type: "GOAL",
                  subType: this.competitionForm.goal.type,
                })
                .then((dt) => {
                  this.showgoalmodalwp = false
                  this.formGoal = false
                })
            }
          }
        })
      },
      controlForLogic(pl, dk) {
        if (pl == null) return "NULL"
        if (!pl.id) pl = Object.values(this.getTeamPlayers).find((d) => d.id == pl)
        let findRepPlayer = Object.values(this.getTeamPlayers).find((data) => data.stats.changeplayer == pl.id)
        if (findRepPlayer) {
          if (Number(findRepPlayer.stats.changedk) < Number(pl.stats.yellowCard)) pl.stats.yellowCard = null
          if (Number(findRepPlayer.stats.changedk) < Number(pl.stats.redCard)) pl.stats.redCard = null
          if (Number(findRepPlayer.stats.changedk) < Number(pl.stats.yellowRedCard)) pl.stats.yellowRedCard = null
        } else {
          if (Number(pl.stats.changedk) > Number(dk)) {
            this.toast(
              this.$t("Not available"),
              "EditIcon",
              "warning",
              this.$t("Please enter different time for ") + pl.name,
            )
            return false
          }
        }
        return true
      },
      goSubmitEvent(object) {
        setTimeout(() => {
          this.helper.submitEventForm(object)
        }, 1000)
      },
      goRemoveGoal() {
        this.competitionForm.goal.team = this.competition[this.teamItem].team.id
        this.competitionForm.goal.dk = 0 // FOR REMOVE, if dk == 0, backend will auto remove this.
        this.helper.submitEventForm({
          id: this.competitionForm.goal.id,
          sub: this.competitionForm.goal.assist,
          team: this.competitionForm.goal.team,
          player: this.competitionForm.goal.mainPlayer,
          dk: this.competitionForm.goal.dk,
          type: "GOAL",
          subType: this.competitionForm.goal.type,
        })
        this.showgoalmodal = false
        this.formGoal = false
      },
      getSubs(Players) {
        return Object.values(Players).filter((pl) => {
          if (pl.isStarterPlayer !== undefined && pl.isStarterPlayer == 0) {
            return pl
          }
        })
      },
      getMainSubs(Players) {
        return Object.values(Players).filter((pl) => {
          if (
            pl.isStarterPlayer !== undefined &&
            (Number(pl.isStarterPlayer) == 1 || (Number(pl.isStarterPlayer) == 0 && pl.stats.changedk !== undefined))
          ) {
            return pl
          }
        })
      },
      getAsists(Player) {
        return Object.values(this.competition.competitionEvents).filter((event) => {
          if (event.competitionEventType === "GOAL" && event.subPlayer && event.subPlayer.id === Player.id) {
            return event.minute
          }
        })
      },
    },
    beforeMount() {
      this.competition.changeplayer = null
    },
    created() {
    },
    setup(props, { root }) {
      return { avatarText }
    },
  }
</script>
<style>
  @media (max-width: 1550px) {
    [dir] .formation-stat.table td:nth-child(n + 2) {
      padding: 5px;
    }
  }
</style>
