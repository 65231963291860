<template>
  <div>
    <div class="mt-2 card card-body py-2">
      <addMatchInfoBar :competitionForm="competitionForm" :competition="competition" :showSection.sync="showSection">
      </addMatchInfoBar>
    </div>

    <b-card>
      <template #header>
        <h3>
          {{
            new Date(
              competitionForm.competition.date.date + " " + competitionForm.competition.date.time,
            ).toLocaleDateString(undefined, {
              weekday: "long",
              year: "numeric",
              month: "long",
              day: "numeric",
              hour: "numeric",
              minute: "numeric",
            })
          }}
        </h3>
      </template>
      <b-card-body> </b-card-body>
    </b-card>
  </div>
</template>

<script>
  import useAddMatch from "../add-match/useAddMatch"
  import addMatchInfoBar from "../add-match/addMatchInfoBar"

  export default {
    components: {
      addMatchInfoBar,
    },
    methods: {},
    computed: {},
    created() {
      this.loading = false
      console.log(this.$refs["compInfoBar"])
    },
    data() {
      return {
        loading: true,
      }
    },
    destroyed() {},
    setup(props, { root }) {
      const { showSection, competition, competitionForm } = useAddMatch()

      return {
        showSection,
        competition,
        competitionForm,
      }
    },
  }
</script>

<style lang="scss" scoped>
  .h-40 {
    height: 40px;
  }

  .inp {
    box-shadow: unset !important;
  }
</style>
