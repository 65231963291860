<template>
  <div class="w-100">
    <b-row class="p-1">
      <div class="col-md-12">
        <div v-if="teamProps.team.teamPlayers.length < 1">
          <div class="">
            <div class="alert alert-warning p-2">
              {{ $t("Not found any player") }}
            </div>
          </div>
        </div>
        <div class="table-responsive" v-if="teamProps.team.teamPlayers.length">
          <b-table
            bordered
            :items="getTeamPlayers"
            :fields="[
              { key: 'name', label: 'Player', sortable: true, thStyle: { minWidth: '20rem' } },
              { key: 'position', label: 'Position', sortable: true },
              { key: 'matchPlayer', label: 'matchPlayer', thStyle: { width: '10rem' } },
              { key: 'changePlayer', label: 'changePlayer', thStyle: { width: '10rem' } },
              { key: 'injuryPlayer', label: 'Injury', thStyle: { width: '10rem' } },
              { key: 'outOffTeam', label: 'Out of Squad', thStyle: { width: '10rem' } },
            ]"
            sticky-header="100vh"
          >
            <template #head(name)="scope">
              <div class="d-flex justify-content-between" style="line-height: 3">
                <div>
                  <span class="h5">{{ $t("Player") }} </span>
                </div>
                <div v-if="false">
                  <b-dropdown variant="outline-primary" :text="$t('Select Position')">
                    <b-dropdown-item>Orta Saha</b-dropdown-item>
                    <b-dropdown-item>Orta Saha</b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
            </template>
            <template #head(position)="scope">
              {{ $t("Position") }}
            </template>
            <template #head(changePlayer)="scope">
              <div v-b-tooltip.hover :title="$t('Substitute')">
                <img :src="require('@/assets/images/icons/change-player.png')" height="32" />
              </div>
            </template>
            <template #head(matchPlayer)="scope">
              <div v-b-tooltip.hover :title="$t('Squad Player')">
                <img :src="require('@/assets/images/icons/foot.png')" height="32" alt />
              </div>
            </template>
            <template #thead-top="data" v-if="false">
              <b-tr>
                <b-th>
                  <div class="d-flex justify-content-between" style="line-height: 3">
                    <div>
                      <span class="h5">{{ $t("Player") }} </span>
                    </div>
                    <div>
                      <b-dropdown variant="outline-primary" :text="$t('Select Position')">
                        <b-dropdown-item>Orta Saha</b-dropdown-item>
                        <b-dropdown-item>Orta Saha</b-dropdown-item>
                      </b-dropdown>
                    </div>
                  </div>
                </b-th>
                <b-th>{{ $t("Position") }}</b-th>
                <!-- TODO : TRANSLATE -->
                <b-th v-b-tooltip.hover :title="$t('Squad Player')">
                  <img :src="require('@/assets/images/icons/foot.png')" height="32" alt />
                </b-th>
                <b-th v-b-tooltip.hover :title="$t('Substitute')">
                  <img :src="require('@/assets/images/icons/change-player.png')" height="32" />
                </b-th>
                <b-th v-b-tooltip.hover :title="$t('Injury')">
                  {{ $t("Injury") }}
                </b-th>
                <b-th v-b-tooltip.hover :title="$t('Out Of Squad')">
                  {{ $t("Out of Squad") }}
                </b-th>
              </b-tr>
            </template>

            <template #cell(name)="data">
              <div class="d-flex justify-content-start">
                <div>
                  <b-avatar :src="data.item.avatar" :text="avatarText(data.item.name)"></b-avatar>
                </div>
                <div class="ml-1">{{ data.item.name }}</div>
              </div>
            </template>
            <template #cell(position)="data">
              {{ $t(data.item.position.name) }}
            </template>
            <template #cell(matchPlayer)="data">
              <input
                onclick="return false;"
                class="formationSelect"
                type="checkbox"
                v-model="data.item.isStarterPlayer"
                true-value="1"
                false-value="2"
              />
            </template>
            <template #cell(changePlayer)="data">
              <input
                onclick="return false;"
                class="formationSelect"
                type="checkbox"
                v-model="data.item.isStarterPlayer"
                true-value="0"
                false-value="2"
              />
            </template>
            <template #cell(injuryPlayer)="data">
              <input
                onclick="return false;"
                class="formationSelect"
                type="checkbox"
                v-model="data.item.isStarterPlayer"
                true-value="3"
                false-value="2"
              />
            </template>
            <template #cell(outOffTeam)="data">
              <input
                onclick="return false;"
                class="formationSelect"
                type="checkbox"
                v-model="data.item.isStarterPlayer"
                true-value="2"
                false-value="2"
              />
            </template>
          </b-table>
          <br />
        </div>
      </div>
    </b-row>
  </div>
</template>
<script>
  import FormationPlugin from "./FormationPlugin"
  import addFormationTableAddNew from "./addFormationTableAddNew"
  export default {
    components: { FormationPlugin, addFormationTableAddNew },
    props: {
      teamProps: null,
      competition: null,
      competitionForm: null,
      avatarText: null,
      FormationPlugin: null,
      helperFuncs: null,
    },
    data() {
      return {
        triggerPlugin: 0,
      }
    },
    computed: {
      getTeamPlayers() {
        return Object.values(this.teamProps.team.teamPlayers).filter((player) => player.isStarterPlayer != null)
      },
    },
    methods: {
      drawFormationPlugin(player, teamId) {
        this.helperFuncs.submitFormation(player, teamId).then((dt) => {
          this.triggerPlugin++
        })
      },
    },
    setup(props, { root }) {},
    mounted() {},
  }
</script>
