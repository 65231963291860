<template>
  <div class="ability">
    <div class="d-flex justify-content-between">
      <div>
        <b-form-rating
          :readonly="disabled"
          class="rating"
          :stars="getSetting('star')"
          precision="3"
          no-border
          :size="$store.getters['app/currentBreakPoint'] === 'xs' ? 'sm' : 'xl'"
          variant="primary"
          v-model="rate"
          @change="changeRating"
        ></b-form-rating>
      </div>
      <div class="d-flex justify-content-end">
        <input
          :disabled="disabled"
          style="width: 45px"
          placeholder=""
          v-model.number="rate"
          type="number"
          @change="changeRating"
          :class="$store.getters['app/currentBreakPoint'] === 'xs' ? 'form-control' : 'form-control'"
        />
      </div>
    </div>
    <b-button variant="link" class="" v-if="!showCommentInput" @click="showCommentInput = true">Yorum Ekle</b-button>
    <input
      v-if="showCommentInput"
      :disabled="disabled || Number.isNaN(parseInt(rate))"
      placeholder=""
      v-model="comment"
      type="text"
      @change="changeRating"
      class="form-control comment mt-1 mb-1"
    />
  </div>
</template>

<script>
  import { ref, watch, reactive, onBeforeMount, onMounted } from "@vue/composition-api"

  import axios from "@axios"
  import { useRouter } from "@core/utils/utils"

  import { useToast } from "vue-toastification/composition"
  import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
  import i18n from "@/libs/i18n"

  export default {
    components: {},
    props: {
      player: {
        type: Object,
        default: {},
      },
      ability: {
        type: Number,
        default: 1,
      },
      items: {
        required: true,
        type: Array,
        default: () => [],
      },
      disabled: {
        type: Boolean,
        default: false,
      },
    },
    setup(props, { emit, root }) {
      const { router, route } = useRouter()
      const toast = useToast()

      const showCommentInput = ref(false)
      const rate = ref(null)
      const comment = ref(null)
      const tempItem = ref(null)
      const setItem = (data) => {
        rate.value = null
        comment.value = null
        showCommentInput.value = false
        return props.items.map((item) => {
          if (item.abilityItem.id === props.ability && item.player.id === props.player.id) {
            tempItem.value = item
            rate.value = item.rate
            comment.value = item.comment

            if (item.comment) {
              showCommentInput.value = true
            }
            return false
          }
        })
      }

      const changeRating = () => {
        let object = {
          player: props.player.id,
          abilityItem: props.ability,
          performance: parseInt(router.currentRoute.params.performanceId),
          season: parseInt(router.currentRoute.params.seasonId),
          rate: rate.value,
          comment: comment.value,
        }
        if (Number.isNaN(parseInt(rate.value))) return false
        if (props.disabled) return false
        if (tempItem.value && tempItem.value.abilityItem.id === props.ability) {
          axios.put(`performance-ability-item/${tempItem.value.id}`, object).then((response) => {
            toast({
              component: ToastificationContent,
              position: "bottom-right",
              props: {
                title: i18n.t("Successfully Saved"),
                icon: "CheckIcon",
                variant: "success",
              },
            })
          })
        } else {
          axios.post(`performance-ability-item`, object).then((response) => {
            tempItem.value = response.data
            toast({
              component: ToastificationContent,
              position: "bottom-right",
              props: {
                title: i18n.t("Successfully Added"),
                icon: "CheckIcon",
                variant: "success",
              },
            })
          })
        }
      }

      watch(
        () => props.ability,
        (val, oldVal) => {
          if (val !== oldVal) {
            setItem()
          }
        },
      )

      onMounted(() => {
        setItem()
      })

      return { rate, changeRating, comment, showCommentInput }
    },
  }
</script>

<style lang="scss">
  .ability .b-rating-icon {
    font-size: 40px;
    @media (max-width: 767px) {
      font-size: 18px;
    }
  }
  .ability {
    @media (max-width: 767px) {
      min-width: 100px;
      max-width: 350px;
    }
  }
</style>
