<template>
  <section class="invoice-add-wrapper">
    <b-row class="invoice-add">
      <!-- Col: Left (Invoice Container) -->
      <b-col cols="12" xl="9" md="8">
        <b-form @submit.prevent="addTeam()" @reset.prevent="resetForm">
          <b-card no-body class="invoice-preview-card">
            <b-card-body>
              <b-row>
                <!-- Col: Sales Persion -->
                <b-col cols="12" md="6" sm="12" class="mt-md-0" order="2" order-md="1">
                  <label class="mr-50">Avatar:</label>
                  <b-form-file
                    :browseText="$t('Browse')"
                    v-model="formData.avatar"
                    placeholder="Bir resim secin veya buraya surukleyin.."
                    drop-placeholder="buraya surukleyin.."
                    no-drop
                  />
                </b-col>
              </b-row>
            </b-card-body>

            <b-card-body>
              <b-row>
                <!-- Col: Sales Persion -->
                <b-col cols="12" md="6" sm="12" class="mt-md-0" order="2" order-md="1">
                  <label class="mr-50">Takim Ismi:</label>
                  <b-form-input
                    id="invoice-data-sales-person"
                    v-model="formData.teamName"
                    placeholder="Edward Crowley"
                  />
                </b-col>

                <b-col cols="12" md="6" sm="12" class="mt-md-0" order="2" order-md="1">
                  <label class="mr-50">Brans:</label>
                  <v-select
                    v-model="formData.branch"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="branchOptions"
                    input-id="payment-method"
                    class="payment-selector"
                    :clearable="false"
                  />
                </b-col>
              </b-row>
            </b-card-body>

            <b-card-body>
              <b-row>
                <!-- Col: Sales Persion -->
                <b-col cols="12" md="6" sm="12" class="mt-md-0" order="2" order-md="1">
                  <label class="mr-50">Antrenor Ismi:</label>
                  <b-form-input
                    id="invoice-data-sales-person"
                    v-model="formData.staff.antrenor"
                    placeholder="Edward Crowley"
                  />
                </b-col>

                <b-col cols="12" md="6" sm="12" class="mt-md-0" order="2" order-md="1">
                  <label class="mr-50">Yardimci Antrenor Ismi:</label>
                  <b-form-input
                    id="invoice-data-sales-person"
                    v-model="formData.staff.yardimciAntrenor"
                    placeholder="Edward Crowley"
                  />
                </b-col>
              </b-row>
            </b-card-body>

            <b-card-body>
              <b-row>
                <!-- Col: Sales Persion -->
                <b-col cols="12" md="6" sm="12" class="mt-md-0" order="2" order-md="1">
                  <label class="mr-50">Kondisyoner Ismi:</label>
                  <b-form-input
                    id="invoice-data-sales-person"
                    v-model="formData.staff.kondisyoner"
                    placeholder="Edward Crowley"
                  />
                </b-col>

                <b-col cols="12" md="6" sm="12" class="mt-md-0" order="2" order-md="1">
                  <label class="mr-50">Kaleci Antrenor Ismi:</label>
                  <b-form-input
                    id="invoice-data-sales-person"
                    v-model="formData.staff.kaleciAntrenoru"
                    placeholder="Edward Crowley"
                  />
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
          <input type="submit" class="d-none" />
        </b-form>
      </b-col>

      <!-- Right Col: Card -->
      <b-col cols="12" md="4" xl="3" class="mt-md-0 mt-2">
        <!-- Action Buttons -->
        <b-card>
          <!-- Button: Send Invoice -->
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mb-75" block> Save </b-button>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
  import Logo from "@core/layouts/components/Logo.vue"
  import { ref, onUnmounted } from "@vue/composition-api"
  import { heightTransition } from "@core/mixins/ui/transition"
  import Ripple from "vue-ripple-directive"
  import store from "@/store"
  import {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BFormCheckbox,
    BPopover,
    VBToggle,
    BFormFile,
  } from "bootstrap-vue"
  import vSelect from "vue-select"
  import flatPickr from "vue-flatpickr-component"
  import teamStoreModule from "../teamStoreModule"
  import { useToast } from "vue-toastification/composition"
  import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
  import formValidation from "@core/comp-functions/forms/form-validation"

  export default {
    components: {
      BRow,
      BCol,
      BCard,
      BCardBody,
      BButton,
      BCardText,
      BForm,
      BFormGroup,
      BFormInput,
      BInputGroup,
      BInputGroupPrepend,
      BFormTextarea,
      BFormCheckbox,
      BPopover,
      flatPickr,
      vSelect,
      Logo,
      BFormFile,
    },
    directives: {
      Ripple,
      "b-toggle": VBToggle,
    },
    mixins: [heightTransition],
    mounted() {
      this.initTrHeight()
    },
    created() {
      window.addEventListener("resize", this.initTrHeight)
    },
    destroyed() {
      window.removeEventListener("resize", this.initTrHeight)
    },
    methods: {
      addNewTeam() {
        console.log("addNewTeam")
      },
      addNewItemInItemForm() {
        this.$refs.form.style.overflow = "hidden"
        this.invoiceData.items.push(JSON.parse(JSON.stringify(this.itemFormBlankItem)))

        this.$nextTick(() => {
          if (Array.isArray(this.$refs.row)) {
            this.trAddHeight(this.$refs.row[0].offsetHeight)
          }
          setTimeout(() => {
            this.$refs.form.style.overflow = null
          }, 350)
        })
      },
      removeItem(index) {
        this.invoiceData.items.splice(index, 1)
        this.trTrimHeight(this.$refs.row[0].offsetHeight)
      },
      initTrHeight() {
        this.trSetHeight(null)
        this.$nextTick(() => {
          if (Array.isArray(this.$refs.row)) {
            this.trSetHeight(this.$refs.form.scrollHeight)
          }
        })
      },
    },
    setup(props, { root }) {
      const toast = useToast()
      const STORE_MODULE_NAME = "app-team"

      // Register module
      if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, teamStoreModule)

      // UnRegister on leave
      onUnmounted(() => {
        if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
      })

      const formData = ref({
        teamName: "Fenerbahce U17",
        avatar: null,
        branch: "Futbol",
        staff: {
          antrenor: "Erol Bulut",
          yardimciAntrenor: "Volkan Demirel",
          kondisyoner: "Usain Bolt",
          kaleciAntrenoru: "Michael Schumacher",
        },
      })

      const { resetForm } = formValidation()

      const branchOptions = ["Futbol", "Voleybol", "Basketbol"]

      const addTeam = () => {
        store
          .dispatch("app-team/addTeam", formData)
          .then((success) => {
            toast({
              component: ToastificationContent,
              props: {
                title: "Team added",
                icon: "StarIcon",
                variant: "success",
              },
            })
          })
          .catch((err) => {
            toast({
              component: ToastificationContent,
              props: {
                title: "Error adding team",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            })
          })
      }

      return {
        formData,
        addTeam,
        branchOptions,
        resetForm,
      }
    },
  }
</script>

<style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
  @import "@core/scss/vue/libs/vue-flatpicker.scss";

  .invoice-add-wrapper {
    .add-new-client-header {
      padding: $options-padding-y $options-padding-x;
      color: $success;

      &:hover {
        background-color: rgba($success, 0.12);
      }
    }
  }
</style>

<style lang="scss" scoped>
  @import "~@core/scss/base/pages/app-invoice.scss";
  @import "~@core/scss/base/components/variables-dark";

  .form-item-section {
    background-color: $product-details-bg;
  }

  .form-item-action-col {
    width: 27px;
  }

  .repeater-form {
    // overflow: hidden;
    transition: 0.35s height;
  }

  .v-select {
    &.item-selector-title,
    &.payment-selector {
      background-color: #fff;

      .dark-layout & {
        background-color: unset;
      }
    }
  }

  .dark-layout {
    .form-item-section {
      background-color: $theme-dark-body-bg;

      .row .border {
        background-color: $theme-dark-card-bg;
      }
    }
  }
</style>
