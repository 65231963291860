<template>
  <b-card :title="$t(data.title)">
    <app-echart-bar v-if="data.series.length > 0" :option-data="option" />
    <div v-else class="alert alert-primary p-2">{{ $t("Data not found") }}</div>
  </b-card>
</template>

<script>
  import { BCard } from "bootstrap-vue"
  import AppEchartBar from "@core/components/charts/echart/AppEchartBar.vue"

  export default {
    components: {
      BCard,
      AppEchartBar,
    },
    props: {
      data: {
        type: Object,
        default: () => {},
      },
    },
    data() {
      return {
        option: {
          xAxis: [
            {
              type: "value",
            },
          ],
          yAxis: [
            {
              type: "category",
              data: this.data.weeklyDays,
              splitLine: { show: true },
            },
          ],
          grid: {
            left: "100px",
            right: "30px",
            bottom: "30px",
          },
          series: this.data.series,
        },
      }
    },
  }
</script>

<style>
  .echarts {
    width: 100%;
  }
</style>
