import axios from "@axios"

export default {
  namespaced: true,
  state: {
    players: {},
    history: {},
  },
  getters: {
    players: (state) => state.players,
    history: (state) => state.history,
  },
  mutations: {
    // PLAYERS
    ADD_PLAYER(state, item) {
      state.players.unshift(item)
    },
    SET_PLAYER(state, itemData) {
      state.players = itemData
    },
    UPDATE_PLAYER(state, item) {
      const itemIndex = state.players.findIndex((i) => i.id === item.id)
      Object.assign(state.players[itemIndex], item)
    },
    REMOVE_PLAYER(state, itemId) {
      const ItemIndex = state.players.findIndex((i) => i.id === itemId)
      state.players.splice(ItemIndex, 1)
    },
  },
  actions: {
    /**
     *
     * @param {id} playerId
     * @param {playerData} playerData
     * @param {playerEduData} playerEducationData
     * @param {eduId} playerEducationId
     */
    fetchPlayer(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/player/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    updatePlayer(ctx, { id, playerData }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/player/${id}`, playerData)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    addEducation(ctx, { id, playerEduData }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/player/${id}/edu`, playerEduData)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    editEducation(ctx, { eduId, playerEduData }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/player/edu/${eduId}`, playerEduData)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    deleteEducation(ctx, { eduId }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/player/edu/${eduId}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },

    // CONTACT
    addContact(ctx, { id, playerContactData }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/player/${id}/contact`, playerContactData)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    editContact(ctx, { contactId, playerContactData }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/player/contact/${contactId}`, playerContactData)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    deleteContact(ctx, { contactId }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/player/contact/${contactId}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },

    // TRANSFER HISTORY
    addTransferHistory(ctx, { id, playerTransferHistoryData }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/player/${id}/transfer-history`, playerTransferHistoryData)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    editTransferHistory(ctx, { transferHistoryId, playerTransferHistoryData }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/player/transfer-history/${transferHistoryId}`, playerTransferHistoryData)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    deleteTransferHistory(ctx, { transferHistoryId }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/player/transfer-history/${transferHistoryId}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },

    // Reviews / Comment
    addComment(ctx, { id, data }) {
      return new Promise((resolve, reject) => {
        axios
          .post("/player/review", data)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    editComment(ctx, { dataId, data }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/player/review/${dataId}`, data)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    deleteComment(ctx, { dataId }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/player/review/${dataId}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },

    // MOCKAPI DUMMY DATA
    fetchUsers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get("https://602b8a2fef26b40017f145a3.mockapi.io/api/players", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    fetchUser(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`https://602b8a2fef26b40017f145a3.mockapi.io/api/players/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },

    updateUser(ctx, { id, userData }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`https://602b8a2fef26b40017f145a3.mockapi.io/api/players/${id}`, updateUser)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },

    addUser(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post("https://602b8a2fef26b40017f145a3.mockapi.io/api/players", userData)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    fetchHistory(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get("https://5fb45c0ee473ab0016a164c9.mockapi.io/api/v1/playermatch")
          .then((res) => resolve(res))
          .catch((err) => reject(err))
      })
    },
  },
}
