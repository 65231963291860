<template>
  <div @click="goToPlayer" class="shadow-sm rounded px-1 pt-1 bg-white">
    <div class="d-flex p-0">
      <div class="p-0">
        <b-avatar :src="user ? user.avatar : ''" size="80px" rounded />
      </div>
      <div>
        <b-link class="mt-0 ml-1 d-block h4" :to="'/staff/edit/' + user.id">{{ user.fullName }}</b-link>
        <span class="mt-0 ml-1 d-block">{{ user.data.birthDate }}</span>
        <div class="p-1 d-flex">
          <span class="badge badge-primary mr-1 col">{{ user.role }}</span>
          <span class="badge badge-dark col">{{ user.status }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { BLink, BRow, BCol, BAvatar, BCard, BTab, BTabs, BCardText, BCardTitle, BButton } from "bootstrap-vue"
  import Ripple from "vue-ripple-directive"
  import { avatarText } from "@core/utils/filter"

  export default {
    props: {
      user: {
        type: Object,
        default: {},
      },
    },
    components: {
      BLink,
      BRow,
      BCol,
      BAvatar,
      BCard,
      BTab,
      BTabs,
      BCardText,
      BCardTitle,
      BButton,
      avatarText,
    },
    directives: {
      Ripple,
    },
    setup(props, { root }) {
      return {
        avatarText,
      }
    },
    methods: {
      goToPlayer() {
        this.$router.push({ name: "staff-edit", params: { id: this.routerParams.id, staffId: this.user.id } })
      },
    },
  }
</script>
<style lang="scss"></style>
