<template>
  <div class="d-flex flex-column justify-content-center">
    <!-- BODY -->
    <b-card>
      <div class="d-flex">
        <h4>{{ $t("Scout Player Transfer") }}</h4>
        <span>
          <feather-icon id="popover-button-info" icon="InfoIcon" class="mr-1 outline-info"></feather-icon>

          <b-popover target="popover-button-info" triggers="hover click" placement="right">
            <span>{{ $t("Transfer date, Transfer season's start date is set as.") }}</span>
          </b-popover>
        </span>
      </div>
    </b-card>
    <b-row>
      <b-col md="6">
        <b-card>
          <b-overlay variant="white" :show="loading" spinner-variant="primary" blur="0" opacity=".75" rounded="sm">
            <div class="d-flex">
              <div class="px-1 w-50">
                <h6 class="text-primary font-weight-bold mb-2">
                  {{ $t("Current Season") }}
                </h6>
                <v-select
                  id="staffRole"
                  :placeholder="$t('Please select a season')"
                  v-model="currentSeason"
                  :options="seasons"
                  :reduce="(option) => option.id"
                  :getOptionLabel="(option) => option.name"
                >
                </v-select>
                <div class="my-1 w-100" v-if="currentPlayers">
                  <div v-if="currentPlayers" class="py-1">
                    <b-form-group :label="$t('Players')">
                      <div v-for="(player, index) in currentPlayers" :key="index">
                        <div class="d-flex">
                          <p class="text-sm">{{ player.name }}</p>
                          <feather-icon
                            @click="transferToright(player, index)"
                            icon="ArrowRightIcon"
                            size="18"
                            class="ml-1 cursor-pointer bg-primary text-white"
                          />
                        </div>
                      </div>
                    </b-form-group>
                  </div>
                  <div v-else>
                    {{ $t("Players Not Found") }}
                  </div>
                </div>
              </div>
              <div class="px-1 w-50">
                <h6 class="text-primary font-weight-bold mb-2">
                  {{ $t("Current Team") }}
                </h6>
                <v-select
                  v-model="currentTeam"
                  :placeholder="$t('Please select a team')"
                  :getOptionLabel="(option) => (option.club ? option.club.name + ' - ' + option.name : option.name)"
                  :options="teams"
                  :reduce="(val) => val.id"
                  :clearable="false"
                  input-id="team"
                >
                  <template v-slot:option="option">
                    <span class="text-nowrap">{{ option.club ? option.club.name : "" }} / {{ option.name }}</span>
                  </template>
                </v-select>
              </div>
            </div>
          </b-overlay>
        </b-card>
      </b-col>
      <b-col md="6" class="mt-sm-2 mt-md-0">
        <b-card>
          <b-overlay variant="white" :show="loading" spinner-variant="primary" blur="0" opacity=".75" rounded="sm">
            <div class="d-flex">
              <div class="px-1 w-50">
                <h6 class="text-primary font-weight-bold mb-2">
                  {{ $t("Transfer Season") }}
                </h6>
                <v-select
                  id="staffRole"
                  :placeholder="$t('Please select a season')"
                  v-model="transferSeason"
                  :options="seasons.filter((season) => season.year > currentSeasonYear)"
                  :reduce="(option) => option.id"
                  :getOptionLabel="(option) => option.name"
                >
                </v-select>
                <div class="my-1 w-100" v-if="transferPlayers">
                  <div v-if="transferPlayers" class="py-1">
                    <b-form-group :label="$t('Players')">
                      <div v-for="(player, index) in transferPlayers" :key="index">
                        <div class="d-flex">
                          <feather-icon
                            @click="transferToleft(player, index)"
                            icon="ArrowLeftIcon"
                            size="18"
                            class="mr-1 cursor-pointer bg-primary text-white"
                          />
                          <p class="text-sm">{{ player.name }}</p>
                        </div>
                      </div>
                    </b-form-group>
                  </div>
                  <div v-else>
                    {{ $t("Players Not Found") }}
                  </div>
                </div>
              </div>
              <div class="px-1 w-50">
                <h6 class="text-primary font-weight-bold mb-2">
                  {{ $t("Transfer Team") }}
                </h6>
                <v-select
                  v-model="transferTeam"
                  :placeholder="$t('Please select a team')"
                  :getOptionLabel="(option) => (option.club ? option.club.name + ' - ' + option.name : option.name)"
                  :options="teams"
                  :reduce="(val) => val.id"
                  :clearable="false"
                  input-id="team"
                >
                  <template v-slot:option="option">
                    <span class="text-nowrap">{{ option.club ? option.club.name : "" }} / {{ option.name }}</span>
                  </template>
                </v-select>
              </div>
            </div>
          </b-overlay>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
  import { ValidationProvider, ValidationObserver } from "vee-validate"
  import { ref, onMounted, computed, watch } from "@vue/composition-api"
  import { required } from "@validations"
  import flatPickr from "vue-flatpickr-component"
  import vSelect from "vue-select"
  import http from "@/libs/axios"
  import store from "@/store"
  import { useRouter } from "@core/utils/utils"
  import i18n from "@/libs/i18n"

  export default {
    components: {
      flatPickr,
      vSelect,
      ValidationProvider,
      ValidationObserver,
    },
    setup(props, { root }) {
      const { router, route } = useRouter()
      //const seasons = JSON.parse(store.getters["app/seasons"]);
      const teams = ref([])
      const seasons = ref([])
      const loading = ref(false)
      const currentSeason = ref(null)
      const currentSeasonYear = ref(null)
      const currentTeam = ref(null)
      const currentPlayers = ref(null)
      const transferSeasonYear = ref(null)
      const transferSeason = ref(null)
      const transferTeam = ref(null)
      const transferPlayers = ref(null)
      onMounted(async () => {
        await getTeams()
        await getSeasons()
      })
      const getTeams = () => {
        http
          .get(`/team`)
          .then((res) => {
            teams.value = res.data.data
          })
          .catch((error) => console.log(error))
      }
      const getSeasons = () => {
        http
          .get(`/season`)
          .then((res) => {
            seasons.value = res.data
          })
          .catch((error) => console.log(error))
      }
      const getCurrentPlayers = (season, team) => {
        loading.value = true
        http
          .get(`/season/${season}/team/${team}`)
          .then((res) => {
            loading.value = false
            currentPlayers.value = res.data.players
          })
          .catch((error) => console.log(error))
      }
      const getTransferPlayers = (season, team) => {
        loading.value = true
        http
          .get(`/season/${season}/team/${team}`)
          .then((res) => {
            loading.value = false
            transferPlayers.value = res.data.players
          })
          .catch((error) => console.log(error))
      }
      watch(currentTeam, (val) => {
        if (currentSeason.value) {
          getCurrentPlayers(currentSeason.value, val)
        }
      })
      watch(currentSeason, (val) => {
        currentSeasonYear.value = seasons.value.find((season) => season.id == val)?.year
        if (currentSeasonYear.value >= transferSeasonYear.value) transferSeason.value = null
        if (currentTeam.value) {
          getCurrentPlayers(val, currentTeam.value)
        }
      })
      watch(transferTeam, (val) => {
        if (transferSeason.value) {
          getTransferPlayers(transferSeason.value, val)
        }
      })
      watch(transferSeason, (val) => {
        transferSeasonYear.value = seasons.value.find((season) => season.id == val)?.year
        if (transferTeam.value) {
          getTransferPlayers(val, transferTeam.value)
        }
      })
      const transferToright = (player, ItemIndex) => {
        if (player !== null && transferSeason.value !== null && transferTeam.value !== null) {
          let data = {
            player: player.id,
            currentSeason: currentSeason.value,
            currentTeam: currentTeam.value,
            transferSeason: transferSeason.value,
            transferTeam: transferTeam.value,
          }
          transferPlayer(data)
          transferPlayers.value.push(player)
          currentPlayers.value.splice(ItemIndex, 1)
        } else {
          return false
        }
      }
      const transferToleft = (player, ItemIndex) => {
        if (player !== null && currentSeason.value !== null && currentTeam.value !== null) {
          let data = {
            player: player.id,
            currentSeason: transferSeason.value,
            currentTeam: transferTeam.value,
            transferSeason: currentSeason.value,
            transferTeam: currentTeam.value,
          }
          transferPlayer(data)
          currentPlayers.value.push(player)
          transferPlayers.value.splice(ItemIndex, 1)
        } else {
          return false
        }
      }
      const transferPlayer = (data) => {
        loading.value = true
        http
          .post(`/player/${data.player}/transfer`, data)
          .then((res) => {
            loading.value = false
            root.toast(i18n.t("Transfer Success"), "RepeatIcon", "success")
          })
          .catch((error) => console.log(error))
      }
      return {
        seasons,
        teams,
        currentSeasonYear,
        currentSeason,
        currentTeam,
        currentPlayers,
        transferSeasonYear,
        transferSeason,
        transferTeam,
        transferPlayers,
        loading,
        transferToright,
        transferToleft,
      }
    },
  }
</script>
<style lang="scss">
  @import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
