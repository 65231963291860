<template>
  <component :is="staff === undefined ? 'div' : 'b-card'">
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="staff === undefined">
      <h4 class="alert-heading">
        {{ $t("Error Fetching Data") }}
      </h4>
      <div class="alert-body">
        {{ $t("No data found with this data id") }} {{ $t("Check") }}
        <b-link class="alert-link" :to="{ name: 'staff-list' }">
          {{ $t("Staff List") }}
        </b-link>
      </div>
    </b-alert>

    <div v-if="staff !== undefined && staff == null" class="m-n2 p-5">
      <b-overlay :show="staff !== undefined && staff == null" rounded="lg"></b-overlay>
    </div>

    <b-tabs v-if="staff" pills>
      <!-- Tab: Account -->
      <b-tab active>
        <template #title>
          <feather-icon icon="UserIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">{{ $t("Account") }}</span>
        </template>
        <user-edit-tab-account
          :users="users"
          :teams="teams"
          :seasons="seasons"
          :departments="departments"
          :jobs="jobs"
          :staff="staff"
          class="mt-2 pt-75"
        />
      </b-tab>

      <!-- Tab: Contact -->
      <b-tab>
        <template #title>
          <feather-icon icon="MailIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">{{ $t("Contact") }}</span>
        </template>
        <staff-edit-list-tab-contact class="mt-2 pt-75" :staffData="staff" />
      </b-tab>

      <!-- Tab: Duty History -->
      <b-tab>
        <template #title>
          <feather-icon icon="UserPlusIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">{{ $t("Job History") }}</span>
        </template>
        <staff-edit-list-tab-duty-history
          class="mt-2 pt-75"
          :staffData="staff"
          :teams="teams"
          :seasons="seasons"
          :departments="departments"
          :jobs="jobs"
        />
      </b-tab>

      <b-tab>
        <template #title>
          <feather-icon icon="UserIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">{{ $t("User Permissions") }}</span>
        </template>
        <staff-permissions class="mt-2 pt-75" :users="users" :staffData="staff" />
      </b-tab>
    </b-tabs>
  </component>
</template>

<script>
  import { BTab, BTabs, BCard, BAlert, BLink } from "bootstrap-vue"
  import { ref, onUnmounted, onMounted, computed } from "@vue/composition-api"
  import store from "@/store"
  import userStoreModule from "../staffStoreModule"
  import UserEditTabAccount from "./UserEditTabAccount.vue"
  import StaffEditListTabContact from "./StaffEditListTabContact.vue"
  import StaffEditListTabDutyHistory from "./StaffEditListTabDutyHistory.vue"
  import StaffPermissions from "./StaffPermissions.vue"
  import http from "@/libs/axios"
  import { useRouter } from "@core/utils/utils"
  import staffService from "@/services/staffService"
  export default {
    components: {
      BTab,
      BTabs,
      BCard,
      BAlert,
      BLink,
      UserEditTabAccount,

      StaffEditListTabContact,
      StaffEditListTabDutyHistory,
      StaffPermissions,
    },
    setup(props, { emit, root }) {
      const { router, route } = useRouter()
      const userData = ref(null)
      const staff = ref(null)

      const staffId = ref(Number(router.currentRoute.params.staffID))
      const staffSer = new staffService()
      const jobs = ref([])
      const users = ref([])
      const departments = ref([])
      const seasons = JSON.parse(store.getters["app/seasons"])

      const getStaff = () => {
        staffSer.get(staffId.value).then((res) => {
          staff.value = res.data
        })
      }

      const teams = computed(() => {
        const teams = store.getters["app/teams"]
        const localTeams = JSON.parse(localStorage.getItem("teams"))
        if (teams.length > 0) {
          return teams
        } else {
          return localTeams
        }
        //
      })

      const getJobs = () => {
        http
          .get(`/job`)
          .then((res) => {
            jobs.value = res.data
          })
          .catch((error) => console.log(error))
      }

      const getUsers = () => {
        http
          .get(`/user`)
          .then((res) => {
            users.value = res.data
          })
          .catch((error) => reject(error))
      }

      const getDepartments = () => {
        http
          .get(`/department`)
          .then((res) => {
            departments.value = res.data
          })
          .catch((error) => console.log(error))
      }

      onMounted(async () => {
        await getStaff()
        await getJobs()
        await getUsers()
        await getDepartments()
      })

      return {
        userData,
        staff,
        history,
        teams,
        seasons,
        jobs,
        users,
        departments,
      }
    },
  }
</script>

<style></style>
