<template>
  <div>
    <div class="table-match-history bg-white">
      <b-table
        show-empty
        :empty-text="$t('Data not found')"
        striped
        class="border matchHistory"
        responsive
        :fields="matchHistoryFields"
        :items="matchHistory"
        sticky-header="70vh"
      >
        <template #cell(match)="data">
          <div class="d-flex justify-content-start" @click="goToMatch(data.item)">
            <div class="d-flex justify-content-start">
              <span class="teamText mr-1"
                >{{ data.item.homeTeam.name + " " }}
                <span
                  v-if="data.item.homeTeam.club && data.item.homeTeam.club.name"
                  v-text="data.item.homeTeam.club.name"
                ></span>
              </span>
              <img
                v-if="data.item.homeTeam.club && data.item.homeTeam.club.logo"
                :src="data.item.homeTeam.club.logo"
                height="24"
                alt
              />
            </div>
            <div>
              <span
                class="badge mx-1 px-1"
                v-if="data.item.homeScore != undefined"
                :class="{
                  'badge-dark text-light': true,
                  'badge-success': data.value.status == 'win',
                  'badge-danger': data.value.status == 'lose',
                  'badge-dark': data.value.status == 'draw',
                  'badge-primary': data.value.status == 'not started',
                }"
                >{{ data.item.homeScore }} - {{ data.item.awayScore }}</span
              >
              <!-- clock icon -->
              <span class="icon-time mx-1 px-1" v-if="data.item.awayScore == undefined">
                <feather-icon icon="ClockIcon" size="24" scale="1" />
              </span>
            </div>
            <!-- away team -->
            <div class="d-flex justify-content-start">
              <img
                v-if="data.item.awayTeam.club && data.item.awayTeam.club.logo"
                :src="data.item.awayTeam.club.logo"
                height="24"
                alt
              />
              <span class="teamText ml-1"
                >{{ data.item.awayTeam.name + " " }}
                <span
                  v-if="data.item.awayTeam.club && data.item.awayTeam.club.name"
                  v-text="data.item.awayTeam.club.name"
                ></span>
              </span>
            </div>
          </div>
        </template>
        <template #cell(sure)="data">
          <span @click="goToMatch(data.item)">
            {{ data.value }}
            <feather-icon icon="ClockIcon" size="24" scale="1" />
          </span>
        </template>
        <template #cell(league)="data">
          <div class="d-flex justify-content-start">
            <div class="h5" style="line-height: 1.5">
              <b>{{ data.value.name }}</b>
            </div>
          </div>
        </template>
        <template #cell(date)="data">
          <div class="h6" @click="goToMatch(data.item)">
            <b>
              {{ data.value | nativeDate }}
            </b>
            <br />
            {{ data.value | nativeTime }}
          </div>
        </template>
        <template #cell(location)="data">
          <div class="stad" @click="goToMatch(data.item)">
            <span class="small">{{ data.value.name }}</span>
          </div>
        </template>
        <template #cell(Datetime)="data">
          <div class="d-flex justify-content-start" @click="goToMatch(data.item)">
            <div class="small ml-1" v-html="getBr(data.value) + ' 14:00'"></div>
          </div>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
  import i18n from "@/libs/i18n"
  import { computed } from "@vue/composition-api"

  export default {
    props: {
      matchHistory: {
        default: {},
      },
    },
    components: {},
    setup() {
      const matchHistoryFields = computed({
        get: () => [
          {
            key: "league",
            label: i18n.t("League"),
            sortable: false,
            thStyle: { "min-width": "11rem" },
          },
          {
            key: "date",
            label: i18n.t("Date"),
            sortable: true,
            thStyle: { "min-width": "13rem" },
          },
          {
            key: "Match",
            label: i18n.t("Match"),
            sortable: false,
            thStyle: { "min-width": "9rem" },
          },
          {
            key: "location",
            label: i18n.t("Location"),
            sortable: false,
            thStyle: { "min-width": "15rem" },
          },
        ],
      })

      return {
        matchHistoryFields,
      }
    },
    beforeMount() {},
    methods: {
      getDate(date) {
        date = new Date(date).toLocaleDateString()
        return date
      },
      getTime(date) {
        date = new Date(date).toLocaleTimeString()
        return date
      },
      getBr(el) {
        let dayName = el.split(" ").pop()
        el = el.split(" ")
        delete el[el.length - 1]
        el = el.join(" ")
        return el + "<br>" + dayName + ""
      },
      goToMatch(comp) {
        this.$router.push({
          name: "competition-edit",
          params: {
            seasonId: comp.season.id,
            id: this.routerParams.id ?? this.getTeamId(comp),
            competitionId: comp.id,
          },
        })
      },
      getTeamId(comp) {
        let teams = JSON.parse(localStorage.getItem("teams")).map((d) => d.id) || []
        return teams.includes(comp.awayTeam.id) ? comp.awayTeam.id : comp.homeTeam.id
      },
    },

    beforeCreate() {},
  }
</script>

<style lang="scss">
  .table-match-history {
    tr {
      cursor: pointer;
      transition: all 300ms;
      outline: 0;

      th,
      td {
        white-space: nowrap;
        width: 1%;
      }

      &:hover {
        background: #eee;
      }
    }

    .stad,
    .teamText {
      white-space: nowrap;
      width: 6rem;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .teamText {
      width: 8rem;
    }
  }
</style>
