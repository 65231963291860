<template>
  <!-- TODO : TRANSLATE -->
  <div v-if="competitionForm.homeTeam.select">
    <div class="card-header p-0 m-0 mb-2">
      <div>
        <b-card-title>
          <feather-icon icon="CopyIcon" size="24" />
          {{ $t("Competition") }}
        </b-card-title>
      </div>
      <div>
        <b-link
          class="btn btn-primary"
          :to="{
            name: 'team-scout-competition-show',
            params: { id: routerParams.id, competitionId: routerParams.competitionId },
          }"
        >
          <i class="code-toggler feather icon-eye cursor-pointer"></i>
        </b-link>
        <span
          class="btn btn-primary ml-1"
          @click="
            showSection.addMatchBasicForm = true
            showSection.Formation = false
          "
        >
          <i class="code-toggler feather icon-edit cursor-pointer"></i>
        </span>
      </div>
    </div>
    <div class="info-bar-match mt-n1">
      <div class="row justify-content-md-center">
        <div class="col">
          <div class="_card card-profile">
            <div class="d-flex justify-content-end teamInfo">
              <div class="mr-2 text-right">
                <h3>{{ competitionForm.homeTeam.select.name }}</h3>
                <span class="badge profile-badge badge-light text-dark">
                  {{ $t("Home Team") }}
                </span>
              </div>
              <b-avatar
                :src="competitionForm.homeTeam.select.club ? competitionForm.homeTeam.select.club.logo : null"
                :text="avatarText(competitionForm.homeTeam.select.name)"
                size="90px"
                rounded-lg
              />
            </div>
          </div>
        </div>
        <div class="col-md-auto score">
          <div class="card-profile mt-n2" style="height: 12rem; overflow: hidden">
            <div class="profile-image p-0">
              <div class="d-flex justify-content-center h3">
                <span class="h3 text-dark bg-light p-1">{{
                  getScore(competitionForm.competition.homeScore) +
                  "-" +
                  getScore(competitionForm.competition.awayScore)
                }}</span>
              </div>
            </div>
            <span class="d-block text-dark h3" style="width: 28rem; height: 1.8rem; overflow: hidden">{{
              competitionForm.location.select.name.substr(0, 28) + "..."
            }}</span>
            <span class="d-block text-dark h4">{{ competitionForm.league.select.name }}</span>
            <span class="text-primary" style="font-weight: 900">
              {{ competitionForm.date | nativeDate }}
              <br />
              {{ competitionForm.date | nativeTime }}
            </span>
          </div>
        </div>
        <div class="col">
          <div class="card-profile">
            <div class="d-flex justify-content-start teamInfo">
              <div class>
                <b-avatar
                  :src="competitionForm.awayTeam.select.club ? competitionForm.awayTeam.select.club.logo : null"
                  :text="avatarText(competitionForm.awayTeam.select.name)"
                  size="90px"
                  rounded-lg
                />
              </div>
              <div class="ml-2 text-left">
                <h3>{{ competitionForm.awayTeam.select.name }}</h3>
                <span class="badge badge-light text-dark">{{ $t("Away Team") }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { avatarText } from "@core/utils/filter"
  export default {
    props: {
      competitionForm: null,
      competition: null,
      showSection: null,
    },
    data() {
      return {
        editCompetition: true,
      }
    },
    methods: {
      getScore(score) {
        if (score && Number(score) != NaN) {
          return Number(score)
        }
        return 0
      },
    },
    setup() {
      return {
        avatarText,
      }
    },
  }
</script>
<style lang="scss" scoped>
  ._col.score {
    margin-top: 0rem;
  }

  .badge-secondary {
    background: unset;
  }

  .info-bar-match {
    ._card-body {
      padding: 1.5rem 0 0 0;
    }

    .card {
      margin-bottom: 0.5rem;
    }

    .teamInfo {
      .badge {
        margin: 0;
      }
    }

    ._col:first-child {
      .d-flex > div:first-child {
        margin-right: 1rem;
        text-align: right;
      }
    }

    ._col:last-child {
      .d-flex > div:last-child {
        margin-left: 1rem;
        text-align: left;
      }
    }
  }
</style>
