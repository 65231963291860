<template>
  <b-nav-item>
    <b-dropdown
      id="dropdown-grouped-season"
      variant="primary"
      primary
      class="dropdown-language"
      right
      :text="findSeason(season) ? findSeason(season).name : ''"
      size="sm"
    >
      <b-dropdown-item @click="selectSeason(item)" v-for="item in seasons" :key="item.id">
        <span class="ml-50">{{ item.name }} </span>
      </b-dropdown-item>
    </b-dropdown>
  </b-nav-item>
</template>
<script>
  import store from "@/store"
  import { computed, ref, onMounted } from "@vue/composition-api"
  import { BNavItemDropdown, BDropdownItem, BImg } from "bootstrap-vue"
  import axios from "@axios"
  import { useRouter } from "@core/utils/utils"
  export default {
    components: {
      BNavItemDropdown,
      BDropdownItem,
      BImg,
    },
    setup() {
      const season = store.getters["app/season"]
      const seasons = JSON.parse(store.getters["app/seasons"])
      const selectedSeason = ref(null)
      const { router, route } = useRouter()
      const selectSeason = (item) => {
        if (item) {
          store.dispatch("app/setSeason", item.id).then((response) => {
            selectedSeason.value = item.id
            localStorage.setItem("season", item.id)
            let routerParams = router.currentRoute.params
            let currentRoute = router.currentRoute

            // sezon ve takım bilgisi dışında bir bilgiye sahip ise anasayfaya yönlendir
            let paramKeys = ["seasonId", "sId", "id"]
            if (Object.keys(routerParams).every((r) => paramKeys.includes(r))) {
              let paramsSeason = routerParams.seasonId
                ? (routerParams.seasonId = item.id)
                : routerParams.sId
                ? (routerParams.sId = item.id)
                : null

              if (paramsSeason !== null) {
                console.log("push")
                router.push({
                  name: currentRoute.name,
                  params: {
                    ...routerParams,
                  },
                })
              }
              console.log("go")
              // refres page
              router.go()
            } else {
              console.log("href")
              // return home page
              location.href = "/"
            }
          })
        }
      }
      const findSeason = (id) => {
        return Object.values(seasons).find((item) => item.id == id)
      }
      onMounted(() => {})
      return {
        seasons,
        season,
        selectSeason,
        selectedSeason,
        findSeason,
      }
    },
  }
</script>
<style></style>
