<template>
  <div>
    <staff-contact-new
      :staff-data="staffData"
      :is-add-cont-active.sync="isAddContActive"
      :submit-contact="submitContact"
      :update-contact="updateContact"
      :staff-contact="contact"
    />
    <b-row>
      <b-col md="4" v-for="pd in staffData.contacts" :key="pd.id">
        <b-card style="border: 1px solid #ddd">
          <div class="card-header p-0 m-0 mb-2">
            <div>
              <b-card-title>
                <feather-icon icon="FileIcon" size="24" />
                {{ pd.name }}
              </b-card-title>
            </div>
            <div>
              <span class="btn btn-light" @click="goSidebarContact(pd)">
                <feather-icon icon="EditIcon"></feather-icon>
              </span>
              <span class="btn btn-light ml-1" @click="goDeleteContact(pd.id)">
                <feather-icon icon="Trash2Icon"></feather-icon>
              </span>
            </div>
          </div>
          <div class="mb-2">
            <div>
              <span class="badge badge-light text-dark" v-text="pd.contactLevel"></span>
              <span class="badge badge-light text-dark ml-1">
                <!--new Date(pd.created_at).toLocaleDateString() --></span
              >
            </div>
            <div class="mt-2">
              <span class="h4 d-block mb-2">
                <feather-icon icon="PhoneIcon"></feather-icon>
                {{ pd.phone }}
              </span>
              <span class="h4 d-block">
                <feather-icon icon="MapIcon"></feather-icon>
                {{ pd.address }}
              </span>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>

    <b-button
      variant="primary"
      v-b-toggle.sidebar-add-cont
      type="button"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      @click="contact = {}"
      >{{ $t("Add New") }}</b-button
    >
  </div>
</template>

<script>
  import flatPickr from "vue-flatpickr-component"
  import vSelect from "vue-select"
  import { ref } from "@vue/composition-api"
  import Cleave from "vue-cleave-component"
  // eslint-disable-next-line import/no-extraneous-dependencies
  import "cleave.js/dist/addons/cleave-phone.us"
  import StaffContactNew from "./StaffContactNew.vue"
  import staffContactService from "@/services/staffContactService"

  export default {
    components: {
      vSelect,
      flatPickr,
      Cleave,
      StaffContactNew,
    },
    props: {
      staffData: {
        default: null,
      },
      teams: {
        default: null,
      },
      seasons: {
        default: null,
      },
      departments: {
        default: null,
      },
      jobs: {
        default: null,
      },
    },
    data() {
      return {
        contact: null,
        isAddContActive: false,
      }
    },
    methods: {
      goSidebarContact(staffContactData) {
        this.contact = { id: null, name: null, address: null, phone: null }
        this.contact.id = staffContactData.id
        this.contact.name = staffContactData.name
        this.contact.address = staffContactData.address
        this.contact.phone = staffContactData.phone
        this.isAddContActive = true
      },
      goDeleteContact(contId) {
        this.$bvModal
          .msgBoxConfirm(this.$t("Please confirm that you want to delete this."), {
            title: this.$t("Please Confirm"),
            size: "sm",
            okVariant: "primary",
            okTitle: this.$t("Yes"),
            cancelTitle: this.$t("No"),
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          })
          .then((value) => {
            if (value) {
              this.deleteContact(contId)
            }
          })
      },
    },
    beforeMount() {},
    setup(props, { root }) {
      const contactSer = new staffContactService()

      const submitContact = (staffContactData) => {
        contactSer
          .event({ data: staffContactData })
          .then((res) => {
            //todo: table refresh
            location.reload()
          })
          .catch((error) => {
            console.log("error", error)
          })
      }

      const updateContact = (staffContactData) => {
        contactSer
          .event({ data: staffContactData, dataId: staffContactData.id })
          .then((res) => {
            //todo: table refresh
            location.reload()
          })
          .catch((error) => {
            console.log("error", error)
          })
      }

      const deleteContact = (contactId) => {
        contactSer
          .event({ dataId: contactId })
          .then((res) => {
            //todo: table refresh
            location.reload()
          })
          .catch((error) => {
            console.log("error", error)
          })
      }

      const languageOptions = ["Türkçe", "English", "Spanish", "French", "Russian", "German"]

      return {
        languageOptions,
        submitContact,
        updateContact,
        deleteContact,
      }
    },
  }
</script>

<style lang="scss">
  @import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
