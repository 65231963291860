<template>
  <b-overlay variant="white" :show="showLoading" spinner-variant="primary" blur="0" opacity=".75" rounded="sm">
    <div class="d-flex flex-column justify-content-center">
      <b-modal
        @ok="submit"
        id="modal-scout-palyer"
        ref="isScoutPlayerModalOpen"
        centered
        :title="$t('Duplicate Scout Player Name')"
      >
        <div class="d-flex">
          <div>
            <b-avatar
              size="82"
              :text="avatarText(modalPlayer.name)"
              :src="modalPlayer.avatar"
              :variant="`light-primary`"
              :to="{ name: 'scout-player-view', params: { playerId: modalPlayer.id } }"
              target="_blank"
              rounded
            />
          </div>
          <div class="ml-1">
            <b-link target="_blank" :to="{ name: 'scout-player-view', params: { playerId: modalPlayer.id } }">
              <span class="mt-0 d-block h4">{{ modalPlayer.name }}</span>
              <div class="mb-1 d-flex">
                <span class="badge badge-primary mr-1 col">{{
                  modalPlayer.position ? $t(modalPlayer.position.name) : " - "
                }}</span>
                <span class="badge badge-dark col"
                  >{{
                    modalPlayer.foot === 1
                      ? $t("Right Foot")
                      : modalPlayer.foot === 0
                      ? $t("Left Foot")
                      : $t("Both Feet")
                  }}
                </span>
              </div>
              <span class="badge badge-info col" v-if="modalPlayer.team"
                >{{ modalPlayer.team ? modalPlayer.team.club.name : "" }} /
                {{ modalPlayer.team ? modalPlayer.team.name : "" }}
              </span>
            </b-link>
          </div>
        </div>

        <p class="mt-1">{{ $t("Already Scout Player With Name") }}</p>

        <template #modal-footer="{ ok }">
          <div class="d-flex">
            <b-button size="sm" variant="success" class="mr-1" @click="ok()"> {{ $t("Add Anyway") }} </b-button>
            <b-button size="sm" variant="danger" @click="$bvModal.hide('modal-scout-palyer')">
              {{ $t("Cancel") }}
            </b-button>
          </div>
        </template>
      </b-modal>
      <!-- BODY -->
      <b-card>
        <h4>{{ $t("Add New Player") }}</h4>
      </b-card>
      <div class="">
        <div>
          <b-card>
            {{ form.selectDate }}

            <validation-observer #default="{ handleSubmit }" ref="formTrigger" :key="1">
              <!-- Form -->
              <b-form v-if="form" class="p-2" @submit.prevent="handleSubmit(checkName)">
                <b-row>
                  <b-col lg="6">
                    <validation-provider #default="{ errors }" name="name" rules="required">
                      <b-form-group :label="$t('Name')" label-for="firstName">
                        <b-input
                          id="name"
                          :placeholder="$t('Please enter player name')"
                          v-model="form.name"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <b-col lg="6">
                    <validation-provider #default="{ errors }" name="recommender">
                      <b-form-group :label="$t('Recommended by')" label-for="recommender">
                        <b-input
                          id="recommender"
                          v-model="form.recommender"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <b-col lg="6">
                    <validation-provider #default="{ errors }" name="position" rules="required">
                      <b-form-group :label="$t('Position')" label-for="position">
                        <v-select
                          v-model="form.position"
                          :getOptionLabel="(option) => option.name"
                          :options="positions"
                          :reduce="(val) => val.id"
                          :clearable="false"
                          input-id="position"
                        />

                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <b-col lg="6">
                    <validation-provider #default="{ errors }" name="team">
                      <b-form-group :label="$t('Team')" label-for="team">
                        <v-select
                          v-model="form.team"
                          :getOptionLabel="
                            (option) => (option.club ? option.club.name + ' - ' + option.name : option.name)
                          "
                          :options="teams"
                          :reduce="(val) => val.id"
                          input-id="team"
                        >
                          <template v-slot:option="option">
                            <span class="text-nowrap"
                              >{{ option.club ? option.club.name : "" }} / {{ option.name }}</span
                            >
                          </template>
                        </v-select>

                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <b-col lg="6">
                    <validation-provider #default="{ errors }" name="foot" rules="required">
                      <b-form-group :label="$t('Strong Foot')" label-for="foot">
                        <v-select
                          v-model="form.foot"
                          :getOptionLabel="(option) => option.label"
                          :options="footOptions"
                          :reduce="(val) => val.value"
                          :clearable="false"
                          input-id="foot"
                        />

                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <b-col lg="6">
                    <validation-provider #default="{ errors }" name="birthdate" rules="required">
                      <b-form-group :label="$t('Birth Date')" label-for="birthdate">
                        <selectable-date v-model="form.birthdate"></selectable-date>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <b-col lg="6">
                    <validation-provider #default="{ errors }" name="height">
                      <b-form-group :label="$t('Estimated Height')" label-for="height">
                        <b-input
                          type="number"
                          id="height"
                          :placeholder="$t('Please enter height')"
                          v-model.number="form.height"
                          :state="errors.length > 0 ? false : null"
                        />

                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <b-col lg="6">
                    <validation-provider #default="{ errors }" name="weight">
                      <b-form-group :label="$t('Estimated Weight')" label-for="weight">
                        <b-input
                          type="number"
                          id="weight"
                          :placeholder="$t('Please enter weight')"
                          v-model.number="form.weight"
                          :state="errors.length > 0 ? false : null"
                        />

                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <b-col lg="6">
                    <validation-provider #default="{ errors }" name="mobile">
                      <b-form-group :label="$t('Mobile')" label-for="mobile">
                        <b-input v-model="form.phone" id="pPhone" placeholder="555 444 33 22"></b-input>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <b-col lg="6">
                    <validation-provider #default="{ errors }" name="email">
                      <b-form-group :label="$t('Email')" label-for="email">
                        <b-input
                          id="lastName"
                          type="email"
                          :placeholder="$t('Please enter player email')"
                          v-model="form.email"
                          :state="errors.length > 0 ? false : null"
                        />

                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>

                <!-- Form Actions -->
                <div class="d-flex mt-2 float-right">
                  <b-button variant="primary" type="submit">{{ $t("Add") }}</b-button>
                </div>
              </b-form>
            </validation-observer>
          </b-card>
        </div>
      </div>
    </div>
  </b-overlay>
</template>
<script>
  import { ValidationProvider, ValidationObserver } from "vee-validate"
  import { ref, onMounted, computed } from "@vue/composition-api"
  import { required } from "@validations"
  import formValidation from "@core/comp-functions/forms/form-validation"
  import Ripple from "vue-ripple-directive"
  import flatPickr from "vue-flatpickr-component"
  import vSelect from "vue-select"
  import seasonService from "@/services/seasonService"
  import teamService from "@/services/teamService"
  import staffService from "@/services/staffService"
  import http from "@/libs/axios"
  import store from "@/store"
  import { useRouter } from "@core/utils/utils"
  import i18n from "@/libs/i18n"
  import { Turkish } from "flatpickr/dist/l10n/tr.js"
  import { French } from "flatpickr/dist/l10n/fr.js"
  import Cleave from "cleave.js"
  import SelectableDate from "@/@core/components/global-components/SelectableDate"
  import { BAvatar } from "bootstrap-vue"
  import { avatarText } from "@core/utils/filter"
  export default {
    components: {
      SelectableDate,
      vSelect,
      ValidationProvider,
      ValidationObserver,
      BAvatar,
    },
    mounted() {
      new Cleave("#pPhone", {
        numericOnly: true,
        delimiter: " ",
        blocks: [3, 3, 2, 2],
      })
      // new Cleave("#bDateInput", {
      //   date: true,
      //   datePattern: ["d", "m", "Y"],
      //   delimiter: "/",
      // });
    },
    setup(props, { emit, root }) {
      const { getValidationState } = formValidation()
      const { router, route } = useRouter()
      const passwordFieldType = ref("password")
      const formTrigger = ref(null)
      const seasons = ref([])
      const teams = ref([])
      const positions = ref([])
      const player = ref({})
      const seasonId = ref(Number(router.currentRoute.params.seasonId))
      const user = JSON.parse(localStorage.getItem("userData"))
      const isScoutPlayerModalOpen = ref(false)
      const modalPlayer = ref({})
      const showLoading = ref(false)

      const form = ref({
        firstName: null,
        lastName: null,
        nation: null,
        nationId: null,
        birthDate: null,
        email: null,
        height: null,
        weight: null,
        phoneNumber: null,
        status: 1,
        season: null,
        team: null,
        department: null,
        job: null,
        jobEndDate: "01-01-2025",
        season: Number(localStorage.getItem("season")),
      })

      const seasonSer = new seasonService()
      const staffSer = new staffService()

      onMounted(async () => {
        await getSeasons()
        await getTeams()
        await getPositions()
      })

      const getSeasons = () => {
        seasonSer
          .get()
          .then((response) => {
            seasons.value = response.data
          })
          .catch((error) => {
            console.log(error)
            root.toast(i18n.t("Error fetching season list"), "AlertTriangleIcon", "danger")
          })
      }

      const getTeams = () => {
        http
          .get(`/team`)
          .then((res) => {
            teams.value = res.data.data
          })
          .catch((error) => console.log(error))
      }

      const getPositions = () => {
        http
          .get(`/position`)
          .then((res) => {
            positions.value = res.data
          })
          .catch((error) => console.log(error))
      }

      const togglePassword = () => {
        passwordFieldType.value = passwordFieldType.value === "password" ? "text" : "password"
      }

      const passwordToggleIcon = computed(() => {
        return passwordFieldType.value === "password" ? "EyeIcon" : "EyeOffIcon"
      })
      const checkName = async () => {
        let success = await formTrigger.value.validate()
        if (success) {
          showLoading.value = true
          modalPlayer.value = (await http.get(`/scout/player/check-duplicate-name?name=${form.value.name}`)).data
          if (modalPlayer.value) {
            showLoading.value = false
            isScoutPlayerModalOpen.value.show()
          } else {
            submit()
          }
        }
      }

      const submit = async () => {
        formTrigger.value.validate().then((success) => {
          if (success) {
            showLoading.value = true
            http
              .post(`/scout/player`, form.value)
              .then((res) => {
                showLoading.value = false
                //player.value = res.data;
                root.toast(i18n.t("Successfully Created"), "PlusIcon", "success")
                router.push({
                  name: "scout-player-view",
                  params: {
                    seasonId: seasonId.value,
                    playerId: res.data.id,
                  },
                })
              })
              .catch((error) => {
                console.log(error)
                showLoading.value = false
              })
          }
        })
      }

      return {
        getValidationState,
        togglePassword,
        passwordFieldType,
        passwordToggleIcon,
        seasons,
        teams,
        formTrigger,
        form,
        submit,
        positions,
        player,
        user,
        Turkish,
        French,
        isScoutPlayerModalOpen,
        avatarText,
        modalPlayer,
        checkName,
        showLoading,
      }
    },
  }
</script>

<style lang="scss">
  @import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
