var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"variant":"white","show":_vm.showLoading,"spinner-variant":"primary","blur":"0","opacity":".75","rounded":"sm"}},[_c('div',{staticClass:"d-flex flex-column justify-content-center"},[_c('b-modal',{ref:"isScoutPlayerModalOpen",attrs:{"id":"modal-scout-palyer","centered":"","title":_vm.$t('Duplicate Scout Player Name')},on:{"ok":_vm.submit},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
var ok = ref.ok;
return [_c('div',{staticClass:"d-flex"},[_c('b-button',{staticClass:"mr-1",attrs:{"size":"sm","variant":"success"},on:{"click":function($event){return ok()}}},[_vm._v(" "+_vm._s(_vm.$t("Add Anyway"))+" ")]),_c('b-button',{attrs:{"size":"sm","variant":"danger"},on:{"click":function($event){return _vm.$bvModal.hide('modal-scout-palyer')}}},[_vm._v(" "+_vm._s(_vm.$t("Cancel"))+" ")])],1)]}}])},[_c('div',{staticClass:"d-flex"},[_c('div',[_c('b-avatar',{attrs:{"size":"82","text":_vm.avatarText(_vm.modalPlayer.name),"src":_vm.modalPlayer.avatar,"variant":"light-primary","to":{ name: 'scout-player-view', params: { playerId: _vm.modalPlayer.id } },"target":"_blank","rounded":""}})],1),_c('div',{staticClass:"ml-1"},[_c('b-link',{attrs:{"target":"_blank","to":{ name: 'scout-player-view', params: { playerId: _vm.modalPlayer.id } }}},[_c('span',{staticClass:"mt-0 d-block h4"},[_vm._v(_vm._s(_vm.modalPlayer.name))]),_c('div',{staticClass:"mb-1 d-flex"},[_c('span',{staticClass:"badge badge-primary mr-1 col"},[_vm._v(_vm._s(_vm.modalPlayer.position ? _vm.$t(_vm.modalPlayer.position.name) : " - "))]),_c('span',{staticClass:"badge badge-dark col"},[_vm._v(_vm._s(_vm.modalPlayer.foot === 1 ? _vm.$t("Right Foot") : _vm.modalPlayer.foot === 0 ? _vm.$t("Left Foot") : _vm.$t("Both Feet"))+" ")])]),(_vm.modalPlayer.team)?_c('span',{staticClass:"badge badge-info col"},[_vm._v(_vm._s(_vm.modalPlayer.team ? _vm.modalPlayer.team.club.name : "")+" / "+_vm._s(_vm.modalPlayer.team ? _vm.modalPlayer.team.name : "")+" ")]):_vm._e()])],1)]),_c('p',{staticClass:"mt-1"},[_vm._v(_vm._s(_vm.$t("Already Scout Player With Name")))])]),_c('b-card',[_c('h4',[_vm._v(_vm._s(_vm.$t("Add New Player")))])]),_c('div',{},[_c('div',[_c('b-card',[_vm._v(" "+_vm._s(_vm.form.selectDate)+" "),_c('validation-observer',{key:1,ref:"formTrigger",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [(_vm.form)?_c('b-form',{staticClass:"p-2",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.checkName)}}},[_c('b-row',[_c('b-col',{attrs:{"lg":"6"}},[_c('validation-provider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Name'),"label-for":"firstName"}},[_c('b-input',{attrs:{"id":"name","placeholder":_vm.$t('Please enter player name'),"state":errors.length > 0 ? false : null},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"lg":"6"}},[_c('validation-provider',{attrs:{"name":"recommender"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Recommended by'),"label-for":"recommender"}},[_c('b-input',{attrs:{"id":"recommender","state":errors.length > 0 ? false : null},model:{value:(_vm.form.recommender),callback:function ($$v) {_vm.$set(_vm.form, "recommender", $$v)},expression:"form.recommender"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"lg":"6"}},[_c('validation-provider',{attrs:{"name":"position","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Position'),"label-for":"position"}},[_c('v-select',{attrs:{"getOptionLabel":function (option) { return option.name; },"options":_vm.positions,"reduce":function (val) { return val.id; },"clearable":false,"input-id":"position"},model:{value:(_vm.form.position),callback:function ($$v) {_vm.$set(_vm.form, "position", $$v)},expression:"form.position"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"lg":"6"}},[_c('validation-provider',{attrs:{"name":"team"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Team'),"label-for":"team"}},[_c('v-select',{attrs:{"getOptionLabel":function (option) { return (option.club ? option.club.name + ' - ' + option.name : option.name); },"options":_vm.teams,"reduce":function (val) { return val.id; },"input-id":"team"},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(option.club ? option.club.name : "")+" / "+_vm._s(option.name))])]}}],null,true),model:{value:(_vm.form.team),callback:function ($$v) {_vm.$set(_vm.form, "team", $$v)},expression:"form.team"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"lg":"6"}},[_c('validation-provider',{attrs:{"name":"foot","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Strong Foot'),"label-for":"foot"}},[_c('v-select',{attrs:{"getOptionLabel":function (option) { return option.label; },"options":_vm.footOptions,"reduce":function (val) { return val.value; },"clearable":false,"input-id":"foot"},model:{value:(_vm.form.foot),callback:function ($$v) {_vm.$set(_vm.form, "foot", $$v)},expression:"form.foot"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"lg":"6"}},[_c('validation-provider',{attrs:{"name":"birthdate","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Birth Date'),"label-for":"birthdate"}},[_c('selectable-date',{model:{value:(_vm.form.birthdate),callback:function ($$v) {_vm.$set(_vm.form, "birthdate", $$v)},expression:"form.birthdate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"lg":"6"}},[_c('validation-provider',{attrs:{"name":"height"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Estimated Height'),"label-for":"height"}},[_c('b-input',{attrs:{"type":"number","id":"height","placeholder":_vm.$t('Please enter height'),"state":errors.length > 0 ? false : null},model:{value:(_vm.form.height),callback:function ($$v) {_vm.$set(_vm.form, "height", _vm._n($$v))},expression:"form.height"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"lg":"6"}},[_c('validation-provider',{attrs:{"name":"weight"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Estimated Weight'),"label-for":"weight"}},[_c('b-input',{attrs:{"type":"number","id":"weight","placeholder":_vm.$t('Please enter weight'),"state":errors.length > 0 ? false : null},model:{value:(_vm.form.weight),callback:function ($$v) {_vm.$set(_vm.form, "weight", _vm._n($$v))},expression:"form.weight"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"lg":"6"}},[_c('validation-provider',{attrs:{"name":"mobile"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Mobile'),"label-for":"mobile"}},[_c('b-input',{attrs:{"id":"pPhone","placeholder":"555 444 33 22"},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"lg":"6"}},[_c('validation-provider',{attrs:{"name":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Email'),"label-for":"email"}},[_c('b-input',{attrs:{"id":"lastName","type":"email","placeholder":_vm.$t('Please enter player email'),"state":errors.length > 0 ? false : null},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)],1),_c('div',{staticClass:"d-flex mt-2 float-right"},[_c('b-button',{attrs:{"variant":"primary","type":"submit"}},[_vm._v(_vm._s(_vm.$t("Add")))])],1)],1):_vm._e()]}}])})],1)],1)])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }