<template>
  <section id="dashboard-dashboard">
    <b-row class="match-height">
      <b-col xl="12" md="12">
        <calendar-app></calendar-app>
      </b-col>
    </b-row>
  </section>
</template>

<script>
  import { BRow, BCol, BCard, BCardText, BLink, BButton, BImg } from "bootstrap-vue"
  import ChartTraining from "./ChartTraining.vue"
  import CalendarApp from "@/views/apps/calendar-app/Calendar.vue"
  import axios from "axios"

  export default {
    components: {
      BRow,
      BCol,
      BCard,
      BCardText,
      BLink,
      BImg,
      BButton,

      ChartTraining,
      CalendarApp,
    },
    data() {
      return {
        matchHistory: false,
        data: {
          revenue: {
            years: ["2020", "2019", "2018"],
            price: "25,852",
            budget: "56,800",
            revenueReport: {
              series: [
                {
                  name: "Katılım",
                  data: [95, 177, 284, 256, 105, 63, 168, 218, 72],
                },
                {
                  name: "Devamsız",
                  data: [-145, -80, -60, -180, -100, -60, -85, -75, -100],
                },
              ],
            },
            budgetChart: {
              series: [
                {
                  data: [61, 48, 69, 52, 60, 40, 79, 60, 59, 43, 62],
                },
                {
                  data: [20, 10, 30, 15, 23, 0, 25, 15, 20, 5, 27],
                },
              ],
            },
          },
        },
      }
    },
    mounted() {
      axios
        .get("https://602b8a2fef26b40017f145a3.mockapi.io/api/matches")
        .then((d) => {
          this.matchHistory = d.data
        })
        .catch((d) => {
          this.matchHistory = false
        })
    },
    methods: {},
  }
</script>

<style lang="scss"></style>
