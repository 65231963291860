/**
 * Dependencies
 */
import { reactive, ref, onBeforeMount, onUnmounted, onMounted, watch } from "@vue/composition-api"
import formValidation from "@core/comp-functions/forms/form-validation"
import axios from "@/libs/axios"
import router from "@/router"
import i18n from "@/libs/i18n"

/**
 * Static
 */
import { competitionStatic, competitionFormStatic } from "./competition"

/**
 * Services
 */
import { teamService, competitionService } from "@/services"
import toastService from "@/services/toastService"

export default function useAddMatch() {
  const showSection = ref({ infoBarLoading: true, addMatchBasicForm: true, statComponent: false })
  const helper = reactive({})
  const viewTrigger = ref(0)
  const competitionForm = ref(new competitionFormStatic())
  const competition = ref(new competitionStatic())
  const service = new competitionService()
  const teamSer = new teamService()
  const routerTeamId = Number(router.currentRoute.params.id) || null
  const routerCompetitionId = Number(router.currentRoute.params.competitionId) || null
  const routerSeasonId = Number(router.currentRoute.params.seasonId) || null
  const dateString = (date = null, post = false) => {
    // for Safari
    date = post ? date.replace(/-/g, "/") : date
    return new Date(date)
      .toLocaleString("en", {
        timeZone: "Europe/Istanbul",
        hour12: false,
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      })
      .replace(",", "")
  }
  const loadingMatchFormation = ref(0)

  onBeforeMount(async () => {
    try {
      const competitionId = routerCompetitionId

      if (!competitionId) {
        const teams = await axios.get("/team?row=999")
        competitionForm.value.teams.option = teams.data.data
        const location = await axios.get("/location")
        competitionForm.value.location.option = location.data
        const league = await axios.get("/league?officialLeagueTeam=" + routerTeamId)
        competitionForm.value.league.option = league.data

        competitionForm.value.homeTeam.select = Object.values(competitionForm.value.teams.option).find(
          (teamItem) => teamItem.id == Number(routerTeamId),
        )
      }

      if (competitionId) {
        showSection.value.addMatchBasicForm = false
        showSection.value.Formation = true
        showSection.value.infoBarLoading = true

        const getCompetition = await service.find(competitionId)
        showSection.value.infoBarLoading = false
        competition.value.competitionStats = getCompetition.competitionStats
        competition.value.competitionFormations = getCompetition.competitionFormations
        competition.value.competitionEvents = getCompetition.competitionEvents
        competitionForm.value.homeTeam.select = getCompetition.homeTeam
        competitionForm.value.awayTeam.select = getCompetition.awayTeam
        competitionForm.value.location.select = getCompetition.location
        competitionForm.value.winId = getCompetition.winId
        competitionForm.value.league.select = getCompetition.league
        competitionForm.value.date = getCompetition.date
        competitionForm.value.competition.homeScore = getCompetition.homeScore
        competitionForm.value.competition.awayScore = getCompetition.awayScore
        competitionForm.value.competition.time.select = getCompetition.duration
        competitionForm.value.competition.firstHalfOvertime = getCompetition.firstHalfOvertime
        competitionForm.value.competition.secondHalfOvertime = getCompetition.secondHalfOvertime
        competitionForm.value.competition.date.time = new Date(getCompetition.date).toLocaleTimeString("en", {
          timeZone: "Europe/Istanbul",
          hour12: false,
        })
        // DESC: new Date("08/26/2021, 17:42:55").toLocaleString('en', { timeZone: 'Europe/Istanbul', hour12:false, year: "numeric", month: "2-digit", day: "2-digit", hour: "2-digit", minute: "2-digit", second: "2-digit" });
        competitionForm.value.competition.date.date = dateString(getCompetition.date).substr(0, 10)
        showSection.value.statComponent = true

        loadingMatchFormation.value = 0
        getTeamPlayers(competitionForm.value.awayTeam.select.id, "awayTeam").then((data) => {
          setTeamFormations("awayTeam")
        })
        getTeamPlayers(competitionForm.value.homeTeam.select.id, "homeTeam").then((data) => {
          setTeamFormations("homeTeam")
        })

        const teams = await axios.get("/team")
        competitionForm.value.teams.option = teams.data.data
        const location = await axios.get("/location")
        competitionForm.value.location.option = location.data
        const league = await axios.get("/league?officialLeagueTeam=" + routerTeamId)
        competitionForm.value.league.option = league.data

        competition.value.formationTrigger = 1
      }
    } catch (e) {
      console.log("ERROR!!", e)
      router.push({
        name: "team-competitions",
      })
      toastService(i18n.t("Error fetching match"), "EditIcon", "danger")
    }
  })
  // FOR DESTROY ALL VARIABLES
  onUnmounted(() => {
    // TODO : DESTROY VARIABLES
    // location.reload();
  })

  const getTeamPlayers = async (teamId, elementTarget) => {
    try {
      competition.value[elementTarget].team.teamPlayers = false
      // -1 for the team and testing players together
      const data = await axios.get(
        `/season/${routerSeasonId}/team/${teamId}?testing=-1&isActive=all&competition=${routerCompetitionId}`,
      )
      competition.value[elementTarget].team.teamPlayers = data.data.players
      competition.value[elementTarget].team.id = data.data.id
      competition.value[elementTarget].team.name = data.data.name
      if (data.data.club) competition.value[elementTarget].team.club = data.data.club
      loadingMatchFormation.value++
    } catch (error) {
      competition.value[elementTarget].team.teamPlayers = []
    }
  }
  const setTeamFormations = (selectTeam) => {
    if (competition.value[selectTeam].team) {
      competition.value[selectTeam].team.teamPlayers = Object.values(
        competition.value[selectTeam].team.teamPlayers,
      ).map((players) => {
        if (!players) players = {}
        // if (!players.isStarter) players.isStarter = null
        if (!players.isStarterPlayer) players.isStarterPlayer = 2
        // if (!players.isStarterId) players.isStarterId = null
        if (!players.position) {
          $set(players, "position", { id: 1, name: "TAKIMDA" })
        }
        players.stats = {
          yellowCard: null,
          yellowRedCard: null,
          redCard: null,
          goals: [],
          events: [],
        }
        players.coach = null
        players.coachReview = null
        players.coachComment = null
        players.playerReview = null
        players.playerComment = null
        players.assistantCoachReview = null
        players.athleticPerformanceCoachReview = null
        return players
      })
    }
    if (
      competition.value.competitionFormations.length > 0 &&
      competition.value[selectTeam].team.teamPlayers.length > 0
    ) {
      Object.values(competition.value.competitionFormations).map((players) => {
        const playerIndex = Object.values(competition.value[selectTeam].team.teamPlayers).findIndex(
          (pr) => pr.id == players.player.id,
        )
        if (playerIndex != -1) {
          const player = competition.value[selectTeam].team.teamPlayers[playerIndex]
          if (!player) return
          player.isStarterPlayer = players.isStarter.toString()
          player.isStarterId = players.id
          player.coach = players.coachReview
          player.coachReview = players.coachReview
          player.coachComment = players.coachComment
          player.playerReview = players.playerReview
          player.playerComment = players.playerComment
          player.assistantCoachReview = players.assistantCoachReview
          player.athleticPerformanceCoachReview = players.athleticPerformanceCoachReview
        }
      })
      Object.values(competition.value.competitionEvents).map((events) => {
        const dt = Object.values(competition.value[selectTeam].team.teamPlayers).filter(
          (pr) => pr.id == events.mainPlayer.id,
        )
        // FILTER PLAYER FROM TEAM
        if (dt.length == 1) {
          // SWITCH COMPETITION EVENT TYPES....
          if (events.competitionEventType == "YELLOW_CARD") {
            dt[0].stats.yellowCard = events.minute
            dt[0].stats.yellowCardId = events.id
          }
          if (events.competitionEventType == "RED_CARD") {
            dt[0].stats.redCard = events.minute
            dt[0].stats.redCardId = events.id
          }
          if (events.competitionEventType == "YELLOW_RED_CARD") {
            dt[0].stats.yellowRedCard = events.minute
            dt[0].stats.yellowRedCardId = events.id
          }
          if (events.competitionEventType == "CHANGE_PLAYER") {
            dt[0].stats.changedk = events.minute
            dt[0].stats.changeId = events.id
            dt[0].stats.changeplayer =
              events.subPlayer && parseInt(events.subPlayer.id) > 0 ? parseInt(events.subPlayer.id) : ""
          }
          if (events.competitionEventType == "GOAL") {
            dt[0].stats.goals.push({
              id: events.id,
              dk: events.minute,
              subPlayerId: events.subPlayer ? events.subPlayer.id : null,
              type: events.competitionEventSubType,
            })
          }
        }
      })
    }
  }
  const searchTeam = async (searchKey) => {
    if (searchKey.length < 3) return false
    const data = await axios.get("/team", {
      key: searchKey,
    })
    if (data) competitionForm.value.teams.option = data
  }
  const addTeam = async (addData, elementTarget, callback) => {
    const data = await teamSer.event({ data: addData })
    if (data) {
      competitionForm.value.teams.option.push(data)
      competitionForm.value[elementTarget].select = data
      callback()
    }
  }
  const addLeague = async (addValue) => {
    const data = await axios.post("/league", {
      playerLimit: 32,
      competitionDuration: 90,
      type: "TOURNAMENT",
      name: addValue,
    })
    if (data) {
      competitionForm.value.league.option.push(data.data)
      competitionForm.value.league.select = data.id
    }
  }
  const addLocation = async (addValue) => {
    const data = await axios.post("/location", {
      name: addValue,
    })
    if (data) {
      competitionForm.value.location.option.push(data.data)
      toastService(i18n.t("Successfully Added"), "PenIcon", "success")
      competitionForm.value.location.select = null
    }
  }
  const submitCompetition = () => {
    showSection.value.statComponent = false
    if (routerCompetitionId) {
      return editCompetition()
    }
    const req = {
      userTeam: Number(routerTeamId),
      homeTeam: Number(competitionForm.value.homeTeam.select.id),
      awayTeam: Number(competitionForm.value.awayTeam.select.id),
      league: Number(competitionForm.value.league.select.id),
      season: Number(routerSeasonId),
      location: Number(competitionForm.value.location.select.id),
      duration: Number(competitionForm.value.competition.time.select),
      homeScore: Number(competitionForm.value.competition.homeScore) || 0,
      awayScore: Number(competitionForm.value.competition.awayScore) || 0,
      competitionDate: dateString(
        `${competitionForm.value.competition.date.date} ${competitionForm.value.competition.date.time}`,
        true,
      ),
    }
    req.winId = req.homeScore == req.awayScore ? 0 : req.homeScore > req.awayScore ? req.homeTeam : req.awayTeam
    axios
      .post("/competition", req)
      .then((response) => {
        showSection.value.addMatchBasicForm = false
        showSection.value.Formation = true
        showSection.value.infoBarLoading = false
        router.push({
          name: "competition-edit",
          params: {
            id: router.currentRoute.params.id,
            competitionId: response.data.id,
          },
        })
        toastService(i18n.t("Successfully Created"), "EditIcon", "success")
        setTimeout(() => location.reload(), 250)
      })
      .catch((err) => {
        toastService(i18n.t("Failed to save"), "AlertIcon", "danger")
      })
  }
  const editCompetition = () => {
    const req = {
      homeTeam: Number(competitionForm.value.homeTeam.select.id),
      awayTeam: Number(competitionForm.value.awayTeam.select.id),
      league: Number(competitionForm.value.league.select.id),
      season: Number(routerSeasonId),
      location: Number(competitionForm.value.location.select.id),
      duration: Number(competitionForm.value.competition.time.select),
      competitionDate: dateString(
        `${competitionForm.value.competition.date.date} ${competitionForm.value.competition.date.time}`,
        true,
      ),
      homeScore: Number(competitionForm.value.competition.homeScore),
      awayScore: Number(competitionForm.value.competition.awayScore),
      firstHalfOvertime: Number(competitionForm.value.competition.firstHalfOvertime),
      secondHalfOvertime: Number(competitionForm.value.competition.secondHalfOvertime),
    }
    req.winId = req.homeScore == req.awayScore ? 0 : req.homeScore > req.awayScore ? req.homeTeam : req.awayTeam
    axios.put(`/competition/${routerCompetitionId}`, req).then((response) => {
      showSection.value.addMatchBasicForm = false
      toastService(i18n.t("Successfully Updated"), "EditIcon", "success")
      viewTrigger.value++
      setTimeout(() => {
        location.reload()
      }, 350)
    })
  }
  const submitFormation = (player, teamId) => {
    competition.value.formationTrigger++
    const req = {
      playerId: Number(player.id),
      positionId: Number(player.position.id),
      competitionId: routerCompetitionId,
      isStarter: Number(player.isStarterPlayer),
      teamId: Number(teamId),
      coachReview: null,
    }
    if (player.isStarterId) req.id = player.isStarterId
    return service.formation(req, player, competition, setTeamFormations)
  }
  const submitFormationCoach = (player, teamId, coach, coachComment, assistant, athletic) => {
    const req = {
      playerId: Number(player.id),
      positionId: Number(player.position.id),
      competitionId: routerCompetitionId,
      isStarter: Number(player.isStarterPlayer),
      coachReview: Number(coach),
      assistantCoachReview: Number(assistant),
      athleticPerformanceCoachReview: Number(athletic),
      teamId: Number(teamId),
      coachComment,
    }
    if (player.isStarterId) req.id = player.isStarterId
    service.coachReview(req)
  }
  const sendSerpNotifyToPlayers = () => {
    service.sendPlayerSerpNotification(routerCompetitionId)
  }
  helper.calculateGoal = (team) => {
    // && Number(goalCounter.length) > Number(competitionForm.value.competition.homeScore)
    const goalCounter = Object.values(competition.value.competitionEvents).filter(
      (d) => d.team.id == team && d.competitionEventType == "GOAL",
    )
    if (Number(competitionForm.value.homeTeam.select.id) == team)
      competitionForm.value.competition.homeScore = Number(goalCounter.length)
    if (Number(competitionForm.value.awayTeam.select.id) == team)
      competitionForm.value.competition.awayScore = Number(goalCounter.length)
    // axios.put(`/competition/${routerCompetitionId}/score`, { homeScore:competitionForm.value.competition.homeScore, awayScore:competitionForm.value.competition.homeScore })
    editCompetition()
  }
  helper.submitRequestFormation = (req) => {
    service
      .submitFormationPlugin(routerCompetitionId, req)
      .then((response) => {
        competition.value.competitionFormations = response.data
        new Promise((resolve) => resolve(response.data))
      })
      .catch((err) => new Promise((reject) => reject(err)))
  }
  helper.submitEventForm = ({ id, player, dk, type, reason, team, sub, subType }) => {
    const req = {
      id: Number(id),
      mainPlayer: Number(player.id),
      competition: routerCompetitionId,
      minute: Number(dk),
      subPlayer: Number(sub) ? Number(sub) : null,
      type,
      reason,
      subType: subType || "",
      team: Number(team),
    }
    return service.event(competition, req, setTeamFormations).then((data) => {
      if (type == "GOAL") helper.calculateGoal(Number(team))
    })
  }
  helper.submitEventFormWithoutplayer = ({ playerName, id, dk, type, team, subType }) => {
    const req = {
      id: Number(id),
      competition: routerCompetitionId,
      minute: Number(dk),
      type,
      subType: subType || "",
      team: Number(team),
      playerName: playerName || "",
    }
    return service.eventWithoutplayer(competition, req, setTeamFormations).then((data) => {
      helper.calculateGoal(Number(team))
    })
  }
  helper.reloadTeamPlayers = () => {
    loadingMatchFormation.value = 0
    getTeamPlayers(competitionForm.value.awayTeam.select.id, "awayTeam").then((data) => {
      setTeamFormations("awayTeam")
    })
    getTeamPlayers(competitionForm.value.homeTeam.select.id, "homeTeam").then((data) => {
      setTeamFormations("homeTeam")
    })
  }

  const { refFormObserver, getValidationState, resetForm } = formValidation()
  return {
    showSection,
    competition,
    competitionForm,
    helper,
    getTeamPlayers,
    searchTeam,
    addTeam,
    addLocation,
    addLeague,
    submitCompetition,
    submitFormation,
    submitFormationCoach,
    sendSerpNotifyToPlayers,
    viewTrigger,
    loadingMatchFormation,
  }
}
