<template>
  <div>
    <b-overlay :show="playerCreateLoading" rounded="sm">
      <b-card>
        <validation-observer #default="{ handleSubmit }" ref="playerAddForm">
          <form @submit.prevent="addSubmit()">
            <div class="card-header p-0 m-0 mb-2">
              <div>
                <b-card-title>
                  <feather-icon icon="UserPlusIcon" size="24" />
                  {{ $t("Add New Player") }}
                </b-card-title>
              </div>
              <span class="btn btn-primary" @click="addSubmit()">
                <i class="code-toggler feather icon-save cursor-pointer"></i>
                {{ $t("Save") }}
              </span>
            </div>
            <b-row>
              <b-col md="6" xl="4" class="mb-1">
                <label>{{ $t("First Name") }} {{ $t("Last Name") }}</label>
                <b-form-group>
                  <validation-provider #default="{ errors }" name="name" rules="required">
                    <b-input v-model="playerInitform.name" :placeholder="$t('First Name') + $t('Last Name')"></b-input>
                    <div>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </div>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6" xl="4" class="mb-1">
                <label>{{ $t("Birth Date") }}</label>
                <b-form-group>
                  <validation-provider #default="{ errors }" name="birthdate" rules="required">
                    <div class="d-flex justify-content-between">
                      <b-input v-model="playerInitform.birthdate" id="bDate" placeholder="01/01/2004"></b-input>
                    </div>
                    <div>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </div>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6" xl="4" class="mb-1">
                <label>{{ $t("Email") }}</label>
                <b-form-group>
                  <validation-provider #default="{ errors }" name="email" rules="required">
                    <b-input type="email" v-model="playerInitform.email" placeholder="adres@ornek.com"></b-input>
                    <div>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </div>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6" xl="4" class="mb-1">
                <label>{{ $t("Mobile") }}</label>
                <b-form-group>
                  <validation-provider #default="{ errors }" name="mobile" rules="required">
                    <b-input v-model="playerInitform.phone" id="pPhone" placeholder="555 444 33 22"></b-input>
                    <div>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </div>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6" xl="4" class="mb-1">
                <label>{{ $t("Position") }}</label>
                <b-form-group>
                  <validation-provider #default="{ errors }" name="position" rules="required">
                    <v-select
                      :placeholder="$t('Player position')"
                      :reduce="(option) => option.id"
                      :getOptionLabel="(option) => option.name"
                      v-model="playerInitform.position.select"
                      :options="playerInitform.position.option"
                    />
                    <div>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </div>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6" xl="4" class="mb-1">
                <label>
                  {{ $t("Strong Foot") }}
                  <img :src="require('@/assets/images/icons/select-foot.png')" height="18" alt />
                </label>
                <b-form-group>
                  <validation-provider #default="{ errors }" name="foot" rules="required">
                    <v-select
                      :placeholder="$t('Strong foot')"
                      v-model="playerInitform.foot.select"
                      :state="errors.length > 0 ? false : null"
                      :reduce="(option) => option.id"
                      :options="playerInitform.foot.option"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </form>
        </validation-observer>
      </b-card>
    </b-overlay>
  </div>
</template>
<script>
  import vSelect from "vue-select"
  import { ValidationProvider, ValidationObserver } from "vee-validate"
  import { required, email } from "@validations"
  import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
  import Cleave from "cleave.js"

  export default {
    name: "PlayerAddComponent",
    components: {
      ValidationProvider,
      ValidationObserver,
      vSelect,
      ToastificationContent,
    },
    data() {
      return {
        playerCreateLoading: true,
        playerInitform: {
          name: null,
          phone: null,
          email: null,
          teamId: this.routerParams.id,
          position: {
            select: "",
            option: [],
          },
          foot: {
            select: "",
            option: [
              { id: 1, label: this.$t("Right Foot") },
              { id: 0, label: this.$t("Left Foot") },
              { id: 2, label: this.$t("Both Feet") },
            ],
          },
        },
      }
    },

    methods: {
      addSubmit() {
        this.$refs.playerAddForm.validate().then((success) => {
          if (!success) {
            return false
          }
          this.playerCreateLoading = true
          this.playerSendForm = {
            name: this.playerInitform.name,
            // TODO: AVATAR UPLOAD
            avatar: "user.png",
            teamId: Number(this.playerInitform.teamId),
            foot: Number(this.playerInitform.foot.select),
            birthdate: new Date(this.playerInitform.birthdate),
            email: this.playerInitform.email,
            phone: this.playerInitform.phone,
            position: this.playerInitform.position.select,
          }

          this.$http
            .post(
              `/season/${this.routerParams.seasonId}/team/${this.playerInitform.teamId}/player`,
              this.playerSendForm,
            )
            .then((succ) => {
              this.loading = false
              let newPlayerId = succ.data.id
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: this.$t("Player saved successfully"),
                  icon: "UserIcon",
                  variant: "success",
                },
              })

              this.$router.push({
                name: "team-player-edit",
                params: {
                  id: this.routerParams.id,
                  playerId: newPlayerId,
                },
              })
            })
            .catch((err) => {
              this.playerCreateLoading = false
            })
        })
      },
    },
    props: {},
    mounted() {
      this.$http.get("/position").then((response) => {
        this.playerInitform.position.option = response.data
      })
      new Cleave("#pPhone", {
        numericOnly: true,
        delimiter: " ",
        blocks: [3, 3, 2, 2],
      })
      new Cleave("#bDate", {
        date: true,
        datePattern: ["d", "m", "Y"],
        delimiter: "/",
      })
    },
    created() {
      setTimeout(() => {
        this.playerCreateLoading = false
      }, 1500)
    },
  }
</script>
