<template>
  <b-sidebar
    id="sidebar-season"
    :visible="isSeasonActive"
    bg-variant="white"
    @shown="$emit('update:isSeasonActive', true)"
    @hidden="
      $emit('update:isSeasonActive', false)
      formTrigger++
    "
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
  >
    <template #default="{ hide }">
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">{{ $t("Season") }}</h5>
        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver" :key="formTrigger">
        <!-- Form -->
        <b-form v-if="seasonFormData" class="p-2" @submit.prevent="handleSubmit(goSubmitSeason())">
          <!-- Season Name -->
          <validation-provider #default="{ errors }" name="seasonName" rules="required">
            <b-form-group :label="$t('Season Name')" label-for="seasonName">
              <b-form-input id="seasonName" v-model="seasonFormData.name" :state="errors[0] ? false : null" />

              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <b-row>
            <!-- Season Start Date -->
            <b-col md="12" xl="6">
              <validation-provider #default="{ errors }" name="startDate" rules="required">
                <b-form-group :label="$t('Start Date')" label-for="seasonStart">
                  <div class="d-flex justify-content-between">
                    <flat-pickr
                      id="seasonStart"
                      class="form-control"
                      v-model="seasonFormData.startDate"
                      :searchable="false"
                      :config="{
                        enableTime: false,
                        locale: user.lang.value == 'fr' ? French : user.lang.value == 'tr' ? Turkish : null,
                        enableTime: false,
                        dateFormat: 'Y-m-d',
                      }"
                    />
                  </div>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Season End Date -->
            <b-col md="12" xl="6">
              <validation-provider #default="{ errors }" name="endDate" rules="required">
                <b-form-group :label="$t('End Date')" label-for="seasonEnd">
                  <div class="d-flex justify-content-between">
                    <flat-pickr
                      id="seasonEnd"
                      class="form-control"
                      v-model="seasonFormData.endDate"
                      :searchable="false"
                      :config="{
                        enableTime: false,
                        locale: user.lang.value == 'fr' ? French : user.lang.value == 'tr' ? Turkish : null,
                        enableTime: false,
                        dateFormat: 'Y-m-d',
                      }"
                    />
                  </div>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <!-- Season Year -->
          <validation-provider #default="{ errors }" name="seasonYear" rules="required">
            <b-form-group :label="$t('Season Year')" label-for="seasonYear">
              <b-form-input
                id="seasonYear"
                type="number"
                v-model.number="seasonFormData.year"
                :state="errors[0] ? false : null"
              />

              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" type="submit">{{
              $t("Add")
            }}</b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              ref="hideClick"
              @click="hide"
              >{{ $t("Cancel") }}</b-button
            >
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>
<script>
  import { ValidationProvider, ValidationObserver } from "vee-validate"
  import { required } from "@validations"
  import Ripple from "vue-ripple-directive"
  import flatPickr from "vue-flatpickr-component"
  import { Turkish } from "flatpickr/dist/l10n/tr.js"
  import { French } from "flatpickr/dist/l10n/fr.js"

  export default {
    components: {
      flatPickr,

      // Form Validation
      ValidationProvider,
      ValidationObserver,
    },
    directives: {
      Ripple,
    },
    model: {
      prop: "isSeasonActive",
      event: "update:is-season-active",
    },
    props: {
      isSeasonActive: {
        type: Boolean,
        require: true,
      },
      submitSeason: {
        required: true,
      },
      updateSeason: {
        require: true,
      },
      seasonFormData: {
        default: null,
      },
    },
    data() {
      return {
        required,
        formTrigger: 0,
      }
    },
    methods: {
      goSubmitSeason() {
        this.$refs.refFormObserver.validate().then((success) => {
          if (success) {
            if (this.seasonFormData.id) {
              // TODO: return the organization from rest group
              this.seasonFormData.organization = 1
              this.updateSeason(this.seasonFormData)
            } else {
              // TODO: dynamically choose organization
              this.seasonFormData.organization = 1
              this.seasonFormData.year = parseInt(this.seasonFormData.year)
              this.submitSeason(this.seasonFormData)
            }
            this.$refs.hideClick.click()
          }
        })
      },
    },
    setup() {
      const user = JSON.parse(localStorage.getItem("userData"))

      return {
        user,
        Turkish,
        French,
      }
    },
  }
</script>

<style lang="scss">
  @import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
