export default [
  // *===============================================---*
  // *--------- ADMIN ---- ---------------------------------------*
  // *===============================================---*
  // {
  //    path: '/admin/list',
  //    name: 'admin-list',
  //    component: () => import('@/views/admin/users-list/UsersList.vue'),
  // },
  // {
  //    path: '/admin/view/:id',
  //    name: 'admin-view',
  //    component: () => import('@/views/admin/users-view/UsersView.vue'),
  // },
  // {
  //    path: '/admin/edit/:id',
  //    name: 'admin-edit',
  //    component: () => import('@/views/admin/users-edit/UsersEdit.vue'),
  // }
]
