<template>
  <div v-if="positions">
    <!-- Media -->
    <b-media class="mb-2">
      <template #aside>
        <b-avatar ref="previewEl" :src="player.avatar" size="90px" rounded />
      </template>
      <h4 class="mb-1">
        {{ player.name }}
      </h4>
      <div class="d-flex flex-wrap">
        <b-button variant="primary" @click="$refs.refInputEl.click()">
          <input
            ref="refInputEl"
            @change="onFileSelected"
            type="file"
            accept=".jpg, .png, .gif"
            class="d-none"
            @input="inputImageRenderer"
          />
          <span class="d-none d-sm-inline">{{ $t("Select") }}</span>
          <feather-icon icon="EditIcon" class="d-inline d-sm-none" />
        </b-button>
        <b-button class="ml-1" @click="uploadAvatar" variant="primary">
          <feather-icon size="12" icon="UploadIcon"></feather-icon>
        </b-button>
      </div>
    </b-media>
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <!-- User Info: Input Fields -->
      <b-form @submit.prevent="handleSubmit(update)">
        <b-row>
          <b-col lg="6">
            <validation-provider #default="{ errors }" name="name" rules="required">
              <b-form-group :label="$t('Name')" label-for="firstName">
                <b-input
                  id="name"
                  :placeholder="$t('Please enter name')"
                  v-model="player.name"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col lg="6">
            <validation-provider #default="{ errors }" name="birthdate" rules="required">
              <b-form-group :label="$t('Birth Date')" label-for="birthdate">
                <!-- <flat-pickr
                  v-model="player.birthdate"
                  class="form-control"
                  :config="{
                    locale: user.lang.value == 'fr' ? French : user.lang.value == 'tr' ? Turkish : null,
                    enableTime: false,
                    dateFormat: 'Y-m-d',
                  }"
                  placeholder="YYYY-MM-DD"
                /> -->
                <selectable-date v-model="player.birthdate"></selectable-date>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col lg="6">
            <validation-provider #default="{ errors }" name="position">
              <b-form-group :label="$t('Position')" label-for="position">
                <v-select
                  v-model="player.position"
                  :getOptionLabel="(option) => option.name"
                  :options="positions"
                  :reduce="(val) => val.id"
                  :clearable="false"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col lg="6">
            <validation-provider #default="{ errors }" name="team">
              <b-form-group :label="$t('Team')" label-for="team">
                <v-select
                  v-model="player.team"
                  :getOptionLabel="(option) => (option.club ? option.club.name + ' - ' + option.name : option.name)"
                  :options="teams"
                  :reduce="(val) => val.id"
                  :clearable="false"
                  input-id="team"
                >
                  <template v-slot:option="option">
                    <span class="text-nowrap">{{ option.club ? option.club.name : "" }} / {{ option.name }}</span>
                  </template>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col lg="6">
            <validation-provider #default="{ errors }" name="foot" rules="required">
              <b-form-group :label="$t('Strong Foot')" label-for="foot">
                <v-select
                  v-model="player.foot"
                  :getOptionLabel="(option) => option.label"
                  :options="footOptions"
                  :reduce="(val) => val.value"
                  :clearable="false"
                  input-id="foot"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col lg="6">
            <validation-provider #default="{ errors }" name="mobile">
              <b-form-group :label="$t('Mobile')" label-for="mobile">
                <b-input
                  type="tel"
                  id="phone"
                  :placeholder="$t('Please enter phone number')"
                  v-model="player.phone"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col lg="6">
            <validation-provider #default="{ errors }" name="height">
              <b-form-group :label="$t('Estimated Height')" label-for="height">
                <b-input
                  type="number"
                  id="height"
                  :placeholder="$t('Please enter height')"
                  v-model.number="player.height"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col lg="6">
            <validation-provider #default="{ errors }" name="weight">
              <b-form-group :label="$t('Estimated Weight')" label-for="weight">
                <b-input
                  type="number"
                  id="weight"
                  :placeholder="$t('Please enter weight')"
                  v-model.number="player.weight"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col lg="6">
            <validation-provider #default="{ errors }" name="email">
              <b-form-group :label="$t('Email')" label-for="email">
                <b-input
                  id="lastName"
                  :placeholder="$t('Please enter email')"
                  v-model="player.email"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col lg="3">
            <b-form-group :label="$t('Recommended by')" label-for="recommender">
              <div class="d-flex justify-content-between">
                <b-input
                  v-if="player.user && user.id == player.user.id"
                  id="recommender"
                  v-model="player.recommender"
                ></b-input>
                <b-input v-else id="recommender-ds" disabled :value="player.recommender"></b-input>
              </div>
            </b-form-group>
          </b-col>
          <b-col lg="3">
            <b-form-group :label="$t('User Who Added The Player')" label-for="added">
              <div class="d-flex justify-content-between">
                <b-input id="added" disabled :value="userName"></b-input>
              </div>
            </b-form-group>
          </b-col>
        </b-row>
        <b-button class="mb-1 mb-sm-0 mr-0 mr-sm-1" variant="primary" type="submit">
          {{ $t("Save Changes") }}
        </b-button>
      </b-form>
    </validation-observer>
  </div>
</template>
<script>
  import SelectableDate from "@/@core/components/global-components/SelectableDate"
  import { avatarText } from "@core/utils/filter"
  import vSelect from "vue-select"
  import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils"
  import { ref, onMounted, computed } from "@vue/composition-api"
  import store from "@/store"
  import router from "@/router"
  import i18n from "@/libs/i18n"
  import { ValidationProvider, ValidationObserver } from "vee-validate"
  import { required, email, confirmed, password } from "@validations"
  import flatPickr from "vue-flatpickr-component"
  import http from "@/libs/axios"
  import { useRouter } from "@core/utils/utils"
  import { Turkish } from "flatpickr/dist/l10n/tr.js"
  import { French } from "flatpickr/dist/l10n/fr.js"
  import Cleave from "cleave.js"
  export default {
    components: {
      vSelect,
      ValidationProvider,
      ValidationObserver,
      flatPickr,
      SelectableDate,
    },
    props: {
      player: {
        type: Object,
        required: true,
      },
      positions: {
        required: true,
      },
    },
    setup(props, { emit, root }) {
      // ? Demo Purpose => Update image on click of update
      const teams = ref([])
      const refInputEl = ref(null)
      const previewEl = ref(null)
      const user = JSON.parse(localStorage.getItem("userData"))
      const { inputImageRenderer } = useInputImageRenderer(refInputEl, (base64) => {
        // eslint-disable-next-line no-param-reassign
        console.log("sea")
        propsData.avatar = base64
      })
      const profileFile = ref(null)

      onMounted(async () => {
        await getTeams()
        var cleave = new Cleave("#phone", {
          numericOnly: true,
          delimiter: " ",
          blocks: [3, 3, 2, 2],
        })
      })

      const onFileSelected = (event) => {
        if (event.target.files.length > 0) {
          profileFile.value = event.target.files[0]
        }
      }
      const uploadAvatar = () => {
        if (profileFile.value) {
          let formData = new FormData()
          formData.append("image", profileFile.value)

          http
            .post(`scout/player/${props.player.id}/avatarUpload`, formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then((res) => {
              root.toast(i18n.t("Successfully Updated"), "PlusIcon", "success")
              location.reload()
            })
        }
      }
      const getTeams = () => {
        http
          .get(`/team`)
          .then((res) => {
            teams.value = res.data.data
          })
          .catch((error) => console.log(error))
      }
      const update = () => {
        //props.player.player = Number(router.currentRoute.params.playerId);
        if (props.player.position && Object.keys(props.player.position).length != 0) {
          props.player.position = props.player.position.id
        }
        if (props.player.team && Object.keys(props.player.team).length != 0) {
          props.player.team = props.player.team.id
        }
        http
          .put(`scout/player/${props.player.id}`, props.player)
          .then((res) => {
            //player.value = res.data;
            root.toast(i18n.t("Successfully Updated"), "PlusIcon", "success")
          })
          .catch((error) => console.log(error))
      }

      const userName = computed(() =>
        props.player.user ? props.player.user.firstName + " " + props.player.user.lastName.substr(0, 1) + "." : "",
      )

      return {
        avatarText,
        refInputEl,
        previewEl,
        inputImageRenderer,
        required,
        update,
        teams,
        user,
        Turkish,
        French,
        onFileSelected,
        uploadAvatar,
        userName,
      }
    },
  }
</script>
<style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
</style>
