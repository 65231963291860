<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Register v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <img :src="require('@teamLogoPath')" height="90" />
        </b-link>

        <b-card-title class="mb-1"> Kayıt Ol </b-card-title>

        <!-- form -->
        <validation-observer ref="registerForm">
          <b-form class="auth-register-form mt-2" @submit.prevent="register">
            <!-- firstName -->
            <b-form-group label="Isim" label-for="firstName">
              <validation-provider #default="{ errors }" name="firstName" rules="required">
                <b-form-input
                  id="firstName"
                  v-model="firstName"
                  :state="errors.length > 0 ? false : null"
                  name="firstName"
                  placeholder="Ahmet"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- firstName -->
            <b-form-group label="Soyisim" label-for="lastName">
              <validation-provider #default="{ errors }" name="lastName" rules="required">
                <b-form-input
                  id="lastName"
                  v-model="lastName"
                  :state="errors.length > 0 ? false : null"
                  name="lastName"
                  placeholder="Yılmaz"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- email -->
            <b-form-group label="Email" label-for="email">
              <validation-provider #default="{ errors }" name="email" rules="required|email">
                <b-form-input
                  id="email"
                  v-model="email"
                  :state="errors.length > 0 ? false : null"
                  name="email"
                  placeholder="ahmet@gmail.com"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- username -->
            <b-form-group label="Username" label-for="userName">
              <validation-provider #default="{ errors }" name="userName" rules="required">
                <b-form-input
                  id="userName"
                  v-model="userName"
                  :state="errors.length > 0 ? false : null"
                  name="userName"
                  placeholder="ahmet.yilmaz"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- password -->
            <b-form-group label="Password" label-for="password">
              <validation-provider #default="{ errors }" name="Password" rules="required">
                <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                  <b-form-input
                    id="password"
                    v-model="password"
                    :type="passwordFieldType"
                    :state="errors.length > 0 ? false : null"
                    class="form-control-merge"
                    name="register-password"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon :icon="passwordToggleIcon" class="cursor-pointer" @click="togglePasswordVisibility" />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- checkbox -->
            <b-form-group>
              <b-form-checkbox id="register-privacy-policy" v-model="agree" name="checkbox-1">
                <b-link>Şartları ve Koşulları</b-link> kabul ediyorum.
              </b-form-checkbox>
            </b-form-group>

            <!-- submit button -->
            <b-button variant="primary" block type="submit"> Kayıt Ol </b-button>
          </b-form>
        </validation-observer>

        <b-card-text class="text-center mt-2">
          <span>Zaten hesabım var </span>
          <b-link :to="{ name: 'login' }">
            <span>Giriş yapmak istiyorum</span>
          </b-link>
        </b-card-text>

        <!-- <div class="divider my-2">
          <div class="divider-text">or</div>
        </div>
        <div class="auth-footer-btn d-flex justify-content-center">
          <b-button variant="facebook" href="javascript:void(0)">
            <feather-icon icon="FacebookIcon" />
          </b-button>
          <b-button variant="twitter" href="javascript:void(0)">
            <feather-icon icon="TwitterIcon" />
          </b-button>
          <b-button variant="google" href="javascript:void(0)">
            <feather-icon icon="MailIcon" />
          </b-button>
          <b-button variant="github" href="javascript:void(0)">
            <feather-icon icon="GithubIcon" />
          </b-button>
        </div> -->
      </b-card>
      <!-- /Register v1 -->
    </div>
  </div>
</template>

<script>
  import { ValidationProvider, ValidationObserver } from "vee-validate"
  import {
    BCard,
    BLink,
    BCardTitle,
    BCardText,
    BForm,
    BButton,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
  } from "bootstrap-vue"

  import { required, email } from "@validations"
  import { togglePasswordVisibility } from "@core/mixins/ui/forms"
  import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
  import useJwt from "@/auth/jwt/useJwt"

  export default {
    components: {
      // BSV
      BCard,
      BLink,
      BCardTitle,
      BCardText,
      BForm,
      BButton,
      BFormInput,
      BFormGroup,
      BInputGroup,
      BInputGroupAppend,
      BFormCheckbox,
      // validations
      ValidationProvider,
      ValidationObserver,
    },
    mixins: [togglePasswordVisibility],
    data() {
      return {
        email: "",
        firstName: "",
        lastName: "",
        userName: "",
        password: "",
        platform: "client_web",

        agree: "",

        // validation rules
        required,
        email,
      }
    },
    computed: {
      passwordToggleIcon() {
        return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon"
      },
    },
    methods: {
      register() {
        this.$refs.registerForm.validate().then((success) => {
          if (success) {
            useJwt
              .register({
                email: this.email,
                firstName: this.firstName,
                lastName: this.lastName,
                userName: this.userName,
                password: this.password,
                platform: this.platform,
              })
              .then((response) => {
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: `Başarı ile kayıt oldunuz.`,
                    icon: "CoffeeIcon",
                    variant: "success",
                    text: `Lütfen Giriş Yapınız.`,
                  },
                })
                this.$router.push({ name: "login" })
              })
          }
        })
      },
    },
  }
</script>

<style lang="scss">
  @import "@core/scss/vue/pages/page-auth.scss";
</style>
