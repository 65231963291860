<template>
  <div>
    <b-card style="min-height: 80vh">
      <b-card-title> {{ $t("Fixture Schedule") }} </b-card-title>
      <div class="d-block hide-print" v-show="tableColumns.length > 0">
        <div class="float-left">
          <label>{{ $t("Show") }}</label>
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block mx-50"
          />
        </div>
        <div class="float-right">
          <b-button
            v-b-tooltip="{
              title: $t('To be able to print, you must select a display value of 20 per page.'),
              disabled: !(perPage > 20),
              trigger: 'hover',
              placement: 'top',
            }"
            :disabled="loading"
            class="right-top"
            variant="primary"
            @click="printPage()"
            :class="{ 'cursor-unset opacity-65': perPage > 20 }"
          >
            {{ $t("Print Page") }}</b-button
          >
        </div>
      </div>
      <b-overlay
        variant="white"
        :show="loading"
        spinner-variant="primary"
        blur="0"
        opacity=".75"
        rounded="sm"
        style="min-height: 10vh"
      >
        <div class="table-analysis mx-n2">
          <div ref="tableContainer" @mousedown="handleMouseDown" @mousemove="handleMouseMove" @mouseup="handleMouseUp">
            <b-table
              responsive
              striped
              bordered
              small
              no-border-collapse
              :items="items"
              :fields="tableColumns"
              :empty-text="$t('No records found')"
              show-empty
            >
              <template #head(player)="data">
                <div class="d-flex flex-column align-items-center">
                  <span class="text-nowrap"> &nbsp </span>
                </div>
              </template>
              <template #head()="data">
                <b-link
                  :to="{
                    name: 'competition-edit',
                    params: {
                      seasonId: data.field.rawCompetition.season.id,
                      id: routerParams.id,
                      competitionId: data.field.rawCompetition.id,
                    },
                  }"
                  tag="div"
                >
                  <div class="d-flex flex-column align-items-center" v-b-tooltip.hover :title="data.label">
                    <span
                      style="font-size: 0.45rem; margin-bottom: 0.5rem; margin-left: -0.5rem; margin-right: -0.5rem"
                    >
                      {{ data.field.competition.date | nativeDate }}</span
                    >
                    <img :src="data.field.competition.logo" height="24" style="margin-bottom: 0.5rem" />
                    <span
                      class="badge text-light"
                      :class="{
                        'badge-success': routerParams.id && data.field.competition.winId == routerParams.id,
                        'badge-danger': routerParams.id && data.field.competition.winId != routerParams.id,
                        'badge-dark': data.field.competition.winId == 0,
                      }"
                    >
                      {{ data.field.competition.score }}
                    </span>
                  </div>
                </b-link>
              </template>

              <template #cell(player)="data">
                <span v-if="data.value.testingPlayerRowTitle">
                  {{ data.value.testingPlayerRowTitle }}
                </span>
                <span v-else class="d-flex align-items-center">
                  <b-avatar
                    :text="avatarText(data.value.name)"
                    :src="data.value.avatar ? data.value.avatar : null"
                    class="mr-1"
                  />
                  {{ data.value.name }}
                </span>
              </template>

              <template #cell()="data" class="bg-success">
                <div
                  v-if="data.value"
                  class="d-flex justify-content-center"
                  style="gap: 3px"
                  :class="{
                    'yellow-card': data.value.YELLOW_CARD > 0,
                    'second-yellow-card': data.value.YELLOW_RED_CARD > 0,
                    'red-card': data.value.RED_CARD > 0,
                  }"
                >
                  <span
                    v-if="data.value.TIME_IN_THE_GAME > 0"
                    class="d-flex flex-column align-items-center"
                    v-b-tooltip.hover
                    :title="$t('Played Minutes')"
                  >
                    <feather-icon icon="ClockIcon" size="18" scale="1" />
                    {{ data.value.TIME_IN_THE_GAME }}
                  </span>
                  <span
                    v-if="data.value.ASSIST > 0"
                    class="d-flex flex-column align-items-center"
                    v-b-tooltip.hover
                    :title="$t('Assist')"
                  >
                    <img :src="require('@/assets/images/icons/foot.png')" height="18" alt />
                    {{ data.value.ASSIST }}
                  </span>
                  <span
                    v-if="data.value.GOAL > 0"
                    class="d-flex flex-column align-items-center"
                    v-b-tooltip.hover
                    :title="$t('Goal!')"
                  >
                    <img :src="require('@/assets/images/icons/ball.svg')" height="18" alt />
                    {{ data.value.GOAL }}
                  </span>
                </div>
              </template>
            </b-table>
          </div>

          <div v-if="items.length > 0" class="mx-2 mb-2 hide-print">
            <b-row>
              <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
                <span class="text-muted">{{
                  $t("format.showingData", { from: dataMeta.from, to: dataMeta.to, of: dataMeta.of })
                }}</span>
              </b-col>
              <!-- Pagination -->
              <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalItems"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-overlay>
    </b-card>
  </div>
</template>
<script>
  import { ref, reactive, watch, onBeforeMount, onMounted, computed, nextTick } from "@vue/composition-api"
  import axios from "@axios"
  import { avatarText } from "@core/utils/filter"
  import competitionService from "@/services/competitionService"
  import router from "@/router"
  import vSelect from "vue-select"
  import i18n from "@/libs/i18n"
  import { BLink } from "bootstrap-vue"

  export default {
    components: {
      vSelect,
      BLink,
    },
    data() {
      return {
        avatarText,
        perPageOptions: [10, 15, 20, 25, 50, 100],
        isDragging: false,
        initialX: 0,
        scrollLeft: 0,
      }
    },
    created() {},
    methods: {
      goToMatch(comp) {
        this.$router.push({
          name: "competition-edit",
          params: {
            seasonId: comp.season.id,
            id: this.routerParams.id ?? this.getTeamId(comp),
            competitionId: comp.id,
          },
        })
      },
      getTeamId(comp) {
        let teams = JSON.parse(localStorage.getItem("teams")).map((d) => d.id) || []
        return teams.includes(comp.awayTeam.id) ? comp.awayTeam.id : comp.homeTeam.id
      },
      handleMouseDown(e) {
        if (!e.target.closest(".table-responsive")) {
          this.isDragging = false
          return
        }
        this.isDragging = true
        this.initialX = e.clientX
        this.scrollLeft = this.$refs.tableContainer.querySelector(".table-responsive").scrollLeft
        this.$refs.tableContainer.style.cursor = "grabbing"
      },
      handleMouseMove(e) {
        if (!this.isDragging) return
        const deltaX = e.clientX - this.initialX
        this.$refs.tableContainer.querySelector(".table-responsive").scrollLeft = this.scrollLeft - deltaX
      },
      handleMouseUp() {
        this.isDragging = false
        this.$refs.tableContainer.style.cursor = "default"
      },
    },
    setup(props, { root }) {
      const service = new competitionService()

      const loading = ref(false)
      const perPage = ref(20)
      const totalItems = ref(0)
      const currentPage = ref(1)
      const isSortDirDesc = ref(true)
      const tableContainer = ref(null)
      const tableDefaultColumns = ref([
        {
          label: "",
          key: "player",
          stickyColumn: true,
          thStyle: { "min-width": "15rem", "min-height": "5rem" },
        },
      ])
      const defaultTestingPlayerItem = ref([
        {
          player: {
            testingPlayerRowTitle: i18n.t("Tested Players"),
          },
          _rowVariant: "secondary",
        },
      ])
      const items = ref([])
      const tableColumns = ref([...tableDefaultColumns.value])

      const dataMeta = computed(() => {
        const localItemsCount = tableColumns.value.length > 1 ? tableColumns.value.length - 1 : 0
        return {
          from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
          to: perPage.value * (currentPage.value - 1) + localItemsCount,
          of: totalItems.value,
        }
      })

      onMounted(async () => {
        getCompetitions()
      })

      const columnFormatter = (competition) => {
        const routerTeamId = router.currentRoute.params.id
        const teamSide = competition.homeTeam.id == routerTeamId ? "home" : "away"

        return {
          label:
            teamSide == "home"
              ? competition.awayTeam.name + " " + competition.awayTeam.club?.name
              : competition.homeTeam.name + " " + competition.homeTeam.club?.name,
          key: competition.id.toString(),
          thStyle: { "min-width": "5.5rem" },
          competition: {
            logo: teamSide == "home" ? competition.awayTeam.club?.logo : competition.homeTeam.club?.logo,
            score:
              teamSide == "home"
                ? `${competition.homeScore}-${competition.awayScore}`
                : `${competition.awayScore}-${competition.homeScore}`,
            date: competition.date,
            winId: competition.winId,
          },
          rawCompetition: competition,
        }
      }

      const getCompetitions = () => {
        loading.value = true
        return service
          .findCompetitionWithStatsByTeam(router.currentRoute.params.seasonId, router.currentRoute.params.id, {
            row: perPage.value,
            page: currentPage.value,
            sortBy: "date",
            isDesc: isSortDirDesc.value,
          })
          .then(async (res) => {
            totalItems.value = res.count
            items.value = []
            tableColumns.value = [...tableDefaultColumns.value]

            tableColumns.value.push(
              ...res.data.competitions.reverse().map((item) => {
                return columnFormatter(item)
              }),
            )

            if (res.data.competitions.length > 0) {
              items.value.push(
                ...res.data.players?.teamPlayers?.map((item) => {
                  return {
                    player: item.profile,
                    ...item.stats,
                  }
                }),
              )

              items.value.push(
                ...res.data.players?.competitionPlayersWithoutTeam?.map((item) => {
                  return {
                    player: item.profile,
                    ...item.stats,
                  }
                }),
              )

              if (res.data.players?.testingPlayers?.length > 0) {
                items.value.push(...defaultTestingPlayerItem.value)
              }

              items.value.push(
                ...res.data.players?.testingPlayers?.map((item) => {
                  return {
                    player: item.profile,
                    ...item.stats,
                  }
                }),
              )
            }

            loading.value = false
            await nextTick()
            tableContainer.value.querySelector(".table-responsive").scrollLeft =
              tableContainer.value.querySelector(".table-responsive")?.scrollWidth

            return items.value
          })
          .catch((err) => {
            console.log("err", err)
            loading.value = false
          })
      }

      const printPage = () => {
        if (perPage.value > 20) return

        window.print()
      }

      watch([perPage, currentPage], () => {
        getCompetitions()
      })

      return { tableColumns, items, loading, perPage, currentPage, dataMeta, totalItems, tableContainer, printPage }
    },
  }
</script>
<style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }

  .right-top {
    position: absolute;
    right: 2rem;
    top: 1rem;
  }

  .cursor-unset {
    cursor: unset !important;
  }

  .opacity-65 {
    opacity: 0.65;
  }

  .yellow-card {
    background-color: #ffd700; /* Sarı */
  }

  .second-yellow-card {
    background: linear-gradient(100deg, #ffd700 50%, #ff6347 50%); /* Sarı ve Kırmızı Renk Geçişi */
  }

  .red-card {
    background-color: #ff6347; /* Kırmızı */
  }
</style>

<style lang="scss">
  @media print {
    .header-navbar,
    .main-menu,
    .header-navbar-shadow,
    .tooltip.b-tooltip,
    .btn-scroll-to-top,
    .hide-print,
    .btn-scroll-to-top,
    footer {
      display: none !important;
      min-height: 0 !important;
      visibility: hidden !important;
    }

    .app-content {
      padding: 0px !important;
      margin-left: 0px !important;
    }

    body {
      background: white !important;
    }

    a {
      text-decoration: none !important;
    }

    #app {
      background: white !important;
      height: unset !important;
    }

    .card {
      min-height: none !important;
    }

    .table-responsive {
      overflow-x: visible !important;
    }

    .table {
      border-collapse: collapse;

      -webkit-print-color-adjust: exact !important; /*Chrome, Safari */
      color-adjust: exact !important; /*Firefox*/
    }

    /* Özel yazdırma stilleri ekleyin */
    .table th {
      background-color: #f0f0f0;
      color: #000;
      font-size: 14px;
    }

    .table td {
      font-size: 12px;
    }
  }
</style>
