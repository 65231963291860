import { render, staticRenderFns } from "./userAdd.vue?vue&type=template&id=2f93a046&"
import script from "./userAdd.vue?vue&type=script&lang=js&"
export * from "./userAdd.vue?vue&type=script&lang=js&"
import style0 from "./userAdd.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports