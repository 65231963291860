<template>
  <b-sidebar
    id="sidebar-club"
    :visible="isClubActive"
    bg-variant="white"
    @shown="$emit('update:isClubActive', true)"
    @hidden="
      $emit('update:isClubActive', false)
      formTrigger++
    "
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    :no-close-on-backdrop="uploading"
    :no-close-on-esc="uploading"
    :no-header-close="uploading"
  >
    <template #default="{ hide }">
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">{{ $t("Club") }}</h5>
        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" v-show="!uploading" />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver" :key="formTrigger">
        <!-- Form -->
        <b-form v-if="clubFormData" class="p-2" @submit.prevent="handleSubmit(goSubmitClub())">
          <!-- Club Name -->
          <validation-provider #default="{ errors }" name="clubName" rules="required">
            <b-form-group :label="$t('Club Name')" label-for="clubName">
              <b-form-input id="clubName" v-model="clubFormData.name" :state="errors[0] ? false : null" />

              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Club Logo -->
          <validation-provider #default="{ errors }" name="clubLogoUrl" rules="required">
            <b-form-group :label="$t('Club Logo Url')" label-for="clubLogoUrl">
              <input
                ref="refClubLogoInput"
                @change="onFileSelected"
                type="file"
                accept=".jpg, .png, .gif"
                class="d-none"
              />

              <b-input-group class="input-group-merge" :class="errors[0] ? 'is-invalid' : null">
                <b-form-input id="clubLogoUrl" v-model="clubFormData.logo" :state="errors[0] ? false : null" />
                <b-input-group-append>
                  <button class="btn btn-outline-primary" type="button" @click="$refs.refClubLogoInput.click()">
                    <feather-icon icon="UploadIcon" class="cursor-pointer" />
                  </button>
                </b-input-group-append>
              </b-input-group>

              <b-progress
                class="mt-1"
                height="1rem"
                v-if="uploading"
                max="100"
                :value.sync="uploadPercentage"
                show-progress
                animated
              ></b-progress>

              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <b-row>
            <!-- Club Main Color -->
            <b-col md="12" xl="6">
              <validation-provider #default="{ errors }" name="clubMainColor" rules="required">
                <b-form-group :label="$t('Club Main Color')" label-for="clubMainColor">
                  <b-form-input
                    id="clubMainColor"
                    type="color"
                    v-model="clubFormData.mainColor"
                    :state="errors[0] ? false : null"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Club Sub Color -->
            <b-col md="12" xl="6">
              <validation-provider #default="{ errors }" name="clubSubColor" rules="required">
                <b-form-group :label="$t('Club Sub Color')" label-for="clubSubColor">
                  <b-form-input
                    id="clubSubColor"
                    type="color"
                    v-model="clubFormData.subColor"
                    :state="errors[0] ? false : null"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              :disabled="uploading"
              >{{ $t("Add") }}</b-button
            >
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              ref="hideClick"
              @click="hide"
              :disabled="uploading"
              >{{ $t("Cancel") }}</b-button
            >
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>
<script>
  import { ValidationProvider, ValidationObserver } from "vee-validate"
  import { required } from "@validations"
  import formValidation from "@core/comp-functions/forms/form-validation"
  import Ripple from "vue-ripple-directive"
  import flatPickr from "vue-flatpickr-component"
  import vSelect from "vue-select"
  import http from "@/libs/axios"
  import i18n from "@/libs/i18n"
  import { ref } from "@vue/composition-api"

  export default {
    components: {
      flatPickr,
      vSelect,

      // Form Validation
      ValidationProvider,
      ValidationObserver,
    },
    directives: {
      Ripple,
    },
    model: {
      prop: "isClubActive",
      event: "update:is-club-active",
    },
    props: {
      isClubActive: {
        type: Boolean,
        require: true,
      },
      submitClub: {
        required: true,
      },
      updateClub: {
        require: true,
      },
      clubFormData: {
        default: null,
      },
    },
    data() {
      return {
        required,
        formTrigger: 0,
        logoOption: "url",
        logoOptions: [
          { value: "url", text: this.$t("from url") },
          { value: "file", text: this.$t("from upload") },
        ],
      }
    },
    methods: {
      goSubmitClub() {
        this.$refs.refFormObserver.validate().then((success) => {
          if (success) {
            if (this.clubFormData.id) {
              this.updateClub(this.clubFormData)
            } else {
              this.submitClub(this.clubFormData)
            }
            this.$refs.hideClick.click()
          }
        })
      },
    },
    setup(props, { root }) {
      const { getValidationState } = formValidation()
      const uploading = ref(false)
      const uploadPercentage = ref(0)

      const onFileSelected = async (event) => {
        if (event.target.files.length > 0) {
          let formData = new FormData()
          formData.append("image", event.target.files[0])
          uploading.value = true
          await http
            .post(`club/logoUpload`, formData, {
              headers: { "Content-Type": "multipart/form-data" },
              onUploadProgress: (progressEvent) =>
                (uploadPercentage.value = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100))),
            })
            .then((res) => {
              root.toast(i18n.t("Successfully Uploaded"), "PlusIcon", "success")
              props.clubFormData.logo = res.data.url
            })
            .finally(() => (uploading.value = false), (uploadPercentage.value = 0))
        }
      }

      return {
        getValidationState,
        onFileSelected,
        uploading,
        uploadPercentage,
      }
    },
  }
</script>

<style lang="scss">
  @import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
