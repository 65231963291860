<template>
  <div>
    <b-overlay :show="staffEditedLoading" rounded="sm">
      <!-- Media -->
      <b-media class="mb-2">
        <template #aside>
          <b-avatar
            ref="previewEl"
            :src="staff.avatar"
            :variant="`light-${resolveUserRoleVariant(staff.role)}`"
            size="90px"
            rounded
          />
        </template>
        <h4 class="mb-1">
          {{ staff.firstName + " " + staff.lastName }}
        </h4>
        <div class="d-flex flex-wrap">
          <b-button variant="primary" @click="$refs.refInputEl.click()">
            <input
              ref="refInputEl"
              type="file"
              accept=".jpg, .png, .gif"
              class="d-none"
              @input="inputImageRenderer"
              @change="onFileSelected"
            />
            <span class="d-none d-sm-inline">{{ $t("Select") }}</span>
            <feather-icon icon="EditIcon" class="d-inline d-sm-none" />
          </b-button>
          <b-button class="ml-1" @click="uploadAvatar" variant="primary">
            <feather-icon size="12" icon="UploadIcon"></feather-icon>
          </b-button>
        </div>
      </b-media>

      <!-- User Info: Input Fields -->
      <b-form @submit.prevent="update">
        <b-row>
          <b-col lg="6">
            <validation-provider #default="{ errors }" name="firstName" rules="required">
              <b-form-group :label="$t('First Name')" label-for="firstName">
                <b-input
                  id="firstName"
                  :placeholder="$t('Please enter first name')"
                  v-model="staff.firstName"
                  :state="errors.length > 0 ? false : null"
                />

                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col lg="6">
            <validation-provider #default="{ errors }" name="lastName" rules="required">
              <b-form-group :label="$t('Last Name')" label-for="lastName">
                <b-input
                  id="lastName"
                  :placeholder="$t('Please enter last name')"
                  v-model="staff.lastName"
                  :state="errors.length > 0 ? false : null"
                />

                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col lg="6">
            <validation-provider #default="{ errors }" name="nation" rules="required">
              <b-form-group :label="$t('Nation')" label-for="nation">
                <v-select
                  id="nation"
                  :placeholder="$t('Please select a nation')"
                  v-model="staff.nation"
                  :state="errors.length > 0 ? false : null"
                  :options="countries"
                  :reduce="(option) => option.c"
                  :getOptionLabel="(option) => option.n"
                  :clearable="false"
                >
                </v-select>

                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col lg="6">
            <validation-provider #default="{ errors }" name="nationId" rules="required">
              <b-form-group :label="$t('Nation Id')" label-for="nationId">
                <b-input
                  type="number"
                  id="nationId"
                  :placeholder="$t('Please enter nation id')"
                  v-model="staff.nationId"
                  :state="errors.length > 0 ? false : null"
                />

                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col lg="6">
            <validation-provider #default="{ errors }" name="birthDate" rules="required">
              <b-form-group :label="$t('Birth Date')" label-for="birthDate">
                <selectable-date v-model="staff.birthDate"></selectable-date>

                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col lg="6">
            <validation-provider #default="{ errors }" name="email" rules="required">
              <b-form-group :label="$t('Email')" label-for="email">
                <b-input
                  id="email"
                  :placeholder="$t('Please enter email')"
                  v-model="staff.email"
                  :state="errors.length > 0 ? false : null"
                />

                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col lg="6">
            <validation-provider #default="{ errors }" name="phoneNumber" rules="required">
              <b-form-group :label="$t('Phone Number')" label-for="phoneNumber">
                <b-input
                  id="phoneNumber"
                  :placeholder="$t('Please enter phone number')"
                  v-model="staff.phoneNumber"
                  :state="errors.length > 0 ? false : null"
                />

                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Staff Department -->
          <b-col lg="6">
            <!-- Staff User -->
            <validation-provider #default="{ errors }" name="department" rules="required">
              <b-form-group :label="$t('Staff Department')" label-for="staffRole">
                <v-select
                  id="staffRole"
                  :placeholder="$t('Please select a department')"
                  v-model="staff.department"
                  :state="errors.length > 0 ? false : null"
                  :options="departments"
                  :reduce="(option) => option"
                  :getOptionLabel="(option) => option.name"
                >
                </v-select>

                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col lg="6">
            <!-- Staff Role -->
            <validation-provider #default="{ errors }" name="job" rules="required">
              <b-form-group :label="$t('Staff Job')" label-for="staffRole">
                <v-select
                  id="staffRole"
                  :placeholder="$t('Please select a job')"
                  v-model="staff.job"
                  :state="errors.length > 0 ? false : null"
                  :options="jobs"
                  :reduce="(option) => option"
                  :getOptionLabel="(option) => option.name"
                >
                </v-select>

                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col lg="6">
            <!-- User Birth Date  -->
            <validation-provider #default="{ errors }" name="jobEndDate" rules="required">
              <b-form-group :label="$t('Job End Date')" label-for="jobEndDate">
                <flat-pickr
                  v-model="staff.jobEndDate"
                  class="form-control"
                  :config="{
                    locale: user.lang.value == 'fr' ? French : user.lang.value == 'tr' ? Turkish : null,
                    enableTime: false,
                    dateFormat: 'Y-m-d',
                  }"
                  placeholder="YYYY-MM-DD"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col lg="6">
            <!-- Staff Season -->
            <validation-provider #default="{ errors }" name="season" rules="required">
              <b-form-group :label="$t('Staff Season')" label-for="season">
                <v-select
                  id="season"
                  multiple
                  :placeholder="$t('Please select a season')"
                  v-model="staff.season"
                  :state="errors.length > 0 ? false : null"
                  :options="seasons"
                  :reduce="(option) => option.id"
                  :getOptionLabel="(option) => option.name"
                >
                </v-select>

                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col lg="6">
            <!-- Staff Team -->
            <validation-provider #default="{ errors }" name="team" rules="required">
              <b-form-group :label="$t('Staff Team')" label-for="team">
                <v-select
                  id="team"
                  multiple
                  :placeholder="$t('Please select a team')"
                  v-model="staff.team"
                  :state="errors.length > 0 ? false : null"
                  :options="teams"
                  :reduce="(option) => option.id"
                  :getOptionLabel="(option) => option.name"
                >
                </v-select>

                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col lg="6">
            <!-- Staff Team -->
            <validation-provider #default="{ errors }" name="status" rules="required">
              <b-form-group :label="$t('Status')" label-for="status">
                <v-select
                  id="status"
                  :placeholder="$t('Please select a team')"
                  v-model="staff.status"
                  :state="errors.length > 0 ? false : null"
                  :options="statusOptions"
                  :reduce="(option) => option.value"
                  :getOptionLabel="(option) => option.label"
                  :clearable="false"
                >
                </v-select>

                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-button class="mb-1 mb-sm-0 mr-0 mr-sm-1" variant="primary" type="submit">
          {{ $t("Save Changes") }}
        </b-button>
      </b-form>
    </b-overlay>
  </div>
</template>

<script>
  import { avatarText } from "@core/utils/filter"
  import vSelect from "vue-select"
  import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils"
  import { ref } from "@vue/composition-api"
  import useUsersList from "../staffs-list/useStaffsList"
  import store from "@/store"
  import router from "@/router"
  import userService from "@/services/userService"
  import i18n from "@/libs/i18n"
  import { ValidationProvider, ValidationObserver } from "vee-validate"
  import http from "@/libs/axios"
  import flatPickr from "vue-flatpickr-component"
  import { Turkish } from "flatpickr/dist/l10n/tr.js"
  import { French } from "flatpickr/dist/l10n/fr.js"
  import SelectableDate from "@/@core/components/global-components/SelectableDate"
  import countries from "@/libs/country.json"
  import Cleave from "cleave.js"

  export default {
    components: {
      vSelect,
      ValidationProvider,
      ValidationObserver,
      flatPickr,
      SelectableDate,
      countries,
    },
    props: {
      staff: {
        type: Object,
        required: true,
      },
      seasons: {
        type: Array,
        required: true,
      },
      teams: {
        type: Array,
        required: true,
      },
      departments: {
        type: Array,
        required: true,
      },
      jobs: {
        type: Array,
        required: true,
      },
    },

    mounted() {
      new Cleave("#phoneNumber", {
        numericOnly: true,
        delimiter: " ",
        blocks: [3, 3, 2, 2],
      })
    },

    setup(props, { emit, root }) {
      const { resolveUserRoleVariant } = useUsersList()
      const userSer = new userService()
      const user = JSON.parse(localStorage.getItem("userData"))
      const profileFile = ref(null)
      const staffEditedLoading = ref(false)

      const update = () => {
        staffEditedLoading.value = true
        let tempStaff = {
          firstName: props.staff.firstName,
          lastName: props.staff.lastName,
          nation: props.staff.nation,
          nationId: props.staff.nationId,
          birthDate: props.staff.birthDate,
          email: props.staff.email,
          phoneNumber: props.staff.phoneNumber,
          status: props.staff.status,
          season: props.staff.season.map((i) => i.id || i),
          team: props.staff.team.map((i) => i.id || i),
          department: props.staff.department ? props.staff.department.id : null,
          job: props.staff.job.id,
          jobEndDate: props.staff.jobEndDate,
        }
        http
          .put(`/staff/${props.staff.id}`, tempStaff)
          .then((res) => {
            staffEditedLoading.value = false
            root.toast(i18n.t("Successfully Updated"), "PlusIcon", "success")
            location.reload()
            console.log(res)
          })
          .catch((error) => {
            staffEditedLoading.value = false
            console.log(error)
          })
      }

      const onFileSelected = (event) => {
        if (event.target.files.length > 0) {
          profileFile.value = event.target.files[0]
        }
      }
      const uploadAvatar = () => {
        if (profileFile.value) {
          let formData = new FormData()
          formData.append("image", profileFile.value)

          http
            .post(`/staff/${props.staff.id}/avatarUpload`, formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then((res) => {
              root.toast(i18n.t("Successfully Updated"), "PlusIcon", "success")
              location.reload()
            })
        }
      }

      const roleOptions = [
        { label: "Antrenör", value: "Antrenör" },
        { label: "Kondisyoner", value: "Kondisyoner" },
        { label: "Kaleci Antrenörü", value: "Kaleci Antrenörü" },
      ]

      const statusOptions = [
        { label: i18n.t("Active"), value: 1 },
        { label: i18n.t("Inactive"), value: 0 },
      ]

      const permissionsData = [
        {
          module: "Admin",
          read: true,
          write: false,
          create: false,
          delete: false,
        },
        {
          module: "Staff",
          read: false,
          write: true,
          create: false,
          delete: false,
        },
        {
          module: "Author",
          read: true,
          write: false,
          create: true,
          delete: false,
        },
        {
          module: "Contributor",
          read: false,
          write: false,
          create: false,
          delete: false,
        },
        {
          module: "User",
          read: false,
          write: false,
          create: false,
          delete: true,
        },
      ]

      // ? Demo Purpose => Update image on click of update
      const refInputEl = ref(null)
      const previewEl = ref(null)

      const { inputImageRenderer } = useInputImageRenderer(refInputEl, (base64) => {
        // eslint-disable-next-line no-param-reassign
        propsData.avatar = base64
      })

      return {
        resolveUserRoleVariant,
        avatarText,
        roleOptions,
        statusOptions,
        permissionsData,
        staffEditedLoading,

        //  ? Demo - Update Image on click of update button
        refInputEl,
        previewEl,
        inputImageRenderer,
        onFileSelected,
        uploadAvatar,

        update,
        user,
        Turkish,
        French,
        countries,
      }
    },
  }
</script>

<style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
</style>
