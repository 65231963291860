<template>
  <section id="dashboard-ecommerce">
    <b-row class="match-height">
      <b-col xl="5" md="5">
        <b-overlay :show="isLoadingAttendance" rounded="sm">
          <chart-training :data="teamTrainingAttendsData"></chart-training>
        </b-overlay>
      </b-col>
      <b-col xl="7" md="7">
        <b-overlay :show="isLoadingMatchHistories" rounded="sm">
          <table-match-history :match-history="matchHistory" class="tableCardMatches"></table-match-history>
        </b-overlay>
      </b-col>
      <b-col xl="5" md="5">
        <calendar-list-app withSidebar="false"></calendar-list-app>
      </b-col>
      <b-col xl="7" md="7" v-if="loc">
        <b-overlay :show="isLoadingDailyTrainingLOCDurations" rounded="sm">
          <training-program-list :data="antrenmanTimeData"></training-program-list>
        </b-overlay>
      </b-col>
    </b-row>
  </section>
</template>

<script>
  import ChartTraining from "@core/components/global-components/ChartTraining"
  import TrainingProgramList from "@core/components/global-components/TrainingProgramList"
  import TableMatchHistory from "@core/components/global-components/TableMatchHistory"

  import CalendarListApp from "@/views/apps/calendar-app/Calendar.vue"
  import i18n from "@/libs/i18n"
  import { avatarText } from "@/@core/utils/filter"

  export default {
    components: {
      ChartTraining,
      TableMatchHistory,
      TrainingProgramList,
      CalendarListApp,
    },
    data() {
      return {
        avatarText,
        matchHistory: [],
        teamTrainingAttendsData: {
          participanData: {
            // TODO : Translate
            series: [
              {
                name: "Katılım",
                data: [],
              },
              {
                name: "Devamsız",
                data: [],
              },
            ],
          },
          radialChartData: {
            series: [],
            participiantCount: 0,
            unParticipiantCount: 1,
          },
          teams: [],
          type: "team", // 'team',
          title: "Training Participation Breakdown",
        },
        antrenmanTimeData: {
          title: "Daily Training Purpose Times",
          series: [],
          weeklyDays: [
            i18n.t("Monday"),
            i18n.t("Tuesday"),
            i18n.t("Wednesday"),
            i18n.t("Thursday"),
            i18n.t("Friday"),
            i18n.t("Saturday"),
            i18n.t("Sunday"),
          ].reverse(),
        },
        teamTrainingAttends: [],
        team: {},
        attendance: null,
        loc: null,
        isLoadingAttendance: false,
        isLoadingMatchHistories: false,
        isLoadingDailyTrainingLOCDurations: false,
      }
    },
    mounted() {
      this.getAttendance()
      this.getCompetitions()
      this.getDailyTrainingLOCDurations()
    },
    methods: {
      getAttendance() {
        this.isLoadingAttendance = true
        this.$http
          .get(`/season/${this.routerParams.seasonId}/training-attendant/team/${this.routerParams.id}`)
          .then((response) => {
            if (response.data.playerAttendance) {
              this.attendance = Object.values(response.data.playerAttendance)
              this.setAttendantChartData()
            }
            this.isLoadingAttendance = false
          })
          .catch((err) => {
            console.log(err)
            this.isLoadingAttendance = false
          })
      },
      setAttendantChartData() {
        this.getAttendantCount()
        if (this.attendance.length > 0) {
          this.attendance.filter((item) => {
            this.teamTrainingAttendsData.teams.push(this.avatarText(item.player.name))
            this.teamTrainingAttendsData.participanData.series[0].data.push(item.attendant ? item.attendant.length : 0)
            this.teamTrainingAttendsData.participanData.series[1].data.push(item.absent ? item.absent.length : 0)
          })
        }
      },
      getAttendantCount() {
        let attendant = 0
        let absent = 0
        let persentage = 0
        if (this.attendance.length > 0) {
          this.attendance.filter((item) => {
            attendant = item.attendant ? attendant + item.attendant.length : attendant
            absent = item.absent ? absent + item.absent.length : absent
          })
        }

        persentage = attendant / (attendant + absent)
        persentage = persentage.toFixed(2)
        persentage = persentage * 100

        this.teamTrainingAttendsData.radialChartData.participiantCount = attendant
        this.teamTrainingAttendsData.radialChartData.unParticipiantCount = absent
        this.teamTrainingAttendsData.radialChartData.series = [persentage]
      },
      getCompetitions() {
        this.isLoadingMatchHistories = true
        this.$http
          .get(`/season/${this.routerParams.seasonId}/team/${this.routerParams.id}/competition`)
          .then((response) => {
            this.matchHistory = response.data.data
            this.isLoadingMatchHistories = false
          })
          .catch((err) => {
            this.matchHistory = []
            this.isLoadingMatchHistories = false
          })
      },
      getDailyTrainingLOCDurations() {
        this.isLoadingDailyTrainingLOCDurations = true
        this.$http
          .get(`/season/${this.routerParams.seasonId}/training-loc/team/${this.routerParams.id}`)
          .then((response) => {
            this.loc = Object.values(response.data)
            this.setDailyTLOCD()
            this.isLoadingDailyTrainingLOCDurations = false
          })
          .catch((err) => {
            console.log(err)
            this.isLoadingDailyTrainingLOCDurations = false
          })
      },
      setDailyTLOCD() {
        if (this.loc.length > 0) {
          this.loc.filter((item) => {
            this.antrenmanTimeData.series.push({
              name: item.loc,
              type: "bar",
              stack: "advertising",
              data: item.duration,
              barWidth: "30%",
            })
          })
        }
      },
    },
    watch: {
      "$route.params.id": {
        handler: function (id) {
          this.getAttendance()
          this.getCompetitions()
          this.getDailyTrainingLOCDurations()
        },
        deep: true,
        immediate: true,
      },
    },
    created() {},
  }
</script>

<style lang="scss"></style>

<style lang="scss">
  .tableCardMatches {
    .card-body {
      margin: -1.5rem -0.5rem;
    }
  }
</style>
