<template>
  <b-sidebar
    id="sidebar-add-cont"
    :visible="isAddContActive"
    bg-variant="white"
    @shown="$emit('update:isAddContActive', true)"
    @hidden="
      $emit('update:isAddContActive', false)
      formTrigger++
    "
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
  >
    <template #default="{ hide }">
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">{{ $t("Contact") }}</h5>
        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver" :key="formTrigger">
        <!-- Form -->
        <b-form
          v-if="playerContactForm"
          class="p-2"
          @submit.prevent="handleSubmit(goSubmitCont())"
          @reset.prevent="resetForm"
        >
          <validation-provider #default="{ errors }" name="name" rules="required">
            <b-form-group :label="$t('Name')" label-for="contact-name">
              <b-form-input
                id="contact-name"
                v-model="playerContactForm.contactName"
                autofocus
                :state="getValidationState(errors)"
                trim
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- {{ $t('Player Full Name') }} -->
          <validation-provider #default="{ errors }" name="contactLevel" rules="required">
            <b-form-group :label="$t('Contact Level')" label-for="contactLevel">
              <v-select
                :placeholder="$t('Contact Level')"
                :state="errors.length > 0 ? false : null"
                v-model="playerContactForm.contactLevel"
                :reduce="(option) => option.value"
                :options="[
                  { label: $t('Mother'), value: 'Anne' },
                  { label: $t('Father'), value: 'Baba' },
                ]"
              ></v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <validation-provider #default="{ errors }" name="mobile" rules="required">
            <b-form-group :label="$t('Mobile')" label-for="contactPhone">
              <cleave
                id="time"
                v-model="playerContactForm.contactPhone"
                class="form-control"
                :raw="false"
                :options="options.delimiter"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Contact Email -->
          <validation-provider name="Email" #default="{ errors }" rules="required|email">
            <b-form-group :label="$t('Email')" label-for="contact-email">
              <b-form-input v-model="playerContactForm.email" />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <validation-provider #default="{ errors }" name="address" rules="required">
            <b-form-group :label="$t('Adress')" label-for="contactAddress">
              <b-form-textarea
                id="contactAddress"
                placeholder
                type="text"
                :state="getValidationState(errors)"
                v-model="playerContactForm.contactAddress"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" type="submit">{{
              $t("Add")
            }}</b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              ref="hideClick"
              @click="hide"
              >{{ $t("Cancel") }}</b-button
            >
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
  import { ValidationProvider, ValidationObserver } from "vee-validate"
  import { ref } from "@vue/composition-api"
  import { required, alphaNum, email } from "@validations"
  import formValidation from "@core/comp-functions/forms/form-validation"
  import Ripple from "vue-ripple-directive"
  import vSelect from "vue-select"
  import store from "@/store"

  import Cleave from "vue-cleave-component"
  // eslint-disable-next-line import/no-extraneous-dependencies
  import "cleave.js/dist/addons/cleave-phone.us"
  export default {
    components: {
      vSelect,
      Cleave,

      // Form Validation
      ValidationProvider,
      ValidationObserver,
    },
    directives: {
      Ripple,
    },
    model: {
      prop: "isAddContActive",
      event: "update:is-add-cont-active",
    },
    props: {
      isAddContActive: {
        type: Boolean,
        required: true,
      },
      playerCont: {
        default: null,
      },
      submitContact: {
        required: true,
      },
      updateContact: {
        default: null,
      },
      playerContactForm: {
        default: null,
      },
    },
    data() {
      return {
        required,
        formTrigger: 0,
      }
    },
    watch: {
      Data: function (newData, oldData) {
        if (newData) {
          this.playerContactForm = newData
        }
      },
    },
    methods: {
      goSubmitCont() {
        this.$refs.refFormObserver.validate().then((success) => {
          if (success) {
            if (this.playerContactForm.id) {
              this.updateContact(this.playerContactForm)
            } else {
              this.submitContact(this.playerContactForm)
            }
            this.$refs.hideClick.click()
          }
        })
      },
    },
    setup(props, { emit, root }) {
      const blankUserData = {}

      const { refFormObserver, getValidationState, resetForm } = formValidation()

      const options = {
        creditCard: {
          creditCard: true,
        },
        date: {
          date: true,
          delimiter: "/",
          datePattern: ["d", "m", "Y"],
        },
        phone: {
          phone: true,
          phoneRegionCode: "TR",
        },
        time: {
          time: true,
          timePattern: ["h", "m", "s"],
        },
        number: {
          numeral: true,
          numeralThousandsGroupStyle: "thousand",
        },
        block: {
          blocks: [4, 3, 3],
          uppercase: true,
        },
        delimiter: {
          delimiter: "·",
          blocks: [3, 3, 4],
          uppercase: true,
        },
        customDelimiter: {
          delimiters: [".", ".", "-"],
          blocks: [3, 3, 3, 2],
          uppercase: true,
        },
        prefix: {
          prefix: "+63",
          blocks: [3, 3, 3, 4],
          uppercase: true,
        },
      }

      return {
        options,

        refFormObserver,
        getValidationState,
        resetForm,
      }
    },
  }
</script>

<style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";

  #add-new-user-sidebar {
    .vs__dropdown-menu {
      max-height: 200px !important;
    }
  }
</style>
