<template>
  <div>
    <player-education-new
      :player-data="playerData"
      :is-add-edu-active.sync="isAddEduActive"
      :submit-edu="submitEdu"
      :update-edu="updateEdu"
      :school-status="schoolStatus"
      :player-edu-form="edu"
    />
    <b-row>
      <b-col md="4" v-for="pd in playerData.educations" :key="pd.id">
        <b-card style="border: 1px solid #ddd">
          <div class="card-header p-0 m-0 mb-2">
            <div>
              <b-card-title>
                <feather-icon icon="FileIcon" size="24" />
                {{ pd.schoolName }}
              </b-card-title>
            </div>
            <div>
              <span class="btn btn-light" @click="goSidebarContact(pd)">
                <feather-icon icon="EditIcon"></feather-icon>
              </span>
              <span class="btn btn-light ml-1" @click="goDeleteEdu(pd.id)">
                <feather-icon icon="Trash2Icon"></feather-icon>
              </span>
            </div>
          </div>
          <div class="mb-2">
            <span class="badge badge-warning mb-2" v-text="pd.schoolLevel"></span>
            <div>
              <span class="h4">
                <feather-icon icon="PhoneIcon"></feather-icon>
                {{ pd.schoolPhone }}
              </span>
            </div>

            <b-form-group :label="$t('Status')" label-for="schoolStatus" label-class="mt-2 mb-1">
              <b-form-radio-group v-model="pd.schoolStatus" :options="schoolStatus" disabled />
            </b-form-group>
          </div>
        </b-card>
      </b-col>
    </b-row>

    <b-button
      variant="primary"
      v-b-toggle.sidebar-add-edu
      type="button"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      @click="edu = {}"
      >{{ $t("Add New") }}</b-button
    >
  </div>
</template>

<script>
  import flatPickr from "vue-flatpickr-component"
  import vSelect from "vue-select"
  import { ref } from "@vue/composition-api"
  import PlayerEducationNew from "./PlayerEducationNew.vue"
  import Cleave from "vue-cleave-component"
  // eslint-disable-next-line import/no-extraneous-dependencies
  import "cleave.js/dist/addons/cleave-phone.us"
  import i18n from "@/libs/i18n"

  export default {
    components: {
      vSelect,
      flatPickr,
      PlayerEducationNew,
      Cleave,
    },
    props: {
      playerData: {
        default: null,
      },
      submitEdu: {
        default: null,
      },
      submitHandle: {
        default: null,
      },
      updateEdu: {
        default: null,
      },
      deleteEdu: { default: null },
    },
    data() {
      return {
        edu: null,
        isAddEduActive: false,
      }
    },
    methods: {
      goSidebarContact(playerEduData) {
        this.edu = {
          id: null,
          schoolName: null,
          schoolLevel: null,
          schoolStatus: null,
          schoolPhone: null,
          schoolLevelClass: null,
        }
        this.edu.id = playerEduData.id
        this.edu.schoolName = playerEduData.schoolName
        this.edu.schoolLevel = playerEduData.schoolLevel
        this.edu.schoolStatus = playerEduData.schoolStatus
        this.edu.schoolPhone = playerEduData.schoolPhone
        this.edu.schoolLevelClass = playerEduData.schoolLevelClass
        this.isAddEduActive = true
      },
      goDeleteEdu(eduId) {
        this.$bvModal
          .msgBoxConfirm(this.$t("Please confirm that you want to delete this."), {
            title: this.$t("Please Confirm"),
            size: "sm",
            okVariant: "primary",
            okTitle: this.$t("Yes"),
            cancelTitle: this.$t("No"),
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          })
          .then((value) => {
            if (value) {
              this.deleteEdu(eduId)
            }
          })
      },
    },
    beforeMount() {},
    setup(props, { root }) {
      const languageOptions = ["Türkçe", "English", "Spanish", "French", "Russian", "German"]

      const options = {
        creditCard: {
          creditCard: true,
        },
        date: {
          date: true,
          delimiter: "/",
          datePattern: ["d", "m", "Y"],
        },
        phone: {
          phone: true,
          phoneRegionCode: "US",
        },
        time: {
          time: true,
          timePattern: ["h", "m", "s"],
        },
        number: {
          numeral: true,
          numeralThousandsGroupStyle: "thousand",
        },
        block: {
          blocks: [4, 3, 3],
          uppercase: true,
        },
        delimiter: {
          delimiter: "·",
          blocks: [3, 3, 3],
          uppercase: true,
        },
        customDelimiter: {
          delimiters: [".", ".", "-"],
          blocks: [3, 3, 3, 2],
          uppercase: true,
        },
        prefix: {
          prefix: "+63",
          blocks: [3, 3, 3, 4],
          uppercase: true,
        },
      }

      const schoolStatus = [
        { text: i18n.t("Continue"), value: "continue" },
        { text: i18n.t("Completed"), value: "completed" },
        { text: i18n.t("left"), value: "left" },
      ]

      const contactOptionsOptions = ["Email", "Message", "Phone"]

      return {
        languageOptions,
        schoolStatus,
        contactOptionsOptions,
      }
    },
  }
</script>

<style lang="scss">
  @import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
