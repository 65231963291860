<template>
  <div>
    <b-card>
      <b-row>
        <b-col xl="5">
          <div class="my-3">
            <h3 class="mb-1">{{ $t("Staff History") }}</h3>
            <div v-if="history.length > 0">
              <app-timeline v-for="item in history" :key="item.id">
                <app-timeline-item :img="item.team.club.logo">
                  <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                    <h6>{{ item.season.name }}</h6>
                    <small class="text-muted">{{ item.roleEndDate | longDate }}</small>
                  </div>
                  <p>{{ item.team.name }}</p>
                </app-timeline-item>
              </app-timeline>
            </div>

            <div v-else>
              <span> {{ $t("No records found") }}</span>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
  import { useUtils as useI18nUtils } from "@core/libs/i18n"
  import AppTimeline from "@core/components/app-timeline/AppTimeline.vue"
  import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue"
  import { ref, onUnmounted, onMounted } from "@vue/composition-api"
  import i18n from "@/libs/i18n"

  export default {
    components: {
      AppTimeline,
      AppTimelineItem,
    },
    props: {
      userData: {
        type: [Array, Object, null],
        default: null,
      },
      history: {
        type: [Array, Object, null],
        default: null,
      },
    },

    setup(props, { root }) {
      const { t } = useI18nUtils()

      return {}
    },
  }
</script>

<style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
</style>
