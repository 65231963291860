export default [
  {
    header: "Forms & Tables",
    icon: "EditIcon",
    children: [
      {
        title: "Forms Elements",
        icon: "CopyIcon",
        children: [
          {
            title: "Input",
            route: "forms-element-input",
          },
          {
            title: "Input Group",
            route: "forms-element-input-group",
          },
          {
            title: "Input Mask",
            route: "forms-element-input-mask",
          },
          {
            title: "Textarea",
            route: "forms-element-textarea",
          },
          {
            title: "Auto Suggest",
            route: "extensions-auto-suggest",
          },
          {
            title: "Checkbox",
            route: "forms-element-checkbox",
          },
          {
            title: "Radio",
            route: "forms-element-radio",
          },
          {
            title: "Switch",
            route: "forms-element-switch",
          },
          {
            title: "Select",
            route: "forms-element-select",
          },
          {
            title: "Vue Select",
            route: "extensions-vue-select",
          },
          {
            title: "Spinbutton",
            route: "forms-element-spinbutton",
          },
          {
            title: "File Input",
            route: "forms-element-file-input",
          },
          {
            title: "Quill Editor",
            route: "extensions-quill-editor",
          },
          {
            title: "Form Datepicker",
            route: "forms-element-datepicker",
          },
          {
            title: "Form Timepicker",
            route: "forms-element-timepicker",
          },
          {
            title: "Date Time Picker",
            route: "extensions-date-time-picker",
          },
          {
            title: "Form Rating",
            route: "forms-element-rating",
          },
          {
            title: "Form Tag",
            route: "forms-element-tag",
          },
        ],
      },
      {
        title: "Form Layout",
        route: "form-layout",
        icon: "CopyIcon",
      },
      {
        title: "Form Wizard",
        route: "form-wizard",
        icon: "PackageIcon",
      },
      {
        title: "Form Validation",
        route: "form-validation",
        icon: "CheckCircleIcon",
      },
      {
        title: "Form Repeater",
        route: "form-repeater",
        icon: "CopyIcon",
      },
      {
        title: "BS Table",
        route: "table-bs-table",
        icon: "ServerIcon",
      },
      {
        title: "Good Table",
        route: "table-good-table",
        icon: "GridIcon",
      },
    ],
  },
]
