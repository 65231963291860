<template>
  <!-- TODO : TRANSLATE -->
  <div>
    <div class="card-header p-0 m-0 mb-2">
      <div>
        <b-card-title>
          <feather-icon icon="UserIcon" size="24" />
          {{ $t("Coach Review") }}
        </b-card-title>
      </div>
    </div>
    <div class="alert alert-warning p-2" v-if="competition.homeTeam.team.teamPlayers.length == 0">
      <p>{{ $t("Not found any player") }}</p>
    </div>
    <div class="table-responsive">
      <table class="table table-bordered" v-if="competition.homeTeam.team.teamPlayers.length !== 0">
        <thead>
          <tr>
            <td>{{ $t("Player") }}</td>
            <td v-b-tooltip.hover :title="$t('Yellow Card')">
              <img :src="require('@/assets/images/icons/yellow-card.png')" height="32" alt />
            </td>
            <td v-b-tooltip.hover :title="$t('Red Card from 2nd Yellow')">
              <img :src="require('@/assets/images/icons/yellow-red-card.png')" height="32" alt />
            </td>
            <td v-b-tooltip.hover :title="$t('Red Card')">
              <img :src="require('@/assets/images/icons/red-card.png')" height="32" alt />
            </td>
            <td v-b-tooltip.hover :title="$t('Goal!')">
              <img :src="require('@/assets/images/icons/ball.svg')" height="32" alt />
            </td>
            <td v-b-tooltip.hover :title="$t('Coach Review')">
              <feather-icon icon="UserIcon" size="32"></feather-icon>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(pl, index) in getMainSubs(competition.homeTeam.team.teamPlayers)" :key="index">
            <td>
              <div class="d-flex justify-content-start">
                <div>
                  <b-avatar :text="avatarText(pl.name)" :src="pl.avatar ? pl.avatar : null"></b-avatar>
                </div>
                <div class="ml-1">
                  {{ pl.name }}
                  <br />
                  {{ $t(pl.position.name) }}
                </div>
              </div>
            </td>
            <td class>
              <span class="badge badge-warning" v-b-tooltip.hover :title="`Sarı Kart`" v-if="pl.stats.yellowCard">
                {{ pl.stats.yellowCard }}
              </span>
            </td>
            <td class>
              <span
                class="badge badge-warning"
                v-b-tooltip.hover
                :title="`Sarıdan Kırmızı`"
                v-if="pl.stats.yellowRedCard"
              >
                {{ pl.stats.yellowRedCard }}
              </span>
            </td>
            <td class>
              <span class="badge badge-warning" v-b-tooltip.hover :title="`Kırmızı Kart`" v-if="pl.stats.redCard">
                {{ pl.stats.redCard }}
              </span>
            </td>
            <td class>
              <div class="d-flex justify-content-between">
                <div style="align-self: center">
                  <span
                    class="badge badge-primary ml-1"
                    v-b-tooltip.hover
                    :title="`${playerGoals.dk}. dk (${playerGoals.type})`"
                    v-for="(playerGoals, plgIndex) in pl.stats.goals"
                    :key="plgIndex"
                    >{{ playerGoals.dk }}</span
                  >
                </div>
              </div>
            </td>
            <td class>
              <b-input-group>
                <b-form-rating
                  variant="warning"
                  :stars="getSetting('star')"
                  readonly
                  @change="helperFuncs.submitFormationCoach(pl, competition.homeTeam.team.id, pl.coachReview)"
                  v-model="pl.coachReview"
                  show-value
                ></b-form-rating>
              </b-input-group>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
  import { avatarText } from "@core/utils/filter"
  export default {
    components: {},
    name: "CompetitionAddCoachReview",
    props: {
      competition: null,
      helper: null,
      showSection: null,
      helperFuncs: null,
    },
    data() {
      return {
        isLoading: false,
      }
    },
    methods: {
      goSubmitEvent(object) {
        this.helper.submitEventForm(object)
      },
      getMainSubs(Players) {
        return Object.values(Players).filter((pl) => {
          if (
            pl.isStarterPlayer !== undefined &&
            (pl.isStarterPlayer == 0 || pl.isStarterPlayer == 1) &&
            pl.coachReview
          ) {
            return pl
          }
        })
      },
    },
    computed: {},
    async beforeMount() {
      this.competition.formationTrigger = 0
    },
    created() {},
    setup(props, { root }) {
      return {
        avatarText,
      }
    },
  }
</script>
<style>
  input.formationSelect {
    width: 30px;
    height: 30px;
  }

  .add-match-formation .nav-pills .nav-link.active {
    /* background: unset; */
  }
</style>
