<template>
  <b-sidebar
    id="sidebar-learning-outcome"
    :visible="isActive"
    bg-variant="white"
    @shown="$emit('update:isActive', true)"
    @hidden="
      $emit('update:isActive', false)
      formTrigger++
    "
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
  >
    <template #default="{ hide }">
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">{{ $t("Training Exercices") }}</h5>
        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver" :key="formTrigger">
        <!-- Form -->
        <b-form v-if="formData" class="p-2" @submit.prevent="handleSubmit(goSubmit())">
          <!-- Test Item Name -->
          <validation-provider #default="{ errors }" name="name" rules="required">
            <b-form-group :label="$t('Name')" label-for="name">
              <b-form-input
                id="name"
                style="text-transform: uppercase"
                v-model="formData.name"
                :state="errors[0] ? false : null"
              />

              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Category -->
          <validation-provider #default="{ errors }" name="category" rules="required">
            <b-form-group :label="$t('Category')" label-for="category" rules="required">
              <v-select
                id="category"
                v-model="formData.learningOutcomeCategory"
                :options="categories"
                :reduce="(option) => option.id"
                :getOptionLabel="(option) => option.name"
              >
              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" type="submit">{{
              $t("Add")
            }}</b-button>

            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              ref="hideClick"
              @click="hide"
              >{{ $t("Cancel") }}</b-button
            >
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>
<script>
  import { ValidationProvider, ValidationObserver } from "vee-validate"
  import { ref, onMounted, watch } from "@vue/composition-api"
  import { required } from "@validations"
  import Ripple from "vue-ripple-directive"
  import flatPickr from "vue-flatpickr-component"
  import vSelect from "vue-select"
  import i18n from "@/libs/i18n"
  import axios from "@axios"

  export default {
    components: {
      flatPickr,
      vSelect,

      // Form Validation
      ValidationProvider,
      ValidationObserver,
    },
    directives: {
      Ripple,
    },
    model: {
      prop: "isActive",
      event: "update:is-outcome-active",
    },
    props: {
      isActive: {
        type: Boolean,
        require: true,
      },
      submit: {
        required: true,
      },
      update: {
        require: true,
      },
      formData: {
        default: null,
      },
    },
    data() {
      return {
        required,
        formTrigger: 0,
      }
    },
    methods: {
      goSubmit() {
        this.$refs.refFormObserver.validate().then((success) => {
          if (success) {
            if (this.formData.id) {
              this.formData.learningOutcomeCategory =
                this.formData.learningOutcomeCategory.id ?? this.formData.learningOutcomeCategory
              this.update(this.formData)
            } else {
              this.submit(this.formData)
            }
            this.$refs.hideClick.click()
          }
        })
      },
    },
    setup(props) {
      const categories = ref([])

      onMounted(async () => {
        await getCategories()
      })

      // watch props isActive
      watch(
        () => props.isActive,
        (val) => {
          if (val) {
            getCategories()
          }
        },
      )

      const getCategories = () => {
        axios.get("/learning-outcome-category").then((response) => {
          categories.value = response.data
        })
      }

      return {
        categories,
      }
    },
  }
</script>

<style lang="scss">
  @import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
