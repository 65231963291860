import axios from "@axios"

export default {
  namespaced: true,
  state: {
    loading: false,
  },
  getters: {},
  mutations: {
    // TEAMS
    ADD_TRAINING_INITIAL_DATA(state, data) {
      // state.teams.unshift(item)
      state.trainingInitialData = data
    },
    ADD_BLOCK(state, item) {
      // state.teams.unshift(item)
      state.blocks.unshift(item)
    },

    SET_TEAM(state, itemData) {
      // state.teams = itemData
    },
    UPDATE_TEAM(state, item) {
      // const itemIndex = state.teams.findIndex((i) => i.id === item.id)
      // Object.assign(state.teams[itemIndex], item)
    },
    REMOVE_TEAM(state, itemId) {
      // const ItemIndex = state.teams.findIndex((i) => i.id === itemId)
      // state.teams.splice(ItemIndex, 1)
    },

    CHANGE_LOADING(state, status) {
      state.loading = status
    },
  },

  actions: {
    getTraining({ commit }, itemId) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/training/${itemId}`)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => reject(error))
      })
    },

    addTrainingInitialData({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`team/${data.teamId}/training`, data.form)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => reject(error))
      })
    },
    saveTraining({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/training/${data.trainingId}`, data.form)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => reject(error))
      })
    },

    addNewBlock({ commit }, form) {
      return new Promise((resolve, reject) => {
        axios
          .post("/training-block", form)
          .then((response) => {
            resolve(response.data)
          })
          .catch((error) => reject(error))
      })
    },
  },
}
