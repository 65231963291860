<template>
  <b-card>
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <!-- form -->
      <b-form @submit.prevent="handleSubmit(goSubmitPassword())">
        <b-row>
          <!-- old password -->
          <b-col md="6">
            <validation-provider #default="{ errors }" rules="required|min:4" name="currentPassword">
              <b-form-group :label="$t('Current Password')" label-for="account-old-password">
                <b-input-group class="input-group-merge" :class="errors[0] ? 'is-invalid' : null">
                  <b-form-input
                    id="account-old-password"
                    v-model="passwordValueOld"
                    name="old-password"
                    :type="passwordFieldTypeOld"
                    :placeholder="$t('Current Password')"
                    :state="errors[0] ? false : null"
                  />
                  <b-input-group-append is-text>
                    <feather-icon :icon="passwordToggleIconOld" class="cursor-pointer" @click="togglePasswordOld" />
                  </b-input-group-append>
                </b-input-group>

                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!--/ old password -->
        </b-row>
        <b-row>
          <!-- new password -->
          <b-col md="6">
            <validation-provider #default="{ errors }" rules="required|min:4" vid="newPassword" name="newPassword">
              <b-form-group label-for="account-new-password" :label="$t('New Password')">
                <b-input-group class="input-group-merge" :class="errors[0] ? 'is-invalid' : null">
                  <b-form-input
                    id="account-new-password"
                    v-model="newPasswordValue"
                    :type="passwordFieldTypeNew"
                    name="new-password"
                    :placeholder="$t('New Password')"
                    :state="errors[0] ? false : null"
                  />
                  <b-input-group-append is-text>
                    <feather-icon :icon="passwordToggleIconNew" class="cursor-pointer" @click="togglePasswordNew" />
                  </b-input-group-append>
                </b-input-group>

                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!--/ new password -->

          <!-- retype password -->
          <b-col md="6">
            <validation-provider #default="{ errors }" rules="required|confirmed:newPassword" name="retypeNewPassword">
              <b-form-group label-for="account-retype-new-password" :label="$t('Re Type NewPassword')">
                <b-input-group class="input-group-merge" :class="errors[0] ? 'is-invalid' : null">
                  <b-form-input
                    id="account-retype-new-password"
                    v-model="RetypePassword"
                    :type="passwordFieldTypeRetype"
                    name="retype-password"
                    :state="errors[0] ? false : null"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconRetype"
                      class="cursor-pointer"
                      @click="togglePasswordRetype"
                    />
                  </b-input-group-append>
                </b-input-group>

                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!--/ retype password -->

          <!-- buttons -->
          <b-col cols="12">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mt-1 mr-1" type="submit">
              {{ $t("Save changes") }}
            </b-button>
            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="reset" variant="outline-secondary" class="mt-1">
              {{ $t("Reset") }}
            </b-button>
          </b-col>
          <!--/ buttons -->
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
  import {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
  } from "bootstrap-vue"
  import Ripple from "vue-ripple-directive"
  import { ValidationProvider, ValidationObserver } from "vee-validate"
  import { required } from "@validations"

  export default {
    components: {
      BButton,
      BForm,
      BFormGroup,
      BFormInput,
      BRow,
      BCol,
      BCard,
      BInputGroup,
      BInputGroupAppend,

      // Form Validation
      ValidationProvider,
      ValidationObserver,
    },
    directives: {
      Ripple,
    },
    props: {
      updatePassword: {
        default: null,
      },
    },
    data() {
      return {
        required,
        passwordValueOld: "",
        newPasswordValue: "",
        RetypePassword: "",
        passwordFieldTypeOld: "password",
        passwordFieldTypeNew: "password",
        passwordFieldTypeRetype: "password",
      }
    },
    computed: {
      passwordToggleIconOld() {
        return this.passwordFieldTypeOld === "password" ? "EyeIcon" : "EyeOffIcon"
      },
      passwordToggleIconNew() {
        return this.passwordFieldTypeNew === "password" ? "EyeIcon" : "EyeOffIcon"
      },
      passwordToggleIconRetype() {
        return this.passwordFieldTypeRetype === "password" ? "EyeIcon" : "EyeOffIcon"
      },
    },
    methods: {
      togglePasswordOld() {
        this.passwordFieldTypeOld = this.passwordFieldTypeOld === "password" ? "text" : "password"
      },
      togglePasswordNew() {
        this.passwordFieldTypeNew = this.passwordFieldTypeNew === "password" ? "text" : "password"
      },
      togglePasswordRetype() {
        this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === "password" ? "text" : "password"
      },
      goSubmitPassword() {
        this.$refs.refFormObserver.validate().then((success) => {
          if (success) {
            this.updatePassword(this.passwordValueOld, this.newPasswordValue)
          }
        })
      },
    },
  }
</script>
