import { can } from "@/@core/libs/acl/utils"
import ability from "./ability"

// TODO: CAN NAVIGATE TO WITH by teamId AND seasonId
export const canNavigate = (to) => {
  return to.matched.some((route) => {
    return ability.can(route.meta.action || "read", route.meta.resource)
  })
}

export const _ = undefined
