import { render, staticRenderFns } from "./addMatchInfoBar.vue?vue&type=template&id=1ddae1fe&scoped=true&"
import script from "./addMatchInfoBar.vue?vue&type=script&lang=js&"
export * from "./addMatchInfoBar.vue?vue&type=script&lang=js&"
import style0 from "./addMatchInfoBar.vue?vue&type=style&index=0&id=1ddae1fe&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ddae1fe",
  null
  
)

export default component.exports