<template>
  <div>
    <div>
      <b-form ref="form" class="repeater-form">
        <!-- Row Loop -->
        <b-row v-for="(item, index) in addItems" :id="index" :key="index" ref="quickPlayerRow">
          <!-- Player Name -->
          <b-col md="4">
            <b-form-group :label="$t('Player Name')" label-for="item-name">
              <b-form-input id="item-name" type="text" :placeholder="$t('Player Name')" />
            </b-form-group>
          </b-col>

          <!-- Player Age -->
          <b-col md="2">
            <b-form-group :label="$t('Age')" label-for="cost">
              <b-form-input id="age" type="number" placeholder="14" />
            </b-form-group>
          </b-col>

          <!-- Player Foot -->
          <b-col md="3">
            <b-form-group :label="$t('Strong Foot')" label-for="foot">
              <b-form-select
                :placeholder="$t('Strong foot')"
                id="foot"
                value-field="id"
                text-field="label"
                :value="0"
                :options="footOptions"
              ></b-form-select>
            </b-form-group>
          </b-col>

          <!-- Actions Button -->
          <b-col md="2" class="mb-50">
            <div class="d-flex justify-content-between">
              <b-button variant="outline-danger" class="mt-0 mt-md-2 mr-1 ml-2" @click="deleteItem(index)">
                <feather-icon icon="XIcon" class="mr-25" />
              </b-button>
              <b-button @click="saveItem(index)" variant="outline-success" class="mt-0 mt-md-2">
                <span>{{ $t("Save") }}</span>
              </b-button>
            </div>
          </b-col>
          <b-col cols="12">
            <hr />
          </b-col>
        </b-row>
      </b-form>
    </div>
  </div>
</template>
<script>
  import { ref } from "@vue/composition-api"
  import vSelect from "vue-select"
  import i18n from "@/libs/i18n"
  import ToastificationContent from "../../../@core/components/toastification/ToastificationContent"
  export default {
    components: { vSelect },
    props: { teamId: null, reloadTeams: null },
    methods: {
      saveItem(index) {
        let saveObject = {}
        let findRow = this.$refs.quickPlayerRow[index]
        saveObject.name = findRow.querySelector("#item-name").value
        saveObject.foot = findRow.querySelector("#foot").value
        saveObject.age = findRow.querySelector("#age").value
        this.submitQuickPlayer({ index, team: this.teamId, ...saveObject }).then((response) => {
          findRow.querySelector("#item-name").value = ""
          findRow.querySelector("#foot").value = ""
          findRow.querySelector("#age").value = ""
          this.reloadTeams()
        })
      },
    },
    setup(props, { root }) {
      const submitQuickPlayer = function ({ index, team, name, foot, age }) {
        this.playerSendForm = {
          name: name,
          avatar: "user.png",
          teamId: team,
          foot: Number(foot),
          birthdate: new Date(new Date().setFullYear(new Date().getFullYear() - Number(age))),
          email: "quickinsert@client.com",
          phone: "555 555 55 55",
          position: 1,
        }
        return this.$http.post("/player", this.playerSendForm).then((succ) => {
          this.loading = false
          let newPlayerId = succ.data.id
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("Player saved successfully"),
              icon: "UserIcon",
              variant: "success",
            },
          })

          return new Promise((resolve) => {
            return resolve(succ.data)
          })
        })
      }

      const addItems = ref([{ id: null }])

      const addItemNew = function () {
        addItems.value.push({ id: null })
      }

      const deleteItem = function (index) {
        addItems.value.splice(index, 1)
      }

      return {
        addItems,
        addItemNew,
        deleteItem,
        submitQuickPlayer,
      }
    },
  }
</script>
