<template>
  <div>
    <div class="card-header p-0 m-0 mb-2">
      <div>
        <b-card-title>
          <feather-icon icon="SettingsIcon" size="24" />
          {{ $t("Team Line Up") }}
        </b-card-title>
      </div>
    </div>
    <div class="row" style="min-height: calc(50vh + 250px)">
      <b-tabs
        style="width: 100%"
        content-class="mt-1"
        pills
        nav-wrapper-class="col-md-12 col-12 add-match-formation"
        active-tab-class="w-100"
        nav-class="nav-left"
        lazy
      >
        <b-tab active>
          <template #title>
            <span class="d-sm-inline">
              <b-avatar :src="competitionForm.homeTeam.select.club.logo" style="background: unset"></b-avatar>
              {{ competitionForm.homeTeam.select.club.name }}
              {{ competitionForm.homeTeam.select.name }}
            </span>
          </template>
          <add-match-formation-table
            :helperFuncs="helperFuncs"
            :teamProps="competition.homeTeam"
            :competition="competition"
            :competitionForm="competitionForm"
            :avatarText="avatarText"
          />
        </b-tab>
        <b-tab>
          <template #title>
            <span class="d-sm-inline">
              <b-avatar :src="competitionForm.awayTeam.select.club.logo" style="background: unset"></b-avatar>
              {{ competitionForm.awayTeam.select.club.name }}
              {{ competitionForm.awayTeam.select.name }}
            </span>
          </template>
          <add-match-formation-table
            :helperFuncs="helperFuncs"
            :teamProps="competition.awayTeam"
            :competition="competition"
            :competitionForm="competitionForm"
            :avatarText="avatarText"
          />
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>
<script>
  import { avatarText } from "@core/utils/filter"
  import AddMatchFormationTable from "./addMatchFormationTable.vue"
  export default {
    components: { AddMatchFormationTable },
    name: "CompetitionFormationEdit",
    props: {
      competitionForm: null,
      competition: null,
      helperFuncs: null,
      showSection: null,
    },
    data() {
      return {
        compFilter: "all",
      }
    },
    computed: {},
    async created() {
      /**
       * get competition formations
       * set competition teams...
       */
    },
    setup(props, { root }) {
      return {
        avatarText,
      }
    },
  }
</script>
<style>
  input.formationSelect {
    width: 30px;
    height: 30px;
  }

  .add-match-formation .nav-pills .nav-link.active {
    /* background: unset; */
  }
</style>
