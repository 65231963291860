import axios from "@axios"

export default {
  namespaced: true,
  state: {
    teams: [],
    players: [],
  },
  getters: {
    teams: (state) => state.teams,
    players: (state) => state.players,
    playersByBranc: (state) =>
      // let branchKey = {}
      // let branchFill = Object.values(getters.players).reduce((acc, curr) => {
      //   // console.log(curr.position.name)
      //   if (!branchKey[curr.position.name]) branchKey[curr.position.name] = []
      //   if (branchKey[curr.position.name]) branchKey[curr.position.name].push(curr)
      // }, {})

      state.players,
  },
  mutations: {
    // TEAMS
    ADD_DATA(state, item) {
      state.teams.unshift(item)
    },
    SET_DATA(state, itemData) {
      state.teams = itemData
    },
    UPDATE_DATA(state, item) {
      const itemIndex = state.teams.findIndex((i) => i.id === item.id)
      Object.assign(state.teams[itemIndex], item)
    },
    REMOVE_DATA(state, itemId) {
      const ItemIndex = state.teams.findIndex((i) => i.id === itemId)
      state.teams.splice(ItemIndex, 1)
    },

    // PLAYERS
    ADD_PLAYER(state, item) {
      state.players.unshift(item)
    },
    SET_PLAYER(state, itemData) {
      state.players = itemData
    },
    UPDATE_PLAYER(state, item) {
      const itemIndex = state.players.findIndex((i) => i.id === item.id)
      Object.assign(state.players[itemIndex], item)
    },
    REMOVE_PLAYER(state, itemId) {
      const ItemIndex = state.players.findIndex((i) => i.id === itemId)
      state.players.splice(ItemIndex, 1)
    },
  },
  actions: {
    fetchDatas({ commit }, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get("https://602b8a2fef26b40017f145a3.mockapi.io/api/teams", {
            params: queryParams,
          })
          .then((response) => {
            resolve(response)
            commit("SET_DATA", response.data)
          })
          .catch((error) => reject(error))
      })
    },
    fetchData({ commit }, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`https://602b8a2fef26b40017f145a3.mockapi.io/api/teams/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    deleteData({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`https://602b8a2fef26b40017f145a3.mockapi.io/api/teams/${id}`)
          .then((response) => {
            commit("REMOVE_DATA", id)
            resolve("")
          })
          .catch((error) => reject(error))
      })
    },
    updateData({ commit }, { id, userData }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`https://602b8a2fef26b40017f145a3.mockapi.io/api/teams/${id}`, userData)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    addData({ commit }, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post("https://602b8a2fef26b40017f145a3.mockapi.io/api/teams", userData)
          .then((response) => {
            resolve(response)
            commit("ADD_DATA", response.data)
          })
          .catch((error) => reject(error))
      })
    },

    fetchTeamPlayers({ commit }, { seasonId, teamId }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/season/${seasonId}/team/${teamId}`)
          .then((response) => {
            resolve(response)
            // console.log(response.data.players)
            commit("SET_PLAYER", response.data.players)
          })
          .catch((error) => reject(error))
      })
    },
  },
}
