<template>
  <div class="app-calendar overflow-hidden border">
    <div class="row no-gutters">
      <!-- Sidebar -->
      <div
        v-if="withSidebar == true"
        class="col app-calendar-sidebar flex-grow-0 overflow-hidden d-flex flex-column"
        :class="{ show: isCalendarOverlaySidebarActive }"
      >
        <calendar-sidebar
          :fetchEvents="refetchEvents"
          :is-event-handler-sidebar-active.sync="isEventHandlerSidebarActive"
        />
      </div>

      <!-- Calendar -->
      <div class="col position-relative">
        <div class="card shadow-none border-0 mb-0 rounded-0">
          <div class="card-body pb-0">
            <div class="custom-date-picker-for-calendar">
              <selectable-date
                hide-day="hide"
                class=""
                style="position: absolute; width: 280px; height: 40px; margin-left: 12rem; margin-top: 0.7rem"
                v-model="customDatePicker"
              />
            </div>
            <full-calendar ref="refCalendar" :options="calendarEditedOptions" class="full-calendar mt-3 mt-md-0" style="zoom: 0.8">
              <template v-slot:eventContent="arg">
                <!-- EVENT TRAINING -->
                <router-link v-if="arg.event.extendedProps.calendar =='training'" target="_blank" :to="generateRouterLink(arg)"
                  :style="{width: [ 'timeGridWeek'].includes(arg.view.type) ? '300px !important': ''}" class="w-100"
                >
                  <div
                    :class="`rounded w-100 h-100 border-${
                      $store.state.calendar.teams.find((d) => d.id == arg.event.extendedProps.eventTeamId).color
                    }`"
                    style="min-height: 4.5rem; padding: 0.6rem;"
                  >
                    <div class="d-flex w-100 h-100">
                      <div class="d-flex flex-column w-100">
                        <span
                          class="font-weight-bolder"
                          style="flex: none; word-break: break-word; font-size:0.7rem; min-height: 0.7rem; white-space: pre-line; width: 100%; -webkit-line-clamp: 2; display: -webkit-box; -webkit-box-orient: vertical; overflow: hidden;"
                        >
                          {{ arg.event.title.trim() }}
                        </span>

                        <small v-if="arg.view.type != 'timeGridWeek'" class="font-small-1 w-100 overflow-hidden d-block" style="">
                          {{ arg.event.extendedProps.description }}
                        </small>

                        <div class="icon d-flex align-items-end overflow-hidden" style="flex-grow:1; min-height: 1rem;">
                          <div class="d-flex">
                            <img
                              :src="require(`@/assets/images/icons/${arg.event.extendedProps.calendar}.png`)"
                              alt="icon"
                              height="16"
                            />
                            <span class="d-block w-100 text-black font-weight-bolder text-nowrap" style="margin-left: 5px">{{
                              arg.timeText.length != 2 ? arg.timeText : arg.timeText + ":00"
                            }}</span>
                            <span class="d-block w-100 d-flex" v-if="arg.event.extendedProps.homeTeam" style="gap: 5px;margin-top:-3px;margin-left:10px">
                              <img :src="arg.event.extendedProps.homeTeam" style="height:20px">
                              <FeatherIcon icon="HomeIcon" v-if="arg.event.extendedProps.competitionSide==='home'"   size="18"/>
                              <FeatherIcon icon="SendIcon" v-if="arg.event.extendedProps.competitionSide==='away'"   size="18"/>
                              <img :src="arg.event.extendedProps.awayTeam" style="height:20px">
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </router-link>
                <!-- EVENT COMPETITION -->
                <router-link v-else target="_blank" :to="generateRouterLink(arg)" class="w-100"
                  :style="{ height: arg.event.extendedProps.calendar == 'competition' ? '8.5rem' : '7rem' }">
                  <div :style="{ height: arg.event.extendedProps.calendar == 'competition' ? '8.5rem' : '7rem' }" :class="`rounded w-100 border-${$store.state.calendar.teams.find((d) => d.id == arg.event.extendedProps.eventTeamId).color}`"
                    style="min-height: 4.5rem; padding: 0.6rem;">
                    <div class="d-flex w-100">
                      <div class="w-100">
                        <span class="w-100 d-flex justify-content-center overflow-hidden" v-if="arg.event.extendedProps.homeTeam" style="gap:5px">
                          <img :src="arg.event.extendedProps.homeTeam" style="height:20px">
                          <span> {{
                            arg.timeText.length != 2 ? arg.timeText : arg.timeText + ":00"
                          }} </span>
                          <img :src="arg.event.extendedProps.awayTeam" style="height:20px">
                        </span>
                        <span class="font-weight-bolder mt-2"
                          style="word-break: break-word; font-size:0.7rem; min-height: 0.7rem; white-space: pre-line; width: 100%; display: block; -webkit-line-clamp: 2; display: -webkit-box; -webkit-box-orient: vertical; overflow: hidden;">
                          {{ arg.event.title.trim() }}
                        </span>
                        <small v-if="arg.view.type != 'timeGridWeek'" class="font-small-1 w-100 overflow-hidden d-block mb-2">
                          {{ arg.event.extendedProps.description }}
                        </small>
                      </div>
                    </div>
                  </div>
                </router-link>
              </template>
            </full-calendar>
          </div>
        </div>
      </div>

      <!-- Sidebar Overlay -->
      <div
        class="body-content-overlay"
        :class="{ show: isCalendarOverlaySidebarActive }"
        @click="isCalendarOverlaySidebarActive = false"
      />
      <calendar-event-handler
        v-if="false"
        v-model="isEventHandlerSidebarActive"
        :event="event"
        :clear-event-data="clearEventData"
        @remove-event="removeEvent"
        @add-event="addEvent"
        @update-event="updateEvent"
      />
    </div>
  </div>
</template>

<script>
  import FullCalendar from "@fullcalendar/vue"
  import store from "@/store"
  import { onUnmounted, ref, watch } from "@vue/composition-api"
  import calendarStoreModule from "./calendarStoreModule"
  import CalendarSidebar from "./calendar-sidebar/CalendarSidebar.vue"
  import CalendarEventHandler from "./calendar-event-handler/CalendarEventHandler.vue"
  import useCalendar from "./useCalendar"
  import SelectableDate from "@core/components/global-components/SelectableDate.vue"
  import { useRouter } from "@core/utils/utils"

  export default {
    components: {
      SelectableDate,
      FullCalendar, // make the <FullCalendar> tag available
      CalendarSidebar,
      CalendarEventHandler,
    },
    props: {
      withSidebar: { default: true },
    },
    setup() {
      const eventColors = {
        competition: "primary",
        training: "success",
      }

      const CALENDAR_APP_STORE_MODULE_NAME = "calendar"

      // Register module
      if (!store.hasModule(CALENDAR_APP_STORE_MODULE_NAME))
        store.registerModule(CALENDAR_APP_STORE_MODULE_NAME, calendarStoreModule)

      // UnRegister on leave
      onUnmounted(() => {
        if (store.hasModule(CALENDAR_APP_STORE_MODULE_NAME)) store.unregisterModule(CALENDAR_APP_STORE_MODULE_NAME)
      })

      const {
        refCalendar,
        isCalendarOverlaySidebarActive,
        event,
        clearEventData,
        addEvent,
        updateEvent,
        removeEvent,
        fetchEvents,
        refetchEvents,
        changeDate,
        calendarOptions,
        calendarEditedOptions,

        // ----- UI ----- //
        isEventHandlerSidebarActive,
      } = useCalendar()

      const customDatePicker = ref("")

      watch(customDatePicker, (val) => {
        let dt = new Date(val)
        let dtToStringYmd = val.replace(/\//g, "-")
        store.commit("calendar/SET_DATE", dtToStringYmd)
        changeDate(dt)
      })

      const generateRouterLink = (calendar) => {
        let calendarType = calendar.event.extendedProps.calendar
        let ext = calendar.event.extendedProps
        if (calendarType == "competition") {
          return {
            name: "competition-edit",
            params: { id: ext.eventTeamId, seasonId: ext.seasonId, competitionId: ext.eventTypeId },
          }
        } else if (calendarType == "training") {
          return {
            name: "team-training-preview",
            params: { id: ext.eventTeamId, seasonId: ext.seasonId, trainingId: ext.eventTypeId },
          }
        }
        return "/calendar"
      }

      fetchEvents()

      return {
        generateRouterLink,
        refCalendar,
        isCalendarOverlaySidebarActive,
        event,
        clearEventData,
        addEvent,
        updateEvent,
        removeEvent,
        fetchEvents,
        refetchEvents,
        customDatePicker,
        eventColors,
        calendarOptions,
        calendarEditedOptions,

        // ----- UI ----- //
        isEventHandlerSidebarActive,
      }
    },
    data() {
      return {
        monthNames: [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ],
        calendarAppTitle: "Team Plans",
        calendarAppMonthName: "",
      }
    },
    methods: {
      goFullCalendar() {
        this.$router.push({ name: "dashboard-calendar" })
      },
    },
    created() {
      this.calendarAppMonthName = this.monthNames[new Date().getMonth()]
    },
    mounted() {},
  }
</script>

<style lang="scss">
  @import "@core/scss/vue/apps/calendar.scss";

  .calendarClick {
    cursor: pointer;
  }

  .fc-toolbar-title::first-letter {
    text-transform: uppercase;
  }
  
  .app-calendar .fc-sidebarToggle-button {
    border-color: unset;
    background-color: unset;
  }

  .app-calendar .fc-day-past .fc-daygrid-day-number,
  .app-calendar .fc-day-future .fc-daygrid-day-number {
    color: #333;
  }

  [dir] .fc-v-event {
    background-color: white;
    border: 0;
  }

  [dir] .fc-v-event a > div {
    background-color: white;
  }


  .fc-timegrid-col-events .fc-timegrid-event-harness:hover {
    z-index: 15 !important;
  }

  .fc-timegrid-col-frame:hover {
    z-index: 4;
  }

  .fc-event-main a:hover > div {
    background: #eee;
    min-width: 146px !important;
    transition: min-width 1s;
    z-index: 15;
  }
</style>
