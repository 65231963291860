import { ref, watch, computed, onBeforeMount } from "@vue/composition-api"

import { useToast } from "vue-toastification/composition"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
import store from "@/store"

export default function useTeamList() {
  const toast = useToast()

  const refTeamListTable = ref(null)

  const tableColumns = [
    {
      key: "id",
      label: "#",
      sortable: true,
    },
    {
      key: "avatar",
      sortable: true,
    },
    {
      key: "teamName",
      sortable: true,
    },
    {
      key: "branch",
      sortable: true,
    },
    {
      key: "staff",
      sortable: true,
    },
    {
      key: "actions",
    },
  ]

  const totalTeams = ref(null)
  const perPage = ref(localStorage.getItem("perPage") || 10)
  const totalItems = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref("")
  const sortBy = ref("id")
  const isSortDirDesc = ref(true)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refTeamListTable.value ? refTeamListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalItems.value,
    }
  })

  const refetchData = () => {
    fetchTeams()
  }

  const deleteItem = (id) => {
    store.dispatch("app-team/deleteData", id)
  }

  const tableItems = computed(() => store.state["app-team"].teams)
  const players = computed(
    () =>
      // console.log('OK')
      store.state["app-team"].members,
  )

  onBeforeMount(() => {
    fetchTeams()
  })

  const fetchTeams = (ctx, callback) => {
    store
      .dispatch("app-team/fetchDatas", {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        status: statusFilter.value,
      })
      .then((response) => {
        store.state["app-team"].teams = response.data
        const total = response.data.length

        totalTeams.value = total
      })
      .catch((err) => {
        console.log("ERR!", err)
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching users list",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        })
      })
  }

  watch([currentPage, perPage, searchQuery, statusFilter], () => {
    refetchData()
  })

  watch(perPage, (perPage) => {
    localStorage.setItem("perPage", perPage)
  })

  return {
    tableColumns,
    perPage,
    currentPage,
    totalItems,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refTeamListTable,
    statusFilter,
    refetchData,
    fetchTeams,
    tableItems,
    deleteItem,
    players,
  }
}
