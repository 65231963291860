<template>
  <ul id="main-menu-navigation" class="navbar-nav">
    <template v-if="showTeamMenuItems">
      <component :is="resolveNavComponent(item)" v-for="item in items" :key="item.header || item.title" :item="item" />
    </template>

    <template v-if="showDashboardMenuItems">
      <component
        :is="resolveNavComponent(item)"
        v-for="item in dashboardItems"
        :key="item.route || item.header || item.title"
        :item="item"
      />
    </template>
  </ul>
</template>

<script>
  import HorizontalNavMenuHeaderLink from "../horizontal-nav-menu-header-link/HorizontalNavMenuHeaderLink.vue"
  import HorizontalNavMenuHeaderGroup from "../horizontal-nav-menu-header-group/HorizontalNavMenuHeaderGroup.vue"
  import { ref, watch, computed, onBeforeMount } from "@vue/composition-api"
  import { useRouter } from "@core/utils/utils"
  import store from "@/store"

  export default {
    components: {
      HorizontalNavMenuHeaderLink,
      HorizontalNavMenuHeaderGroup,
    },
    props: {
      items: {
        type: Array,
        required: true,
      },
      dashboardItems: {
        type: Array,
        required: false,
        default: [],
      },
    },
    setup() {
      const { router, route } = useRouter()

      const showTeamMenuItems = ref(false)
      const showDashboardMenuItems = ref(false)
      const teamId = ref(null)
      const currentRouteName = ref(null)
      const season = store.getters["app/season"]

      const resolveNavComponent = (item) => {
        if (item.children) return "horizontal-nav-menu-header-group"
        return "horizontal-nav-menu-header-link"
      }

      const teamQuickMenu = (item) => {
        if (router.currentRoute.matched[0]) {
          let regExp = /^\/dashboard\/antrenor(.*)/
          let path = regExp.exec(router.currentRoute.matched[0].path)

          if (router.currentRoute.matched[0].path.includes(`/team/:id`)) {
            showTeamMenuItems.value = true
            showDashboardMenuItems.value = false
            teamId.value = router.currentRoute.params.id
            currentRouteName.value = router.currentRoute.name
          } else if (path != null && path.length > 0 && !["calender", "my-account", "scout"].includes(path[0])) {
            showDashboardMenuItems.value = true
            showTeamMenuItems.value = false
          } else {
            showDashboardMenuItems.value = false
            showTeamMenuItems.value = false
          }
        }
      }

      watch(route, (val) => {
        teamQuickMenu()
      })

      onBeforeMount(() => {
        teamQuickMenu()
      })

      return {
        resolveNavComponent,
        showTeamMenuItems,
        showDashboardMenuItems,
        teamId,
        season,
      }
    },
  }
</script>
