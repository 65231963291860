<template>
  <!-- TODO: TRANSLATE -->
  <div>
    <b-overlay :show="loading" rounded="sm">
      <!-- TODO: Background bg-white not working -->
      <div class="">
        <!-- Notifications -->
        <div class="tableScrollCustom NotifyTable">
          <b-table
            ref="selectableTable"
            selectable
            :select-mode="selectMode"
            sticky-header="400px"
            :items="items"
            :fields="fields"
            class="mb-0"
            @row-selected="onRowSelected"
          >
            <template #cell(selected)="{ rowSelected }">
              <template v-if="rowSelected">
                <i class="feather icon-disc primary" />
              </template>

              <template v-else>
                <i class="feather icon-circle" />
              </template>
            </template>
            <template #cell(id)="data">{{ data.index + 1 }}</template>

            <template #cell(avatar)="data">
              <b-avatar
                rounded
                size="48"
                :src="data.item.avatar"
                :variant="'light-primary'"
                :text="avatarText(data.item.full_name)"
              ></b-avatar>
            </template>

            <template #cell(email)="data">
              <span>{{ data.item.email }}</span>
            </template>

            <template #cell(position)="data">
              <span>{{ $t(data.item.position.name) }}</span>
            </template>

            <template #cell(status)>
              <FeatherIcon size="32" icon="MessageSquareIcon" />
              <FeatherIcon size="32" class="ml-1" icon="MailIcon" />
            </template>
          </b-table>
        </div>

        <div class="p-2">
          <!-- TODO : TRANSLATE -->
          <!--<div class="d-block mb-1">
            <div class="ml-2">
              <div class="row">
                <span
                  class="border bg-light rounded"
                  style="height: 37px; line-height: 1; margin-right: 1rem; padding: 0.7rem 1rem; margin-left: -3px"
                >
                  <feather-icon icon="BellIcon" size="18"></feather-icon>
                </span>
                <b-input type="number" min="1" v-model="notifyTimeValue" class="bg-light" placeholder="Süre" style="width: 50px"></b-input>
                <v-select
                  class="col-md-2"
                  :select="'Dakika'"
                  v-model="notifyTimeType"
                  :reduce="(option) => option.id"
                  :getOptionLabel="(option) => option.name"
                  :options="[
                    { id: 'H', name: $t('Hour') },
                    { id: 'D', name: $t('Day') },
                    { id: 'M', name: $t('Minute') },
                  ]"
                ></v-select>
              </div>
            </div>
          </div>-->

          <b-button size="md" class="mr-1" variant="outline-primary" @click="selectAllRows">{{
            $t("Select All")
          }}</b-button>
          <b-button size="md" class="mr-1" variant="outline-primary" @click="clearSelected">{{
            $t("Clear Selections")
          }}</b-button>
          <b-button size="md" class="mr-1" variant="outline-primary" @click="sendNotify()"
            >{{ $t("Save Participants") }} ({{ selected.length }})</b-button
          >
          <!--<b-button size="md" @click="sendNotify()" variant="outline-primary" class="mr-1"
            >{{ $t('Send Notification') }} ({{ selected.length }})</b-button
          >
          <b-button size="md" @click="sendNotifyNow()" variant="outline-primary" class="mr-1"
            >{{ $t('Send it now') }} ({{ selected.length }})</b-button
          >-->
        </div>
      </div>
    </b-overlay>
  </div>
</template>

<script>
  import { BTable, BButton, BFormGroup, BAvatar, BBadge } from "bootstrap-vue"
  import vSelect from "vue-select"
  import { avatarText } from "@core/utils/filter"
  import i18n from "@/libs/i18n"

  export default {
    props: { trainingAttenders: null, trainingData: null },
    components: {
      BTable,
      BButton,
      BFormGroup,
      BAvatar,
      BBadge,
      vSelect,
    },
    data() {
      return {
        notifyTimeType: "D",
        notifyTimeValue: 1,
        loading: true,
        modes: ["multi", "single", "range"],
        fields: [
          "id",
          {
            key: "avatar",
            label: i18n.t("Picture"),
            thStyle: {
              "max-width": "30px !important",
            },
          },
          { key: "name", label: i18n.t("Name") },
          { key: "position", label: i18n.t("Position") },
          { key: "email", label: i18n.t("Email") },
          { key: "status", label: i18n.t("Status") },
        ],
        items: [],
        selectMode: "multi",
        selected: [],
      }
    },
    computed: {
      selectedItems() {
        let dataIds = []
        let dataIdsUnSelected = []
        let dataUnSelectedIds = []
        Object.values(this.selected).map((data) => dataIds.push(data.id))
        dataIdsUnSelected = Object.values(this.items).filter((dataItem) => !dataIds.includes(dataItem.id))
        Object.values(dataIdsUnSelected).map((data) => dataUnSelectedIds.push(data.id))
        return { selected: dataIds, unSelected: dataUnSelectedIds }
      },
    },
    watch: {
      trainingAttenders() {
        this.getInvitedPlayers()
      },
    },
    methods: {
      getPlayers() {
        this.$http
          .get(
            `/season/${this.routerParams.seasonId}/team/${this.routerParams.id}?training=${this.routerParams.trainingId}&isActive=all`,
          )
          .then((response) => {
            this.items = response.data.players
            setTimeout(() => {
              this.loading = false
              this.getInvitedPlayers()
            }, 1000)
          })
          .catch((err) => {
            this.loading = false
          })
      },
      getInvitedPlayers() {
        if (this.trainingAttenders && this.trainingAttenders.length > 0) {
          this.notifyTimeValue = Number(this.trainingData.notifyTimeValue)
          this.notifyTimeType = this.trainingData.notifyTimeType
          Object.values(this.trainingAttenders).map((data) => {
            if (data.invited == true) {
              let findTableIndex = this.items.findIndex((player) => player.id == data.player.id)
              if (findTableIndex > -1) this.$refs.selectableTable.selectRow(findTableIndex)
            }
          })
        }
      },
      sendNotifyNow() {
        this.sendNotify(`/training/${this.routerParams.trainingId}/attendance/notification`)
      },
      sendNotify(postURL) {
        postURL =
          postURL || `/season/${this.routerParams.seasonId}/training/${this.routerParams.trainingId}/attendance/invite`
        this.loading = true
        this.$http
          .post(postURL, {
            selected: this.selectedItems.selected,
            unSelected: this.selectedItems.unSelected,
            notifyTimeType: this.notifyTimeType,
            notifyTimeValue: Number(this.notifyTimeValue),
            team: Number(this.routerParams.id),
          })
          .then((response) => {
            this.loading = false
          })
          .catch((failure) => {
            this.loading = false
            this.$bvToast.toast("Hatalı gönderi", {
              variant: "danger",
              title: "Bildirim gönderilemedi",
            })
          })
      },
      onRowSelected(items) {
        this.selected = items
      },
      selectAllRows() {
        this.$refs.selectableTable.selectAllRows()
      },
      clearSelected() {
        this.$refs.selectableTable.clearSelected()
      },
      selectThirdRow() {
        // Rows are indexed from 0, so the third row is index 2
        this.$refs.selectableTable.selectRow(2)
      },
      unselectThirdRow() {
        // Rows are indexed from 0, so the third row is index 2
        this.$refs.selectableTable.unselectRow(2)
      },
    },
    created() {
      this.getPlayers()
      this.loading = false
    },
    setup() {
      return {
        avatarText,
      }
    },
  }
</script>

<style lang="scss" scoped>
  .b-table-selectable {
    .feather {
      font-size: 1.3rem;
    }
  }
  .tableScrollCustom {
  }
</style>

<style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
  .NotifyTable .table-active,
  [aria-selected="true"] {
    color: #333;
    font-weight: bold;
  }
</style>
