<template>
  <div>
    <b-modal
      id="modal-copy"
      ref="isCopyModalOpen"
      :ok-title="$t('Add')"
      :cancel-title="$t('Cancel')"
      :title="$t('Copy')"
      centered
      :ok-disabled="copying"
      :cancel-disabled="copying"
      :no-close-on-backdrop="copying"
      :hide-header-close="copying"
      @ok="handleOk"
      modal-header-close="false"
    >
      <b-overlay :show="copying" rounded="sm">
        <div>
          <b-form>
            <b-row>
              <b-col md="12">
                <label>{{ $t("Training Name") }}</label>
                <b-form-group>
                  <b-input-group>
                    <b-form-input v-model="trainingModalData.title" type="text" :placeholder="$t('Training Name')" />
                  </b-input-group>
                </b-form-group>
              </b-col>

              <b-col md="8">
                <label>{{ $t("Training Date") }}</label>
                <b-form-group>
                  <b-input-group>
                    <b-form-datepicker
                      v-model="trainingModalData.startDate"
                      :placeholder="$t('Training Date')"
                      :obj="trainingModalData.startDate"
                      :locale="lang"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>

              <b-col md="4">
                <label>{{ $t("Start Time") }}</label>
                <b-form-group>
                  <b-input-group>
                    <b-form-input v-model="trainingModalData.startTime" type="text" placeholder="HH:mm" />
                    <b-input-group-append>
                      <b-form-timepicker
                        v-model="trainingModalData.startTime"
                        no-close-button
                        button-only
                        button-variant="outline-info"
                        left
                        size="sm"
                        locale="tr"
                      />
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <label>{{ $t("Season") }}</label>
                <b-form-group>
                  <v-select
                    v-model="trainingModalData.season"
                    :options="avaibleSeasons"
                    :reduce="(option) => option.id"
                    :get-option-label="(option) => option.name"
                    :clearable="false"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </div>
      </b-overlay>
    </b-modal>

    <b-card>
      <div class="card-header p-0 m-0 mb-2">
        <div>
          <b-card-title />
        </div>
        <div>
          <div>
            <training-navigation />
          </div>
        </div>
      </div>

      <b-row>
        <b-col lg="4">
          <div class="block">
            <p class="text-">
              {{ $t("Training Name") }}
            </p>
            <p class="h5">
              {{ training.title }}
            </p>
          </div>
        </b-col>

        <b-col lg="4">
          <div class="block">
            <p class="">
              {{ $t("Training Date") }}
            </p>
            <p class="h5">
              {{ training.startDate | longDateTime }} -
              {{ parseTime(training.endDate) }}
            </p>
          </div>
        </b-col>

        <b-col lg="4">
          <div class="block">
            <p>{{ $t("Location") }}</p>
            <p class="h5">
              {{ training.location ? training.location.name : null }}
            </p>
          </div>
        </b-col>
      </b-row>
    </b-card>

    <b-modal id="modal-1" :title="$t('Reason')" centered>
      <div class="reasons" style="min-height: 150px">
        <v-select
          v-model="reasonType"
          :placeholder="$t('Reason Select')"
          :options="reason"
          :reduce="(opt) => opt.id"
          :get-option-label="(option) => option.name"
        />
      </div>
      <template #modal-footer>
        <div class="w-100">
          <b-button variant="primary" size="md" class="float-right" @click="setReason()"> Save </b-button>
        </div>
      </template>
    </b-modal>

    <b-tabs
      v-model="tabIndex"
      style="width: 100%"
      content-class="mt-1"
      pills
      nav-wrapper-class="col-12 training-attenders"
      active-tab-class="w-100"
      nav-class="nav-left"
      lazy
    >
      <b-tab active>
        <template #title>
          <span class="d-sm-inline"> {{ $t("Attendance") }} </span>
        </template>

        <div class="bg-white">
          <b-table
            :busy="isBusy"
            responsive
            hover
            :items="training.players"
            :fields="training.fields"
            class="border"
            style="min-height: 70vh"
            sticky-header
            head-variant="light"
          >
            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle" />
              </div>
            </template>
            <template #thead-top="data">
              <b-tr>
                <b-th variant="primary">
                  {{ $t("Players") }}
                  <b-button :variant="toggleAll ? 'primary' : 'danger'" size="sm" class="ml-2" @click="clickAll()">
                    {{ $t("Select All") }}
                  </b-button>
                </b-th>
                <b-th variant="primary">
                  {{ $t("Attendant") }}
                </b-th>
                <b-th v-for="tib in training.trainingBlocks" :key="tib.id" :colspan="tib.trainingItems.length"
                  ><span v-text="tib.title"
                /></b-th>
              </b-tr>
            </template>
            <template #cell(name)="data">
              <div
                v-b-tooltip.hover
                class="d-flex justify-content-start"
                :title="data.item.attendant.reason ? data.item.attendant.reason.name : ' - '"
              >
                <b-avatar :text="getAvatarText(data.item.name)" :src="data.item.avatar" />
                <span>
                  <span class="ml-1 d-block" v-text="data.item.name" />
                  <span
                    class="ml-1 badge"
                    :class="`badge-${data.item.position.color}`"
                    v-text="data.item.position.name"
                  />
                </span>
              </div>
            </template>
            <template #cell(attendant)="data">
              <b-form-checkbox
                :ref="'dataKey' + data.index"
                v-model="data.item.attendant.attendant"
                :checked="data.item.attendant.attendant"
                @change="setAttendantAll(data.item)"
              />
            </template>
            <template #cell()="data">
              <div class="d-flex">
                <b-form-checkbox
                  v-if="data.item.atts && data.field.keyIndex > -1 && data.item.atts[data.field.keyIndex]"
                  v-model="data.item.atts[data.field.keyIndex].attendant"
                  class="align-self-center"
                  :checked="data.item.atts[data.field.keyIndex].attendant"
                  @change="
                    setAttendant(
                      data.item,
                      true,
                      data.item.atts[data.field.keyIndex].id,
                      data.item.atts[data.field.keyIndex].duration,
                      data.item.atts[data.field.keyIndex].attendant,
                    )
                  "
                />

                <b-input-group
                  v-if="data.item.atts[data.field.keyIndex] && data.item.atts[data.field.keyIndex].attendant"
                  :append="$t('dk')"
                  style="min-width: 100px; max-width: 115px"
                >
                  <b-form-input
                    v-model.number="data.item.atts[data.field.keyIndex].duration"
                    type="number"
                    @change="
                      setAttendant(
                        data.item,
                        true,
                        data.item.atts[data.field.keyIndex].id,
                        data.item.atts[data.field.keyIndex].duration,
                        data.item.atts[data.field.keyIndex].attendant,
                      )
                    "
                  />
                </b-input-group>
              </div>
            </template>
          </b-table>
        </div>
      </b-tab>

      <b-tab>
        <template #title>
          <span class="d-sm-inline"> {{ $t("Participants") }} </span>
        </template>

        <!-- Participants --->
        <b-card-actions :title="$t('Participants')" class="mt-2" action-collapse>
          <send-notify :training-attenders="training.trainingAttenders" :training-data="training" />
        </b-card-actions>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
  import i18n from "@/libs/i18n"
  import vSelect from "vue-select"
  import TrainingPlugin from "@/views/team/team-sub-pages/training/TrainingPlugin"
  import { BCardActions } from "@/@core/components/b-card-actions"
  import { ref } from "@vue/composition-api"
  import SendNotifyVue from "./detail/training-send-notify/SendNotify.vue"
  import TrainingNavigation from "./edit-training/TrainingNavigation.vue"

  export default {
    components: {
      vSelect,
      sendNotify: SendNotifyVue,
      BCardActions,
      TrainingNavigation,
    },
    data() {
      return {
        isBusy: true,
        reasonType: 0,
        reasonId: 0,
        reasonData: {},
        tabIndex: 0,

        /**
         * endpoint response objects
         */
        reason: [],
        training: {},
        attendant: [],

        toggleAll: false,
        toggleAllButtonActive: false,
      }
    },
    computed: {},
    watch: {
      tabIndex(newValue) {
        if (newValue == 0) this.beforeMountFunction()
      },
    },
    async mounted() {
      this.beforeMountFunction()
    },
    methods: {
      async beforeMountFunction() {
        const trainingId = Number(this.routerParams.trainingId)
        this.training.id = trainingId

        //   GET REASON LIST
        var { data } = await this.$http.get("/reason")
        this.reason = data
        //   GET ATTENDANT LIST
        var { data } = await this.$http.get(`/training/${trainingId}/attendant`)
        this.attendant = data

        const training = await this.$http.get(`/training/${trainingId}`)
        this.training = training.data
        this.training.fields = [
          {
            key: "name",
            label: this.$t("Oyuncu"),
            stickyColumn: true,
            isRowHeader: true,
            variant: "primary",
            thStyle: { "min-width": "25rem" },
          },
          { key: "attendant", label: this.$t("General") },
        ]
        this.getPlayers(this.training)
        const newItems = this.setTrainingItemCols(this.training.trainingBlocks)
        if (newItems) this.training.fields.push(...newItems)

        // SET ATTENDANT IDS INTO PLAYER OBJECT
        this.setPlayersAttendant(this.attendant, this.training.players)

        this.isBusy = false

        const anyAttendantFalse = Object.values(this.training.players).some((d) => d.attendant.attendant == true)
        this.toggleAll = anyAttendantFalse
      },
      getAvatarText(el) {
        return el.substr(0, 1) + (el.split(" ")[1] ? el.split(" ")[1].substr(0, 1) : "")
      },
      handleOk(bvModalEvt) {
        // Prevent modal from closing
        bvModalEvt.preventDefault()
        this.copyTraining(this.trainingModalData)
      },
      getPlayers: (training) => {
        if (training.trainingAttenders) {
          const playerList = training.trainingAttenders

          if (!playerList.length > 0) return []

          training.players = []
          training.block = []
          if (!playerList) return
          playerList.forEach((element) => {
            if (element.invited === true) training.players.push(element.player)
          })
          training.players = training.players.sort((a, b) => b.position.id - a.position.id)
          const gk = training.players.filter((d) => d.position.availablePositions.includes("GK"))
          const notGk = training.players.filter((d) => !d.position.availablePositions.includes("GK"))
          training.players = []
          training.players = [...notGk, ...gk]
        }
      },
      setPlayersAttendant: (attendant, players) => {
        if (attendant.length == 0) return
        if (players.length == 0) return

        if (!attendant) return
        attendant.forEach((attItems) => {
          const findPlayer = players.find((p) => p.id == attItems.player.id)
          findPlayer.atts = []
          findPlayer.attendant = attItems.trainingAttendant
          if (findPlayer.attendant.attendant === true) findPlayer.attendant.reason = null
          if (!attItems.trainingItemAttendant) return (findPlayer.atts = [])
          attItems.trainingItemAttendant.forEach((trAttend, index) => {
            findPlayer.atts[index] = trAttend
          })
        })
      },
      setTrainingItemCols: (blocks) => {
        // TABLE DYNAMIC COLS
        if (blocks.length == 0) return
        const arrs = []
        let keyIndex = 0
        blocks.map((item) => {
          if (item.trainingItems.length > 0) {
            item.trainingItems.map((tItem, index) => {
              arrs.push({
                key: tItem.name,
                label: tItem.name,
                thStyle: { "min-width": "15rem" },
                keyIndex,
              })
              keyIndex++
            })
          }
        })

        return arrs
      },
      async setReason() {
        this.$bvModal.hide("modal-1")
        this.reasonData.attendant.reason = this.reasonType
        const tempReasonData = { ...this.reasonData.attendant }
        tempReasonData.duration = null
        this.$http.put(`/training/attendant/${this.reasonData.attendant.id}`, tempReasonData)
        this.reasonData.attendant.reason = this.reason.find((data) => data.id == this.reasonType)
      },
      async clickAll() {
        this.toggleAll = !this.toggleAll
        this.toggleAllButtonActive = true
        const calc = this.training.players.length * 500 + 500
        Object.values(this.training.players).map((data, map) => {
          setTimeout(() => {
            data.attendant.attendant = this.toggleAll
            this.setAttendantAll(data)
          }, map * 500 + 100)
        })
        setTimeout(() => {
          this.toggleAllButtonActive = false
        }, calc)
      },
      async setAttendantAll(dataItem) {
        await new Promise((r) => setTimeout(r, 500))
        const general = dataItem.attendant.attendant
        const { atts } = dataItem
        if (!atts) return
        atts.forEach((at) => {
          if (at.attendant != dataItem.attendant.attendant) {
            at.attendant = dataItem.attendant.attendant
            this.setAttendant(dataItem, false, at.id, at.duration, at.attendant)
          }
        })
        this.checkAnyAttendant(dataItem)
        this.toast("Saved", "EditIcon", "primary")
      },
      async setAttendant(dataItem, checkAll, itemId, duration, attendant, reason = null) {
        const { data } = await this.$http.put(`/training/item/attendant/${itemId}`, { duration, attendant, reason })
        // TODO: translate
        if (data && checkAll) this.toast("Saved", "EditIcon", "primary")
        if (checkAll) this.checkAnyAttendant(dataItem, true)
      },
      // SET GENERAL ATTENDANT STATUS FOR TRAINING_ATTENDANT
      checkAnyAttendant(dataItem, sendRequest = true) {
        const tempAttendant = { ...dataItem.attendant }
        tempAttendant.duration = null
        if (!dataItem.atts || dataItem.atts.length == 0) return
        const control = Object.values(dataItem.atts).some((item) => item.attendant == true)
        dataItem.attendant.attendant = control
        dataItem.attendant.reason = null
        if (!control && !this.toggleAllButtonActive) {
          this.reasonId = dataItem.attendant.id
          this.reasonData = dataItem
          this.$bvModal.show("modal-1")
        }
        if (tempAttendant.reason != null && tempAttendant.reason.hasOwnProperty("id")) {
          tempAttendant.reason = tempAttendant.reason.id
        }
        if (sendRequest) this.$http.put(`/training/attendant/${dataItem.attendant.id}`, tempAttendant)
        if (dataItem.attendant.attendant === true) dataItem.attendant.reason = null
        return control
        // TODO : IF control false, Auto open REASON_DROPDOWN_MENU
        // TODO: Please select reason from the dropdown menu
      },
    },
    setup(props, { root }) {
      const lang = ref(i18n.locale)

      const parseTime = (date) => {
        if (!date) return ""
        const dateFormat = new Date(date)
        const dateFormat1 = dateFormat.toLocaleTimeString(i18n.locale, {
          hour: "numeric",
          minute: "numeric",
        })

        return dateFormat1
      }

      const { isCopyModalOpen, copying, copyTraining, showTrainingCopyModal, trainingModalData, avaibleSeasons } =
        TrainingPlugin()

      return {
        parseTime,
        isCopyModalOpen,
        copying,
        copyTraining,
        showTrainingCopyModal,
        trainingModalData,
        avaibleSeasons,
        lang,
      }
    },
  }
</script>
