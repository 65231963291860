<template>
  <!-- Need to add height inherit because Vue 2 don't support multiple root ele -->
  <div style="height: inherit">
    <div
      class="body-content-overlay"
      :class="{ show: shallShowUserProfileSidebar || shallShowActiveChatContactSidebar || mqShallShowLeftSidebar }"
      @click="mqShallShowLeftSidebar = shallShowActiveChatContactSidebar = shallShowUserProfileSidebar = false"
    />

    <!-- Main Area -->
    <section class="chat-app-window">
      <!-- Start Chat Logo -->
      <div v-if="!activeChat.contact" class="start-chat-area">
        <div class="mb-1 start-chat-icon">
          <feather-icon icon="MessageSquareIcon" size="56" />
        </div>
        <h4 class="sidebar-toggle start-chat-text" @click="startConversation">Start Conversation</h4>
      </div>

      <!-- Chat Content -->
      <div v-else class="active-chat">
        <!-- Chat Navbar -->
        <div class="chat-navbar">
          <header class="chat-header">
            <!-- Avatar & Name -->
            <div class="d-flex justify-content-start">
              <b-avatar
                :key="activeChat.contact.id"
                v-b-tooltip.hover.bottom="activeChat.contact.fullName"
                :text="avatarText(activeChat.contact.fullName)"
                class="mt-1 mb-0"
              />
              <h6 class="align-self-center cursor-pointer ml-1 mb-0">{{ activeChat.contact.fullName }}</h6>
            </div>
            <div class="d-flex align-items-center" v-if="false">
              <!-- Toggle Icon -->
              <div class="sidebar-toggle d-block d-lg-none mr-1">
                <feather-icon icon="MenuIcon" class="cursor-pointer" size="21" @click="mqShallShowLeftSidebar = true" />
              </div>

              <b-avatar-group class="pt-50" size="33">
                <b-avatar
                  v-for="contact in chatsContacts"
                  :key="contact.id"
                  v-b-tooltip.hover.bottom="contact.fullName"
                  :text="avatarText(contact.fullName)"
                  class="pull-up"
                />
                <h6 class="align-self-center cursor-pointer ml-1 mb-0">+16</h6>
              </b-avatar-group>
            </div>

            <!-- Contact Actions -->
            <!-- <div class="d-flex align-items-center">
              <feather-icon
                icon="PhoneCallIcon"
                size="17"
                class="cursor-pointer d-sm-block d-none mr-1"
              />
              <feather-icon
                icon="VideoIcon"
                size="17"
                class="cursor-pointer d-sm-block d-none mr-1"
              />
              <feather-icon
                icon="SearchIcon"
                size="17"
                class="cursor-pointer d-sm-block d-none mr-50"
              />
              <div class="dropdown">
                <b-dropdown
                  variant="link"
                  no-caret
                  toggle-class="p-0"
                  right
                >
                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="17"
                      class="align-middle text-body"
                    />
                  </template>
                  <b-dropdown-item>
                    View Contact
                  </b-dropdown-item>
                  <b-dropdown-item>
                    Mute Notifications
                  </b-dropdown-item>
                  <b-dropdown-item>
                    Block Contact
                  </b-dropdown-item>
                  <b-dropdown-item>
                    Clear Chat
                  </b-dropdown-item>
                  <b-dropdown-item>
                    Report
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </div> -->
          </header>
        </div>

        <!-- User Chat Area -->
        <vue-perfect-scrollbar ref="refChatLogPS" :settings="perfectScrollbarSettings" class="user-chats scroll-area">
          <chat-log
            :chat-data="activeChat"
            :profile-user-avatar="profileUserDataMinimal.avatar"
            :profile-user="profileUserDataMinimal"
            :chats-contacts="chatsContacts"
          />
        </vue-perfect-scrollbar>

        <!-- Message Input -->
        <b-form class="chat-app-form" @submit.prevent="sendMessage">
          <b-input-group class="input-group-merge form-send-message mr-1">
            <b-form-input v-model="chatInputMessage" :placeholder="$t('Enter your message')" />
          </b-input-group>
          <b-button variant="primary" type="submit">
            {{ $t("Send") }}
          </b-button>
        </b-form>
      </div>
    </section>

    <!-- Active Chat Contact Details Sidebar -->
    <chat-active-chat-content-details-sidedbar
      :shall-show-active-chat-contact-sidebar.sync="shallShowActiveChatContactSidebar"
      :contact="activeChat.contact || {}"
    />

    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-left">
      <chat-left-sidebar
        :groups="groups"
        :chats-contacts="chatsContacts"
        :contacts="contacts"
        :active-chat-contact-id="activeChat.contact ? activeChat.contact.id : null"
        :shall-show-user-profile-sidebar.sync="shallShowUserProfileSidebar"
        :profile-user-data="profileUserData"
        :profile-user-minimal-data="profileUserDataMinimal"
        :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar"
        @show-user-profile="showUserProfileSidebar"
        @open-chat="openChatOfContact"
      />
    </portal>
  </div>
</template>

<script>
  import store from "@/store"
  import { ref, onUnmounted, nextTick } from "@vue/composition-api"
  import {
    BAvatar,
    BAvatarGroup,
    BDropdown,
    BDropdownItem,
    BForm,
    BInputGroup,
    BFormInput,
    BButton,
    VBTooltip,
  } from "bootstrap-vue"
  import VuePerfectScrollbar from "vue-perfect-scrollbar"
  // import { formatDate } from '@core/utils/filter'
  import { $themeBreakpoints } from "@themeConfig"
  import { useResponsiveAppLeftSidebarVisibility } from "@core/comp-functions/ui/app"
  import ChatLeftSidebar from "./ChatLeftSidebar.vue"
  import chatStoreModule from "./chatStoreModule"
  import ChatActiveChatContentDetailsSidedbar from "./ChatActiveChatContentDetailsSidedbar.vue"
  import ChatLog from "./ChatLog.vue"
  import useChat from "./useChat"
  import chatService from "@/services/chatService"
  import axios from "@/libs/axios"
  import { avatarText } from "@/@core/utils/filter"

  export default {
    components: {
      // BSV
      BAvatar,
      BAvatarGroup,
      BDropdown,
      BDropdownItem,
      BForm,
      BInputGroup,
      BFormInput,
      BButton,

      // 3rd Party
      VuePerfectScrollbar,

      // SFC
      ChatLeftSidebar,
      ChatActiveChatContentDetailsSidedbar,
      ChatLog,
    },
    directives: {
      "b-tooltip": VBTooltip,
    },
    setup(props, { root }) {
      const chats = new chatService()
      const CHAT_APP_STORE_MODULE_NAME = "app-chat"

      // Register module
      if (!store.hasModule(CHAT_APP_STORE_MODULE_NAME))
        store.registerModule(CHAT_APP_STORE_MODULE_NAME, chatStoreModule)

      // UnRegister on leave
      onUnmounted(() => {
        if (store.hasModule(CHAT_APP_STORE_MODULE_NAME)) store.unregisterModule(CHAT_APP_STORE_MODULE_NAME)
      })

      const { resolveAvatarBadgeVariant } = useChat()

      // Scroll to Bottom ChatLog
      const refChatLogPS = ref(null)
      const scrollToBottomInChatLog = () => {
        const scrollEl = refChatLogPS.value.$el || refChatLogPS.value
        scrollEl.scrollTop = scrollEl.scrollHeight
      }

      // ------------------------------------------------
      // Chats & Contacts
      // ------------------------------------------------
      const chatsContacts = ref([])
      const contacts = ref([])

      const fetchChatAndContacts = () => {
        // store.dispatch('app-chat/fetchChatsAndContacts')
        //   .then(response => {
        var response = {}
        response.data = {}

        axios.get("/chat/user").then((userFetchData) => {
          var newContacts = []
          Object.values(userFetchData.data).map((dt) => {
            newContacts.push({
              id: dt.id,
              fullName: dt.firstName + " " + dt.lastName,
              role: dt.role,
              about: dt.role,
              avatar: require("@/assets/images/profile/user-uploads/user-01.jpg"),
              status: "online",
              chat: {
                id: dt.id,
                unseenMsgs: 1,
                lastMessage: {
                  message: "I will purchase it for sure. 👍",
                  time: "2021-06-29T10:28:06.213Z",
                  senderId: 1,
                },
              },
            })
          })

          console.log(newContacts)
          chatsContacts.value = newContacts
          contacts.value = newContacts
          // eslint-disable-next-line no-use-before-define
          profileUserDataMinimal.value = response.data.profileUser
          // scrollToBottomInChatLog();
        })

        // })
      }

      fetchChatAndContacts()

      // ------------------------------------------------
      // Single Chat
      // ------------------------------------------------
      const activeChat = ref({})
      const chatInputMessage = ref("")
      const openChatOfContact = (userId) => {
        // Reset send message input value
        chatInputMessage.value = ""
        console.log(userId)

        // store.dispatch("app-chat/getChat", { userId }).then((response) => {
        chats.get(userId, profileUserDataMinimal.value.id).then((dataFireStore) => {
          if (!userId.toString().includes("group_")) {
            var chatData = {
              chat: {
                id: new Date().getTime(),
                unseenMsgs: 0,
                userId,
                chat: dataFireStore,
              },
              contact: chatsContacts.value.find((c) => c.id === userId),
            }
          } else {
            var chatData = {
              chat: {
                id: new Date().getTime(),
                unseenMsgs: 0,
                userId,
                chat: dataFireStore,
              },
              contact: groups.find((c) => c.id === userId),
            }
          }

          activeChat.value = chatData

          nextTick(() => {
            scrollToBottomInChatLog()
          })
        })

        if (!userId.toString().includes("group_")) {
          chats.handle(profileUserDataMinimal.value.id, userId, (dataFireStore) => {
            if (Object.values(activeChat.value.chat.chat).find((d) => d.message != dataFireStore.message)) {
              activeChat.value.chat.chat.push(dataFireStore)
            }

            nextTick(() => {
              scrollToBottomInChatLog()
            })
          })
        } else {
          chats.groupHandle(userId, (dataFireStore) => {
            if (Object.values(activeChat.value.chat.chat).find((d) => d.message != dataFireStore.message)) {
              activeChat.value.chat.chat.push(dataFireStore)
              scrollToBottomInChatLog()
            }

            nextTick(() => {
              scrollToBottomInChatLog()
            })
          })
        }
        const contact = chatsContacts.value.find((c) => c.id === userId)
        if (contact) contact.chat.unseenMsgs = 0
        // if SM device =>  Close Chat & Contacts left sidebar
        // eslint-disable-next-line no-use-before-define
        mqShallShowLeftSidebar.value = false
      }
      const sendMessage = () => {
        if (!chatInputMessage.value) return
        const payload = {
          contactId: activeChat.value.contact.id,
          userId: activeChat.value.contact.id,
          // eslint-disable-next-line no-use-before-define
          senderId: profileUserDataMinimal.value.id,
          message: chatInputMessage.value,
          time: new Date().toLocaleString("tr"),
        }
        // store.dispatch("app-chat/sendMessage", payload).then((response) => {
        payload.id = new Date().getTime()
        if (payload.userId != payload.senderId && !payload.userId.toString().includes("group"))
          activeChat.value.chat.chat.push(payload)
        chats.send(payload, activeChat.value.contact.id).then((fireStoreData) => {
          chatInputMessage.value = ""
          const contact = chatsContacts.value.find((c) => c.id === activeChat.value.contact.id)
          contact.chat.lastMessage = payload
          nextTick(() => {
            scrollToBottomInChatLog()
          })
        })
        // });
      }

      const perfectScrollbarSettings = {
        maxScrollbarLength: 150,
      }

      // User Profile Sidebar
      // ? Will contain all details of profile user (e.g. settings, about etc.)
      const profileUserData = ref({})
      // ? Will contain id, name and avatar & status
      const profileUserDataMinimal = ref({})

      const shallShowUserProfileSidebar = ref(false)
      const showUserProfileSidebar = () => {
        store.dispatch("app-chat/getProfileUser").then((response) => {
          profileUserData.value = response.data
          shallShowUserProfileSidebar.value = true
        })
      }

      // Active Chat Contact Details
      const shallShowActiveChatContactSidebar = ref(false)

      // UI + SM Devices
      // Left Sidebar Responsiveness
      const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()
      const startConversation = () => {
        if (store.state.app.windowWidth < $themeBreakpoints.lg) {
          mqShallShowLeftSidebar.value = true
        }
      }

      const groups = [
        {
          id: "group_u19",
          fullName: "U19 (Genel)",
          role: "GENERAL_MESSAGE",
          about: "FENERBAHCE SK",
          avatar: "/img/2.748e7d02.png",
          status: "online",
          chat: {
            id: "group_u19",
            unseenMsgs: 0,
            lastMessage: { message: "U-19 Fenerbahçe", time: "2021-06-29T10:28:06.213Z", senderId: "group_u19" },
          },
        },
      ]

      return {
        // Filters
        // formatDate,

        // useChat
        resolveAvatarBadgeVariant,

        // Chat & Contacts
        chatsContacts,
        contacts,

        // Single Chat
        refChatLogPS,
        activeChat,
        chatInputMessage,
        openChatOfContact,
        sendMessage,

        // Profile User Minimal Data
        profileUserDataMinimal,

        // User Profile Sidebar
        profileUserData,
        shallShowUserProfileSidebar,
        showUserProfileSidebar,

        // Active Chat Contact Details
        shallShowActiveChatContactSidebar,

        // UI
        perfectScrollbarSettings,

        // UI + SM Devices
        startConversation,
        mqShallShowLeftSidebar,
        groups,
        avatarText,
      }
    },
  }
</script>

<style lang="scss" scoped></style>

<style lang="scss">
  @import "~@core/scss/base/pages/app-chat.scss";
  @import "~@core/scss/base/pages/app-chat-list.scss";
</style>
