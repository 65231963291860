export default [
  {
    header: "Others",
    action: "read",
    resource: "ACL",
  },
  // {
  //   title: 'Chat',
  //   route: 'chat-app',
  //   icon: 'MessageSquareIcon',
  // },
  {
    title: "User Management",
    route: "user-manage",
    icon: "UserPlusIcon",
    action: "manage",
    resource: "User",
  },
  {
    title: "Staff Management",
    route: "staff-list",
    icon: "UserCheckIcon",
    action: "manage",
    resource: "Staff",
  },
  {
    title: "Organizations Chart",
    route: "org-chart-page",
    icon: "UsersIcon",
    action: "read",
    resource: "OrganizationChart",
  },
  {
    title: "Settings",
    route: "settings",
    icon: "SettingsIcon",
    action: "manage",
    resource: "Setting",
  },
  {
    title: "Notification",
    route: "team-notify",
    tag: 3,
    icon: "BellIcon",
    action: "read",
    resource: "ACL",
  },
  {
    title: "File Library",
    route: "analysis-file-manage",
    icon: "BookIcon",
    action: "read",
    resource: "ACL",
  },
]
