/**
 * Dependencies
 */
import { reactive, ref, onBeforeMount, onUnmounted } from "@vue/composition-api"
import formValidation from "@core/comp-functions/forms/form-validation"
import axios from "@/libs/axios"
import router from "@/router"
import i18n from "@/libs/i18n"

/**
 * Static
 */
import { competitionStatic, competitionFormStatic } from "./competition"

/**
 * Services
 */
import competitionService from "@/services/competitionService"
import toastService from "@/services/toastService"

export default function useAddMatch() {
  const showSection = ref({ infoBarLoading: true, addMatchBasicForm: true, statComponent: false })
  const helper = reactive({})
  const competitionForm = ref(competitionFormStatic)
  const competition = ref(competitionStatic)
  const service = new competitionService()
  const routerCompetitionId = Number(router.currentRoute.params.competitionId) || null

  onBeforeMount(async () => {
    try {
      const competitionId = routerCompetitionId
      if (competitionId) {
        showSection.value.addMatchBasicForm = false
        showSection.value.Formation = true
        showSection.value.infoBarLoading = true

        const getCompetition = await service.find(competitionId)
        showSection.value.infoBarLoading = false
        competition.value.competitionStats = getCompetition.competitionStats
        competition.value.competitionFormations = getCompetition.competitionFormations
        competition.value.competitionEvents = getCompetition.competitionEvents
        competitionForm.value.homeTeam.select = getCompetition.homeTeam
        competitionForm.value.awayTeam.select = getCompetition.awayTeam
        competitionForm.value.location.select = getCompetition.location
        competitionForm.value.league.select = getCompetition.league
        competitionForm.value.date = getCompetition.date
        competitionForm.value.competition.homeScore = getCompetition.homeScore
        competitionForm.value.competition.awayScore = getCompetition.awayScore
        competitionForm.value.competition.time.select = getCompetition.duration
        competitionForm.value.competition.date.time = new Date(getCompetition.date).toLocaleTimeString()
        competitionForm.value.competition.date.date = new Date(getCompetition.date).toLocaleDateString()
        competition.value.formationTrigger = 0
        showSection.value.statComponent = true

        getTeamPlayers(competitionForm.value.awayTeam.select.id, "awayTeam").then((data) => {
          setTeamFormations("awayTeam")
        })
        getTeamPlayers(competitionForm.value.homeTeam.select.id, "homeTeam").then((data) => {
          setTeamFormations("homeTeam")
        })
      }
    } catch (e) {
      console.log("ERROR!!", e)
      router.push({
        name: "team-competitions",
      })
      toastService(i18n.t("Error fetching match"), "EditIcon", "danger")
    }

    const teams = await axios.get("/team")
    competitionForm.value.teams.option = teams.data
    const location = await axios.get("/location")
    competitionForm.value.location.option = location.data
    const league = await axios.get("/league")
    competitionForm.value.league.option = league.data
  })
  // DESTROY ALL VARIABLES
  onUnmounted(() => {
    location.reload()
  })

  const getTeamPlayers = async (teamId, elementTarget) => {
    const data = await axios.get(`/team/${teamId}`)
    competition.value[elementTarget].team.teamPlayers = []
    if (data) {
      competition.value[elementTarget].team.teamPlayers = data.data.players
      competition.value[elementTarget].team.id = data.data.id
      competition.value[elementTarget].team.name = data.data.name
      if (data.data.club) competition.value[elementTarget].team.club = data.data.club
      return data
    }
  }
  const setTeamFormations = (selectTeam) => {
    if (competition.value[selectTeam].team) {
      competition.value[selectTeam].team.teamPlayers = Object.values(
        competition.value[selectTeam].team.teamPlayers,
      ).map((players) => {
        if (!players) players = {}
        if (!players.isStarter) players.isStarter = null
        if (!players.position) $set(players, "position", { id: 1, name: "TAKIMDA" })
        players.stats = {
          yellowCard: null,
          yellowRedCard: null,
          redCard: null,
          goals: [],
          events: [],
        }
        players.isStarterId = null
        players.isStarterPlayer = null
        players.coach = null
        players.coachReview = null
        return players
      })
    }
    if (
      competition.value.competitionFormations.length > 0 &&
      competition.value[selectTeam].team.teamPlayers.length > 0
    ) {
      Object.values(competition.value.competitionFormations).map((players) => {
        const playerIndex = Object.values(competition.value[selectTeam].team.teamPlayers).findIndex(
          (pr) => pr.id == players.player.id,
        )
        if (playerIndex) {
          const player = competition.value[selectTeam].team.teamPlayers[playerIndex]
          if (!player) return
          player.isStarterPlayer = players.isStarter.toString()
          player.isStarterId = players.id
          player.coach = players.coachReview
          player.coachReview = players.coachReview
        }
      })

      Object.values(competition.value.competitionEvents).map((events) => {
        const dt = Object.values(competition.value[selectTeam].team.teamPlayers).filter(
          (pr) => pr.id == events.mainPlayer.id,
        )
        // FILTER PLAYER FROM TEAM
        if (dt.length == 1) {
          // SWITCH COMPETITION EVENT TYPES....
          if (events.competitionEventType == "YELLOW_CARD") {
            dt[0].stats.yellowCard = events.minute
            dt[0].stats.yellowCardId = events.id
          }
          if (events.competitionEventType == "RED_CARD") {
            dt[0].stats.redCard = events.minute
            dt[0].stats.redCardId = events.id
          }
          if (events.competitionEventType == "YELLOW_RED_CARD") {
            dt[0].stats.yellowRedCard = events.minute
            dt[0].stats.yellowRedCardId = events.id
          }
          if (events.competitionEventType == "CHANGE_PLAYER") {
            dt[0].stats.changedk = events.minute
            dt[0].stats.changeId = events.id
            dt[0].stats.changeplayer = Number(events.subPlayer.id) != 0 ? Number(events.subPlayer.id) : null
          }
          if (events.competitionEventType == "GOAL") {
            dt[0].stats.goals.push({
              id: events.id,
              dk: events.minute,
              subPlayerId: events.subPlayer ? events.subPlayer.id : null,
              type: events.competitionEventSubType,
            })
          }
        }
      })
    }
  }
  const searchTeam = async (searchKey) => {
    if (searchKey.length < 3) return false
    const data = await axios.get("/team", {
      key: searchKey,
    })
    if (data) competitionForm.value.teams.option = data
  }
  const addTeam = async (addValue, elementTarget) => {
    const data = await axios.post("/team", {
      name: addValue,
    })
    if (data) {
      competitionForm.value.teams.option.push(data.data)
      competitionForm.value[elementTarget].select = data.id
    }
  }
  const addLocation = async (addValue) => {
    const data = await axios.post("/location", {
      name: addValue,
    })
    if (data) {
      competitionForm.value.location.option.push(data.data)
      competitionForm.value.location.select = data.id
    }
  }
  const submitCompetition = () => {
    showSection.value.statComponent = false
    if (routerCompetitionId) {
      return editCompetition()
    }
    const requestPayload = {
      homeTeam: Number(competitionForm.value.homeTeam.select.id),
      awayTeam: Number(competitionForm.value.awayTeam.select.id),
      league: Number(competitionForm.value.league.select.id),
      // TODO : STATIC SEASON NUMBER
      season: Number(1),
      location: Number(competitionForm.value.location.select.id),
      duration: Number(competitionForm.value.competition.time.select),
      homeScore: Number(competitionForm.value.competition.homeScore) || 0,
      awayScore: Number(competitionForm.value.competition.awayScore) || 0,
      competitionDate: `${new Date(competitionForm.value.competition.date.date).toLocaleDateString()} ${
        competitionForm.value.competition.date.time
      }`,
    }
    axios
      .post("/competition", requestPayload)
      .then((response) => {
        showSection.value.addMatchBasicForm = false
        showSection.value.Formation = true
        showSection.value.infoBarLoading = false
        router.push({
          name: "scout-competition-edit",
          params: {
            id: router.currentRoute.params.id,
            competitionId: response.data.id,
          },
        })
        toastService(i18n.t("Successfully Created"), "EditIcon", "success")
        setTimeout(() => {
          location.reload()
        }, 1000)
      })
      .catch((err) => {
        toastService(i18n.t("Failed to save"), "AlertIcon", "danger")
      })
  }
  const editCompetition = () => {
    const requestPayload = {
      homeTeam: Number(competitionForm.value.homeTeam.select.id),
      awayTeam: Number(competitionForm.value.awayTeam.select.id),
      league: Number(competitionForm.value.league.select.id),
      season: Number(1),
      location: Number(competitionForm.value.location.select.id),
      duration: Number(competitionForm.value.competition.time.select),
      competitionDate: `${competitionForm.value.competition.date.date} ${competitionForm.value.competition.date.time}`,
      homeScore: Number(competitionForm.value.competition.homeScore),
      awayScore: Number(competitionForm.value.competition.awayScore),
    }
    axios.put(`/competition/${routerCompetitionId}`, requestPayload).then((response) => {
      showSection.value.addMatchBasicForm = false
      router.push({
        name: "scout-competition-edit",
        params: {
          id: router.currentRoute.params.id,
          competitionId: response.data.id,
        },
      })
      toastService(i18n.t("Successfully Updated"), "EditIcon", "success")
      setTimeout(() => {
        location.reload()
      }, 1000)
    })
  }
  const submitFormation = (player, teamId) => {
    competition.value.formationTrigger++
    const requestPayload = {
      playerId: Number(player.id),
      positionId: Number(player.position.id),
      competitionId: routerCompetitionId,
      isStarter: Number(player.isStarterPlayer),
      teamId: Number(teamId),
      coachReview: null,
    }
    if (player.isStarterId) requestPayload.id = player.isStarterId
    return service.formation(requestPayload, player, competition, setTeamFormations)
  }
  const submitFormationCoach = (player, teamId, coach) => {
    const requestPayload = {
      playerId: Number(player.id),
      positionId: Number(player.position.id),
      competitionId: routerCompetitionId,
      isStarter: Number(player.isStarterPlayer),
      coachReview: Number(coach),
      teamId: Number(teamId),
    }
    if (player.isStarterId) requestPayload.id = player.isStarterId
    service.coachReview(requestPayload)
  }

  // TODO: connect to service
  helper.submitRequestFormation = (requestPayload) => {
    service
      .submitFormationPlugin(routerCompetitionId, requestPayload)
      .then((response) => {
        competition.value.competitionFormations = response.data
        new Promise((resolve) => resolve(response.data))
      })
      .catch((err) => new Promise((reject) => reject(err)))
  }
  helper.submitEventForm = ({ id, player, dk, type, team, sub, subType }) => {
    const requestPayload = {
      id: Number(id),
      mainPlayer: Number(player.id),
      competition: routerCompetitionId,
      minute: Number(dk),
      subPlayer: Number(sub) ? Number(sub) : null,
      type,
      subType: subType || "",
      team: Number(team),
    }
    service.event(competition, requestPayload, setTeamFormations)
  }
  helper.reloadTeamPlayers = () => {
    getTeamPlayers(competitionForm.value.awayTeam.select.id, "awayTeam").then((data) => {
      setTeamFormations("awayTeam")
    })
    getTeamPlayers(competitionForm.value.homeTeam.select.id, "homeTeam").then((data) => {
      setTeamFormations("homeTeam")
    })
  }

  const { refFormObserver, getValidationState, resetForm } = formValidation()
  return {
    showSection,
    competition,
    competitionForm,
    helper,
    getTeamPlayers,
    searchTeam,
    addTeam,
    addLocation,
    submitCompetition,
    submitFormation,
    submitFormationCoach,
  }
}
