<template>
  <div style="min-height: 420px" class="w-100 settingsComponent">
    <b-card action-collapse>
      <b-card-title>
        <div class="d-flex justify-content-between">
          <span>
            {{ $t("Settings") }}
          </span>
          <span class="small text-success opacity-25 bg-light-success badge">
            {{ $t("Auto save enabled") }}
          </span>
        </div>
      </b-card-title>
      <div class="d-flex justify-content-start align-items-center">
        <div class="w-100">
          <table class="table table-border table-striped">
            <thead>
              <tr>
                <th>{{ $t("Key") }}</th>
                <th>{{ $t("Value") }}</th>
              </tr>
            </thead>
            <tbody>
              <template v-for="item in settingsData" class="row">
                <tr :key="item.id">
                  <td>
                    <label
                      :for="item.key"
                      style="line-height: 38px !important"
                      v-b-tooltip.hover
                      :title="item.description"
                      class="text-capitalize"
                    >
                      {{ $t("config." + item.key) }}
                      <b-icon icon="image-fill" size="18" />
                    </label>
                  </td>
                  <td>
                    <b-form-input
                      :id="item.key"
                      v-if="item.type == 'str'"
                      class="w-100 col-md-10"
                      v-model="item.value"
                      @change="updateSetting(item.id, item.key, item.value)"
                    />

                    <b-form-group
                      :id="item.key"
                      v-if="item.type == 'bool'"
                      class="w-100 col-md-10"
                      v-slot="{ ariaDescribedby }"
                    >
                      <b-form-radio
                        @change="updateSetting(item.id, item.key, item.value)"
                        v-model="item.value"
                        :aria-describedby="ariaDescribedby"
                        :name="item.key"
                        value="true"
                      >
                        {{ $t("Yes") }}
                      </b-form-radio>
                      <b-form-radio
                        @change="updateSetting(item.id, item.key, item.value)"
                        v-model="item.value"
                        :aria-describedby="ariaDescribedby"
                        :name="item.key"
                        value=""
                      >
                        {{ $t("No") }}
                      </b-form-radio>
                    </b-form-group>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
  import { BIcon } from "bootstrap-vue"

  export default {
    components: { BIcon },
    data() {
      return {
        settingsData: [],
      }
    },
    beforeCreate() {
      this.$http
        .get("/settings")
        .then((resp) => (this.settingsData = resp.data))
        .finally(() => this._clearLoading())
    },
    created() {
      this._setLoading(".settingsComponent .card")
    },
    methods: {
      updateSetting(id, key, value) {
        if (key == "star") {
          this.toast(this.$t("Error updating setting, please contact your service provider"), "UserIcon", "danger")
          return
        }

        this._setLoading(".settingsComponent .card")
        this.$http
          .put("/settings/" + id, {
            key: key,
            value: value,
          })
          .then((resp) => {
            this.setupAppConfig()
            this.toast(this.$t("Settings updated"))
          })
          .catch((err) => {
            this.toast(this.$t("Error updating setting"), "UserIcon", "danger")
          })
          .finally(() => this._clearLoading())
      },
    },
  }
</script>
