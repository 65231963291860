<template>
  <vue-good-table
    :columns="fields"
    :rows="items"
    styleClass="vgt-table striped"
    :select-options="{
      enabled: true,
      selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
      selectionInfoClass: 'custom-class',
      selectionText: 'rows selected',
      clearSelectionText: 'clear',
      disableSelectInfo: true, // disable the select info panel on top// when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
    }"
    max-height="300px"
    @on-selected-rows-change="selectionChanged($event, blockId, itemId)"
  >
    <template slot="table-row" slot-scope="props">
      <!-- Column: Name -->
      <span v-if="props.column.field === 'id'" class="text-nowrap">
        <span class="text-nowrap">{{ props.row.id }}</span>
      </span>

      <span v-if="props.column.field === 'duration'" class="text-nowrap">
        <b-form-input v-if="props.row.vgtSelected" type="number" class="w-75 h-25" v-model="props.row.duration" />
      </span>

      <!-- Column: Common -->
      <span v-else>
        {{ props.formattedRow[props.column.field] }}
      </span>
    </template>
  </vue-good-table>
</template>

<script>
  import { VueGoodTable } from "vue-good-table"
  import { toRefs } from "@vue/composition-api"

  import axios from "@axios"
  export default {
    components: {
      VueGoodTable,
    },
    props: {
      fields: {
        type: Array,
        default: [],
      },
      items: {
        type: Array,
        default: [],
      },
      blockId: {
        type: Number,
        default: 1,
      },
      itemId: {
        type: Number,
        default: 1,
      },
      trainingItem: {
        type: Object,
        default: {},
      },
    },
    data() {
      return {
        selected: [],
        tempId: [],
      }
    },

    setup(props, { emit, root }) {
      const selectionChanged = (params, blockId, itemId) => {
        const data = params.selectedRows
        data.forEach((element) => {
          axios
            .post(`/training-item-player`, {
              player: element.id,
              duration: 15,
              trainingItem: itemId,
            })
            .then((response) => {
              console.log(response.data)
            })
        })
      }

      return { selectionChanged }
    },
  }
</script>
<style lang="scss">
  @import "@core/scss/vue/libs/vue-good-table.scss";
  .ql-tooltip {
    z-index: 900;
  }
  .quill-editor {
    border: 0.1px solid rgba(190, 190, 190, 0.397);
    border-radius: 5px;
  }
  .top-seperated-drills {
    margin-bottom: 5px;
  }
</style>
