<template>
  <b-sidebar
    id="sidebar-league"
    :visible="isLeagueActive"
    bg-variant="white"
    @shown="$emit('update:isLeagueActive', true)"
    @hidden="
      $emit('update:isLeagueActive', false)
      formTrigger++
    "
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
  >
    <template #default="{ hide }">
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">{{ $t("League") }}</h5>
        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver" :key="formTrigger">
        <!-- Form -->
        <b-form v-if="leagueFormData" class="p-2" @submit.prevent="handleSubmit(goSubmitLeague())">
          <!-- League Name -->
          <validation-provider #default="{ errors }" name="leagueName" rules="required">
            <b-form-group :label="$t('League Name')" label-for="leagueName">
              <b-form-input id="leagueName" v-model="leagueFormData.name" :state="errors[0] ? false : null" />

              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <b-row>
            <!-- Player Limit -->
            <b-col md="12" xl="6">
              <validation-provider #default="{ errors }" name="playerLimit" rules="required">
                <b-form-group :label="$t('Player Limit')" label-for="playerLimit">
                  <b-form-input
                    id="playerLimit"
                    type="number"
                    v-model="leagueFormData.playerLimit"
                    :state="errors[0] ? false : null"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Competition Duration -->
            <b-col md="12" xl="6">
              <validation-provider #default="{ errors }" name="competitionDuration" rules="required">
                <b-form-group :label="$t('Competition Duration')" label-for="compDuration">
                  <v-select
                    v-model="leagueFormData.competitionDuration"
                    :reduce="(option) => option.value"
                    :state="getValidationState(errors)"
                    :options="[
                      { label: `30 ${$t('dk')}`, value: 30 },
                      { label: `45 ${$t('dk')}`, value: 45 },
                      { label: `50 ${$t('dk')}`, value: 50 },
                      { label: `60 ${$t('dk')}`, value: 60 },
                      { label: `70 ${$t('dk')}`, value: 70 },
                      { label: `80 ${$t('dk')}`, value: 80 },
                      { label: `90 ${$t('dk')}`, value: 90 },
                    ]"
                  >
                  </v-select>

                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="12" xl="6">
              <validation-provider #default="{ errors }" name="isOfficial" rules="required">
                <b-form-group label-for="isOfficial">
                  <b-form-checkbox v-model="isOfficial" :value="true" :unchecked-value="false">
                    {{ $t("Official League") }}
                  </b-form-checkbox>

                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col md="12" xl="12" v-show="isOfficial">
              <validation-provider #default="{ errors }" name="team" :rules="`${isOfficial ? 'required' : ''}`">
                <b-form-group :label="$t('Team')" label-for="team">
                  <v-select
                    id="leagueTeam"
                    :disabled="!isOfficial"
                    :placeholder="$t('Select')"
                    v-model="leagueFormData.team"
                    :state="errors.length > 0 ? false : null"
                    :options="teams"
                    :reduce="(option) => option.id"
                    :getOptionLabel="(option) => (option.club ? option.club.name + ' - ' + option.name : option.name)"
                    :clearable="false"
                  >
                    <template v-slot:option="option">
                      <span v-if="option.club">
                        <img v-if="option.club" :src="option.club.logo" height="18" />
                        {{ option.club.name }}
                      </span>
                      {{ option.name }}
                    </template>
                  </v-select>

                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" type="submit">{{
              $t("Add")
            }}</b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              ref="hideClick"
              @click="hide"
              >{{ $t("Cancel") }}</b-button
            >
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>
<script>
  import { ValidationProvider, ValidationObserver } from "vee-validate"
  import { required } from "@validations"
  import formValidation from "@core/comp-functions/forms/form-validation"
  import Ripple from "vue-ripple-directive"
  import flatPickr from "vue-flatpickr-component"
  import vSelect from "vue-select"
  import { ref, onMounted, watch } from "@vue/composition-api"
  import { teamService } from "@/services"
  export default {
    components: {
      flatPickr,
      vSelect,

      // Form Validation
      ValidationProvider,
      ValidationObserver,
    },
    directives: {
      Ripple,
    },
    model: {
      prop: "isLeagueActive",
      event: "update:is-league-active",
    },
    props: {
      isLeagueActive: {
        type: Boolean,
        require: true,
      },
      submitLeague: {
        required: true,
      },
      updateLeague: {
        require: true,
      },
      leagueFormData: {
        default: null,
      },
    },
    data() {
      return {
        required,
        formTrigger: 0,
        isOfficial: false,
      }
    },
    methods: {
      goSubmitLeague() {
        this.$refs.refFormObserver.validate().then((success) => {
          if (success) {
            if (this.leagueFormData.id) {
              if (!this.isOfficial) this.leagueFormData.team = null

              this.leagueFormData.team = this.leagueFormData.team?.id ?? this.leagueFormData.team
              this.leagueFormData.playerLimit = parseInt(this.leagueFormData.playerLimit)
              this.updateLeague(this.leagueFormData)
            } else {
              // TODO: League Type ?
              this.leagueFormData.type = "null"
              this.leagueFormData.playerLimit = parseInt(this.leagueFormData.playerLimit)
              this.submitLeague(this.leagueFormData)
            }
            this.$refs.hideClick.click()
          }
        })
      },
    },
    watch: {
      isLeagueActive: function (newVal, oldVal) {
        this.isOfficial = this.leagueFormData.team ? true : false
        if (newVal == true) this.getTeams()
      },
    },
    setup(props, { emit }) {
      const { getValidationState } = formValidation()
      const teamSer = new teamService()
      const teams = ref([])

      onMounted(async () => {
        await getTeams()
      })

      let findClubId = JSON.parse(localStorage.getItem("teams"))
        ? JSON.parse(localStorage.getItem("teams"))[0].club.id
        : null

      const getTeams = () => {
        teamSer
          .get({ leagueSidebarOptions: true, club: findClubId, includeId: props.leagueFormData?.team?.id })
          .then((response) => {
            teams.value = response.data.data
          })
      }

      return {
        getValidationState,
        teams,
        getTeams,
      }
    },
  }
</script>

<style lang="scss">
  @import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
