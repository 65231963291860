<template>
  <div>
    <!-- <training-check-table /> -->
  </div>
</template>

<script>
  // import TrainingCheckTable from "@/views/team/team-sub-pages/training/training-table/TrainingCheckTable";

  export default {
    // components: { TrainingCheckTable },
  }
</script>

<style lang="scss">
  .per-page-selector {
    width: 90px;
  }

  .right-top {
    position: absolute;
    right: 2rem;
    top: 1rem;
  }

  .embed-video {
    iframe {
      max-width: 100%;
      max-height: 30vh;
    }
  }
</style>
