<template>
  <div>
    <div class="card-header p-0 m-0 mb-2">
      <div>
        <b-card-title>
          <feather-icon icon="SettingsIcon" size="24" />
          {{ $t("Competition Analysis") }}
        </b-card-title>
      </div>
    </div>
    <div>
      <div class="row">
        <b-col md="6">
          <h2>{{ $t("Pre Match Analysis") }}</h2>
          <b-row>
            <b-col :md="10" class="mt-2">
              <b-row>
                <b-col :md="3" v-for="item in preFiles" :key="item.id">
                  <div @click="openFile(item)" class="card icon-card cursor-pointer text-center">
                    <div class="file-boxes shadow-lg">
                      <feather-icon size="32" icon="FileIcon" class="mt-2"></feather-icon>
                      <span class="d-block my-1">{{ item.name }}</span>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
        <div class="col-md-6">
          <h2>{{ $t("Pre Match Analysis") }}</h2>
          <b-col :md="10" class="mt-2">
            <b-row>
              <b-col :md="3" v-for="item in postFiles" :key="item.id">
                <div @click="openFile(item)" class="card icon-card cursor-pointer text-center">
                  <div class="file-boxes shadow-lg">
                    <feather-icon size="32" icon="FileIcon" class="mt-2"></feather-icon>
                    <span class="d-block my-1">{{ item.name }}</span>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-col>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { avatarText } from "@core/utils/filter"

  export default {
    components: {},
    name: "CompetitionAnalysisEdit",
    props: {
      competitionForm: null,
      competition: null,
      helperFuncs: null,
      showSection: null,
    },
    data() {
      return {
        isLoading: false,
        file: [],
        preFiles: [],
        postFiles: [],
        avatarText,
      }
    },
    methods: {
      openFile(item) {
        window.open(item.file)
      },
      createPostCompetitionAnalysis() {
        let file = this.$refs.post.files[0]
        let formData = new FormData()
        formData.append("file", file)
        this.$http
          .post(`/competition/analysis/post/${this.routerParams.competitionId}`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            this.postFiles.push(response.data)
          })
      },
      createPreCompetitionAnalysis() {
        let file = this.$refs.pre.files[0]
        let formData = new FormData()
        formData.append("file", file)
        this.$http
          .post(`/competition/analysis/pre/${this.routerParams.competitionId}`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            this.preFiles.push(response.data)
          })
      },
      getFilesByType() {},
    },
    computed: {},
    async beforeMount() {
      this.competition.formationTrigger = 0
    },
    created() {
      this.$http.get(`/competition/${this.routerParams.competitionId}/analysis`).then((res) => {
        if (res) {
          res.data.filter((item) => {
            if (item.isPre === 1) {
              this.preFiles.push(item)
            } else {
              this.postFiles.push(item)
            }
          })
        }
      })
    },
  }
</script>
<style>
  input.formationSelect {
    width: 30px;
    height: 30px;
  }

  .add-match-formation .nav-pills .nav-link.active {
    /* background: unset; */
  }
</style>
