<template>
  <div>
    <b-card no-body>
      <b-card-header class="pb-50">
        <h5>{{ $t("Filter") }}</h5>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col cols="12" md="3" class="mb-md-0 mb-2">
            <label>{{ $t("Player Name") }}</label>
            <b-input v-model="playerName" :placeholder="$t('Search')" type="search" />
          </b-col>

          <b-col cols="12" md="3" class="mb-md-0 mb-2">
            <label>{{ $t("Coach Name") }}</label>
            <b-input v-model="coachName" :placeholder="$t('Search')" type="search" />
          </b-col>

          <b-col cols="12" md="3" class="mb-md-0 mb-2">
            <label>{{ $t("Parent") }}</label>
            <b-input v-model="contactName" :placeholder="$t('Search')" type="search" />
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <b-card no-body>
      <div class="">
        <b-table
          id="excelTable"
          ref="refDataTable"
          :fields="fields"
          :items="getData"
          class="position-relative"
          :empty-text="$t('No records found')"
          responsive
          primary-key="id"
          show-empty
          busy.sync="true"
          style="min-height: 400px"
          :sort-by.sync="sortBy"
          :sort-desc.sync="isSortDirDesc"
          :per-page="perPage"
          :current-page="currentPage"
          :tbody-tr-class="isReadRowClass"
          :no-sort-reset="true"
        >
          <template #table-busy>
            <div class="text-center my-2">
              <b-spinner class="align-middle"></b-spinner>
            </div>
          </template>

          <template #row-details="row">
            <b-card>
              <b-row class="mb-2">
                <b-col sm="3" class="text-sm-right"
                  ><b>{{ $t("Message") }}</b></b-col
                >
                <b-col>{{ row.item.message }}</b-col>
              </b-row>
            </b-card>
          </template>

          <template #cell(show_details)="row">
            <div class="d-flex justify-content-between">
              <b-link class="text-dark">
                <feather-icon
                  v-b-tooltip.hover
                  :title="$t('Details')"
                  @click="showDetails(row)"
                  size="21"
                  icon="EyeIcon"
                />
              </b-link>
              <b-link v-if="!row.item.answered_at && row.item.isRead" class="text-dark" style="margin-left: 5px">
                <feather-icon
                  v-b-tooltip.hover
                  :title="$t('Treated')"
                  @click="setIsAnswered(row)"
                  size="21"
                  icon="CheckCircleIcon"
                />
              </b-link>
              <b-link class="text-dark" style="margin-left: 5px">
                <feather-icon
                  v-b-tooltip.hover
                  :title="$t('Delete')"
                  @click="removeRequestForm(row)"
                  size="21"
                  icon="TrashIcon"
                />
              </b-link>
            </div>
          </template>

          <template #cell(created_at)="data">
            <span>{{ data.value | nativeDateTime }}</span>
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
              <label>{{ $t("Show") }}</label>
              <v-select
                v-model="perPage"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
              />

              <span class="text-muted">{{
                $t("format.showingData", { from: dataMeta.from, to: dataMeta.to, of: dataMeta.of })
              }}</span>
            </b-col>

            <!-- Pagination -->
            <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalItems"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
  import vSelect from "vue-select"
  import i18n from "@/libs/i18n"
  import { ref, watch, computed } from "@vue/composition-api"
  import axios from "@/libs/axios"

  export default {
    components: {
      vSelect,
    },
    methods: {},
    mounted() {},
    setup(props, { root }) {
      const isSortDirDesc = ref(true)
      const perPage = ref(localStorage.getItem("perPage") || 10)
      const totalItems = ref(24)
      const playerName = ref("")
      const coachName = ref("")
      const contactName = ref("")
      const currentPage = ref(1)
      const sortBy = ref("id")
      const perPageOptions = [10, 25, 50, 100]
      const data = ref([])
      const refDataTable = ref(null)

      const fields = computed({
        get: () => [
          { key: "playerName", label: i18n.t("Player Name"), sortable: true },
          { key: "age", label: i18n.t("Age"), sortable: true },
          { key: "coachName", label: i18n.t("Coach Name") },
          { key: "name", label: i18n.t("Parent") },
          { key: "subject", label: i18n.t("Subject") },
          { key: "created_at", label: i18n.t("Date") },
          { key: "show_details", label: i18n.t("Actions") },
        ],
      })

      const getData = () => {
        return axios
          .get("communication/request-form", {
            params: {
              page: currentPage.value,
              row: perPage.value,
              sortBy: sortBy.value,
              isDesc: isSortDirDesc.value,
              playerName: playerName.value,
              coachName: coachName.value,
              contactName: contactName.value,
            },
          })
          .then((res) => {
            data.value = res.data.data
            totalItems.value = res.data.count

            return data.value
          })
          .catch((error) => {
            console.log(error)
          })
      }

      watch([playerName, coachName, contactName], () => {
        refDataTable.value.refresh()
      })

      watch(perPage, (perPage) => {
        localStorage.setItem("perPage", perPage)
      })

      const dataMeta = computed(() => {
        let localItemsCount = data.value.length > 0 ? data.value.length : 0
        return {
          from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
          to: perPage.value * (currentPage.value - 1) + localItemsCount,
          of: totalItems.value,
        }
      })

      const findAge = (birthdate) => {
        return Math.abs(new Date(Date.now() - new Date(birthdate).getTime()).getUTCFullYear() - 1970)
      }

      const isReadRowClass = (item, type) => {
        if (!item || type !== "row") return
        if (!item.isRead) return "table-secondary font-weight-bolder"
        if (item.answered_at) return "table-success"
      }

      const showDetails = (row) => {
        row.toggleDetails()
        if (!row.detailsShowing && row.item.isRead == false) {
          // for set isRead to true
          axios.get(`/communication/request-form/${row.item.id}`).then(() => {
            row.item.isRead = true
          })
        }
      }

      const setIsAnswered = async (row) => {
        let confirm = await root.confirmModal("It will be marked as answered by you. Do you confirm?")
        if (!confirm) return false
        refDataTable.value.localBusy = true
        axios
          .post(`/communication/request-form/${row.item.id}/set-answered`)
          .then(() => {
            refDataTable.value.localBusy = false
            refDataTable.value.refresh()
          })
          .catch((error) => {
            refDataTable.value.localBusy = false
            console.log(error)
          })
      }

      const removeRequestForm = async (row) => {
        let confirm = await root.confirmModal()
        if (!confirm) return false

        axios.delete(`/communication/request-form/${row.item.id}`).then(() => {
          refDataTable.value.refresh()
        })
      }

      return {
        findAge,

        isSortDirDesc,
        perPage,
        totalItems,
        currentPage,
        sortBy,
        perPageOptions,
        dataMeta,
        fields,
        getData,
        showDetails,
        isReadRowClass,
        refDataTable,
        playerName,
        coachName,
        contactName,
        setIsAnswered,
        removeRequestForm,
      }
    },
  }
</script>
