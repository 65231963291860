<template>
  <b-sidebar
    id="sidebar-add-cont"
    :visible="isSidebarActive"
    bg-variant="white"
    @shown="$emit('update:isSidebarActive', true)"
    @hidden="
      $emit('update:isSidebarActive', false)
      formTrigger++
    "
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
  >
    <template #default="{ hide }">
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">{{ $t("Transfer") }}</h5>
        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver" :key="formTrigger">
        <!-- Form -->
        <b-form class="p-2" @submit.prevent="handleSubmit(goSubmit())" @reset.prevent="resetForm">
          <!-- Player Season -->
          <validation-provider #default="{ errors }" name="player" rules="required">
            <b-form-group :label="$t('Player')" label-for="player">
              <v-select
                input-id="player"
                v-model="form.player"
                :getOptionLabel="(option) => option.name"
                :options="players"
                :reduce="(opt) => opt.id"
                :clearable="false"
              />

              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <validation-provider #default="{ errors }" name="season" rules="required">
            <b-form-group :label="$t('Season')" label-for="season">
              <v-select
                input-id="season"
                v-model="form.season"
                :getOptionLabel="(option) => option.name"
                :options="seasons"
                :reduce="(opt) => opt.id"
                :clearable="false"
              />

              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <validation-provider #default="{ errors }" name="team" rules="required">
            <b-form-group :label="$t('Team')" label-for="team">
              <v-select
                v-model="form.team"
                :getOptionLabel="(option) => (option.club ? option.club.name + ' - ' + option.name : option.name)"
                :options="teams"
                :reduce="(val) => val.id"
                :clearable="false"
                input-id="team"
              >
                <template v-slot:option="option">
                  <span class="text-nowrap">{{ option.club ? option.club.name : "" }} / {{ option.name }}</span>
                </template>
              </v-select>

              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <validation-provider #default="{ errors }" name="startDate" rules="required">
            <b-form-group>
              <label class="w-100">
                {{ $t("Start Date") }}
              </label>
              <div class="d-flex justify-content-between">
                <flat-pickr
                  v-model="form.startDate"
                  class="form-control"
                  :config="{
                    enableTime: false,
                    locale: $i18n.locale == 'fr' ? French : $i18n.locale == 'tr' ? Turkish : null,
                    enableTime: false,
                    defaultDate: new Date(),
                    altFormat: 'F j, Y',
                  }"
                />
              </div>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button variant="primary" class="mr-2" type="submit">{{ $t("Transfer") }}</b-button>
            <b-button type="button" variant="outline-secondary" ref="hideClick" @click="hide">{{
              $t("Cancel")
            }}</b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
  import { ValidationProvider, ValidationObserver } from "vee-validate"
  import { required, alphaNum, email } from "@validations"
  import { ref, onMounted, computed, watch } from "@vue/composition-api"
  import { useRouter } from "@core/utils/utils"
  import http from "@/libs/axios"
  import i18n from "@/libs/i18n"
  import vSelect from "vue-select"
  import { Turkish } from "flatpickr/dist/l10n/tr.js"
  import { French } from "flatpickr/dist/l10n/fr.js"
  import flatPickr from "vue-flatpickr-component"

  export default {
    components: {
      ValidationProvider,
      ValidationObserver,
      vSelect,
      flatPickr,
    },
    model: {
      prop: "isSidebarActive",
      event: "update:is-sidebar-active",
    },
    props: {
      isSidebarActive: {
        type: Boolean,
        required: true,
      },
      form: {
        type: Object,
        default: () => {},
      },
      players: {
        type: Array,
        default: () => [],
      },
      seasons: {
        type: Array,
        default: () => [],
      },
      teams: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        required,
        formTrigger: 0,
      }
    },
    methods: {
      goSubmit() {
        this.$refs.refFormObserver.validate().then((success) => {
          if (success) {
            this.create()
          }
        })
      },
    },
    setup(props, { emit, root }) {
      const { router, route } = useRouter()
      const blanForm = {}
      const formTrigger = ref(0)
      const loading = ref(false)

      const submit = () => {
        //create();
      }

      const create = (data) => {
        loading.value = true
        http
          .post(`scout/player/transfer`, props.form)
          .then((res) => {
            loading.value = false
            root.toast(i18n.t("Transfer Successful"), "PlusIcon", "success")
            location.reload()
          })
          .catch((error) => console.log(error))
      }

      return {
        submit,
        required,
        formTrigger,
        loading,
        create,
        Turkish,
        French,
      }
    },
  }
</script>

<style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
  @import "@core/scss/vue/libs/vue-flatpicker.scss";

  #add-new-user-sidebar {
    .vs__dropdown-menu {
      max-height: 200px !important;
    }
  }
</style>
