export default [
  {
    title: "Dashboard Scout",
    icon: "ActivityIcon",
    route: "dashboard-scout",
    resource: "Scout",
    action: "manage",
  },
  {
    title: "Inbox",
    icon: "InboxIcon",
    route: "dashboard-communicaiton-request",
    resource: "Inbox",
    action: "manage",
    setting: "communication",
  },
  {
    header: "Scout Management",
    action: "read",
    resource: "Scout",
  },
  {
    title: "Scout Watch List",
    icon: "EyeIcon",
    route: "scout-watch-list",
    action: "manage",
    resource: "Scout",
  },
  {
    title: "Scout Players",
    icon: "UserIcon",
    route: "scout-players",
    action: "manage",
    resource: "Scout",
  },
  {
    title: "Scout Competitions",
    icon: "ApertureIcon",
    route: "scout-competitions",
    action: "manage",
    resource: "Scout",
  },
  {
    title: "Scout Player Transfer",
    icon: "ArrowRightIcon",
    route: "scout-player-transfer",
    action: "manage",
    resource: "Scout",
  },
  {
    title: "Scout Archive Player",
    icon: "ArchiveIcon",
    route: "scout-deleted-players",
    action: "manage",
    resource: "Scout",
  },
]
