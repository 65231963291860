<template>
  <div style="min-height: 420px">
    <b-table
      class="position-relative border"
      :items="tableItems"
      responsive
      :fields="tableColumns"
      primary-key="id"
      show-empty
      :empty-text="$t('No records found')"
      sticky-header="400px"
      style="min-height: 400px"
      ref="Table"
      :id="tableId"
      busy.sync="false"
      :tbody-tr-class="deletedDataRowClass"
    >
      <template #table-busy>
        <div class="text-center my-2">
          <b-spinner class="align-middle"></b-spinner>
        </div>
      </template>
      <!-- Column: Actions -->
      <template #cell(actions)="data">
        <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
          <template #button-content>
            <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
          </template>
          <b-dropdown-item @click="goSidebar(data.item)" v-if="!data.item.deleted_at">
            <feather-icon icon="EditIcon" />
            <span class="align-middle ml-50">{{ $t("Edit") }}</span>
          </b-dropdown-item>

          <b-dropdown-item @click="goDelete(data.item.id)" v-if="!data.item.deleted_at">
            <feather-icon icon="TrashIcon" />
            <span class="align-middle ml-50">{{ $t("Delete") }}</span>
          </b-dropdown-item>

          <b-dropdown-item @click="goUnDelete(data.item.id)" v-if="data.item.deleted_at">
            <feather-icon icon="RotateCwIcon" />
            <span class="align-middle ml-50">{{ $t("Undo Deletion") }}</span>
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>
  </div>
</template>

<script>
  import { BTable, BDropdown, BDropdownItem, BMedia, BAvatar } from "bootstrap-vue"
  export default {
    components: {
      BTable,
      BDropdown,
      BDropdownItem,
      BMedia,
      BAvatar,
    },
    props: {
      tableItems: {
        default: null,
      },
      tableColumns: {
        default: null,
      },
      deleteData: {
        default: null,
      },
      unDelete: {
        default: null,
      },
      tableId: {
        default: null,
      },
    },
    data() {
      return {
        team: { id: null, club: null, name: null, logo: null, season: null },
        season: { id: null, endDate: null, startDate: null, name: null, year: null, organization: null },
      }
    },
    created() {},
    mounted() {},
    methods: {
      goSidebar(data) {
        this.$emit("goSidebar", { ...data })
      },
      async goDelete(dataId) {
        let confirm = await this.confirmModal()
        if (!confirm) return false

        this.deleteData(dataId)
      },
      async goUnDelete(dataId) {
        let confirm = await this.confirmModal(this.$t("Please confirm that you want to undo the deletion."))
        if (!confirm) return false

        this.unDelete(dataId)
      },
      deletedDataRowClass(item, type) {
        if (!item || type !== "row") return
        if (item.deleted_at) return "table-secondary"
      },
    },
  }
</script>
