<template>
  <div>
    <b-table-simple responsive="" :busy="isBusy" data-style="transform:scale(70%) translate(-21.5%); margin-top:-3%">
      <b-thead>
        <b-tr>
          <b-th class="text-center" colspan="15"
            ><span class="h4 p-0 m-0"
              >FENERBAHÇE FUTBOL AŞ / GENÇ FUTBOL TAKIMLARI HAFTALIK ÇALIŞMA ÇİZELGESİ</span
            ></b-th
          >
        </b-tr>
        <b-tr>
          <b-th class="text-center" colspan="1"><span class="">TARİH</span></b-th>
          <b-th class="text-center" colspan="2"><span class="">01.01.2020</span></b-th>
          <b-th class="text-center" colspan="2"><span class="">01.01.2020</span></b-th>
          <b-th class="text-center" colspan="2"><span class="">01.01.2020</span></b-th>
          <b-th class="text-center" colspan="2"><span class="">01.01.2020</span></b-th>
          <b-th class="text-center" colspan="2"><span class="">01.01.2020</span></b-th>
          <b-th class="text-center" colspan="2"><span class="">01.01.2020</span></b-th>
          <b-th class="text-center" colspan="2"><span class="">01.01.2020</span></b-th>
        </b-tr>
        <b-tr>
          <b-th class="text-center" colspan="1"><span class="">TAKIMLAR</span></b-th>
          <b-th class="text-center" colspan="2"><span class="">Pazartesi</span></b-th>
          <b-th class="text-center" colspan="2"><span class="">Salı</span></b-th>
          <b-th class="text-center" colspan="2"><span class="">Çarşamba</span></b-th>
          <b-th class="text-center" colspan="2"><span class="">Perşembe</span></b-th>
          <b-th class="text-center" colspan="2"><span class="">Cuma</span></b-th>
          <b-th class="text-center" colspan="2"><span class="">Cumartesi</span></b-th>
          <b-th class="text-center" colspan="2"><span class="">Pazar</span></b-th>
        </b-tr>
        <b-tr>
          <b-th class="text-center" colspan="1"><span class="">TAKIMLAR</span></b-th>
          <template v-for="index in 7">
            <b-th :key="index" class="text-center" colspan="1"
              ><span class="">SABAH <br />(MORNING)</span></b-th
            >
            <b-th :key="index * 15" class="text-center" colspan="1"
              ><span class="">Ö. SONRA <br />(AFTERNOON)</span></b-th
            >
          </template>
        </b-tr>
      </b-thead>
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle"></b-spinner>
        </div>
      </template>
      <b-tbody>
        <b-tr>
          <b-td colspan="1" variant="light">FENERBAHCE U18</b-td>
          <b-td colspan="2" variant="success text-center">
            <span class="h4"
              >İZİN <br />
              (OFF DAY)</span
            >
          </b-td>
          <b-td colspan="1" variant="light" class="text-danger font-weight-bolder">16.00 SAHA 3 TRAINING</b-td>
          <b-td colspan="2" variant="success text-center">
            <span class="h4">OFF İZİN</span>
          </b-td>
          <b-td colspan="1" variant="light" class="text-danger font-weight-bolder">16.00 SAHA 3 TRAINING</b-td>
          <b-td colspan="2" variant="success text-center">
            <span class="h4">OFF İZİN</span>
          </b-td>
          <b-td colspan="1" variant="light" class="text-danger font-weight-bolder">16.00 SAHA 3 TRAINING</b-td>
          <b-td colspan="2" variant="success text-center">
            <span class="h4">OFF İZİN</span>
          </b-td>
          <b-td colspan="1" variant="light" class="text-danger font-weight-bolder">16.00 SAHA 3 TRAINING</b-td>
          <b-td colspan="2" variant="success text-center">
            <span class="h4">OFF İZİN</span>
          </b-td>
        </b-tr>
        <b-tr>
          <b-td colspan="1" variant="light">FENERBAHCE U17</b-td>
          <b-td colspan="2" variant="success text-center">
            <span class="h4">OFF İZİN</span>
          </b-td>
          <b-td colspan="1" variant="light" class="text-danger font-weight-bolder">16.00 SAHA 3 TRAINING</b-td>
          <b-td colspan="1" variant="light" class="text-danger font-weight-bolder">16.00 SAHA 3 TRAINING</b-td>
          <b-td colspan="2" variant="success text-center">
            <span class="h4">OFF İZİN</span>
          </b-td>
          <b-td colspan="1" variant="light" class="text-danger font-weight-bolder">16.00 SAHA 3 TRAINING</b-td>
          <b-td colspan="2" variant="success text-center">
            <span class="h4">OFF İZİN</span>
          </b-td>
          <b-td colspan="1" variant="light" class="text-danger font-weight-bolder">16.00 SAHA 3 TRAINING</b-td>
          <b-td colspan="2" variant="success text-center">
            <span class="h4">OFF İZİN</span>
          </b-td>
          <b-td colspan="2" variant="success text-center">
            <span class="h4">OFF İZİN</span>
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        isBusy: true,

        items: [],
        fields: [],
      }
    },
    mounted() {
      var simpleData = []

      // var items = [{ team: "FENERBAHCE - 19", date01: "", date011: "16.00 ANT. TRAINING SAHA 3" }];

      // this.items = items;
      // this.fields = simpleData;
    },

    created() {
      setTimeout(() => {
        this.isBusy = false
      }, 1000)
    },
  }
</script>
