<template>
  <div>
    <div class="card-header p-0 m-0 mb-2">
      <div>
        <b-card-title>
          <feather-icon icon="SettingsIcon" size="24" />
          {{ $t("Competition Events") }}
        </b-card-title>
      </div>
    </div>

    <b-tabs lazy>
      <b-tab v-for="teamItem in ['homeTeam', 'awayTeam']" :key="teamItem">
        <template #title>
          <span class="d-sm-inline">
            <b-avatar :src="competitionForm[teamItem].select.club.logo" style="background: unset"></b-avatar>
            {{ competitionForm[teamItem].select.club.name }}
            {{ competitionForm[teamItem].select.name }}
          </span>
        </template>
        <div class="row">
          <div class="col-md-8">
            <add-match-formation-stat-table
              :helper="helper"
              :competition.sync="competition"
              :competitionForm.sync="competitionForm"
              :teamItem="teamItem"
            />
          </div>
          <div class="col-md-4">
            <add-match-timeline
              v-if="competitionForm[teamItem] && competitionForm[teamItem].select.id"
              :competitionForm="{
                competition: competitionForm.competition,
                location: competitionForm.location,
                homeTeam: competitionForm.homeTeam,
              }"
              :items.sync="getSortEvents"
            ></add-match-timeline>
          </div>
        </div>
      </b-tab>
    </b-tabs>
  </div>
</template>
<script>
  import AddMatchFormationStatTable from "./addMatchFormationStatTable.vue"
  import addMatchTimeline from "./addMatchTimeline.vue"
  export default {
    name: "CompetitionFormationStat",
    components: {
      AddMatchFormationStatTable,
      addMatchTimeline,
    },
    data() {
      return {
        CE: null,
      }
    },
    props: {
      competition: null,
      competitionForm: null,
      helper: null,
    },
    computed: {
      getSortEvents() {
        this.CE = this.competition.competitionEvents.sort((a, b) => {
          return a.minute - b.minute
        })
        return this.CE
      },
    },
    created() {},
    methods: {
      displayToast() {},
    },
    setup() {
      return {}
    },
  }
</script>
<style lang="scss" scoped>
  td {
    input {
      min-width: 5rem;
    }
  }
</style>
