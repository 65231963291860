<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewUserSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ $t("Add New Team") }}
        </h5>

        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
          <!-- {{ $t('Player Full Name') }} -->
          <validation-provider #default="validationContext" name="Team Name" rules="required">
            <b-form-group label="Team Name" label-for="full-name">
              <b-form-input
                id="full-name"
                v-model="teamData.teamName"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="U19"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Antrenor -->
          <validation-provider #default="validationContext" name="Antrenor" rules="required">
            <b-form-group label="Antrenor" label-for="contact">
              <b-form-input
                id="antrenor"
                v-model="teamData.staff.antrenor"
                :state="getValidationState(validationContext)"
                trim
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- YardimciAntrenor -->
          <validation-provider #default="validationContext" name="Yardımcı Antrenor" rules="required">
            <b-form-group label="Yardımcı Antrenor" label-for="company">
              <b-form-input
                id="yardimciAntrenor"
                v-model="teamData.staff.yardimciAntrenor"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Plan -->
          <validation-provider #default="validationContext" name="Plan" rules="required">
            <b-form-group label="Branch" label-for="branch" :state="getValidationState(validationContext)">
              <v-select
                v-model="teamData.branch"
                :options="branchOptions"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :reduce="(val) => val.value"
                :clearable="false"
                input-id="branch"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" type="submit">
              {{ $t("Add") }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              {{ $t("Cancel") }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
  import { BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton } from "bootstrap-vue"
  import { ValidationProvider, ValidationObserver } from "vee-validate"
  import { ref } from "@vue/composition-api"
  import { required, alphaNum, email } from "@validations"
  import formValidation from "@core/comp-functions/forms/form-validation"
  import Ripple from "vue-ripple-directive"
  import vSelect from "vue-select"
  import countries from "@/@fake-db/data/other/countries"
  import store from "@/store"

  export default {
    components: {
      BSidebar,
      BForm,
      BFormGroup,
      BFormInput,
      BFormInvalidFeedback,
      BButton,
      vSelect,

      // Form Validation
      ValidationProvider,
      ValidationObserver,
    },
    directives: {
      Ripple,
    },
    model: {
      prop: "isAddNewUserSidebarActive",
      event: "update:is-add-new-user-sidebar-active",
    },
    props: {
      isAddNewUserSidebarActive: {
        type: Boolean,
        required: true,
      },
      roleOptions: {
        type: Array,
        required: true,
      },
      planOptions: {
        type: Array,
        required: true,
      },
    },
    data() {
      return {
        required,
        alphaNum,
        email,
        countries,
      }
    },
    setup(props, { emit, root }) {
      const blankTeamData = {
        teamName: "U18",
        avatar: "",
        branch: "soccer",
        staff: {
          antrenor: "Erol Bulut",
          yardimciAntrenor: "Bill Gates",
          kondisyoner: "Usain Bolt",
          kaleciAntrenoru: " Michael Schumacher",
        },
      }

      const branchOptions = [
        { label: "Futbol", value: "soccer" },
        { label: "Basketbol", value: "basketball" },
        { label: "Voleybol", value: "volleyball" },
      ]

      const teamData = ref(JSON.parse(JSON.stringify(blankTeamData)))
      const resetteamData = () => {
        teamData.value = JSON.parse(JSON.stringify(blankTeamData))
      }

      const onSubmit = () => {
        store.dispatch("app-team/addData", teamData.value)
        emit("refetch-data")
        emit("update:is-add-new-user-sidebar-active", false)
      }

      const { refFormObserver, getValidationState, resetForm } = formValidation(resetteamData)

      return {
        teamData,
        onSubmit,
        branchOptions,
        refFormObserver,
        getValidationState,
        resetForm,
      }
    },
  }
</script>

<style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";

  #add-new-user-sidebar {
    .vs__dropdown-menu {
      max-height: 200px !important;
    }
  }
</style>
