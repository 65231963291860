<template>
  <div>
    <div>
      <div class="text-center"></div>
      <div class="d-flex align-content-end flex-wrap" style="display: none !important">
        <template v-for="pl in teamProps.team.teamPlayers">
          <div
            class="r frm text-center"
            style="position: relative"
            v-bind:id="pl.id"
            :key="pl.id"
            v-b-tooltip.hover
            :title="pl.name"
            v-if="pl != null || pl != '' || pl != 'null' || (pl && pl.toString() != 'null')"
          >
            <span class="small-text">{{ playerName(pl.name) }}</span>
            <br />
          </div>
        </template>
      </div>

      <div>
        <div class="row row-cols-5 imgbg" :style="{ 'background-image': 'url(' + bgImgUrl + ')' }">
          <div
            v-for="(k, x) in cols"
            :key="x"
            v-bind:id="'boxdrop' + k"
            v-bind:dataid="k"
            class="col"
            v-if="k != null || k != '' || k != 'null' || (k && k.toString() != 'null')"
          >
            <span class="no-text">{{ k }}</span>
            <span class="small-text"></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    components: {},
    props: {
      teamProps: null,
      club: null,
      competitionFormations: null,
      helper: null,
    },
    data() {
      return {
        bgImgUrl: require("@/assets/images/stadium/stadium.png"),
        itemsFormation: null,
        draggingCount: "",
        dragging: "",
        items: [],
        newitems: [],
        dragitem: "",
        cols: [
          "FF LL",
          "FF L",
          "FF",
          "FF R",
          "FF RR",
          "CF LL",
          "CF L",
          "CF",
          "CF R",
          "CF RR",
          "DW LL",
          "DW L",
          "DW",
          "DW R",
          "DW RR",
          "AM LL",
          "AM L",
          "AM",
          "AM R",
          "AM RR",
          "AM I LL",
          "AM I L",
          "AM I",
          "AM I R",
          "AM I RR",
          "MM LL",
          "MM L",
          "MM",
          "MM R",
          "MM RR",
          "DM LL",
          "DM L",
          "DM",
          "DM R",
          "DM RR",
          "GK LL ",
          "GK L",
          "GK",
          "GK R",
          "GK RR",
        ],
      }
    },
    mounted() {
      if (this.competitionFormations) {
        this.customCreateFormation()
      }
    },
    methods: {
      customCreateFormation() {
        Object.values(this.competitionFormations).map((data) => {
          if (data.subPosition && data.isStarter == 1 && data.team.id == this.teamProps.team.id) {
            let nestName = data.subPosition.trim()

            let nest = document.querySelector('[dataid="' + nestName + '"]')

            if (nest) {
              nest.append(document.getElementById(data.player.id))

              this.newitems.push({
                key: {
                  id: nestName,
                  item: data.player.id.toString(),
                },
              })
            }
          }
        })
        this.createFormation()
      },
      createFormation() {
        let countPosition = (countPositionKey) => {
          return Object.values(this.newitems).filter((el) => el.key.id == countPositionKey)
        }
        let countId = ["", "L", "R", "LL", "RR"]
        let findIsStarterPlayer = Object.values(this.competitionFormations).filter(
          (pl) => pl.team.id == this.teamProps.team.id && pl.isStarter === 1,
        )
        // this.newitems = [];
        let tempitems = {}

        Object.values(findIsStarterPlayer).map((pl, index) => {
          if (!pl.subPosition) {
            let playerAvailable = pl.position.availablePositions[0]
            if (!tempitems[playerAvailable]) tempitems[playerAvailable] = []
            tempitems[playerAvailable].push(pl.player.id)
          }
        })
        Object.keys(tempitems).map((data) => {
          Object.values(tempitems[data]).map((index, players) => {
            // console.log(data, index, players, countPosition(data).length);
            if (countPosition(data).length == 1) {
              players = players + 1
            }
            let nestName = (data + " " + countId[players]).trim()
            if (nestName.includes("GK") && nestName.length > 3) return

            let nest = document.querySelector('[dataid="' + nestName + '"]')

            if (nest && true) {
              nest.append(document.getElementById(index))

              this.newitems.push({
                key: {
                  id: nestName,
                  item: index.toString(),
                },
              })
            }
          })
        })
      },
      playerName(fullName) {
        let lastIndex = fullName.lastIndexOf(" ")
        return fullName.slice(0, lastIndex) + " " + fullName[lastIndex + 1] + "."
      },
    },
    setup(props, { root }) {
      return {}
    },
  }
</script>
<style lang="scss" scoped>
  .imgbg {
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position-y: 100% !important;
  }

  div.r {
    width: 30px;
    height: 30px;
    margin: 1pt;
    border-radius: 50%;
    text-align: center;
    display: flex;
    align-content: center;
    align-items: center;
    padding-left: 0.5rem;
  }

  .row > div {
    background: rgba(0, 0, 0, 0.05);
    border: 1px solid black;
    min-height: 70px;
    text-align: center;
    display: flex;
    align-items: center;
    border: 1px solid rgba(0, 0, 0, 0.05);
  }

  div > .no-text {
    display: none;
  }

  .col > div.r {
    cursor: pointer;
    margin: 0 6%;
  }

  .r.frm {
    cursor: pointer;

    > span.badge {
      position: absolute;
      z-index: 2;
      zoom: 0.8;
      margin-top: 5px;
    }
  }

  div.r:hover {
    transform: scale(1.3);
  }
</style>
