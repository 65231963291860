import http from "@/libs/axios"
import toastService from "./toastService"
import i18n from "@/libs/i18n"

export default class teamService {
  constructor() {}

  async get(queryParams = null) {
    return new Promise((resolve, reject) => {
      http
        .get("/team?row=999", { params: queryParams })
        .then((response) => {
          return resolve(response)
        })
        .catch((error) => reject(error))
    })
  }

  async getByTeamAndSeason(teamId, seasonId, params = null) {
    return new Promise((resolve, reject) => {
      http
        .get(`/season/${seasonId}/team/${teamId}`, params)
        .then((response) => {
          return resolve(response)
        })
        .catch((error) => reject(error))
    })
  }

  async getAllTeamsPlayersBySeason(seasonId, params = null) {
    return new Promise((resolve, reject) => {
      http
        .get(`/season/${seasonId}/teams-players`, { params })
        .then((response) => {
          return resolve(response)
        })
        .catch((error) => reject(error))
    })
  }

  async getTeamsCompetitionStatistics(seasonId, params) {
    return http
      .get(`/season/${seasonId}/competition/statistics`, { params })
      .then((response) => new Promise((resolve) => resolve(response.data)))
      .catch((error) => new Promise((reject) => reject(error)))
  }

  async event({ data, dataId }) {
    if (data && dataId) {
      return new Promise((resolve, reject) => {
        http
          .put(`/team/${dataId}`, data)
          .then((response) => {
            toastService(i18n.t("Successfully Updated"), "PlusIcon", "success")
            return resolve(response)
          })
          .catch((error) => reject(error))
      })
    } else if (dataId) {
      return new Promise((resolve, reject) => {
        http
          .delete(`/team/${dataId}`)
          .then((response) => {
            toastService(i18n.t("Successfully Deleted"), "PlusIcon", "success")
            return resolve(response)
          })
          .catch((error) => reject(error))
      })
    } else if (data) {
      return new Promise((resolve, reject) => {
        http
          .post("/team", data)
          .then((response) => {
            toastService(i18n.t("Successfully Created"), "PlusIcon", "success")
            return resolve(response.data)
          })
          .catch((error) => reject(error))
      })
    }
  }
}
