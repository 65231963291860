<template>
  <div>
    <input
      :disabled="disabled"
      placeholder=""
      v-model="comment"
      @change="changeComment"
      type="text"
      class="form-control comment"
    />
  </div>
</template>

<script>
  import { ref, watch, onBeforeMount, onMounted } from "@vue/composition-api"

  import axios from "@axios"
  import { useRouter } from "@core/utils/utils"

  import { useToast } from "vue-toastification/composition"
  import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
  import i18n from "@/libs/i18n"

  export default {
    components: {},
    props: {
      player: {
        type: Object,
        default: {},
      },
      items: {
        required: true,
        type: Array,
        default: () => [],
      },
      disabled: {
        type: Boolean,
        default: false,
      },
    },
    setup(props, { emit, root }) {
      const { router, route } = useRouter()
      const toast = useToast()
      const comment = ref(null)
      const tempItem = ref(null)
      const setItem = () => {
        props.items.filter((item) => {
          if (item) {
            if (item.player.id === props.player.id) {
              comment.value = item.comment
              tempItem.value = item
            }
          }
        })
      }

      const changeComment = () => {
        let object = {
          player: props.player.id,
          comment: comment.value,
          performance: parseInt(router.currentRoute.params.performanceId),
        }
        if (tempItem.value) {
          axios.put(`performance-ability-comment/${tempItem.value.id}`, object).then((response) => {
            toast({
              component: ToastificationContent,
              position: "bottom-right",
              props: {
                title: i18n.t("Successfully Saved"),
                icon: "CheckIcon",
                variant: "success",
              },
            })
          })
        } else {
          axios.post(`performance-ability-comment`, object).then((response) => {
            tempItem.value = response.data
            toast({
              component: ToastificationContent,
              position: "bottom-right",
              props: {
                title: i18n.t("Successfully Added"),
                icon: "CheckIcon",
                variant: "success",
              },
            })
          })
        }
      }

      watch(
        () => props.comment,
        (val, oldVal) => {
          if (val !== oldVal) {
            setItem()
          }
        },
      )

      onMounted(() => {
        setItem()
      })

      return { comment, changeComment }
    },
  }
</script>

<style lang="scss">
  .comment {
    @media (max-width: 767px) {
      min-width: 320px;
    }
  }
</style>
