import axios from "@axios"

export default {
  namespaced: true,
  state: {
    teams: {},
    members: {},
    member: {},
  },
  getters: {
    teams: (state) => state.teams,
    members: (state) => state.members,
  },
  mutations: {
    // TEAMS
    ADD_TEAM(state, item) {
      state.teams.unshift(item)
    },
    SET_TEAM(state, itemData) {
      state.teams = itemData
    },
    UPDATE_TEAM(state, item) {
      const itemIndex = state.teams.findIndex((i) => i.id === item.id)
      Object.assign(state.teams[itemIndex], item)
    },
    REMOVE_TEAM(state, itemId) {
      const ItemIndex = state.teams.findIndex((i) => i.id === itemId)
      state.teams.splice(ItemIndex, 1)
    },

    // MEMBERS
    ADD_MEMBER(state, item) {
      state.members.unshift(item)
    },
    SET_MEMBER(state, itemData) {
      state.members = itemData
    },
    UPDATE_MEMBER(state, item) {
      const itemIndex = state.members.findIndex((i) => i.id === item.id)
      Object.assign(state.members[itemIndex], item)
    },
    REMOVE_MEMBER(state, itemId) {
      const ItemIndex = state.members.findIndex((i) => i.id === itemId)
      state.members.splice(ItemIndex, 1)
    },
  },

  actions: {
    fetchItems({ commit }, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get("/apps/match/matches", { params: queryParams })
          .then((response) => {
            // commit('SET_TEAM', response.data)
            resolve(response)
          })
          .catch((error) => reject(error))
      })
    },

    // fetchTeamMembers({ commit }, queryParams) {
    //   return new Promise((resolve, reject) => {
    //     axios
    //       .get('/apps/team/members', { params: queryParams })
    //       .then(response => {
    //          //commit('SET_MEMBER', response.data)
    //         resolve(response)
    //       })
    //       .catch(error => reject(error))
    //   })
    // },

    // addUser(ctx, userData) {
    //   return new Promise((resolve, reject) => {
    //     axios
    //       .post('/apps/user/users', { user: userData })
    //       .then(response => resolve(response))
    //       .catch(error => reject(error))
    //   })
    // },
  },
}
