import http from "@/libs/axios"
import toastService from "./toastService"
import i18n from "@/libs/i18n"

export default class staffService {
  constructor() {}

  async getStaffs({ seasonId, teamId }) {
    return new Promise((resolve, reject) => {
      http
        .get(`/staff/list`)
        .then((response) => {
          return resolve(response)
        })
        .catch((error) => reject(error))
    })
  }

  async getStaffRoles() {
    return new Promise((resolve, reject) => {
      http
        .get("/staff/roles")
        .then((response) => {
          return resolve(response)
        })
        .catch((error) => reject(error))
    })
  }
  async getChildStaffs(id) {
    return new Promise((resolve, reject) => {
      http
        .post("/staff/list", id)
        .then((response) => {
          return resolve(response)
        })
        .catch((error) => reject(error))
    })
  }

  async findByUserId(id) {
    return new Promise((resolve, reject) => {
      http
        .post(`/staff/user-id/${id}`)
        .then((response) => {
          return resolve(response)
        })
        .catch((error) => reject(error))
    })
  }

  async get(id) {
    return new Promise((resolve, reject) => {
      http
        .post(`/staff/${id}`)
        .then((response) => {
          return resolve(response)
        })
        .catch((error) => reject(error))
    })
  }

  async add(staff) {
    return new Promise((resolve, reject) => {
      http
        .post("/staff/create", staff)
        .then((response) => {
          toastService(i18n.t("Successfully Created"), "PlusIcon", "success")
          return resolve(response)
        })
        .catch((error) => reject(error))
    })
  }

  async update(staff) {
    return new Promise((resolve, reject) => {
      http
        .put(`/staff/${staff.id}`, staff)
        .then((response) => {
          toastService(i18n.t("Successfully Updated"), "PlusIcon", "success")
          return resolve(response)
        })
        .catch((error) => reject(error))
    })
  }
  async delete(id) {
    return new Promise((resolve, reject) => {
      http
        .delete(`/staff/${id}`)
        .then((response) => {
          toastService(i18n.t("Successfully Deleted"), "PlusIcon", "success")
          return resolve(response)
        })
        .catch((error) => reject(error))
    })
  }

  async addCareer(staff) {
    return new Promise((resolve, reject) => {
      http
        .post(`/staff/${staff.staff}/career/create`, staff)
        .then((response) => {
          toastService(i18n.t("Successfully Created"), "PlusIcon", "success")
          return resolve(response)
        })
        .catch((error) => reject(error))
    })
  }

  async updateCareer(career) {
    console.log(career)
    return new Promise((resolve, reject) => {
      http
        .put(`/staff/career/${career.id}`, career)
        .then((response) => {
          toastService(i18n.t("Successfully Updated"), "PlusIcon", "success")
          return resolve(response)
        })
        .catch((error) => reject(error))
    })
  }
}
