var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"show":_vm.loading,"rounded":"sm"}},[_c('b-card',{attrs:{"title":_vm.$t('Add a New Training')}},[_c('validation-observer',{ref:"trainingInitialDataForm"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"4","xl":"2"}},[_c('label',[_vm._v(_vm._s(_vm.$t("Training Name")))]),_c('validation-provider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-input-group',[_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"type":"text","placeholder":_vm.$t('Training Name')},model:{value:(_vm.trainingInitForm.title),callback:function ($$v) {_vm.$set(_vm.trainingInitForm, "title", $$v)},expression:"trainingInitForm.title"}})],1)],1),_c('small',{staticClass:"text-danger mt-1"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"md":"4","xl":"3"}},[_c('label',[_vm._v(_vm._s(_vm.$t("Training Date")))]),_c('validation-provider',{attrs:{"name":"startDate","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-input-group',[_c('b-form-datepicker',{attrs:{"placeholder":_vm.$t('Training Date'),"state":errors.length > 0 ? false : null,"obj":_vm.trainingInitForm.startDate,"locale":_vm.lang},model:{value:(_vm.trainingInitForm.startDate),callback:function ($$v) {_vm.$set(_vm.trainingInitForm, "startDate", $$v)},expression:"trainingInitForm.startDate"}})],1)],1),_c('small',{staticClass:"text-danger mt-1"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"md":"4","xl":"2"}},[_c('label',[_vm._v(_vm._s(_vm.$t("Time")))]),_c('validation-provider',{attrs:{"name":"startTime","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-input-group',[_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"type":"text","placeholder":"HH:mm"},model:{value:(_vm.trainingInitForm.startTime),callback:function ($$v) {_vm.$set(_vm.trainingInitForm, "startTime", $$v)},expression:"trainingInitForm.startTime"}}),_c('b-input-group-append',[_c('b-form-timepicker',{attrs:{"state":errors.length > 0 ? false : null,"no-close-button":"","button-only":"","button-variant":"outline-info","left":"","size":"sm","locale":_vm.lang},model:{value:(_vm.trainingInitForm.startTime),callback:function ($$v) {_vm.$set(_vm.trainingInitForm, "startTime", $$v)},expression:"trainingInitForm.startTime"}})],1)],1)],1),_c('small',{staticClass:"text-danger mt-1"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"6","xl":"5"}},[_c('label',[_vm._v(_vm._s(_vm.$t("Location")))]),_c('validation-provider',{attrs:{"name":"location","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('v-select',{attrs:{"autocomplete":"on","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.locations,"reduce":function (option) { return option.id; },"getOptionLabel":function (option) { return option.name; }},on:{"search":_vm.searchLocation},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var name = ref.name;
return [(name)?_c('div',[(name)?_c('span',[_vm._v(" "+_vm._s(name))]):_vm._e()]):_vm._e()]}},{key:"no-options",fn:function(ref){
var search = ref.search;
return [_c('div',{on:{"click":function($event){return _vm.addThisLocation(search)}}},[_c('feather-icon',{attrs:{"icon":"PlusCircleIcon","size":"14"}}),_c('span',[_vm._v(" "+_vm._s(_vm.$t("Do you want to add this item?")))])],1)]}},{key:"selected-option",fn:function(ref){
var name = ref.name;
return [(name)?_c('div',[_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"text-nowrap",attrs:{"title":name}},[_vm._v(_vm._s(name.substring(0, 50))+"...")])]):_vm._e()]}}],null,true),model:{value:(_vm.trainingInitForm.location),callback:function ($$v) {_vm.$set(_vm.trainingInitForm, "location", $$v)},expression:"trainingInitForm.location"}})],1),_c('small',{staticClass:"text-danger mt-1"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{attrs:{"clas":"d-flex items-end justify-content-end"}},[(!_vm.trainingData)?_c('button',{staticClass:"btn btn-primary float-right",on:{"click":function($event){$event.preventDefault();return _vm.addInitialTraining($event)}}},[_vm._v(" "+_vm._s(_vm.$t("Add"))+" ")]):_c('button',{staticClass:"btn btn-primary float-right",on:{"click":function($event){$event.preventDefault();return _vm.saveTraining($event)}}},[_vm._v(" "+_vm._s(_vm.$t("Change"))+" ")])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }