<template>
  <div>
    <div class="d-flex justify-content-center">
      <b-button variant="primary" class="mt-1" @click="printPage()"> {{ $t("Print Page") }}</b-button>
    </div>

    <div class="printable mt-2" id="section-to-print">
      <b-row v-if="userData">
        <b-col xl="12" class="player-top-bar-info">
          <b-card class="pb-0">
            <b-row class="topbar">
              <b-col md="4" @click="editPlayer()">
                <div class="d-flex mt-2">
                  <div class="p-0">
                    <b-avatar
                      v-if="!userData.avatar"
                      size="120"
                      :src="userData != undefined ? userData.avatar : null"
                      :text="avatarText(userData.name)"
                      :variant="`light-primary`"
                      rounded
                    />
                    <div v-else style="overflow: hidden; width: 120px; height: 120px">
                      <img :src="userData.avatar" style="width: 120px" alt="" />
                    </div>
                  </div>

                  <div>
                    <b-link class="mt-0 ml-1 d-block h4">{{ userData.name }}</b-link>
                    <span class="mt-0 ml-1 d-block"
                      >{{ userData.birthdate | nativeDate }} ({{ getAge(userData.birthdate) }})</span
                    >
                    <span class="mt-0 ml-1 d-block">{{ $t("license") }}: {{ userData.licenseNo || "-" }}</span>
                    <div class="p-1 d-flex justify-content-start">
                      <span class="badge badge-primary mr-1 w-50">{{ $t(userData.position.name) }}</span>
                      <span class="badge badge-dark w-50"
                        >{{
                          userData.foot === 1
                            ? $t("Right Foot")
                            : userData.foot === 0
                            ? $t("Left Foot")
                            : $t("Both Feet")
                        }}
                      </span>
                    </div>
                    <div>
                      <div class="ml-1 d-flex justify-content-start">
                        <b-badge variant="primary" class="mr-1 w-50">
                          {{
                            (testsUnique.find((d) => d.name == "height")
                              ? testsUnique.find((d) => d.name == "height").value
                              : "") + " cm"
                          }}
                        </b-badge>
                        <b-badge variant="dark" class="w-50">
                          {{
                            (testsUnique.find((d) => d.name == "weight")
                              ? testsUnique.find((d) => d.name == "weight").value
                              : "") + " kg"
                          }}
                        </b-badge>
                      </div>
                    </div>
                  </div>
                </div>
              </b-col>

              <!-- KATILIM -->
              <b-col md="4" v-if="!showLoading">
                <training-participate :attendance="attendance" />
              </b-col>

              <!-- SON 5 ANTREANMAN -->
              <b-col md="4" v-if="performances && performances.average">
                <span
                  class="d-block h1 text-center p-2 bg-gradient-primary text-white w-100 h-100 pt-4 display-1 rounded"
                >
                  {{ performances.average }}
                </span>
              </b-col>
            </b-row>
          </b-card>
        </b-col>

        <!-- BOY & KILO -->
        <b-col xl="3" v-if="0" class="player-top-bar-phy">
          <b-card class="p-0" style="min-height: 20vh" no-body>
            <div class="w-100 cards-tests"></div>
          </b-card>
        </b-col>
      </b-row>

      <b-card title="Performans" v-if="performances && performances.player">
        <div class="d-flex justify-content-between">
          <div
            class="display-4 text-center p-2 bg-gradient-primary shadow-lg text-white rounded w-100 mr-1"
            v-for="(x, index) in performances.player"
            :key="index"
          >
            {{ x.average }}
            <span class="h2 d-block w-100 text-center mt-2 text-white" v-text="x.parent"></span>
          </div>
        </div>
      </b-card>

      <b-overlay :show="!radarChart.trigger" rounded="sm" spinner-variant="primary" spinner-type="grow" spinner-small>
        <b-card title="Performans Verileri" :key="radarChart.trigger">
          <div v-if="radarChart.trigger > 0">
            <vue-apex-charts type="radar" height="500" :options="radarChart.chartOptions" :series="radarChart.series" />
          </div>
        </b-card>
      </b-overlay>

      <b-card :title="$t('Statistics Data')">
        <table class="table table-bordered compares">
          <tbody>
            <template v-for="(k, x) in compareValues">
              <tr :key="'1'">
                <td v-text="'Oynadığı Maç Sayısı'" :class="tableClass"></td>
                <td :class="tableClass" v-text="k.competition"></td>
              </tr>
              <tr :key="'2'">
                <td v-text="'İlk 11'" :class="tableClass"></td>
                <td :class="tableClass" v-text="k.stats.IS_STARTER"></td>
              </tr>
              <tr :key="'3'">
                <td v-text="'Oyunda Kaldığı Süre'" :class="tableClass"></td>
                <td :class="tableClass" v-text="k.stats.PLAYER_TIME_IN_THE_GAME"></td>
              </tr>
              <tr :key="'4'">
                <td v-text="'Sarı Kart'" :class="tableClass"></td>
                <td :class="tableClass" v-text="k.event.YELLOW_CARD"></td>
              </tr>
              <tr :key="'5'">
                <td v-text="'Kırmızı Kart'" :class="tableClass"></td>
                <td :class="tableClass" v-text="k.event.RED_CARD"></td>
              </tr>
              <tr :key="'6'">
                <td v-text="'Kazanç/Beraber/Kayıp'" :class="tableClass"></td>
                <td :class="tableClass" v-text="k.stats.WDL"></td>
              </tr>
              <tr :key="'7'">
                <td v-text="'Atılan Gol/Yenilen Gol (Takımın)'" :class="tableClass"></td>
                <td :class="tableClass" v-text="k.stats.GOAL_BY_COMPETITION_AG"></td>
              </tr>
              <tr :key="'8'">
                <td v-text="'Maç Başına Puan'" :class="tableClass"></td>
                <td :class="tableClass" v-text="k.stats.TEAM_POINTS_AVERAGE"></td>
              </tr>
            </template>
          </tbody>
        </table>

        <b-table
          v-if="false"
          sort-by="ps"
          hover
          :fields="[
            { key: 'player', label: 'Oyuncu', sortable: true },
            { key: 'competition', label: 'Oynadığı Maç Sayısı', sortable: true },
            { key: 'stats.IS_STARTER', label: 'İlk 11', sortable: true },
            { key: 'stats.PLAYER_TIME_IN_THE_GAME', label: 'Oyunda Kaldığı Süre', sortable: true },
            { key: 'event.YELLOW_CARD', label: 'Sarı Kart', sortable: true },
            { key: 'event.RED_CARD', label: 'Kırmızı Kart', sortable: true },
            { key: 'stats.WDL', label: 'Kazanç/Beraber/Kayıp', sortable: true },
            { key: 'stats.GOAL_BY_COMPETITION_AG', label: 'Atılan Gol/Yenilen Gol (Takımın)', sortable: true },
            { key: 'stats.TEAM_POINTS_AVERAGE', label: 'Maç Başına Puan', sortable: true },
            { key: 'stats.REVIEWS.coachReview', label: 'Performans Skorları', sortable: true },
            { key: 'stats.REVIEWS.playerReview', label: 'Yük Puanları', sortable: true },
          ]"
          :items="compareValues"
        >
          <template #head(competition)="scope">
            <div v-b-tooltip.hover :title="$t('Match played')">{{ $t("OMS") }}</div>
          </template>
          <template #head(stats.REVIEWS.coachReview)="scope">
            <div v-b-tooltip.hover :title="$t('Performance Score')">PS</div>
          </template>
          <template #head(stats.PLAYER_TIME_IN_THE_GAME)="scope">
            <div v-b-tooltip.hover :title="$t('Time Played')">{{ $t("TP") }}</div>
          </template>

          <template #cell(player)="data">
            <b-avatar :src="data.item.player ? data.item.player.avatar : null"></b-avatar>
            <span class="ml-1" style="margin-top: 0">
              {{ data.item.player ? data.item.player.name.toString().substr(0, 12) : "" }}
            </span>
          </template>
        </b-table>
      </b-card>

      <b-card title="Teknik Sorumlu Değerlendirmesi" class="pb-0">
        <div style="min-height: 200px" class="bordered border"></div>
        <div class="d-flex justify-content-between mx-n1 w-100 mb-n2" style="padding: 0">
          <b-card title="Sonuç" class="w-100">
            <div style="min-height: 70px" class="w-100 bordered border"></div>
          </b-card>
          <b-card title="İmza" class="w-100">
            <div style="min-height: 70px" class="w-100 bordered border"></div>
          </b-card>
        </div>
      </b-card>
    </div>
  </div>
</template>
<script>
  import { avatarText } from "@core/utils/filter"
  import axios from "@axios"
  import { onMounted, onUnmounted, ref } from "@vue/composition-api"
  import router from "@/router"
  import store from "@/store"
  import playerStoreModule from "../playerStoreModule"
  import PlayerAccount from "./PlayerAccount.vue"
  import PlayerMatches from "./PlayerMatches.vue"
  import PlayerHistory from "./PlayerHistory.vue"
  import PlayerTrainings from "./PlayerTrainings.vue"
  import PlayerPerformance from "./PlayerPerformance.vue"
  import PlayerTest from "./PlayerTest.vue"
  import PlayerCard from "@/views/apps/player-card/PlayerCard"

  import TrainingParticipate from "./components/TrainingParticipate.vue"
  import TrainingPerformance from "./components/TrainingPerformance.vue"
  import PhysicsChart from "./components/PhysicsChart.vue"

  import { useToast } from "vue-toastification/composition"
  import i18n from "@/libs/i18n"
  // import html2canvas from 'html2canvas'
  import VueApexCharts from "vue-apexcharts"

  export default {
    components: {
      PlayerAccount,
      PlayerTest,
      PlayerHistory,
      PlayerPerformance,
      PlayerCard,
      PlayerMatches,
      TrainingParticipate,
      TrainingPerformance,
      PlayerTrainings,
      PhysicsChart,

      VueApexCharts,
    },
    metaInfo: {
      script: [
        {
          // rel: "preload",
          src: "https://unpkg.com/html2canvas@1.0.0-alpha.12/dist/html2canvas.min.js",
        },
      ],
    },
    data() {
      return {
        historyData: [],
      }
    },
    setup(props, { root }) {
      const showLoading = ref(false)
      const showPlayerAccount = ref(false)
      const userData = ref(null)
      const attendance = ref(null)
      const performances = ref(null)
      const playerPerformances = ref(null)
      const playerLearningOutcomes = ref(null)
      const playerTransferHistories = ref(null)
      const tests = ref(null)
      const competitions = ref(null)
      const toast = useToast()
      const seasonId = ref(Number(router.currentRoute.params.seasonId))
      const teamId = ref(Number(router.currentRoute.params.id))
      const compareValues = ref()

      const STORE_MODULE_NAME = "app-player"

      // Register module
      if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, playerStoreModule)

      // UnRegister on leave
      onUnmounted(() => {
        if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
      })

      onMounted(async () => {
        await fetchPlayer()
        await fetchUserAttendance()
        await fetchUserTest()
        await fetchUserCompetitions()
        await getchPlayerPerformances()

        var { data } = await axios.get("/player-compare?player=" + router.currentRoute.params.playerId)
        compareValues.value = data
      })

      const fetchAbilities = async () => {
        await axios
          .get(`/ability`)
          .then((response) => {
            radarChart.value.series[0].data = response.data.map((i) => {
              return {
                x: i.name,
                y: 0,
              }
            })
            radarChart.value.series[1].data = response.data.map((i) => {
              return {
                x: i.name,
                y: 0,
              }
            })
            radarChart.value.series[2].data = response.data.map((i) => {
              return {
                x: i.name,
                y: 0,
              }
            })
          })
          .catch((error) => {
            console.log(error)
          })
      }

      const fetchPlayer = () => {
        axios
          .get(`/player/${router.currentRoute.params.playerId}`)
          .then((response) => {
            userData.value = response.data
            playerTransferHistories.value = response.data.playerTransferHistories
          })
          .catch((error) => {
            console.log(error)
          })
      }

      const fetchUserAttendance = () => {
        showLoading.value = true
        axios
          .get(`/season/${seasonId.value}/training-attendant/player/${router.currentRoute.params.playerId}`)
          .then((response) => {
            ;(attendance.value = response.data.trainingAttendants),
              (playerLearningOutcomes.value = response.data.playerLearningOutcomes),
              (showPlayerAccount.value = true)
            showLoading.value = false
          })
          .catch((error) => {
            showLoading.value = false
            console.log(error)
          })
      }

      const getAge = (birthDate) => {
        var birthDate = new Date(birthDate)
        var now = new Date()
        let otherDate = new Date(now.getFullYear(), now.getMonth(), now.getDate())

        var years = otherDate.getFullYear() - birthDate.getFullYear()

        if (
          otherDate.getMonth() < birthDate.getMonth() ||
          (otherDate.getMonth() == birthDate.getMonth() && otherDate.getDate() < birthDate.getDate())
        ) {
          years--
        }

        return years
      }

      const testsUnique = ref([])
      let uniqs = []

      const fetchUserTest = () => {
        axios
          .get(`/season/${seasonId.value}/test/player/${router.currentRoute.params.playerId}`)
          .then((response) => {
            tests.value = response.data
            tests.value
              .sort((a, b) => b.id - a.id)
              .map((d) => {
                if (!uniqs[d.name]) {
                  uniqs[d.name] = d.value
                  testsUnique.value.push({
                    name: d.name,
                    value: d.value,
                  })
                }
              })
          })
          .catch((error) => {
            console.log(error)
          })
      }

      const fetchUserPerformances = async () => {
        await axios
          .get(
            `/season/${seasonId.value}/performance/ability-item/team/${router.currentRoute.params.id}/player/${router.currentRoute.params.playerId}`,
          )
          .then((response) => {
            performances.value = response.data
          })
          .catch((error) => {
            console.log(error)
          })
      }

      const getchPlayerPerformances = () => {
        axios
          .get(
            `/season/${seasonId.value}/performance/team/${router.currentRoute.params.id}/player/${router.currentRoute.params.playerId}`,
          )
          .then((response) => {
            playerPerformances.value = response.data
          })
          .catch((error) => {
            console.log(error)
          })
      }

      const competitionsDetails = ref({
        assist: 0,
        goal: 0,
        duration: 0,
        yellowCard: 0,
        redCard: 0,
        yellowRedCard: 0,
        isStarter: 0,
      })

      const fetchUserCompetitions = () => {
        axios
          .get(`/season/${seasonId.value}/competition/player/${router.currentRoute.params.playerId}`)
          .then((response) => {
            competitions.value = response.data
            Object.values(response.data).map((d) => {
              competitionsDetails.value.assist += d.ASSIST ? d.ASSIST.length : 0
              competitionsDetails.value.goal += d.GOAL ? d.GOAL.length : 0
              competitionsDetails.value.duration += d.duration ? Number(d.duration) : 0
              competitionsDetails.value.yellowCard += d.YELLOW_CARD ? d.YELLOW_CARD.length : 0
              competitionsDetails.value.redCard += d.RED_CARD ? d.RED_CARD.length : 0
              competitionsDetails.value.yellowRedCard += d.YELLOW_RED_CARD ? d.YELLOW_RED_CARD.length : 0
              competitionsDetails.value.isStarter += d.isStarter == 1 ? 1 : 0
              competitionsDetails.value.isNotStarter += d.isStarter == 1 ? 0 : 0
            })
          })
          .catch((error) => {
            console.log(error)
          })
      }

      const chartColors = {
        primaryColorShade: "#836AF9",
        yellowColor: "#ffe800",
        successColorShade: "#28dac6",
        warningColorShade: "#ffe802",
        warningLightColor: "#FDAC34",
        infoColorShade: "#299AFF",
        greyColor: "#4F5D70",
        blueColor: "#2c9aff",
        blueLightColor: "#84D0FF",
        greyLightColor: "#EDF1F4",
        tooltipShadow: "rgba(0, 0, 0, 0.25)",
        lineChartPrimary: "#666ee8",
        lineChartDanger: "#ff4961",
        labelColor: "#6e6b7b",
        grid_line_color: "rgba(200, 200, 200, 0.2)",
        color1: "#648FFF",
        color2: "#DC267F",
        color3: "#FFB000",
      }

      const printPage = () => {
        html2canvas(document.querySelector(".printable"), {
          letterRendering: 1,
          allowTaint: true,
          logging: true,
          useCORS: true,
        }).then((canvas) => {
          document.querySelector(".printable").appendChild(canvas)
          setTimeout(() => {
            var a = window.open()
            a.document.write('<img style="width:100%;" src=' + document.querySelector("canvas").toDataURL() + ">")
            setTimeout(() => a.print(), 1000)
            setTimeout(() => a.close(), 1100)

            setTimeout(() => document.querySelector("canvas").remove(), 1500)
          })
        })
      }

      const radarChart = ref({
        trigger: 0,
        series: [
          {
            name: i18n.t("Player Avg."),
            data: [],
          },
          {
            name: i18n.t("Team Avg."),
            data: [],
          },
          {
            name: i18n.t("Position Avg."),
            data: [],
          },
        ],
        chartOptions: {
          chart: {
            toolbar: {
              show: false,
            },
            dropShadow: {
              enabled: true,
              blur: 5,
              left: 1,
              top: 1,
              opacity: 0.002,
            },
          },
          legend: {
            show: true,
            fontSize: "14px",
            fontFamily: "Montserrat",
          },
          yaxis: {
            show: true,
            min: 0,
            max: 10,
            tickAmount: 5,
          },
          colors: [
            chartColors.color1,
            chartColors.color2,
            chartColors.color3,
            chartColors.infoColorShade,
            chartColors.infoColoblueColorrShade,
          ],
          xaxis: {},
          fill: {
            opacity: [0.5, 0.5, 0.5],
          },
          stroke: {
            show: true,
            width: 3,
          },
          markers: {
            size: 5,
          },
          grid: {
            show: false,
          },
        },
      })

      const setConditionChartDataPlayer = () => {
        if (!performances.value || (performances.value && !performances.value.player)) {
          radarChart.value.trigger++
          return false
        }

        for (let [key, value] of Object.entries(performances.value.player)) {
          let avg = 0
          let itemCount = 0

          Object.values(value.items).filter((item) => {
            itemCount += item.length
            item.forEach((data) => {
              avg += data.rate
            })
          })
          let avgValue = Math.round(avg / itemCount)
          let itemIndex = radarChart.value.series[0].data.findIndex((i) => i.x === value.parent)
          radarChart.value.series[0].data[itemIndex].y = avgValue
        }
        radarChart.value.trigger++
      }

      const setConditionChartDataTeam = () => {
        if (!performances.value || (performances.value && !performances.value.team)) {
          radarChart.value.trigger++
          return false
        }

        for (let [key, value] of Object.entries(performances.value.team)) {
          let avg = 0
          let itemCount = 0

          Object.values(value.items).filter((item) => {
            itemCount += item.length
            item.forEach((data) => {
              avg += data.rate
            })
          })
          let avgValue = Math.round(avg / itemCount)
          let itemIndex = radarChart.value.series[1].data.findIndex((i) => i.x === value.parent)
          radarChart.value.series[1].data[itemIndex].y = avgValue
        }
        radarChart.value.trigger++
      }

      const setConditionChartDataPOS = () => {
        if (!performances.value || (performances.value && !performances.value.position)) {
          radarChart.value.trigger++
          return false
        }

        for (let [key, value] of Object.entries(performances.value.position)) {
          let avg = 0
          let itemCount = 0

          Object.values(value.items).filter((item) => {
            itemCount += item.length
            item.forEach((data) => {
              avg += data.rate
            })
          })
          let avgValue = Math.round(avg / itemCount)
          let itemIndex = radarChart.value.series[2].data.findIndex((i) => i.x === value.parent)
          radarChart.value.series[2].data[itemIndex].y = avgValue
        }
        radarChart.value.trigger++
      }

      const setChartData = () => {
        if (!playerLearningOutcomes) return false
        let dataset = {
          labels: [],
          data: [],
          backgroundColor: [
            chartColors.primaryColorShade,
            chartColors.yellowColor,
            chartColors.successColorShade,
            chartColors.warningColorShade,
            chartColors.infoColorShade,
            chartColors.infoColoblueColorrShade,
            chartColors.tooltipShadow,
            chartColors.lineChartDanger,
            chartColors.labelColor,
            chartColors.grid_line_color,
          ],
          borderWidth: 0,
          pointStyle: "rectRounded",
        }
      }

      onMounted(async () => {
        await fetchUserPerformances()
        await fetchAbilities()
        await setChartData()
        setTimeout(() => {
          setConditionChartDataPlayer()
          setConditionChartDataTeam()
          setConditionChartDataPOS()
        }, 4000)
      })

      const tableClass = ref("text-bold font-weight-bold font-bold h3")

      const getPerformancesKey = () => {
        return (
          performances.value.player &&
          performances.value.player.map((i, d) => {
            console.log(i)
            return i
          })
        )
      }

      return {
        getPerformancesKey,
        tableClass,
        printPage,
        avatarText,
        showLoading,
        showPlayerAccount,
        userData,
        attendance,
        getAge,
        tests,
        playerLearningOutcomes,
        playerTransferHistories,
        competitions,
        competitionsDetails,
        performances,
        playerPerformances,
        radarChart,
        testsUnique,

        compareValues,
      }
    },
  }
</script>
<style>
  #section-to-print,
  .printable {
  }

  @media print {
    .printable {
      transform: scale(0.8);
      -moz-transform: scale(0.8);
      -ms-transform: scale(0.8);
      -webkit-transform: scale(0.8);
      margin-top: -5%;
    }

    #section-to-print canvas {
      display: block !important;
    }
  }

  .compares tr td:first-child {
    width: 35%;
  }
</style>
