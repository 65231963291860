<template>
  <div>
    <b-overlay variant="white" :show="loading" spinner-variant="primary" blur="0" opacity=".75" rounded="sm">
      <b-row>
        <b-col lg="3">
          <b-card>
            <b-form-group :label="$t('Drill Name')" label-for="name">
              <b-form-input v-model="template.name" id="name" :placeholder="$t('Drill Name')" />
            </b-form-group>
            <span class="mr-25 small">{{ $t("Planned Duration") }}</span>
            <b-input-group :append="$t('dk')" class="mb-1">
              <b-form-input class="w-75" v-model.number="template.duration" id="time" type="number" />
            </b-input-group>

            <b-form-group :label="$t('Purpose')" v-if="learningOutcomes">
              <!-- <v-select
              ref="librarySelectInput"
              v-model="trainingLibrary.learningOutcomes"
              multiple
              push-tags
              @search="searchLearningOutcome"
              :filter="filterOptions"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="learningOutcomes"
              :reduce="(option) => option.id" -->

              <v-select
                ref="librarySelectInput"
                v-model="template.learningOutcomes"
                multiple
                push-tags
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="Object.values(learningOutcomes)"
                :reduce="(option) => option"
                :getOptionLabel="(option) => option.name"
              >
                <!-- <template #option="{ name, learningOutcomeCategory }">
                <div v-if="name">
                  <cite> {{ learningOutcomeCategory.name }} > {{ name }}</cite>
                </div>
              </template> -->

                <template #selected-option="{ name }">
                  <div v-if="name">
                    <cite> {{ name }}</cite>
                  </div>
                </template>

                <template #no-options="{ search, searching }">
                  <span> {{ $t("No Result") }}</span>
                </template>
              </v-select>
            </b-form-group>
            <b-form-group :label="$t('Description')" label-for="description">
              <div class="d-flex">
                <b-form-textarea
                  id="description"
                  v-model="template.description"
                  :placeholder="$t('Enter Description')"
                  rows="4"
                />
              </div>
            </b-form-group>
          </b-card>
        </b-col>
        <b-col lg="9">
          <b-row>
            <b-col md="12">
              <b-card v-if="template.trainingItemTemplateMedia.length > 0">
                <ck-editor-component
                  v-for="item in template.trainingItemTemplateMedia.filter((i) => i.type != 'video')"
                  :key="item.id"
                  v-model="item.path"
                  :editorData="item.path"
                  @changedCKEditor="onCKEdtiorChanged($event)"
                />
              </b-card>
              <b-card v-else>
                <ck-editor-component @changedCKEditor="onCKEdtiorChangedEmpty($event)" />
              </b-card>
            </b-col>
          </b-row>

          <!--  Video Modal --->
          <b-modal id="add-new-video-modal" ref="addNewVideoModal" hide-footer centered :title="$t('Add New Video')">
            <b-tabs pills>
              <b-tab :title="$t('Video Upload')" @click="video = ''" active>
                <b-form-file
                  :browseText="$t('Browse')"
                  accept="video/*"
                  :placeholder="$t('You can select video from here or just move to here.')"
                  :drop-placeholder="$t('Move to here...')"
                  v-model="videoFile"
                />
                <small class="form-text text-danger">{{ $t("Maximum file limit 1 GB") }}</small>
                <b-progress
                  class="mt-1"
                  height="1rem"
                  v-if="loading"
                  max="100"
                  :value.sync="uploadPercentage"
                  show-progress
                  animated
                ></b-progress>
              </b-tab>
              <b-tab :title="$t('Video Embed Code')" active>
                <b-form-group>
                  <b-input-group>
                    <b-form-input v-model="video" type="text" :placeholder="$t('Video Link')" />
                  </b-input-group>
                  <small class="form-text text-danger">{{ $t("Please enter the embed link") }}</small>
                </b-form-group>
              </b-tab>
            </b-tabs>
            <div class="d-flex justify-content-end">
              <b-button @click="addVideo" variant="success" class="mt-1" v-if="!loading">
                {{ $t("Add Video") }}
              </b-button>
              <b-button variant="success" class="mt-1" v-else>
                {{ $t("Adding ...") }}
              </b-button>
            </div>
          </b-modal>
          <b-card :title="$t('Videos')">
            <div class="d-flex align-items-center justify-content-end mr-1 mt-n3">
              <b-button v-b-modal.add-new-video-modal variant="info">
                <feather-icon icon="PlusIcon" size="12" />
                {{ $t("Add New Video") }}
              </b-button>
            </div>
            <b-row v-if="template.trainingItemTemplateMedia.filter((i) => i.type == 'video')">
              <b-col
                lg="4"
                class="my-1"
                v-for="video in template.trainingItemTemplateMedia.filter((i) => i.type == 'video')"
                :key="video.id"
              >
                <div class="border-primary rounded p-1 block">
                  <div class="d-flex align-items-center justify-content-end">
                    <b-button
                      @click="deleteVideo(video.id)"
                      variant="danger"
                      class="btn-icon rounded-circle mt-n3 mr-n2"
                    >
                      <feather-icon icon="TrashIcon" />
                    </b-button>
                  </div>
                  <div class="embed-responsive embed-responsive-16by9">
                    <span v-html="video.path"></span>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="d-flex align-items-center justify-content-end">
            <b-button @click="SaveTemplate" variant="primary" class="w-25">{{ $t("Save") }}</b-button>
          </div>
        </b-col>
      </b-row>
    </b-overlay>
  </div>
</template>

<script>
  import { quillEditor } from "vue-quill-editor"
  import vSelect from "vue-select"
  // eslint-disable-next-line
  import "quill/dist/quill.core.css"
  // eslint-disable-next-line
  import "quill/dist/quill.snow.css"
  // eslint-disable-next-line
  import "quill/dist/quill.bubble.css"

  import TrainingPlugin from "@/views/team/team-sub-pages/training/TrainingPlugin"

  import CkEditorComponent from "@/views/team/team-sub-pages/training/components/CkEditorComponent.vue"

  import { nextTick, onMounted, ref, toRefs } from "@vue/composition-api"
  import axios from "@axios"
  import { useRouter } from "@core/utils/utils"
  import { useToast } from "vue-toastification/composition"
  import i18n from "@/libs/i18n"
  import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"

  export default {
    components: {
      quillEditor,
      vSelect,
      CkEditorComponent,
    },
    directives: {},

    setup(props, { emit, root }) {
      const { router, route } = useRouter()
      const { getLearningOutcomes, learningOutcomes } = TrainingPlugin(toRefs(props), emit)

      const toast = useToast()
      const loading = ref(false)
      const user = ref(JSON.parse(localStorage.getItem("userData")))
      const uploadPercentage = ref(0)
      const addNewVideoModal = ref(null)
      const video = ref(null)
      const videoFile = ref(null)
      const tempdalateId = ref(router.currentRoute.params.templateID)

      const onCKEdtiorChangedEmpty = (event) => {
        // let image = document.querySelector(".image img")["src"];
        if (event) {
          nextTick(() => {
            template.value.trainingItemTemplateMedia.push({ path: event })
          })
        }
      }

      const onCKEdtiorChanged = (event) => {
        // let image = document.querySelector(".image img")["src"];
        if (event) {
          nextTick(() => {
            Object.assign(template.value.trainingItemTemplateMedia[0], {
              path: event,
              is_cover_image: null,
            })
          })
        }
      }

      const editorOption = {
        theme: "bubble",
        placeholder: "drill giriniz",
      }

      const librarySelectInput = ref(null)

      //const learningOutcomes = ref([]);
      const searchLearningOutcome = (query, vm, kl) => {
        if (query.length < 2) return false
        axios.get(`/learning-outcome/search/${query}`).then((response) => {
          learningOutcomes.value = response.data
        })
      }

      const getTemplate = () => {
        loading.value = true
        axios.get(`/training-item-template/${router.currentRoute.params.templateID}`).then((response) => {
          template.value = response.data
          loading.value = false
        })
      }

      const filterOptions = (options, search) => {
        return options.filter((item) => {
          if (item) {
            if (item.name.toLowerCase().includes(search.toLowerCase())) return item
          }
        })
      }

      const SaveTemplate = () => {
        if (template.value.user && template.value.user.id != user.value.id && user.value.role != "SUPERADMIN") {
          root.toast(i18n.t("You have doesn't access to save or edit"), "UserIcon", "danger")
          return false
        }
        loading.value = true
        removeCategoriesFromSelectedItems()
        template.value.duration = Number(template.value.duration)
        axios
          .put(`/training-item-template/${router.currentRoute.params.templateID}`, template.value)
          .then((response) => {
            loading.value = false
            template.value = response.data
            router.push({ name: "team-training-library" })
            toast({
              component: ToastificationContent,
              props: {
                title: i18n.t("Successfully Saved"),
                icon: "CheckIcon",
                variant: "success",
              },
            })
          })
      }

      const addVideo = () => {
        if (video.value || videoFile.value) {
          let formData
          if (video.value) {
            formData = {
              video: video.value,
            }
          } else {
            formData = new FormData()
            let status = root.validateSize(videoFile.value, 1024)
            if (!status) return false
            formData.append("video", videoFile.value)
          }

          loading.value = true
          axios
            .post(`/training-item-template/${tempdalateId.value}/video`, formData, {
              //headers: { 'Content-Type': 'multipart/form-data' },
              onUploadProgress: (progressEvent) =>
                (uploadPercentage.value = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100))),
            })
            .then((response) => {
              template.value = response.data
              addNewVideoModal.value.hide()
              loading.value = false
              uploadPercentage.value = 0
            })
            .catch((error) => {
              root.toast(i18n.t("Server error"), "AlertIcon", "warning")
              loading.value = false
              console.log(error)
            })
        } else {
          root.toast(i18n.t("Please fill the blanks"), "AlertIcon", "warning")
        }
      }

      const deleteVideo = async (id) => {
        let confirm = await root.confirmModal()
        if (!confirm) return false
        axios
          .delete(`/training-item-template/video/${id}`)
          .then((response) => {
            root.toast(i18n.t("Successfully Deleted"), "CheckIcon", "success")
            let mediaIndex = template.value.trainingItemTemplateMedia.findIndex((i) => i.id == id)
            template.value.trainingItemTemplateMedia.splice(mediaIndex, 1)
          })
          .catch((error) => console.log(error))
      }

      const removeCategoriesFromSelectedItems = () => {
        let newArr = []
        template.value.learningOutcomes.filter((item) => {
          newArr.push({
            id: item.id,
            name: item.name,
          })
        })
        return (template.value.learningOutcomes = newArr)
      }

      const template = ref({
        name: "",
        duration: 30,
        description: "",
        type: "Template",
        learningOutcomes: [],
        trainingItemTemplateMedia: [],
      })

      onMounted(async () => {
        await getTemplate()
        await getLearningOutcomes()
      })

      return {
        learningOutcomes,
        searchLearningOutcome,
        librarySelectInput,
        filterOptions,
        onCKEdtiorChanged,
        editorOption,
        SaveTemplate,
        template,
        loading,
        onCKEdtiorChangedEmpty,
        uploadPercentage,
        addVideo,
        deleteVideo,
        addNewVideoModal,
        video,
        videoFile,
      }
    },
  }
</script>

<style lang="scss">
  .ql-tooltip {
    z-index: 900;
  }
  .quill-editor {
    border: 0.1px solid rgba(190, 190, 190, 0.397);
    border-radius: 5px;
  }
  .top-seperated-drills {
    margin-bottom: 5px;
  }

  .ck-editor-style {
    border: 1px solid rgb(221, 216, 216) !important;
    min-height: 340px;
  }
</style>
