<template>
  <b-sidebar
    id="sidebar-department"
    :visible="isDepartmentActive"
    bg-variant="white"
    @shown="$emit('update:isDepartmentActive', true)"
    @hidden="
      $emit('update:isDepartmentActive', false)
      formTrigger++
    "
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
  >
    <template #default="{ hide }">
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">{{ $t("Department") }}</h5>
        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver" :key="formTrigger">
        <!-- Form -->
        <b-form v-if="departmentFormData" class="p-2" @submit.prevent="handleSubmit(goSubmit())">
          <!-- Deparment Name -->
          <validation-provider #default="{ errors }" name="departmentName" rules="required">
            <b-form-group :label="$t('Department Name')" label-for="departmentName">
              <b-form-input id="departmentName" v-model="departmentFormData.name" :state="errors[0] ? false : null" />

              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Parent -->
          <b-form-group :label="$t('Parent Department')" label-for="parent">
            <v-select
              id="parent"
              v-model="departmentFormData.parent"
              :options="departments"
              :reduce="(option) => option.id"
              :getOptionLabel="(option) => option.name"
            >
            </v-select>
          </b-form-group>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" type="submit">{{
              $t("Add")
            }}</b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              ref="hideClick"
              @click="hide"
              >{{ $t("Cancel") }}</b-button
            >
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>
<script>
  import { ValidationProvider, ValidationObserver } from "vee-validate"
  import { ref, onMounted } from "@vue/composition-api"
  import { required } from "@validations"
  import Ripple from "vue-ripple-directive"
  import flatPickr from "vue-flatpickr-component"
  import vSelect from "vue-select"
  import axios from "@axios"

  export default {
    components: {
      flatPickr,
      vSelect,

      // Form Validation
      ValidationProvider,
      ValidationObserver,
    },
    directives: {
      Ripple,
    },
    model: {
      prop: "isDepartmentActive",
      event: "update:is-department-active",
    },
    props: {
      isDepartmentActive: {
        type: Boolean,
        require: true,
      },
      submitDepartment: {
        required: true,
      },
      updateDepartment: {
        require: true,
      },
      departmentFormData: {
        default: null,
      },
    },
    data() {
      return {
        required,
        formTrigger: 0,
      }
    },
    watch: {
      formTrigger: function (newVal) {
        this.getDepartments()
      },
      isDepartmentActive: function (newVal) {
        if (newVal) {
          this.departments = this.departments.filter((i) => i.id != this.departmentFormData?.id)
        }
      },
    },
    methods: {
      goSubmit() {
        this.$refs.refFormObserver.validate().then((success) => {
          if (success) {
            if (this.departmentFormData.id) {
              this.departmentFormData.parent = this.departmentFormData.parent.id ?? this.departmentFormData.parent
              this.updateDepartment(this.departmentFormData)
            } else {
              this.submitDepartment(this.departmentFormData)
            }
            this.$refs.hideClick.click()
          }
        })
      },
    },
    setup(props, { root }) {
      const departments = ref([])

      onMounted(async () => {
        await getDepartments()
      })

      const getDepartments = () => {
        axios.get("/department").then((response) => {
          departments.value = response.data.filter((i) => i.id != props.departmentFormData?.id)
        })
      }

      return {
        departments,
        getDepartments,
      }
    },
  }
</script>

<style lang="scss">
  @import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
