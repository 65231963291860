<template>
  <div>
    <div class="card-header p-0 m-0 mb-2">
      <div>
        <b-card-title>
          <feather-icon icon="SettingsIcon" size="24" />
          {{ $t("Competition Analysis") }}
        </b-card-title>
      </div>
    </div>
    <div>
      <div class="row">
        <b-col md="6">
          <h2>{{ $t("Pre Match") }}</h2>
          <b-row>
            <b-col lg="7">
              <b-form-file
                :browse-text="$t('Browse')"
                style="overflow: hidden"
                id="pre-competition"
                v-model="file"
                name="file"
                ref="pre"
                :placeholder="$t('You can select file from here or just move to here.')"
                :drop-placeholder="$t('Move to here...')"
              />
            </b-col>
            <b-col lg="4" sm="9" class="mx-lg-n2">
              <v-select
                v-model="preIsFile"
                :reduce="(option) => option.key"
                :getOptionLabel="(option) => option.value"
                label="homeTeam"
                :options="options"
                :clearable="false"
              />
            </b-col>
            <b-col>
              <b-button @click="createPreCompetitionAnalysis(file)" variant="success">
                <feather-icon size="12" icon="PlusIcon"></feather-icon>
              </b-button>
            </b-col>
          </b-row>
          <b-row>
            <b-col :md="10" class="mt-2">
              <b-row>
                <b-col :md="4" :xl="3" v-for="item in preFiles" :key="item.id">
                  <div class="position-relative card icon-card cursor-pointer text-center">
                    <div class="position-absolute corner-top-right">
                      <b-button
                        @click="goDeleteFileModal(item.id, 'pre')"
                        variant="danger"
                        class="btn-icon rounded-circle"
                      >
                        <feather-icon icon="TrashIcon" />
                      </b-button>
                    </div>
                    <div @click="openFile(item)" class="file-boxes shadow-lg">
                      <feather-icon size="32" icon="FileIcon" class="mt-2"></feather-icon>
                      <span class="d-block my-1">{{ item.name }}</span>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
        <div class="col-md-6">
          <h2>{{ $t("Post Match") }}</h2>
          <b-row>
            <b-col lg="7">
              <b-form-file
                :browse-text="$t('Browse')"
                style="overflow: hidden"
                id="post-competition"
                v-model="file"
                name="file"
                ref="post"
                :placeholder="$t('You can select file from here or just move to here.')"
                :drop-placeholder="$t('Move to here...')"
              />
            </b-col>
            <b-col lg="4" sm="9" class="mx-lg-n2">
              <v-select
                v-model="postIsFile"
                :reduce="(option) => option.key"
                :getOptionLabel="(option) => option.value"
                label="homeTeam"
                :options="options"
                :clearable="false"
              />
            </b-col>
            <b-col>
              <b-button @click="createPostCompetitionAnalysis(file)" variant="primary">
                <feather-icon size="12" icon="PlusIcon"></feather-icon>
              </b-button>
            </b-col>
          </b-row>
          <b-row>
            <b-col :md="10" class="mt-2">
              <b-row>
                <b-col :md="4" :xl="3" v-for="item in postFiles" :key="item.id">
                  <div class="position-relative card icon-card cursor-pointer text-center">
                    <div class="position-absolute corner-top-right">
                      <b-button
                        @click="goDeleteFileModal(item.id, 'post')"
                        variant="danger"
                        class="btn-icon rounded-circle"
                      >
                        <feather-icon icon="TrashIcon" />
                      </b-button>
                    </div>
                    <div @click="openFile(item)" class="file-boxes shadow-lg">
                      <feather-icon
                        size="32"
                        :icon="item.isFile == 1 ? 'SearchIcon' : 'FileIcon'"
                        class="mt-2"
                      ></feather-icon>
                      <span class="d-block my-1">{{ item.name }}</span>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { avatarText } from "@core/utils/filter"
  import vSelect from "vue-select"

  export default {
    components: {
      vSelect,
    },
    name: "CompetitionAnalysisEdit",
    props: {
      competitionForm: null,
      competition: null,
      helperFuncs: null,
      showSection: null,
    },
    data() {
      return {
        isLoading: false,
        file: [],
        preFiles: [],
        postFiles: [],
        avatarText,
        preIsFile: 0,
        postIsFile: 0,
        options: [
          { key: 0, value: this.$t("File") },
          { key: 1, value: this.$t("Analysis File") },
        ],
      }
    },
    methods: {
      openFile(item) {
        item.file = !item.file.includes("https:") ? "https:" + item.file : item.file
        window.open(item.file)
      },
      sendEmitAnalysisFile() {
        this.$root.$emit("reloadAnalysisFileItems", 1)
      },
      createPostCompetitionAnalysis() {
        let file = this.$refs.post.files[0]
        let formData = new FormData()
        formData.append("file", file)
        formData.append("isFile", this.postIsFile)
        this.$http
          .post(`/competition/analysis/post/${this.routerParams.competitionId}`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            this.postFiles.push(response.data)
            this.sendEmitAnalysisFile()
          })
      },
      createPreCompetitionAnalysis() {
        let file = this.$refs.pre.files[0]
        let formData = new FormData()
        formData.append("file", file)
        formData.append("isFile", this.preIsFile)
        this.$http
          .post(`/competition/analysis/pre/${this.routerParams.competitionId}`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            this.preFiles.push(response.data)
            this.sendEmitAnalysisFile()
          })
      },
      getFilesByType() {},
      goDeleteFileModal(id, type) {
        this.$bvModal
          .msgBoxConfirm(this.$t("Please confirm that you want to delete this."), {
            title: this.$t("Please Confirm"),
            size: "sm",
            okVariant: "primary",
            okTitle: this.$t("Yes"),
            cancelTitle: this.$t("Cancel"),
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          })
          .then((value) => {
            if (value) {
              this.deleteFile(id, type)
            }
          })
      },
      deleteFile(id, type) {
        this.$http
          .delete(`/competition/analysis/${id}`)
          .then((response) => {
            this.toast(this.$t("Successfully Deleted"), "PlusIcon", "success")
            let fileIndex =
              type == "pre" ? this.preFiles.findIndex((i) => i.id == id) : this.postFiles.findIndex((i) => i.id == id)
            type == "pre" ? this.preFiles.splice(fileIndex, 1) : this.postFiles.splice(fileIndex, 1)
            this.sendEmitAnalysisFile()
          })
          .catch((error) => console.log(error))
      },
    },
    computed: {},
    async beforeMount() {
      this.competition.formationTrigger = 0
    },
    created() {
      this.$http.get(`/competition/${this.routerParams.competitionId}/analysis`).then((res) => {
        if (res) {
          res.data.filter((item) => {
            if (item.isPre === 1) {
              this.preFiles.push(item)
            } else {
              this.postFiles.push(item)
            }
          })
        }
      })
    },
  }
</script>
<style>
  input.formationSelect {
    width: 30px;
    height: 30px;
  }
  .corner-top-right {
    top: 0%;
    right: 0%;
    transform: translate(40%, -40%);
  }

  .add-match-formation .nav-pills .nav-link.active {
    /* background: unset; */
  }
</style>
