var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-overlay',{attrs:{"show":_vm.staffEditedLoading,"rounded":"sm"}},[_c('b-media',{staticClass:"mb-2",scopedSlots:_vm._u([{key:"aside",fn:function(){return [_c('b-avatar',{ref:"previewEl",attrs:{"src":_vm.staff.avatar,"variant":("light-" + (_vm.resolveUserRoleVariant(_vm.staff.role))),"size":"90px","rounded":""}})]},proxy:true}])},[_c('h4',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(_vm.staff.firstName + " " + _vm.staff.lastName)+" ")]),_c('div',{staticClass:"d-flex flex-wrap"},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.$refs.refInputEl.click()}}},[_c('input',{ref:"refInputEl",staticClass:"d-none",attrs:{"type":"file","accept":".jpg, .png, .gif"},on:{"input":_vm.inputImageRenderer,"change":_vm.onFileSelected}}),_c('span',{staticClass:"d-none d-sm-inline"},[_vm._v(_vm._s(_vm.$t("Select")))]),_c('feather-icon',{staticClass:"d-inline d-sm-none",attrs:{"icon":"EditIcon"}})],1),_c('b-button',{staticClass:"ml-1",attrs:{"variant":"primary"},on:{"click":_vm.uploadAvatar}},[_c('feather-icon',{attrs:{"size":"12","icon":"UploadIcon"}})],1)],1)]),_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.update($event)}}},[_c('b-row',[_c('b-col',{attrs:{"lg":"6"}},[_c('validation-provider',{attrs:{"name":"firstName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('First Name'),"label-for":"firstName"}},[_c('b-input',{attrs:{"id":"firstName","placeholder":_vm.$t('Please enter first name'),"state":errors.length > 0 ? false : null},model:{value:(_vm.staff.firstName),callback:function ($$v) {_vm.$set(_vm.staff, "firstName", $$v)},expression:"staff.firstName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"lg":"6"}},[_c('validation-provider',{attrs:{"name":"lastName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Last Name'),"label-for":"lastName"}},[_c('b-input',{attrs:{"id":"lastName","placeholder":_vm.$t('Please enter last name'),"state":errors.length > 0 ? false : null},model:{value:(_vm.staff.lastName),callback:function ($$v) {_vm.$set(_vm.staff, "lastName", $$v)},expression:"staff.lastName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"lg":"6"}},[_c('validation-provider',{attrs:{"name":"nation","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Nation'),"label-for":"nation"}},[_c('v-select',{attrs:{"id":"nation","placeholder":_vm.$t('Please select a nation'),"state":errors.length > 0 ? false : null,"options":_vm.countries,"reduce":function (option) { return option.c; },"getOptionLabel":function (option) { return option.n; },"clearable":false},model:{value:(_vm.staff.nation),callback:function ($$v) {_vm.$set(_vm.staff, "nation", $$v)},expression:"staff.nation"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"lg":"6"}},[_c('validation-provider',{attrs:{"name":"nationId","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Nation Id'),"label-for":"nationId"}},[_c('b-input',{attrs:{"type":"number","id":"nationId","placeholder":_vm.$t('Please enter nation id'),"state":errors.length > 0 ? false : null},model:{value:(_vm.staff.nationId),callback:function ($$v) {_vm.$set(_vm.staff, "nationId", $$v)},expression:"staff.nationId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"lg":"6"}},[_c('validation-provider',{attrs:{"name":"birthDate","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Birth Date'),"label-for":"birthDate"}},[_c('selectable-date',{model:{value:(_vm.staff.birthDate),callback:function ($$v) {_vm.$set(_vm.staff, "birthDate", $$v)},expression:"staff.birthDate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"lg":"6"}},[_c('validation-provider',{attrs:{"name":"email","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Email'),"label-for":"email"}},[_c('b-input',{attrs:{"id":"email","placeholder":_vm.$t('Please enter email'),"state":errors.length > 0 ? false : null},model:{value:(_vm.staff.email),callback:function ($$v) {_vm.$set(_vm.staff, "email", $$v)},expression:"staff.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"lg":"6"}},[_c('validation-provider',{attrs:{"name":"phoneNumber","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Phone Number'),"label-for":"phoneNumber"}},[_c('b-input',{attrs:{"id":"phoneNumber","placeholder":_vm.$t('Please enter phone number'),"state":errors.length > 0 ? false : null},model:{value:(_vm.staff.phoneNumber),callback:function ($$v) {_vm.$set(_vm.staff, "phoneNumber", $$v)},expression:"staff.phoneNumber"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"lg":"6"}},[_c('validation-provider',{attrs:{"name":"department","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Staff Department'),"label-for":"staffRole"}},[_c('v-select',{attrs:{"id":"staffRole","placeholder":_vm.$t('Please select a department'),"state":errors.length > 0 ? false : null,"options":_vm.departments,"reduce":function (option) { return option; },"getOptionLabel":function (option) { return option.name; }},model:{value:(_vm.staff.department),callback:function ($$v) {_vm.$set(_vm.staff, "department", $$v)},expression:"staff.department"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"lg":"6"}},[_c('validation-provider',{attrs:{"name":"job","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Staff Job'),"label-for":"staffRole"}},[_c('v-select',{attrs:{"id":"staffRole","placeholder":_vm.$t('Please select a job'),"state":errors.length > 0 ? false : null,"options":_vm.jobs,"reduce":function (option) { return option; },"getOptionLabel":function (option) { return option.name; }},model:{value:(_vm.staff.job),callback:function ($$v) {_vm.$set(_vm.staff, "job", $$v)},expression:"staff.job"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"lg":"6"}},[_c('validation-provider',{attrs:{"name":"jobEndDate","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Job End Date'),"label-for":"jobEndDate"}},[_c('flat-pickr',{staticClass:"form-control",attrs:{"config":{
                  locale: _vm.user.lang.value == 'fr' ? _vm.French : _vm.user.lang.value == 'tr' ? _vm.Turkish : null,
                  enableTime: false,
                  dateFormat: 'Y-m-d',
                },"placeholder":"YYYY-MM-DD"},model:{value:(_vm.staff.jobEndDate),callback:function ($$v) {_vm.$set(_vm.staff, "jobEndDate", $$v)},expression:"staff.jobEndDate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"lg":"6"}},[_c('validation-provider',{attrs:{"name":"season","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Staff Season'),"label-for":"season"}},[_c('v-select',{attrs:{"id":"season","multiple":"","placeholder":_vm.$t('Please select a season'),"state":errors.length > 0 ? false : null,"options":_vm.seasons,"reduce":function (option) { return option.id; },"getOptionLabel":function (option) { return option.name; }},model:{value:(_vm.staff.season),callback:function ($$v) {_vm.$set(_vm.staff, "season", $$v)},expression:"staff.season"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"lg":"6"}},[_c('validation-provider',{attrs:{"name":"team","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Staff Team'),"label-for":"team"}},[_c('v-select',{attrs:{"id":"team","multiple":"","placeholder":_vm.$t('Please select a team'),"state":errors.length > 0 ? false : null,"options":_vm.teams,"reduce":function (option) { return option.id; },"getOptionLabel":function (option) { return option.name; }},model:{value:(_vm.staff.team),callback:function ($$v) {_vm.$set(_vm.staff, "team", $$v)},expression:"staff.team"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"lg":"6"}},[_c('validation-provider',{attrs:{"name":"status","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Status'),"label-for":"status"}},[_c('v-select',{attrs:{"id":"status","placeholder":_vm.$t('Please select a team'),"state":errors.length > 0 ? false : null,"options":_vm.statusOptions,"reduce":function (option) { return option.value; },"getOptionLabel":function (option) { return option.label; },"clearable":false},model:{value:(_vm.staff.status),callback:function ($$v) {_vm.$set(_vm.staff, "status", $$v)},expression:"staff.status"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('b-button',{staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1",attrs:{"variant":"primary","type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t("Save Changes"))+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }