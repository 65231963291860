import http from "@/libs/axios"
import toastService from "./toastService"
import i18n from "@/libs/i18n"

export default class userService {
  constructor() {}

  async get({ id }) {
    console.log(id)
    return new Promise((resolve, reject) => {
      http
        .post("/user/list", { id })
        .then((response) => {
          return resolve(response)
        })
        .catch((error) => reject(error))
    })
  }

  async acceptTerms() {
    return new Promise((resolve, reject) => {
      http
        .put(`user-accept-terms`)
        .then((response) => {
          console.log("sataGeldi")
          return resolve(response)
        })
        .catch((error) => reject(error))
    })
  }

  async event({ user, userId }) {
    if (user && userId) {
      return new Promise((resolve, reject) => {
        http
          .put(`/user/${userId}`, user)
          .then((response) => {
            toastService(i18n.t("Successfully Updated"), "PlusIcon", "success")
            return resolve(response)
          })
          .catch((error) => reject(error))
      })
    } else if (userId) {
      return new Promise((resolve, reject) => {
        http
          .delete(`/staff/${staffId}`)
          .then((response) => {
            toastService(i18n.t("Successfully Deleted"), "PlusIcon", "success")
            return resolve(response)
          })
          .catch((error) => reject(error))
      })
    } else if (user) {
      console.log(staff)
      return new Promise((resolve, reject) => {
        http
          .post("/staff/create", staff)
          .then((response) => {
            toastService(i18n.t("Successfully Created"), "PlusIcon", "success")
            return resolve(response)
          })
          .catch((error) => reject(error))
      })
    }
  }
}
