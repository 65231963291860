import Vue from "vue"

// axios
import axios from "axios"

import { app } from "@themeConfig"

axios.defaults.headers = {
  "Access-Control-Allow-Origin": "*",
}

const axiosIns = axios.create({
  baseURL: process.env.VUE_APP_API,
  timeout: app.timeout,
  headers: {},
})

Vue.prototype.$http = axiosIns

export default axiosIns
