import axios from "@/libs/axios"
// RESOURCE: https://www.programmersought.com/article/42067881313/

/**
 * Customized upload picture plugin
 */
class uploadAdapter {
  constructor(loader) {
    this.loader = loader
  }

  async upload() {
    const data = new FormData()
    data.append("upload", await this.loader.file)

    const res = await axios({
      url: process.env.VUE_APP_CDN_UPLOAD_API,
      // url: 'https://academy-api.sportserp.com/api/upload-ckeditor',
      method: "POST",
      data,
      // withCredentials: true, // True is not allowed to bring token, false is allowed
    })

    // console.log(res.data);
    // Backstage returns data:
    // {"url":"/upload/image.jpeg"}

    // Method Returns data format: {Default: "URL"}
    return {
      default: res.data.url,
    }
  }
}

export default uploadAdapter
