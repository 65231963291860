<template>
  <div class="d-flex justify-content-center">
    <div class="stadium-component">
      <img :src="require('@/assets/images/stadium/stadium-pastel.svg')" height="400" alt="" />
      <span
        class="badge badge-dark"
        v-for="(posShort, index) in positions"
        @contextmenu.prevent="$refs.menu.open"
        :class="posShort"
        :key="index"
      >
        {{ positionName(posShort) }}
      </span>
    </div>

    <div class="row">
      <vue-context ref="menu">
        <li>
          <b-link
            v-for="data in menuData"
            :key="data.text"
            class="d-flex align-items-center"
            @click="optionClicked(data.text, data.icon)"
          >
            <feather-icon :icon="data.icon" size="16" />
            <span class="ml-75">{{ data.text }}</span>
          </b-link>
        </li>
      </vue-context>
    </div>
  </div>
</template>
<style lang="scss">
  @import "./stadium.scss";
  @import "@core/scss/vue/libs/vue-context.scss";
</style>
<script>
  import { BButton, BLink } from "bootstrap-vue"
  import VueContext from "vue-context"
  export default {
    data() {
      return {
        menuData: [
          { icon: "PlusIcon", text: "New" },
          { icon: "FileIcon", text: "Open" },
          { icon: "SaveIcon", text: "Save" },
          { icon: "SaveIcon", text: "Save As" },
          { icon: "XIcon", text: "Close" },
        ],
      }
    },
    props: ["positions"],
    methods: {
      positionName(el) {
        return el.split(" ")[0]
      },
      optionClicked(text, icon) {
        alert(text)
      },
    },
    components: {
      VueContext,
      BButton,
      BLink,
    },
  }
</script>
