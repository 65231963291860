var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-sidebar',{attrs:{"id":"sidebar-add-cont","visible":_vm.isSidebarActive,"bg-variant":"white","sidebar-class":"sidebar-lg","shadow":"","backdrop":"","no-header":"","right":""},on:{"shown":function($event){return _vm.$emit('update:isSidebarActive', true)},"hidden":function($event){_vm.$emit('update:isSidebarActive', false)
    _vm.formTrigger++}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('h5',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.$t("Comment")))]),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":hide}})],1),_c('validation-observer',{key:_vm.formTrigger,ref:"refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var handleSubmit = ref.handleSubmit;
return [(_vm.form)?_c('b-form',{staticClass:"p-2",on:{"submit":function($event){$event.preventDefault();handleSubmit(_vm.goSubmit())},"reset":function($event){$event.preventDefault();return _vm.resetForm($event)}}},[_c('validation-provider',{attrs:{"name":"comment","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Comment'),"label-for":"season"}},[_c('b-textarea',{attrs:{"id":"name","placeholder":_vm.$t('Please enter comment'),"state":errors.length > 0 ? false : null},model:{value:(_vm.form.comment),callback:function ($$v) {_vm.$set(_vm.form, "comment", $$v)},expression:"form.comment"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('div',{staticClass:"d-flex mt-2"},[_c('b-button',{staticClass:"mr-2",attrs:{"variant":"primary","type":"submit"}},[_vm._v(_vm._s(_vm.$t("Add")))]),_c('b-button',{ref:"hideClick",attrs:{"type":"button","variant":"outline-secondary"},on:{"click":hide}},[_vm._v(_vm._s(_vm.$t("Cancel")))])],1)],1):_vm._e()]}}],null,true)})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }