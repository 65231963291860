import Vue from "vue"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"

export default function (title, icon = "EditIcon", variant = "success", text = null) {
  Vue.$toast({
    component: ToastificationContent,
    props: {
      title,
      icon,
      variant,
      text,
    },
  })
}
