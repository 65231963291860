<template>
  <b-sidebar
    id="sidebar-add-cont"
    :visible="isAddDutyActive"
    bg-variant="white"
    @shown="$emit('update:isAddDutyActive', true)"
    @hidden="
      $emit('update:isAddDutyActive', false)
      formTrigger++
    "
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
  >
    <template #default="{ hide }">
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">{{ $t("Job") }}</h5>
        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver" :key="formTrigger">
        <!-- Form -->
        <b-form v-if="staffData" class="p-2" @submit.prevent="handleSubmit(goSubmitCont())" @reset.prevent="resetForm">
          <!-- Staff Season -->
          <validation-provider #default="{ errors }" name="season" rules="required">
            <b-form-group :label="$t('Staff Season')" label-for="season">
              <v-select
                id="season"
                :placeholder="$t('Please select a season')"
                v-model="staffDuty.season"
                :state="errors.length > 0 ? false : null"
                :options="seasons"
                :reduce="(option) => option"
                :getOptionLabel="(option) => option.name"
              >
              </v-select>

              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Staff Team -->
          <validation-provider #default="{ errors }" name="team" rules="required">
            <b-form-group :label="$t('Staff Team')" label-for="team">
              <v-select
                id="team"
                :placeholder="$t('Please select a team')"
                v-model="staffDuty.team"
                :state="errors.length > 0 ? false : null"
                :options="teams"
                :reduce="(option) => option"
                :getOptionLabel="(option) => option.name"
              >
              </v-select>

              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Staff Role -->
          <validation-provider #default="{ errors }" name="job" rules="required">
            <b-form-group :label="$t('Staff Job')" label-for="staffRole">
              <v-select
                id="staffRole"
                :placeholder="$t('Please select a job')"
                v-model="staffDuty.job"
                :state="errors.length > 0 ? false : null"
                :options="jobs"
                :reduce="(option) => option"
                :getOptionLabel="(option) => option.name"
              >
              </v-select>

              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Staff Role -->
          <validation-provider #default="{ errors }" name="department" rules="required">
            <b-form-group :label="$t('Department')" label-for="department">
              <v-select
                id="department"
                :placeholder="$t('Please select a department')"
                v-model="staffDuty.department"
                :state="errors.length > 0 ? false : null"
                :options="departments"
                :reduce="(option) => option"
                :getOptionLabel="(option) => option.name"
              >
              </v-select>

              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Role End Date  -->
          <validation-provider #default="{ errors }" name="jobEndDate" rules="required">
            <b-form-group :label="$t('Job End Date')" label-for="roleEndDate">
              <flat-pickr
                v-model="staffDuty.jobEndDate"
                class="form-control"
                :config="{
                  locale: user.lang.value == 'fr' ? French : user.lang.value == 'tr' ? Turkish : null,
                  enableTime: false,
                  dateFormat: 'Y-m-d',
                }"
                placeholder="YYYY-MM-DD"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" type="submit">{{
              $t("Add")
            }}</b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              ref="hideClick"
              @click="hide"
              >{{ $t("Cancel") }}</b-button
            >
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
  import { ValidationProvider, ValidationObserver } from "vee-validate"
  import { required, alphaNum, email } from "@validations"
  import formValidation from "@core/comp-functions/forms/form-validation"
  import Ripple from "vue-ripple-directive"
  import vSelect from "vue-select"
  import { ref, onMounted, computed, watch } from "@vue/composition-api"
  import seasonService from "@/services/seasonService"
  import teamService from "@/services/teamService"
  import staffService from "@/services/staffService"
  import store from "@/store"
  import flatPickr from "vue-flatpickr-component"
  import Cleave from "vue-cleave-component"
  import "cleave.js/dist/addons/cleave-phone.us"
  import { Turkish } from "flatpickr/dist/l10n/tr.js"
  import { French } from "flatpickr/dist/l10n/fr.js"

  export default {
    components: {
      vSelect,
      Cleave,

      // Form Validation
      ValidationProvider,
      ValidationObserver,
      flatPickr,
    },
    directives: {
      Ripple,
    },
    model: {
      prop: "isAddDutyActive",
      event: "update:is-add-cont-active",
    },
    props: {
      isAddDutyActive: {
        type: Boolean,
        required: true,
      },
      submitHistory: {
        required: true,
      },
      updateHistory: {
        required: true,
      },
      staffData: {
        required: true,
      },
      teams: {
        required: true,
      },
      seasons: {
        required: true,
      },
      departments: {
        required: true,
      },
      jobs: {
        required: true,
      },
      staffDuty: {
        required: true,
      },
    },
    data() {
      return {
        required,
        formTrigger: 0,
      }
    },
    methods: {
      goSubmitCont() {
        this.$refs.refFormObserver.validate().then((success) => {
          let tempStaffData = {
            id: this.staffDuty.id,
            season: this.staffDuty.season.id,
            team: this.staffDuty.team.id,
            job: this.staffDuty.job.id,
            department: this.staffDuty.department.id,
            jobEndDate: this.staffDuty.jobEndDate,
            staff: this.staffDuty.staff,
          }
          if (success) {
            if (tempStaffData.id) {
              this.updateHistory(tempStaffData)
            } else {
              this.submitHistory(tempStaffData)
            }
            this.$refs.hideClick.click()
          }
        })
      },
    },
    setup(props, { emit, root }) {
      const blankUserData = {}
      const seasons = ref([])
      const user = JSON.parse(localStorage.getItem("userData"))

      const { refFormObserver, getValidationState, resetForm } = formValidation()

      return {
        refFormObserver,
        getValidationState,
        resetForm,
        user,
        Turkish,
        French,
      }
    },
  }
</script>

<style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";

  #add-new-user-sidebar {
    .vs__dropdown-menu {
      max-height: 200px !important;
    }
  }
</style>
