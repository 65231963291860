<template>
  <b-overlay :show="loading" rounded="sm">
    <div v-if="items.length > 0 && performance">
      <b-card class="w-100">
        <div class="card-header p-0 m-0">
          <div>
            <div v-if="!showEditForm">
              <span class="d-flex justify-content-start">
                <b-avatar size="48" :src="team.club.logo" style="background: unset" />
                <span class="d-flex flex-column" style="padding-top: 0.5rem">
                  <span class="font-weight-bold">{{ team.club.name }} {{ team.name }}</span>
                  <span class=""
                    >{{ $t("Ability Evulate") }} {{ $t("Date") }}: <b>{{ getDate(performance.date) }}</b></span
                  ></span
                >
              </span>
            </div>
            <div v-if="showEditForm">
              <b-form-group :class="$store.getters['app/currentBreakPoint'] === 'xs' ? 'w-100' : 'd-flex '">
                <b-form-datepicker
                  class="w-100"
                  :placeholder="$t('Training Date')"
                  :obj="performance.date"
                  v-model="performance.date"
                  :locale="lang"
                  :date-disabled-fn="dateDisabled"
                  :max="maxDate"
                />
              </b-form-group>
            </div>
          </div>
          <div>
            <b-button class="mb-2" type="button" variant="primary" v-if="showEditForm" @click.prevent="savePerformance">
              <i class="code-toggler feather icon-save cursor-pointer"></i>
            </b-button>
            <b-button
              @click="showEditForm = true"
              type="button"
              variant="primary"
              v-if="!showEditForm"
              :disabled="localUser.id != performance.user.id"
            >
              <i class="code-toggler feather icon-edit"></i>
            </b-button>
          </div>
        </div>
      </b-card>

      <b-row>
        <b-col lg="8" sm="12" order="2" order-md="1">
          <vue-good-table
            ref="table"
            :columns="fields"
            :rows="filteredItems"
            :isLoading.sync="loading"
            max-height="500px"
            :search-options="{
              enabled: false,
            }"
            :pagination-options="{
              enabled: false,
              perPage: pageLength,
            }"
          >
            <template slot="table-row" slot-scope="props">
              <div v-if="props.column.field == 'player'">
                <b-link
                  :to="{
                    name: 'team-player',
                    params: {
                      id: teamId,
                      playerId: props.row.id,
                    },
                  }"
                >
                  <div class="d-flex flex-column align-items-start mr-n1">
                    <div class="d-flex align-items-start">
                      <b-avatar :src="props.row.avatar" size="32px" />
                      <div class="d-block ml-1">
                        <p class="small mt-0 text-left">{{ props.row.name }}</p>
                        <p class="mt-n1 text-left">
                          <b-badge :variant="`light-${props.row.position.color}`">
                            {{ $t(props.row.position.name) }}
                          </b-badge>
                        </p>
                      </div>
                    </div>
                  </div>
                </b-link>
              </div>

              <div v-else-if="props.column.field == 'ability'">
                <div v-if="performance">
                  <ability
                    :player.sync="props.row"
                    :ability.sync="ability"
                    :items.sync="performance.performanceAbilityItems"
                    :disabled="localUser.id != performance.user.id"
                  />
                  <p v-show="false">
                    {{ (props.column.label = findAbility(ability)) }}
                  </p>
                </div>
              </div>
              <div v-else-if="props.column.field == 'comment'">
                <div v-if="performance">
                  <comment
                    :disabled="localUser.id != performance.user.id"
                    :player="props.row"
                    :items="performance.performanceComments"
                  />
                </div>
              </div>
              <div v-else>
                {{ props.formattedRow[props.column.field] }}
              </div>
            </template>

            <template slot="pagination-bottom" slot-scope="props">
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap">
                    {{ $t("Showing") }}
                  </span>
                  <b-form-select
                    v-model="pageLength"
                    :options="['3', '5', '10']"
                    class="mx-1"
                    @input="(value) => props.perPageChanged({ currentPerPage: value })"
                  />
                  <span class="text-nowrap"> {{ props.total }} {{ $t("Has records") }} </span>
                </div>
                <div>
                  <b-pagination
                    :value="1"
                    :total-rows="props.total"
                    :per-page="pageLength"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @input="(value) => props.pageChanged({ currentPage: value })"
                  >
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18" />
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18" />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
        </b-col>

        <b-col v-if="$store.getters['app/currentBreakPoint'] !== 'xs'" lg="4" sm="12" order="1" order-md="1">
          <b-card style="overflow: scroll; max-height: 520px">
            <div class="d-flex mb-1 justify-content-between">
              <p class="h4">{{ $t("Abilities") }}</p>
              <b-input v-model="searchQuery" :placeholder="$t('Search')" class="col-6" type="search" debounce="350" />
            </div>
            <b-button-group size="sm" class="mb-1">
              <b-button variant="info" :class="{ active: selectedParent === null }" @click="selectedParent = null">
                <feather-icon icon="EyeIcon" size="22" />
              </b-button>
              <b-button
                variant="info"
                :class="{ active: selectedParent === pAbility.key }"
                @click="selectedParent = pAbility.key"
                v-for="pAbility in $store.getters['app/currentBreakPoint'] !== 'md' ? abilities.slice(0, 3) : abilities"
                :key="pAbility.id"
              >
                {{ $t(pAbility.name) }}
              </b-button>
              <b-dropdown
                class="border-left"
                v-if="$store.getters['app/currentBreakPoint'] !== 'md'"
                variant="info"
                right
                text="..."
              >
                <b-dropdown-item
                  :active="selectedParent === pAbility.key"
                  @click="selectedParent = pAbility.key"
                  v-for="pAbility in abilities.slice(3)"
                  :key="pAbility.id"
                >
                  {{ $t(pAbility.name) }}
                </b-dropdown-item>
              </b-dropdown>
            </b-button-group>
            <div v-for="(parent, parentIndex) in filteredAbilities" :key="parentIndex">
              <b-form-radio
                v-for="item in parent.abilityItems"
                :key="item.id"
                v-model="ability"
                :value="item.id"
                stacked
                name="radio-options"
              >
                <div style="display: inline-flex">
                  {{ $t(item.name) }}
                  <p class="small">({{ $t(parent.name) }} )</p>
                </div>
              </b-form-radio>
            </div>
            <b-form-checkbox
              id="checkbox-1"
              v-model="showComment"
              name="checkbox-1"
              :value="false"
              :unchecked-value="true"
            >
              {{ $t("Comment") }}
            </b-form-checkbox>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </b-overlay>
</template>

<script>
  import { useRouter } from "@core/utils/utils"
  import i18n from "@/libs/i18n"
  import vSelect from "vue-select"
  import { VueGoodTable } from "vue-good-table"
  import { useToast } from "vue-toastification/composition"

  import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
  import { ref, reactive, watch, onBeforeMount, onMounted, computed } from "@vue/composition-api"

  import axios from "@axios"
  import ability from "./Ability"
  import comment from "./Comment"

  export default {
    components: {
      vSelect,
      VueGoodTable,
      ability,
      comment,
    },
    setup(props, { emit, root }) {
      const { router, route } = useRouter()
      const toast = useToast()

      let localUser = JSON.parse(localStorage.getItem("userData"))

      onMounted(async () => {
        await getPlayers()
        await getPerformances()
      })

      const now = new Date()
      const maxDate = new Date(now.getFullYear(), now.getMonth(), now.getDate())
      const teamId = ref(router.currentRoute.params.id)
      const table = ref(null)
      const ability = ref(8)
      const items = ref([])
      const abilities = ref([])
      const selectedParent = ref(null)
      const searchQuery = ref(null)
      const performance = ref(null)
      const team = ref({})
      const loading = ref(false)
      const showLogs = ref(false)
      const showEditForm = ref(false)
      const showComment = ref(true)
      const pageLength = ref(10)
      const performances = ref([])
      const lang = ref(i18n.locale)
      const fields = ref([
        {
          field: "player",
          label: i18n.t("Player"),
          sortable: false,
          width: "250px",
        },
        {
          field: "ability",
          label: i18n.t("Ability"),
          sortable: false,
          width: "300px",
          hidden: false,
          thClass: "text-center",
        },
        {
          field: "comment",
          label: i18n.t("Comment"),
          sortable: false,
          width: "190px",
          hidden: true,
        },
      ])

      const getPlayers = async () => {
        setLoading(true, "Fetching Data...")
        axios
          .get(`/season/${router.currentRoute.params.seasonId}/team/${router.currentRoute.params.id}`)
          .then((response) => {
            items.value = response.data.players
            team.value = response.data
            getAbilities()
            getPerformance()
          })
      }

      const getAbilities = () => {
        axios.get(`/ability`).then((response) => {
          abilities.value = response.data
        })
      }

      const getPerformance = () => {
        axios.get(`/performance/${router.currentRoute.params.performanceId}`).then((response) => {
          performance.value = response.data
          //setItem();
          setTimeout(() => {
            // setLoading(false, "Fetched Data");
          }, 500)
        })
      }

      const getDate = (date) => {
        date = new Date(date)
        let month = date.getMonth() + 1
        let day = date.getDate()
        let year = date.getFullYear()
        return day + `/` + month + `/` + year
      }

      const setLoading = (value, text = null) => {
        // loading.value = value;
        // if (showLogs.value === true && text !== null) {
        //   console.log(text);
        // }
      }

      const filteredItems = computed(() => {
        return selectedParent.value == "technicalGoalKeeper"
          ? items.value.filter((p) => p.position.availablePositions.includes("GK"))
          : items.value
      })

      const filteredAbilities = computed(() => {
        let regex = new RegExp(searchQuery.value, "i")

        return abilities.value
          .filter((parent) => (selectedParent.value != null ? parent.key == selectedParent.value : true))
          .map((i) => {
            return searchQuery.value != null
              ? { ...i, abilityItems: i.abilityItems.filter((item) => regex.test(item.name)) }
              : { ...i }
          })
      })

      watch(showComment, (val) => {
        toggleColumn(2, val)
        //I SENT 2 FOR INDEX - IF YOU CHANGE ITEMS COLUMNS YOU NEED SET NEW INDEX TO HERE.
      })

      const handleScroll = (event) => {
        console.log(event)
      }

      watch(filteredAbilities, (val) => {
        //ability.value = val[0].abilityItems[0].id;
      })

      watch(searchQuery, (newVal) => {
        selectedParent.value = null
      })

      watch(ability, (val) => {
        if (val && !showComment.value) showComment.value = true
        setLoading(true, "Fetching Data...")
        const scrollEl = document.getElementsByClassName("vgt-responsive")[0]
        scrollEl.scrollTo({
          top: 0,
          behavior: "smooth",
        })
        getPerformance()
      })

      const findAbility = (id, label) => {
        let data = null
        abilities.value.filter((parent) => {
          parent.abilityItems.find((item) => {
            if (item.id === id) {
              data = item
            }
          })
        })
        if (data) return data.name
      }

      const toggleColumn = (index, val) => {
        // if (val) {
        //   ability.value = null;
        // }
        fields.value[index].hidden = val
        fields.value[index - 1].hidden = !val
      }
      const savePerformance = () => {
        axios
          .put(`/performance/${router.currentRoute.params.performanceId}`, {
            date: performance.value.date,
            team: parseInt(router.currentRoute.params.id),
          })
          .then((response) => {
            performance.value = response.data
            showEditForm.value = false
            toast({
              component: ToastificationContent,
              position: "bottom-right",
              props: {
                title: i18n.t("Successfully Saved"),
                icon: "CheckIcon",
                variant: "success",
              },
            })
          })
      }

      const getFormattedDate = (date) => {
        let tempMonth = date.getMonth() + 1
        let month = tempMonth < 10 ? "0" + tempMonth.toString() : tempMonth
        let day = date.getDate() < 10 ? "0" + date.getDate().toString() : date.getDate()
        let year = date.getFullYear()
        return year + `-` + month + `-` + day
      }

      const getPerformances = async () => {
        axios
          .get(`/season/${router.currentRoute.params.seasonId}/performance/team/${router.currentRoute.params.id}`)
          .then((response) => {
            performances.value = Object.values(response.data).map((i) => ({
              id: i.id,
              date: getFormattedDate(new Date(i.date)),
            }))
          })
          .catch((error) => {
            console.log(error)
          })
      }

      const dateDisabled = (ymd, date) => {
        return performances.value && performances.value.find((i) => i.date == ymd) ? true : false
      }
      return {
        table,
        fields,
        items,
        ability,
        abilities,
        performance,
        getDate,
        team,
        loading,
        pageLength,
        showComment,
        findAbility,
        toggleColumn,
        showEditForm,
        savePerformance,
        maxDate,
        handleScroll,
        teamId,
        dateDisabled,
        lang,
        selectedParent,
        filteredAbilities,
        searchQuery,
        filteredItems,
        localUser,
      }
    },
  }
</script>

<style>
  .custom-header-text {
    margin-top: 10px;
    margin-right: 5px;
    font-size: 18px;
  }
  .vgt-left-align {
    @media (max-width: 767px) {
      max-width: 120px;
    }
  }
  .custom-radio {
    font-size: 28px;
  }
  .custom-control-label {
    font-size: 28px;
  }
</style>
<style lang="scss">
  @import "@core/scss/vue/libs/vue-good-table.scss";
</style>
