<template>
  <div>
    <player-contact-new
      :player-data="playerData"
      :is-add-cont-active.sync="isAddContActive"
      :submit-contact="submitContact"
      :update-contact="updateContact"
      :player-contact-form="contact"
    />
    <b-row>
      <b-col md="4" v-for="pd in playerData.contacts" :key="pd.id">
        <b-card style="border: 1px solid #ddd">
          <div class="card-header p-0 m-0 mb-2">
            <div>
              <b-card-title>
                <feather-icon icon="FileIcon" size="24" />
                {{ pd.contactName }}
              </b-card-title>
            </div>
            <div>
              <span class="btn btn-light" @click="goSidebarContact(pd)">
                <feather-icon icon="EditIcon"></feather-icon>
              </span>
              <span class="btn btn-light ml-1" @click="goDeleteContact(pd.id)">
                <feather-icon icon="Trash2Icon"></feather-icon>
              </span>
            </div>
          </div>
          <div class="mb-2">
            <div>
              <span class="badge badge-light text-dark" v-text="pd.contactLevel"></span>
              <span class="badge badge-light text-dark ml-1">{{ new Date(pd.created_at).toLocaleDateString() }}</span>
            </div>
            <div class="mt-2">
              <span class="h4 d-block mb-2">
                <feather-icon icon="PhoneIcon"></feather-icon>
                {{ pd.contactPhone }}
              </span>
              <span class="h4 d-block">
                <feather-icon icon="MapIcon"></feather-icon>
                {{ pd.contactAddress }}
              </span>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>

    <b-button
      variant="primary"
      v-b-toggle.sidebar-add-cont
      type="button"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      @click="contact = {}"
      >{{ $t("Add New") }}</b-button
    >
  </div>
</template>

<script>
  import flatPickr from "vue-flatpickr-component"
  import vSelect from "vue-select"
  import { ref } from "@vue/composition-api"
  import PlayerContactNew from "./PlayerContactNew.vue"

  export default {
    components: {
      vSelect,
      flatPickr,
      PlayerContactNew,
    },
    props: {
      playerData: {
        default: null,
      },
      submitContact: {
        default: null,
      },
      submitHandle: {
        default: null,
      },
      updateContact: {
        default: null,
      },
      deleteContact: { default: null },
    },
    data() {
      return {
        contact: null,
        isAddContActive: false,
      }
    },
    methods: {
      goSidebarContact(playerContactData) {
        this.contact = {
          id: null,
          contactLevel: null,
          contactName: null,
          contactAddress: null,
          contactPhone: null,
          email: null,
        }
        this.contact.id = playerContactData.id
        this.contact.contactLevel = playerContactData.contactLevel
        this.contact.contactName = playerContactData.contactName
        this.contact.contactAddress = playerContactData.contactAddress
        this.contact.contactPhone = playerContactData.contactPhone
        this.contact.email = playerContactData.email
        this.isAddContActive = true
      },
      goDeleteContact(contId) {
        this.$bvModal
          .msgBoxConfirm(this.$t("Please confirm that you want to delete this."), {
            title: this.$t("Please Confirm"),
            size: "sm",
            okVariant: "primary",
            okTitle: this.$t("Yes"),
            cancelTitle: this.$t("No"),
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          })
          .then((value) => {
            if (value) {
              this.deleteContact(contId)
            }
          })
      },
    },
    beforeMount() {},
    setup(props, { root }) {
      const languageOptions = ["Türkçe", "English", "Spanish", "French", "Russian", "German"]

      return {
        languageOptions,
      }
    },
  }
</script>

<style lang="scss">
  @import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
