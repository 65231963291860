<template>
  <div class="my-2">
    <b-modal id="modal-image" ref="imageModal" hide-footer centered title="Video">
      <div style="height: 70vh; overflow: scroll" class="text-center img-medias">
        <span v-html="imageModalHTML"></span>
      </div>
    </b-modal>

    <b-modal id="modal-video" ref="isVideoModalOpen" hide-footer centered title="Video">
      <div style="height: 50vh; overflow: scroll" class="text-center">
        <div v-for="video in tempVideoData" :key="video.id" class="my-1 px-md-3">
          <div class="border-primary rounded p-1 block">
            <div class="embed-responsive embed-responsive-16by9">
              <span v-html="video.path"></span>
            </div>
          </div>
        </div>
      </div>
    </b-modal>

    <b-overlay variant="white" :show="loading" spinner-variant="primary" blur="0" opacity=".75" rounded="sm">
      <b-card no-body>
        <b-card-header class="pb-50">
          <h5>{{ $t("Filter") }}</h5>
        </b-card-header>
        <b-card-body>
          <b-row>
            <b-col cols="12" md="4" xl="3" class="mb-md-0 mb-2">
              <label>{{ $t("Name") }}</label>
              <b-input v-model="name"> </b-input>
            </b-col>

            <b-col cols="12" md="4" xl="3" class="mb-md-0 mb-2">
              <label>{{ $t("Exercises") }}</label>
              <v-select
                :autocomplete="`on`"
                v-model="outcomes"
                multiple
                push-tags
                :options="learningOutcomes ? Object.values(learningOutcomes) : []"
                :reduce="(option) => option.id"
                :getOptionLabel="(option) => option.name"
                class="fit-dropdown"
              >
              </v-select>
            </b-col>

            <b-col cols="12" md="4" xl="3" class="mb-md-0 mb-2">
              <label>{{ $t("Created By") }}</label>
              <v-select
                v-model="user"
                :options="users"
                :reduce="(option) => option.user.id"
                :getOptionLabel="(option) => option.user.firstName + ' ' + option.user.lastName"
              >
              </v-select>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>

      <b-card>
        <b-card-title>{{ $t("Training Library") }}</b-card-title>
        <div class="d-block">
          <div class="float-right">
            <b-button
              :to="{
                name: 'team-training-library-add',
                params: { id: routerParams.id },
              }"
              variant="primary"
              class="right-top"
              >{{ $t("Add New") }}</b-button
            >
          </div>
        </div>
        <b-table
          :fields="fields"
          striped
          class="border mx-n2"
          :items="getItems"
          :filter="[currentPage, perPage, outcomes, name, user]"
          responsive="lg"
        >
          <!-- general table td -->
          <template #cell(id)="data">
            <div @click="editItem(data.value)">
              <a class="d-block h6 font-weight-bold">#{{ data.value }}</a>
            </div>
          </template>

          <template #cell(name)="data">
            <b-link @click="imageModalHTML = getMediaImg(data)" v-b-modal.modal-image class="text-dark">
              <span class="text-nowrap" v-b-tooltip.hover :title="data.value">{{ data.value }}</span>
              <span class="mediaImg" v-html="getMediaImg(data)"></span>
            </b-link>
          </template>
          <template #cell(trainingItemTemplateMedia)="data">
            <b-link @click="showVideoModal(getVideos(data.value, 'video'))" class="text-dark" style="margin-left: 3px">
              <feather-icon icon="VideoIcon" size="22" :badge="getVideoCount(data.value, 'video')" />
            </b-link>
          </template>
          <template #cell(description)="data">
            <span class="maxLine" v-b-tooltip.hover :title="data.value">{{ data.value }}</span>
          </template>

          <template #cell(learningOutcomes)="data">
            <ul class="outcome-ul ml-n3" v-for="(item, index) in data.value" :key="index">
              <li style="list-style: inside" class="text-truncate text-small py-0" v-b-tooltip.hover :title="item.name">
                {{ item.name }}
              </li>
            </ul>
          </template>

          <template #cell(user)="data">
            <span
              class="text-nowrap"
              v-b-tooltip.hover
              :title="data.value.firstName + ' ' + data.value.lastName"
              v-if="data.value"
              >{{ data.value.firstName + " " + data.value.lastName }}</span
            >
          </template>

          <template #cell(action)="data">
            <b-link class="text-dark ml-1">
              <feather-icon
                v-b-tooltip.hover
                @click="editItem(data.item.id)"
                :title="$t('Edit')"
                size="24"
                icon="EditIcon"
              />
            </b-link>
            <b-link class="text-dark ml-1">
              <feather-icon
                v-b-tooltip.hover
                @click="removeItem(data.item.id)"
                :title="$t('Delete')"
                size="24"
                icon="TrashIcon"
              />
            </b-link>
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
              <label>{{ $t("Show") }}</label>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
              />

              <span class="text-muted">{{
                $t("format.showingData", { from: dataMeta.from, to: dataMeta.to, of: dataMeta.of })
              }}</span>
            </b-col>
            <!-- Pagination -->
            <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalItems"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
  import TrainingPlugin from "@/views/team/team-sub-pages/training/TrainingPlugin"
  import TrainingStoreModule from "@/views/team/team-sub-pages/training/TrainingStoreModule"
  import { useRouter } from "@core/utils/utils"
  import axios from "@axios"
  import store from "@/store"
  import i18n from "@/libs/i18n"
  import { ref, reactive, watch, computed, toRefs, onMounted, onUnmounted, onBeforeMount } from "@vue/composition-api"
  import vSelect from "vue-select"
  import { localService } from "@/services"

  export default {
    components: { vSelect },
    directives: {},
    methods: {
      removeItem(id) {
        this.$bvModal
          .msgBoxConfirm(this.$t("Please confirm that you want to delete this."), {
            title: this.$t("Please Confirm"),
            size: "sm",
            okVariant: "primary",
            okTitle: this.$t("Yes"),
            cancelTitle: this.$t("Cancel"),
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          })
          .then((value) => {
            if (value) {
              this.$http
                .delete(`/training-item-template/${id}`)
                .then((data) => location.reload())
                .catch((err) => console.log(err))
            }
          })
      },
    },
    setup(props, { emit, root }) {
      const { router, route } = useRouter()
      const STORE_MODULE_NAME = "teamTraining"

      // Register module
      if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, TrainingStoreModule)

      // UnRegister on leave
      onUnmounted(() => {
        if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
      })
      const loading = ref(false)
      const { getLearningOutcomes, learningOutcomes } = TrainingPlugin(toRefs(props), emit)
      const outcomes = ref("")
      const name = ref(null)
      const user = ref(null)
      const users = ref([])
      const tempVideoData = ref([])
      const isVideoModalOpen = ref(false)
      const totalItems = ref(0)
      const items = ref([])
      const perPage = ref(new localService("training_template_perPage").get() || 10)
      const currentPage = ref(1)
      const perPageOptions = [10, 25, 50, 100]
      const fields = computed({
        get: () => [
          {
            key: "id",
            label: i18n.t("Id"),
            sortable: true,
            thStyle: { "max-width": "60px" },
          },
          // A column that needs custom formatting
          {
            key: "name",
            label: i18n.t("Name"),
            thStyle: { "max-width": "60px" },
          },
          {
            key: "trainingItemTemplateMedia",
            label: i18n.t("Video"),
            thStyle: { width: "80px", "min-width": "80px", "max-width": "100px" },
          },
          {
            key: "description",
            label: i18n.t("Description"),
            tdClass: "responsiveWidth",
          },
          {
            key: "learningOutcomes",
            label: i18n.t("Exercises"),
            tdClass: "responsiveWidth2",
          },
          {
            key: "user",
            label: i18n.t("Created By"),
            thStyle: { "max-width": "60px" },
          },
          {
            key: "action",
            label: i18n.t("Action"),
            sortable: false,
            thStyle: { "min-width": "60px", "max-width": "120px" },
          },
        ],
      })

      const editItem = (id) => {
        router.push({
          name: "team-training-library-edit",
          params: { id: router.currentRoute.params.id, templateID: id },
        })
      }

      const getItems = () => {
        loading.value = true
        return axios
          .get(`/training-item-template`, {
            params: {
              learningOutcomes: outcomes.value.toString(),
              name: name.value,
              user: user.value,
              row: perPage.value,
              page: currentPage.value,
            },
          })
          .then((response) => {
            loading.value = false
            items.value = response.data.data
            totalItems.value = response.data.count
            return response.data.data
          })
      }

      const getVideoCount = (media, type) => {
        let array = media.filter((item) => {
          return item.type == type
        })

        return array.length
      }

      const getVideos = (media, type) => {
        return media.filter((item) => {
          return item.type == type
        })
      }

      const getLearningOutcomeUsers = async () => {
        const { data } = await axios.get(`/training-item-template?groupUser=true&row=999`)
        users.value = data.data
      }

      onMounted(async () => {
        await getLearningOutcomes()
        getLearningOutcomeUsers()
      })

      const getMediaImg = (data) => {
        return data.item.trainingItemTemplateMedia[0]?.path
      }

      const imageModalHTML = ref(null)

      const showVideoModal = (data) => {
        tempVideoData.value = data
        if (data.length > 0) {
          isVideoModalOpen.value.show()
        } else {
          root.toast(i18n.t("No video available"), "AlertCircleIcon", "warning")
        }
      }

      const dataMeta = computed(() => {
        const localItemsCount = items.value.length > 0 ? items.value.length : 0
        return {
          from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
          to: perPage.value * (currentPage.value - 1) + localItemsCount,
          of: totalItems.value,
        }
      })

      watch(perPage, (perPage) => {
        new localService("training_template_perPage", perPage)
      })

      return {
        imageModalHTML,
        fields,
        editItem,
        getItems,
        loading,
        getMediaImg,
        learningOutcomes,
        outcomes,
        name,
        user,
        users,
        getVideoCount,
        getVideos,
        showVideoModal,
        isVideoModalOpen,
        tempVideoData,
        dataMeta,
        totalItems,
        perPage,
        currentPage,
        perPageOptions,
      }
    },
  }
</script>

<style lang="scss">
  .mediaImg {
    display: block;
    max-height: 100px;
    overflow-y: hidden;
    img {
      width: 150px;
      height: auto;
    }
  }

  .per-page-selector {
    width: 90px;
  }

  .right-top {
    position: absolute;
    right: 2rem;
    top: 1rem;
  }

  .embed-video {
    iframe {
      max-width: 100%;
      max-height: 30vh;
    }
  }
  .outcome-ul {
    margin-top: 1px;
    margin-bottom: 0px;
    font-size: 13px;
  }
  .outcome-div {
    padding-right: 0px;
    margin-left: -25px;
  }
  .img-medias {
    img {
      width: 100%;
    }
  }
  .fit-dropdown .vs__dropdown-menu {
    width: fit-content;
    li {
      padding-right: 35px;
      white-space: normal;
    }
  }
  .responsiveWidth {
    min-width: 2em;
    width: 25em;
    max-width: 30em;
  }
  .responsiveWidth2 {
    min-width: 3em;
    width: 3em;
    max-width: 15em;
  }
  .maxLine {
    overflow: hidden;
    display: -webkit-box;
    -moz-box-orient: vertical;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
  }
</style>
