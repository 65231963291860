<template>
  <div @click="goToPlayer" class="shadow-sm rounded px-1 pt-1 bg-white pl-card">
    <div class="d-flex p-0">
      <div class="p-0">
        <b-avatar :src="user ? user.avatar : ''" size="80px" rounded />
      </div>
      <div>
        <b-link
          class="mt-0 ml-1 d-block pl-name h4"
          :to="{
            name: 'team-player',
            params: { id: routerParams.id, playerId: user.id },
          }"
          >{{ user.name }}</b-link
        >
        <span class="mt-0 ml-1 d-block"
          >{{ new Date(user.birthdate).toLocaleDateString(i18n.locale) }} ({{ findAge(user.birthdate) }})</span
        >
        <div class="p-1 d-flex">
          <span class="badge badge-primary mr-1 col">{{ $t(user.position.name) }}</span>
          <span class="badge badge-dark col">
            {{ user.foot === 1 ? $t("Right Foot") : user.foot === 0 ? $t("Left Foot") : $t("Both Feet") }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Ripple from "vue-ripple-directive"
  import { avatarText } from "@core/utils/filter"
  import i18n from "@/libs/i18n"

  export default {
    props: {
      user: {
        type: Object,
        default: {},
      },
    },
    components: {
      avatarText,
    },
    directives: {
      Ripple,
    },
    setup(props, { root }) {
      return {
        avatarText,
        i18n,
      }
    },
    methods: {
      goToPlayer() {
        this.$router.push({
          name: "team-player",
          params: {
            id: this.routerParams.id,
            playerId: this.user.id,
          },
        })
      },
      findAge(birthdate) {
        return Math.abs(new Date(Date.now() - new Date(birthdate).getTime()).getUTCFullYear() - 1970)
      },
    },
  }
</script>
<style lang="scss">
  .pl-card {
    overflow: hidden;
    .pl-name {
      display: block;
      height: 1.4rem;
      overflow-y: hidden;
    }
  }
</style>
