<template>
  <div>
    <b-card :title="$t('Search Player to Compare')">
      <div class="row">
        <b-col md="3">
          <v-select
            :placeholder="$t('Team')"
            v-model="forms.team"
            :options="teams"
            @input="handleTeam"
            :getOptionLabel="(option) => (option.club ? option.club.name + ' - ' + option.name : option.name)"
          >
            <template v-slot:option="option">
              <span v-if="option.club">
                <img v-if="option.club" :src="option.club.logo" height="18" />
                {{ option.club.name }}
              </span>
              {{ option.name }}
            </template>
          </v-select></b-col
        >

        <b-col md="3">
          <v-select
            style="min-width: 150px"
            :placeholder="$t('Season')"
            v-model="forms.season"
            :options="seasons"
            :getOptionLabel="(option) => option.year"
            :reduce="(d) => d.id"
          ></v-select
        ></b-col>

        <b-col md="3">
          <v-select
            style="min-width: 150px"
            :placeholder="$t('Player') + ' ' + '(' + teamPlayersLength() + ')'"
            v-model="forms.selectPlayer"
            :options="forms.team.players"
            :getOptionLabel="(option) => option.name"
          ></v-select
        ></b-col>

        <b-col md="3" :disabled="forms.selectPlayer" v-b-tooltip.hover :title="$t('Select Player')">
          <b-button :disabled="!forms.selectPlayer" class="w-100" variant="primary" size="md" @click="addPlayer">
            {{ $t("Compare") }}
          </b-button>
        </b-col>
      </div>
    </b-card>
    <b-card :title="$t('Compared Players') + ' (' + players.length + ')'">
      <div class="mt-2 mx-n2" style>
        <b-overlay :show="isBusy">
          <div class="table-responsive" v-if="players.length > 0">
            <b-table
              sort-by="ps"
              :fields="[
                { key: 'player', label: $t('Player'), sortable: true },
                { key: 'competition', label: $t('OMS'), sortable: true },
                { key: 'stats.IS_STARTER', label: $t('Starting 11'), sortable: true },
                { key: 'stats.PLAYER_TIME_IN_THE_GAME', label: $t('OKS'), sortable: true },
                { key: 'event.YELLOW_CARD', label: $t('Yellow Card'), sortable: true },
                { key: 'event.RED_CARD', label: $t('Red Card'), sortable: true },
                { key: 'stats.WDL', label: $t('W/D/L'), sortable: true },
                { key: 'stats.GOAL_BY_COMPETITION_AG', label: $t('AG'), sortable: true },
                { key: 'stats.TEAM_POINTS_AVERAGE', label: $t('MBP'), sortable: true },
                { key: 'stats.REVIEWS.coachReview', label: $t('PS'), sortable: true },
                { key: 'stats.REVIEWS.playerReview', label: $t('YP'), sortable: true },
              ]"
              :items="players"
            >
              <template #head(competition)="scope">
                <div v-b-tooltip.hover :title="$t('Match played')">{{ $t("OMS") }}</div>
              </template>
              <template #head(stats.WDL)="scope">
                <div v-b-tooltip.hover :title="`${$t('Win')} / ${$t('Draw')} / ${$t('Loss')}`">{{ $t("W/D/L") }}</div>
              </template>
              <template #head(stats.TEAM_POINTS_AVERAGE)="scope">
                <div v-b-tooltip.hover :title="$t('Performance Per Game')">{{ $t("PPG") }}</div>
              </template>
              <template #head(stats.REVIEWS.playerReview)="scope">
                <div v-b-tooltip.hover :title="$t('Player Review')">YP</div>
              </template>
              <template #head(stats.REVIEWS.coachReview)="scope">
                <div v-b-tooltip.hover :title="$t('Coach Review') + ' ' + $t('Performance Score')">PS</div>
              </template>
              <template #head(stats.PLAYER_TIME_IN_THE_GAME)="scope">
                <div v-b-tooltip.hover :title="$t('Points Total')">{{ $t("TP") }}</div>
              </template>

              <template #cell(player)="data">
                <b-button @click="players.splice(data.index, 1)" size="sm" variant="dark">
                  <feather-icon icon="TrashIcon"></feather-icon>
                </b-button>
                <b-link
                  target="_blank"
                  :disabled="getLink(data.item.player) == '#'"
                  :to="getLink(data.item.player)"
                  class="ml-1"
                >
                  <b-avatar :src="data.item.player ? data.item.player.avatar : null"></b-avatar>
                  <span class="ml-1" style="margin-top: 0">
                    {{ data.item.player ? data.item.player.name.toString().substr(0, 12) : "" }}
                    {{ data.item.season && data.item.season.year }}
                  </span>
                </b-link>
              </template>

              <template #cell(competition)="data">
                <span class="font-weight-bold" v-text="data.item.competition"></span>
              </template>
              <template #cell(stats.IS_STARTER)="data">
                <span class="font-weight-bold" v-text="data.item.stats.IS_STARTER"></span>
              </template>
              <template #cell(stats.PLAYER_TIME_IN_THE_GAME)="data">
                <span class="font-weight-bold" v-text="data.item.stats.PLAYER_TIME_IN_THE_GAME"></span
              ></template>
              <template #cell(event.YELLOW_CARD)="data"
                ><span class="font-weight-bold" v-text="data.item.event.YELLOW_CARD"></span>
              </template>
              <template #cell(event.RED_CARD)="data"
                ><span class="font-weight-bold" v-text="data.item.event.RED_CARD"></span>
              </template>
              <template #cell(stats.WDL)="data">
                <span class="font-weight-bold" v-text="data.item.stats.WDL"></span>
              </template>
              <template #cell(stats.GOAL_BY_COMPETITION_AG)="data"
                ><span class="font-weight-bold" v-text="data.item.stats.GOAL_BY_COMPETITION_AG"></span
              ></template>
              <template #cell(stats.TEAM_POINTS_AVERAGE)="data"
                ><span class="font-weight-bold" v-text="data.item.stats.TEAM_POINTS_AVERAGE"></span
              ></template>
              <template #cell(stats.REVIEWS.coachReview)="data"
                ><span class="font-weight-bold" v-text="data.item.stats.REVIEWS.coachReview.toFixed(2)"></span
              ></template>
              <template #cell(stats.REVIEWS.playerReview)="data"
                ><span class="font-weight-bold" v-text="data.item.stats.REVIEWS.playerReview"></span
              ></template>
            </b-table>

            <b-table-simple responsive="sm" hover striped v-if="false">
              <b-thead>
                <b-tr>
                  <b-th>Player</b-th>
                  <b-th>OMS</b-th>
                  <b-th>First 11</b-th>
                  <b-th>OKS</b-th>
                  <b-th>YELLOW C.</b-th>
                  <b-th>RED C.</b-th>
                  <b-th>WDL Win Draw Loss</b-th>
                  <b-th>AG Goal / Income</b-th>
                  <b-th>MBP</b-th>
                  <b-th>Red Card</b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <template v-for="index in players">
                  <b-tr class="text-center" :key="index.player.id">
                    <b-td sticky-column class="p-2 d-flex justify-content-start" style="min-width: 250px">
                      <b-link target="_blank" :disabled="getLink(index.player) == '#'" :to="getLink(index.player)">
                        <b-avatar :src="index.player ? index.player.avatar : null"></b-avatar>
                        <span class="ml-1" style="margin-top: 0">
                          {{ index.player ? index.player.name.toString().substr(0, 12) : "" }}
                        </span>
                      </b-link>
                    </b-td>
                    <b-td v-text="index.competition"></b-td>
                    <b-td v-text="index.stats.IS_STARTER"></b-td>
                    <b-td v-text="index.stats.PLAYER_TIME_IN_THE_GAME"></b-td>
                    <b-td v-text="index.event.YELLOW_CARD"></b-td>
                    <b-td v-text="index.event.RED_CARD"></b-td>
                    <b-td
                      v-text="
                        index.stats.TEAM_POINTS.count.home +
                        index.stats.TEAM_POINTS.count.away +
                        '/' +
                        index.stats.TEAM_POINTS.count.draw +
                        '/' +
                        (index.competition -
                          (index.stats.TEAM_POINTS.count.draw +
                            index.stats.TEAM_POINTS.count.home +
                            index.stats.TEAM_POINTS.count.away))
                      "
                    ></b-td>
                    <b-td
                      v-text="
                        index.stats.GOAL_BY_COMPETITION.SCORE + '/' + index.stats.GOAL_BY_COMPETITION.IN_COME_SCORE
                      "
                    ></b-td>
                    <b-td v-text="index.stats.TEAM_POINTS_AVERAGE"></b-td>
                    <b-td v-text="index.stats.REVIEWS.coachReview"></b-td>
                  </b-tr>
                </template>
              </b-tbody>
            </b-table-simple>

            <b-table-simple
              responsive="sm"
              data-style="transform:scale(70%) translate(-21.5%); margin-top:-3%"
              hover
              v-if="false"
            >
              <b-thead>
                <!-- <b-tr>
                <b-th class="text-center" colspan="15"><span class="h4 p-0 m-0">PLAYER COMPARES</span></b-th>
              </b-tr> -->
                <b-tr>
                  <b-th sticky-column> Player </b-th>
                  <template v-for="(index, key) in players">
                    <b-th
                      :key="getRandomKey()"
                      class="text-center"
                      colspan="1"
                      @mouseover="isOpen = true"
                      @mouseleave="isOpen = false"
                      style="min-width: 250px"
                    >
                      <span class="">{{ index.player ? index.player.name.toString().substr(0, 12) : "" }}...</span>
                      <b-button @click="players.splice(key, 1)" size="sm" variant="dark">
                        <feather-icon icon="TrashIcon"></feather-icon>
                      </b-button>
                    </b-th>
                  </template>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr>
                  <b-td sticky-column> Avatar </b-td>
                  <template v-for="index in players">
                    <b-td class="text-center" :key="getRandomKey()">
                      <b-avatar :src="index.player ? index.player.avatar : null"></b-avatar>
                    </b-td>
                  </template>
                </b-tr>
                <b-tr>
                  <b-td sticky-column> Name </b-td>
                  <template v-for="index in players">
                    <b-td class="text-center" :key="getRandomKey()">
                      {{ index.player.name }}
                    </b-td>
                  </template>
                </b-tr>
                <b-tr>
                  <b-td sticky-column> Age </b-td>
                  <template v-for="index in players">
                    <b-td class="text-center" :key="getRandomKey()">
                      {{ findAge(index.player.birthdate) }}
                    </b-td>
                  </template>
                </b-tr>
                <b-tr>
                  <b-td sticky-column> Position </b-td>
                  <template v-for="index in players">
                    <b-td class="text-center" :key="getRandomKey()">
                      {{ index.player.position.name }}
                    </b-td>
                  </template>
                </b-tr>
                <b-tr>
                  <b-td sticky-column> Height </b-td>
                  <template v-for="index in players">
                    <b-td class="text-center" :key="getRandomKey()">
                      {{ index.player.height }}
                    </b-td>
                  </template>
                </b-tr>
                <b-tr>
                  <b-td sticky-column> Weight </b-td>
                  <template v-for="index in players">
                    <b-td class="text-center" :key="getRandomKey()">
                      {{ index.player.weight }}
                    </b-td>
                  </template>
                </b-tr>
                <b-tr>
                  <b-td sticky-column> Competition </b-td>
                  <template v-for="index in players">
                    <b-td class="text-center" :key="getRandomKey()">
                      {{ index.competition }}
                    </b-td>
                  </template>
                </b-tr>
                <b-tr>
                  <b-td sticky-column> Training </b-td>
                  <template v-for="index in players">
                    <b-td class="text-center" :key="getRandomKey()">
                      {{ index.training }}
                    </b-td>
                  </template>
                </b-tr>
                <b-tr>
                  <b-td sticky-column> Goal </b-td>
                  <template v-for="index in players">
                    <b-td class="text-center" :key="getRandomKey()">
                      {{ index.event.GOAL }}
                    </b-td>
                  </template>
                </b-tr>
                <b-tr>
                  <b-td sticky-column> Assist </b-td>
                  <template v-for="index in players">
                    <b-td class="text-center" :key="getRandomKey()">
                      {{ index.event.ASSIST }}
                    </b-td>
                  </template>
                </b-tr>
                <b-tr>
                  <b-td sticky-column> Red Card </b-td>
                  <template v-for="index in players">
                    <b-td class="text-center" :key="getRandomKey()">
                      {{ index.event.RED_CARD + index.event.YELLOW_RED_CARD }}
                    </b-td>
                  </template>
                </b-tr>
                <b-tr>
                  <b-td sticky-column> Yellow Card </b-td>
                  <template v-for="index in players">
                    <b-td class="text-center" :key="getRandomKey()">
                      {{ index.event.YELLOW_CARD + index.event.YELLOW_RED_CARD }}
                    </b-td>
                  </template>
                </b-tr>
              </b-tbody>
              <template #table-busy>
                <div class="text-center text-danger my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>
            </b-table-simple>
          </div>
        </b-overlay>
      </div>
    </b-card>
  </div>
</template>
<script>
  import { ref, onUnmounted, onMounted, onBeforeMount, watch } from "@vue/composition-api"
  import axios from "@/libs/axios"
  import vSelect from "vue-select"
  import router from "@/router"

  export default {
    components: { vSelect },
    setup(props, { root }) {
      const isBusy = ref(true)
      const teams = ref([])
      const players = ref([])
      const forms = ref({ player: [], team: [], selectPlayer: null, season: 0 })
      const season = ref(localStorage.getItem("season"))
      const seasons = ref(JSON.parse(localStorage.getItem("seasons")))

      const getPlayersfromRoute = async (playersSearch) => {
        try {
          var { data } = await axios.get("/player-compare?season=" + forms.value.season + "&player=" + playersSearch)
          players.value = data
        } catch (error) {
          console.log(error)
        }
      }

      onBeforeMount(async () => {
        let findPlayers =
          router.currentRoute.query.players != undefined ? router.currentRoute.query.players.split(",") : ""
        let uniqFindPlayer = [...new Set(findPlayers)]
        if (uniqFindPlayer.length > 0) getPlayersfromRoute(uniqFindPlayer.toString())

        try {
          var { data } = await axios.get("/team")
          teams.value = data.data

          isBusy.value = false
        } catch (error) {}
      })

      const handleTeam = async () => {
        try {
          var { data } = await axios.get("/team/" + forms.value.team.id)
          forms.value.team = data
        } catch (error) {
          console.log("err", error)
        }
      }

      const teamPlayersLength = () => {
        return forms.value && forms.value.team && forms.value.team.players ? forms.value.team.players.length : 0
      }

      const addPlayer = async () => {
        // FOR ARRAY UNIQUE
        if (players.value.find((d) => d.player.id == forms.value.selectPlayer.id && d.season?.id == forms.value.season))
          return

        let playersVals = ""

        var { data } = await axios.get(
          "/player-compare?season=" + forms.value.season + "&player=" + forms.value.selectPlayer.id,
        )

        forms.value.selectPlayer = null

        players.value.push(data[0])
      }

      watch([players], () => {
        let playersUnique = [...new Set(players.value)]

        let playersVals = ""

        playersVals = Object.values(players.value).map((d) => d.player.id + (d.season ? "-" + d.season.id : ""))

        if (playersVals.toString() != router.currentRoute.query.players)
          router.replace({ query: { players: playersVals.toString() } })
      })

      const isOpen = ref(false)

      const getRandomKey = () => Math.floor(Math.random() * 99999 + 1)

      const findAge = (birthdate) =>
        Math.abs(new Date(Date.now() - new Date(birthdate).getTime()).getUTCFullYear() - 1970)

      const getLink = ref((player) => {
        var filterSeason = player.playerTransferHistories.find((d) => d.isActive == 1) || null
        if (filterSeason == null) return "#"
        return `/season/${parseInt(filterSeason.season.id)}/team/${filterSeason.team.id}/players/${player.id}`
      })

      return {
        getLink,
        players,
        teams,
        teamPlayersLength,
        forms,
        seasons,

        isOpen,
        isBusy,
        getRandomKey,

        handleTeam,
        addPlayer,
        findAge,
        getPlayersfromRoute,
      }
    },
  }
</script>
