<template>
  <div class="d-flex flex-column">
    <b-card no-body class="p-1">
      <h4 class="p-0 m-0">{{ $t("Scout Player Watch List") }}</h4>
      <div class="mt-2" v-if="items.length == 0">
        <div class="alert alert-primary p-2">{{ $t("No players followed") }}</div>
      </div>
    </b-card>

    <b-card no-body>
      <b-card-header class="pb-50">
        <h5>{{ $t("Filter") }}</h5>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col cols="12" md="12" class="mb-md-0 mb-2">
            <label>{{ $t("Player Name") }} / {{ $t("Team") }}</label>
            <b-input v-model="name" :placeholder="$t('Search')" type="search" />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="4" class="mb-md-0 mb-2">
            <label>{{ $t("Strong foot") }}</label>
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              v-model="foot"
              :options="footOptions"
              class="w-100"
              :reduce="(item) => item.id"
              :getOptionLabel="(item) => item.label"
            />
          </b-col>
          <b-col cols="12" md="4" class="mb-md-0 mb-2">
            <label>{{ $t("Position") }}</label>
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              v-model="position"
              :options="positionOptions"
              class="w-100"
              :reduce="(item) => item.id"
              :getOptionLabel="(item) => item.name"
            />
          </b-col>
          <b-col cols="12" md="4" class="mb-md-0 mb-2">
            <label>{{ $t("Age") }}</label>
            <b-input v-model="age" :placeholder="$t('Age')" type="search" />
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <div class="row pr-1">
      <div class="col-md-12">
        <div class="row">
          <div v-for="item in items" :key="item.playerId" class="col-xl-3 col-lg-4 col-md-6 pr-0 pl-1 mb-2">
            <div class="shadow-sm rounded px-1 pt-1 bg-white pl-card">
              <div class="d-flex p-0">
                <div class="d-flex w-100">
                  <div class="p-0">
                    <b-avatar :src="item.avatar ? item.avatar : ''" size="80px" rounded />
                  </div>
                  <div class="w-100">
                    <div class="d-flex justify-content-between">
                      <div>
                        <b-link
                          class="mt-0 ml-1 d-block pl-name h6 text-primary"
                          :to="{
                            name: 'scout-player-view',
                            params: {
                              playerId: item.playerId,
                            },
                          }"
                          ><p class="small-text">{{ item.playerName }}</p>
                        </b-link>
                        <span class="ml-1 mb-1 d-block"
                          >{{ new Date(item.birthdate).toLocaleDateString() }} ({{ findAge(item.birthdate) }})</span
                        >
                      </div>

                      <div class="">
                        <b-link
                          :to="{
                            name: 'scout-player-view',
                            params: {
                              playerId: item.playerId,
                            },
                          }"
                          v-b-popover.hover.top="
                            $t('scout_player_review_detail', {
                              rev: item.reviewCount,
                              pts: item.reviewAvg ? parseFloat(parseFloat(item.reviewAvg).toFixed(2)).toFixed(1) : '',
                            })
                          "
                        >
                          <p class="h1 mb-0">
                            {{ item.reviewAvg ? parseFloat(parseFloat(item.reviewAvg).toFixed(2)).toFixed(1) : "" }}
                          </p>
                          <span class="h5 ml-1">({{ item.reviewCount }})</span>
                        </b-link>
                      </div>
                    </div>

                    <div class="pl-1 d-flex" style="width: fit-content">
                      <span :class="`badge-` + item.positionColor" class="badge mr-1 col">{{ $t(item.position) }}</span>
                      <span class="badge badge-dark col">{{
                        item.foot === 1 ? $t("Right Foot") : item.foot === 0 ? $t("Left Foot") : $t("Both Feet")
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <span class="badge badge-info col mb-1 mt-1"
                  >{{ item.clubName ? item.clubName : "" }} / {{ item.teamName }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import vSelect from "vue-select"
  import { ref, onUnmounted, onMounted, watch, computed } from "@vue/composition-api"
  import { playerouter, useRouter } from "@core/utils/utils"
  import i18n from "@/libs/i18n"
  import http from "@/libs/axios"
  import store from "@/store"
  import TransferSidebar from "@/views/scout/player/transfer-sidebar.vue"
  import FilterList from "@/views/scout/player/filter-list.vue"
  import localService from "@/services/localService.js"

  export default {
    components: {
      vSelect,
    },
    setup(props, { root }) {
      const items = ref([])
      const loading = ref(false)

      const name = ref(null)
      const foot = ref(null)
      const position = ref(null)
      const positionOptions = ref([])
      const team = ref(null)
      const teams = ref([])
      const age = ref(null)
      const footOptions = ref([
        {
          id: 0,
          label: i18n.t("Left Foot"),
        },
        {
          id: 1,
          label: i18n.t("Right Foot"),
        },
        {
          id: 2,
          label: i18n.t("Both Feet"),
        },
      ])
      let clubLocal = JSON.parse(localStorage.getItem("teams"))
        ? JSON.parse(localStorage.getItem("teams"))
            .filter((i) => i.club)
            .map((i) => i.club.id)
        : []

      onMounted(async () => {
        await fetchWatchList()
        await getTeams()
        await getPositions()
      })

      watch([name, foot, position, team, age], () => {
        fetchWatchList()
      })

      const fetchWatchList = () => {
        loading.value = true

        let params = {}
        if (name.value != null) params.name = name.value
        if (team.value != null) params.team = team.value
        if (position.value != null) params.position = position.value
        if (foot.value != null) params.foot = foot.value
        if (age.value != null) params.age = age.value

        http
          .get(`/scout/players-competition`, { params })
          .then((res) => {
            items.value = res.data.data
            loading.value = false
          })
          .catch((error) => console.log(error))
      }

      const findAge = (birthdate) => {
        return Math.abs(new Date(Date.now() - new Date(birthdate).getTime()).getUTCFullYear() - 1970)
      }

      const getTeams = () => {
        http
          .get(`/team?club=${clubLocal.join(",")}`)
          .then((res) => {
            teams.value = res.data.data
          })
          .catch((error) => console.log(error))
      }

      const getPositions = () => {
        http
          .get(`/position`)
          .then((res) => {
            positionOptions.value = res.data
          })
          .catch((error) => console.log(error))
      }

      return {
        items,
        findAge,
        name,
        foot,
        position,
        positionOptions,
        team,
        teams,
        age,
      }
    },
  }
</script>

<style lang="scss" scoped>
  .card_title {
    margin-top: -45px;
  }
  .pl-card {
    overflow: hidden;
    .pl-name {
      display: block;
      height: 1.4rem;
      overflow-y: hidden;
    }
  }
</style>

<style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
</style>
