<template>
  <div class="scrollable-area">
    <b-card class="training-print" style="min-width: 1024px">
      <!-- HEADER -->
      <div class="d-flex justify-content-between">
        <span class="h4">
          {{ (team.club && team.club.name) + team.name }}
          <span class="text-small small-text">
            <a @click="printPage" class="hide-print">{{ $t("Print") }}</a>
          </span>
        </span>
        <span>
          <b-avatar :src="team.club && team.club.logo" size="32"></b-avatar>
        </span>
      </div>

      <!-- BODY -->
      <div class="d-flex justify-content-between mt-2">
        <div class="col-6 pl-0">
          <table class="table table-bordered">
            <thead>
              <tr>
                <td class="bg-light" colspan="2">{{ $t("Group Training") }}</td>
              </tr>
            </thead>
            <tbody class="t-1">
              <tr>
                <td>{{ $t("Date") }}</td>
                <td>{{ training.startDate | nativeDate }}</td>
              </tr>
              <tr>
                <td>{{ $t("Location") }}</td>
                <td v-text="training.location && training.location.name"></td>
              </tr>
              <tr>
                <td>{{ $t("Time") }}</td>
                <td>
                  {{
                    new Date(training.startDate).toLocaleTimeString($i18n.locale, {
                      hour: "numeric",
                      minute: "numeric",
                    })
                  }}
                </td>
              </tr>
              <tr>
                <td>{{ $t("Topics") }}</td>
                <td>
                  {{
                    training.trainingBlocks &&
                    training.trainingBlocks
                      .map((d) => "," + d.title.split(" ")[0] + " " + $t(d.title.split(" ")[1]))
                      .join("")
                      .substr(1)
                  }}
                </td>
              </tr>
              <tr>
                <td>{{ $t("Attendance") }}</td>
                <td v-if="training.trainingAttenders">
                  {{ training.trainingAttenders.filter((item) => item.attendant == true).length }}
                  {{ $t("Attended") }} -
                  {{ training.trainingAttenders.filter((item) => item.attendant != true).length }}
                  {{ $t("UnAttended") }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-6 pr-1">
          <table class="table table-bordered">
            <thead>
              <tr>
                <td class="bg-light" colspan="2">{{ $t("Training Blocks") }}</td>
              </tr>
            </thead>
            <tbody class="t-1" :set="(tmpTitles = [])">
              <tr
                v-for="(tb, index) in training.trainingBlocks"
                :key="tb.id"
                :set="(tmpTitles[index] = tb.title.split(' '))"
              >
                <td>{{ tmpTitles[index][0] + " " + $t(tmpTitles[index][1]) }}</td>
                <td>{{ sum(tb) }} {{ $t("min") }}</td>
              </tr>
              <tr>
                <td>{{ $t("Total Training Time") }}</td>
                <td>{{ training && sumTotally(training) }} {{ $t("min") }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <!-- PLAYERS -->
      <div class="d-flex justify-content-between mt-2" v-if="loadedTraining">
        <div class="col-6 pl-0">
          <table class="table table-bordered">
            <thead>
              <tr>
                <td class="bg-light" colspan="1">{{ $t("Players") }}</td>
                <td class="bg-light" colspan="1">{{ $t("Comment") }}</td>
              </tr>
            </thead>
            <tbody class="t-1">
              <tr
                v-for="tb in training.trainingAttenders
                  .filter((item) => item.attendant == true)
                  .slice(
                    0,
                    parseInt(training.trainingAttenders.filter((item) => item.attendant == true).length / 2) + 1,
                  )"
                :key="tb.player.id"
                style="height: 22px; padding: 0px; font-size: 12px"
              >
                <template v-if="tb.attendant">
                  <td>{{ tb.player.name }}</td>
                  <td></td>
                </template>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-6 pr-1">
          <table class="table table-bordered">
            <thead>
              <tr>
                <td class="bg-light" colspan="1">{{ $t("Players") }}</td>
                <td class="bg-light" colspan="1">{{ $t("Comment") }}</td>
              </tr>
            </thead>
            <tbody class="t-1">
              <tr
                v-for="tb in training.trainingAttenders
                  .filter((item) => item.attendant == true)
                  .slice(
                    parseInt(training.trainingAttenders.filter((item) => item.attendant == true).length / 2) + 1,
                    training.trainingAttenders.filter((item) => item.attendant == true).length,
                  )"
                :key="tb.player.id"
                style="height: 22px; padding: 0px; font-size: 12px"
              >
                <template v-if="tb.attendant">
                  <td>{{ tb.player.name }}</td>
                  <td></td>
                </template>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <!-- TRAINING LEARNING OUTCOMES -->
      <div class="d-flex justify-content-between mt-2" v-if="loadedTraining">
        <div class="col-6 pl-0">
          <table class="table table-bordered">
            <thead>
              <tr>
                <td class="bg-light" colspan="1">{{ $t("Learning Outcomes") }}</td>
                <td class="bg-light" colspan="1">{{ $t("Duration") }}</td>
              </tr>
            </thead>
            <tbody class="t-1">
              <template
                v-for="block in training.trainingBlocks.slice(0, parseInt(training.trainingBlocks.length / 2) + 1)"
                class="outcome-div"
              >
                <template v-for="trainingItem in block.trainingItems">
                  <tr v-for="(loc, index) in trainingItem.learningOutcomes" :key="trainingItem.id">
                    <td :key="index">
                      <span class="text-nowrap text-small py-0" v-b-tooltip.hover :title="loc.name">{{
                        loc.name
                      }}</span>
                    </td>
                    <td :key="index">
                      <span class="text-nowrap text-small py-0">{{ trainingItem.duration }} {{ $t("min") }}</span>
                    </td>
                  </tr>
                </template>
              </template>
            </tbody>
          </table>
        </div>
        <div class="col-6 pr-1">
          <table class="table table-bordered">
            <thead>
              <tr>
                <td class="bg-light" colspan="1">{{ $t("Learning Outcomes") }}</td>
                <td class="bg-light" colspan="1">{{ $t("Duration") }}</td>
              </tr>
            </thead>
            <tbody class="t-1">
              <template
                v-for="block in training.trainingBlocks.slice(
                  parseInt(training.trainingBlocks.length / 2) + 1,
                  training.trainingBlocks.length,
                )"
                class="outcome-div"
              >
                <template v-for="trainingItem in block.trainingItems">
                  <tr v-for="(loc, index) in trainingItem.learningOutcomes" :key="trainingItem.id">
                    <td :key="index">
                      <span class="text-nowrap text-small py-0" v-b-tooltip.hover :title="loc.name">{{
                        loc.name
                      }}</span>
                    </td>
                    <td :key="index">
                      <span class="text-nowrap text-small py-0">{{ trainingItem.duration }} {{ $t("min") }}</span>
                    </td>
                  </tr>
                </template>
              </template>
            </tbody>
          </table>
        </div>
      </div>

      <span class="h3 mt-2 d-block">{{ $t("Comments") }}</span>
      <div class="d-flex w-100 p-2 bordered border mt-1" style="height: 230px"></div>

      <!-- TRAINING BLOCKS -->
    </b-card>

    <b-card class="training-blocks">
      <div class="" v-if="training && training.trainingBlocks" class="mt-2">
        <table class="table table-bordered table-striped">
          <thead>
            <tr>
              <td>{{ $t("Drill") }}</td>
              <td>{{ $t("Info") }}</td>
            </tr>
          </thead>
          <tbody class="training-item-medias">
            <template v-for="block in training.trainingBlocks">
              <tr :key="block.id" v-for="item in block.trainingItems">
                <td>
                  <template v-for="medias in item.trainingItemMedia">
                    <span v-html="medias.path" :key="medias.id"></span>
                  </template>
                </td>
                <td>
                  <span v-html="item.title || item.name" class="d-block h4"></span>
                  <span v-html="item.description"></span>
                  <br />
                  <span class="h6 mt-1 d-block">{{ $t("Learning Outcomes") }}</span>
                  <span
                    v-for="outComes in item.learningOutcomes"
                    :key="outComes"
                    v-html="outComes.name + '<br>'"
                  ></span>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </b-card>
  </div>
</template>
<script>
  import AppCollapse from "@core/components/app-collapse/AppCollapse.vue"
  import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue"
  import PreviewInfoBar from "../preview/PreviewInfoBar.vue"
  import PreviewTrainingPlayerTest from "../preview/PreviewTrainingPlayerTest.vue"
  import { ref } from "@vue/composition-api"

  export default {
    components: {
      PreviewInfoBar,
      AppCollapse,
      AppCollapseItem,
      PreviewTrainingPlayerTest,
    },
    data() {
      return {
        team: {},
        training: {},
        showImgModal: false,
        imgModal: false,
        loadedTraining: false,
      }
    },
    methods: {
      getTraining() {
        this.$http
          // TODO : GET TRAINING FROM API
          // .get(`https://run.mocky.io/v3/241914e8-89a4-42c3-9063-5992fab18a4b`)
          .get(`/training/${this.routerParams.trainingId}`)
          .then((response) => {
            this.training = response.data
          })
          .catch((err) => {
            // TODO : TRANSLATE
            this.toast(this.$t("Data not found"), "EyeIcon", "danger")
          })
          .finally(() => (this.loadedTraining = true))

        this.$http
          .get("/team/" + this.routerParams.id)
          .then((d) => (this.team = d.data))
          .catch((err) => console.log(err))
      },
    },
    beforeMount() {
      this.getTraining()
    },
    setup(props, { root }) {
      const getMedias = (media, type) => {
        if (!media) return []
        return Object.values(media).filter((item) => {
          return item.type == type
        })
      }

      // SUM FOR TRAINING ITEMS DURATION
      const sum = ref((data) => {
        var t = 0
        Object.values(data.trainingItems).map((dd) => (t += dd.duration))
        return t
      })

      // SUM FOR TRAINING ITEMS DURATION TOTALLY
      const sumTotally = ref((data) => {
        var t = 0
        Object.values(data.trainingBlocks).map((d) => Object.values(d.trainingItems).map((dd) => (t += dd.duration)))
        return t
      })

      const printPage = () => {
        window.print()
      }

      window.addEventListener("beforeprint", (event) => {
        document.querySelector(".main-menu.menu-fixed").style.display = "none"
        document.querySelector(".app-content.content").style.marginLeft = "0px"
        setTimeout(() => {
          document.querySelector(".main-menu.menu-fixed").removeAttribute("style")
          document.querySelector(".app-content.content").removeAttribute("style")
        }, 500)
      })

      return {
        printPage,
        sum,
        sumTotally,
        getMedias,
      }
    },
  }
</script>
<style lang="scss">
  .collapse-default {
    all: unset !important;
  }
  span {
    figure {
      img {
        max-width: 100%;
        width: 100%;
      }
    }
  }
  .training-items {
    .row + small {
      position: absolute;
      left: 2rem;
      bottom: 1.5rem;
    }
    .border {
      box-shadow: 0 0 15px rgba(1, 1, 1, 0.04);
      height: 300px;
      overflow: scroll;
      @media (max-width: 768px) {
        margin-bottom: 1rem;
      }
    }
  }
  .scrollable-area {
    @media screen {
      overflow: auto;
      max-width: 100%;
      max-height: 200vh;
    }
  }

  .training-print {
    @media print {
      margin-top: -10px;
      .hide-print {
        display: none;
      }
    }

    .training-item-medias {
      img {
        width: 100%;
        max-height: 250px;
        margin: 0 auto;
      }
    }
  }
</style>
