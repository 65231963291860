<template>
  <section id="dashboard-ecommerce">
    <b-row>
      <b-col lg="3">
        <b-card>
          <b-link :to="{ name: 'scout-watch-list' }">
            <b-media class="">
              <div class="d-flex align-items-center justify-content-center">
                <b-avatar size="48" :variant="`success`">
                  <feather-icon size="24" :icon="`EyeIcon`" />
                </b-avatar>
              </div>
              <b-media-body class="mt-1 text-center">
                <h5 class="font-weight-bolder mb-0">
                  {{ $t("Player Watch List") }}
                </h5>
              </b-media-body>
            </b-media>
          </b-link>
        </b-card>
      </b-col>
      <b-col lg="3">
        <b-card>
          <b-link :to="{ name: 'scout-players' }">
            <b-media class="">
              <div class="d-flex align-items-center justify-content-center">
                <b-avatar size="48" :variant="`primary`">
                  <feather-icon size="24" :icon="`UserIcon`" />
                </b-avatar>
              </div>
              <b-media-body class="mt-1 text-center">
                <h5 class="font-weight-bolder mb-0">
                  {{ $t("Player Filter") }}
                </h5>
              </b-media-body>
            </b-media>
          </b-link>
        </b-card>
      </b-col>
      <b-col lg="3">
        <b-card>
          <b-link :to="{ name: 'scout-competitions' }">
            <b-media class="">
              <div class="d-flex align-items-center justify-content-center">
                <b-avatar size="48" :variant="`info`">
                  <feather-icon size="24" :icon="`ApertureIcon`" />
                </b-avatar>
              </div>
              <b-media-body class="mt-1 text-center">
                <h5 class="font-weight-bolder mb-0">
                  {{ $t("Competitions") }}
                </h5>
              </b-media-body>
            </b-media>
          </b-link>
        </b-card>
      </b-col>
      <b-col lg="3">
        <b-card>
          <b-link :to="{ name: 'scout-player-transfer' }">
            <b-media class="">
              <div class="d-flex align-items-center justify-content-center">
                <b-avatar size="48" :variant="`info`">
                  <feather-icon size="24" :icon="`ArrowRightIcon`" />
                </b-avatar>
              </div>
              <b-media-body class="mt-1 text-center">
                <h5 class="font-weight-bolder mb-0">
                  {{ $t("Scout Player Transfer") }}
                </h5>
              </b-media-body>
            </b-media>
          </b-link>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>
<script>
  import http from "@/libs/axios"
  import store from "@/store"
  import { useRouter } from "@core/utils/utils"
  import i18n from "@/libs/i18n"
  import { ref, onUnmounted, onMounted } from "@vue/composition-api"

  export default {
    components: {},

    setup(props, { emit, root }) {
      const { router, route } = useRouter()

      onMounted(async () => {})

      const getSeasons = () => {}

      return {}
    },
  }
</script>
