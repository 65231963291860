<template>
  <div>
    <player-transfer-history-new
      :player-data="playerData"
      :is-add-transfer-history-active.sync="isAddTransferHistoryActive"
      :submit-transfer-history="submitTransferHistory"
      :update-transfer-history="updateTransferHistory"
      :player-transfer-history-form="transferHistory"
    />

    <b-row>
      <b-col md="4" v-for="pd in playerData.playerTransferHistories" :key="pd.id">
        <b-card style="border: 1px solid #ddd">
          <div class="card-header p-0 m-0 mb-2">
            <div class="d-flex align-items-start">
              <span class="mr-2">
              <b-img v-if="!pd.isNationalTeam && pd.team.club && pd.team.club.logo" class="mr-2" :src="pd.team.club.logo" rounded="0" width="50" />
              <country-flag v-else-if="pd.isNationalTeam && playerData.nation" :country="playerData.nation" size="big" class="rounded" />
              <b-img v-else-if="pd.isNationalTeam && !playerData.nation" class="mr-2"
                     :src="`/img/default-club-logo.png`" rounded="0" v-b-tooltip title="Please select country nation"
                     width="50" />
              </span>
              <div class="text-left" style="max-width: 150px">
                <template v-if="!pd.isNationalTeam">
                  {{ pd.team.club.name }}<br />
                  {{ pd.team.name }}
                </template>
                <template v-else>
                  {{ $t("National Team") }}<br />
                  {{ "" }}
                </template>
              </div>
            </div>
            <div>
              <span class="btn btn-light" @click="goSidebarTransferHistory(pd)">
                <feather-icon icon="EditIcon"></feather-icon>
              </span>
              <span class="btn btn-light ml-1" @click="goDeleteTransferHistory(pd.id)">
                <feather-icon icon="Trash2Icon"></feather-icon>
              </span>
            </div>
          </div>
          <div class="mb-2">
            <div>
              <span class="badge badge-light text-dark">
                {{ new Date(pd.startDate).toLocaleDateString($i18n.locale) }}
              </span>
              <span class="badge badge-light text-dark ml-1">
                {{ pd.endDate ? new Date(pd.endDate).toLocaleDateString($i18n.locale) : "-" }}
              </span>
              <b-badge variant="success" class="ml-1" v-if="pd.isActive && !pd.isSwapped">{{ $t("Active") }}</b-badge>
              <b-badge variant="primary" class="ml-1" v-if="!pd.isNationalTeam && !pd.isActive && pd.isSwapped">
                {{ $t("Swapped") }}
              </b-badge>
              <b-badge variant="primary" class="ml-1" v-if="pd.isNationalTeam && !pd.isActive && pd.isSwapped">
                {{ $t("National Team") }}
              </b-badge>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-button
      variant="primary"
      v-b-toggle.sidebar-add-transfer-history
      type="button"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      @click="transferHistory = {}"
    >{{ $t("Add New") }}
    </b-button
    >
  </div>
</template>

<script>
  import flatPickr from "vue-flatpickr-component"
  import vSelect from "vue-select"
  import { ref } from "@vue/composition-api"
  import PlayerTransferHistoryNew from "./PlayerTransferHistoryNew.vue"

  export default {
    components: {
      vSelect,
      flatPickr,
      PlayerTransferHistoryNew,
    },
    props: {
      playerData: {
        default: null,
      },
      submitTransferHistory: {
        default: null,
      },
      updateTransferHistory: {
        default: null,
      },
      deleteTransferHistory: { default: null },
    },
    data() {
      return {
        transferHistory: null,
        isAddTransferHistoryActive: false,
      }
    },
    watch: {},
    methods: {
      goSidebarTransferHistory(playerTransferHistoryData) {
        this.transferHistory = {
          id: null,
          club: null,
          season: null,
          startDate: null,
          team: null,
          endDate: null,
          isNationalTeam: null,
          isSwapped: null,
        }
        this.transferHistory.id = playerTransferHistoryData.id
        this.transferHistory.club = playerTransferHistoryData.club.id
        this.transferHistory.team = playerTransferHistoryData.team.id
        this.transferHistory.season = playerTransferHistoryData.season.id
        this.transferHistory.startDate = playerTransferHistoryData.startDate
        this.transferHistory.endDate = playerTransferHistoryData.endDate
        this.transferHistory.isNationalTeam = playerTransferHistoryData.isNationalTeam
        this.transferHistory.isSwapped = playerTransferHistoryData.isSwapped
        this.isAddTransferHistoryActive = true
      },
      goDeleteTransferHistory(transferHistoryId) {
        this.$bvModal
          .msgBoxConfirm(this.$t("Please confirm that you want to delete this."), {
            title: this.$t("Please Confirm"),
            size: "sm",
            okVariant: "primary",
            okTitle: this.$t("Yes"),
            cancelTitle: this.$t("No"),
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          })
          .then((value) => {
            if (value) {
              this.deleteTransferHistory(transferHistoryId)
            }
          })
      },
    },
    beforeMount() {
    },
    setup(props, { root }) {
      const languageOptions = ["Türkçe", "English", "Spanish", "French", "Russian", "German"]

      return {
        languageOptions,
      }
    },
  }
</script>

<style lang="scss">
  @import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
