var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('vue-good-table',{attrs:{"columns":_vm.fields,"rows":_vm.items,"styleClass":"vgt-table striped","select-options":{
    enabled: true,
    selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
    selectionInfoClass: 'custom-class',
    selectionText: 'rows selected',
    clearSelectionText: 'clear',
    disableSelectInfo: true, // disable the select info panel on top// when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
  },"max-height":"300px"},on:{"on-selected-rows-change":function($event){return _vm.selectionChanged($event, _vm.blockId, _vm.itemId)}},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'id')?_c('span',{staticClass:"text-nowrap"},[_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(props.row.id))])]):_vm._e(),(props.column.field === 'duration')?_c('span',{staticClass:"text-nowrap"},[(props.row.vgtSelected)?_c('b-form-input',{staticClass:"w-75 h-25",attrs:{"type":"number"},model:{value:(props.row.duration),callback:function ($$v) {_vm.$set(props.row, "duration", $$v)},expression:"props.row.duration"}}):_vm._e()],1):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }