<template>
  <div>
    <div class="quick-player-insert">
      <b-form ref="form" class="repeater-form">
        <!-- Row Loop -->
        <b-row v-for="(item, index) in addItems" :id="index" :key="index" ref="quickPlayerRow">
          <!-- Player Name -->
          <!-- <b-col md="3">
            <b-form-group :label="$t('Player Name')" label-for="item-name">
              <b-form-input id="item-name" v-model="name" type="text" :placeholder="$t('Player Name')" />
            </b-form-group>
          </b-col> -->

          <b-col lg="3">
            <b-form-group :label="$t('Player Name')" label-for="item-name">
              <v-select
                @input="changedLabel"
                @search="onSearch"
                v-model="name"
                single
                :filterable="false"
                :options="testingPlayerLikeName"
                class="quick-player-name"
              >
                <template slot="no-options">
                  <p></p>
                </template>
                <template slot="option" slot-scope="option">
                  <div class="d-center">
                    {{ option.name + ", " + option.team.club.name + ", " + new Date(option.birthdate).getFullYear() }}
                  </div>
                </template>
              </v-select>
            </b-form-group>
          </b-col>

          <!-- Player Age -->
          <b-col md="2" class="ml-n1">
            <b-form-group :label="$t('Age')" label-for="cost">
              <b-form-input
                id="age"
                v-model="age"
                type="number"
                placeholder="14"
                @change="
                  birthdate = new Date(new Date().setFullYear(new Date().getFullYear() - Number(age))).getFullYear()
                "
              />
            </b-form-group>
          </b-col>
          <!-- Player Age -->
          <b-col md="2" class="ml-n1">
            <b-form-group :label="$t('Birth Year')" label-for="cost">
              <b-form-input
                id="year"
                v-model="birthdate"
                type="number"
                placeholder="2002"
                @change="age = Math.abs(new Date(Date.now() - new Date(birthdate).getTime()).getUTCFullYear() - 1970)"
              />
            </b-form-group>
          </b-col>

          <!-- Player Position -->
          <b-col md="2" class="ml-n1">
            <b-form-group :label="$t('Position')" label-for="position">
              <b-form-select
                :placeholder="$t('Player position')"
                id="position"
                value-field="id"
                text-field="name"
                :value="0"
                :options="positionOptions"
                v-model="position"
              ></b-form-select>
            </b-form-group>
          </b-col>

          <!-- Player Foot -->
          <b-col md="2" class="ml-n1">
            <b-form-group :label="$t('Strong Foot')" label-for="foot">
              <b-form-select
                :placeholder="$t('Strong foot')"
                id="foot"
                value-field="id"
                text-field="label"
                :value="0"
                :options="footOptions"
                v-model="foot"
              ></b-form-select>
            </b-form-group>
          </b-col>

          <!-- Actions Button -->
          <b-col md="1" class="mb-50">
            <div class="d-flex justify-content-between">
              <b-button @click="saveItem(index)" variant="outline-success" class="mt-0 mt-md-2">
                <feather-icon icon="SaveIcon"></feather-icon>
              </b-button>
            </div>
          </b-col>
          <b-col cols="12">
            <hr />
          </b-col>
        </b-row>
      </b-form>
    </div>
  </div>
</template>
<script>
  import { ref, onMounted } from "@vue/composition-api"
  import http from "@/libs/axios"
  import i18n from "@/libs/i18n"
  import vSelect from "vue-select"
  // import ToastificationContent from "../../../@core/components/toastification/ToastificationContent"
  // import router from "@/router"
  import _ from "lodash"

  export default {
    components: { vSelect },
    props: { teamId: null, reloadTeams: null, seasonId: null, teamPlayers: null, helper: null },
    data() {
      return {
        name: "",
        foot: "",
        age: "",
        position: "",
        birthdate: "",
        testingPlayerLikeName: [],
        transferLastTeam: false,
      }
    },
    watch: {
      age(newAge) {},
      birthdate(newYear) {},
    },
    methods: {
      saveItem(index) {
        let saveObject = {}
        saveObject.name = this.name
        saveObject.foot = Number(this.foot)
        saveObject.age = this.age
        saveObject.position = Number(this.position)
        this.submitQuickPlayer({ index, team: this.teamId, season: this.seasonId, ...saveObject }).then((response) => {
          this.name = ""
          this.foot = ""
          this.age = ""
          this.position = ""
          this.reloadTeams()
        })
      },
      changedLabel(player) {
        if (player.team.id != this.teamId) {
          this.$bvModal
            .msgBoxConfirm(this.$t("Player Swap Team Query"), {
              title: this.$t("Please Confirm"),
              size: "sm",
              okVariant: "primary",
              okTitle: this.$t("Yes"),
              cancelTitle: this.$t("No"),
              cancelVariant: "outline-secondary",
              hideHeaderClose: false,
              centered: true,
            })
            .then((value) => {
              if (value) {
                this.transferLastTeam = true
              }
              this.saveFormation(player)
            })
        } else {
          this.saveFormation(player)
        }
      },
      saveFormation(player) {
        if (player != null && player.id) {
          player.isStarterPlayer = 2
          player["stats"] = {
            yellowCard: null,
            yellowRedCard: null,
            redCard: null,
            goals: [],
            events: [],
          }
          this.teamPlayers.push(player)
          this.helper.drawFormationPlugin(player, this.teamId, this.transferLastTeam)
          this.name = ""
          this.testingPlayerLikeName = []
          this.transferLastTeam = false
        } else if (player == null) {
          this.name = ""
          this.testingPlayerLikeName = []
        } else {
          console.log("else")
        }
      },
      // get options
      onSearch(search, loading) {
        if (search.length) {
          loading(true)
          this.searchPlayer(loading, search, this)
          this.name = search
        }
      },
      searchPlayer: _.debounce((loading, search, vm) => {
        http.get(`/scout/player-search?name=${search}`).then((res) => {
          vm.testingPlayerLikeName = res.data.filter(
            ({ id: id1 }) => !vm.teamPlayers.some(({ id: id2 }) => id2 === id1),
          )
          loading(false)
        })
      }, 350),
    },
    setup(props, { root }) {
      const positionOptions = ref([])

      const footOptions = ref([
        {
          id: 0,
          label: i18n.t("Left Foot"),
        },
        {
          id: 1,
          label: i18n.t("Right Foot"),
        },
        {
          id: 2,
          label: i18n.t("Both Feet"),
        },
      ])

      onMounted(async () => {
        await getPositions()
      })

      const getPositions = () => {
        http
          .get(`/position`)
          .then((res) => {
            positionOptions.value = res.data
          })
          .catch((error) => console.log(error))
      }

      const submitQuickPlayer = function ({ index, team, season, name, foot, age, position }) {
        this.playerSendForm = {
          name: name,
          avatar: "user.png",
          team: team,
          season: season,
          foot: Number(foot),
          birthdate: new Date(new Date().setFullYear(new Date().getFullYear() - Number(age))),
          email: "scout@client.com",
          phone: "555 555 55 55",
          position: position,
        }
        return this.$http.post("/scout/player", this.playerSendForm).then((succ) => {
          this.loading = false
          let newPlayerId = succ.data.id
          root.toast(this.$t("Player saved successfully"), "UserIcon", "success")

          return new Promise((resolve) => {
            return resolve(succ.data)
          })
        })
      }

      const addItems = ref([{ id: null }])

      const addItemNew = function () {
        addItems.value.push({ id: null })
      }

      const deleteItem = function (index) {
        addItems.value.splice(index, 1)
      }

      return {
        footOptions,
        positionOptions,
        addItems,
        addItemNew,
        deleteItem,
        submitQuickPlayer,
      }
    },
  }
</script>
<style lang="scss">
  .quick-player-insert {
    .v-select {
      &.quick-player-name {
        .vs__dropdown-menu {
          min-width: fit-content;
        }
      }
    }
  }
</style>
