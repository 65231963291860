<template>
  <div class="">
    <b-modal id="modal-video" ref="isVideoModalOpen" hide-footer centered title="Video">
      <div style="height: 50vh; overflow: scroll" class="text-center">
        <div v-for="video in tempVideoData" :key="video.id" class="my-1 px-md-3">
          <div class="border-primary rounded p-1 block">
            <div class="embed-responsive embed-responsive-16by9">
              <span v-html="video.path"></span>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal id="modal-participant" ref="isAttendanceModalOpen" hide-footer centered :title="modalTitle">
      <div
        v-for="(data, index) in tempParticipantList"
        :key="index"
        class="d-flex justify-content-start align-items-center player_team_list mt-1"
      >
        <div class="mr-1">
          <b-avatar size="40" :src="data.player.avatar" />
        </div>
        <div class="profile-user-info">
          <h6 class="mb-0">
            {{ data.player.name }}
          </h6>
          <small class="text-muted">{{ $t(data.player.position.name) }}</small>
        </div>
      </div>
    </b-modal>

    <b-overlay variant="white" :show="loading" spinner-variant="primary" blur="0" opacity=".75" rounded="sm">
      <b-card :title="$t('Calendar')">
        <div class="d-flex justify-content-between mb-1">
          <div>
            <label>{{ $t("Show") }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
          </div>
          <div>
            <b-button-group v-if="$store.getters['app/currentBreakPoint'] !== 'xs'">
              <b-button
                variant="info"
                :class="{ active: showTable === 'competitions' }"
                @click="showTable = 'competitions'"
              >
                {{ $t("Competitions") }}
              </b-button>
              <b-button variant="info" :class="{ active: showTable === 'trainings' }" @click="showTable = 'trainings'">
                {{ $t("Trainings") }}
              </b-button>
            </b-button-group>
            <b-dropdown
              id="dropdown-team-players1"
              :text="$t('Please Select by...')"
              class="m-md-2 ml-1"
              v-if="$store.getters['app/currentBreakPoint'] === 'xs'"
            >
              <b-dropdown-item variant="info" @click="showTable = 'competitions'">
                {{ $t("Competitions") }}
              </b-dropdown-item>
              <b-dropdown-item variant="info" @click="showTable = 'trainings'">
                {{ $t("Trainings") }}
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
        <div class="mx-n2">
          <b-table
            :fields="showTable == 'competitions' ? competitionFields : fields"
            class="position-relative"
            :empty-text="$t('No records found')"
            responsive
            primary-key="id"
            show-empty
            :per-page="perPage"
            :current-page="currentPage"
            striped
            :items="showTable == 'competitions' ? getCompetitions : getTrainings"
            :sort-by.sync="sortBy"
            :sort-desc.sync="isSortDirDesc"
            ref="refTrainingTable"
          >
            <template #head(avgCoachReview)="scope">
              <div v-b-tooltip.hover :title="$t('Performances Score')">P. Score</div>
            </template>
            <template #head(serp)="data">
              <div v-b-tooltip.hover :title="$t('Session Rating of Perceived Exertion')">{{ $t("sERP") }}</div>
            </template>

            <template #cell(team)="data">
              <b>{{ getTeam(data.item).name }}</b>
            </template>
            <template #cell(match)="data">
              <competition-card :data="data.item" />
            </template>
            <template #cell(league)="data">
              <div class="d-flex justify-content-start">
                <div class="h5" style="line-height: 1.5">
                  <b>{{ data.value.name }}</b>
                </div>
              </div>
            </template>
            <template #cell(date)="data">
              <div class="h6" @click="goToMatch(data.item)">
                <b> {{ data.value | nativeDate }} </b>
                <br />
                {{ new Date(data.value).toLocaleTimeString($i18n.locale, { hour: "numeric", minute: "numeric" }) }}
              </div>
            </template>
            <template #cell(location)="data">
              <div class="stad" @click="goToMatch">
                <span class="small">{{ data.value.name }}</span>
              </div>
            </template>

            <!-- start training template -->

            <!-- general table td   class="border"    responsive="lg"  -->
            <template #cell(startDate)="data" style="width: 120px">
              <div @click="goToItem(data.item)">
                <b-link class="text-dark">
                  {{ data.value | nativeDateTime }}
                </b-link>
              </div>
            </template>

            <template #cell(trainingBlocks)="data">
              <div v-for="block in data.value" :key="block.id" class="outcome-div">
                <div v-for="trainingItem in block.trainingItems" :key="trainingItem.id">
                  <ul class="outcome-ul ml-n2" v-for="(loc, index) in trainingItem.learningOutcomes" :key="index">
                    <li
                      style="list-style: inside"
                      class="text-truncate text-uppercase"
                      v-b-tooltip.hover
                      :title="loc.name"
                    >
                      {{ loc.name }}
                    </li>
                  </ul>
                </div>
              </div>
            </template>

            <template #cell(location)="data">
              <span class="d-block text-truncate" v-b-tooltip.hover :title="data.value.name">{{
                data.value.name
              }}</span>
            </template>

            <template #cell(title)="data">
              <b-link @click="goToItem(data.item)" class="text-dark">
                <span
                  class="d-block text-truncate font-weight-bold text-uppercase"
                  v-b-tooltip.hover
                  :title="data.value"
                  >{{ data.value }}</span
                >
              </b-link>
            </template>

            <template #cell(trainingAttenders)="data">
              <b-link @click="showAttendanceModal(getAttenders(data.value, false), $t('Absents'))">
                <b-badge
                  variant="danger"
                  v-b-tooltip.hover
                  :title="$t('Absents') + ` :` + getAttendersCount(data.value, false)"
                  >{{ getAttendersCount(data.value, false) }}</b-badge
                >
              </b-link>
              /
              <b-link @click="showAttendanceModal(getAttenders(data.value, true), $t('Attendant'))">
                <b-badge
                  variant="success"
                  v-b-tooltip.hover
                  :title="$t('Attendant') + ` :` + getAttendersCount(data.value, true)"
                  >{{ getAttendersCount(data.value, true) }}</b-badge
                >
              </b-link>
            </template>

            <template #cell(trainingMedia)="data">
              <b-link
                @click="showVideoModal(getVideos(data.value, 'video'))"
                class="text-dark"
                style="margin-left: 3px"
              >
                <feather-icon icon="VideoIcon" size="22" :badge="getVideoCount(data.value, 'video')" />
              </b-link>
            </template>

            <template #cell(participant)="data">
              <b-badge
                v-b-modal.modal-participant
                @click="participantListModal(data.value)"
                v-b-tooltip.hover
                class="pull-up"
                :title="$t('Participant') + ` : ` + data.value.length"
                variant="success"
                rounded
              >
                {{ data.value.length }}
              </b-badge>
            </template>

            <template #cell(serp)="data">
              <b-link
                v-if="
                  data.item.status != 'pending' || data.item.trainingAttenders.filter((i) => i.playerSerpDate).length
                "
              >
                <b-badge
                  :set="(result = playerSerpAvg(data.item.trainingAttenders))"
                  :variant="result.isAllSent ? 'success' : 'danger'"
                  v-b-tooltip.hover
                >
                  {{ result.avg }}
                </b-badge>
              </b-link>
              <feather-icon
                v-else
                v-b-tooltip.hover
                :title="data.item.status === 'pending' ? 'Henüz Gerçekleşmedi' : 'Tamamlandı'"
                size="22"
                :icon="data.item.status === 'pending' ? 'ClockIcon' : `CheckIcon`"
              />
            </template>
          </b-table>
          <div class="mx-2 mb-2">
            <b-row>
              <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
                <span class="text-muted">{{
                  $t("format.showingData", { from: dataMeta.from, to: dataMeta.to, of: dataMeta.of })
                }}</span>
              </b-col>
              <!-- Pagination -->
              <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalItems"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
  import vSelect from "vue-select"
  import { useRouter } from "@core/utils/utils"
  import i18n from "@/libs/i18n"
  import { ref, watch, computed, onUnmounted, onMounted, onBeforeMount } from "@vue/composition-api"
  import http from "@/libs/axios"
  import { localService } from "@/services"
  import CompetitionCard from "@core/components/global-components/CompetitionCard"

  export default {
    components: {
      vSelect,
      CompetitionCard,
    },
    directives: {},
    methods: {},
    setup(props, { emit, root }) {
      const { router, route } = useRouter()

      onMounted(() => {
        showTable.value = new localService("dashboard_antrenor_tab").get() || "trainings"
      })

      const showTable = ref("")
      const refTrainingTable = ref(null)
      const trainings = ref([])

      const modalTitle = ref(null)
      const isVideoModalOpen = ref(false)
      const isAttendanceModalOpen = ref(false)
      const tempVideoData = ref([])
      const tempParticipantList = ref([])

      const loading = ref(false)
      const currentPage = ref(1)
      const totalItems = ref(0)
      const perPage = ref(localStorage.getItem("perPage") || 10)
      const perPageOptions = [10, 25, 50, 100]
      const isSortDirDesc = ref(true)
      const sortBy = ref("startDate")

      const localSeasonId = ref(localStorage.getItem("season"))
      const localTeamIds = ref(
        JSON.parse(localStorage.getItem("teams"))
          .map((d) => d.id)
          .join(),
      )

      const competitionFields = computed({
        get: () => [
          {
            key: "team",
            label: i18n.t("Team"),
            sortable: false,
          },
          {
            key: "league",
            label: i18n.t("League"),
            sortable: false,
            thStyle: { "min-width": "11rem" },
          },
          {
            key: "date",
            label: i18n.t("Date"),
            sortable: true,
            thStyle: { "min-width": "13rem" },
          },
          {
            key: "Match",
            label: i18n.t("Match"),
            sortable: false,
            thStyle: { "min-width": "9rem" },
          },
          {
            key: "location",
            label: i18n.t("Location"),
            sortable: false,
            thStyle: { "min-width": "15rem" },
          },
          {
            key: "avgCoachReview",
            label: i18n.t("Coach Review"),
            sortable: false,
            thStyle: { "min-width": "9rem" },
            formatter: (value) => value.toFixed(1),
          },
        ],
      })

      const fields = computed({
        get: () => [
          {
            key: "team.name",
            label: i18n.t("Team"),
            tdClass: "responsiveWidth",
          },
          {
            key: "startDate",
            label: i18n.t("Date"),
            sortable: true,
            thStyle: { width: "200px", "min-width": "200px", "max-width": "220px" },
          },
          // A column that needs custom formatting
          {
            key: "title",
            label: i18n.t("Training Title"),
            tdClass: "responsiveWidth",
          },
          // A regular column
          {
            key: "trainingBlocks",
            label: i18n.t("Exercises"),
            tdClass: "responsiveWidth",
          },
          {
            key: "trainingAttenders",
            label: i18n.t("Attendance"),
            thStyle: { width: "120px", "min-width": "120px", "max-width": "140px" },
          },
          {
            key: "trainingMedia",
            label: i18n.t("Video"),
            thStyle: { width: "80px", "min-width": "80px", "max-width": "100px" },
          },
          {
            key: "location",
            label: i18n.t("Location"),
            tdClass: "responsiveWidth",
          },
          {
            key: "serp",
            label: i18n.t("sERP"),
            sortable: true,
            thStyle: { width: "80px", "min-width": "80px", "max-width": "120px" },
          },
        ],
      })

      const goToItem = (item) =>
        router.push({
          name: "team-training-preview",
          params: { seasonId: localSeasonId.value, id: item.team.id, trainingId: item.id },
        })

      const goToMatch = (comp) =>
        router.push({
          name: "competition-edit",
          params: {
            seasonId: comp.season.id,
            id: getTeam(comp).id,
            competitionId: comp.id,
          },
        })

      const getTeam = (comp) => {
        let teams = JSON.parse(localStorage.getItem("teams")).map((d) => d.id) || []
        return teams.includes(comp.awayTeam.id) ? comp.awayTeam : comp.homeTeam
      }

      const getBr = (el) => {
        let dayName = el.split(" ").pop()
        el = el.split(" ")
        delete el[el.length - 1]
        el = el.join(" ")
        return el + "<br>" + dayName + ""
      }

      const dataMeta = computed(() => {
        const localItemsCount = trainings.value.length > 0 ? trainings.value.length : 0
        return {
          from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
          to: perPage.value * (currentPage.value - 1) + localItemsCount,
          of: totalItems.value,
        }
      })

      const showVideoModal = (data) => {
        tempVideoData.value = data
        data.length > 0
          ? isVideoModalOpen.value.show()
          : root.toast(i18n.t("No video available"), "AlertCircleIcon", "warning")
      }

      const showAttendanceModal = (data, text) => {
        modalTitle.value = text
        tempParticipantList.value = data
        data.length > 0 && isAttendanceModalOpen.value.show()
      }

      const getAttendersCount = (trainingAttenders, attendant) =>
        trainingAttenders.filter((item) => item.attendant == attendant && item.invited == true).length
      const getAttenders = (trainingAttenders, attendant) =>
        trainingAttenders.filter((item) => item.attendant == attendant && item.invited == true)

      const getVideoCount = (media, type) => media.filter((item) => item.type == type).length
      const getVideos = (media, type) => media.filter((item) => item.type == type)

      const playerSerpAvg = (obj) => {
        let arr = []
        let avgSerp = 0
        Object.values(obj).forEach((item) => {
          if (item.playerSerpDate) {
            arr.push(item.playerSerpScore)
          }
        })
        avgSerp = arr.length > 0 ? (arr.reduce((a, b) => a + b) / arr.length).toFixed(1) : 0
        return { avg: avgSerp, isAllSent: obj.length == arr.length }
      }

      const getCompetitions = () => {
        loading.value = true
        return http
          .get(`/season/${localSeasonId.value}/competition/find?team=${localTeamIds.value}`, {
            params: {
              team: localTeamIds.value,
              sortBy: sortBy.value ? sortBy.value : "date",
              isDesc: isSortDirDesc.value,
              row: perPage.value,
              page: currentPage.value,
            },
          })
          .then((res) => {
            trainings.value = res.data.data
            totalItems.value = res.data.count
            loading.value = false

            return trainings.value
          })
          .catch((err) => {
            console.log(err)
            loading.value = false
          })
      }

      const getTrainings = () => {
        loading.value = true
        return http
          .get(`season/${localSeasonId.value}/training/find`, {
            params: {
              teams: localTeamIds.value,
              sortBy: sortBy.value ? sortBy.value : "startDate",
              isDesc: isSortDirDesc.value,
              row: perPage.value,
              page: currentPage.value,
            },
          })
          .then((res) => {
            loading.value = false
            trainings.value = res.data.data
            totalItems.value = res.data.count

            return trainings.value
          })
          .catch((error) => {
            console.log(error)
            loading.value = false
          })
      }

      watch(perPage, (perPage) => {
        localStorage.setItem("perPage", perPage)
      })

      watch(showTable, async () => {
        sortBy.value = null
        currentPage.value = 1
        isSortDirDesc.value = true
        new localService("dashboard_antrenor_tab", showTable.value)
      })

      return {
        loading,
        perPage,
        currentPage,
        totalItems,
        perPageOptions,
        dataMeta,
        refTrainingTable,
        isSortDirDesc,
        sortBy,
        showTable,
        getCompetitions,
        competitionFields,

        tempParticipantList,
        tempVideoData,
        showAttendanceModal,
        showVideoModal,
        modalTitle,
        isVideoModalOpen,
        isAttendanceModalOpen,

        fields,
        trainings,
        goToItem,
        getVideos,
        getTrainings,
        getAttenders,
        getVideoCount,
        getAttendersCount,
        playerSerpAvg,

        goToMatch,
        getBr,
        getTeam,
      }
    },
  }
</script>

<style lang="scss">
  .per-page-selector {
    width: 90px;
  }

  .right-top {
    position: absolute;
    right: 2rem;
    top: 1rem;
  }

  .embed-video {
    iframe {
      max-width: 100%;
      max-height: 30vh;
    }
  }
  .outcome-ul {
    margin-top: 1px;
    margin-bottom: 0px;
    font-size: 13px;
  }
  .outcome-div {
    padding-right: 0px;
    margin-left: -25px;
  }
  .responsiveWidth {
    min-width: 2em;
    width: 2em;
    max-width: 15em;
  }
</style>
