<template>
  <component :is="playerData === undefined ? 'div' : 'b-card'">
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="playerData === undefined">
      <h4 class="alert-heading">{{ $t("Error Fetching Data") }}</h4>
      <div class="alert-body">
        {{ $t("No data found with this data id") }} {{ $t("Check") }}
        <b-link class="alert-link" :to="{ name: 'apps-players-list' }">{{ $t("Player List") }}</b-link>
      </div>
    </b-alert>
    <b-tabs v-if="playerData" pills v-model="activeTab">
      <!-- Tab: Account -->

      <b-tab active>
        <template #title>
          <feather-icon icon="UserIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">{{ $t("General Information") }}</span>
        </template>
        <player-edit-tab-account :submit-handle="submitHandle" :player-data="playerData" class="mt-2 pt-75" />
      </b-tab>
      <!-- Tab: Social -->
      <b-tab>
        <template #title>
          <feather-icon icon="EditIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">{{ $t("Education") }}</span>
        </template>
        <player-education
          :submit-handle="submitHandle"
          :update-edu="updateEdu"
          :player-data="playerData"
          :submit-edu="submitEdu"
          :delete-edu="deleteEdu"
          class="mt-2 pt-75"
        />
      </b-tab>
      <!-- Tab: Information -->
      <b-tab>
        <template #title>
          <feather-icon icon="PhoneCallIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">{{ $t("Contact Information") }}</span>
        </template>
        <player-contact
          :submit-contact="submitContact"
          :update-contact="updateContact"
          :delete-contact="deleteContact"
          :player-data="playerData"
          class="mt-2 pt-75"
        />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="PenToolIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">{{ $t("Comment") }}</span>
        </template>
        <player-comment
          :submit-comment="submitComment"
          :update-comment="updateComment"
          :player="playerData"
          class="mt-2 pt-75 mx-n2"
        />
      </b-tab>
      <!-- Tab: Transfer History -->
      <b-tab>
        <template #title>
          <feather-icon icon="FileTextIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">{{ $t("Player Transfer History") }}</span>
        </template>
        <player-transfer-history
          :submit-transfer-history="submitTransferHistory"
          :update-transfer-history="updateTransferHistory"
          :delete-transfer-history="deleteTransferHistory"
          :player-data="playerData"
          class="mt-2 pt-75"
        />
      </b-tab>
      <div class="mt-1">
        <a
          v-if="playerNavigations.prev"
          class="btn btn-light"
          variant="light-primary"
          :href="$router.resolve({ name: 'team-player-edit', params: { playerId: playerNavigations.prev.id } }).href"
        >
          <feather-icon icon="ArrowLeftIcon"></feather-icon> {{ playerNavigations.prev.name }}
        </a>
        <a
          v-if="playerNavigations.next"
          class="btn btn-light ml-1"
          variant="light-primary"
          :href="$router.resolve({ name: 'team-player-edit', params: { playerId: playerNavigations.next.id } }).href"
        >
          {{ playerNavigations.next.name }} <feather-icon icon="ArrowRightIcon"></feather-icon>
        </a>
      </div>
    </b-tabs>
  </component>
</template>
<script>
  import { onUnmounted, ref, onBeforeMount } from "@vue/composition-api"
  import router from "@/router"
  import store from "@/store"
  import playerStoreModule from "../playerStoreModule"
  import PlayerEditTabAccount from "./PlayerEditTabAccount.vue"
  import PlayerContact from "./PlayerContact.vue"
  import PlayerComment from "./PlayerComment.vue"
  import PlayerEducation from "./PlayerEducation.vue"
  import PlayerTransferHistory from "./PlayerTransferHistory"
  import { useToast } from "vue-toastification/composition"
  import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
  import i18n from "@/libs/i18n"
  import axios from "@/libs/axios"

  export default {
    components: {
      ToastificationContent,
      PlayerEditTabAccount,
      PlayerContact,
      PlayerEducation,
      PlayerTransferHistory,
      PlayerComment,
    },
    setup(props, { root }) {
      const playerData = ref(null)

      const USER_APP_STORE_MODULE_NAME = "app-player"
      // Register module
      if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) {
        store.registerModule(USER_APP_STORE_MODULE_NAME, playerStoreModule)
      }
      // UnRegister on leave
      onUnmounted(() => {
        if (store.hasModule(USER_APP_STORE_MODULE_NAME)) {
          store.unregisterModule(USER_APP_STORE_MODULE_NAME)
        }
      })

      const getDetails = () => {
        store
          .dispatch("app-player/fetchPlayer", {
            id: router.currentRoute.params.playerId,
          })
          .then((response) => {
            playerData.value = response.data
            playerData.value.playerTransferHistories = playerData.value.playerTransferHistories.filter(
              (item) => item.isTesting != 1,
            )
            playerData.value.position = response.data.position.id
          })
          .catch((error) => {
            if (error.response.status === 404) {
              playerData.value = undefined
            }
          })
      }

      getDetails()

      const toast = useToast()

      const submitContact = (playerContactData) => {
        store
          .dispatch("app-player/addContact", {
            id: playerData.value.id,
            playerContactData,
          })
          .then((response) => {
            // TODO: Global

            toast({
              component: ToastificationContent,
              props: {
                title: i18n.t("Successfully Added"),
                icon: "PlusIcon",
                variant: "success",
              },
            })
            playerContactData.id = response.data.id
            playerContactData.created_at = response.data.created_at
            playerData.value.contacts.push(playerContactData)
          })
          .catch((error) => {
            // TODO: Global error...
            console.log("error", error)
            toast({
              component: ToastificationContent,
              props: {
                title: i18n.t("An error occurred"),
                icon: "AlertCircleIcon",
                variant: "danger",
              },
            })
          })
      }

      const submitEdu = (playerEduData) => {
        store
          .dispatch("app-player/addEducation", {
            id: playerData.value.id,
            playerEduData,
          })
          .then((response) => {
            // TODO: Global
            toast({
              component: ToastificationContent,
              props: {
                title: i18n.t("Successfully Added"),
                icon: "PlusIcon",
                variant: "success",
              },
            })
            playerEduData.id = response.data.id
            playerData.value.educations.push(playerEduData)
          })
          .catch((error) => {
            // TODO: Global error...
            toast({
              component: ToastificationContent,
              props: {
                title: i18n.t("An error occurred"),
                icon: "AlertCircleIcon",
                variant: "danger",
              },
            })
          })
      }

      const submitTransferHistory = (playerTransferHistoryData) => {
        store
          .dispatch("app-player/addTransferHistory", {
            id: playerData.value.id,
            playerTransferHistoryData,
          })
          .then((response) => {
            // TODO: Global
            toast({
              component: ToastificationContent,
              props: {
                title: i18n.t("Successfully Added"),
                icon: "PlusIcon",
                variant: "success",
              },
            })
            playerTransferHistoryData.id = response.data.id
            playerTransferHistoryData.team = response.data.team
            playerTransferHistoryData.club = response.data.club
            playerTransferHistoryData.season = response.data.season
            getDetails()
          })
          .catch((error) => {})
      }

      const submitComment = (data) => {
        store
          .dispatch("app-player/addComment", {
            id: playerData.value.id,
            data,
          })
          .then((response) => {
            // TODO: Global
            toast({
              component: ToastificationContent,
              props: {
                title: i18n.t("Successfully Added"),
                icon: "PlusIcon",
                variant: "success",
              },
            })
            data.id = response.data.id
            data.user = response.data.user
            data.player = response.data.player
            playerData.value.reviews.push(data)
          })
          .catch((error) => {
            console.log(error)
            toast({
              component: ToastificationContent,
              props: {
                title: i18n.t("An error occurred"),
                icon: "AlertCircleIcon",
                variant: "danger",
              },
            })
          })
      }

      const updateEdu = (playerEduData) => {
        store
          .dispatch("app-player/editEducation", {
            eduId: playerEduData.id,
            playerEduData,
          })
          .then((response) => {
            // TODO: Global
            toast({
              component: ToastificationContent,
              props: {
                title: i18n.t("Successfully Updated"),
                icon: "PlusIcon",
                variant: "success",
              },
            })

            let playerEdu = playerData.value.educations.find((x) => x.id === response.data.id)
            if (playerEdu) {
              playerEdu.schoolLevel = response.data.schoolLevel
              playerEdu.schoolName = response.data.schoolName
              playerEdu.schoolStatus = response.data.schoolStatus
              playerEdu.schoolPhone = response.data.schoolPhone
              playerEdu.schoolLevelClass = response.data.schoolLevelClass
            }
          })
          .catch((error) => {
            // TODO: Global error...
            toast({
              component: ToastificationContent,
              props: {
                title: i18n.t("An error occurred"),
                icon: "AlertCircleIcon",
                variant: "danger",
              },
            })
          })
      }

      const updateContact = (playerContactData) => {
        store
          .dispatch("app-player/editContact", {
            contactId: playerContactData.id,
            playerContactData,
          })
          .then((response) => {
            // TODO: Global
            toast({
              component: ToastificationContent,
              props: {
                title: i18n.t("Successfully Updated"),
                icon: "PlusIcon",
                variant: "success",
              },
            })

            let playerContact = playerData.value.contacts.find((x) => x.id === response.data.id)
            if (playerContact) {
              playerContact.contactLevel = response.data.contactLevel
              playerContact.contactName = response.data.contactName
              playerContact.contactAddress = response.data.contactAddress
              playerContact.contactPhone = response.data.contactPhone
            }
          })
          .catch((error) => {
            // TODO: Global error...
            toast({
              component: ToastificationContent,
              props: {
                title: i18n.t("An error occurred"),
                icon: "AlertCircleIcon",
                variant: "danger",
              },
            })
          })
      }

      const updateTransferHistory = (playerTransferHistoryData) => {
        store
          .dispatch("app-player/editTransferHistory", {
            transferHistoryId: playerTransferHistoryData.id,
            playerTransferHistoryData,
          })
          .then((response) => {
            // TODO: Global
            toast({
              component: ToastificationContent,
              props: {
                title: i18n.t("Successfully Updated"),
                icon: "PlusIcon",
                variant: "success",
              },
            })

            let playerTransfer = playerData.value.playerTransferHistories.find((x) => x.id === response.data.id)
            if (playerTransfer) {
              playerTransfer.team = response.data.team
              playerTransfer.club = response.data.club
              playerTransfer.season = response.data.season
              playerTransfer.startDate = response.data.startDate
              playerTransfer.endDate = response.data.endDate
              getDetails()
            }
          })
          .catch((error) => {
            // TODO: Global error...
            toast({
              component: ToastificationContent,
              props: {
                title: i18n.t("An error occurred"),
                icon: "AlertCircleIcon",
                variant: "danger",
              },
            })
          })
      }

      const updateComment = (data) => {
        store
          .dispatch("app-player/editComment", {
            dataId: data.id,
            data,
          })
          .then((response) => {
            // TODO: Global
            toast({
              component: ToastificationContent,
              props: {
                title: i18n.t("Successfully Updated"),
                icon: "PlusIcon",
                variant: "success",
              },
            })

            let playerComment = playerData.value.reviews.find((x) => x.id === response.data.id)
            if (playerComment) {
              playerComment.comment = response.data.comment
            }
          })
          .catch((error) => {
            // TODO: Global error...
            toast({
              component: ToastificationContent,
              props: {
                title: i18n.t("An error occurred"),
                icon: "AlertCircleIcon",
                variant: "danger",
              },
            })
          })
      }

      const deleteContact = (contactId) => {
        store
          .dispatch("app-player/deleteContact", {
            contactId,
          })
          .then((succ) => {
            // TODO: Global
            toast({
              component: ToastificationContent,
              props: {
                title: i18n.t("Successfully Deleted"),
                icon: "PlusIcon",
                variant: "success",
              },
            })
            playerData.value.contacts = playerData.value.contacts.filter((d) => {
              return d.id != contactId
            })
          })
          .catch((error) => {
            // TODO: Global error...
            toast({
              component: ToastificationContent,
              props: {
                title: i18n.t("An error occurred"),
                icon: "AlertCircleIcon",
                variant: "danger",
              },
            })
          })
      }

      const deleteEdu = (eduId) => {
        store
          .dispatch("app-player/deleteEducation", {
            eduId,
          })
          .then((succ) => {
            // TODO: Global
            toast({
              component: ToastificationContent,
              props: {
                title: i18n.t("Successfully Deleted"),
                icon: "PlusIcon",
                variant: "success",
              },
            })
            playerData.value.educations = playerData.value.educations.filter((d) => {
              return d.id != eduId
            })
          })
          .catch((error) => {
            // TODO: Global error...
            toast({
              component: ToastificationContent,
              props: {
                title: i18n.t("An error occurred"),
                icon: "AlertCircleIcon",
                variant: "danger",
              },
            })
          })
      }

      const deleteTransferHistory = (transferHistoryId) => {
        store
          .dispatch("app-player/deleteTransferHistory", {
            transferHistoryId,
          })
          .then((succ) => {
            // TODO: Global
            root.toast(i18n.t("Successfully Deleted"), "PlusIcon", "success")
            playerData.value.playerTransferHistories = playerData.value.playerTransferHistories.filter((d) => {
              return d.id != transferHistoryId
            })
          })
          .catch((error) => {
            root.toast(i18n.t("An error occurred"), "AlertCircleIcon", "danger")
          })
      }

      const submitHandle = () => {
        // TODO: current route params team id
        let playerSendData = playerData.value
        delete playerSendData.team
        playerSendData.teamId = Number(playerData.value.id)

        store
          .dispatch("app-player/updatePlayer", {
            id: playerData.value.id,
            playerData: playerSendData,
          })
          .then((succ) => {
            toast({
              component: ToastificationContent,
              props: {
                title: i18n.t("Successfully Updated"),
                icon: "PlusIcon",
                variant: "success",
              },
            })
          })
          .catch((error) => {
            // if (error.response.status === 404) {
            //   playerData.value = undefined;
            // }
          })
      }

      const playerNavigations = ref({ next: {}, prev: {} })
      onBeforeMount(() => {
        var playerId = router.currentRoute.params.playerId
        axios
          .get(`/season/${router.currentRoute.params.seasonId}/team/${router.currentRoute.params.id}`)
          .then((res) => {
            var filterNext = Object.values(res.data.players).findIndex((d) => d.id == playerId)
            var next = filterNext + 1
            var prev = filterNext - 1
            if (next == res.data.players.length) next = 0
            if (prev < 0) prev = 0

            next = res.data.players[next]
            prev = res.data.players[prev]

            playerNavigations.value = { next, prev }
          })
      })

      const activeTab = ref()

      return {
        activeTab,
        playerNavigations,
        playerData,
        submitHandle,

        submitEdu,
        updateEdu,
        deleteEdu,

        submitContact,
        updateContact,
        deleteContact,

        submitTransferHistory,
        updateTransferHistory,
        deleteTransferHistory,

        submitComment,
        updateComment,
      }
    },
  }
</script>
<style></style>
