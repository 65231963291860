<template>
  <li
    v-if="canViewVerticalNavMenuLink(item) && (item.setting ? getSetting(item.setting) : true)"
    class="nav-item"
    :class="{ active: isActive, disabled: item.disabled }"
  >
    <b-link v-bind="linkProps" class="d-flex align-items-center">
      <feather-icon :style="iconStyle" v-if="item.icon" :icon="item.icon || 'CircleIcon'" />
      <span v-if="!item.icon">{{ t(item.title) }}</span>
    </b-link>
  </li>
</template>

<script>
  import { useUtils as useAclUtils } from "@core/libs/acl"
  import { BLink, BBadge } from "bootstrap-vue"
  import { useUtils as useI18nUtils } from "@core/libs/i18n"
  import useVerticalNavMenuLink from "./useVerticalNavMenuLink"
  import mixinVerticalNavMenuLink from "./mixinVerticalNavMenuLink"
  import { onMounted, ref } from "vue-demi"

  export default {
    components: {
      BLink,
      BBadge,
    },
    mixins: [mixinVerticalNavMenuLink],
    props: {
      item: {
        type: Object,
        required: true,
      },
    },
    setup(props) {
      const { isActive, linkProps, updateIsActive } = useVerticalNavMenuLink(props.item)
      const { t } = useI18nUtils()
      const { canViewVerticalNavMenuLink } = useAclUtils()
      const iconStyle = {
        marginLeft: 7,
      }
      const linkActive = ref(false)

      onMounted(() => {
        if (props.item.route == "team-players") {
          // console.log(location.href,props.item)
          if (location.href.includes("team/" + props.item.slug)) {
            linkActive.value = true
          } else {
            linkActive.value = false
          }
        } else {
          linkActive.value = false
        }
      })

      return {
        linkActive,
        isActive,
        linkProps,
        updateIsActive,
        iconStyle,

        // ACL
        canViewVerticalNavMenuLink,

        // i18n
        t,
      }
    },
  }
</script>

<style>
  .badge .badge-up .badge-pill .badge-primary {
    margin-right: 10px !important;
  }
</style>
