var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"show":_vm.loading,"rounded":"sm"}},[_c('validation-observer',{ref:"addTrainingLibraryForm"},[_c('b-row',[_c('b-col',{attrs:{"lg":"3"}},[_c('b-card',[_c('validation-provider',{attrs:{"name":"trainingName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Drill Name'),"label-for":"name"}},[_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"id":"name","placeholder":_vm.$t('Drill Name')},model:{value:(_vm.trainingLibrary.name),callback:function ($$v) {_vm.$set(_vm.trainingLibrary, "name", $$v)},expression:"trainingLibrary.name"}})],1),_c('small',{staticClass:"text-danger mt-1"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('validation-provider',{attrs:{"name":"duration"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Planned Duration'),"label-for":"name"}},[_c('b-input-group',{staticClass:"mb-1",attrs:{"append":_vm.$t('dk')}},[_c('b-form-input',{staticClass:"w-75",attrs:{"state":errors.length > 0 ? false : null,"id":"duration","type":"number"},model:{value:(_vm.trainingLibrary.duration),callback:function ($$v) {_vm.$set(_vm.trainingLibrary, "duration", _vm._n($$v))},expression:"trainingLibrary.duration"}})],1)],1),_c('small',{staticClass:"text-danger mt-1"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('validation-provider',{attrs:{"name":"exercises","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.learningOutcomes)?_c('b-form-group',{attrs:{"label":_vm.$t('Exercises')}},[_c('v-select',{ref:"librarySelectInput",attrs:{"multiple":"","push-tags":"","state":errors.length > 0 ? false : null,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":Object.values(_vm.learningOutcomes),"reduce":function (option) { return option; },"getOptionLabel":function (option) { return option.name; }},scopedSlots:_vm._u([{key:"selected-option",fn:function(ref){
var name = ref.name;
return [(name)?_c('div',[_c('cite',[_vm._v(" "+_vm._s(name))])]):_vm._e()]}},{key:"no-options",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
return [_c('span',[_vm._v(" "+_vm._s(_vm.$t("No Result")))])]}}],null,true),model:{value:(_vm.trainingLibrary.learningOutcomes),callback:function ($$v) {_vm.$set(_vm.trainingLibrary, "learningOutcomes", $$v)},expression:"trainingLibrary.learningOutcomes"}})],1):_vm._e(),_c('small',{staticClass:"text-danger mt-1"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('validation-provider',{attrs:{"name":"description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Description'),"label-for":"description"}},[_c('div',{staticClass:"d-flex"},[_c('b-form-textarea',{attrs:{"id":"description","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('Enter Description'),"rows":"4"},model:{value:(_vm.trainingLibrary.description),callback:function ($$v) {_vm.$set(_vm.trainingLibrary, "description", $$v)},expression:"trainingLibrary.description"}})],1)]),_c('small',{staticClass:"text-danger mt-1"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"lg":"9"}},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-card',[_c('b-form-group',{attrs:{"label":_vm.$t('Content')}},_vm._l((_vm.trainingLibrary.trainingItemTemplateMedia),function(item){return _c('ck-editor-component',{key:item.id,attrs:{"state":item.path == null ? null : false,"editorData":item.path},on:{"loading":function($event){return _vm.setLoading($event)},"changedCKEditor":_vm.onCKEdtiorChanged}})}),1)],1)],1),_c('b-card',{staticClass:"m-1 w-100",attrs:{"title":_vm.$t('Videos')}},[_vm._v(" "+_vm._s(_vm.$t("Please save item then upload the video"))+" ")])],1)],1)],1),_c('b-row',[_c('b-col',[_c('div',{staticClass:"d-flex align-items-center justify-content-end"},[(!_vm.loading)?_c('b-button',{staticClass:"w-25",attrs:{"variant":"primary"},on:{"click":_vm.addNewTemplate}},[_vm._v(_vm._s(_vm.$t("Add")))]):_c('b-button',{staticClass:"w-25",attrs:{"variant":"primary"}},[_vm._v(_vm._s(_vm.$t("Adding ...")))])],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }