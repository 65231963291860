<template>
  <b-overlay
    variant="white"
    :show="loadingPlayerCompetition"
    spinner-variant="primary"
    blur="0"
    opacity=".75"
    rounded="sm"
  >
    <b-row v-if="player">
      <b-col xl="12" class="player-top-bar-info">
        <b-card class="pb-0">
          <b-row class="topbar">
            <b-col lg="4">
              <div class="d-flex">
                <div class="p-0">
                  <b-avatar
                    size="120"
                    :src="player != undefined ? player.avatar : null"
                    :text="avatarText(player.name)"
                    :variant="`light-primary`"
                    rounded
                  />
                </div>
                <div>
                  <b-link class="mt-0 ml-1 d-block h4">{{ player.name }}</b-link>
                  <span class="mt-0 ml-1 d-block"
                    >{{ player.birthdate | nativeDate }} ({{ getAge(player.birthdate) }})</span
                  >
                  <div class="p-1 d-flex">
                    <span class="badge badge-primary mr-1 col">{{
                      player.position ? $t(player.position.name) : " - "
                    }}</span>
                    <span class="badge badge-dark col"
                      >{{
                        player.foot === 1 ? $t("Right Foot") : player.foot === 0 ? $t("Left Foot") : $t("Both Feet")
                      }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="mt-1">
                <span class="badge badge-info px-1"
                  >{{ player.team ? player.team.club.name : "" }} / {{ player.team ? player.team.name : "" }}</span
                >
              </div>
            </b-col>

            <b-col lg="4">
              <b-card class="p-0"><physics-chart :user="player" :tests="tests" /></b-card>
            </b-col>

            <b-col lg="3">
              <b-input-group class="pt-3 pr-4">
                <b-form-rating
                  readonly
                  variant="warning"
                  :stars="getSetting('star')"
                  v-model="reviewAvg"
                ></b-form-rating>
              </b-input-group>
              <div
                class="h5 p-1"
                v-b-popover.hover.top="
                  $t('scout_player_review_detail', {
                    rev: reviewCount,
                    pts: reviewAvg ? parseFloat(parseFloat(reviewAvg).toFixed(2)).toFixed(1) : '',
                  })
                "
              >
                ({{ reviewCount }}) {{ $t("Rate") }} *
              </div>
            </b-col>

            <b-col lg="1" class="text-right">
              <div>
                <div v-if="loading">
                  <b-button class="my-1" variant="info">
                    <b-spinner small label="Small Spinner" type="grow" />
                  </b-button>
                </div>
                <div v-else>
                  <b-button
                    v-if="!watching"
                    class="my-1"
                    v-b-tooltip.hover
                    :title="$t('Add watch list')"
                    @click="watchPlayer(1, playerId)"
                    variant="success"
                  >
                    <feather-icon icon="UserPlusIcon" size="16" />
                  </b-button>
                  <b-button
                    v-else
                    class="my-1"
                    v-b-tooltip.hover
                    :title="$t('Remove from watch list')"
                    @click="watchPlayer(0, playerId)"
                    variant="danger"
                  >
                    <feather-icon icon="UserXIcon" size="16" />
                  </b-button>
                </div>
              </div>
              <b-button
                :title="$t('Edit')"
                variant="primary"
                :to="{
                  name: 'scout-player-edit',
                  params: { seasonId: seasonId, playerId: playerId },
                }"
              >
                <i class="code-toggler feather icon-edit cursor-pointer"></i>
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <b-row v-if="player">
      <b-col lg="12">
        <b-card style="min-height: 206px">
          <h5>{{ $t("Reviews") }}</h5>
          <div v-if="player.reviews && player.reviews.length > 0">
            <b-table
              ref="selectableTable"
              selectable
              sticky-header="400px"
              :items="player.reviews"
              :fields="fields"
              class="mb-0"
            >
              <template #cell(id)="data">
                {{ data.item.id }}
              </template>
              <template #cell(created_at)="data">
                {{ data.item.created_at | nativeDate }}
              </template>
              <template #cell(comment)="data">
                {{ data.item.comment }}
              </template>
            </b-table>
          </div>
          <div v-else class="alert alert-warning p-2 m-2">
            {{ $t("No comment") }}
          </div>
        </b-card>
      </b-col>

      <b-col lg="12">
        <b-card>
          <h5>{{ $t("Matches") }}</h5>
          <div v-if="competitions != null">
            <player-matches :competitions="competitions" class="pt-75" />
          </div>
          <div v-else>
            {{ $t("Data not found") }}
          </div>
        </b-card>
      </b-col>
    </b-row>
  </b-overlay>
</template>

<script>
  import { avatarText } from "@core/utils/filter"
  import { ref, onUnmounted, onMounted, computed } from "@vue/composition-api"
  import { useRouter } from "@core/utils/utils"
  import i18n from "@/libs/i18n"
  import http from "@/libs/axios"
  import Summary from "./Summary.vue"
  import PhysicsChart from "./components/PhysicsChart.vue"
  import PlayerMatches from "./components/PlayerMatches.vue"
  import axios from "@axios"

  export default {
    components: {
      Summary,
      PhysicsChart,
      PlayerMatches,
    },
    async beforeMount() {},
    setup(props, { root }) {
      const { router, route } = useRouter()
      const loading = ref(false)
      const playerId = ref(Number(router.currentRoute.params.playerId))
      const seasonId = ref(Number(router.currentRoute.params.seasonId))
      const player = ref(null)
      const tests = ref(null)
      const competitions = ref(null)
      const loadingPlayerCompetition = ref(false)
      const watching = ref(false)
      const reviewAvg = ref(0)
      const reviewCount = ref(0)

      onMounted(async () => {
        await watchPlayer(1, null)
        await getPlayer()
        await fetchPlayerCompetitions()
      })

      const contactFields = computed({
        get: () => [
          {
            key: "address",
            label: i18n.t("Address"),
            sortable: false,
            thStyle: { width: "200px", "min-width": "200px", "max-width": "220px" },
          },
          {
            key: "phone",
            label: i18n.t("Phone"),
            sortable: false,
            thStyle: { width: "200px", "min-width": "200px", "max-width": "220px" },
          },
        ],
      })
      const fields = computed({
        get: () => [
          {
            key: "id",
            label: i18n.t("Id"),
            sortable: false,
            thStyle: { width: "50px", "min-width": "50px", "max-width": "50px" },
          },
          {
            key: "created_at",
            label: i18n.t("Date"),
            sortable: false,
            thStyle: { width: "200px", "min-width": "200px", "max-width": "220px" },
          },
          {
            key: "comment",
            label: i18n.t("Comment"),
            sortable: false,
            thStyle: { width: "200px", "min-width": "200px", "max-width": "220px" },
          },
        ],
      })

      const getPlayer = () => {
        http
          .get(`/scout/player/${playerId.value}`)
          .then((res) => {
            player.value = res.data

            http.get(`scout/player/${playerId.value}/review-average`).then((resp) => {
              reviewAvg.value = resp.data.reviewAvg
              reviewCount.value = resp.data.reviewCount
            })
          })
          .catch((error) => console.log(error))
      }

      const fetchPlayerCompetitions = () => {
        axios
          .get(`/scout/competition/player/${playerId.value}`)
          .then((response) => {
            competitions.value = response.data
            //loadingPlayerCompetition = false;
            // console.log("asdasd");
          })
          .catch((error) => {
            console.log(error)
            //loadingPlayerCompetition = false;
          })
      }

      const getAge = (birthDate) => {
        var birthDate = new Date(birthDate)
        var now = new Date()
        let otherDate = new Date(now.getFullYear(), now.getMonth(), now.getDate())

        var years = otherDate.getFullYear() - birthDate.getFullYear()

        if (
          otherDate.getMonth() < birthDate.getMonth() ||
          (otherDate.getMonth() == birthDate.getMonth() && otherDate.getDate() < birthDate.getDate())
        ) {
          years--
        }

        return years
      }

      const watchPlayer = (type, playerId) => {
        loading.value = true
        http
          .post(`/scout/player/watch`, {
            type: type,
            player: playerId,
          })
          .then((res) => {
            setPlayerWatching(res.data)
            if (playerId) root.toast(i18n.t("Successfully Updated"), "PlusIcon", "success")
          })
          .catch((error) => console.log(error))
      }

      const setPlayerWatching = (watches) => {
        if (watches.length < 1) watching.value = false
        let watchesFind = Object.values(watches).find((item) => item.player.id == playerId.value)
        if (watchesFind) watching.value = true
        if (!watchesFind) watching.value = false

        loading.value = false
      }

      return {
        avatarText,
        loading,
        getAge,

        fields,
        contactFields,
        player,
        tests,
        playerId,
        seasonId,
        competitions,
        loadingPlayerCompetition,
        watchPlayer,
        watching,
        reviewAvg,
        reviewCount,
      }
    },
  }
</script>

<style>
  .tabs > [class="col-md-12 col-12 tab-title-for-player"] {
    padding-left: 0;
  }

  .player-top-bar-info,
  .player-top-bar-phy {
    .card-body {
      padding-bottom: 0.3rem;
    }
  }
</style>

<style scoped lang="scss">
  @media (min-width: 700px) {
    .topbar > .col-lg-4,
    .col-lg-3 {
      border-left: 1px solid #ddd;

      &:first-child {
        border-left: 0;
      }
    }

    .p-0 > div {
      padding: 0;
    }
  }
</style>

<style lang="scss">
  @import "@core/scss/vue/libs/vue-good-table.scss";
</style>
