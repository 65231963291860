<template>
  <div class="scrollable-area match-print print-match-page" style="min-width: 1024px">
    <div>
      <div>
        <div class="mt-2 card card-body py-2 match-info">
          <addMatchInfoBar
            :competitionForm="competitionForm"
            :competition.sync="competition"
            :showSection.sync="showSection"
          ></addMatchInfoBar>
        </div>
      </div>
      <div class="col-md-12 mt-2 card card-body print-fomation">
        <div class="row">
          <div class="col-md-9">
            <div class="formation-print-plugin-all" v-if="teamLine">
              <formation-plugin
                :key="triggerPlugin"
                :competitionFormations.sync="competition.competitionFormations"
                :teamProps="competition[teamLine]"
                :helper="{
                  getTeamPlayers,
                }"
              ></formation-plugin>
            </div>
          </div>
          <div class="col-md-3" v-if="teamLine">
            <div>
              <table class="table table-bordered" style="margin-bottom: 30px">
                <thead>
                  <tr>
                    <td colspan="2" class="text-center">{{ $t("Out of Squad") }}</td>
                  </tr>
                </thead>
                <tbody v-if="competition[teamLine].team.teamPlayers">
                  <tr
                    v-for="(item, index) in competition[teamLine].team.teamPlayers.filter(
                      (i) => i.isStarterPlayer == 2,
                    )"
                    :key="index"
                  >
                    <td>{{ index + 1 }}</td>
                    <td>{{ item.name }}</td>
                  </tr>
                </tbody>
              </table>

              <table class="table table-bordered">
                <thead>
                  <tr>
                    <td colspan="2" class="text-center">{{ $t("Injury") }}</td>
                  </tr>
                </thead>
                <tbody v-if="teamLine && competition[teamLine].team.teamPlayers">
                  <tr
                    v-for="(item, index) in competition[teamLine].team.teamPlayers.filter(
                      (i) => i.isStarterPlayer == 3,
                    )"
                    :key="index"
                  >
                    <td>{{ index + 1 }}</td>
                    <td>{{ item.name }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12 card card-body ilk-11">
        <div class="card-header p-0 m-0 mb-2">
          <div>
            <b-card-title>
              <feather-icon icon="CopyIcon" size="24" />
              İLK 11
            </b-card-title>
          </div>
        </div>
        <add-coach-review-table v-if="teamLine" :competition.sync="competition" :teamItem="teamLine" />
      </div>
      <div class="col-md-12 card card-body change-player">
        <div class="table-responsive">
          <div>
            <div class="mt-0 py-2">
              <span class="h4">{{ $t("Oyuncu Değişiklikleri") }}</span>
            </div>
            <table class="table table-bordered mt-n1">
              <thead>
                <tr>
                  <td class="print-5">Giren</td>
                  <td class="print-2" v-b-tooltip.hover :title="$t('Game Login Minute')">
                    {{ $t("Minute") }}
                  </td>
                  <td class="print-5" v-b-tooltip.hover :title="$t('Leaving Player')">Çıkan</td>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(pl, index) in getSortEvents" :key="index">
                  <td>
                    <div class="d-flex justify-content-start">
                      <div class="ml-1">
                        {{ pl.mainPlayer.name }}
                      </div>
                    </div>
                  </td>
                  <td class>
                    <span>{{ pl.minute }}.dk</span>
                  </td>
                  <td class>
                    <div class="d-flex">
                      {{ pl.subPlayer.name }}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="col-md-12 card card-body">
        <div class="card-header p-0 m-0 mb-2">
          <div>
            <b-card-title>
              <feather-icon icon="CopyIcon" size="24" />
              MAÇ DEĞERLENDİRME RAPORU
            </b-card-title>
          </div>
        </div>
        <table class="table table-bordered">
          <thead>
            <tr>
              <th class="bg-light text-center" colspan="1">TD YORUMU</th>
              <th class="bg-light text-center" colspan="1">GENEL MAÇ DURUMU</th>
              <th class="bg-light text-center" colspan="1">KALECİ ANTRENÖR YORUMU</th>
            </tr>
          </thead>
          <tbody class="t-1">
            <tr height="500px">
              <td></td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
  import vSelect from "vue-select"
  import addMatchInfoBar from "./addMatchInfoBar"
  import addMatchFormationStat from "./addMatchFormationStat"
  import addCoachReview from "./addCoachReview"
  import flatPickr from "vue-flatpickr-component"
  import useAddMatch from "@/views/competition/add-match/useAddMatch"
  import { ref, watch } from "@vue/composition-api"

  import addCoachReviewTable from "./addCoachReviewTable"
  import FormationPrintPlugin from "./FormationPrintPlugin.vue"
  import router from "@/router"

  export default {
    components: {
      vSelect,
      flatPickr,
      addMatchInfoBar,
      FormationPlugin: FormationPrintPlugin,
      addMatchFormationStat,
      addCoachReviewTable,
      addCoachReview,
    },
    methods: {
      getSubs(Players) {
        return Object.values(Players).filter((pl) => {
          if (pl.isStarterPlayer !== undefined && pl.isStarterPlayer == 0) {
            return pl
          }
        })
      },
    },
    computed: {
      getSortEvents() {
        this.CE = this.competition.competitionEvents
          .sort((a, b) => {
            return a.minute - b.minute
          })
          .filter((i) => i.competitionEventType == "CHANGE_PLAYER")
        return this.CE
      },
    },
    data() {
      return {}
    },
    destroyed() {},
    setup(props, { root }) {
      const { showSection, competitionForm, competition, getTeamPlayers, helper } = useAddMatch()

      const routerTeamId = Number(router.currentRoute.params.id)
      const teamLine = ref(null)
      const triggerPlugin = ref(0)

      watch(competition.value, (newv, oldv) => {
        triggerPlugin.value++

        if (competition.value) {
          if (competition.value.homeTeam.team.id == routerTeamId) {
            teamLine.value = "homeTeam"
          } else {
            teamLine.value = "awayTeam"
          }
        }
      })

      return {
        showSection,
        competitionForm,
        competition,
        getTeamPlayers,
        helper,
        teamLine,
        triggerPlugin,
      }
    },
  }
</script>

<style lang="scss" scoped>
  .h-40 {
    height: 40px;
  }
  .inp {
    box-shadow: unset !important;
  }
  thead th {
    width: 33%; //or what you want
  }

  .match-print table td {
    padding: 0.28rem !important;
  }
</style>

<style lang="scss">
  @media print {
    .header-navbar,
    .main-menu,
    .header-navbar-shadow,
    .tooltip.b-tooltip,
    .btn-scroll-to-top {
      display: none !important;
    }
    .app-content.content {
      margin-left: 0px !important;
      padding-top: 0px !important;
    }
    .print-match-page {
      .teamInfo .b-avatar {
        display: block !important;
      }
      .b-avatar {
        display: none !important;
      }
      div.r {
        margin: 10px;
      }
      .formation-print-plugin-all {
        width: 418px !important;
        position: absolute !important;
        margin-left: 120px !important;
        margin-top: -100px !important;
      }
      .print-match-page  {
        box-shadow: unset !important;
      }
      .card-header {
        margin-bottom: 0.5rem !important;
      }
      .card {
        margin-bottom: 0px !important;
      }
      .card-body {
        padding: 0px !important;
      }
      .match-info {
        margin-top: 50px !important;
      }
      .print-fomation {
        min-height: 470px !important;
      }
    }
  }

  .print-match-page {
    @media screen {
      .formation-print-plugin-all {
        margin-top: -160px !important;
      }
      .match-info .btn {
        display: block !important;
      }
    }
    .bg-light {
      background-color: transparent !important;
    }
    .change-player {
      page-break-after: always;
    }
    .match-info .card-title {
      display: none;
    }
    .btn {
      display: none !important;
    }
    .formation-print-plugin-all span.badge,
    .formation-print-plugin-all svg {
      display: none;
    }
    .row > div {
      min-height: 7.7vw !important;
    }
    .text-center > .lineup {
      display: none !important;
    }
    table td {
      padding: 0.28rem !important;
    }
    .imgbg .frm {
      transform: rotate(-90deg) !important;
    }
    .imgbg > div.col {
      padding-left: 0rem !important;
      padding-right: 0rem !important;
    }
    div.r {
      width: 8vw !important;
      height: auto !important;
      background-color: #0000009c !important;
      border-radius: 0px !important;
      padding: 3px !important;
      min-height: 3rem !important;
      color: white;
      display: table !important;
    }
    .formation-print-plugin-all {
      border: 1px solid transparent;
      width: 530px;
      margin: 0 auto;
      position: relative;
      min-height: 550px;
      transform: rotate(90deg);
    }
    .imgbg .small-text {
      display: table-cell;
      vertical-align: middle;
      font-size: small;
    }
    .print-5 {
      width: 41.66666667%;
    }
    .print-2 {
      width: 16.66666667%;
    }
    .print-3 {
      width: 25%;
    }
    -webkit-print-color-adjust: exact !important;
    font-size: x-small;
  }
</style>
