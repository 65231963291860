export default [
  {
    path: "/season/:seasonId/team/list",
    name: "team-list",
    component: () => import("@/views/team/team-sub-pages/list/TeamListTable.vue"),
  },
  {
    path: "/season/:seasonId/team/:id/overview",
    name: "team-overview",
    component: () => import("@/views/team/team-sub-pages/overview/ShowTeamOverview.vue"),
    meta: {
      resource: "Auth",
      action: "read",
    },
  },

  {
    path: "/season/:seasonId/team/:id/training",
    name: "team-trainings",
    component: () => import("@/views/team/team-sub-pages/training/ShowTeamTrainings.vue"),
    meta: {
      resource: "Training",
      action: "manage",
    },
  },
  /*{
  path: '/season/:seasonId/team/:id/training/preview/:trainingId',
  name: 'team-training-preview',
  component: () => import('@/views/team/team-sub-pages/training/detail/preview/Preview.vue'),
  meta: {
    resource: 'Training',
    action: 'manage',
  },
},*/
  {
    path: "/season/:seasonId/team/:id/training/serp-score/:trainingId",
    name: "team-serp-score",
    component: () => import("@/views/team/team-sub-pages/training/test/Test.vue"),
    meta: {
      resource: "Training",
      action: "manage",
    },
  },

  {
    path: "/season/:seasonId/team/add",
    name: "team-add",
    component: () => import("@/views/team/actions/addTeam.vue"),
  },
  {
    path: "/season/:seasonId/team/:id",
    name: "show-team",
    component: () => import("@/views/team/ShowTeam.vue"),
  },
  {
    path: "/season/:seasonId/team/:id/training/preview/:trainingId",
    name: "team-training-preview",
    component: () => import("@/views/team/team-sub-pages/training/detail/preview/Preview.vue"),
    meta: {
      resource: "Training",
      action: "manage",
    },
  },
  {
    path: "/season/:seasonId/team/:id/training/print/:trainingId",
    name: "team-training-print",
    component: () => import("@/views/team/team-sub-pages/training/detail/print/Print.vue"),
    meta: {
      resource: "Training",
      action: "manage",
    },
  },
  {
    path: "/season/:seasonId/team/:id/training/test/:trainingId",
    name: "team-training-test",
    component: () => import("@/views/team/team-sub-pages/training/test/Test.vue"),
    meta: {
      resource: "Training",
      action: "manage",
    },
  },

  {
    path: "/season/:seasonId/team/:id/training-add",
    name: "team-training-add",
    component: () => import("@/views/team/team-sub-pages/training/add-training/AddAntrenmans.vue"),
    meta: {
      resource: "Training",
      action: "manage",
    },
  },
  {
    path: "/season/:seasonId/team/:id/training-edit/:trainingId",
    name: "team-training-edit",
    component: () => import("@/views/team/team-sub-pages/training/edit-training/EditTraining.vue"),
    meta: {
      resource: "Training",
      action: "manage",
    },
  },
  {
    path: "/season/:seasonId/team/:id/training-attenders/:trainingId",
    name: "team-training-attenders-old",
    component: () => import("@/views/team/team-sub-pages/training/TeamTrainingAttenders.vue"),
    meta: {
      resource: "Training",
      action: "manage",
    },
  },
  {
    path: "/season/:seasonId/team/:id/training-attenders/:trainingId/new",
    name: "team-training-attenders",
    component: () => import("@/views/team/team-sub-pages/training/TeamTrainingAttendersNew.vue"),
    meta: {
      resource: "Training",
      action: "manage",
    },
  },
  {
    path: "/season/:seasonId/team/:id/training-library/add",
    name: "team-training-library-add",
    component: () => import("@/views/team/team-sub-pages/training/library/AddTrainingLibrary.vue"),
    meta: {
      resource: "Training",
      action: "manage",
    },
  },
  {
    path: "/season/:seasonId/team/:id/training-library/edit/:templateID",
    name: "team-training-library-edit",
    component: () => import("@/views/team/team-sub-pages/training/library/EditTrainingLibrary.vue"),
    meta: {
      resource: "Training",
      action: "manage",
    },
  },
  {
    path: "/season/:seasonId/team/:id/training-library",
    name: "team-training-library",
    component: () => import("@/views/team/team-sub-pages/training/library/TrainingLibrary.vue"),
    meta: {
      resource: "ACL",
      action: "read",
    },
  },
  {
    path: "/season/:seasonId/team/:id/competitions",
    name: "team-competitions",
    component: () => import("@/views/team/team-sub-pages/competition/ShowTeamMatches.vue"),
    meta: {
      resource: "Competition",
      action: "read",
    },
  },
  {
    path: "/season/:seasonId/team/:id/competitions-chart",
    name: "team-competitions-chart",
    component: () => import("@/views/team/team-sub-pages/competition/ShowTeamMatchesChart.vue"),
    meta: {
      resource: "Competition",
      action: "read",
    },
  },
  {
    path: "/calendar",
    name: "team-calendar",
    component: () => import("@/views/dashboard/antrenor/DashboardCalendar.vue"),
    meta: {
      resource: "ACL",
      action: "read",
    },
  },
  {
    path: "/season/:seasonId/team/:id/performance",
    name: "team-perfs-log",
    component: () => import("@/views/team/team-sub-pages/team-performance/ShowTeamPerformance.vue"),
    meta: {
      resource: "Performance",
      action: "read",
    },
  },
  {
    path: "/season/:seasonId/team/:id/performance/evulates",
    name: "team-perfs-evulate",
    component: () => import("@/views/team/team-sub-pages/team-performance/ShowTeamPerformanceEvulates.vue"),
    meta: {
      resource: "Performance",
      action: "read",
    },
  },
  {
    path: "/season/:seasonId/team/:id/performance/rating/:performanceId",
    name: "team-perfs-rating",
    component: () => import("@/views/team/team-sub-pages/team-performance/PerformanceRating.vue"),
    meta: {
      resource: "Performance",
      action: "read",
    },
  },
  {
    path: "/season/:seasonId/team/:id/performance/rating/:performanceId/player/:playerId",
    name: "team-player-performance-rating",
    component: () => import("@/views/team/team-sub-pages/team-performance/PerformancePlayerRating.vue"),
    meta: {
      resource: "Performance",
      action: "read",
    },
  },
  {
    path: "/season/:seasonId/team/:id/health",
    name: "team-health",
    component: () => import("@/views/team/team-sub-pages/team-health/ShowTeamHealth.vue"),
    meta: {
      resource: "Health",
      action: "read",
    },
  },
  {
    path: "/season/:seasonId/team/:id/reports",
    name: "team-reports",
    component: () => import("@/views/team/team-sub-pages/team-reports/ShowTeamReports.vue"),
    meta: {
      resource: "Health",
      action: "read",
    },
  },
  // *===============================================---*
  // *--------- PLAYER ---- ---------------------------------------*
  // *===============================================---*
  {
    path: "/season/:seasonId/team/:id/players",
    name: "team-players",
    component: () => import("@/views/team/team-sub-pages/players/ShowTeamPlayers.vue"),
    meta: {
      resource: "Player",
      action: "read",
    },
  },
  {
    path: "/season/:seasonId/team/:id/players/add",
    name: "team-player-add",
    component: () => import("@/views/player/PlayerAdd.vue"),
    meta: {
      resource: "Player",
      action: "manage",
    },
  },
  {
    path: "/season/:seasonId/team/:id/players/edit/:playerId",
    name: "team-player-edit",
    component: () => import("@/views/player/player-edit/PlayerEdit.vue"),
    meta: {
      resource: "Player",
      action: "manage",
    },
  },
  {
    path: "/season/:seasonId/team/:id/players/:playerId",
    name: "team-player",
    component: () => import("@/views/player/player-view/PlayerView.vue"),
    meta: {
      resource: "Player",
      action: "manage",
    },
  },
  {
    path: "/season/:seasonId/team/:id/players/print/:playerId",
    name: "team-player-print",
    component: () => import("@/views/player/player-view/PlayerPrint.vue"),
    meta: {
      resource: "Player",
      action: "manage",
    },
  },
  {
    path: "/season/:seasonId/team/:id/players/share/:playerId/parent",
    name: "team-player-share-parent",
    component: () => import("@/views/player/player-view/PlayerShare.vue"),
    meta: {
      resource: "Player",
      action: "manage",
    },
  },
  {
    path: "/season/:seasonId/team/:id/players/share/:playerId/scout",
    name: "team-player-share-scout",
    component: () => import("@/views/player/player-view/PlayerShare.vue"),
    meta: {
      resource: "Player",
      action: "manage",
    },
  },
  // *===============================================---*
  // *--------- ORG CHART ---- ---------------------------------------*
  // *===============================================---*
  {
    path: "/org-chart",
    name: "org-chart-page",
    component: () => import("@/views/organization/OrganizationChart.vue"),
    meta: {
      resource: "Staff",
      action: "read",
    },
  },
  // *===============================================---*
  // *--------- STAFF ---- ---------------------------------------*
  // *===============================================---*
  {
    path: "/staff_manage",
    name: "staff-list",
    component: () => import("@/views/staff/staffs-list/StaffsList.vue"),
    meta: {
      resource: "Staff",
      action: "read",
    },
  },
  {
    path: "/staff_manage/add",
    name: "add-staff",
    component: () => import("@/views/staff/add-new-staff/AddNewStaff.vue"),
    meta: {
      resource: "Staff",
      action: "manage",
    },
  },
  {
    path: "/staff_manage/edit/:staffID",
    name: "edit-staff",
    component: () => import("@/views/staff/edit-staff/EditStaff.vue"),
    meta: {
      resource: "Staff",
      action: "manage",
    },
  },
  {
    path: "/staff_manage/view/:staffID",
    name: "show-staff",
    component: () => import("@/views/staff/staff-view/StaffView.vue"),
    meta: {
      resource: "Staff",
      action: "read",
    },
  },

  {
    path: "/season/:seasonId/team/:id/tests",
    name: "team-tests",
    component: () => import("@/views/team/team-sub-pages/team-test/ShowTeamTests.vue"),
    meta: {
      resource: "Test",
      action: "manage",
    },
  },
  {
    path: "/season/:seasonId/team/:id/test/latest",
    name: "team-latest-test",
    component: () => import("@/views/team/team-sub-pages/team-test/ShowTeamLastTest.vue"),
    meta: {
      resource: "Test",
      action: "read",
    },
  },
  {
    path: "/season/:seasonId/team/:id/test/enter",
    name: "team-tests-enter",
    component: () => import("@/views/team/team-sub-pages/team-test/TestEnter.vue"),
    meta: {
      resource: "Test",
      action: "manage",
    },
  },
  {
    path: "/season/:seasonId/team/:id/test/enter/:date",
    name: "team-tests-enter-date",
    component: () => import("@/views/team/team-sub-pages/team-test/TestEnter.vue"),
    meta: {
      resource: "Test",
      action: "manage",
    },
  },
  {
    path: "/season/:seasonId/team/:id/test/enter/:date/player/:playerId",
    name: "team-player-tests-enter-date",
    component: () => import("@/views/team/team-sub-pages/team-test/PlayerTestEnter.vue"),
    meta: {
      resource: "Test",
      action: "manage",
    },
  },

  // DELETED
  // // *===============================================---*
  // // *--------- NOTIFY ---- ---------------------------------------*
  // // *===============================================---*
  // {
  //   path: '/team/:id/notify',
  //   name: 'notify',
  //   component: () => import('@/views/team/team-sub-pages/team-todo/Todo.vue'),
  //   meta: {
  //     contentRenderer: 'sidebar-left',
  //     contentClass: 'todo-application',
  //   },
  // },
  // *===============================================---*
  // *--------- TEAM MATCH  ----------------------------*
  // *===============================================---*
  {
    path: "/season/:seasonId/team/:id/competition/add",
    name: "competition-add",
    component: () => import("@/views/competition/add-match/addMatch.vue"),
    meta: {
      resource: "Competition",
      action: "write",
    },
  },
  {
    path: "/season/:seasonId/team/:id/competition/edit/:competitionId",
    name: "competition-edit",
    component: () => import("@/views/competition/add-match/addMatch.vue"),
    meta: {
      resource: "Competition",
      action: "write",
    },
  },
  {
    path: "/season/:seasonId/team/:id/competition/analyse/:competitionId",
    name: "competition-analyse",
    component: () => import("@/views/competition/edit-match/matchAnalyse.vue"),
    meta: {
      resource: "Competition",
      action: "write",
    },
  },
  {
    path: "/season/:seasonId/team/:id/competition/:competitionId",
    name: "team-competition-show",
    component: () => import("@/views/competition/show-match/addMatch.vue"),
    meta: {
      resource: "Competition",
      action: "read",
    },
  },
  {
    path: "/season/:seasonId/team/:id/competition/print/:competitionId",
    name: "team-competition-print",
    component: () => import("@/views/competition/show-match/Print.vue"),
    meta: {
      resource: "Training",
      action: "manage",
    },
  },

  // *===============================================---*
  // *--------- STAFF ---- ---------------------------------------*
  // *===============================================---*
  {
    path: "/user_manage",
    name: "user-manage",
    component: () => import("@/views/user/users-list/UsersList.vue"),
    meta: {
      resource: "User",
      action: "read",
    },
  },
  {
    path: "/user_manage/add",
    name: "user-manage-add",
    component: () => import("@/views/user/userAdd.vue"),
    meta: {
      resource: "User",
      action: "manage",
    },
  },
  {
    path: "/user_manage/edit/:id",
    name: "user-manage-edit",
    component: () => import("@/views/user/userEdit.vue"),
    meta: {
      resource: "User",
      action: "manage",
    },
  },
  {
    path: "/blank-page",
    name: "blank-page",
    component: () => import("@/views/blank/blankPage.vue"),
    meta: {
      resource: "Auth",
      action: "read",
    },
  },
  {
    path: "/compare-player",
    name: "compare-player",
    component: () => import("@/views/compare/player/index.vue"),
    meta: {
      resource: "ACL",
      action: "manage",
    },
  },
  {
    path: "/compare-team",
    name: "compare-team",
    component: () => import("@/views/compare/team/index.vue"),
    meta: {
      resource: "ACL",
      action: "manage",
    },
  },
  {
    path: "/custom-calendar-table",
    name: "custom-calendar-table",
    component: () => import("@/views/pages/custom-calendar/example.vue"),
    meta: {
      resource: "Auth",
      action: "read",
    },
  },
  {
    path: "/apps/chat",
    name: "apps-chat",
    component: () => import("@/views/apps/chat/Chat.vue"),
    meta: {
      contentRenderer: "sidebar-left",
      contentClass: "chat-application",
    },
  },
]
