<template>
  <div>
    <!-- Media -->
    <b-media class="mb-2">
      <template #aside>
        <b-avatar
          ref="previewEl"
          :src="playerData.avatar"
          :text="avatarText(playerData.name)"
          :variant="`light-warning`"
          size="90px"
          rounded
        />
      </template>
      <h4 class="mb-1">{{ playerData.name }}</h4>
      <div class="d-flex flex-wrap">
        <b-button variant="primary" @click="$refs.refInputEl.click()">
          <input
            ref="refInputEl"
            @change="onFileSelected"
            type="file"
            accept=".jpg, .png, .gif"
            class="d-none"
            @input="inputImageRenderer"
          />
          <span class="d-none d-sm-inline">{{ $t("Select") }}</span>
          <feather-icon icon="EditIcon" class="d-inline d-sm-none" />
        </b-button>
        <b-button class="ml-1" @click="uploadAvatar" variant="primary" v-b-tooltip.hover="$t('Upload')">
          <feather-icon size="12" icon="UploadIcon"></feather-icon>
        </b-button>
        <b-button
          class="ml-1"
          variant="info"
          @click="$router.push({ name: 'team-player' })"
          v-b-tooltip.hover="$t('Show profile')"
        >
          <feather-icon size="12" icon="EyeIcon"></feather-icon>
        </b-button>
      </div>
    </b-media>
    <!-- Player Info: Input Fields -->
    <b-form @submit.prevent="submitHandle()">
      <b-row>
        <!-- Field: Playername -->
        <b-col cols="12" md="6" lg="3">
          <b-form-group :label="$t('Name')" label-for="full-name">
            <b-form-input id="full-name" v-model="playerData.name" />
          </b-form-group>
        </b-col>
        <!-- Field: Email -->
        <b-col cols="12" md="6" lg="3">
          <b-form-group :label="$t('Email')" label-for="email">
            <b-form-input id="email" v-model="playerData.email" type="email" />
          </b-form-group>
        </b-col>
        <!-- Field: Phone -->
        <b-col cols="12" md="3" lg="2">
          <b-form-group :label="$t('Mobile')" label-for="player-phone">
            <b-input
              type="tel"
              name="phone"
              id="pPhone"
              ref="pPhone"
              @input="slicePhoneNumber"
              v-model="playerData.phone"
            ></b-input>
          </b-form-group>
        </b-col>
        <!-- Field: Status -->
        <b-col cols="12" md="3" lg="2">
          <b-form-group label-for="player-status">
            <label>
              {{ $t("Foot") }}
            </label>
            <v-select
              v-model="playerData.foot"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="footOptions"
              :reduce="(val) => val.value"
              :clearable="false"
              input-id="player-status"
            />
          </b-form-group>
        </b-col>
        <!-- Field: Role -->
        <b-col cols="12" md="3" lg="2">
          <b-form-group :label="$t('Player position')" label-for="player-role">
            <v-select
              v-model="playerData.position"
              :getOptionLabel="(option) => option.name"
              :options="positionOptions"
              :reduce="(val) => val.id"
              :clearable="false"
              input-id="player-role"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <!-- Field: Birthdate -->
        <b-col cols="12" md="6" lg="6">
          <b-form-group :label="$t('Birth Date')" label-for="birthdate">
            <div>
              <selectable-date v-model="playerData.birthdate"></selectable-date>
            </div>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group :label="$t('Available Positions')">
            <v-select
              multiple
              v-model="playerData.availablePositions"
              :getOptionLabel="(option) => option.name"
              :options="positionOptions"
              :reduce="(val) => val.id"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <hr />
      <b-row>
        <!-- Field: Country -->
        <b-col cols="12" md="3" lg="2">
          <b-form-group :label="$t('Country')" label-for="country">
            <div class="d-flex justify-content-between">
              <b-form-select
                id="country"
                v-model="playerData.nation"
                text-field="name"
                value-field="code"
                :options="countries"
              />
            </div>
          </b-form-group>
        </b-col>
        <!-- Field: licenseNo -->
        <b-col cols="12" md="3" lg="2">
          <b-form-group :label="$t('License No')" label-for="licenseNo">
            <div class="d-flex justify-content-between">
              <b-input id="licenseNo" v-model="playerData.licenseNo"></b-input>
            </div>
          </b-form-group>
        </b-col>

        <!-- Field: recommend -->
        <b-col cols="12" md="3" lg="2">
          <b-form-group :label="$t('Recommended by')" label-for="recommend">
            <div class="d-flex justify-content-between">
              <b-input
                v-if="playerData.user && localUser.id == playerData.user.id"
                id="recommend"
                v-model="playerData.recommend"
              ></b-input>
              <b-input v-else id="recommend-ds" disabled :value="playerData.recommend"></b-input>
            </div>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="3" lg="2">
          <b-form-group :label="$t('Academy Entry Date')" label-for="entryDate">
            <div class="d-flex justify-content-between">
              <b-input
                id="entryDate"
                disabled
                :value="
                  (playerData.playerTransferHistories.length > 0
                    ? playerData.playerTransferHistories.at(-1).startDate
                    : null) | nativeDate
                "
              ></b-input>
            </div>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="3" lg="2">
          <b-form-group :label="$t('User Who Added The Player')" label-for="added">
            <div class="d-flex justify-content-between">
              <b-input id="added" disabled :value="userName"></b-input>
            </div>
          </b-form-group>
        </b-col>
      </b-row>
      <hr />
      <b-button class="mb-1 mb-sm-0 mr-0 mr-sm-1" variant="primary" type="submit">{{ $t("Save Changes") }}</b-button>
      <b-button variant="outline-secondary" type="reset" :block="$store.getters['app/currentBreakPoint'] === 'xs'">{{
        $t("Reset")
      }}</b-button>
      <hr />
    </b-form>
  </div>
</template>
<script>
  import { avatarText } from "@core/utils/filter"
  import vSelect from "vue-select"
  import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils"
  import { ref, onMounted, computed, watch } from "@vue/composition-api"
  import flatPickr from "vue-flatpickr-component"
  import Cleave from "cleave.js"
  // eslint-disable-next-line import/no-extraneous-dependencies
  // import "cleave.js/dist/addons/cleave-phone.tr";
  import i18n from "@/libs/i18n"
  import SelectableDate from "@/@core/components/global-components/SelectableDate"
  import http from "@/libs/axios"
  const countries = [
    { name: "Afghanistan", code: "AF" },
    { name: "Åland Islands", code: "AX" },
    { name: "Albania", code: "AL" },
    { name: "Algeria", code: "DZ" },
    { name: "American Samoa", code: "AS" },
    { name: "AndorrA", code: "AD" },
    { name: "Angola", code: "AO" },
    { name: "Anguilla", code: "AI" },
    { name: "Antarctica", code: "AQ" },
    { name: "Antigua and Barbuda", code: "AG" },
    { name: "Argentina", code: "AR" },
    { name: "Armenia", code: "AM" },
    { name: "Aruba", code: "AW" },
    { name: "Australia", code: "AU" },
    { name: "Austria", code: "AT" },
    { name: "Azerbaijan", code: "AZ" },
    { name: "Bahamas", code: "BS" },
    { name: "Bahrain", code: "BH" },
    { name: "Bangladesh", code: "BD" },
    { name: "Barbados", code: "BB" },
    { name: "Belarus", code: "BY" },
    { name: "Belgium", code: "BE" },
    { name: "Belize", code: "BZ" },
    { name: "Benin", code: "BJ" },
    { name: "Bermuda", code: "BM" },
    { name: "Bhutan", code: "BT" },
    { name: "Bolivia", code: "BO" },
    { name: "Bosnia and Herzegovina", code: "BA" },
    { name: "Botswana", code: "BW" },
    { name: "Bouvet Island", code: "BV" },
    { name: "Brazil", code: "BR" },
    { name: "British Indian Ocean Territory", code: "IO" },
    { name: "Brunei Darussalam", code: "BN" },
    { name: "Bulgaria", code: "BG" },
    { name: "Burkina Faso", code: "BF" },
    { name: "Burundi", code: "BI" },
    { name: "Cambodia", code: "KH" },
    { name: "Cameroon", code: "CM" },
    { name: "Canada", code: "CA" },
    { name: "Cape Verde", code: "CV" },
    { name: "Cayman Islands", code: "KY" },
    { name: "Central African Republic", code: "CF" },
    { name: "Chad", code: "TD" },
    { name: "Chile", code: "CL" },
    { name: "China", code: "CN" },
    { name: "Christmas Island", code: "CX" },
    { name: "Cocos (Keeling) Islands", code: "CC" },
    { name: "Colombia", code: "CO" },
    { name: "Comoros", code: "KM" },
    { name: "Congo", code: "CG" },
    { name: "Congo, The Democratic Republic of the", code: "CD" },
    { name: "Cook Islands", code: "CK" },
    { name: "Costa Rica", code: "CR" },
    { name: "Cote D'Ivoire", code: "CI" },
    { name: "Croatia", code: "HR" },
    { name: "Cuba", code: "CU" },
    { name: "Cyprus", code: "CY" },
    { name: "Czech Republic", code: "CZ" },
    { name: "Denmark", code: "DK" },
    { name: "Djibouti", code: "DJ" },
    { name: "Dominica", code: "DM" },
    { name: "Dominican Republic", code: "DO" },
    { name: "Ecuador", code: "EC" },
    { name: "Egypt", code: "EG" },
    { name: "El Salvador", code: "SV" },
    { name: "Equatorial Guinea", code: "GQ" },
    { name: "Eritrea", code: "ER" },
    { name: "Estonia", code: "EE" },
    { name: "Ethiopia", code: "ET" },
    { name: "Falkland Islands (Malvinas)", code: "FK" },
    { name: "Faroe Islands", code: "FO" },
    { name: "Fiji", code: "FJ" },
    { name: "Finland", code: "FI" },
    { name: "France", code: "FR" },
    { name: "French Guiana", code: "GF" },
    { name: "French Polynesia", code: "PF" },
    { name: "French Southern Territories", code: "TF" },
    { name: "Gabon", code: "GA" },
    { name: "Gambia", code: "GM" },
    { name: "Georgia", code: "GE" },
    { name: "Germany", code: "DE" },
    { name: "Ghana", code: "GH" },
    { name: "Gibraltar", code: "GI" },
    { name: "Greece", code: "GR" },
    { name: "Greenland", code: "GL" },
    { name: "Grenada", code: "GD" },
    { name: "Guadeloupe", code: "GP" },
    { name: "Guam", code: "GU" },
    { name: "Guatemala", code: "GT" },
    { name: "Guernsey", code: "GG" },
    { name: "Guinea", code: "GN" },
    { name: "Guinea-Bissau", code: "GW" },
    { name: "Guyana", code: "GY" },
    { name: "Haiti", code: "HT" },
    { name: "Heard Island and Mcdonald Islands", code: "HM" },
    { name: "Holy See (Vatican City State)", code: "VA" },
    { name: "Honduras", code: "HN" },
    { name: "Hong Kong", code: "HK" },
    { name: "Hungary", code: "HU" },
    { name: "Iceland", code: "IS" },
    { name: "India", code: "IN" },
    { name: "Indonesia", code: "ID" },
    { name: "Iran, Islamic Republic Of", code: "IR" },
    { name: "Iraq", code: "IQ" },
    { name: "Ireland", code: "IE" },
    { name: "Isle of Man", code: "IM" },
    { name: "Israel", code: "IL" },
    { name: "Italy", code: "IT" },
    { name: "Jamaica", code: "JM" },
    { name: "Japan", code: "JP" },
    { name: "Jersey", code: "JE" },
    { name: "Jordan", code: "JO" },
    { name: "Kazakhstan", code: "KZ" },
    { name: "Kenya", code: "KE" },
    { name: "Kiribati", code: "KI" },
    { name: "Korea, Democratic People'S Republic of", code: "KP" },
    { name: "Korea, Republic of", code: "KR" },
    { name: "Kuwait", code: "KW" },
    { name: "Kyrgyzstan", code: "KG" },
    { name: "Lao People'S Democratic Republic", code: "LA" },
    { name: "Latvia", code: "LV" },
    { name: "Lebanon", code: "LB" },
    { name: "Lesotho", code: "LS" },
    { name: "Liberia", code: "LR" },
    { name: "Libyan Arab Jamahiriya", code: "LY" },
    { name: "Liechtenstein", code: "LI" },
    { name: "Lithuania", code: "LT" },
    { name: "Luxembourg", code: "LU" },
    { name: "Macao", code: "MO" },
    { name: "Macedonia, The Former Yugoslav Republic of", code: "MK" },
    { name: "Madagascar", code: "MG" },
    { name: "Malawi", code: "MW" },
    { name: "Malaysia", code: "MY" },
    { name: "Maldives", code: "MV" },
    { name: "Mali", code: "ML" },
    { name: "Malta", code: "MT" },
    { name: "Marshall Islands", code: "MH" },
    { name: "Martinique", code: "MQ" },
    { name: "Mauritania", code: "MR" },
    { name: "Mauritius", code: "MU" },
    { name: "Mayotte", code: "YT" },
    { name: "Mexico", code: "MX" },
    { name: "Micronesia, Federated States of", code: "FM" },
    { name: "Moldova, Republic of", code: "MD" },
    { name: "Monaco", code: "MC" },
    { name: "Mongolia", code: "MN" },
    { name: "Montserrat", code: "MS" },
    { name: "Morocco", code: "MA" },
    { name: "Mozambique", code: "MZ" },
    { name: "Myanmar", code: "MM" },
    { name: "Namibia", code: "NA" },
    { name: "Nauru", code: "NR" },
    { name: "Nepal", code: "NP" },
    { name: "Netherlands", code: "NL" },
    { name: "Netherlands Antilles", code: "AN" },
    { name: "New Caledonia", code: "NC" },
    { name: "New Zealand", code: "NZ" },
    { name: "Nicaragua", code: "NI" },
    { name: "Niger", code: "NE" },
    { name: "Nigeria", code: "NG" },
    { name: "Niue", code: "NU" },
    { name: "Norfolk Island", code: "NF" },
    { name: "Northern Mariana Islands", code: "MP" },
    { name: "Norway", code: "NO" },
    { name: "Oman", code: "OM" },
    { name: "Pakistan", code: "PK" },
    { name: "Palau", code: "PW" },
    { name: "Palestinian Territory, Occupied", code: "PS" },
    { name: "Panama", code: "PA" },
    { name: "Papua New Guinea", code: "PG" },
    { name: "Paraguay", code: "PY" },
    { name: "Peru", code: "PE" },
    { name: "Philippines", code: "PH" },
    { name: "Pitcairn", code: "PN" },
    { name: "Poland", code: "PL" },
    { name: "Portugal", code: "PT" },
    { name: "Puerto Rico", code: "PR" },
    { name: "Qatar", code: "QA" },
    { name: "Reunion", code: "RE" },
    { name: "Romania", code: "RO" },
    { name: "Russian Federation", code: "RU" },
    { name: "RWANDA", code: "RW" },
    { name: "Saint Helena", code: "SH" },
    { name: "Saint Kitts and Nevis", code: "KN" },
    { name: "Saint Lucia", code: "LC" },
    { name: "Saint Pierre and Miquelon", code: "PM" },
    { name: "Saint Vincent and the Grenadines", code: "VC" },
    { name: "Samoa", code: "WS" },
    { name: "San Marino", code: "SM" },
    { name: "Sao Tome and Principe", code: "ST" },
    { name: "Saudi Arabia", code: "SA" },
    { name: "Senegal", code: "SN" },
    { name: "Serbia and Montenegro", code: "CS" },
    { name: "Seychelles", code: "SC" },
    { name: "Sierra Leone", code: "SL" },
    { name: "Singapore", code: "SG" },
    { name: "Slovakia", code: "SK" },
    { name: "Slovenia", code: "SI" },
    { name: "Solomon Islands", code: "SB" },
    { name: "Somalia", code: "SO" },
    { name: "South Africa", code: "ZA" },
    { name: "South Georgia and the South Sandwich Islands", code: "GS" },
    { name: "Spain", code: "ES" },
    { name: "Sri Lanka", code: "LK" },
    { name: "Sudan", code: "SD" },
    { name: "Suriname", code: "SR" },
    { name: "Svalbard and Jan Mayen", code: "SJ" },
    { name: "Swaziland", code: "SZ" },
    { name: "Sweden", code: "SE" },
    { name: "Switzerland", code: "CH" },
    { name: "Syrian Arab Republic", code: "SY" },
    { name: "Taiwan, Province of China", code: "TW" },
    { name: "Tajikistan", code: "TJ" },
    { name: "Tanzania, United Republic of", code: "TZ" },
    { name: "Thailand", code: "TH" },
    { name: "Timor-Leste", code: "TL" },
    { name: "Togo", code: "TG" },
    { name: "Tokelau", code: "TK" },
    { name: "Tonga", code: "TO" },
    { name: "Trinidad and Tobago", code: "TT" },
    { name: "Tunisia", code: "TN" },
    { name: "Turkey", code: "TR" },
    { name: "Turkmenistan", code: "TM" },
    { name: "Turks and Caicos Islands", code: "TC" },
    { name: "Tuvalu", code: "TV" },
    { name: "Uganda", code: "UG" },
    { name: "Ukraine", code: "UA" },
    { name: "United Arab Emirates", code: "AE" },
    { name: "United Kingdom", code: "GB" },
    { name: "United States", code: "US" },
    { name: "United States Minor Outlying Islands", code: "UM" },
    { name: "Uruguay", code: "UY" },
    { name: "Uzbekistan", code: "UZ" },
    { name: "Vanuatu", code: "VU" },
    { name: "Venezuela", code: "VE" },
    { name: "Viet Nam", code: "VN" },
    { name: "Virgin Islands, British", code: "VG" },
    { name: "Virgin Islands, U.S.", code: "VI" },
    { name: "Wallis and Futuna", code: "WF" },
    { name: "Western Sahara", code: "EH" },
    { name: "Yemen", code: "YE" },
    { name: "Zambia", code: "ZM" },
    { name: "Zimbabwe", code: "ZW" },
  ]

  export default {
    components: {
      vSelect,
      flatPickr,
      Cleave,
      SelectableDate,
    },
    props: {
      submitHandle: {
        default: null,
      },
      playerData: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        positionOptions: [],
        countries: countries.map((d) => {
          d.code = d.code.toLowerCase()
          return d
        }),
      }
    },
    async created() {
      let pos = await this.$http.get("/position")
      this.positionOptions = pos.data
    },
    mounted() {
      new Cleave("#pPhone", {
        numericOnly: true,
        delimiter: " ",
        blocks: [3, 3, 2, 2],
      })
    },
    setup(props, { root }) {
      const profileFile = ref(null)
      const localUser = JSON.parse(localStorage.getItem("userData"))

      onMounted(() => {
        slicePhoneNumber(props.playerData.phone)
      })

      const onFileSelected = (event) => {
        if (event.target.files.length > 0) {
          profileFile.value = event.target.files[0]
        }
      }
      const uploadAvatar = () => {
        if (profileFile.value) {
          let formData = new FormData()
          formData.append("image", profileFile.value)

          http
            .post(`player/${props.playerData.id}/avatarUpload`, formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then((res) => {
              root.toast(i18n.t("Successfully Updated"), "PlusIcon", "success")
              location.reload()
            })
        }
      }

      const refInputEl = ref(null)
      const previewEl = ref(null)
      const { inputImageRenderer } = useInputImageRenderer(refInputEl, (base64) => {
        props.playerData.avatar = base64
      })
      const onContext = (ctx) => {
        console.log(ctx)
      }

      const options = {
        creditCard: {
          creditCard: true,
        },
        date: {
          date: true,
          delimiter: "/",
          datePattern: ["d", "m", "Y"],
        },
        phone: {
          phone: true,
          phoneRegionCode: "TR",
        },
        time: {
          time: true,
          timePattern: ["h", "m", "s"],
        },
        number: {
          numeral: true,
          numeralThousandsGroupStyle: "thousand",
        },
        block: {
          blocks: [4, 3, 3],
          uppercase: true,
        },
        delimiter: {
          delimiter: "·",
          blocks: [3, 3, 3],
          uppercase: true,
        },
        customDelimiter: {
          delimiters: [".", ".", "-"],
          blocks: [3, 3, 3, 2],
          uppercase: true,
        },
        prefix: {
          prefix: "+63",
          blocks: [3, 3, 3, 4],
          uppercase: true,
        },
      }

      const userName = computed(() =>
        props.playerData.user
          ? props.playerData.user.firstName + " " + props.playerData.user.lastName.substr(0, 1) + "."
          : "",
      )

      const slicePhoneNumber = (nv) => {
        if (nv && nv[0] == "0") props.playerData.phone = nv.slice(1)
      }

      return {
        slicePhoneNumber,
        avatarText,
        refInputEl,
        previewEl,
        inputImageRenderer,
        onContext,
        options,
        onFileSelected,
        uploadAvatar,
        userName,
        localUser,
      }
    },
  }
</script>
<style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
</style>
