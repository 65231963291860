<template>
  <div>
    <div>
      <b-form ref="form" class="repeater-form">
        <!-- Row Loop -->
        <b-row v-for="(item, index) in addItems" :id="index" :key="index" ref="quickPlayerRow">
          <!-- Player Name -->
          <b-col lg="4">
            <b-form-group :label="$t('Player Name')" label-for="item-name">
              <v-select
                @input="changedLabel"
                @search="onSearch"
                v-model="name"
                single
                :filterable="false"
                :options="testingPlayerLikeName"
              >
                <template slot="no-options">
                  <p></p>
                </template>
                <template slot="option" slot-scope="option">
                  <div class="d-center">
                    {{ option.name }}
                  </div>
                </template>
              </v-select>
            </b-form-group>
          </b-col>

          <!-- Player Age -->
          <b-col lg="4">
            <b-form-group :label="$t('Age')" label-for="cost">
              <b-form-input
                id="age"
                v-model="age"
                type="number"
                placeholder="14"
                @change="
                  birthdate = new Date(new Date().setFullYear(new Date().getFullYear() - Number(age))).getFullYear()
                "
              />
            </b-form-group>
          </b-col>
          <!-- Player Age -->
          <b-col lg="4">
            <b-form-group :label="$t('Birth Year')" label-for="cost">
              <b-form-input
                id="year"
                v-model="birthdate"
                type="number"
                placeholder="2002"
                @change="age = Math.abs(new Date(Date.now() - new Date(birthdate).getTime()).getUTCFullYear() - 1970)"
              />
            </b-form-group>
          </b-col>

          <!-- Player Position -->
          <b-col lg="4">
            <b-form-group :label="$t('Position')" label-for="position">
              <b-form-select
                :placeholder="$t('Player position')"
                id="position"
                value-field="id"
                text-field="name"
                :value="0"
                :options="positionOptions"
                v-model="position"
              ></b-form-select>
            </b-form-group>
          </b-col>

          <!-- Player Foot -->
          <b-col lg="3">
            <b-form-group :label="$t('Strong Foot')" label-for="foot">
              <b-form-select
                :placeholder="$t('Strong foot')"
                id="foot"
                value-field="id"
                text-field="label"
                :value="0"
                :options="footOptions"
                v-model="foot"
              ></b-form-select>
            </b-form-group>
          </b-col>

          <b-col lg="4">
            <b-form-group :label="$t('Recommended by')" label-for="item-name">
              <v-select
                v-model="recommend"
                :options="recommenderOptions"
                :reduce="(val) => val.id"
                :getOptionLabel="(option) => option.firstName + ' ' + (option.lastName ? option.lastName : '')"
                :loading="recommenderLoading"
                ref="recommenderSelect"
              >
                <template #no-options="{ search }">
                  <div @click="recommenderSubmitModal(search)" style="cursor: pointer">
                    <feather-icon icon="PlusCircleIcon" size="14" />
                    <span class="text-primary">{{ $t("Do you want to add this item?") }}</span>
                  </div>
                </template>
              </v-select>
            </b-form-group>
          </b-col>

          <!-- Actions Button -->
          <b-col lg="1" class="mb-50 ml-n1">
            <b-button @click="saveItem(index)" variant="outline-success" class="mt-0 mt-md-2">
              <feather-icon icon="SaveIcon"></feather-icon>
            </b-button>
          </b-col>
          <b-col cols="12">
            <hr />
          </b-col>
        </b-row>
      </b-form>
    </div>
  </div>
</template>
<script>
  import { ref, onMounted } from "@vue/composition-api"
  import http from "@/libs/axios"
  import i18n from "@/libs/i18n"
  import vSelect from "vue-select"
  import ToastificationContent from "../../../@core/components/toastification/ToastificationContent"
  import router from "@/router"
  import _ from "lodash"
  import { recommenderService } from "@/services"

  export default {
    components: { vSelect },
    props: { helper: null, teamId: null, reloadTeams: null, teamPlayers: null },
    data() {
      return {
        name: "",
        foot: "",
        age: "",
        position: "",
        birthdate: "",
        recommend: "",
        seasonId: Number(router.currentRoute.params.seasonId) || null,
        testingPlayerLikeName: [],
        recommenderLoading: false,
      }
    },
    watch: {
      age(newAge) {},
      birthdate(newYear) {},
    },
    methods: {
      saveItem(index) {
        let saveObject = {}
        // let findRow = this.$refs.quickPlayerRow[index];
        saveObject.name = this.name
        saveObject.foot = Number(this.foot)
        saveObject.age = this.age
        saveObject.position = Number(this.position)
        saveObject.recommend = this.recommend
        this.submitQuickPlayer({ index, team: this.teamId, ...saveObject }).then((response) => {
          this.name = ""
          this.foot = ""
          this.age = ""
          this.position = ""
          this.recommend = ""

          this.helper.helperFuncs.submitFormation(response, this.teamId).then((dt) => {
            dt.player.isTesting = 1
            this.changedLabel(dt.player)
          })
        })
      },

      async recommenderSubmitModal(searchRecommender) {
        let confirm = await this.confirmModal(i18n.t("Do you want to add this item?"))
        if (!confirm) return false

        try {
          const recommenderSer = new recommenderService()
          this.recommenderLoading = true

          const data = await recommenderSer.event({ data: { firstName: searchRecommender } })

          this.recommend = data.data.id
          this.recommenderOptions.push(data.data)
          this.recommenderLoading = false
        } catch (err) {
          console.log("err-recommender", err)
          this.recommenderLoading = false
        }
      },

      changedLabel(player) {
        if (player != null && player.id) {
          player.isStarterPlayer = 2
          player["stats"] = {
            yellowCard: null,
            yellowRedCard: null,
            redCard: null,
            goals: [],
            events: [],
          }
          this.teamPlayers.push(player)
          this.helper.drawFormationPlugin(player, this.teamId)
          this.name = ""
          this.testingPlayerLikeName = []
        } else if (player == null) {
          this.name = ""
          this.testingPlayerLikeName = []
        } else {
          console.log("else")
        }
      },

      // get options
      onSearch(search, loading) {
        if (search.length) {
          loading(true)
          this.searchPlayer(loading, search, this)
          this.name = search
        }
      },
      searchPlayer: _.debounce((loading, search, vm) => {
        http.get(`/season/${vm.seasonId}/team/${vm.teamId}?testing=1&q=${search}`).then((res) => {
          vm.testingPlayerLikeName = res.data.players.filter(
            ({ id: id1 }) => !vm.teamPlayers.some(({ id: id2 }) => id2 === id1),
          )
          loading(false)
        })
      }, 350),
    },
    setup(props, { root }) {
      const positionOptions = ref([])
      const recommenderOptions = ref([])

      onMounted(async () => {
        await Promise.all([getPositions(), getRecommenders()])
      })

      const getPositions = () => {
        http
          .get(`/position`)
          .then((res) => {
            positionOptions.value = res.data
          })
          .catch((error) => console.log(error))
      }

      const getRecommenders = async () => {
        const res = await new recommenderService().get()
        recommenderOptions.value = res.data
      }

      const submitQuickPlayer = function ({ index, team, name, foot, age, position, recommend }) {
        this.playerSendForm = {
          name: name,
          avatar: "user.png",
          teamId: team,
          foot: Number(foot),
          birthdate: new Date(new Date().setFullYear(new Date().getFullYear() - Number(age))),
          email: "quickinsert@client.com",
          phone: "555 555 55 55",
          position: position,
          recommend: recommend,
          isTesting: 1, // 1: testing player
        }
        return this.$http
          .post(`/season/${this.routerParams.seasonId}/team/${team}/player`, this.playerSendForm)
          .then((succ) => {
            succ.data.isStarterPlayer = 2
            this.loading = false
            let newPlayerId = succ.data.id
            this.$toast({
              component: ToastificationContent,
              props: {
                title: this.$t("Player saved successfully"),
                icon: "UserIcon",
                variant: "success",
              },
            })

            return new Promise((resolve) => {
              return resolve(succ.data)
            })
          })
      }

      const addItems = ref([{ id: null }])

      const addItemNew = function () {
        addItems.value.push({ id: null })
      }

      const deleteItem = function (index) {
        addItems.value.splice(index, 1)
      }

      return {
        positionOptions,
        recommenderOptions,
        addItems,
        addItemNew,
        deleteItem,
        submitQuickPlayer,
      }
    },
  }
</script>
