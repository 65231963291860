<template>
  <div class="d-flex flex-column">
    <b-modal
      id="isReasonModal"
      ref="reasonModal"
      :title="$t('Reason')"
      centered
      :no-close-on-backdrop="isLoading"
      :hide-header-close="isLoading"
    >
      <div class="reasons" style="min-height: 150px">
        <p>
          {{
            playerData.deleted_at == null
              ? $t("Please confirm that you want to delete this.")
              : $t("Please confirm that you want to undo the deletion.")
          }}
        </p>
        <v-select
          v-model="playerModalData.reason"
          :placeholder="$t('Reason') + ' ' + $t('Select')"
          :options="reasonOptions"
          :reduce="(option) => option.value"
          :getOptionLabel="(option) => option.label"
        >
        </v-select>
        <b-textarea class="mt-2" required :placeholder="$t('Description')" v-model="playerModalData.description" />
        <span> {{ $t("Please enter min 20 char") }} </span>
      </div>
      <template #modal-footer>
        <div class="w-100">
          <b-button
            variant="primary"
            size="md"
            class="float-right"
            @click="playerData.deleted_at == null ? removePlayer() : unRemovePlayer()"
            :disabled="
              !(playerModalData.reason && playerModalData.description && playerModalData.description.length > 20) ||
              isLoading
            "
          >
            <b-spinner v-if="isLoading" small style="margin-bottom: 3px"></b-spinner>
            {{ playerData.deleted_at == null ? $t("Remove") : $t("Add Back To The Squad") }}
          </b-button>
          <b-button
            variant="danger"
            size="md"
            class="float-right mr-1"
            @click="$bvModal.hide('isReasonModal')"
            :disabled="isLoading"
            >{{ $t("Cancel") }}</b-button
          >
        </div>
      </template>
    </b-modal>
    <div v-if="isTeamPlayers">
      <div class="alert alert-primary p-2">
        {{ $t("Data not found") }}
      </div>
    </div>
    <div class="responsive" v-else>
      <b-card :title="$t('List')">
        <div class="d-flex justify-content-between">
          <b-input v-model="searchQuery" class="col-md-3" :placeholder="$t('Search')" type="search" />
          <b-button-group v-if="$store.getters['app/currentBreakPoint'] !== 'xs'">
            <b-button variant="info" :class="{ active: showTable === 'overview' }" @click="showTable = 'overview'">
              {{ $t("Overview") }}
            </b-button>
            <b-button
              variant="info"
              :class="{ active: showTable === 'competitions' }"
              @click="showTable = 'competitions'"
            >
              {{ $t("Competitions") }}
            </b-button>
            <b-button variant="info" :class="{ active: showTable === 'trainings' }" @click="showTable = 'trainings'">
              {{ $t("Trainings") }}
            </b-button>
            <b-button
              variant="info"
              :class="{ active: showTable === 'deletedPlayer' }"
              @click="showTable = 'deletedPlayer'"
            >
              {{ $t("Out of Squad") }}
            </b-button>
          </b-button-group>
          <b-dropdown
            id="dropdown-team-players1"
            :text="$t('Please Select by...')"
            class="m-md-2 ml-1"
            v-if="$store.getters['app/currentBreakPoint'] === 'xs'"
          >
            <b-dropdown-item variant="info" @click="showTable = 'overview'">
              {{ $t("Overview") }}
            </b-dropdown-item>
            <b-dropdown-item variant="info" @click="showTable = 'competitions'">
              {{ $t("Competitions") }}
            </b-dropdown-item>
            <b-dropdown-item variant="info" @click="showTable = 'trainings'">
              {{ $t("Trainings") }}
            </b-dropdown-item>
            <b-dropdown-item variant="info" @click="showTable = 'deletedPlayer'">
              {{ $t("Dismiss") }}
            </b-dropdown-item>
          </b-dropdown>
        </div>
        <div class="mt-2" :class="showTable != 'deletedPlayer' ? 'mx-n2' : ''">
          <b-table
            v-if="showTable == 'overview'"
            hover
            responsive
            :fields="[
              { key: 'name', label: $t('Player'), sortable: true },
              { key: 'position.name', label: $t('Position'), sortable: true },
              { key: 'birthdate', label: $t('Age'), sortable: true },
              { key: 'height', label: $t('Height'), sortable: true },
              { key: 'weight', label: $t('Weight'), sortable: true },
              { key: 'serpTraining', label: $t('sERP'), sortable: true },
              //{ key: 'serpCompetition', label: `sERP (${$t('Competition')})`, sortable: true },
              { key: 'coachReviewComp', label: `mbp`, sortable: true },
              { key: 'performanceAvg', label: `pd`, sortable: true },
              { key: 'action', label: $t('Action') },
            ]"
            :items="getPlayers"
            :empty-text="$t('No records found')"
            :empty-filtered-text="$t('There are no records matching your request')"
            show-empty
            busy.sync="false"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :filter="searchQuery"
            ref="refOverview"
          >
            <template #head(serpTraining)="data">
              <div v-b-tooltip.hover :title="$t('Session Rating of Perceived Exertion')">
                {{ $t("sERP") }}
              </div>
            </template>
            <template #head(coachReviewComp)="scope">
              <div v-b-tooltip.hover :title="$t('Performance Per Game')">
                {{ $t("PPG") }}
              </div>
            </template>
            <template #head(performanceAvg)="scope">
              <div v-b-tooltip.hover :title="$t('Performance Rate')">{{ $t("PR") }}</div>
            </template>
            <template #table-busy>
              <div class="text-center my-2">
                <b-spinner class="align-middle"></b-spinner>
              </div>
            </template>
            <template #cell(name)="data">
              <player-item-box :player="data.item" />
            </template>
            <template #cell(birthdate)="data">
              {{ findAge(data.item.birthdate) }}
            </template>
            <template #cell(position.name)="data">
              {{ $t(data.item.position.name) }}
            </template>
            <template #cell(serpTraining)="data">
              <p @click="goToPlayer(data.item.id, 4)">{{ data.value }}</p>
            </template>
            <template #cell(serpCompetition)="data">
              <p @click="goToPlayer(data.item.id, 3)">{{ data.value }}</p>
            </template>
            <template #cell(coachReviewComp)="data">
              <p @click="goToPlayer(data.item.id, 3)">{{ data.value }}</p>
            </template>
            <template #cell(performanceAvg)="data">
              <p @click="goToPlayer(data.item.id, 1)">
                {{ isNaN(parseFloat(data.value)) ? "" : parseFloat(data.value).toFixed(1) }}
              </p>
            </template>
            <template #cell(action)="data">
              <b-link class="text-dark ml-1">
                <feather-icon
                  v-b-tooltip.hover
                  @click="showRemoveModal(data.item)"
                  :title="$t('Delete')"
                  size="24"
                  icon="TrashIcon"
                />
              </b-link>
            </template>
          </b-table>
          <!-- - yedek, oks, gbm, oks gbm, gol, asist, serp -->
          <b-table
            v-if="showTable == 'competitions'"
            hover
            responsive
            :fields="[
              { key: 'name', label: $t('Player'), sortable: true },
              {
                key: 'player.position.name',
                label: $t('Position'),
                sortable: true,
              },
              {
                key: 'eventIsStarter',
                label: $t('Starting 11'),
                sortable: true,
              },
              {
                key: 'eventIsSubstitue',
                label: $t('Substitute'),
                sortable: true,
              },
              {
                key: 'playerTotalInGame',
                label: $t('TP'),
                thDataAttr: $t('Time Played'),
                sortable: true,
              },
              {
                key: 'playerSurvivalRate',
                label: $t('SR'),
                thDataAttr: $t('Survival Rate'),
                sortable: true,
                formatter(val) {
                  return `${val.toFixed(0)}%`
                },
              },
              {
                key: 'competitionStatistics',
                label: $t('W/D/L'),
                sortable: false,
                formatter(v, k, i) {
                  return `${v.homeWin + v.awayWin}/${v.draw}/${v.lose}`
                },
              },
              {
                key: 'competitionInGameStatistics',
                label: $t('TP W/D/L'),
                sortable: false,
                formatter(v, k, i) {
                  return `${v.home.win + v.away.win}/${v.home.draw + v.away.draw}/${v.home.lose + v.away.lose}`
                },
              },
              { key: 'goal', label: $t('Goal'), sortable: true },
              { key: 'assist', label: $t('Assist'), sortable: true },
              { key: 'yellowCard', label: $t('Yellow Card'), sortable: true },
              { key: 'redCard', label: $t('Red Card'), sortable: true },
              /*{
								key: 'playerLastReview',
								label: $t('sERP'),
								sortable: false,
								formatter(v,k,i) { return v.map(d => d.playerReview).join('-') }
							},*/
            ]"
            :items="getPlayerCompetitions"
            :empty-text="$t('No records found')"
            :empty-filtered-text="$t('There are no records matching your request')"
            show-empty
            busy.sync="false"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :filter="searchQuery"
          >
            <template #head(playerTotalInGame)="data">
              <span v-b-tooltip.hover="$t('Time Played')">
                {{ $t("TP") }}
              </span>
            </template>
            <template #head(playerSurvivalRate)="data">
              <span v-b-tooltip.hover="$t('Survival Rate')"> {{ $t("SR") }}% </span>
            </template>
            <template #head(competitionInGameStatistics)="data">
              <span v-b-tooltip.hover="$t('Win Draw Lose on played time')">
                {{ $t("TP W/D/L") }}
              </span>
            </template>
            <template #head(competitionStatistics)="data">
              <span v-b-tooltip.hover="`${$t('Win')} / ${$t('Draw')} / ${$t('Loss')}`">
                {{ $t("W/D/L") }}
              </span>
            </template>
            <template #head(redCard)="data">
              {{ $t("Red Card") }}
              <img :src="require('@/assets/images/icons/yellow-red-card.png')" height="24" alt />
            </template>
            <template #head(yellowCard)="data">
              {{ $t("Yellow Card") }}
              <img :src="require('@/assets/images/icons/yellow-card.png')" height="24" alt />
            </template>
            <template #table-busy>
              <div class="text-center my-2">
                <b-spinner class="align-middle"></b-spinner>
              </div>
            </template>
            <template #cell(name)="data">
              <player-item-box :player="data.item" />
            </template>
            <template #cell(player.position.name)="data">
              {{ $t(data.item.player.position.name) }}
            </template>
          </b-table>
          <b-table
            v-if="showTable == 'trainings'"
            hover
            responsive
            :fields="[
              { key: 'name', label: $t('Player'), sortable: true },
              {
                key: 'player.position.name',
                label: $t('Position'),
                sortable: true,
              },
              {
                key: 'eventItemAttendant',
                label: $t('Attended Trainings'),
                sortable: true,
              },
              {
                key: 'eventItem',
                label: $t('Not-Attended Trainings'),
                sortable: true,
              },
              {
                key: 'eventItemAttendantPercent',
                label: $t('Percent') + ' %',
                sortable: true,
              },
              {
                key: 'serp',
                label: $t('sERP'),
                sortable: false,
              },
              {
                key: 'duration',
                label: `${$t('Total Duration')}`,
                sortable: false,
              },
            ]"
            :items="getPlayerTrainings"
            :empty-text="$t('No records found')"
            :empty-filtered-text="$t('There are no records matching your request')"
            show-empty
            busy.sync="false"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :filter="searchQuery"
          >
            <template #head(serp)="data">
              <div v-b-tooltip.hover :title="$t('Session Rating of Perceived Exertion')">
                {{ $t("sERP") }}
              </div>
            </template>
            <template #table-busy>
              <div class="text-center my-2">
                <b-spinner class="align-middle"></b-spinner>
              </div>
            </template>
            <template #cell(name)="data">
              <player-item-box :player="data.item" />
            </template>
            <template #cell(eventItemAttendantPercent)="data">
              {{ data.item.eventItemAttendantPercent && data.item.eventItemAttendantPercent.toFixed(2) }}%
            </template>
            <template #cell(player.position.name)="data">
              {{ $t(data.item.player.position.name) }}
            </template>
            <template #cell(serp)="data">
              {{ data.value.join("-") }}
            </template>
          </b-table>
          <div class="row" v-if="showTable == 'deletedPlayer'">
            <div class="table-responsive col-12 col-md-6">
              <b-card-header class="p-1">
                <b-card-title class="font-medium-2">
                  <span class="align-middle ml-50">{{ $t("Dismiss") }}</span>
                </b-card-title>
              </b-card-header>
              <b-table
                hover
                responsive
                small
                bordered
                :fields="[
                  { key: 'name', label: $t('Player'), tdClass: 'rowHeight' },
                  {
                    key: 'deletedDate',
                    label: $t('Action Date'),
                    thStyle: { 'min-width': '10em' },
                    formatter: (val, key, item) =>
                      getLastDeleteAction(item.actions) ? item.actions[0].created_at : '',
                  },
                  {
                    key: 'reason',
                    label: $t('Reason'),
                    formatter: (val, key, item) =>
                      getLastDeleteAction(item.actions) ? $t(item.actions[0].reason) : '',
                  },
                  {
                    key: 'description',
                    label: $t('Description'),
                    tdClass: 'responsiveWidth',
                  },
                  { key: 'user', label: $t('User') },
                  { key: 'action', label: $t('Action') },
                ]"
                :items="getDeletedPlayers"
                :empty-text="$t('No records found')"
                :empty-filtered-text="$t('There are no records matching your request')"
                show-empty
                busy.sync="false"
                :filter="searchQuery"
                :ref="refDeletedPlayers"
              >
                <template #table-busy>
                  <div class="text-center my-2">
                    <b-spinner class="align-middle"></b-spinner>
                  </div>
                </template>
                <template #cell(name)="data">
                  <player-item-box :player="data.item" />
                </template>
                <template #cell(deletedDate)="data">
                  {{ data.value | nativeDateTime }}
                </template>
                <template #cell(description)="data" class="px-n2">
                  <p
                    v-if="getLastDeleteAction(data.item.actions)"
                    class="maxLine"
                    v-b-tooltip.hover
                    :title="getLastDeleteAction(data.item.actions).description"
                  >
                    {{ getLastDeleteAction(data.item.actions).description }}
                  </p>
                </template>
                <template #cell(user)="data">
                  <span v-if="getLastDeleteAction(data.item.actions)">
                    {{ getLastDeleteAction(data.item.actions).user.firstName }}
                    {{ getLastDeleteAction(data.item.actions).user.lastName.substr(0, 1) + "." }}
                  </span>
                </template>
                <template #cell(action)="data">
                  <b-link class="text-dark ml-1">
                    <feather-icon
                      v-b-tooltip.hover
                      @click="showRemoveModal(data.item)"
                      :title="$t('Add Back To The Squad')"
                      size="24"
                      icon="RotateCcwIcon"
                    />
                  </b-link>
                </template>
              </b-table>
            </div>

            <div class="table-responsive col-12 col-md-6">
              <b-card-header class="p-1">
                <b-card-title class="font-medium-2">
                  <span class="align-middle ml-50">{{ $t("Tested Players") }}</span>
                </b-card-title>
              </b-card-header>
              <b-table
                hover
                responsive
                small
                bordered
                :fields="[
                  { key: 'name', label: $t('Player'), tdClass: 'rowHeight' },
                  { key: 'position.name', label: $t('Position') },
                  { key: 'birthdate', label: $t('Age') },
                  { key: 'foot', label: $t('Strong Foot') },
                  { key: 'action', label: $t('Action') },
                ]"
                :items="getTestingPlayers"
                :empty-text="$t('No records found')"
                :empty-filtered-text="$t('There are no records matching your request')"
                show-empty
                busy.sync="false"
                :filter="searchQuery"
                ref="refTestingPlayers"
              >
                <template #table-busy>
                  <div class="text-center my-2">
                    <b-spinner class="align-middle"></b-spinner>
                  </div>
                </template>
                <template #cell(name)="data">
                  <player-item-box :player="data.item" />
                </template>
                <template #cell(birthdate)="data">
                  {{ findAge(data.item.birthdate) }}
                </template>
                <template #cell(position.name)="data">
                  {{ $t(data.item.position.name) }}
                </template>
                <template #cell(foot)="data">
                  {{
                    data.item.foot === 1 ? $t("Right Foot") : data.item.foot === 0 ? $t("Left Foot") : $t("Both Feet")
                  }}
                </template>
                <template #cell(action)="data">
                  <b-link class="text-dark ml-1">
                    <feather-icon
                      v-b-tooltip.hover
                      @click="showInfoModal(data.item)"
                      :title="$t('Add To Squad')"
                      size="24"
                      icon="CornerLeftUpIcon"
                    />
                  </b-link>
                </template>
              </b-table>
            </div>
          </div>
        </div>
      </b-card>
    </div>
  </div>
</template>
<script>
  import {
    BCard,
    BCardBody,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    BCardTitle,
    BCardSubTitle,
  } from "bootstrap-vue"
  import vSelect from "vue-select"
  import { onUnmounted, watch, ref, onMounted } from "@vue/composition-api"
  import store from "@/store"
  import useTeamPlayerList from "../../useTeamPlayerList"
  import BCardActions from "@core/components/b-card-actions/BCardActions.vue"
  import teamStoreModule from "../../teamStoreModule"
  import PlayerCard from "@/views/apps/player-card/PlayerCard"
  import UserCard from "@/views/apps/user-card/UserCard"
  import axios from "@/libs/axios"
  import router from "@/router"
  import { localService } from "@/services"
  import i18n from "@/libs/i18n"
  import PlayerItemBox from "@core/components/global-components/PlayerItemBox"

  export default {
    components: {
      BCard,
      BCardBody,
      BRow,
      BCol,
      BFormInput,
      BButton,
      BTable,
      BMedia,
      BAvatar,
      BLink,
      BBadge,
      BDropdown,
      BDropdownItem,
      BPagination,
      BTooltip,
      BCardActions,
      vSelect,
      PlayerCard,
      UserCard,
      BCardTitle,
      BCardSubTitle,
      PlayerItemBox,
    },
    data() {
      return {}
    },
    watch: {
      $route() {
        location.reload()
      },
    },
    methods: {
      getLastDeleteAction(actions) {
        const deleteActions = actions.filter((i) => i.action === "delete")
        return deleteActions.length > 0 ? deleteActions.at(-1) : null
      },
    },
    setup(props, { root, context }) {
      const STORE_MODULE_NAME = "app-team"
      // Register module
      if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, teamStoreModule)
      // UnRegister on leave
      onUnmounted(() => {
        if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
      })
      onMounted(() => {
        showTable.value = new localService("team_player_tab").get() || "overview"
        searchQuery.value = new localService("team_player_query").get() || ""
      })

      const {
        members,
        tableColumns,
        perPage,
        currentPage,
        totalInvoices,
        dataMeta,
        perPageOptions,
        isSortDirDesc,
        refListTable,
        statusFilter,
        refFetchData,
      } = useTeamPlayerList()
      const isTeamPlayers = ref(false)
      const deletedPlayers = ref([])
      const showTable = ref("")
      const searchQuery = ref("")
      const playerModalData = ref({ id: null, reason: null })
      const playerData = ref("")
      const reasonModal = ref(false)
      const isLoading = ref(false)
      const isBusy = ref(false)
      const seasonId = ref(router.currentRoute.params.seasonId)
      const teamId = ref(router.currentRoute.params.id)
      const refOverview = ref(null)
      const refTestingPlayers = ref(null)
      const refDeletedPlayers = ref(null)

      const sortBy = ref("position.name")
      const sortDesc = ref(true)

      const reasonOptions = ref([
        { label: i18n.t("Performance"), value: "Performance" },
        { label: i18n.t("Discipline"), value: "Disiplin" },
        { label: i18n.t("Transfer"), value: "Transfer" },
        { label: i18n.t("Other"), value: "Diğer" },
      ])

      const addReasonnOption = (val) => {
        reasonOptions.value.push({ label: val, value: val })
        playerModalData.value.reason = val
      }

      const showRemoveModal = (player) => {
        playerData.value = player
        playerModalData.value = { id: null, reason: null, description: null }
        playerModalData.value.id = player.id
        reasonModal.value.show()
      }

      const showInfoModal = async (player) => {
        let confirm = await root.confirmModal(i18n.t("Please confirm that you want to squad the player."))
        if (!confirm) return false
        transferTestingToSquad(player.id)
      }

      const removePlayer = async () => {
        isLoading.value = true
        axios
          .put(`/player/${playerModalData.value.id}/delete`, playerModalData.value)
          .then((response) => {
            isLoading.value = false
            reasonModal.value.hide()
            root.toast(i18n.t("Successfully Deleted"), "PlusIcon", "success")
            refOverview.value.refresh()
          })
          .catch((error) => {
            console.log(error)
            isLoading.value = false
          })
      }

      const unRemovePlayer = async () => {
        isLoading.value = true
        axios
          .put(`/player/${playerModalData.value.id}/undelete`, playerModalData.value)
          .then((response) => {
            isLoading.value = false
            reasonModal.value.hide()
            root.toast(i18n.t("Successfully Updated"), "PlusIcon", "success")
            refDeletedPlayers.value.refresh()
          })
          .catch((error) => {
            console.log(error)
            isLoading.value = false
          })
      }

      watch([searchQuery], async (tabIndex) => {
        new localService("team_player_query", searchQuery.value)
      })
      watch(showTable, async () => {
        sortBy.value = "position.name"
        sortDesc.value = true
        new localService("team_player_tab", showTable.value)
      })

      const getDeletedPlayers = async () => {
        var { data } = await axios.get(
          `/season/${seasonId.value}/team/${teamId.value}?q=${searchQuery.value}&deleted=true`,
        )

        for (var i = 0; i < data.players.length; i++) {
          data.players[i].actions.sort(function (a, b) {
            return b.id - a.id
          })
        }
        return data.players
      }

      const getPlayers = async () => {
        var { data } = await axios.get(`/season/${seasonId.value}/team/${teamId.value}?q=${searchQuery.value}`)
        if (sortBy.value == "position.name" && sortDesc.value)
          data.players = data.players.sort((a, b) => (b.position.priority ?? 0) - (a.position.priority ?? 0))
        else if (sortBy.value == "position.name" && !sortDesc.value)
          data.players = data.players.sort((a, b) => (a.position.priority ?? 0) - (b.position.priority ?? 0))
        else if (["name", "birthdate"].includes(sortBy.value) && sortDesc.value)
          data.players = data.players.sort((a, b) => b[sortBy.value].localeCompare(a[sortBy.value]))
        else if (["name", "birthdate"].includes(sortBy.value) && !sortDesc.value)
          data.players = data.players.sort((a, b) => a[sortBy.value].localeCompare(b[sortBy.value]))
        else if (sortDesc.value)
          data.players = data.players.sort((a, b) => (b[sortBy.value] ?? 0) - (a[sortBy.value] ?? 0))
        else if (!sortDesc.value)
          data.players = data.players.sort((a, b) => (a[sortBy.value] ?? 0) - (b[sortBy.value] ?? 0))
        return data.players
      }

      const getPlayerCompetitions = async () => {
        var { data } = await axios.get(
          `/season/${seasonId.value}/team/${teamId.value}/player/competitions?q=${searchQuery.value}`,
        )
        if (sortBy.value == "player.position.name" && sortDesc.value)
          data = data.sort((a, b) => (b.player.position.priority ?? 0) - (a.player.position.priority ?? 0))
        else if (sortBy.value == "player.position.name" && !sortDesc.value)
          data = data.sort((a, b) => (a.player.position.priority ?? 0) - (b.player.position.priority ?? 0))
        else if (sortBy.value == "name" && sortDesc.value)
          data = data.sort((a, b) => b.player.name.localeCompare(a.player.name))
        else if (sortBy.value == "name" && !sortDesc.value)
          data = data.sort((a, b) => a.player.name.localeCompare(b.player.name))
        else if (sortDesc.value) data = data.sort((a, b) => (b[sortBy.value] ?? 0) - (a[sortBy.value] ?? 0))
        else if (!sortDesc.value) data = data.sort((a, b) => (a[sortBy.value] ?? 0) - (b[sortBy.value] ?? 0))
        return data
      }

      const getPlayerTrainings = async () => {
        var { data } = await axios.get(
          `/season/${seasonId.value}/team/${teamId.value}/player/trainings?q=${searchQuery.value}`,
        )
        if (sortBy.value == "player.position.name" && sortDesc.value)
          data = data.sort((a, b) => (b.player.position.priority ?? 0) - (a.player.position.priority ?? 0))
        else if (sortBy.value == "player.position.name" && !sortDesc.value)
          data = data.sort((a, b) => (a.player.position.priority ?? 0) - (b.player.position.priority ?? 0))
        else if (sortBy.value == "name" && sortDesc.value)
          data = data.sort((a, b) => b.player.name.localeCompare(a.player.name))
        else if (sortBy.value == "name" && !sortDesc.value)
          data = data.sort((a, b) => a.player.name.localeCompare(b.player.name))
        else if (sortDesc.value) data = data.sort((a, b) => (b[sortBy.value] ?? 0) - (a[sortBy.value] ?? 0))
        else if (!sortDesc.value) data = data.sort((a, b) => (a[sortBy.value] ?? 0) - (b[sortBy.value] ?? 0))
        return data
      }

      const getTestingPlayers = async () => {
        var { data } = await axios.get(
          `/season/${seasonId.value}/team/${teamId.value}?q=${searchQuery.value}&testing=1`,
        )
        return data.players
      }

      const transferTestingToSquad = async (playerId) => {
        var { data } = await axios.put(`/player/${playerId}/testing`, { isTesting: 0 })
        if (data) {
          refTestingPlayers.value.refresh()
        }
      }

      const findAge = (birthdate) => {
        return Math.abs(new Date(Date.now() - new Date(birthdate).getTime()).getUTCFullYear() - 1970)
      }

      const goToPlayer = (playerId, tabIndex) => {
        new localService("player_tab_index", tabIndex || "")
        router.push({
          name: "team-player",
          params: {
            seasonId: seasonId.value,
            id: teamId.value,
            playerId: playerId,
          },
        })
      }

      return {
        findAge,
        showTable,
        members,
        tableColumns,
        perPage,
        currentPage,
        totalInvoices,
        dataMeta,
        perPageOptions,
        sortBy,
        isSortDirDesc,
        refListTable,
        statusFilter,
        refFetchData,
        isTeamPlayers,
        searchQuery,
        showRemoveModal,
        showInfoModal,
        unRemovePlayer,
        reasonModal,
        playerModalData,
        playerData,
        removePlayer,
        isLoading,
        reasonOptions,
        addReasonnOption,
        isBusy,
        deletedPlayers,
        getPlayers,
        getPlayerCompetitions,
        getPlayerTrainings,
        getDeletedPlayers,
        getTestingPlayers,
        refOverview,
        refTestingPlayers,
        refDeletedPlayers,
        goToPlayer,
        sortBy,
        sortDesc,
      }
    },
  }
</script>
<style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }
  .invoice-filter-select {
    min-width: 190px;
    ::v-deep .vs__selected-options {
      flex-wrap: nowrap;
    }
    ::v-deep .vs__selected {
      width: 100px;
    }
  }
  .card_title {
    margin-top: -45px;
  }
</style>
<style lang="scss">
  .responsiveWidth {
    min-width: 2em;
    width: 2em;
    max-width: 10em;
  }
  .maxLine {
    overflow: hidden;
    display: -webkit-box;
    -moz-box-orient: vertical;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
  .rowHeight {
    height: 80px;
  }
  @import "@core/scss/vue/libs/vue-select.scss";
</style>
