export default [
  {
    path: "/dashboard/antrenor/",
    name: "dashboard-antrenor",
    component: () => import("@/views/dashboard/antrenor/Antrenor.vue"),
    meta: {
      resource: "Auth",
      action: "read",
    },
  },
  {
    path: "/dashboard/calendar",
    name: "dashboard-calendar",
    component: () => import("@/views/dashboard/antrenor/DashboardCalendar.vue"),
    meta: {
      resource: "Auth",
      action: "read",
    },
  },
  {
    path: "/dashboard/my-account",
    name: "dashboard-my-account",
    component: () => import("@/views/admin/my-account/AccountSetting.vue"),
    meta: {
      resource: "Auth",
      action: "read",
    },
  },
  {
    path: "/dashboard/antrenor/competitions",
    name: "dashboard-antrenor-competitions",
    component: () => import("@/views/dashboard/manager/competition/index.vue"),
    meta: {
      resource: "Auth",
      action: "read",
    },
  },

  {
    path: "/dashboard/team/players",
    name: "dashboard-team-players",
    component: () => import("@/views/dashboard/manager/team/players.vue"),
    meta: {
      resource: "Auth",
      action: "read",
    },
  },

  {
    path: "/dashboard/antrenor/calendar",
    name: "dashboard-antrenor-calendar",
    component: () => import("@/views/dashboard/antrenor/general/teamsCalender.vue"),
    meta: {
      resource: "Auth",
      action: "read",
    },
  },

  // SCOUT DASHBOARD
  {
    path: "/dashboard/scout/",
    name: "dashboard-scout",
    component: () => import("@/views/dashboard/Scout/Dashboard.vue"),
    meta: {
      resource: "Scout",
      action: "manage",
    },
  },

  {
    path: "/dashboard/communication/",
    name: "dashboard-communicaiton-request",
    component: () => import("@/views/dashboard/communication/index.vue"),
    meta: {
      resource: "Scout",
      action: "manage",
    },
  },
]
