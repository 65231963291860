<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >
    <!-- general tab -->
    <b-tab active>
      <!-- title -->
      <template #title>
        <feather-icon icon="UserIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">{{ $t("General") }}</span>
      </template>
      <b-overlay :show="generalLoading" rounded="sm">
        <account-setting-general
          :general-data="options.general"
          :update-general="updateGeneral"
          :upload-avatar="uploadAvatar"
        />
      </b-overlay>
    </b-tab>
    <!--/ general tab -->

    <!-- change password tab -->
    <b-tab>
      <!-- title -->
      <template #title>
        <feather-icon icon="LockIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">{{ $t("Change Password") }}</span>
      </template>

      <account-setting-password :update-password="updatePassword" />
    </b-tab>
    <!--/ change password tab -->

    <!-- info -->
    <b-tab>
      <!-- title -->
      <template #title>
        <feather-icon icon="InfoIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">{{ $t("Information") }}</span>
      </template>

      <account-setting-information v-if="options.info" :information-data="options.info" />
    </b-tab>

    <!-- social links -->
    <b-tab>
      <!-- title -->
      <template #title>
        <feather-icon icon="LinkIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">{{ $t("Permissions") }}</span>
      </template>
      <b-overlay :show="generalLoading" rounded="sm">
        <account-setting-permission v-if="options.general.id" :user="options.general" />
      </b-overlay>
    </b-tab>

    <!-- notification -->
    <b-tab>
      <!-- title -->
      <template #title>
        <feather-icon icon="BellIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">{{ $t("Notifications") }}</span>
      </template>

      <account-setting-notification v-if="options.notification" :notification-data="options.notification" />
    </b-tab>
  </b-tabs>
</template>

<script>
  import { BTabs, BTab } from "bootstrap-vue"
  import AccountSettingGeneral from "./AccountSettingGeneral.vue"
  import AccountSettingPassword from "./AccountSettingPassword.vue"
  import AccountSettingInformation from "./AccountSettingInformation.vue"
  import AccountSettingSocial from "./AccountSettingSocial.vue"
  import AccountSettingNotification from "./AccountSettingNotification.vue"
  import AccountSettingPermission from "./AccountSettingPermission.vue"
  import { useToast } from "vue-toastification/composition"
  import i18n from "@/libs/i18n"

  export default {
    components: {
      BTabs,
      BTab,
      AccountSettingGeneral,
      AccountSettingPassword,
      AccountSettingInformation,
      AccountSettingSocial,
      AccountSettingNotification,
      AccountSettingPermission,
    },
    data() {
      return {
        options: {
          general: {},
        },
        generalLoading: true,
      }
    },
    methods: {
      updateGeneral(generalData) {
        return this.$http
          .put("/user", generalData)
          .then((res) => {
            // local storage update
            let localUserData = JSON.parse(localStorage.getItem("userData"))
            localUserData.firstName = res.data.firstName
            localUserData.userName = res.data.userName
            localUserData.email = res.data.email
            localStorage.setItem("userData", JSON.stringify(localUserData))
            location.reload()
            this.toast(i18n.t("Successfully Updated"), "PlusIcon", "success")
          })
          .catch((error) => {
            console.log("error", error)
            this.toast(i18n.t("An error occurred"), "AlertCircleIcon", "danger")
          })
      },

      updatePassword(oldPassword, newPassword) {
        this.$http
          .post("/user/change-password", { oldPassword: oldPassword, password: newPassword })
          .then((res) => {
            // TODO Global
            this.toast(i18n.t("Successfully Updated"), "PlusIcon", "success")
          })
          .catch((error) => {
            console.log("error", error)
            // TODO Global error
            this.toast(i18n.t("An error occurred"), "AlertCircleIcon", "danger")
          })
      },

      uploadAvatar(imageFile) {
        if (imageFile) {
          let formData = new FormData()
          formData.append("image", imageFile)

          this.$http
            .post(`/user/${this.options.general.id}/avatarUpload`, formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then((res) => {
              let localUserData = JSON.parse(localStorage.getItem("userData"))
              localUserData.avatar = res.data.avatar
              localStorage.setItem("userData", JSON.stringify(localUserData))
              location.reload()
              this.toast(i18n.t("Successfully Updated"), "PlusIcon", "success")
            })
            .catch((err) => {
              console.log("err", err)
              this.toast(i18n.t("An error occurred"), "AlertCircleIcon", "danger")
            })
        }
      },
    },
    beforeMount() {
      // TODO: delete this

      this.$http.get("/me").then((res) => {
        this.options.general = res.data
        this.generalLoading = false
      })
    },
    setup(props, { root }) {
      const toast = useToast()

      return {
        toast,
      }
    },
  }
</script>
