<template>
  <b-overlay variant="white" :show="showLoading" spinner-variant="primary" blur="0" opacity=".75" rounded="sm">
    <!-- Add Contact Modal -->
    <b-modal
      id="add-new-contact"
      v-model="showCreateContactModal"
      ref="addNewContactModal"
      hide-footer
      centered
      :title="$t('Contact')"
    >
      <validation-observer ref="contactModalRules">
        <b-form class="auth-forgot-password-form mt-2" @submit.prevent="createContact">
          <!-- Contact Name -->
          <validation-provider name="Name" #default="{ errors }" rules="required">
            <b-form-group :label="$t('Name') + ' & ' + $t('Surname')" label-for="contact-name">
              <b-form-input v-model="contactModalData.contactName" />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Contact Email -->
          <validation-provider name="Email" #default="{ errors }" rules="required">
            <b-form-group :label="$t('Email')" label-for="contact-email">
              <b-form-input v-model="contactModalData.email" />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Contact Level -->
          <validation-provider name="contactLevel" #default="{ errors }" rules="required">
            <b-form-group :label="$t('Parental relationship')" label-for="contactLevel">
              <v-select
                :state="errors.length > 0 ? false : null"
                v-model="contactModalData.contactLevel"
                :reduce="(option) => option.value"
                :options="[
                  { label: $t('Mother'), value: 'Mother' },
                  { label: $t('Father'), value: 'Father' },
                ]"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Contact Phone -->
          <validation-provider name="mobile" #default="{ errors }" rules="required">
            <b-form-group :label="$t('Mobile')" label-for="contactPhone">
              <cleave
                id="time"
                v-model="contactModalData.contactPhone"
                class="form-control"
                :raw="false"
                :options="options.delimiter"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Contact Adress -->
          <validation-provider name="address" #default="{ errors }" rules="required">
            <b-form-group :label="$t('Adress')" label-for="contactAddress">
              <b-form-textarea
                id="contactAddress"
                v-model="contactModalData.contactAddress"
                :state="errors.length > 0 ? false : null"
                rows="5"
                name="text"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <div class="d-flex justify-content-end">
            <b-button type="submit" variant="success" class="mt-1">
              {{ $t("Add") }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </b-modal>

    <b-row v-if="userData">
      <b-col xl="12" class="player-top-bar-info">
        <b-card class="pb-0">
          <b-row class="topbar">
            <b-col md="3">
              <div class="d-flex">
                <div class="p-0">
                  <b-avatar
                    size="112"
                    :src="userData != undefined ? userData.avatar : null"
                    :text="avatarText(userData.name)"
                    :variant="`light-primary`"
                    rounded
                  />
                </div>
                <!--  -->
                <div>
                  <b-link class="mt-0 mb-0 ml-1 d-block h4" :to="{ name: 'team-player' }">{{ userData.name }}</b-link>
                  <span class="mt-0 ml-1 d-block"
                    >{{ userData.birthdate | nativeDate }} ({{ getAge(userData.birthdate) }})</span
                  >
                  <span class="mt-0 ml-1 d-block">{{ $t("license") }}: {{ userData.licenseNo || "-" }}</span>
                  <div class="p-1 d-flex">
                    <span class="badge badge-primary mr-1 col">{{ $t(userData.position.name) }}</span>
                    <span class="badge badge-dark col"
                      >{{
                        userData.foot === 1 ? $t("Right Foot") : userData.foot === 0 ? $t("Left Foot") : $t("Both Feet")
                      }}
                    </span>
                  </div>
                </div>
              </div>
            </b-col>

            <b-col md="8" class="d-flex">
              <div class="col-md-5">
                <template>
                  <div class="contact-parent p1">
                    <p class="text-center">{{ currentRouteName === parentRouteName ? $t("Parent") : $t("Scout") }}</p>
                    <!-- Parent -->
                    <template v-if="currentRouteName === parentRouteName">
                      <!-- Add Contact -->
                      <b-button
                        v-if="userData.contacts.length == 0"
                        class="btn-primary col-md-4"
                        variant="primary"
                        size="sm"
                        @click="showCreateContactModal = true"
                      >
                        <span>{{ $t("Add Parent") }}</span>
                      </b-button>

                      <!-- Select Contact -->
                      <v-select
                        v-if="userData.contacts.length > 0"
                        :placeholder="$t('Select Parent')"
                        id="selecet-parent"
                        class="mb-1"
                        v-model="selectedParent"
                        :options="userData.contacts"
                        :getOptionLabel="(option) => option.contactName"
                      >
                        <template #option="{ contactName, contactLevel }">
                          <h5>{{ contactName }}</h5>
                          <small>{{ contactLevel }}</small>
                        </template>
                      </v-select>
                    </template>

                    <!-- Scout -->
                    <template v-if="currentRouteName === scoutRouteName">
                      <!-- Select Scout -->
                      <v-select
                        :loading="isSelectLoading"
                        :placeholder="$t('Select Scout')"
                        class="mb-1"
                        id="select-scout"
                        v-model="selectedScout"
                        :options="scouts"
                        :getOptionLabel="(option) => option.firstName + ' ' + option.lastName"
                        :filterable="false"
                        @search="onSearch"
                        :clearSearchOnSelect="true"
                      >
                        <li slot="list-footer" class="pagination">
                          <b-pagination
                            @mouseup.native.capture.stop
                            v-model="currentPage"
                            :total-rows="totalItems"
                            :per-page="perPage"
                          ></b-pagination>
                        </li>
                      </v-select>
                    </template>

                    <!-- Parent -->
                    <template v-if="selectedParent">
                      <p>{{ $t("Name") }}: {{ selectedParent.contactName }}</p>
                      <p>{{ $t("Phone") }}: {{ selectedParent.contactPhone }}</p>
                      <p>{{ $t("Email") }}: {{ selectedParent.email }}</p>
                    </template>

                    <!-- Scout -->
                    <template v-if="selectedScout">
                      <p>{{ $t("Name") }}: {{ selectedScout.firstName }} {{ selectedScout.lastName }}</p>
                      <p>{{ $t("Phone") }}: {{ selectedScout.phone }}</p>
                      <p>{{ $t("Email") }}: {{ selectedScout.email }}</p>
                    </template>
                  </div>
                </template>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <b-card>
      <validation-observer ref="communicationRules">
        <b-form class="auth-forgot-password-form mt-2" @submit.prevent="submitCommunicationMail()">
          <!-- Report Type -->
          <b-form-group :label="$t('Report Type')" label-for="report-type" class="col-md-2">
            <validation-provider #default="{ errors }" name="ReportType" rules="required">
              <v-select id="report-type" v-model="formData.reportType" :options="reportTypes" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-row class="col-md-12">
            <!-- Subject -->
            <b-col md="4">
              <b-form-group :label="$t('Subject')" label-for="subject">
                <validation-provider #default="{ errors }" name="Subject" rules="required">
                  <b-form-input
                    id="subject"
                    v-model="formData.subject"
                    :state="errors.length > 0 ? false : null"
                    name="subject"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group :label="$t('File')" label-for="file">
                <b-form-file
                  v-model="file"
                  :placeholder="$t('Choose a file or drop it here')"
                  :browseText="$t('Browse')"
                  drop-placeholder="Drop file here..."
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-button :disabled="!file" @click="fileUpload()" variant="success" class="mt-2">
                <feather-icon size="12" icon="UploadIcon"></feather-icon>
              </b-button>
            </b-col>
          </b-row>

          <!-- Your Message -->
          <b-form-group :label="$t('Message')" label-for="text" class="col-md-12">
            <validation-provider #default="{ errors }" name="Text" rules="required">
              <wysiwyg v-model="formData.message" :placeholder="$t('Write a message')" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-col md="4">
            {{ $t("Attached files") }}
            <li v-for="(file, index) in formData.files" style="list-style: inside" class="d-flex mb-1">
              <b class="mr-1">-</b>
              <a :href="file.url" target="_blank" class="text-truncate">
                <span class="">{{ file.originalName }}</span>
              </a>
              <span
                ><feather-icon
                  icon="TrashIcon"
                  @click="deleteFile(file, index)"
                  v-b-tooltip.hover.top="$t('Delete File')"
                  size="16"
                  class="cursor-pointer ml-1"
                  color="red"
              /></span>
            </li>
          </b-col>

          <!-- submit button -->
          <b-button class="ml-auto mr-1 d-block" variant="primary" type="submit" :disabled="disabledButton">
            {{ $t("Send") }}
          </b-button>
        </b-form>
      </validation-observer>
    </b-card>
  </b-overlay>
</template>

<script>
  import { BCard, BLink, BCardText, BCardTitle, BFormGroup, BFormInput, BForm, BButton } from "bootstrap-vue"
  import { avatarText } from "@core/utils/filter"
  import PlayerAccount from "./PlayerAccount.vue"
  import { ref, onUnmounted, onMounted, watch, computed } from "@vue/composition-api"
  import router from "@/router"
  import vSelect from "vue-select"
  import axios from "@axios"
  import { playerService } from "@/services"
  import i18n from "@/libs/i18n"

  import { ValidationProvider, ValidationObserver } from "vee-validate"
  import { required, email } from "@validations"
  import _ from "lodash"
  import Cleave from "vue-cleave-component"
  // eslint-disable-next-line import/no-extraneous-dependencies
  import "cleave.js/dist/addons/cleave-phone.us"
  export default {
    components: {
      BCard,
      BLink,
      BCardText,
      BCardTitle,
      BFormGroup,
      BFormInput,
      BButton,
      BForm,

      // form validation
      ValidationProvider,
      ValidationObserver,
      required,

      PlayerAccount,
      vSelect,
      Cleave,
    },
    data() {
      return {
        historyData: [],
        reportTypes: [
          i18n.t("Summary"),
          i18n.t("Performance"),
          i18n.t("Tests"),
          i18n.t("Matches"),
          i18n.t("Trainings"),
          i18n.t("Reviews"),
        ],
      }
    },
    methods: {
      onSearch(search, loading) {
        loading(true)
        this.searchPlayer(loading, search, this)
      },
      searchPlayer: _.debounce(async (loading, search, vm) => {
        await vm.getUsers(search)
        loading(false)
      }, 350),
    },
    async beforeMount() {},
    setup(props, { root }) {
      const showLoading = ref(false)
      const showCreateContactModal = ref(false)
      const contactModalRules = ref(null)
      const communicationRules = ref(null)
      const userData = ref(null)
      const positions = ref(null)
      const scouts = ref([])
      const selectedParent = ref(null)
      const selectedScout = ref(null)
      const seasonId = ref(Number(router.currentRoute.params.seasonId || router.currentRoute.params.sId))
      const teamId = ref(Number(router.currentRoute.params.id || router.currentRoute.params.tId))
      const playerId = ref(Number(router.currentRoute.params.playerId))
      const contactModalData = ref({
        contactName: null,
        contactLevel: null,
        contactPhone: null,
      })
      const formData = ref({
        reportType: null,
        subject: null,
        message: null,
        receiverType: null,
        receiverId: null,
        playerId: playerId.value,
        files: [],
      })
      const resetFormData = () => {
        formData.value = {
          reportType: null,
          subject: null,
          message: null,
          receiverType: null,
          receiverId: null,
          playerId: playerId.value,
          files: [],
        }
      }
      const file = ref(null)
      const perPage = ref(localStorage.getItem("perPage") || 25)
      const currentPage = ref(1)
      const totalItems = ref(0)
      const isSelectLoading = ref(false)

      const scoutRouteName = "team-player-share-scout"
      const parentRouteName = "team-player-share-parent"
      // scout || parent
      const routeMetaData = router.currentRoute.meta.data
      const currentRouteName = router.currentRoute.name

      const playerSer = new playerService()

      onMounted(async () => {
        showLoading.value = true
        getPositions()
        await fetchPlayer()
        if (currentRouteName == scoutRouteName) {
          await getUsers()
        }
      })

      const getPositions = async () => {
        const { data } = await axios.get("/position")
        positions.value = data
      }

      const fetchPlayer = () => {
        axios
          .get(`/player/${router.currentRoute.params.playerId}`)
          .then((response) => {
            userData.value = response.data
            showLoading.value = false
            if (currentRouteName == parentRouteName && userData.value.contacts.length == 1) {
              selectedParent.value = userData.value.contacts[0]
            }
          })
          .catch((error) => {
            console.log(error)
            showLoading.value = false
          })
      }

      const getAge = (birthDate) => {
        var birthDate = new Date(birthDate)
        var now = new Date()
        let otherDate = new Date(now.getFullYear(), now.getMonth(), now.getDate())

        var years = otherDate.getFullYear() - birthDate.getFullYear()

        if (
          otherDate.getMonth() < birthDate.getMonth() ||
          (otherDate.getMonth() == birthDate.getMonth() && otherDate.getDate() < birthDate.getDate())
        ) {
          years--
        }

        return years
      }

      const options = {
        delimiter: {
          delimiter: "·",
          blocks: [3, 3, 4],
          uppercase: true,
        },
      }

      const createContact = () => {
        contactModalRules.value.validate().then((success) => {
          if (success) {
            playerSer.postContact(contactModalData.value, playerId.value).then((res) => {
              showCreateContactModal.value = false
              userData.value.contacts.push(res.data)
              if (selectedParent.value == null) selectedParent.value = res.data
            })
          }
        })
      }

      const fileUpload = () => {
        const formData2 = new FormData()
        formData2.append("file", file.value)
        axios
          .post(`/communication/response-form/fileUpload`, formData2)
          .then((response) => {
            console.log(response)
            formData.value.files.push(response.data)
            file.value = null
          })
          .catch((error) => {
            console.log(error)
          })
      }

      const deleteFile = async (file, index) => {
        let confirm = await root.confirmModal()
        if (!confirm) return false
        axios.post(`/communication/response-form/fileRemove`, file).then((response) => {
          console.log(response)
          formData.value.files.splice(index, 1)
        })
      }

      const submitCommunicationMail = () => {
        communicationRules.value.validate().then((success) => {
          if (success) {
            showLoading.value = true
            axios
              .post(`/communication/response-form`, formData.value)
              .then((res) => {
                showCreateContactModal.value = false
                showLoading.value = false
                resetFormData()
                root.toast(i18n.t("Successfully Shared"), "UserIcon", "info")
              })
              .catch((error) => {
                showLoading.value = false
                console.log(error)
              })
          }
        })
      }

      const getUsers = (name = null) => {
        isSelectLoading.value = true
        return axios
          .get(`/users`, {
            params: {
              roleName: "scout",
              enabled: 1,
              sortBy: "id",
              isDesc: "desc",
              name: name,
              season: seasonId.value,
              team: teamId.value,
              row: perPage.value,
              page: currentPage.value,
            },
          })
          .then((res) => {
            totalItems.value = res.data.count
            scouts.value = res.data.data
            isSelectLoading.value = false
            return scouts.value
          })
          .catch((error) => {
            console.log(error)
            isSelectLoading.value = false
          })
      }

      const disabledButton = computed(() => {
        if (currentRouteName.value == scoutRouteName.value && selectedScout.value != null) {
          formData.value.receiverType = "scout"
          formData.value.receiverId = selectedScout.value.id
          return false
        }
        if (currentRouteName.value == parentRouteName.value && selectedParent.value != null) {
          formData.value.receiverType = "parent"
          formData.value.receiverId = selectedParent.value.id
          return false
        }

        return true
      })

      watch(showCreateContactModal, (val) => {
        // reset modal data
        if (!val) {
          contactModalData.value = { contactName: null, contactLevel: null, contactPhone: null }
        }
      })

      watch([currentPage], () => {
        getUsers()
      })

      return {
        options,
        avatarText,
        showLoading,
        userData,
        getAge,
        positions,
        scouts,
        disabledButton,
        formData,

        selectedParent,
        selectedScout,

        createContact,
        showCreateContactModal,
        contactModalData,
        contactModalRules,

        submitCommunicationMail,
        communicationRules,

        parentRouteName,
        scoutRouteName,
        currentRouteName,
        routeMetaData,

        totalItems,
        perPage,
        currentPage,
        isSelectLoading,
        getUsers,

        fileUpload,
        file,
        deleteFile,
      }
    },
  }
</script>

<style lang="scss">
  .tabs > [class="col-md-12 col-12 tab-title-for-player"] {
    padding-left: 0;
  }

  .player-top-bar-info,
  .player-top-bar-phy {
    .card-body {
    }
  }
</style>

<style scoped lang="scss">
  @media (min-width: 700px) {
    .topbar > .col-md-8 {
      border-left: 1px solid #ddd;
    }

    .p-0 > div {
      padding: 0;
    }
    .contact {
      border-right: 1px solid #ddd;

      &:last-child {
        border-right: 0;
      }
    }
  }
  .ovh {
    overflow: hidden;
  }
  .pagination {
    display: flex;
    margin: 0 auto;
    width: fit-content;
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }
</style>

<style lang="scss">
  @import "~vue-wysiwyg/dist/vueWysiwyg.css";
  @import "@core/scss/vue/libs/vue-good-table.scss";
</style>
