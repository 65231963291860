<template>
  <b-overlay variant="white" spinner-variant="primary" blur="0" opacity=".75" rounded="sm">
    <b-card no-body>
      <b-card-header class="pb-50">
        <h5>{{ $t("Filter") }}</h5>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col cols="12" md="4" class="mb-md-0 mb-2">
            <label>{{ $t("Department") }}</label>
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              v-model="department"
              :options="departments"
              class="w-100"
              :reduce="(item) => item.id"
              :getOptionLabel="(item) => item.name"
            />
          </b-col>
          <b-col cols="12" md="4" class="mb-md-0 mb-2">
            <label>{{ $t("Job") }}</label>
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              v-model="job"
              :options="jobs"
              class="w-100"
              :reduce="(item) => item.id"
              :getOptionLabel="(item) => item.name"
            />
          </b-col>
          <b-col cols="12" md="4" class="mb-md-0 mb-2">
            <label>{{ $t("Status") }}</label>
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              v-model="enabled"
              :options="enabledOptions"
              class="w-100"
              :reduce="(item) => item.value"
              :getOptionLabel="(item) => item.label"
            />
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <b-card action-collapse>
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <label>{{ $t("Show") }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                :placeholder="$t('Search')"
                type="search"
              />
              <b-button :to="`/staff_manage/add`" variant="primary">
                <span class="text-nowrap">
                  {{ $t("Add User") }}
                </span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
      <div class="mt-2 mx-n2">
        <b-table
          :fields="fields"
          :items="refetchData"
          class="position-relative"
          :empty-text="$t('No records found')"
          responsive
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          :sort-desc.sync="isSortDirDesc"
          busy.sync="false"
          :per-page="perPage"
          :current-page="currentPage"
          style="min-height: 400px"
          ref="staffTable"
        >
          <!-- Column: User -->
          <template #table-busy>
            <div class="text-center my-2">
              <b-spinner class="align-middle"></b-spinner>
            </div>
          </template>
          <!-- Column: User -->
          <template #cell(firstName)="data">
            <b-media vertical-align="center">
              <template #aside>
                <b-avatar
                  size="32"
                  :text="avatarText(data.item.firstName)"
                  :variant="`light-primary`"
                  :to="{
                    name: 'show-staff',
                    params: {
                      staffID: data.item.id,
                    },
                  }"
                />
              </template>
              <b-link
                :to="{
                  name: 'show-staff',
                  params: {
                    staffID: data.item.id,
                  },
                }"
                class="font-weight-bold d-block text-nowrap"
              >
                {{ data.item.firstName }}
              </b-link>
              <small class="text-muted">@{{ data.item.firstName }}</small>
            </b-media>
          </template>

          <!-- Column: Email -->
          <template #cell(email)="data">
            <div class="text-nowrap">
              <span class="align-text-top">{{ data.item.email }}</span>
            </div>
          </template>

          <!-- Column: Season -->
          <template #cell(season)="data">
            <div class="text-nowrap">
              <span class="align-text-top">{{ data.item.season.length > 0 ? data.item.season[0].name : "" }}</span>
            </div>
          </template>

          <!-- Column: Team -->
          <template #cell(team)="data">
            <div class="text-nowrap">
              <span class="align-text-top">{{
                data.item.team.length > 0 ? data.item.team[0].name + " " + data.item.team[0].club.name : ""
              }}</span>
            </div>
          </template>

          <!-- Column: Role -->
          <template #cell(job)="data">
            <div class="text-nowrap" v-if="data.item.job">
              <feather-icon :icon="`UserIcon`" size="18" class="mr-50" :class="`text-primary`" />
              <span class="align-text-top text-capitalize">{{ data.item.job.name }}</span>
            </div>
          </template>

          <template #cell(status)="data">
            <b-badge pill :variant="`light-${resolveUserStatusVariant(data.item.status)}`" class="text-capitalize">
              {{ data.item.status == 1 ? $t("Active") : $t("InActive") }}
            </b-badge>
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
              <template #button-content>
                <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
              </template>

              <!--<b-dropdown-item :to="{ name: 'staff-view', params: { seasonId:seasonId, id:teamId, staffId:data.item.id } }">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">{{ $t('Details') }}</span>
            </b-dropdown-item>-->

              <b-dropdown-item
                :to="{
                  name: 'edit-staff',
                  params: {
                    staffID: data.item.id,
                  },
                }"
              >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">{{ $t("Edit") }}</span>
              </b-dropdown-item>

              <b-dropdown-item @click="goDeleteStaff(data.item.id)">
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">{{ $t("Delete") }} </span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
              <span class="text-muted">{{
                $t("format.showingData", { from: dataMeta.from, to: dataMeta.to, of: dataMeta.of })
              }}</span>
            </b-col>
            <!-- Pagination -->
            <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalItems"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-card>
  </b-overlay>
</template>

<script>
  import vSelect from "vue-select"
  import i18n from "@/libs/i18n"
  import { ref, watch, onMounted, computed } from "@vue/composition-api"
  import http from "@/libs/axios"
  import { useRouter } from "@core/utils/utils"
  import TransferSidebar from "@/views/scout/player/transfer-sidebar.vue"
  import FilterList from "@/views/scout/player/filter-list.vue"
  import localService from "@/services/localService"
  import { avatarText } from "@core/utils/filter"

  export default {
    components: {
      vSelect,
      TransferSidebar,
      FilterList,
    },
    methods: {
      goDeleteStaff(staffId) {
        this.$bvModal
          .msgBoxConfirm(this.$t("Please confirm that you want to delete this."), {
            title: this.$t("Please Confirm"),
            size: "sm",
            okVariant: "primary",
            okTitle: this.$t("Yes"),
            cancelTitle: this.$t("Cancel"),
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          })
          .then((value) => {
            if (value) {
              http
                .delete(`/staff/${staffId}`)
                .then((res) => {
                  this.toast(i18n.t("Successfully Deleted"), "PlusIcon", "success")
                  this.staffTable.refresh()
                })
                .catch((error) => {
                  console.log(error)
                })
            }
          })
      },
    },
    setup(props, { root }) {
      const { router, route } = useRouter()
      const isSidebarActive = ref(false)
      const showLoading = ref(false)
      const users = ref([])
      const staffTable = ref(null)

      const form = ref({
        player: null,
        team: null,
        season: null,
        data: null,
      })

      const fields = computed({
        get: () => [
          { key: "firstName", label: i18n.t("User"), sortable: true },
          { key: "season", label: i18n.t("Season") },
          { key: "team", label: i18n.t("Team") },
          { key: "email", label: i18n.t("Email"), sortable: true },
          { key: "job", label: i18n.t("Job") },
          { key: "status", label: i18n.t("Status"), sortable: true },
          { key: "actions", label: i18n.t("Actions") },
        ],
      })

      const enabled = ref(null)
      const job = ref(null)
      const department = ref(null)
      const jobs = ref([])
      const departments = ref([])

      const enabledOptions = computed({
        get: () => [
          { label: i18n.t("Active"), value: 1 },
          { label: i18n.t("Inactive"), value: 0 },
        ],
      })

      const resolveUserStatusVariant = (enabled) => {
        if (enabled === "pending") return "warning"
        if (enabled === true) return "success"
        if (enabled === false) return "secondary"
        return "primary"
      }

      const resolveUserenabledText = (enabled) => {
        if (enabled) return i18n.t("Active")
        if (!enabled) return i18n.t("Inactive")
        return i18n.t("Pending")
      }

      const isSortDirDesc = ref(true)
      const perPage = ref(localStorage.getItem("perPage") || 10)
      const totalItems = ref(0)
      const searchQuery = ref(null)
      const currentPage = ref(1)
      const sortBy = ref("id")
      const perPageOptions = [10, 25, 50, 100]
      const staff = ref(null)
      onMounted(async () => {
        await getJobs()
        await getDepartments()

        department.value = new localService("staff_filter_dep").get() || ""
        job.value = new localService("staff_filter_job").get() || ""
        enabled.value = new localService("staff_filter_stat").get() || 1
        searchQuery.value = new localService("staff_filter_sq").get() || ""
      })

      const getJobs = () => {
        http
          .get(`/job`)
          .then((res) => {
            jobs.value = res.data
          })
          .catch((error) => console.log(error))
      }

      const getDepartments = () => {
        http
          .get(`/department`)
          .then((res) => {
            departments.value = res.data
          })
          .catch((error) => console.log(error))
      }

      const goToPlayer = (id) => {
        router.push({
          name: "scout-player-view",
          params: {
            playerId: id,
          },
        })
      }

      const goToAddPlayer = (id) => {
        router.push({
          name: "scout-player-add",
        })
      }

      const openSidebar = (player) => {
        if (player.weight && player.height) {
          isSidebarActive.value = true
          form.value.player = player.id
          form.value.data = player
        } else {
          root.toast(i18n.t("Enter Player Weight and Height"), "XIcon", "warning")
        }
      }

      const refetchData = () => {
        showLoading.value = true
        return http
          .get(`/staff-filter`, {
            params: {
              row: perPage.value,
              page: currentPage.value,
              job: job.value,
              department: department.value,
              status: enabled.value,
              query: searchQuery.value,
              sortBy: sortBy.value ? sortBy.value : "id",
              isDesc: isSortDirDesc.value,
            },
          })
          .then((res) => {
            users.value = res.data.data
            totalItems.value = res.data.count
            showLoading.value = false
            return users.value
          })
          .catch((error) => {
            showLoading.value = false
            console.log(error)
          })
      }

      const dataMeta = computed(() => {
        const localItemsCount = users.value ? (users.value.length > 0 ? users.value.length : 0) : 0
        return {
          from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
          to: perPage.value * (currentPage.value - 1) + localItemsCount,
          of: totalItems.value,
        }
      })

      watch([department, job, enabled, searchQuery], () => {
        staffTable.value.refresh()
        console.log(enabled)
        new localService("staff_filter_dep", department.value || "")
        new localService("staff_filter_job", job.value || "")
        new localService("staff_filter_stat", enabled.value || "") // false, undefined, null, true
        new localService("staff_filter_sq", searchQuery.value || "")
      })

      watch(perPage, (perPage) => {
        localStorage.setItem("perPage", perPage)
      })

      return {
        fields,
        users,
        goToPlayer,
        isSidebarActive,
        form,
        openSidebar,
        goToAddPlayer,

        department,
        departments,
        enabled,
        enabledOptions,
        job,
        jobs,
        refetchData,

        // Filter
        avatarText,
        resolveUserStatusVariant,
        resolveUserenabledText,

        isSortDirDesc,
        perPage,
        currentPage,
        searchQuery,
        sortBy,
        perPageOptions,
        dataMeta,
        totalItems,

        staff,
        showLoading,
        staffTable,
      }
    },
  }
</script>

<style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
</style>
