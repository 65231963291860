<template>
  <b-card>
    <!-- media -->
    <b-media no-body>
      <b-media-aside>
        <b-link>
          <b-avatar ref="previewEl" :src="generalData.avatar" size="90px" rounded />
        </b-link>
        <!--/ avatar -->
      </b-media-aside>

      <b-media-body class="mt-75 ml-75">
        <!-- upload button -->
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          size="sm"
          class="mb-75 mr-75"
          @click="$refs.refInputEl.$el.click()"
        >
          {{ $t("Dosya Seçiniz") }}
        </b-button>
        <b-form-file
          :browseText="$t('Browse')"
          ref="refInputEl"
          accept=".jpg, .png, .gif"
          :hidden="true"
          plain
          @input="inputImageRenderer"
          @change="onFileSelected"
        />
        <!--/ upload button -->

        <b-card-text>{{ $t("File Types") }} JPG, GIF, PNG. {{ $t("Max file size") }} 800kB</b-card-text>
      </b-media-body>
    </b-media>
    <!--/ media -->

    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <!-- form -->
      <b-form class="mt-2" @submit.prevent="handleSubmit(goSubmitGeneral())">
        <b-row>
          <b-col sm="6">
            <validation-provider #default="{ errors }" rules="required" name="userName">
              <b-form-group :label="$t('User Name')" label-for="account-username">
                <b-form-input
                  v-model="generalData.userName"
                  :placeholder="$t('User Name')"
                  name="username"
                  :state="errors[0] ? false : null"
                />

                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col sm="6">
            <validation-provider #default="{ errors }" rules="required" name="firstName">
              <b-form-group :label="$t('First Name')" label-for="account-firstName">
                <b-form-input
                  v-model="generalData.firstName"
                  name="firstName"
                  placeholder="İsim"
                  :state="errors[0] ? false : null"
                />

                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col sm="6">
            <validation-provider #default="{ errors }" rules="required|email" name="email">
              <b-form-group :label="$t('Email')" label-for="account-email">
                <b-form-input
                  v-model="generalData.email"
                  name="email"
                  placeholder="Email"
                  :state="errors[0] ? false : null"
                />

                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col sm="6">
            <validation-provider #default="{ errors }" name="team">
              <b-form-group :label="$t('Team')" label-for="account-company">
                <b-form-input
                  v-model="generalData.company"
                  name="teamName"
                  :placeholder="$t('Team Name')"
                  :state="errors[0] ? false : null"
                />

                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- alert -->
          <b-col cols="12" class="mt-75">
            <b-alert show variant="warning" class="mb-50" v-show="!(generalData.enabled || true)">
              <h4 class="alert-heading">
                {{ $t("Confirm your email account") }}
              </h4>
              <div class="alert-body">
                <b-link class="alert-link">
                  {{ $t("Resend confirmation email") }}
                </b-link>
              </div>
            </b-alert>
          </b-col>
          <!--/ alert -->

          <b-col cols="12">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mt-2 mr-1" type="submit">
              {{ $t("Save changes") }}
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
  import {
    BFormFile,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BImg,
  } from "bootstrap-vue"
  import Ripple from "vue-ripple-directive"
  import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils"
  import { ref } from "@vue/composition-api"
  import { ValidationProvider, ValidationObserver } from "vee-validate"
  import { required } from "@validations"

  export default {
    components: {
      BButton,
      BForm,
      BImg,
      BFormFile,
      BFormGroup,
      BFormInput,
      BRow,
      BCol,
      BAlert,
      BCard,
      BCardText,
      BMedia,
      BMediaAside,
      BMediaBody,
      BLink,

      // Form Validation
      ValidationProvider,
      ValidationObserver,
    },
    directives: {
      Ripple,
    },
    props: {
      generalData: {
        default: null,
      },
      updateGeneral: {
        require: true,
      },
      uploadAvatar: {
        require: true,
      },
    },
    data() {
      return {
        required,
        profileFile: null,
      }
    },
    methods: {
      goSubmitGeneral() {
        this.$refs.refFormObserver.validate().then((success) => {
          if (success) {
            this.updateGeneral(this.generalData, this.profileFile)
          }
        })
      },
      resetForm() {
        this.generalData = JSON.parse(localStorage.getItem("userData"))
        this.$refs.refInputEl.files = []
        this.profileFile = null
      },
      onFileSelected(event) {
        if (event.target.files.length > 0) {
          this.uploadAvatar(event.target.files[0])
        }
      },
    },
    setup() {
      const refInputEl = ref(null)
      const previewEl = ref(null)

      const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl)

      return {
        refInputEl,
        previewEl,
        inputImageRenderer,
      }
    },
  }
</script>
