<template>
  <b-sidebar
    id="sidebar-add-transfer-history"
    :visible="isSidebarActive"
    bg-variant="white"
    @shown="$emit('update:isSidebarActive', true)"
    @hidden="
      $emit('update:isSidebarActive', false)
      formTrigger++
    "
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
  >
    <template #default="{ hide }">
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">{{ $t("Transfer History") }}</h5>
        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver" :key="formTrigger">
        <!-- Form -->
        <b-form v-if="player" class="p-2" @submit.prevent="handleSubmit(submit())" @reset.prevent="resetForm">
          <!-- Season -->
          <validation-provider #default="{ errors }" name="season" rules="required">
            <b-form-group :label="$t('Season')" label-for="season">
              <v-select
                :placeholder="$t('Please select a season')"
                v-model="form.season"
                :state="errors.length > 0 ? false : null"
                :options="seasons"
                :reduce="(option) => option.id"
                :getOptionLabel="(option) => option.name"
              >
              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Club -->
          <validation-provider #default="{ errors }" name="club" rules="required">
            <b-form-group :label="$t('Club')" label-for="club">
              <v-select
                :placeholder="$t('Club Select')"
                v-model="form.club"
                :state="errors.length > 0 ? false : null"
                :options="clubs"
                :reduce="(option) => option.id"
                :getOptionLabel="(option) => option.name"
              >
                <template v-slot:option="option">
                  <img :src="option.logo" height="18" class="mr-1" />
                  {{ option.name }}
                </template>
              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Team -->
          <validation-provider #default="{ errors }" name="team" rules="required">
            <b-form-group :label="$t('Team')" label-for="team">
              <v-select
                :placeholder="$t('Please select a team')"
                v-model="form.team"
                :state="errors.length > 0 ? false : null"
                :options="teams"
                :reduce="(option) => option.id"
                :getOptionLabel="(option) => option.name"
              >
                <template v-slot:option="option">
                  <span v-if="option.club">
                    <img :src="option.club.logo" height="18" />
                    {{ option.club.name }}
                  </span>
                  {{ option.name }}
                </template>
              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <b-row>
            <!-- Start Date -->
            <b-col md="12" xl="6">
              <validation-provider #default="{ errors }" name="startDate" rules="required">
                <b-form-group :label="$t('Start Date')" label-for="startDate">
                  <div class="d-flex justify-content-between">
                    <flat-pickr
                      v-model="form.startDate"
                      class="form-control"
                      :config="{
                        enableTime: false,
                        locale: lang == 'fr' ? French : lang == 'tr' ? Turkish : null,
                        enableTime: false,
                        dateFormat: 'Y-m-d',
                      }"
                    />
                  </div>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- End Date -->
            <b-col md="12" xl="6">
              <validation-provider #default="{ errors }" name="endDate">
                <b-form-group :label="$t('End Date')" label-for="endDate">
                  <div class="d-flex justify-content-between">
                    <flat-pickr
                      v-model="form.endDate"
                      class="form-control"
                      :config="{
                        enableTime: false,
                        locale: lang == 'fr' ? French : lang == 'tr' ? Turkish : null,
                        enableTime: false,
                        dateFormat: 'Y-m-d',
                        maxDate: maxDate,
                      }"
                    />
                  </div>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button variant="primary" class="mr-2" type="submit">{{ $t("Add") }}</b-button>
            <b-button type="button" variant="outline-secondary" ref="hideClick" @click="hide">{{
              $t("Cancel")
            }}</b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
  import { ValidationProvider, ValidationObserver } from "vee-validate"
  import { required, alphaNum, email } from "@validations"
  import { ref, onMounted, computed, watch } from "@vue/composition-api"
  import { useRouter } from "@core/utils/utils"
  import http from "@/libs/axios"
  import i18n from "@/libs/i18n"
  import vSelect from "vue-select"
  import flatPickr from "vue-flatpickr-component"
  import { Turkish } from "flatpickr/dist/l10n/tr.js"
  import { French } from "flatpickr/dist/l10n/fr.js"

  export default {
    components: {
      ValidationProvider,
      ValidationObserver,
      vSelect,
      flatPickr,
    },
    model: {
      prop: "isSidebarActive",
      event: "update:is-add-cont-active",
    },
    props: {
      isSidebarActive: {
        type: Boolean,
        required: true,
      },
      player: {
        default: null,
      },
      form: {
        type: Object,
        default: () => {},
      },
    },
    data() {
      return {
        required,
        formTrigger: 0,
      }
    },
    watch: {
      Data: function (newData, oldData) {
        // console.log(newData)
        if (newData) {
          this.playerTransferHistoryForm = newData
        }
      },
      isSidebarActive: function (oldVal, newVal) {
        this.form.startDate = this.form.startDate || new Date().toLocaleDateString()
      },
    },
    setup(props, { emit, root }) {
      const { router, route } = useRouter()
      const blanForm = {}
      const seasons = ref([])
      const teams = ref([])
      const clubs = ref([])
      const lang = ref(i18n.locale)
      const hideClick = ref(null)
      const now = new Date()
      const maxDate = new Date(now.getFullYear(), now.getMonth(), now.getDate())

      const playerId = ref(Number(router.currentRoute.params.playerId))
      const formTrigger = ref(0)

      onMounted(async () => {
        await getSeasons()
        await getClubs()
        await getTeams()
      })

      const submit = () => {
        if (props.form.id) {
          update()
        } else {
          create()
        }
      }

      const create = (data) => {
        // TODO: ScoutPlayerTransferHistory isActive
        http
          .post(`scout/player/${playerId.value}/transfer-history`, props.form)
          .then((res) => {
            hideClick.value.click()
            root.toast(i18n.t("Successfully Created"), "PlusIcon", "success")
            // TODO: remove location reload
            location.reload()
          })
          .catch((error) => console.log(error))
      }
      const update = (data) => {
        http
          .put(`scout/player/transfer-history/${props.form.id}`, props.form)
          .then((res) => {
            hideClick.value.click()
            root.toast(i18n.t("Successfully Updated"), "PlusIcon", "success")
            // TODO: remove location reload
            location.reload()
          })
          .catch((error) => console.log(error))
      }
      const getSeasons = () => {
        http.get("/season").then((response) => {
          seasons.value = response.data
        })
      }
      const getClubs = () => {
        http.get("/club").then((response) => {
          clubs.value = response.data
        })
      }
      const getTeams = () => {
        http.get("/team").then((response) => {
          teams.value = response.data.data
        })
      }

      return {
        submit,
        update,
        required,
        formTrigger,
        seasons,
        clubs,
        teams,
        lang,
        hideClick,
        maxDate,

        Turkish,
        French,
      }
    },
  }
</script>

<style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";

  #add-new-user-sidebar {
    .vs__dropdown-menu {
      max-height: 200px !important;
    }
  }
</style>
