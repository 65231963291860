<template>
  <!-- TODO : TRANSLATE -->
  <div>
    <div class="card-header p-0 m-0 mb-2">
      <div>
        <b-card-title>
          <feather-icon icon="UserIcon" size="24" />
          {{ $t("Coach Review") }}
        </b-card-title>
      </div>
      <div class="float-right">
        <!--<b-button @click="helperFuncs.sendSerpNotifyToPlayers()" variant="primary" class="right-top">
          {{ $t('Send a Review Notice to Players') }}
        </b-button>-->
      </div>
    </div>

    <b-tabs lazy>
      <b-tab v-for="teamItem in ['homeTeam', 'awayTeam']" :key="teamItem">
        <template #title>
          <span class="d-sm-inline">
            <b-avatar :src="competitionForm[teamItem].select.club.logo" style="background: unset"></b-avatar>
            {{ competitionForm[teamItem].select.club.name }}
            {{ competitionForm[teamItem].select.name }}
          </span>
        </template>
        <div class="row">
          <div class="col-md-12">
            <add-coach-review-table
              :helper="helper"
              :helperFuncs="helperFuncs"
              :competition.sync="competition"
              :teamItem="teamItem"
              :user="user"
            />
          </div>
        </div>
      </b-tab>
    </b-tabs>
  </div>
</template>
<script>
  import { avatarText } from "@core/utils/filter"
  import AddCoachReviewTable from "./addCoachReviewTable.vue"
  export default {
    components: {
      AddCoachReviewTable,
    },
    name: "CompetitionAddCoachReview",
    props: {
      competition: null,
      helper: null,
      showSection: null,
      helperFuncs: null,
      competitionForm: null,
    },
    data() {
      return {
        isLoading: false,
      }
    },
    methods: {},
    computed: {},
    async beforeMount() {
      this.competition.formationTrigger = 0
    },
    created() {},
    setup(props, { root }) {
      const user = JSON.parse(localStorage.getItem("userData"))
      return {
        avatarText,
        user,
      }
    },
  }
</script>
<style>
  input.formationSelect {
    width: 30px;
    height: 30px;
  }

  .add-match-formation .nav-pills .nav-link.active {
    /* background: unset; */
  }
</style>
