<template>
  <b-overlay variant="white" :show="loading" spinner-variant="primary" blur="0" opacity=".75" rounded="sm">
    <b-card>
      <!-- SET CARD HEADER -->
      <div class="card-header p-0 m-0">
        <div>
          <b-card-title class="mt-n2">
            <!-- All Performance Reports -->
            {{ $t("Report log") }}
          </b-card-title>
        </div>
        <div>
          <b-button class="mb-2" type="button" variant="primary" @click="create" :disabled="creating">
            <b-spinner v-if="creating" small style="margin-bottom: 3px"></b-spinner>
            {{ $t("Add New") }}
          </b-button>
        </div>
      </div>

      <vue-good-table
        :columns="tableColumns"
        :rows="Object.values(tableItems)"
        :search-options="{
          enabled: false,
        }"
        :pagination-options="{
          enabled: false,
          perPage: pageLength,
        }"
        class="perfTable"
      >
        <template slot="table-column" slot-scope="props">
          <span
            v-b-tooltip.hover.v-primary
            :title="`${props.column.label} ${props.column.field}`"
            style="min-width: 150px !important; display: block; font-size: 12px !important"
            variant="outline-primary"
          >
            {{ props.column.label }}
          </span>
        </template>

        <template slot="table-row" slot-scope="props">
          <div class="d-flex" v-if="props.column.field == 'user'">
            <p v-text="props.row.user.firstName + ` ` + props.row.user.lastName.substr(0, 1) + `.`"></p>
          </div>
          <div class="d-flex" v-if="props.column.field == 'date'">
            <b-link
              :to="{
                name: 'team-perfs-rating',
                params: {
                  id: teamId,
                  performanceId: props.row.id,
                },
              }"
            >
              {{ props.row.date | nativeDate }}
            </b-link>
          </div>
          <div class="d-flex" v-if="props.column.field == 'physical'">
            <p v-b-tooltip.hover :title="$t('Performance Average')">
              {{ getAvg(props.row.physical) }}
            </p>
          </div>
          <div class="d-flex" v-if="props.column.field == 'mental'">
            <p v-b-tooltip.hover :title="$t('Performance Average')">
              {{ getAvg(props.row.mental) }}
            </p>
          </div>
          <div class="d-flex" v-if="props.column.field == 'tasteful'">
            <p v-b-tooltip.hover :title="$t('Performance Average')">
              {{ getAvg(props.row.tasteful) }}
            </p>
          </div>
          <div class="d-flex" v-if="props.column.field == 'technical'">
            <p v-b-tooltip.hover :title="$t('Performance Average')">
              {{ getAvg(props.row.technical) }}
            </p>
          </div>
          <div class="d-flex" v-if="props.column.field == 'technicalGoalKeeper'">
            <p v-b-tooltip.hover :title="$t('Performance Average')">
              {{ getAvg(props.row.technicalGoalKeeper) }}
            </p>
          </div>
          <div class="d-flex" v-if="props.column.field == 'tactical'">
            <p v-b-tooltip.hover :title="$t('Performance Average')">
              {{ getAvg(props.row.tactical) }}
            </p>
          </div>
          <div class="d-flex" v-if="props.column.field == 'avg'">
            <p v-b-tooltip.hover :title="$t('Performance Average')">
              <b-form-rating
                variant="warning"
                precision="3"
                no-border
                style="padding: 0; margin: 0; margin-top: 0.7rem; height: 0"
                size="md"
                readonly
                :stars="getSetting('star')"
                :value="getAllAvg(props.row)"
              ></b-form-rating>
            </p>
          </div>
        </template>

        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap">
                {{ $i18n.t("Showing") }}
              </span>
              <b-form-select
                v-model="pageLength"
                :options="['3', '5', '10']"
                class="mx-1"
                @input="(value) => props.perPageChanged({ currentPerPage: value })"
              />
              <span class="text-nowrap"> {{ props.total }} {{ $i18n.t("Has records") }} </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card>
  </b-overlay>
</template>

<script>
  import useTeamPerformancePlugin from "./useTeamPerformancePlugin"
  import { VueGoodTable } from "vue-good-table"
  import { useRouter } from "@core/utils/utils"
  import axios from "@axios"
  import i18n from "@/libs/i18n"

  import { ref, reactive, watch, onBeforeMount, onMounted } from "@vue/composition-api"

  export default {
    components: {
      VueGoodTable,
    },
    setup(props, { root }) {
      onMounted(async () => {
        await fetchData()
      })

      const { router, route } = useRouter()

      const loading = ref(false)
      const creating = ref(false)
      const tableItems = ref([])
      const tableColumns = [
        {
          field: "date",
          label: i18n.t("Date"),
          width: "170px",
          filterOptions: {
            enabled: false,
            placeholder: `${i18n.t("Search")} `,
          },
        },
        {
          field: "user",
          label: i18n.t("Reviewer"),
          filterOptions: {
            enabled: false,
            placeholder: `${i18n.t("Search")} `,
          },
        },

        {
          field: "physical",
          label: i18n.t("Physical"),
          filterOptions: {
            enabled: false,
            placeholder: `${i18n.t("Search")} `,
          },
        },
        {
          field: "mental",
          label: i18n.t("Mental"),
          filterOptions: {
            enabled: false,
            placeholder: `${i18n.t("Search")} `,
          },
        },
        {
          field: "tasteful",
          label: i18n.t("Tasteful"),
          filterOptions: {
            enabled: false,
            placeholder: `${i18n.t("Search")} `,
          },
        },
        {
          field: "technical",
          label: i18n.t("Technical"),
          filterOptions: {
            enabled: false,
            placeholder: `${i18n.t("Search")} `,
          },
        },
        {
          field: "technicalGoalKeeper",
          label: i18n.t("Technical Goal Keeper"),
          filterOptions: {
            enabled: false,
            placeholder: `${i18n.t("Search")} `,
          },
        },
        {
          field: "tactical",
          label: i18n.t("Tactical"),
          filterOptions: {
            enabled: false,
            placeholder: `${i18n.t("Search")} `,
          },
        },
        {
          field: "avg",
          label: i18n.t("Performance Average"),
          filterOptions: {
            enabled: false,
            placeholder: `${i18n.t("Search")} `,
          },
        },
      ]
      const pageLength = ref(10)
      const seasonId = ref(Number(router.currentRoute.params.seasonId))
      const teamId = ref(Number(router.currentRoute.params.id))

      const create = async () => {
        creating.value = true
        let nowDate = new Date().toLocaleDateString("en", {
          timeZone: "Europe/Istanbul",
          hour12: false,
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        })
        let performance = (
          await axios.get(`/season/${seasonId.value}/performance/team/${teamId.value}?date=${nowDate}`)
        ).data
        if (performance.length == 0) {
          await axios
            .post(`/season/${seasonId.value}/performance`, {
              team: teamId.value,
            })
            .then((response) => {
              router.push({
                name: "team-perfs-rating",
                params: {
                  id: teamId.value,
                  performanceId: response.data.id,
                },
              })
            })
            .catch(() => {})
        } else {
          router.push({
            name: "team-perfs-rating",
            params: {
              id: teamId.value,
              performanceId: performance[0].id,
            },
          })
        }
        creating.value = false
      }

      const fetchData = () => {
        loading.value = true
        axios
          .get(`/season/${seasonId.value}/performance/rate/${teamId.value}`)
          .then((response) => {
            tableItems.value = Object.values(response.data).sort(function (a, b) {
              return new Date(b.date) - new Date(a.date)
            })

            loading.value = false
          })
          .catch(() => {
            loading.value = false
          })
      }

      const round = (value, step) => {
        step || (step = 1.0)
        var inv = 1.0 / step
        return Math.round(value * inv) / inv
      }
      return {
        tableItems,
        tableColumns,
        create,
        pageLength,
        loading,
        teamId,
        creating,
      }
    },
  }
</script>
<style lang="scss">
  @import "@core/scss/vue/libs/vue-good-table.scss";
</style>
