<template>
  <div>
    <b-card no-body>
      <b-card-header class="pb-50">
        <h5>{{ $t("Filter") }}</h5>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col cols="12" md="3" class="mb-md-0 mb-2">
            <label>{{ $t("League") }}</label>
            <v-select
              v-model="league"
              :options="leagues"
              class="w-100"
              :reduce="(item) => item.id"
              :getOptionLabel="(item) => item.name"
            />
          </b-col>
          <b-col cols="12" md="3" class="mb-md-0 mb-2">
            <label>{{ $t("Team") }}</label>
            <v-select
              v-model="team"
              :options="teams"
              class="w-100"
              :reduce="(item) => item.id"
              :getOptionLabel="(item) => item.clubName + ' / ' + item.name"
            >
              <template v-slot:option="item">
                <span>
                  <img :src="item.clubLogo" height="18" />
                  {{ item.clubName }}
                </span>
                {{ item.name }}
              </template>
            </v-select>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <b-card v-if="$can('read', 'Competition')" style="min-height: 80vh">
      <b-card-title> {{ $t("Competitions History") }} </b-card-title>
      <div class="d-block">
        <div class="float-left">
          <label>{{ $t("Show") }}</label>
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block mx-50"
          />
        </div>
        <div class="float-right">
          <b-button :to="{ name: 'competition-add', params: { id: teamId } }" variant="primary" class="right-top">{{
            $t("Add")
          }}</b-button>
        </div>
      </div>
      <b-overlay class="" variant="white" :show="loaded == false" spinner-variant="primary" opacity="0.75" rounded="sm">
        <div class="mx-n2">
          <b-table
            show-empty
            :empty-text="$t('Data not found')"
            striped
            class="border matchHistory"
            responsive
            :fields="matchHistoryFields"
            :items="getCompetitions"
            sticky-header="70vh"
            :per-page="perPage"
            :current-page="currentPage"
            :sort-by.sync="sortBy"
            :sort-desc.sync="isSortDirDesc"
            no-sort-reset="true"
            ref="refTeamMatches"
          >
            <template #cell(match)="data">
              <competition-card :data="data.item" />
            </template>
            <template #head(avgCoachReview)="scope">
              <div v-b-tooltip.hover :title="$t('Performances Score')">P. Score</div>
            </template>
            <template #cell(sure)="data">
              <span @click="goToMatch(data.item)">
                {{ data.value }}
                <feather-icon icon="ClockIcon" size="24" scale="1" />
              </span>
            </template>
            <template #cell(league)="data">
              <div class="d-flex justify-content-start">
                <div class="h5" style="line-height: 1.5">
                  <b>{{ data.value.name }}</b>
                </div>
              </div>
            </template>
            <template #cell(date)="data">
              <div class="h6" @click="goToMatch(data.item)">
                <b> {{ data.value | nativeDate }} </b>
                <br />
                {{ new Date(data.value).toLocaleTimeString($i18n.locale, { hour: "numeric", minute: "numeric" }) }}
              </div>
            </template>
            <template #cell(location)="data">
              <div class="stad" @click="goToMatch(data.item)">
                <span class="small">{{ data.value.name }}</span>
              </div>
            </template>
            <template #cell(Datetime)="data">
              <div class="d-flex justify-content-start" @click="goToMatch(data.item)">
                <div class="small ml-1" v-html="getBr(data.value) + ' 14:00'"></div>
              </div>
            </template>
            <template #cell(action)="data">
              <b-link class="text-dark ml-1" v-if="$can('manage', 'Competition')">
                <feather-icon
                  v-b-tooltip.hover
                  @click="removeItem(data.item.id)"
                  :title="$t('Delete')"
                  size="24"
                  icon="TrashIcon"
                />
              </b-link>
            </template>
          </b-table>

          <div class="mx-2 mb-2">
            <b-row>
              <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
                <span class="text-muted">{{
                  $t("format.showingData", { from: dataMeta.from, to: dataMeta.to, of: dataMeta.of })
                }}</span>
              </b-col>
              <!-- Pagination -->
              <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalItems"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-overlay>
    </b-card>
  </div>
</template>
<script>
  import vSelect from "vue-select"
  import { onMounted, onUnmounted, computed, ref, watch } from "@vue/composition-api"
  import store from "@/store"
  import useMatchList from "./useMatchList"
  import TableMatchHistory from "@core/components/global-components/TableMatchHistory.vue"
  import CompetitionCard from "@core/components/global-components/CompetitionCard"
  import matchStoreModule from "./matchStoreModule"
  import competitionService from "@/services/competitionService"
  import router from "@/router"
  import i18n from "@/libs/i18n"
  import http from "@/libs/axios"
  import { localService, leagueService } from "@/services"

  export default {
    components: {
      TableMatchHistory,
      vSelect,
      CompetitionCard,
    },
    data() {
      return {}
    },
    created() {},
    methods: {
      getDate(date) {
        date = new Date(date).toLocaleDateString()
        return date
      },
      getTime(date) {
        date = new Date(date).toLocaleTimeString()
        return date
      },
      getBr(el) {
        let dayName = el.split(" ").pop()
        el = el.split(" ")
        delete el[el.length - 1]
        el = el.join(" ")
        return el + "<br>" + dayName + ""
      },
      goToMatch(compId) {
        this.$router.push({
          name: "competition-edit",
          params: {
            id: this.routerParams.id,
            competitionId: compId.id,
          },
        })
      },
      async removeItem(id, index) {
        let confirm = await this.confirmModal()
        if (!confirm) return false
        this.removeCompetition(id)
      },
    },
    setup(props, { root }) {
      const STORE_MODULE_NAME = "app-match"
      const leagueSer = new leagueService()

      // Register module
      if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, matchStoreModule)

      // UnRegister on leave
      onUnmounted(() => {
        if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
      })
      onMounted(async () => {
        await getTeams()
        if (new localService("competition_filter_team").get())
          team.value = new localService("competition_filter_team").get()
        if (new localService("competition_filter_league").get())
          league.value = new localService("competition_filter_league").get()
        await getLeagues()
      })

      const matchHistory = ref([])
      const loaded = ref(false)

      const perPage = ref(localStorage.getItem("perPage") || 10)
      const totalItems = ref(0)
      const currentPage = ref(1)
      const perPageOptions = [10, 25, 50, 100]
      const teams = ref([])
      const team = ref(null)
      const leagues = ref([])
      const league = ref(null)
      const refTeamMatches = ref(null)
      const isSortDirDesc = ref(true)
      const sortBy = ref("date")

      const dataMeta = computed(() => {
        const localItemsCount = matchHistory.value.length > 0 ? matchHistory.value.length : 0
        return {
          from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
          to: perPage.value * (currentPage.value - 1) + localItemsCount,
          of: totalItems.value,
        }
      })

      const getLeagues = () => {
        leagueSer
          .get()
          .then((res) => {
            leagues.value = res.data
          })
          .catch((error) => console.log(error))
      }

      const getTeams = () => {
        http
          .get(`/season/${router.currentRoute.params.seasonId}/team/${router.currentRoute.params.id}/competition/teams`)
          .then((res) => {
            teams.value = res.data
          })
          .catch((error) => console.log(error))
      }

      let service = new competitionService()
      const getCompetitions = () => {
        loaded.value = false

        return service
          .findCompetitionByTeam(router.currentRoute.params.seasonId, router.currentRoute.params.id, {
            row: perPage.value,
            page: currentPage.value,
            team2: team.value,
            league: league.value,
            sortBy: sortBy.value,
            isDesc: isSortDirDesc.value,
          })
          .then((data) => {
            matchHistory.value = data.data
            totalItems.value = data.count
            loaded.value = true

            return matchHistory.value
          })
          .catch((err) => {
            console.log(err)
            loaded.value = true
          })
      }

      const removeCompetition = (id) => {
        service.delete(id).then((data) => {
          root.toast(i18n.t("Successfully Deleted"), "PlusIcon", "success")
          refFetchData()
        })
      }

      const refFetchData = () => {
        refTeamMatches.value.refresh()
      }

      watch([team, league], () => {
        refFetchData()
        new localService("competition_filter_team", team.value)
        new localService("competition_filter_league", league.value)
      })

      const { tableColumns, teamId } = useMatchList()

      const matchHistoryFields = computed({
        get: () => [
          {
            key: "league",
            label: i18n.t("League"),
            sortable: false,
            thStyle: { "min-width": "11rem" },
          },
          {
            key: "date",
            label: i18n.t("Date"),
            sortable: true,
            thStyle: { "min-width": "13rem" },
          },
          {
            key: "Match",
            label: i18n.t("Match"),
            sortable: false,
            thStyle: { "min-width": "9rem" },
          },
          {
            key: "location",
            label: i18n.t("Location"),
            sortable: false,
            thStyle: { "min-width": "15rem" },
          },
          /*{
          key: "avgPlayersSerpScore",
          label: i18n.t("sERP Score"),
          sortable: false,
          thStyle: { "min-width": "9rem" },
          formatter: value => value.toFixed(1)
        },*/
          {
            key: "avgCoachReview",
            label: i18n.t("Coach Review"),
            sortable: false,
            thStyle: { "min-width": "9rem" },
            formatter: (value) => value.toFixed(1),
          },
          {
            key: "action",
            label: i18n.t("Action"),
            sortable: false,
            thStyle: { "min-width": "5rem" },
          },
        ],
      })

      watch(perPage, (perPage) => {
        localStorage.setItem("perPage", perPage)
      })

      return {
        tableColumns,
        perPage,
        currentPage,
        totalItems,
        dataMeta,
        perPageOptions,
        teamId,
        matchHistoryFields,
        matchHistory,
        loaded,
        getCompetitions,
        teams,
        team,
        refTeamMatches,
        removeCompetition,
        isSortDirDesc,
        sortBy,
        leagues,
        league,
      }
    },
  }
</script>

<style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }

  .right-top {
    position: absolute;
    right: 2rem;
    top: 1rem;
  }

  .invoice-filter-select {
    min-width: 190px;

    ::v-deep .vs__selected-options {
      flex-wrap: nowrap;
    }

    ::v-deep .vs__selected {
      width: 100px;
    }
  }
  .table-match-history {
    margin: -1.5rem;
    margin-bottom: 1rem;
  }
</style>

<style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
  .table-match-history {
    tr {
      cursor: pointer;
      transition: all 300ms;
      outline: 0;

      th,
      td {
        white-space: nowrap;
        width: 1%;
      }

      &:hover {
        background: #eee;
      }
    }

    .stad,
    .teamText {
      white-space: nowrap;
      width: 6rem;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .teamText {
      width: 8rem;
    }
  }
</style>
