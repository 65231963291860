<template>
  <b-overlay :show="loading" rounded="sm">
    <div>
      <b-card class="w-100">
        <b-row v-if="team">
          <b-col lg="11">
            <div v-if="!showEditForm" class="d-flex h-100 align-items-center">
              <div class="custom-header-text d-flex align-items-center" style="column-gap: 60px">
                <span
                  ><span style="font-size: smaller">{{ seasonName }}</span> <strong>{{ testTitle }}</strong></span
                >
                <span
                  ><span style="font-size: smaller">{{ $t("Testing") }} {{ $t("Date") }}</span> :
                  <strong>{{ getDate(date) }}</strong></span
                >
              </div>
            </div>
            <div v-if="showEditForm" class="d-flex">
              <div
                class="custom-header-text d-flex align-items-center w-100"
                :class="{
                  'flex-column':
                    $store.getters['app/currentBreakPoint'] === 'xs' ||
                    $store.getters['app/currentBreakPoint'] === 'sm',
                }"
              >
                <span>
                  {{ seasonName }}
                </span>

                <b-col md="5">
                  <b-input v-model="testTitle" :placeholder="$t('Test Title')" />
                </b-col>

                <b-col md="4">
                  <b-form-datepicker
                    v-model="date"
                    class="w-100"
                    :placeholder="$t('Training Date')"
                    :obj="date"
                    :locale="lang"
                    :date-disabled-fn="dateDisabled"
                    :max="maxDate"
                  />
                </b-col>
              </div>
            </div>
          </b-col>
          <b-col lg="1" sm="12" :class="$store.getters['app/currentBreakPoint'] === 'xs' ? 'my-2' : null">
            <div class="d-flex align-items-end">
              <span v-if="!showEditForm" class="btn btn-primary w-100" @click="showEditForm = true">
                <i class="code-toggler feather icon-edit cursor-pointer" />
              </span>
              <span
                v-if="showEditForm"
                :class="$store.getters['app/currentBreakPoint'] === 'xs' ? 'mt-n2' : null"
                class="btn btn-primary w-100"
                @click.prevent="updateTestDate()"
              >
                <i class="code-toggler feather icon-save cursor-pointer" />
              </span>
            </div>
          </b-col>
          <b-col v-if="$store.getters['app/currentBreakPoint'] === 'xs'" lg="6">
            <b-form-group>
              <v-select
                v-model="category"
                :autocomplete="`on`"
                :options="categories"
                :reduce="(option) => option.slug"
                :get-option-label="(option) => $t(option.name)"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>
      <b-row>
        <b-col lg="8" sm="12" order="2" order-md="1">
          <vue-good-table
            ref="table"
            :columns="fields"
            :rows="items"
            :is-loading.sync="loading"
            max-height="750px"
            :search-options="{
              enabled: false,
            }"
            :pagination-options="{
              enabled: false,
              perPage: pageLength,
            }"
          >
            <template slot="table-row" slot-scope="props">
              <div v-if="props.column.field == 'player'">
                <b-link
                  :to="{
                    name: 'team-player',
                    params: {
                      id: routerParams.id,
                      playerId: props.row.id,
                    },
                  }"
                >
                  <div class="d-flex flex-column align-items-start mr-n1">
                    <div class="d-flex align-items-start">
                      <b-avatar :src="props.row.avatar" size="35px" />
                      <div class="d-block ml-1">
                        <p class="small mt-0 text-left">
                          {{ props.row.name }}
                        </p>
                        <p class="mt-n1 text-left">
                          <b-badge pill :variant="props.row.position.color">
                            {{ $t(props.row.position.name) }}
                          </b-badge>
                        </p>
                      </div>
                    </div>
                  </div>
                </b-link>
              </div>

              <div v-else-if="props.column.field == 'value'">
                <div>
                  <data-input
                    :date-changed="dateChanged"
                    :date="date"
                    :player="props.row"
                    :category="category"
                    :title="testTitle"
                    @setLoading="setLoading"
                    :input-type="getType(category)"
                    :key="reloadKey"
                  />
                </div>
                <p v-show="false">
                  {{ (props.column.label = $t(getLabel(category).name)) }}
                </p>
              </div>
              <div v-else>
                {{ props.formattedRow[props.column.field] }}
              </div>
            </template>

            <template slot="pagination-bottom" slot-scope="props">
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap">
                    {{ $t("Showing") }}
                  </span>
                  <b-form-select
                    v-model="pageLength"
                    :options="['3', '5', '10']"
                    class="mx-1"
                    @input="(value) => props.perPageChanged({ currentPerPage: value })"
                  />
                  <span class="text-nowrap"> {{ props.total }} {{ $t("Has records") }} </span>
                </div>
                <div>
                  <b-pagination
                    :value="1"
                    :total-rows="props.total"
                    :per-page="pageLength"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @input="(value) => props.pageChanged({ currentPage: value })"
                  >
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18" />
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18" />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
        </b-col>

        <b-col v-if="$store.getters['app/currentBreakPoint'] !== 'xs'" lg="4" sm="12" order="1" order-md="1">
          <b-card>
            <span class="h4 my-2">{{ $t("Categories") }}</span>
            <b-form-radio-group
              v-model="category"
              class="mb-0 mt-2"
              :options="usedCategories"
              value-field="slug"
              text-field="name"
              stacked
              name="radio-options"
            />
            <hr v-if="usedCategories.length > 0" class="mt-0 mb-2" />
            <b-form-radio-group
              v-model="category"
              :options="unUsedCategories"
              value-field="slug"
              text-field="name"
              stacked
              name="radio-options"
            />
          </b-card>
        </b-col>
      </b-row>
    </div>
  </b-overlay>
</template>

<script>
  import { useRouter } from "@core/utils/utils"
  import i18n from "@/libs/i18n"
  import vSelect from "vue-select"
  import { VueGoodTable } from "vue-good-table"
  import { useToast } from "vue-toastification/composition"
  import store from "@/store"
  import testStoreModule from "./testStoreModule"
  import { onMounted, ref, watch } from "@vue/composition-api"
  import flatPickr from "vue-flatpickr-component"
  import axios from "@axios"
  import DataInput from "./Input"
  import { testService } from "@/services"

  export default {
    components: {
      vSelect,
      VueGoodTable,
      DataInput,
      flatPickr,
    },
    setup(props, { emit, root }) {
      const { router, route } = useRouter()
      const toast = useToast()

      const reloadKey = ref(0)

      const STORE_MODULE_NAME = "team-test"

      // Register module
      if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, testStoreModule)

      // onUnmounted(() => {
      //   if (store.hasModule(STORE_MODULE_NAME))
      //     store.unregisterModule(STORE_MODULE_NAME);
      // });

      const getFormattedDate = (date) => {
        const tempMonth = date.getMonth() + 1
        const month = tempMonth < 10 ? `0${tempMonth.toString()}` : tempMonth
        const day = date.getDate()
        const year = date.getFullYear()
        return `${year}-${month}-${day}`
      }

      onMounted(async () => {
        await getCategories()
        await getPlayers()
        await getDailyData()
        fetchTests()
      })

      const now = new Date()
      const maxDate = new Date(now.getFullYear(), now.getMonth(), now.getDate())
      const date = ref(router.currentRoute.params.date ?? getFormattedDate(new Date()))
      const lang = ref(i18n.locale)
      const testTitle = ref(null)

      const testSer = new testService()
      const tests = ref([])
      const seasonId = ref(Number(router.currentRoute.params.seasonId))
      const seasonName = ref(
        JSON.parse(localStorage.getItem("seasons")).find((season) => season.id === seasonId.value).name,
      )
      const teamId = ref(Number(router.currentRoute.params.id))
      const category = ref()
      const categories = ref([])
      const usedCategories = ref([])
      const unUsedCategories = ref([])
      const table = ref(null)
      const items = ref([])
      const dateChanged = ref(false)
      const team = ref(null)
      const loading = ref(false)
      const setData = ref(false)
      const showEditForm = ref(router?.currentRoute?.name === "team-tests-enter" ? true : false)
      const showLogs = ref(false)
      const pageLength = ref(10)
      const fields = ref([
        {
          field: "player",
          label: i18n.t("Player"),
          sortable: false,
          width: "250px",
        },
        {
          field: "value",
          label: i18n.t("Value"),
          sortable: false,
          width: "300px",
          hidden: false,
          thClass: "text-center",
        },
      ])

      const config = ref({
        dateFormat: "Y-m-d",
        enable: [new Date()],
        maxDate: new Date(),
        locale: i18n.locale, // locale for this instance only
      })
      const dataDays = ref([])
      const pickerEvents = ["onDayCreate"]

      const getPlayers = async () => {
        setLoading(true, "Fetching Data...")
        axios
          .get(`season/${router.currentRoute.params.seasonId}/team/${router.currentRoute.params.id}`)
          .then((response) => {
            items.value = response.data.players
            team.value = response.data
            // fetchTests();
            setLoading(false, "Fetched Data...")
          })
      }

      const getCategories = async () => {
        await axios.get("test-item").then((response) => {
          categories.value = response.data
          // comment move to end
          let commentIndex = categories.value
            .map(function (e) {
              return e.slug
            })
            .indexOf("comment")
          if (commentIndex > -1) categories.value.push(categories.value.splice(commentIndex, 1)[0])
        })
      }

      const setLoading = (value, text = null) => {
        loading.value = value
        if (showLogs.value === true && text !== null) {
          console.log(text)
        }
      }

      const onDayCreate = (dObj, dStr, fp, dayElem) => {
        if (dataDays.value[getFormattedDate(dayElem.dateObj)]) {
        }
      }

      const fetchTests = async () => {
        testSer
          .groupByDate(seasonId.value, teamId.value)
          .then((response) => {
            tests.value = response.data
            testTitle.value = tests.value[date.value]?.title
          })
          .catch((error) => {
            console.log(error)
          })
      }

      const dateDisabled = (ymd, date) => {
        return Object.keys(tests.value) && Object.keys(tests.value).includes(ymd) ? true : false
      }

      const updateTestDate = async () => {
        if (router.currentRoute.params.date != null) {
          testSer
            .updateDate(seasonId.value, teamId.value, router.currentRoute.params.date, {
              date: date.value,
              title: testTitle.value,
            })
            .then((response) => {
              router.push({
                name: router.currentRoute.name,
                params: {
                  ...router.currentRoute.params,
                  date: date.value,
                },
              })
            })
            .catch((error) => {
              console.log(error)
              return false
            })
        }
        showEditForm.value = false
      }

      watch(category, (val) => {
        setLoading(true, "wait...")
        scrollUp()
        setTimeout(() => {
          setLoading(false, "wait...")
        }, 500)
        reloadKey.value++
      })

      const scrollUp = () => {
        const scrollEl = document.getElementsByClassName("vgt-responsive")[0]
        scrollEl.scrollTo({
          top: 0,
          behavior: "smooth",
        })
      }

      const getLabel = (name) => categories.value.find((item) => item.slug === name)
      const getType = (name) => categories.value.find((item) => item.slug === name)?.type

      const getDailyData = () => {
        store
          .dispatch("team-test/fetch", {
            id: parseInt(router.currentRoute.params.id),
            date: date.value,
          })
          .then((response) => {
            const responseCategoryNames = [...new Set(response.data.map((item) => item.name))]
            // categories slug include responseCategoryNames  set used categories
            usedCategories.value = categories.value.filter((c) => responseCategoryNames.includes(c.slug))
            // remove used categories from categories
            unUsedCategories.value = categories.value.filter(
              (c) => !usedCategories.value.map((item) => item.slug).includes(c.slug),
            )

            if (category.value == null) {
              if (usedCategories.value.length > 0) {
                category.value = usedCategories.value[0].slug
              } else if (unUsedCategories.value.length > 0) {
                category.value = unUsedCategories.value[0].slug
              }
            }

            if (response.data.length > 0) {
              dateChanged.value = !dateChanged.value
            } else {
              dateChanged.value = !dateChanged.value
            }
          })
      }

      const getDate = (date) => {
        date = new Date(date)
        const month = date.getMonth() + 1
        const day = date.getDate()
        const year = date.getFullYear()
        return `${day}/${month}/${year}`
      }

      return {
        table,
        fields,
        dateChanged,
        items,
        category,
        categories,
        usedCategories,
        unUsedCategories,
        team,
        loading,
        pageLength,
        seasonName,
        testTitle,
        getLabel,
        getType,
        date,
        maxDate,
        getDate,
        showEditForm,
        setData,
        setLoading,
        config,
        pickerEvents,
        onDayCreate,
        lang,
        reloadKey,
        dateDisabled,
        updateTestDate,
      }
    },
  }
</script>

<style>
  .custom-header-text {
    margin-right: 5px;
    font-size: 18px;
    max-width: 1035px;
  }

  @media (max-width: 767px) {
    .custom-header-text {
      gap: 10px;
      margin-bottom: 10px;
    }
  }

  .vgt-left-align {
    @media (max-width: 767px) {
      max-width: 120px;
    }
  }
  .custom-radio {
    font-size: 28px;
  }
  .custom-control-label {
    font-size: 28px;
  }
</style>
<style lang="scss">
  @import "@core/scss/vue/libs/vue-good-table.scss";
  @import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
