<template>
  <div>
    <div style="height: inherit" class="todo-task-list-wrapper">
      <div
        class="body-content-overlay"
        :class="{ show: mqShallShowLeftSidebar }"
        @click="mqShallShowLeftSidebar = false"
      />

      <div class="todo-app-list">
        <!-- Todo List -->
        <vue-perfect-scrollbar
          style="height: 80vh"
          :settings="perfectScrollbarSettings"
          class="todo-task-list-wrapper list-group scroll-area"
        >
          <draggable v-model="tasks" handle=".draggable-task-handle" tag="ul" class="todo-task-list media-list">
            <li
              v-for="task in tasks"
              :key="task.id"
              class="todo-item"
              :class="{ completed: task.isCompleted }"
              @click="handleTaskClick(task)"
            >
              <feather-icon icon="MoreVerticalIcon" class="draggable-task-handle d-inline" />
              <div class="todo-title-wrapper">
                <div class="todo-title-area">
                  <div class="title-wrapper">
                    <span class="todo-title">{{ task.title }}</span>
                  </div>
                </div>
                <div class="todo-item-action">
                  <div class="badge-wrapper mr-1">
                    <b-badge
                      v-for="tag in task.tags"
                      :key="tag"
                      pill
                      :variant="`light-${resolveTagVariant(tag)}`"
                      class="text-capitalize"
                      >{{ tag }}</b-badge
                    >
                  </div>
                  <small class="text-nowrap text-muted mr-1">{{
                    formatDate(task.dueDate, { month: "short", day: "numeric" })
                  }}</small>
                  <b-avatar
                    v-if="task.assignee"
                    size="32"
                    :src="task.assignee.avatar"
                    :variant="`light-${resolveAvatarVariant(task.tags)}`"
                    :text="avatarText(task.assignee.fullName)"
                  />
                  <b-avatar v-else size="32" variant="light-secondary">
                    <feather-icon icon="UserIcon" size="16" />
                  </b-avatar>
                </div>
              </div>
            </li>
          </draggable>
          <div class="no-results" v-show="!tasks.length" :class="{ show: !tasks.length }">
            <h5>{{ $t("Event Not Found") }}</h5>
          </div>
        </vue-perfect-scrollbar>
      </div>

      <!-- Task Handler -->
      <todo-task-handler-sidebar
        v-model="isTaskHandlerSidebarActive"
        :task="task"
        :clear-task-data="clearTaskData"
        @remove-task="removeTask"
        @add-task="addTask"
        @update-task="updateTask"
      />

      <!-- Sidebar -->
      <portal to="content-renderer-sidebar-left">
        <todo-left-sidebar
          :is-task-handler-sidebar-active.sync="isTaskHandlerSidebarActive"
          :class="{ show: mqShallShowLeftSidebar }"
          @close-left-sidebar="mqShallShowLeftSidebar = false"
          :selectedTags.sync="selectedTags"
        />
      </portal>
    </div>
  </div>
</template>

<script>
  import store from "@/store"
  import { ref, watch, computed, onUnmounted } from "@vue/composition-api"
  import VuePerfectScrollbar from "vue-perfect-scrollbar"
  import draggable from "vuedraggable"
  import { formatDate, avatarText } from "@core/utils/filter"
  import { useRouter } from "@core/utils/utils"
  import { useResponsiveAppLeftSidebarVisibility } from "@core/comp-functions/ui/app"
  import TodoLeftSidebar from "./TodoLeftSidebar.vue"
  import todoStoreModule from "./todoStoreModule"
  import TodoTaskHandlerSidebar from "./TodoTaskHandlerSidebar.vue"
  import axios from "@/libs/axios"

  export default {
    components: {
      draggable,
      VuePerfectScrollbar,

      // App SFC
      TodoLeftSidebar,
      TodoTaskHandlerSidebar,
    },
    setup() {
      const TODO_APP_STORE_MODULE_NAME = "app-todo"

      // Register module
      if (!store.hasModule(TODO_APP_STORE_MODULE_NAME))
        store.registerModule(TODO_APP_STORE_MODULE_NAME, todoStoreModule)

      // UnRegister on leave
      onUnmounted(() => {
        if (store.hasModule(TODO_APP_STORE_MODULE_NAME)) store.unregisterModule(TODO_APP_STORE_MODULE_NAME)
      })

      const { route, router } = useRouter()
      const routeSortBy = computed(() => route.value.query.sort)
      const routeQuery = computed(() => route.value.query.q)
      const routeParams = computed(() => route.value.params)
      watch(routeParams, () => {
        // eslint-disable-next-line no-use-before-define
        fetchTasks()
      })

      const selectedTags = ref("wait")
      watch(selectedTags, (val) => {
        fetchTasks()
      })

      const tasks = ref([])

      const sortOptions = ["latest", "title-asc", "title-desc", "assignee", "due-date"]
      const sortBy = ref(routeSortBy.value)
      watch(routeSortBy, (val) => {
        if (sortOptions.includes(val)) sortBy.value = val
        else sortBy.value = val
      })
      const resetSortAndNavigate = () => {
        const currentRouteQuery = JSON.parse(JSON.stringify(route.value.query))

        delete currentRouteQuery.sort

        router.replace({ name: route.name, query: currentRouteQuery }).catch(() => {})
      }

      const blankTask = {
        id: null,
        title: "",
        dueDate: new Date(),
        description: "",
        assignee: null,
        tags: [],
        isCompleted: false,
        isDeleted: false,
        isImportant: false,
      }
      const task = ref(JSON.parse(JSON.stringify(blankTask)))
      const clearTaskData = () => {
        task.value = JSON.parse(JSON.stringify(blankTask))
      }

      const addTask = (val) => {
        store.dispatch("app-todo/addTask", val).then(() => {
          // eslint-disable-next-line no-use-before-define
          fetchTasks()
        })
      }
      const removeTask = () => {
        store.dispatch("app-todo/removeTask", { id: task.value.id }).then(() => {
          // eslint-disable-next-line no-use-before-define
          fetchTasks()
        })
      }
      const updateTask = (taskData) => {
        store.dispatch("app-todo/updateTask", { task: taskData }).then(() => {
          // eslint-disable-next-line no-use-before-define
          fetchTasks()
        })
      }

      const perfectScrollbarSettings = {
        maxScrollbarLength: 150,
      }

      const isTaskHandlerSidebarActive = ref(false)

      const resolveTagVariant = (tag) => {
        if (tag === "team") return "primary"
        if (tag === "low") return "success"
        if (tag === "medium") return "warning"
        if (tag === "high") return "danger"
        if (tag === "update") return "info"
        return "primary"
      }

      const resolveAvatarVariant = (tags) => {
        if (tags.includes("high")) return "primary"
        if (tags.includes("medium")) return "warning"
        if (tags.includes("low")) return "success"
        if (tags.includes("update")) return "danger"
        if (tags.includes("team")) return "info"
        return "primary"
      }

      // Search Query
      const searchQuery = ref(routeQuery.value)
      watch(routeQuery, (val) => {
        searchQuery.value = val
      })
      // eslint-disable-next-line no-use-before-define
      watch([searchQuery, sortBy], () => fetchTasks())
      const updateRouteQuery = (val) => {
        const currentRouteQuery = JSON.parse(JSON.stringify(route.value.query))

        if (val) currentRouteQuery.q = val
        else delete currentRouteQuery.q

        router.replace({ name: route.name, query: currentRouteQuery })
      }

      const fetchTasks = () => {
        store.dispatch("app-todo/fetchTasks", { taskType: selectedTags.value }).then((response) => {
          tasks.value = response.data
        })
      }

      fetchTasks()

      const handleTaskClick = async (taskData) => {
        task.value = taskData

        if (task.value.relation == "App\\Entity\\CompetitionFormation") {
          let { data } = await axios.get(`/competition-formation/${task.value.relationId}`)
          router.push({
            name: "scout-deleted-player",
            params: {
              sId: Number(data.competition.season.id),
              tId: Number(data.team.id),
              playerId: Number(data.player.id),
            },
          })
        } else if (task.value.relation == "App\\Entity\\Player") {
          let { data } = await axios.get(`/player/${task.value.relationId}`)
          router.push({
            name: "team-player",
            params: {
              seasonId: Number(data.playerTransferHistories[0].season.id),
              id: Number(data.playerTransferHistories[0].team.id),
              playerId: Number(data.id),
            },
          })
        } else {
          isTaskHandlerSidebarActive.value = true
        }
      }

      // Single Task isCompleted update
      const updateTaskIsCompleted = (taskData) => {
        // eslint-disable-next-line no-param-reassign
        taskData.isCompleted = !taskData.isCompleted
        updateTask(taskData)
      }

      const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()

      return {
        task,
        tasks,
        removeTask,
        addTask,
        updateTask,
        clearTaskData,
        searchQuery,
        fetchTasks,
        perfectScrollbarSettings,
        updateRouteQuery,
        resetSortAndNavigate,

        // UI
        resolveTagVariant,
        resolveAvatarVariant,
        isTaskHandlerSidebarActive,

        // Click Handler
        handleTaskClick,

        // Filters
        formatDate,
        avatarText,

        // Single Task isCompleted update
        updateTaskIsCompleted,

        // Left Sidebar Responsive
        mqShallShowLeftSidebar,
        selectedTags,
      }
    },
  }
</script>

<style lang="scss">
  // .todo-application .content-area-wrapper .content-right {
  // 	float: none;
  // 	width: 100% !important;
  // }
  .draggable-task-handle {
    position: absolute;
    left: 8px;
    top: 50%;
    transform: translateY(-50%);
    visibility: hidden;
    cursor: move;

    .todo-task-list .todo-item:hover & {
      visibility: visible;
    }
  }
</style>

<style lang="scss">
  @import "@core/scss/base/pages/app-todo.scss";
</style>
