export default [
  {
    title: 'Dashboards',
    icon: 'HomeIcon',
    route: 'dashboard-antrenor',
    resource: 'ACL',
    action: 'read',
  },
  {
    title: 'Calendar',
    route: 'team-calendar',
    icon: 'CalendarIcon',
    action: 'manage',
    resource: 'ACL',
  },
]
