var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column justify-content-center"},[_c('b-card',[_c('h4',[_vm._v(_vm._s(_vm.$t("Add New Staff")))])]),_c('div',{staticClass:"d-flex align-items-center flex-row"},[_c('div',{staticClass:"col-12"},[_c('b-card',[_c('validation-observer',{key:1,ref:"formTrigger",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [(_vm.form)?_c('b-form',{staticClass:"p-2",on:{"submit":function($event){$event.preventDefault();handleSubmit(_vm.submit())}}},[_c('b-row',[_c('b-col',{attrs:{"lg":"6"}},[_c('validation-provider',{attrs:{"name":"firstName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('First Name'),"label-for":"firstName"}},[_c('b-input',{attrs:{"id":"firstName","placeholder":_vm.$t('Please enter first name'),"state":errors.length > 0 ? false : null},model:{value:(_vm.form.firstName),callback:function ($$v) {_vm.$set(_vm.form, "firstName", $$v)},expression:"form.firstName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"lg":"6"}},[_c('validation-provider',{attrs:{"name":"lastName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Last Name'),"label-for":"lastName"}},[_c('b-input',{attrs:{"id":"lastName","placeholder":_vm.$t('Please enter last name'),"state":errors.length > 0 ? false : null},model:{value:(_vm.form.lastName),callback:function ($$v) {_vm.$set(_vm.form, "lastName", $$v)},expression:"form.lastName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"lg":"6"}},[_c('validation-provider',{attrs:{"name":"department","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Staff Department'),"label-for":"staffRole"}},[_c('v-select',{attrs:{"id":"staffRole","placeholder":_vm.$t('Please select a department'),"state":errors.length > 0 ? false : null,"options":_vm.departments,"reduce":function (option) { return option.id; },"getOptionLabel":function (option) { return option.name; }},model:{value:(_vm.form.department),callback:function ($$v) {_vm.$set(_vm.form, "department", $$v)},expression:"form.department"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"lg":"6"}},[_c('validation-provider',{attrs:{"name":"job","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Staff Job'),"label-for":"staffRole"}},[_c('v-select',{attrs:{"id":"staffRole","placeholder":_vm.$t('Please select a job'),"state":errors.length > 0 ? false : null,"options":_vm.jobs,"reduce":function (option) { return option.id; },"getOptionLabel":function (option) { return option.name; }},model:{value:(_vm.form.job),callback:function ($$v) {_vm.$set(_vm.form, "job", $$v)},expression:"form.job"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)],1),_c('div',{staticClass:"d-flex mt-2 float-right"},[_c('b-button',{attrs:{"variant":"primary","type":"submit"}},[_vm._v(_vm._s(_vm.$t("Add")))])],1)],1):_vm._e()]}}])})],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }