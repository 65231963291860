<template>
  <div>
    <b-overlay :show="generalLoading" rounded="sm">
      <div v-if="!generalLoading && setUserGroup">
        <div v-for="(userPerms, index) in setUserGroup" :key="userPerms.id">
          <div v-if="userPerms.id">
            <b-card-actions
              :collapsed="index == 0 ? false : true"
              ref="cardAction"
              :title="`${userPerms.team.club ? userPerms.team.club.name : ''} ${userPerms.team.name} ${
                userPerms.season.year
              } 
              ${$t('ROLES.' + userPerms.role.name)}`"
            >
              <b-table
                responsive
                :items="userPerms.permissionsData"
                :fields="[
                  { key: 'module', label: $t('Modül') },
                  { key: 'read', label: $t('Read') },
                  { key: 'write', label: $t('Write') },
                  { key: 'manage', label: $t('Manage') },
                ]"
                class="border"
              >
                <template #cell(module)="data"> {{ data.value }} </template>
                <template #cell(read)="data">
                  <b-form-checkbox disabled :checked="data.item.read" />
                </template>
                <template #cell(write)="data">
                  <b-form-checkbox disabled :checked="data.item.write" />
                </template>
                <template #cell(manage)="data">
                  <b-form-checkbox disabled :checked="data.item.manage" />
                </template>
              </b-table>
            </b-card-actions>
          </div>
        </div>
      </div>
      <div v-else>
        {{ $t("Data not found") }}
      </div>
    </b-overlay>
  </div>
</template>

<script>
  import { BButton, BRow, BCol, BCard, BFormCheckbox, BTable } from "bootstrap-vue"
  import Ripple from "vue-ripple-directive"
  import BCardActions from "@core/components/b-card-actions/BCardActions.vue"

  export default {
    components: {
      BButton,
      BRow,
      BCol,
      BCard,
      BFormCheckbox,
      BTable,
      BCardActions,
    },
    directives: {
      Ripple,
    },
    props: {
      user: {
        require: true,
      },
      /*permissionsData: {
      type: Array,
      default: () => {},
    },*/
    },
    data() {
      return {
        generalLoading: true,
        localOptions: null, //JSON.parse(JSON.stringify(this.permissionsData)),
        permissionsData: null,
        setUserGroup: [],
      }
    },
    methods: {
      permissionsKey(defaultPermissionsFromDB, userRoleIndex) {
        this.$http.get(`/user_manage/role/perms/list`).then((data) => {
          let items = data.data
          this.permissionsData = []
          this.setUserGroup.forEach((ind) => {
            if (ind.permissionsData.length == 0) {
              Object.values(items).map((itemData) => {
                ind.permissionsData.push({
                  permsId: itemData.id,
                  module: itemData.name,
                  read: false,
                  write: false,
                  manage: false,
                })
              })
            }
          })

          defaultPermissionsFromDB.forEach((dbData) => {
            /**
             * find perms
             * for set Id
             */
            let findUserRolePerms = this.setUserGroup[userRoleIndex].permissionsData.filter(
              (d) => d.permsId == dbData.permission.id,
            )
            if (findUserRolePerms) {
              findUserRolePerms[0].id = dbData.id
              if (dbData.action == "write") findUserRolePerms[0].write = true
              if (dbData.action == "read") findUserRolePerms[0].read = true
              if (dbData.action == "manage") {
                findUserRolePerms[0].manage = true
                findUserRolePerms[0].write = false
                findUserRolePerms[0].read = false
              }
            }
          })
        })
      },
    },
    beforeMount() {
      // TODO: delete this
      this.$http
        .get(`/user/${this.user.id}`)
        .then((data) => {
          Object.values(data.data.userRole).map((item, userRoleIndex) => {
            this.setUserGroup.push({
              team: item.team,
              season: item.season,
              role: item.role,
              id: item.id,
              permissionsData: [],
            })
            this.permissionsKey(item.userPermissions, userRoleIndex)
          })
          this.generalLoading = false
        })
        .catch((err) => {
          console.log(err)
          this.generalLoading = false
        })
    },
  }
</script>
