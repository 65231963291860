<template>
  <div>
    <div class="d-flex justify-content-start">
      <v-select
        v-if="$i18n.locale != 'en'"
        v-show="hideDay != 'hide'"
        style="width: 25%"
        :clearable="false"
        :options="daySelect"
        v-model="day"
        :getOptionLabel="(data) => data"
      ></v-select>
      <v-select
        style="width: 40%"
        :options="monthSelect"
        v-model="month"
        :clearable="false"
        :getOptionLabel="(data) => new Date(data + '/02/1990').toLocaleDateString($i18n.locale, { month: 'short' })"
        v-if="$i18n.locale != 'en'"
        class="ml-1"
      ></v-select>
      <v-select
        style="width: 40%"
        :options="monthSelect"
        v-model="month"
        :clearable="false"
        :getOptionLabel="(data) => new Date(data + '/02/1990').toLocaleDateString($i18n.locale, { month: 'short' })"
        v-if="$i18n.locale == 'en'"
        class=""
      ></v-select>
      <v-select
        class="ml-1"
        v-show="hideDay != 'hide'"
        v-if="$i18n.locale == 'en'"
        style="width: 25%"
        :options="daySelect"
        v-model="day"
        :clearable="false"
        :getOptionLabel="(data) => data"
      ></v-select>
      <v-select class="ml-1" :clearable="false" style="width: 35%" :options="yearSelect" v-model="year"></v-select>
    </div>
  </div>
</template>
<script>
  import i18n from "@/libs/i18n"
  import { VueSelect } from "vue-select"
  import { ref, watch, emit, onMounted } from "@vue/composition-api"

  export default {
    components: { vSelect: VueSelect },
    props: ["value", "hideDay"],
    watch: {
      value(newv, oldv) {
        this.value = newv
        this.initDateParser()
      },
    },
    setup(props, { emit }) {
      const day = ref([])
      const month = ref([])
      const year = ref([])
      const daySelect = ref([])
      const monthSelect = ref([])
      const yearSelect = ref([])
      for (var i = 1; i < 32; i++) daySelect.value.push(i)
      for (var i = 1960; i < new Date().getFullYear() + 1; i++) yearSelect.value.push(i)
      for (var i = 1; i < 13; i++) monthSelect.value.push(i)
      yearSelect.value.reverse()

      watch([day, month, year], (newv, oldv) => {
        if (year.value && month.value && day.value) {
          emit(
            "input",
            new Date(Date.UTC(year.value, month.value - 1, day.value))
              .toLocaleString("en", {
                timeZone: "Europe/Istanbul",
                hour12: false,
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
              })
              .substr(0, 10)
              .trim(),
          )
        }
      })

      const initDateParser = () => {
        if (props.value) {
          day.value = new Date(props.value).getDate()
          month.value = new Date(props.value).getMonth() + 1
          year.value = new Date(props.value).getFullYear()
        } else {
          day.value = 1
          month.value = new Date().getMonth() + 1
          year.value = new Date().getFullYear()
        }
      }

      onMounted(() => {
        initDateParser()
      })

      return {
        day,
        month,
        year,
        daySelect,
        monthSelect,
        yearSelect,

        initDateParser,
      }
    },
  }
</script>
