<template>
  <b-sidebar
    id="sidebar-position"
    :visible="isPositionActive"
    bg-variant="white"
    @shown="$emit('update:isPositionActive', true)"
    @hidden="
      $emit('update:isPositionActive', false)
      formTrigger++
    "
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
  >
    <template #default="{ hide }">
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">{{ $t("Position") }}</h5>
        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver" :key="formTrigger">
        <!-- Form -->
        <b-form v-if="positionFormData" class="p-2" @submit.prevent="handleSubmit(goSubmitPosition())">
          <!-- Position Name -->
          <validation-provider #default="{ errors }" name="positionName" rules="required">
            <b-form-group :label="$t('Position Name')" label-for="pozitionName">
              <b-form-input id="pozitionName" v-model="positionFormData.name" :state="errors[0] ? false : null" />

              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Color -->
          <validation-provider #default="{ errors }" name="color">
            <b-form-group :label="$t('Color')" label-for="color">
              <v-select
                v-model="positionFormData.color"
                :reduce="(option) => option.value"
                :state="getValidationState(errors)"
                :options="[
                  { label: $t('Red'), value: 'danger' },
                  { label: $t('Blue'), value: 'primary' },
                  { label: $t('Green'), value: 'success' },
                  { label: $t('Yellow'), value: 'warning' },
                  { label: $t('Grey'), value: 'secondary' },
                ]"
              >
              </v-select>

              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Available Positions -->
          <validation-provider #default="{ errors }" name="availablePositions">
            <b-form-group :label="$t('Available Positions')" label-for="available">
              <v-select
                multiple
                v-model="positionFormData.availablePositions"
                :reduce="(option) => option.value"
                :state="getValidationState(errors)"
                :options="[
                  { label: $t('Forward'), value: 'FF' },
                  { label: $t('FORWARD CENTRER'), value: 'CF' },
                  { label: $t('ATTACKING MIDFIELDER'), value: 'DW' },
                  { label: $t('Midfielder'), value: 'AM' },
                  { label: $t('DEFENSIVE MIDFIELDER'), value: 'MM' },
                  { label: $t('Defender'), value: 'DM' },
                  { label: $t('Goalkeeper'), value: 'GK' },
                ]"
              >
              </v-select>

              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Priority -->
          <validation-provider #default="{ errors }" name="priority">
            <b-form-group :label="$t('Priority')" label-for="available">
              <v-select
                v-model.number="positionFormData.priority"
                :state="getValidationState(errors)"
                :options="range1to100"
              >
              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" type="submit">{{
              $t("Add")
            }}</b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              ref="hideClick"
              @click="hide"
              >{{ $t("Cancel") }}</b-button
            >
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>
<script>
  import { ValidationProvider, ValidationObserver } from "vee-validate"
  import { required } from "@validations"
  import formValidation from "@core/comp-functions/forms/form-validation"
  import Ripple from "vue-ripple-directive"
  import flatPickr from "vue-flatpickr-component"
  import vSelect from "vue-select"

  export default {
    components: {
      flatPickr,
      vSelect,

      // Form Validation
      ValidationProvider,
      ValidationObserver,
    },
    directives: {
      Ripple,
    },
    model: {
      prop: "isPositionActive",
      event: "update:is-positione-active",
    },
    props: {
      isPositionActive: {
        type: Boolean,
        require: true,
      },
      submitPosition: {
        required: true,
      },
      updatePosition: {
        require: true,
      },
      positionFormData: {
        default: null,
      },
    },
    data() {
      return {
        required,
        formTrigger: 0,
        range1to100: [...Array(101).keys()].splice(1),
      }
    },
    methods: {
      goSubmitPosition() {
        this.$refs.refFormObserver.validate().then((success) => {
          if (success) {
            if (this.positionFormData.id) {
              this.positionFormData.availablePositions = JSON.stringify(this.positionFormData.availablePositions)
              this.updatePosition(this.positionFormData)
            } else {
              this.positionFormData.availablePositions = JSON.stringify(this.positionFormData.availablePositions)
              this.submitPosition(this.positionFormData)
            }
            this.$refs.hideClick.click()
          }
        })
      },
    },
    setup() {
      const { getValidationState } = formValidation()

      return {
        getValidationState,
      }
    },
  }
</script>

<style lang="scss">
  @import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
