import { ref, watch } from "@vue/composition-api"

import { useRouter } from "@core/utils/utils"

import { useToast } from "vue-toastification/composition"

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
import axios from "@axios"
import store from "@/store"
import i18n from "@/libs/i18n"

export default function TrainingPlugin(props, emit) {
  // Use toast
  const toast = useToast()

  const { router, route } = useRouter()

  // DECLARED CONSTS

  const refTrainingTable = ref(null)
  const trainingInitialDataForm = ref(null)
  const editTrainingData = ref(null)
  const showEditForm = ref(false)
  const loading = ref(false)
  const localLoading = ref(false)
  const showLogs = ref(false)
  const teamId = ref(Number(router.currentRoute.params.id))
  const trainingId = ref(Number(router.currentRoute.params.trainingId))
  const seasonId = ref(Number(router.currentRoute.params.seasonId))
  const trainings = ref([])
  const training = ref([])
  const locations = ref([])
  const trainingItems = ref([])
  const learningOutcomes = ref(null)
  const positions = ref([])
  const trainingInitForm = ref({
    title: null,
    location: 1,
    startDate: null,
    startTime: "14:00",
    endDate: null,
    endTime: "14:00",
  })
  const perPage = ref(localStorage.getItem("perPage") || 10)
  const currentPage = ref(1)
  const totalItems = ref(0)
  const perPageOptions = [10, 25, 50, 100]
  const trainingModalData = ref({
    endDate: null,
    location: null,
    season: null,
    startDate: null,
    title: null,
    trainingBlocks: null,
    startTime: null,
  })
  const isCopyModalOpen = ref(false)
  const copying = ref(false)

  const dateString = (date = null, post = false) => {
    // for Safari
    date = post ? date.replace(/-/g, "/") : date
    return new Date(date)
      .toLocaleString("en", {
        timeZone: "Europe/Istanbul",
        hour12: false,
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      })
      .replace(",", "")
  }
  /**
   * TRAINING CREATE - "team/:ID/training-add"
   */
  const addInitialTraining = () => {
    loading.value = true
    trainingInitialDataForm.value.validate().then((success) => {
      if (success) {
        console.log(i18n.locale)
        const tempData = {
          title: trainingInitForm.value.title,
          startDate: dateString(`${trainingInitForm.value.startDate} ${trainingInitForm.value.startTime}`, true),
          endDate: dateString(`${trainingInitForm.value.startDate} ${trainingInitForm.value.startTime}`, true),
          location: trainingInitForm.value.location,
          season: seasonId.value,
        }
        axios
          .post(`/season/${seasonId.value}/team/${teamId.value}/training`, tempData)
          .then((response) => {
            toast({
              component: ToastificationContent,
              props: {
                title: i18n.t("Training Successfully Added"),
                icon: "CheckIcon",
                variant: "success",
              },
            })
            loading.value = false
            router.push({
              name: "team-training-edit",
              params: {
                id: teamId.value,
                trainingId: response.data.id,
              },
            })
          })
          .catch((error) => {
            loading.value = false
            toast({
              component: ToastificationContent,
              props: {
                title: i18n.t("An error occurred"),
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            })
          })
      } else {
        loading.value = false
      }
    })
  }

  /**
   * TRAINING SAVE - "team/:ID/training-edit"
   */
  const saveTraining = () => {
    trainingInitialDataForm.value.validate().then((success) => {
      if (success) {
        setLoading(true, "Saving Training")
        console.log(i18n.locale)
        const tempData = {
          title: trainingInitForm.value.title,
          startDate: dateString(
            `${trainingInitForm.value.startDate.substr(0, 10)} ${trainingInitForm.value.startTime}`,
            true,
          ),
          endDate: dateString(
            `${trainingInitForm.value.startDate.substr(0, 10)} ${trainingInitForm.value.startTime}`,
            true,
          ),
          location: trainingInitForm.value.location.id
            ? trainingInitForm.value.location.id
            : trainingInitForm.value.location,
          season: 1,
        }

        axios
          .put(`/training/${trainingId.value}`, tempData)
          .then((response) => {
            setLoading(false, "Saved Training")
            toast({
              component: ToastificationContent,
              props: {
                title: i18n.t("Training Successfully Updated"),
                icon: "CheckIcon",
                variant: "success",
              },
            })
            showEditForm.value = false
            location.reload()
          })
          .catch((error) => {
            setLoading(false, error)
            toast({
              component: ToastificationContent,
              props: {
                title: i18n.t("An error occurred"),
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            })
          })
      }
    })
  }

  /**
   * FETCH TRAININGS  - "team/:ID/training"
   */
  /* const getTrainings = () => {
    loading.value = true
    axios.get(`season/${seasonId.value}/team/${teamId.value}/training?row=${perPage.value}&page=${currentPage.value}`).then((response) => {
      loading.value = false
      trainings.value = response.data.data
      totalItems.value = response.data.count
    })
  } */

  /**
   * FETCH TRAINING - "team/:ID/training-edit"
   * @var integer itemId
   */
  const getTraining = (itemId) => {
    setLoading(true, "Fetching Training")
    axios
      .get(`/training/${itemId}`)
      .then((response) => {
        const data = setPathToEmptyTrainingMedia(response.data)
        training.value = data
        setTrainingData(data)
        setLoading(false, "Fetched Training")
      })
      .catch((error) => {
        setLoading(false, error)
      })
  }

  /**
   * TRAINING REMOVE
   */
  const removeTraining = (id) => {
    axios.delete(`/training/${id}`).then((response) => {
      refTrainingTable.value.refresh()
      toast({
        component: ToastificationContent,
        position: "bottom-right",
        props: {
          title: i18n.t("Successfully Deleted"),
          icon: "CheckIcon",
          variant: "success",
        },
      })
    })
  }

  /**
   * SETTING TRAINING DATA - "team/:ID/training-edit"
   * @var Object data
   */
  const setTrainingData = (data) => {
    trainingInitForm.value.title = data.title
    trainingInitForm.value.startDate = data.startDate.substring(0, 10)
    trainingInitForm.value.startTime = parseTime(data.startDate)
    trainingInitForm.value.endDate = data.startDate.substring(0, 10)
    trainingInitForm.value.endTime = parseTime(data.startDate)
    trainingInitForm.value.location = data.location.id
  }

  /**
   * REMOVE BLOCK - "team/:ID/training-edit"
   * @var integer index
   * @var integer id
   */
  const removeBlock = (index, id) => {
    axios.delete(`/training-block-item/${id}`).then((response) => {
      getTraining(trainingId.value)
      training.value.trainingBlocks.splice(index, 1)
      toast({
        component: ToastificationContent,
        position: "bottom-right",
        props: {
          title: i18n.t("Block Successfully Deleted"),
          icon: "CheckIcon",
          variant: "success",
        },
      })
    })
  }

  /**
   * ADD NEW BLOCK - "team/:ID/training-edit"
   * @var Object trainingData
   */
  const AddNewBlock = (trainingData) => {
    localLoading.value = true
    const form = {
      trainingId: trainingData.id,
      title: `${trainingData.trainingBlocks.length + 1}. Blok`,
      description: `${trainingData.trainingBlocks.length + 1}. Blok`,
    }
    axios
      .post("/training-block", form)
      .then((response) => {
        training.value.trainingBlocks.push(response.data)
        localLoading.value = false
        toast({
          component: ToastificationContent,
          position: "bottom-right",
          props: {
            title: i18n.t("Block Successfully Added"),
            icon: "CheckIcon",
            variant: "success",
          },
        })
      })
      .catch((error) => {})
  }

  /**
   * REMOVE TRAINING ITEM - "team/:ID/training-edit"
   * @var integer index
   * @var integer itemIndex
   * @var integer trainingItem
   */
  const removeTrainingItem = (index, itemIndex, trainingItem) => {
    setLoading(true, "Removing Training Item")
    if (trainingItem.type !== "Template") {
      if (trainingItem.id) {
        axios.delete(`/training-item/${trainingItem.id}`).then((response) => {
          getTraining(trainingId.value)
          training.value.trainingBlocks[index].trainingItems.splice(itemIndex, 1)
          setLoading(false, "Removed Training Item")
          toast({
            component: ToastificationContent,
            position: "bottom-right",
            props: {
              title: i18n.t("Block Item Successfully Deleted"),
              icon: "CheckIcon",
              variant: "success",
            },
          })
        })
      } else {
        setLoading(false, "Removed Training Item")
        toast({
          component: ToastificationContent,
          position: "bottom-right",
          props: {
            title: i18n.t("Block Item Successfully Deleted"),
            icon: "CheckIcon",
            variant: "success",
          },
        })
        training.value.trainingBlocks[index].trainingItems.splice(itemIndex, 1)
      }
    } else if (training.value.trainingBlocks[index]) {
      setLoading(false, "Removed Training Item")
      training.value.trainingBlocks[index].trainingItems.splice(itemIndex, 1)
      toast({
        component: ToastificationContent,
        position: "bottom-right",
        props: {
          title: i18n.t("Block Item Successfully Deleted"),
          icon: "CheckIcon",
          variant: "success",
        },
      })
    } else {
      //
    }
  }

  /**
   * ADD NEW TRAINING ITEM - "team/:ID/training-edit"
   * @var integer index
   */
  const addNewTrainingItem = (index) => {
    const targetItem = findTrainingItems(index)
    targetItem.push({
      id: null,
      trainingItemMedia: [
        {
          path: "",
        },
      ],
      trainingItemPlayers: [],
    })
    toast({
      component: ToastificationContent,
      position: "bottom-right",
      props: {
        title: i18n.t("Block Item Successfully Added"),
        icon: "CheckIcon",
        variant: "success",
      },
    })
  }
  const notifyComp = ref(null)
  /**
   * SAVE TRAINING ITEM - "team/:ID/training-edit"
   * @var integer index
   */
  const saveTrainingItem = (blokIndex, itemIndex, blockId) => {
    editTrainingData.value[itemIndex]
      .validate()
      .then((success) => {
        if (success) {
          setLoading(true, "Saving Block Item")
          const data = findTrainingItem(blokIndex, itemIndex, blockId)
          if (data.id) {
            axios.put("/training-item", data).then((response) => {
              notifyComp.value.sendNotify()
              getTraining(trainingId.value)
              setLoading(false, "Saved Block Item")
              toast({
                component: ToastificationContent,
                position: "bottom-right",
                props: {
                  title: i18n.t("Block Item Successfully Updated"),
                  icon: "CheckIcon",
                  variant: "success",
                },
              })
            })
          } else {
            axios.post("/training-item", data).then((response) => {
              notifyComp.value.sendNotify()
              getTraining(trainingId.value)
              setLoading(false, "Added Block Item")
              toast({
                component: ToastificationContent,
                position: "bottom-right",
                props: {
                  title: i18n.t("Block Item Successfully Added"),
                  icon: "CheckIcon",
                  variant: "success",
                },
              })
            })
          }
        }
      })
      .catch((error) => {
        setLoading(false, "error")
      })
  }

  const saveTrainingItemToLibrary = (blokIndex, itemIndex, blockId) => {
    editTrainingData.value[itemIndex]
      .validate()
      .then((success) => {
        if (success) {
          setLoading(true, "Saving Block Item To Library")
          const data = findTrainingItem(blokIndex, itemIndex, blockId)
          if (data.id) {
            axios.post(`/training-item/${data.id}/to-library`).then((response) => {
              getTraining(trainingId.value)
              setLoading(false, "Added Block Item Library")
              toast({
                component: ToastificationContent,
                position: "bottom-right",
                props: {
                  title: i18n.t("Training Item Successfully Added to Library"),
                  icon: "CheckIcon",
                  variant: "success",
                },
              })
            })
          }
        }
      })
      .catch((error) => {
        setLoading(false, "error")
      })
  }

  /**
   * SETTING "PATH" PROPERT TO EMPTY MEDIA OBJECTS  - "team/:ID/training-edit"
   * @var Object data
   */
  const setPathToEmptyTrainingMedia = (data) => {
    Object.values(data.trainingBlocks).map((item) => {
      if (item.trainingItems) {
        item.trainingItems.map((trainingItem) => {
          if (trainingItem.trainingItemMedia.length < 1) {
            trainingItem.trainingItemMedia.push({
              path: "",
            })
          }
        })
      }
    })
    return data
  }

  /**
   * PARSING DATE  - "team/:ID/training-edit"
   */
  const parseTime = (date = null) => dateString(date).substr(11, 5)
  // if (!date) return "";
  // let dateFormat = new Date(date);
  // return dateFormat.toTimeString()

  /**
   * FETCH LOCATIONS - "team/:ID/training-add"
   */
  const getLocations = () => {
    axios.get("/location").then((response) => {
      locations.value = response.data
    })
  }

  /**
   * FETCH TRAINING ITEM TEMPLATES - "team/:ID/training-edit"
   */
  const getTrainingItemTemplates = async (name = null) => {
    await axios
      .get(`/training-item-template`, {
        params: {
          name: name,
          row: perPage.value,
          page: currentPage.value,
        },
      })
      .then((response) => {
        trainingItems.value = response.data.data
        totalItems.value = response.data.count
      })
  }

  /**
   * FETCH LEARNING OUTCOMES - "team/:ID/training-edit"
   */
  const getLearningOutcomes = () => {
    axios.get("/learning-outcome").then((response) => {
      learningOutcomes.value = response.data
    })
  }

  /**
   * SEARCH LEARNING OUTCOME - "team/:ID/training-edit"
   * @var string query
   */
  const searchLearningOutcome = (query) => {
    if (query.length < 2) return false
    axios.get(`/learning-outcome/search/${query}`).then((response) => {
      learningOutcomes.value = response.data
    })
  }

  /**
   * FETCH TEAM PLAYERS - "team/:ID/training-edit"
   */
  const getTeamPlayers = () => {
    axios.get("/team/2").then((response) => {
      // axios.get(`/team/${teamId.value}/player`).then((response) => {
      const tempPostions = {}

      response.data.players.map((item, value) => {
        if (tempPostions[item.position.name]) {
          tempPostions[item.position.name].push(item)
        } else {
          tempPostions[item.position.name] = [item]
        }
      })

      positions.value.push(tempPostions)
    })
  }

  /**
   * OPENING TRAINIG COPY MODAL
   *
   */
  const showTrainingCopyModal = (trainingData) => {
    trainingModalData.value.location = trainingData.location.id
    trainingModalData.value.season = seasonId.value
    trainingModalData.value.startDate = trainingData.startDate
    trainingModalData.value.title = trainingData.title
    trainingModalData.value.trainingBlocks = trainingData.trainingBlocks

    if (trainingModalData.value.startDate)
      trainingModalData.value.startTime = dateString(trainingModalData.value.startDate).substr(11, 5)

    isCopyModalOpen.value.show()
  }

  /**
   * COPY TRAINING
   */
  const copyTraining = async (training) => {
    copying.value = true
    let tempTraining = {
      endDate: null,
      location: null,
      season: null,
      startDate: null,
      title: null,
      trainingBlocks: null,
    }
    tempTraining.location = training.location
    tempTraining.season = training.season
    tempTraining.startDate = dateString(`${training.startDate.substr(0, 10)} ${training.startTime}`)
    tempTraining.endDate = tempTraining.startDate
    tempTraining.title = training.title

    axios
      .post(`/season/${seasonId.value}/team/${teamId.value}/training`, tempTraining)
      .then(async (resTraining) => {
        tempTraining = resTraining.data
        // block
        await Promise.all(
          training.trainingBlocks.map(async (block, blocIndex) => {
            if (!block.title.startsWith("1. ")) {
              block.trainingId = tempTraining.id
              await axios.post("/training-block", block).then((resBlock) => {
                tempTraining.trainingBlocks.push(resBlock.data)
              })
            }
            // block item
            await Promise.all(
              block.trainingItems.map(async (item) => {
                item.block = tempTraining.trainingBlocks[blocIndex].id
                item.training = tempTraining.id
                await axios.post("/training-item", item).then((resItem) => {
                  tempTraining.trainingBlocks[blocIndex].trainingItems.push(resItem.data)
                })
              }),
            )
          }),
        )
        copying.value = false
        isCopyModalOpen.value.hide()
        toast({
          component: ToastificationContent,
          props: {
            title: i18n.t("Successfully Copied"),
            icon: "PlusIcon",
            variant: "success",
          },
        })
        setTimeout(location.reload(), 500)
      })
      .catch((err) => {
        console.log(err)
        copying.value = false
        isCopyModalOpen.value.hide()
      })
  }

  const avaibleSeasons = JSON.parse(store.getters["app/seasons"])

  /**
   * ADDING CUSTOM LOCATION TO VUE SELECT - "team/:ID/training-add"
   * @var string query
   */
  const addThisLocation = async (query) => {
    const data = await axios.post("/location", {
      name: query,
    })
    if (data) {
      locations.value.push(data.data)
      toast({
        component: ToastificationContent,
        props: { title: i18n.t("Successfully Added"), icon: "PlusIcon", variant: "success" },
      })
    }
  }

  /**
   * SEARCHING LOCATION IN THE VUE SELECT - "team/:ID/training-add"
   * @var string query
   */
  const searchLocation = (query) => {
    if (query.length < 3) return false

    const response = null // search locations

    // let data = axios.post(`location/search`,this.query) //search locations

    if (!response) {
      return false
    }
    locations.value = response.data // this.teams = teams
  }

  const findTrainingItem = (blokIndex, itemIndex, blockId) => {
    if (training.value.trainingBlocks.length > 0) {
      const block = training.value.trainingBlocks[blokIndex]
      if (block) {
        const data = block.trainingItems[itemIndex]
        data.block = parseInt(blockId)
        data.duration = parseInt(data.duration)
        data.training = parseInt(trainingId.value)
        return data
      }
    }
  }

  const findTrainingItems = (blokIndex) => {
    if (training.value.trainingBlocks.length > 0) {
      const block = training.value.trainingBlocks[blokIndex]
      if (block) {
        return block.trainingItems
      }
    }
  }

  const setLoading = (value, text = null) => {
    loading.value = value
    if (showLogs.value === true && text !== null) {
      console.log(text)
    }
  }

  watch(perPage, (perPage) => {
    localStorage.setItem("perPage", perPage)
  })

  return {
    loading,
    trainingInitForm,
    locations,
    addThisLocation,
    searchLocation,
    positions,
    teamId,
    learningOutcomes,
    getLearningOutcomes,
    removeBlock,
    AddNewBlock,
    removeTrainingItem,
    addNewTrainingItem,
    addInitialTraining,
    trainingInitialDataForm,
    editTrainingData,
    getTraining,
    getTeamPlayers,
    training,
    getLocations,
    getLocations,
    getTrainingItemTemplates,
    trainingItems,
    // getTrainings,
    trainings,
    saveTrainingItem,
    saveTrainingItemToLibrary,
    saveTraining,
    showEditForm,
    searchLearningOutcome,
    setLoading,
    localLoading,
    perPage,
    currentPage,
    totalItems,
    perPageOptions,
    seasonId,
    teamId,
    showTrainingCopyModal,
    isCopyModalOpen,
    trainingModalData,
    copyTraining,
    copying,
    avaibleSeasons,
    refTrainingTable,
    removeTraining,
    notifyComp,
  }
}
