import http from "@/libs/axios"
import i18n from "@/libs/i18n"
import toastService from "./toastService"

export default class competitionService {
  constructor() {}

  async get() {
    return http
      .get("/competition")
      .then((response) => new Promise((resolve) => resolve(response.data)))
      .catch((error) => new Promise((reject) => reject(error)))
  }

  async find(competitionId) {
    return http
      .get(`/competition/${competitionId}`)
      .then((response) => new Promise((resolve) => resolve(response.data)))
      .catch((error) => new Promise((reject) => reject(error)))
  }

  async delete(competitionId) {
    return http
      .delete(`/competition/${competitionId}`)
      .then((response) => new Promise((resolve) => resolve(response.data)))
      .catch((error) => new Promise((reject) => reject(error)))
  }

  async findCompetitionByTeam(seasonId, teamId, params = { row: 12, page: 1 }) {
    return http
      .get(`/season/${seasonId}/team/${teamId}/competition`, { params })
      .then((response) => new Promise((resolve) => resolve(response.data)))
      .catch((error) => new Promise((reject) => reject(error)))
  }

  async findCompetitionWithStatsByTeam(seasonId, teamId, params = { row: 12, page: 1 }) {
    return http
      .get(`/season/${seasonId}/team/${teamId}/competition-stats`, { params })
      .then((response) => new Promise((resolve) => resolve(response.data)))
      .catch((error) => new Promise((reject) => reject(error)))
  }

  async coachReview(requestPayload) {
    return http.post(`/competition/formation`, requestPayload).then((response) => {
      toastService(i18n.t("COACH_REVIEW_SAVE"), "EditIcon", "success")
      return new Promise((resolve) => resolve(response.data))
    })
  }

  async submitFormationPlugin(competitionId, requestPayload) {
    return http
      .post(`/competition/${competitionId}/formation/plugin`, requestPayload)
      .then((response) => {
        toastService(i18n.t("FORMATION_SAVE"), "EditIcon", "success")
        return new Promise((resolve) => resolve(response))
      })
      .catch((err) => new Promise((reject) => reject(err)))
  }

  async event(competition, requestPayload, setTeamFormations) {
    return http.post(`/competition/event`, requestPayload).then((response) => {
      if (response.data == "DELETED") {
        toastService(`${i18n.t("DELETED_EVENT")}! #${requestPayload.id ? requestPayload.id : "#"}`, "TrashIcon", "info")
        competition.value.competitionEvents = competition.value.competitionEvents.filter(
          (d) => d && d.id != requestPayload.id,
        )
      }
      if (response.data.id) {
        if (!requestPayload.id) {
          toastService(`${i18n.t("SAVED_EVENT")}! #${response.data.id}`, "PlusIcon", "success")
          competition.value.competitionEvents.push(response.data)
        } else {
          toastService(`${i18n.t("CHANGED_EVENT")}! #${response.data.id}`, "EditIcon", "success")
          competition.value.competitionEvents = competition.value.competitionEvents.filter(
            (d) => d.id != response.data.id,
          )
          competition.value.competitionEvents.push(response.data)
        }
      }
      setTeamFormations("homeTeam")
      setTeamFormations("awayTeam")

      return new Promise((resolve) => resolve(response.data))
    })
  }

  async eventWithoutplayer(competition, requestPayload, setTeamFormations) {
    return http.post(`/competition/event/withoutplayer`, requestPayload).then((response) => {
      if (response.data == "DELETED") {
        toastService(`${i18n.t("DELETED_EVENT")}! #${requestPayload.id ? requestPayload.id : "#"}`, "TrashIcon", "info")
        competition.value.competitionEvents = competition.value.competitionEvents.filter(
          (d) => d.subPlayer && d.subPlayer.id != requestPayload.subPlayer,
        )
      }
      if (response.data.id) {
        if (!requestPayload.id) {
          toastService(`${i18n.t("SAVED_EVENT")}! #${response.data.id}`, "PlusIcon", "success")
          competition.value.competitionEvents.push(response.data)
        } else {
          toastService(`${i18n.t("CHANGED_EVENT")}! #${response.data.id}`, "EditIcon", "success")
          competition.value.competitionEvents = competition.value.competitionEvents.filter(
            (d) => d.id != response.data.id,
          )
          competition.value.competitionEvents.push(response.data)
        }
      }
      setTeamFormations("homeTeam")
      setTeamFormations("awayTeam")

      return new Promise((resolve) => resolve(response.data))
    })
  }

  async formation(requestPayload, player, competition, setTeamFormations) {
    return http.post(`/competition/formation`, requestPayload).then((response) => {
      player.isStarterId = response.data.id
      let findData = competition.value.competitionFormations.filter((d) => d.id == response.data.id)
      if (findData) {
        competition.value.competitionFormations = competition.value.competitionFormations.filter(
          (d) => d.id != response.data.id,
        )
        competition.value.competitionFormations.push(response.data)
      } else {
        competition.value.competitionFormations.push(response.data)
      }
      setTeamFormations("homeTeam")
      setTeamFormations("awayTeam")
      competition.value.formationTrigger++
      toastService(i18n.t("Successfully Saved"), "PlusIcon", "success")
      return new Promise((resolve, reject) => {
        resolve(response.data)
      })
    })
  }

  async sendPlayerSerpNotification(competitionId) {
    return http.post(`/competition/${competitionId}/performance/notification`).then((response) => {
      toastService(i18n.t("Evaluation Notification Has Been Sent"), "CheckIcon", "success")
      return new Promise((resolve) => resolve(response.data))
    })
  }
}
