<template>
  <section id="dashboard-ecommerce" v-if="userData">
    <b-row class="match-height">
      <b-col xl="5" md="5">
        <calendar-list-app withSidebar="false"></calendar-list-app>
      </b-col>
      <b-col xl="7" md="7">
        <b-overlay :show="isLoadingMatchHistories" rounded="sm">
          <div class="">
            <table-match-history :match-history="matchHistory" class="tableCardMatches"></table-match-history>
          </div>
        </b-overlay>
      </b-col>
    </b-row>
  </section>
</template>

<script>
  import { BRow, BCol, BCard, BCardText, BLink, BButton, BImg } from "bootstrap-vue"
  import ChartTraining from "@core/components/global-components/ChartTraining"
  import TrainingProgramList from "@core/components/global-components/TrainingProgramList"
  import TableMatchHistory from "@core/components/global-components/TableMatchHistory.vue"

  import CalendarListApp from "@/views/apps/calendar-app/Calendar.vue"
  import i18n from "@/libs/i18n"

  export default {
    components: {
      BRow,
      BCol,
      BCard,
      BCardText,
      BLink,
      BImg,
      BButton,

      ChartTraining,
      TableMatchHistory,
      TrainingProgramList,
      CalendarListApp,
    },
    data() {
      return {
        userData: JSON.parse(localStorage.getItem("userData")) || null,
        matchHistory: [],
        teamQueries: "",
        trainingAttendsData: {
          participanData: {
            series: [
              {
                name: "Katılım",
                data: [],
              },
              {
                name: "Devamsız",
                data: [],
              },
            ],
          },
          radialChartData: {
            series: [],
            participiantCount: 0,
            unParticipiantCount: 0,
          },
          teams: [],
          type: "global", // 'team'
          title: "Last Training Report",
        },
        antrenmanTimeData: {
          title: "Günlük Takım Bazlı Antrenman Süreleri",
          series: [],
          weeklyDays: [
            i18n.t("Monday"),
            i18n.t("Tuesday"),
            i18n.t("Wednesday"),
            i18n.t("Thursday"),
            i18n.t("Friday"),
            i18n.t("Saturday"),
            i18n.t("Sunday"),
          ],
        },
        attendance: null,
        isLoadingAttendance: true,
        isLoadingMatchHistories: true,
        isLoadingDailyTrainingLOCDurations: true,
      }
    },
    beforeMount() {
      let teams = localStorage.getItem("teams")
      teams = JSON.parse(teams)
      this.teamQueries = Object.values(teams)
        .map((d) => d.id)
        .join()
    },
    computed: {
      season() {
        return this.$store.getters["app/season"]
      },
    },
    mounted() {
      this.getCompetitions()
      this.getDailyTrainingLOCDurations()
    },
    methods: {
      getAttendance() {
        this.$http
          .get(`/season/${this.season}/training-attendant/last-all?team=${this.teamQueries}`)
          .then((response) => {
            this.attendance = Object.values(response.data)
            this.setAttendantChartData()
            this.isLoadingAttendance = false
          })
          .catch((err) => {
            console.log(err)
            this.isLoadingAttendance = false
          })
      },
      setAttendantChartData() {
        if (this.attendance.length > 0) {
          this.getAttendantCount()
          this.attendance.filter((item) => {
            this.trainingAttendsData.teams.push(item.team.name)
            this.trainingAttendsData.participanData.series[0].data.push(item.attendant ? item.attendant.length : 0)
            this.trainingAttendsData.participanData.series[1].data.push(item.absent ? item.absent.length : 0)
          })
        }
      },

      getAttendantCount() {
        let attendant = 0
        let absent = 0
        let persentage = 0
        if (this.attendance.length > 0) {
          this.attendance.filter((item) => {
            attendant = item.attendant ? attendant + item.attendant.length : attendant
            absent = item.absent ? absent + item.absent.length : absent
          })
        }

        persentage = attendant / (attendant + absent)
        persentage = persentage.toFixed(2)
        persentage = persentage * 100

        this.trainingAttendsData.radialChartData.participiantCount = attendant
        this.trainingAttendsData.radialChartData.unParticipiantCount = absent
        this.trainingAttendsData.radialChartData.series = [persentage]
      },

      getCompetitions() {
        this.$http
          .get(`/scout/competition`)
          .then((response) => {
            response.data = response.data.slice(0, 6)
            this.matchHistory = response.data
            this.isLoadingMatchHistories = false
          })
          .catch((err) => {
            this.isLoadingMatchHistories = false
          })
      },

      getDailyTrainingLOCDurations() {
        this.$http
          .get(`/season/${this.season}/training-loc-all?team=${this.teamQueries}`)
          .then((response) => {
            this.loc = Object.values(response.data)
            this.setDailyTLOCD()
            this.isLoadingDailyTrainingLOCDurations = false
          })
          .catch((err) => {
            this.isLoadingDailyTrainingLOCDurations = false
            console.log(err)
          })
      },

      setDailyTLOCD() {
        if (this.loc.length > 0) {
          this.loc.filter((item) => {
            this.antrenmanTimeData.series.push({
              name: item.team,
              type: "bar",
              stack: "advertising",
              data: item.duration,
              barWidth: "30%",
            })
          })
        }
      },
    },
    created() {},
  }
</script>

<style lang="scss">
  .tableCardMatches {
    .card-body {
      margin: -1.5rem -0.5rem;
    }
  }
</style>
