<template>
  <div class="p-2">
    <div class="d-flex justify-content-end">
      <feather-icon
        size="16"
        icon="InfoIcon"
        v-b-tooltip.hover
        class="pull-up mr-1"
        :title="`Oyuncunun mevcut sezonda ölçülmüş olan boy ve ağırlığının değişimini ifade eder.`"
      />
    </div>
    <div class="d-flex flex-content-between">
      <div class="col box-chart">
        <h5>{{ $t("Height") }}</h5>
        <h4 class="font-weight-bolder mb-1">
          {{ height.series.length > 0 ? height.series[0]["data"][height.series[0]["data"].length - 1] : user.height }} m
        </h4>
        <!-- chart -->
        <vue-apex-charts
          v-if="height.series.length"
          height="70"
          :options="height.chartOptions"
          :series="height.series"
        />
        <div v-else class="d-flex justify-content-center align-items-center mt-2">
          <span class="text-center"> {{ $t("Serp PointS Not Found") }}</span>
        </div>
      </div>
      <div class="col box-chart">
        <h5>{{ $t("Weight") }}</h5>
        <h4 class="font-weight-bolder mb-1">
          {{ weight.series.length > 0 ? weight.series[0]["data"][weight.series[0]["data"].length - 1] : user.weight }}
          kg
        </h4>
        <!-- chart -->
        <vue-apex-charts
          v-if="weight.series.length"
          height="80"
          :options="weight.chartOptions"
          :series="weight.series"
        />
        <div v-else class="d-flex justify-content-center align-items-center mt-2">
          <span class="text-center"> {{ $t("Serp PointS Not Found") }}</span>
        </div>
      </div>
    </div>
    <div></div>
  </div>
</template>

<script>
  import { BCard, VBTooltip } from "bootstrap-vue"
  import VueApexCharts from "vue-apexcharts"
  import { $themeColors } from "@themeConfig"

  const $trackBgColor = "#EBEBEB"

  export default {
    components: {
      BCard,
      VueApexCharts,
    },
    directives: {
      "b-tooltip": VBTooltip,
    },
    props: {
      user: {
        type: Object,
        default: {},
      },
      tests: {
        type: Array,
        default: [],
      },
    },
    data() {
      return {
        height: {
          series: [],
          chartOptions: {
            chart: {
              type: "line",
              toolbar: {
                show: false,
              },
              zoom: {
                enabled: false,
              },
            },
            grid: {
              borderColor: $trackBgColor,
              strokeDashArray: 5,
              xaxis: {
                lines: {
                  show: true,
                },
              },
              yaxis: {
                lines: {
                  show: false,
                },
              },
              padding: {
                top: -30,
                bottom: -10,
              },
            },
            stroke: {
              width: 6,
            },
            colors: [$themeColors.info],
            markers: {
              size: 2,
              colors: $themeColors.info,
              strokeColors: $themeColors.info,
              strokeWidth: 2,
              strokeOpacity: 1,
              strokeDashArray: 0,
              fillOpacity: 1,
              discrete: [
                {
                  seriesIndex: 0,
                  dataPointIndex: 5,
                  fillColor: "#ffffff",
                  strokeColor: $themeColors.info,
                  size: 5,
                },
              ],
              shape: "circle",
              radius: 2,
              hover: {
                size: 3,
              },
            },
            xaxis: {
              categories: [],
              labels: {
                show: true,
                style: {
                  fontSize: "0px",
                },
              },
              axisBorder: {
                show: false,
              },
              axisTicks: {
                show: true,
              },
            },
            yaxis: {
              show: false,
            },
            tooltip: {
              x: {
                show: false,
              },
            },
          },
        },

        weight: {
          series: [],
          chartOptions: {
            chart: {
              type: "line",
              toolbar: {
                show: false,
              },
              zoom: {
                enabled: false,
              },
            },
            grid: {
              borderColor: $trackBgColor,
              strokeDashArray: 5,
              xaxis: {
                lines: {
                  show: true,
                },
              },
              yaxis: {
                lines: {
                  show: false,
                },
              },
              padding: {
                top: -30,
                bottom: -10,
              },
            },
            stroke: {
              width: 6,
            },
            colors: [$themeColors.warning],
            markers: {
              size: 2,
              colors: $themeColors.warning,
              strokeColors: $themeColors.warning,
              strokeWidth: 2,
              strokeOpacity: 1,
              strokeDashArray: 0,
              fillOpacity: 1,
              discrete: [
                {
                  seriesIndex: 0,
                  dataPointIndex: 5,
                  fillColor: "#ffffff",
                  strokeColor: $themeColors.warning,
                  size: 5,
                },
              ],
              shape: "circle",
              radius: 2,
              hover: {
                size: 3,
              },
            },
            xaxis: {
              categories: null, // [ "01", "02" ]
              labels: {
                show: true,
                style: {
                  fontSize: "0px",
                },
              },
              axisBorder: {
                show: false,
              },
              axisTicks: {
                show: false,
              },
            },
            yaxis: {
              show: false,
            },
            tooltip: {
              x: {
                show: false,
              },
            },
          },
        },
      }
    },
    methods: {
      getDate(date) {
        date = new Date(date)
        let month = date.getMonth() + 1
        let day = date.getDate()
        return day + `/` + month
      },
      filterPlayerWeights() {
        let newArr = []
        let categories = []
        this.tests.map((item) => {
          if (item.name === "weight") {
            newArr.push(item.value)
            categories.push(this.getDate(item.date))
          }
        })
        this.weight.series.push({
          name: "Kilo",
          data: newArr.reverse(),
        })
        this.weight.chartOptions.xaxis.categories = categories
      },

      filterPlayerHeights() {
        let newArr = []
        let categories = []
        this.tests.map((item) => {
          if (item.name === "height") {
            newArr.push(item.value)
            categories.push(this.getDate(item.date))
          }
        })
        this.height.series.push({
          name: "Boy",
          data: newArr.reverse(),
        })
        //Object.assign(this.height.chartOptions.xaxis.categories, categories);
        this.height.chartOptions.xaxis.categories = categories
      },
    },
    watch: {
      tests: function (newVal, oldVal) {
        if (newVal && newVal.length > 0) {
          this.filterPlayerWeights()
          this.filterPlayerHeights()
        }
      },
    },
  }
</script>
<style lang="scss" scoped>
  .row {
    margin: -2rem;

    .box-chart {
      transform: scale(0.9);
    }
  }
</style>
