<template>
  <b-card class="" style="min-height: 80vh">
    <b-card-title> {{ $t("Competitions History") }} </b-card-title>
    <div class="d-block">
      <div class="float-right">
        <b-button :to="{ name: 'scout-competition-add', params: { id: teamId } }" variant="primary" class="right-top">{{
          $t("Add")
        }}</b-button>
      </div>
    </div>
    <b-overlay :show="loading" rounded="sm">
      <table-scout-match-history
        v-if="matchHistory.length > 0"
        class="mt-2 table-match-history"
        :match-history="matchHistory"
      ></table-scout-match-history>
    </b-overlay>
  </b-card>
</template>
<script>
  import vSelect from "vue-select"
  import { onUnmounted } from "@vue/composition-api"
  import store from "@/store"
  import useMatchList from "./useMatchList"
  import TableScoutMatchHistory from "@core/components/global-components/TableScoutMatchHistory.vue"
  import matchStoreModule from "./matchStoreModule"
  import competitionService from "@/services/scoutCompetitionService"

  export default {
    components: {
      TableScoutMatchHistory,
      vSelect,
    },
    data() {
      return {
        matchHistory: [],
        loading: true,
      }
    },
    created() {
      let service = new competitionService()
      service.findCompetitionByTeam().then((data) => {
        this.matchHistory = data
        this.loading = false
      })
    },
    setup(props, { root }) {
      const STORE_MODULE_NAME = "app-match"

      // Register module
      if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, matchStoreModule)

      // UnRegister on leave
      onUnmounted(() => {
        if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
      })

      const {
        tableColumns,
        perPage,
        currentPage,
        totalItems,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refTeamListTable,
        statusFilter,
        refFetchData,
        fetchItems,
        teamId,
      } = useMatchList()

      return {
        tableColumns,
        perPage,
        currentPage,
        totalItems,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refTeamListTable,
        statusFilter,
        refFetchData,
        fetchItems,
        teamId,
      }
    },
  }
</script>

<style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }

  .right-top {
    position: absolute;
    right: 2rem;
    top: 1rem;
  }

  .invoice-filter-select {
    min-width: 190px;

    ::v-deep .vs__selected-options {
      flex-wrap: nowrap;
    }

    ::v-deep .vs__selected {
      width: 100px;
    }
  }
  .table-match-history {
    margin: -1.5rem;
    margin-bottom: 1rem;
  }
</style>

<style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
</style>
