<template>
  <div>
    <div class="d-flex justify-content-end">
      <feather-icon
        size="16"
        icon="InfoIcon"
        v-b-tooltip.hover
        class="pull-up mr-1"
        :title="$t('Training Participation Rate Tooltip')"
      />
    </div>
    <h5 class="font-weight-bolder ml-1 mt-n1">
      {{ $t("Training Participation Rate") }}
    </h5>
    <div class="row" v-if="attendance.length > 0">
      <div class="col-md-8">
        <vue-apex-charts
          type="radialBar"
          height="170"
          :options="goalOverviewRadialBar.chartOptions"
          :series="goalOverviewRadialBar.series"
        />
      </div>
      <div class="col-md-4">
        <div class="d-flex">
          <div class="end">
            <div class="d-flex justify-content-between">
              <span class="text-success h4">{{ $t("Participation") }}</span>
              <span class="ml-1 text-dark h4">{{ attended }}</span>
            </div>
            <div class="d-flex justify-content-between">
              <span class="text-danger h4">{{ $t("Absent") }}</span>
              <span class="ml-1 text-dark h4">{{ absent }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="d-flex justify-content-center align-items-center mt-2">
      <span class="text-center"> {{ $t("Serp PointS Not Found") }}</span>
    </div>
  </div>
</template>

<script>
  import { BCard, BCardHeader, BRow, BCol, BCardText, VBTooltip } from "bootstrap-vue"
  import VueApexCharts from "vue-apexcharts"
  import { $themeColors } from "@themeConfig"

  export default {
    components: {
      VueApexCharts,
    },
    directives: {
      "b-tooltip": VBTooltip,
    },
    props: {
      attendance: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        attended: 0,
        absent: 0,
        showLoading: true,
        goalOverviewRadialBar: {
          series: [],
          chartOptions: {
            chart: {
              sparkline: {
                enabled: true,
              },
              dropShadow: {
                enabled: true,
                blur: 3,
                left: 1,
                top: 1,
                opacity: 0.1,
              },
            },
            colors: ["#51e5a8"],
            plotOptions: {
              radialBar: {
                offsetY: -10,
                startAngle: -150,
                endAngle: 150,
                hollow: {
                  size: "77%",
                },
                track: {
                  background: "#ebe9f1",
                  strokeWidth: "50%",
                },
                dataLabels: {
                  name: {
                    show: false,
                  },
                  value: {
                    color: "#5e5873",
                    fontSize: "2.86rem",
                    fontWeight: "600",
                  },
                },
              },
            },
            fill: {
              type: "gradient",
              gradient: {
                shade: "dark",
                type: "horizontal",
                shadeIntensity: 0.5,
                gradientToColors: [$themeColors.success],
                inverseColors: true,
                opacityFrom: 1,
                opacityTo: 1,
                stops: [0, 100],
              },
            },
            stroke: {
              lineCap: "round",
            },
            grid: {
              padding: {
                bottom: 30,
              },
            },
          },
        },
      }
    },
    computed: {
      persentage() {
        let persentage = (this.attended / this.attendance.length) * 100
        if (persentage) {
          return Math.floor(persentage)
        } else {
          return 0
        }
      },
    },
    methods: {
      attendCalculate(items) {
        if (this.attendance.length < 1) return false
        let filteredItems = this.attendance.filter((item) => {
          return item.attendant == true
        })
        this.attended = filteredItems.length
      },
      absentCalculate(items) {
        if (this.attendance.length < 1) return false
        let filteredItems = this.attendance.filter((item) => {
          return item.attendant == false
        })
        this.absent = filteredItems.length
      },
    },
    async created() {
      this.attendCalculate()
      this.absentCalculate()
      this.goalOverviewRadialBar.series.push(this.persentage)
    },
  }
</script>
<style lang="scss" scoped>
  .col-md-8 {
    margin-left: -2vw;
    transform: scale(0.8);
  }
  .col-md-4 .end {
    margin-left: -3rem;
    margin-top: 4.25rem;
    @media (max-width: 768px) {
      margin-top: 0;
      margin-left: 0rem;
      float: right;
    }
    zoom: 0.9;
  }
</style>
