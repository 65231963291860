<template>
  <div>
    <b-card :title="$t('Find a Team')">
      <div class="row">
        <b-col md="4">
          <v-select
            :placeholder="$t('Team')"
            v-model="forms.team"
            :options="teams"
            @input="handleTeam"
            :getOptionLabel="(option) => (option.club ? option.club.name + ' - ' + option.name : option.name)"
          >
            <template v-slot:option="option">
              <span v-if="option.club">
                <img v-if="option.club" :src="option.club.logo" height="18" />
                {{ option.club.name }}
              </span>
              {{ option.name }}
            </template>
          </v-select></b-col
        >

        <b-col md="4">
          <v-select
            style="min-width: 150px"
            :placeholder="$t('Season')"
            v-model="forms.season"
            :options="seasons"
            :getOptionLabel="(option) => option.year"
            :reduce="(d) => d.id"
          ></v-select
        ></b-col>

        <b-col md="4">
          <b-button class="w-100" variant="primary" size="md" @click="addteam"> {{ $t("Compare") }} </b-button>
        </b-col>
      </div>
    </b-card>
    <b-card :title="$t('Compared Teams') + ' (' + team.length + ')'">
      <div class="mt-2 mx-n2" style>
        <b-overlay :show="isBusy">
          <div class="table-responsive" v-if="team.length > 0">
            <b-table
              sort-by="ps"
              :fields="[
                { key: 'team', label: 'Team', sortable: true, thStyle: { 'min-width': '350px' } },
                { key: 'competition.TOTAL', label: $t('OMS'), sortable: true },
                { key: 'competition.WIN', label: $t('Win'), sortable: true },
                { key: 'competition.DRAW', label: $t('Draw'), sortable: true },
                { key: 'competition.LOSE', label: $t('Loss'), sortable: true },
                { key: 'event.GOAL_AND_INCOMES.GOAL', label: $t('Goal'), sortable: true },
                { key: 'event.GOAL_AND_INCOMES.INCOME', label: $t('Goals Conceded'), sortable: true },
                { key: 'event.RED_CARD', label: $t('Red Card'), sortable: true },
                { key: 'event.YELLOW_CARD', label: $t('Yellow Card'), sortable: true },
                { key: 'points.TOTAL', label: $t('Points Total'), sortable: true },
                { key: 'points.AVERAGE', label: $t('Points Total Average'), sortable: true },
              ]"
              :items="team"
            >
              <template #head(competition)="scope">
                <div v-b-tooltip.hover :title="$t('Match played')">{{ $t("OMS") }}</div>
              </template>
              <template #cell(team)="data">
                <b-button
                  @click="
                    homeTeam.splice(data.index, 1)
                    awayTeam.splice(data.index, 1)
                    team.splice(data.index, 1)
                  "
                  size="sm"
                  variant="dark"
                >
                  <feather-icon icon="TrashIcon"></feather-icon>
                </b-button>
                <span class="ml-1 d-inline-block"
                  >{{ data.item.team ? data.item.team.club.name + " " + data.item.team.name : "" }}
                  {{ data.item.season && data.item.season.year }}
                </span>
              </template>
            </b-table>
          </div>
        </b-overlay>
      </div>
    </b-card>

    <b-card :title="$t('Teams HOME Comparison') + ' (' + team.length + ')'">
      <div class="mt-2 mx-n2" style>
        <b-overlay :show="isBusy">
          <div class="table-responsive" v-if="team.length > 0">
            <b-table
              sort-by="ps"
              :fields="[
                { key: 'team', label: 'Team', sortable: true, thStyle: { 'min-width': '350px' } },
                { key: 'competition.TOTAL', label: $t('OMS'), sortable: true },
                { key: 'competition.WIN', label: $t('Win'), sortable: true },
                { key: 'competition.DRAW', label: $t('Draw'), sortable: true },
                { key: 'competition.LOSE', label: $t('Loss'), sortable: true },
                { key: 'event.GOAL_AND_INCOMES.GOAL', label: $t('Goal'), sortable: true },
                { key: 'event.GOAL_AND_INCOMES.INCOME', label: $t('Goals Conceded'), sortable: true },
                { key: 'event.RED_CARD', label: $t('Red Card'), sortable: true },
                { key: 'event.YELLOW_CARD', label: $t('Yellow Card'), sortable: true },
                { key: 'points.TOTAL', label: $t('Points Total'), sortable: true },
                { key: 'points.AVERAGE', label: $t('Points Total Average'), sortable: true },
              ]"
              :items="homeTeam"
            >
              <template #head(competition)="scope">
                <div v-b-tooltip.hover :title="$t('Match played')">{{ $t("OMS") }}</div>
              </template>

              <template #cell(team)="data">
                <span class="ml-1 d-inline-block"
                  >{{ data.item.team ? data.item.team.club.name + " " + data.item.team.name : "" }}
                  {{ data.item.season && data.item.season.year }}
                </span>
              </template>
            </b-table>
          </div>
        </b-overlay>
      </div>
    </b-card>

    <b-card :title="$t('Teams AWAY Comparison') + ' (' + team.length + ')'">
      <div class="mt-2 mx-n2" style>
        <b-overlay :show="isBusy">
          <div class="table-responsive" v-if="team.length > 0">
            <b-table
              sort-by="ps"
              :fields="[
                { key: 'team', label: 'Team', sortable: true, thStyle: { 'min-width': '350px' } },
                { key: 'competition.TOTAL', label: $t('OMS'), sortable: true },
                { key: 'competition.WIN', label: $t('Win'), sortable: true },
                { key: 'competition.DRAW', label: $t('Draw'), sortable: true },
                { key: 'competition.LOSE', label: $t('Loss'), sortable: true },
                { key: 'event.GOAL_AND_INCOMES.GOAL', label: $t('Goal'), sortable: true },
                { key: 'event.GOAL_AND_INCOMES.INCOME', label: $t('Goals Conceded'), sortable: true },
                { key: 'event.RED_CARD', label: $t('Red Card'), sortable: true },
                { key: 'event.YELLOW_CARD', label: $t('Yellow Card'), sortable: true },
                { key: 'points.TOTAL', label: $t('Points Total'), sortable: true },
                { key: 'points.AVERAGE', label: $t('Points Total Average'), sortable: true },
              ]"
              :items="awayTeam"
            >
              <template #head(competition)="scope">
                <div v-b-tooltip.hover :title="$t('Match played')">{{ $t("OMS") }}</div>
              </template>

              <template #cell(team)="data">
                <span class="ml-1 d-inline-block"
                  >{{ data.item.team ? data.item.team.club.name + " " + data.item.team.name : "" }}
                  {{ data.item.season && data.item.season.year }}
                </span>
              </template>
            </b-table>
          </div>
        </b-overlay>
      </div>
    </b-card>
  </div>
</template>
<script>
  import { ref, onUnmounted, onMounted, onBeforeMount, watch } from "@vue/composition-api"
  import axios from "@/libs/axios"
  import vSelect from "vue-select"
  import router from "@/router"

  export default {
    components: { vSelect },
    setup(props, { root }) {
      const isBusy = ref(true)
      const teams = ref([])
      const team = ref([])
      const homeTeam = ref([])
      const awayTeam = ref([])
      const forms = ref({ player: [], team: [], selectPlayer: "", season: null })
      const season = ref(localStorage.getItem("season"))
      const seasons = ref(JSON.parse(localStorage.getItem("seasons")))

      const getteamfromRoute = async (teamSearch) => {
        try {
          var { data } = await axios.get("/team-compare?season=" + forms.value.season + "&team=" + teamSearch)
          team.value = data

          var { data } = await axios.get("/team-compare?side=home&season=" + forms.value.season + "&team=" + teamSearch)
          homeTeam.value = data

          var { data } = await axios.get("/team-compare?side=away&season=" + forms.value.season + "&team=" + teamSearch)
          awayTeam.value = data
        } catch (error) {
          console.log(error)
        }
      }

      onBeforeMount(async () => {
        let findteam = router.currentRoute.query.team != undefined ? router.currentRoute.query.team.split(",") : ""
        let uniqFindTeam = [...new Set(findteam)]
        if (uniqFindTeam.length > 0) getteamfromRoute(uniqFindTeam.toString())

        try {
          var { data } = await axios.get("/team")
          teams.value = data.data

          isBusy.value = false
        } catch (error) {}
      })

      const handleTeam = async () => {
        try {
          var { data } = await axios.get("/team/" + forms.value.team.id)
          forms.value.team = data
        } catch (error) {
          console.log("err", error)
        }
      }

      const addteam = async () => {
        // FOR ARRAY UNIQUE
        if (team.value.find((d) => d.team.id == forms.value.team.id && d.season?.id == forms.value.season)) return

        let teamVals = ""

        var { data } = await axios.get("/team-compare?season=" + forms.value.season + "&team=" + forms.value.team.id)
        forms.value.selectPlayer = {}

        team.value.push(data[0])

        var { data } = await axios.get(
          "/team-compare?side=home&season=" + forms.value.season + "&team=" + forms.value.team.id,
        )
        forms.value.selectPlayer = {}

        homeTeam.value.push(data[0])

        var { data } = await axios.get(
          "/team-compare?side=away&season=" + forms.value.season + "&team=" + forms.value.team.id,
        )
        forms.value.selectPlayer = {}

        awayTeam.value.push(data[0])
      }

      watch([team], () => {
        let teamUnique = [...new Set(team.value)]

        let teamVals = ""

        teamVals = Object.values(team.value).map((d) => d.team.id + (d.season ? "-" + d.season.id : ""))

        if (teamVals.toString() != router.currentRoute.query.team)
          router.replace({ query: { team: teamVals.toString() } })
      })

      const isOpen = ref(false)

      const getRandomKey = () => Math.floor(Math.random() * 99999 + 1)

      const findAge = (birthdate) =>
        Math.abs(new Date(Date.now() - new Date(birthdate).getTime()).getUTCFullYear() - 1970)

      return {
        awayTeam,
        homeTeam,
        team,
        teams,
        forms,

        isOpen,
        isBusy,
        getRandomKey,

        handleTeam,
        addteam,
        findAge,
        seasons,
        season,
      }
    },
  }
</script>
