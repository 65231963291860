<template>
  <b-card no-body>
    <b-modal id="modal-center" centered v-model="embedVideoModal" title="Video">
      <p class="my-1 embed-video">
        <span v-html="embedVideoRaw"></span>
      </p>

      <template #modal-footer>
        <div class="w-100"></div>
      </template>
    </b-modal>
    <b-table class="border" striped responsive :fields="playerHistoryItems" :items="playerHistoryData">
      <template #cell(musabaka)="data">
        <b> {{ data.value.home }} </b>
        <span
          class="badge"
          v-if="data.value.score != ''"
          :class="{
            'badge-success': data.value.status == 'win',
            'badge-danger': data.value.status == 'lose',
            'badge-primary': data.value.status == 'not started',
          }"
        >
          {{ data.value.score }}
        </span>
        <b> {{ data.value.away }} </b>
      </template>
      <template #cell(sure)="data">
        <span>
          {{ data.value }}
          <feather-icon icon="ClockIcon" size="24" scale="1" />
        </span>
      </template>
      <template #cell(video)="data">
        <span
          class="btn btn-light"
          :class="{ invisible: !data.value.embed }"
          v-b-modal.modal-center
          @click="embedVideoOpen(data.value.embed)"
        >
          <feather-icon icon="VideoIcon" size="24" scale="1" />
        </span>
      </template>
      <template #cell(Tarih)="data">
        <span>
          {{ data.value }}
          <feather-icon icon="CalendarIcon" size="24" scale="1" />
        </span>
      </template>
      <template #cell(Lig)="data">
        <span>
          {{ data.value }}
          <feather-icon icon="FlagIcon" size="24" scale="1" />
        </span>
      </template>
    </b-table>
  </b-card>
</template>

<script>
  import i18n from "@/libs/i18n"
  import { computed } from "@vue/composition-api"

  import {
    BIcon,
    BModal,
    BCard,
    BCardTitle,
    BCardHeader,
    BTable,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BInputGroup,
    BInputGroupPrepend,
  } from "bootstrap-vue"

  export default {
    components: {
      BIcon,
      BModal,
      BCard,
      BCardTitle,
      BCardHeader,
      BTable,
      BRow,
      BCol,
      BForm,
      BFormGroup,
      BFormInput,
      BButton,
      BInputGroup,
      BInputGroupPrepend,
    },
    props: ["playerHistoryData"],
    data() {
      return {
        embedVideoModal: false,
        embedVideo: "",
        embedVideoRaw: "",
      }
    },
    setup(props, { root }) {
      const playerHistoryItems = computed({
        get: () => [
          { label: i18n.t("Match"), key: "musabaka", sortable: true },
          { label: i18n.t("Time Played"), key: "Sure", sortable: true },
          { label: i18n.t("Date"), key: "Tarih", sortable: true },
          { label: i18n.t("League"), key: "Lig", sortable: true },
          { label: i18n.t("Video"), key: "Video", sortable: true },
        ],
      })

      return {
        playerHistoryItems,
      }
    },
    created() {},
    methods: {
      embedVideoOpen(data) {
        this.embedVideoModal = true
        this.embedVideoRaw = data
      },
    },
  }
</script>

<style lang="scss">
  .embed-video {
    iframe {
      max-width: 100%;
      max-height: 30vh;
    }
  }
</style>
