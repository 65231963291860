<template>
  <div>
    <div>
      <div class="text-center"></div>
      <div class="d-flex align-content-end flex-wrap d-none" style="display: none !important">
        <div
          v-for="pl in teamProps.team.teamPlayers"
          class="r frm text-center"
          style="position: relative"
          draggable="false"
          @dragstart="onDragging"
          @dragover="() => false"
          v-bind:id="teamProps.team.id + '_' + pl.id"
          :key="pl.id"
          v-b-tooltip.hover
          :title="pl.name"
        >
          <span class="badge">
            <b-avatar
              size="28"
              style="margin-left: 9px"
              :data-src="pl.avatar"
              :text="avatarText(pl.name)"
              variant="primary"
            ></b-avatar>
          </span>
          <formation-plugin-item :teamColor="{ second: '#3e2c56', first: '#3e2c56' }" />
          <br />
        </div>
      </div>

      <div style="width: 500px; margin: 0 auto">
        <div class="row row-cols-5 imgbg" :style="{ 'background-image': 'url(' + bgImgUrl + ')' }">
          <div
            v-for="(k, x) in cols"
            :key="k"
            v-bind:id="'boxdrop' + k"
            v-bind:dataid="teamProps.team.id + '_' + k"
            class="col"
            @drop="drop"
            @dragover="allowDrop"
          >
            <span class="no-text">{{ k }}</span>
            <span class="small-text"></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import FormationPluginItem from "./FormationPluginItem.vue"
  import { avatarText } from "@core/utils/filter"
  export default {
    components: {
      FormationPluginItem,
    },
    props: {
      teamProps: null,
      club: null,
      competitionFormations: null,
      helper: null,
    },
    data() {
      return {
        bgImgUrl: require("@/assets/images/stadium/stadium-half-design.png"),
        itemsFormation: null,
        draggingCount: "",
        dragging: "",
        items: [],
        newitems: [],
        dragitem: "",
        cols: [
          "FF LL",
          "FF L",
          "FF",
          "FF R",
          "FF RR",
          "CF LL",
          "CF L",
          "CF",
          "CF R",
          "CF RR",
          "AM LL",
          "AM L",
          "AM",
          "AM R",
          "AM RR",
          "AM I LL",
          "AM I L",
          "AM I",
          "AM I R",
          "AM I RR",
          "MM LL",
          "MM L",
          "MM",
          "MM R",
          "MM RR",
          "DM LL",
          "DM L",
          "DM",
          "DM R",
          "DM RR",
          "GK LL ",
          "GK L",
          "GK",
          "GK R",
          "GK RR",
        ],
      }
    },
    mounted() {
      if (this.competitionFormations) {
        this.customCreateFormation()
      }
    },
    watch: {},
    computed: {
      getFormationLineUps() {
        let quickFind = (findKey) => {
          return this.newitems.filter((data) => data.key.id.includes(findKey)).length
        }
        return `${quickFind("DM") + quickFind("MM")}-${quickFind("AM")}-${quickFind("CF") + quickFind("FF")}`
      },
    },
    methods: {
      customCreateFormation() {
        Object.values(this.competitionFormations).map((data) => {
          if (data.subPosition && data.isStarter == 1 && data.team.id == this.teamProps.team.id) {
            let nestName = this.teamProps.team.id + "_" + data.subPosition.trim()

            let nest = document.querySelector('[dataid="' + nestName + '"]')

            if (nest) {
              nest.append(document.getElementById(this.teamProps.team.id + "_" + data.player.id))

              this.newitems.push({
                key: {
                  id: nestName,
                  item: this.teamProps.team.id + "_" + data.player.id.toString(),
                },
              })
            }
          }
        })
        this.createFormation()
      },
      createFormation() {
        let countPosition = (countPositionKey) => {
          return Object.values(this.newitems).filter((el) => el.key.id == countPositionKey)
        }
        let countId = ["", "L", "R", "LL", "RR"]
        let findIsStarterPlayer = Object.values(this.competitionFormations).filter(
          (pl) => pl.team.id == this.teamProps.team.id && pl.isStarter === 1,
        )
        // this.newitems = [];
        let tempitems = {}

        Object.values(findIsStarterPlayer).map((pl, index) => {
          if (!pl.subPosition) {
            let playerAvailable = pl.position.availablePositions[0]
            if (!tempitems[playerAvailable]) tempitems[playerAvailable] = []
            tempitems[playerAvailable].push(pl.player.id)
          }
        })
        Object.keys(tempitems).map((data) => {
          Object.values(tempitems[data]).map((index, players) => {
            console.log(data, index, players, countPosition(data).length)
            if (countPosition(data).length == 1) {
              players = players + 1
            }
            let nestName = (data + " " + countId[players]).trim()
            if (nestName.includes("GK") && nestName.length > 3) return

            let nest = document.querySelector('[dataid="' + this.teamProps.team.id + "_" + nestName + '"]')

            if (nest && true) {
              nest.append(document.getElementById(this.teamProps.team.id + "_" + index))

              this.newitems.push({
                key: {
                  id: nestName,
                  item: this.teamProps.team.id + "_" + index.toString(),
                },
              })
            }
          })
        })
      },
      onDragging(ev) {
        ev.dataTransfer.setData("text", ev.target.id)
        this.dragitem = ev.target.id
      },
      allowDrop(ev) {
        this.dragging = ev.target.getAttribute("dataid")
        this.draggingCount = this.newitems.filter((d) => d.key.id == this.dragging).length
        if (this.draggingCount == 1) {
          return false
        }
        if (this.dragging && this.dragging.includes("GK") && this.dragging.length > 2) {
          return false
        }
        ev.preventDefault()
      },
      drag(ev) {
        ev.dataTransfer.setData("text", ev.target.id)
      },
      drop(ev) {
        ev.preventDefault()
        let data = ev.dataTransfer.getData("text")
        ev.target.appendChild(document.getElementById(data))

        let control = this.newitems.findIndex((d) => d.key.item == this.dragitem)
        if (control > -1) {
          this.newitems[control].key.id = ev.target.getAttribute("dataid")
        } else {
          this.newitems.push({
            key: { id: this.teamProps.team.id + "_" + ev.target.getAttribute("dataid"), item: this.dragitem },
          })
        }
        this.dragitem = null
        this.helper.submitRequestFormation(this.newitems)
      },
    },
    setup(props, { root }) {
      return {
        avatarText,
      }
    },
  }
</script>
<style lang="scss" scoped>
  .imgbg {
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position-y: 100% !important;
  }

  div.r {
    width: 30px;
    height: 30px;
    margin: 1pt;
    border-radius: 50%;
    text-align: center;
    display: flex;
    align-content: center;
    align-items: center;
    padding-left: 0.5rem;
  }

  .row > div {
    background: rgba(0, 0, 0, 0.01);
    border: 1px solid black;
    min-height: 70px;
    text-align: center;
    display: flex;
    align-items: center;
    border: 1px solid rgba(0, 0, 0, 0.02);
    justify-content: center;
  }

  div > .no-text {
    display: none;
  }

  .col > div.r {
    cursor: pointer;
    display: block !important;
  }

  .r.frm {
    cursor: pointer;

    > span.badge {
      display: none;
      position: absolute;
      z-index: 2;
      zoom: 0.8;
      margin-top: 5px;
    }
  }

  div.r.frm {
    padding: 0 !important;
    margin: 0 !important;
    all: unset;
  }
</style>
