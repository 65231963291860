import Vue from "vue"
import Vuex from "vuex"

// Modules
// import ecommerceStoreModule from '@/views/apps/e-commerce/eCommerceStoreModule'
// import settingStoreModule from '@/views/settings/SettingStoreModule'
import app from "./app"
import appConfig from "./app-config"
import verticalMenu from "./vertical-menu"

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    // 'app-ecommerce': ecommerceStoreModule,
    // 'club-setting': settingStoreModule,
  },
  strict: process.env.DEV,
})
