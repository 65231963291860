import { ref, watch, computed, onBeforeMount } from "@vue/composition-api"
import { useToast } from "vue-toastification/composition"
import { useRouter } from "@core/utils/utils"
import store from "@/store"

import staffService from "@/services/staffService"
import toastService from "@/services/toastService"

export default function useUserList() {
  const toast = useToast()

  const refUserListTable = ref(null)

  const tableColumns = [
    { key: "user", sortable: true },
    { key: "season", label: "season", sortable: true },
    { key: "team", label: "team", sortable: true },
    { key: "email", sortable: true },
    { key: "role", sortable: true },
    { key: "actions" },
  ]

  const totalUsers = ref(null)
  const perPage = ref(localStorage.getItem("perPage") || 10)
  const totalItems = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref("")
  const sortBy = ref("id")
  const isSortDirDesc = ref(true)
  const statusFilter = ref(null)
  const staffSer = new staffService()

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalItems.value,
    }
  })
  const staffsData = ref([])

  const { router, route } = useRouter()
  const seasonId = ref(Number(router.currentRoute.params.seasonId))
  const teamId = ref(Number(router.currentRoute.params.id))

  const refetchData = () => {
    getStaffs()
  }

  const deleteStaffItem = (id) => {
    staffSer.event({ staffId: id }).then((res) => {
      // TODO: REFRESH TABLE
      location.reload()
    })
  }

  const tableItems = computed(() => store.state["app-staff"].users)

  onBeforeMount(() => {
    getStaffs()
  })

  const getStaffs = (ctx) => {
    staffSer
      .getStaffs({ seasonId: seasonId.value, teamId: teamId.value })
      .then((response) => {
        const total = response.data.length
        totalUsers.value = total
        staffsData.value = response.data
      })
      .catch((error) => {
        console.log(error)
        root.toast("Error fetching staff list", "AlertTriangleIcon", "danger")
      })
  }

  /* const fetchStaffs = (ctx, callback) => {
    store
      .dispatch('app-staff/fetchDatas', {
        seasonId: 1,
        teamId: 3,
        queryParams: {
          q: searchQuery.value,
          perPage: perPage.value,
          page: currentPage.value,
          sortBy: sortBy.value,
          sortDesc: isSortDirDesc.value,
          status: statusFilter.value,
        }
      })
  } */

  watch([currentPage, perPage, searchQuery, statusFilter], () => {
    refetchData()
  })

  watch(perPage, (perPage) => {
    localStorage.setItem("perPage", perPage)
  })

  const resolveUserRoleVariant = (role) => {
    if (role === "subscriber") return "primary"
    if (role === "author") return "warning"
    if (role === "maintainer") return "success"
    if (role === "editor") return "info"
    if (role === "staff") return "danger"
    return "primary"
  }

  const resolveUserRoleIcon = (role) => {
    if (role === "subscriber") return "UserIcon"
    if (role === "author") return "SettingsIcon"
    if (role === "maintainer") return "DatabaseIcon"
    if (role === "editor") return "Edit2Icon"
    if (role === "staff") return "ServerIcon"
    return "UserIcon"
  }

  const resolveUserStatusVariant = (status) => {
    if (status === "pending") return "warning"
    if (status === "active") return "success"
    if (status === "inactive") return "secondary"
    return "primary"
  }

  return {
    tableColumns,
    perPage,
    currentPage,
    totalItems,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,
    statusFilter,
    refetchData,
    getStaffs,
    tableItems,
    staffsData,

    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    refetchData,
    deleteStaffItem,

    seasonId,
    teamId,
  }
}
