<template>
  <div>
    <div class="d-flex justify-content-end pr-2">
      <b-button
        :to="
          '/season/' +
          seasonId +
          '/team/' +
          teamId +
          '/test/enter/' +
          new Date().toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }) +
          '/player/' +
          routerParams.playerId
        "
        variant="primary"
        >{{ $t("Add New") }}</b-button
      >
    </div>
    <b-card v-if="tableItems.length > 0" no-body>
      <div>
        <vue-good-table
          ref="table"
          :columns="tableColumns"
          :rows="tableItems"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm,
          }"
          :pagination-options="{
            enabled: true,
            perPage: pageLength,
          }"
        >
          <template slot="table-row" slot-scope="props">
            <div class="d-flex align-items-start justify-content-start" v-if="props.column.field == 'date'">
              <b-link
                :to="{
                  name: 'team-player-tests-enter-date',
                  params: {
                    seasonId: seasonId,
                    id: teamId,
                    date: getFormattedDate(props.row.date),
                    playerId: routerParams.playerId,
                  },
                }"
              >
                <p>
                  {{ props.row.date | nativeDate }}
                </p>
              </b-link>
            </div>
          </template>

          <!-- pagination -->
          <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <b-form-select
                  v-model="pageLength"
                  :options="['3', '5', '10']"
                  class="mx-1"
                  @input="(value) => props.perPageChanged({ currentPerPage: value })"
                />
                <span class="text-nowrap"> {{ props.total }} {{ $t("Has records") }} </span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value) => props.pageChanged({ currentPage: value })"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </div>
    </b-card>

    <b-card v-else>
      <span> {{ $t("No records found") }} </span>
    </b-card>
  </div>
</template>

<script>
  import { ref, onMounted, watch } from "@vue/composition-api"
  import flatPickr from "vue-flatpickr-component"
  import { tr } from "flatpickr/dist/l10n/tr.js"

  import { VueGoodTable } from "vue-good-table"

  import i18n from "@/libs/i18n"
  import axios from "@axios"
  import router from "@/router"
  export default {
    components: {
      VueGoodTable,
      flatPickr,
    },
    data() {
      return {
        pageLength: 40,
        dir: false,
        rows: [],
        searchTerm: "",
      }
    },
    setup(props, { root }) {
      onMounted(async () => {
        await getColumns()
        await fetchTests()
      })

      const now = new Date()
      const maxDate = new Date(now.getFullYear(), now.getMonth(), now.getDate())
      const date = ref(new Date())

      const tableColumns = ref([])
      const tableDefaultColumns = [
        {
          field: "date",
          label: i18n.t("Measurement Date"),
          filterOptions: {
            enabled: false,
            placeholder: `${i18n.t("Search")} `,
          },
        },
        {
          field: "user.firstName",
          label: i18n.t("Reviewer"),
          filterOptions: {
            enabled: false,
            placeholder: `${i18n.t("Search")} `,
          },
        },
      ]
      const table = ref(null)
      const pageLength = ref(10)
      const tests = ref([])
      const tableItems = ref([])
      const seasonId = ref(Number(router.currentRoute.params.seasonId || router.currentRoute.params.sId))
      const teamId = ref(Number(router.currentRoute.params.id || router.currentRoute.params.tId))

      const getFormattedDate = (date) => {
        date = new Date(date)
        let tempMonth = date.getMonth() + 1
        let month = tempMonth < 10 ? "0" + tempMonth.toString() : tempMonth
        let day = date.getDate()
        let year = date.getFullYear()
        return year + `-` + month + `-` + day
      }

      const getColumns = async () => {
        axios.get("test-item").then((response) => {
          let formattedResponse = response.data.map((obj) => {
            return { field: obj.slug + ".value", label: i18n.t(obj.name) }
          })

          // comment move to end
          let commentIndex = formattedResponse
            .map(function (e) {
              return e.field
            })
            .indexOf("comment.value")
          if (commentIndex > -1) formattedResponse.push(formattedResponse.splice(commentIndex, 1)[0])

          tableColumns.value = [...tableDefaultColumns, ...formattedResponse]
        })
      }

      const fetchTests = () => {
        axios
          .get(`/season/${seasonId.value}/test/player/${router.currentRoute.params.playerId}/group-by-date`)
          .then((response) => {
            tableItems.value = Object.values(response.data)
          })
          .catch((error) => {
            console.log(error)
          })
      }

      return {
        table,
        tableItems,
        tableColumns,
        pageLength,
        seasonId,
        teamId,
        getFormattedDate,
      }
    },
  }
</script>
<style lang="scss">
  @import "@core/scss/vue/libs/vue-good-table.scss";
  @import "@core/scss/vue/libs/vue-flatpicker.scss";
  .vgt-inner-wrap {
    margin-top: 10px;
  }
</style>
