<template>
  <div class="flex-center-parent" @click="handleGoTo(data)">
    <div class="flex-center">
      <div class="left">
        <span class="teamText">
          {{ data.homeTeam.name + " " }}
          <template v-if="data.homeTeam.club && data.homeTeam.club.name">{{ data.homeTeam.club.name }}</template>
        </span>
        <span style="position: absolute; top: 0; right: 0"
          ><img v-if="data.homeTeam.club && data.homeTeam.club.logo" :src="data.homeTeam.club.logo" height="24" alt
        /></span>
      </div>
      <div class="center">
        <span
          class="badge px-1 text-light"
          v-if="data.status === 1 || isScout"
          :class="{
            'badge-success': routerParams.id ? data.winId == routerParams.id : teams.includes(data.winId),
            'badge-danger': routerParams.id ? data.winId != routerParams.id : !teams.includes(data.winId),
            'badge-dark': data.winId == 0 || isScout,
          }"
        >
          {{ data.homeScore }} - {{ data.awayScore }}
        </span>
        <!-- clock icon -->
        <span class="icon-time px-1" v-if="data.status === 0 && !isScout">
          <feather-icon v-b-tooltip.hover :title="$t('Pending')" icon="ClockIcon" size="24" scale="1" />
        </span>
      </div>
      <!-- away team -->
      <div class="right">
        <span style="position: absolute; top: 0; left: 0">
          <img v-if="data.awayTeam.club && data.awayTeam.club.logo" :src="data.awayTeam.club.logo" height="24" alt />
        </span>
        <span class="teamText">
          {{ data.awayTeam.name + " " }}
          <template v-if="data.awayTeam.club && data.awayTeam.club.name">{{ data.awayTeam.club.name }}</template>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      data: {
        type: Object,
        required: true,
      },
      isScout: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        teams: JSON.parse(localStorage.getItem("teams")).map((d) => d.id) || [],
      }
    },
    methods: {
      handleGoTo(comp) {
        if (this.isScout) {
          this.goToScoutMatch(comp)
        } else {
          this.goToMatch(comp)
        }
      },
      goToMatch(comp) {
        this.$router.push({
          name: "competition-edit",
          params: {
            seasonId: comp.season.id,
            id: this.routerParams.id ?? this.getTeamId(comp),
            competitionId: comp.id,
          },
        })
      },
      goToScoutMatch(comp) {
        this.$router.push({
          name: "scout-competition-edit",
          params: {
            competitionId: comp.id,
          },
        })
      },
      getTeamId(comp) {
        return this.teams.includes(comp.awayTeam.id) ? comp.awayTeam.id : comp.homeTeam.id
      },
    },
  }
</script>

<style lang="scss">
  .flex-center-parent {
    width: 32vw;
    margin: 0 auto;

    .right p {
      float: left;
    }
    .left p {
      float: right;
    }
    .flex-center div {
      float: left;
    }
    .left {
      width: 42%;
      position: relative;
      display: flex;
      padding-right: 30px;
    }
    .teamText {
      width: 100%;
    }
    .left > .teamText {
      text-align: right;
    }
    .right > .teamText {
      text-align: left;
    }
    .center {
      width: 16%;
      text-align: center;
    }
    .right {
      width: 42%;
      position: relative;
      display: flex;
      padding-left: 30px;
    }
    .bottom {
      display: block;
      margin: 0 auto;
      text-align: center;
    }
  }
</style>
