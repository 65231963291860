<template></template>
<script>
  import router from "@/router"

  export default {
    beforeMount() {
      let redirect = localStorage.getItem("redirect")
      if (redirect && redirect.length > 0) {
        setTimeout(() => {
          this.$router.push(redirect)
          localStorage.removeItem("redirect")
        }, 1)
      }
    },
  }
</script>
