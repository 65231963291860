export default [
  {
    path: "/season/list",
    name: "season-list",
    component: () => import("@/views/season/seasonList.vue"),
    meta: {
      resource: "Setting",
      action: "manage",
    },
  },
]
