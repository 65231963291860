<template>
  <div>
    <div>
      <div v-if="showSection.addMatchBasicForm == true">
        <addMatchBasicForm
          :competitionForm="competitionForm"
          :showSection.sync="showSection"
          :helperFuncs="{ addTeam, searchTeam, addLocation, submitCompetition }"
        ></addMatchBasicForm>
      </div>
    </div>
    <div class="" v-if="isSectionActive">
      <b-overlay rounded="sm" :show="!isSectionActive" style="min-height: 150px">
        <div class="mt-2 card card-body py-2">
          <addMatchInfoBar
            :competitionForm="competitionForm"
            :competition.sync="competition"
            :showSection.sync="showSection"
          ></addMatchInfoBar>
        </div>
      </b-overlay>
    </div>
    <div class="" v-if="isSectionActive">
      <div class="mt-2 card card-body py-2">
        <b-overlay rounded="sm" :show="!isSectionActive" style="min-height: 150px">
          <addMatchFormation
            :competition.sync="competition"
            :competitionForm.sync="competitionForm"
            :showSection.sync="showSection"
            :helperFuncs="{
              submitFormation,
              getTeamPlayers,
              submitRequestFormation: helper.submitRequestFormation,
              reloadTeamPlayers: helper.reloadTeamPlayers,
            }"
          ></addMatchFormation>
        </b-overlay>
      </div>
    </div>
    <div v-if="isSectionActive" class="mt-2 card card-body py-2">
      <addMatchFormationStat
        :key="competitionForm.formationStatTrigger"
        :showSection.sync="showSection"
        :competitionForm="competitionForm"
        :competition.sync="competition"
        :helper="helper"
      ></addMatchFormationStat>
    </div>
    <div v-if="isSectionActive" class="mt-2 card card-body py-2">
      <addCoachReview
        :show-section.sync="showSection"
        :competitionForm="competitionForm"
        :competition.sync="competition"
        :helperFuncs="{ submitFormationCoach }"
        :helper="helper"
      ></addCoachReview>
    </div>
    <div v-if="isSectionActive" class="mt-2 card card-body py-2">
      <addMatchAnalysis :competition.sync="competition" :competitionForm="competitionForm"></addMatchAnalysis>
    </div>
  </div>
</template>

<script>
  import vSelect from "vue-select"
  import addMatchBasicForm from "./addMatchBasicForm"
  import addMatchInfoBar from "./addMatchInfoBar"
  import addMatchFormation from "./addMatchFormation"
  import addMatchFormationStat from "./addMatchFormationStat"
  import addMatchAnalysis from "./addMatchAnalysis"
  import addCoachReview from "./addCoachReview"
  import flatPickr from "vue-flatpickr-component"
  import useAddMatch from "./useAddMatch"

  export default {
    components: {
      vSelect,
      flatPickr,
      addMatchBasicForm,
      addMatchInfoBar,
      addMatchFormationStat,
      addMatchAnalysis,
      addMatchFormation,
      addCoachReview,
    },
    methods: {},
    computed: {
      isSectionActive() {
        return (
          this.showSection.Formation == true &&
          this.competitionForm.awayTeam.select != null &&
          this.competitionForm.homeTeam.select != null
        )
      },
    },
    created() {},
    data() {
      return {}
    },
    destroyed() {},
    setup(props, { root }) {
      const {
        addTeam,
        submitCompetition,
        addLocation,
        searchTeam,
        showSection,
        competitionForm,
        competition,
        submitFormation,
        submitFormationCoach,
        getTeamPlayers,
        helper,
        viewTrigger,
      } = useAddMatch()

      return {
        addTeam,
        submitCompetition,
        addLocation,
        searchTeam,
        showSection,
        competitionForm,
        competition,
        submitFormation,
        submitFormationCoach,
        getTeamPlayers,
        helper,
        viewTrigger,
      }
    },
  }
</script>

<style lang="scss" scoped>
  .h-40 {
    height: 40px;
  }

  .inp {
    box-shadow: unset !important;
  }
</style>
