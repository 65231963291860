import http from "@/libs/axios"
import toastService from "./toastService"
import i18n from "@/libs/i18n"

export default class recommenderService {
  constructor() {}

  async get(filters) {
    return new Promise((resolve, reject) => {
      http
        .get("/recommender", { params: { ...filters } })
        .then((response) => resolve(response))
        .catch((error) => reject(error))
    })
  }

  async event({ data, dataId }) {
    if (data && dataId) {
      return false
    } else if (dataId) {
      return false
    } else if (data) {
      return new Promise((resolve, reject) => {
        http
          .post("/recommender", data)
          .then((response) => {
            toastService(i18n.t("Successfully Created"), "PlusIcon", "success")
            return resolve(response)
          })
          .catch((error) => reject(error))
      })
    }
  }
}
