<template>
  <div>
    <b-card no-body>
      <b-card-header class="pb-50">
        <h5>{{ $t("Filter") }}</h5>
      </b-card-header>
      <b-card-body>
        <b-row>
          <!-- Search -->
          <b-col cols="12" md="6" class="mb-md-0 mb-2">
            <label>{{ $t("Player Name") }}</label>
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                :placeholder="$t('Search')"
                type="search"
              />
            </div>
          </b-col>
          <b-col cols="12" md="3" class="mb-md-0 mb-2">
            <label>{{ $t("Position") }}</label>
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              v-model="position"
              :options="positionOptions"
              class="w-100"
              :reduce="(item) => item.id"
              :getOptionLabel="(item) => item.name"
            />
          </b-col>
          <b-col cols="12" md="3" class="mb-md-0 mb-2">
            <label>{{ $t("Scout Review") }}</label>
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="coachReview"
                class="d-inline-block mr-1"
                :placeholder="$t('Scout Review')"
                type="search"
              />
            </div>
            <!-- <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              v-model="team"
              :options="teams"
              class="w-100"
              :reduce="(item) => item.id"
              :getOptionLabel="(item) => (item.club ? item.club.name : '') + ' / ' + item.name"
            /> -->
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <b-card action-collapse>
      <transfer-sidebar
        :is-sidebar-active.sync="isSidebarActive"
        :seasons="seasons"
        :teams="teams"
        :players="transferablePlayers"
        :form.sync="form"
      />
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <label>{{ $t("Show") }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
          </b-col>
        </b-row>
      </div>
      <div class="mx-n2">
        <b-table
          ref="refScoutPlayers"
          :fields="fieldsFiltered"
          :items="getFilteredPlayers"
          class="position-relative"
          :empty-text="$t('No records found')"
          responsive
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          :sort-desc.sync="isSortDirDesc"
          busy.sync="false"
          :per-page="perPage"
          :current-page="currentPage"
          style="min-height: 60vh"
        >
          <template #table-busy>
            <div class="text-center my-2">
              <b-spinner class="align-middle"></b-spinner>
            </div>
          </template>
          <template #cell(id)="data">
            <b-link @click="goToPlayer(data.item.player.id)">
              <span class="text-nowrap">{{ data.value }}</span>
            </b-link>
          </template>
          <template #cell(competition.date)="data">
            <span class="">{{ data.item.competition.date | shortDate }}</span>
          </template>
          <template #cell(p.name)="data">
            <b-link @click="goToPlayer(data.item.player.id)">
              <span class="">{{ data.item.player.name }}</span>
            </b-link>
          </template>
          <template #cell(position)="data">
            <b-badge pill :variant="data.value.color">
              {{ $t(data.value.name) }}
            </b-badge>
          </template>
          <template #cell(team)="data">
            <span class="">{{ data.value.name }}</span>
          </template>
          <template #cell(competition)="data">
            <competition-card :data="data.item.competition" isScout />
          </template>
          <template #cell(review)="data">
            {{ data.item.coachReview }}
          </template>
          <template #cell(comment)="data">
            <span class="">{{ data.item.coachComment ? data.item.coachComment : "" }}</span>
          </template>
          <!-- <template #cell(actions)="data">
            <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
              <template #button-content>
                <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
              </template>
              <b-dropdown-item
                :to="{
                  name: 'scout-player-edit',
                  params: { playerId: data.item.player.id },
                }"
              >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">{{ $t("Edit") }}</span>
              </b-dropdown-item>
              <b-dropdown-item v-if="!data.item.player" @click="openSidebar(data.item)">
                <feather-icon icon="CornerLeftUpIcon" />
                <span class="align-middle ml-50">{{ $t("Transfer") }}</span>
              </b-dropdown-item>

              <b-dropdown-item @click="goDelete(data.item.id)">
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">{{ $t("Delete") }} </span>
              </b-dropdown-item>
            </b-dropdown>
          </template> -->
        </b-table>

        <div class="mx-2 mb-2">
          <b-row>
            <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
              <span class="text-muted">{{
                $t("format.showingData", { from: dataMeta.from, to: dataMeta.to, of: dataMeta.of })
              }}</span>
            </b-col>
            <!-- Pagination -->
            <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalItems"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
  import vSelect from "vue-select"
  import i18n from "@/libs/i18n"
  import { ref, watch, onMounted, computed } from "@vue/composition-api"
  import http from "@/libs/axios"
  import store from "@/store"
  import { useRouter } from "@core/utils/utils"
  import TransferSidebar from "@/views/scout/player/transfer-sidebar.vue"
  import FilterList from "@/views/scout/player/filter-list.vue"
  import localService from "@/services/localService.js"
  import CompetitionCard from "@core/components/global-components/CompetitionCard"

  export default {
    components: {
      vSelect,
      TransferSidebar,
      FilterList,
      CompetitionCard,
    },
    methods: {
      goDelete(dataId) {
        this.$bvModal
          .msgBoxConfirm(this.$t("Please confirm that you want to delete this."), {
            title: this.$t("Please Confirm"),
            size: "sm",
            okVariant: "primary",
            okTitle: this.$t("Yes"),
            cancelTitle: this.$t("Cancel"),
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          })
          .then((value) => {
            if (value) {
              http
                .delete(`/scout/player/${dataId}`)
                .then((res) => {
                  this.toast(i18n.t("Successfully Deleted"), "PlusIcon", "success")
                  this.refScoutPlayers.refresh()
                })
                .catch((error) => {
                  console.log(error)
                })
            }
          })
      },
      goToMatch(competition) {
        this.$router.push({
          name: "scout-competition-edit",
          params: {
            id: this.$router.currentRoute.params.id,
            competitionId: competition.id,
          },
        })
      },
    },
    setup(props, { root, context }) {
      const { router, route } = useRouter()
      const isSidebarActive = ref(false)
      const players = ref([])
      const seasons = ref([])

      const form = ref({
        player: null,
        team: null,
        season: null,
        data: null,
      })

      const fieldsFiltered = computed({
        get: () => [
          {
            key: "id",
            label: i18n.t("Id"),
            sortable: true,
            class: "p-1",
            thStyle: { width: "50px", "min-width": "30px", "max-width": "50px" },
          },
          {
            key: "competition.date",
            label: i18n.t("Date"),
            sortable: true,
            class: "p-1",
            thStyle: { width: "100px", "min-width": "100px", "max-width": "100px" },
          },
          {
            key: "p.name",
            label: i18n.t("Name"),
            sortable: true,
            class: "p-1",
            thStyle: { width: "150px", "min-width": "150px", "max-width": "150px" },
          },
          {
            key: "position",
            label: i18n.t("Position"),
            class: "p-1",
            thStyle: { width: "80px", "min-width": "50px", "max-width": "80px" },
          },
          {
            key: "team",
            label: i18n.t("Team"),
            class: "p-1 text-center",
            thStyle: { width: "80px", "min-width": "50px", "max-width": "80px" },
          },
          {
            key: "competition",
            label: i18n.t("competition"),
            class: "p-1 text-center",
            thStyle: { "min-width": "10rem" },
          },
          {
            key: "review",
            label: i18n.t("points"),
            class: "p-1 text-center",
            thStyle: { "min-width": "70px" },
          },
          {
            key: "comment",
            label: i18n.t("Comment"),
            class: "p-1 text-center",
            thStyle: { width: "200px", "min-width": "200px", "max-width": "220px" },
          },
          // {
          //   key: "actions",
          //   label: i18n.t("Action"),
          //   thStyle: { width: "80px", "min-width": "50px", "max-width": "80px" },
          // },
        ],
      })

      const foot = ref(null)
      const position = ref(null)
      const positionOptions = ref([])
      const coachReview = ref(null)
      const team = ref(null)
      const teams = ref([])
      let clubLocal = JSON.parse(localStorage.getItem("teams"))
        ? JSON.parse(localStorage.getItem("teams"))
            .filter((i) => i.club)
            .map((i) => i.club.id)
        : []

      const isSortDirDesc = ref(true)
      const perPage = ref(localStorage.getItem("perPage") || 10)
      const totalItems = ref(0)
      const searchQuery = ref("")
      const currentPage = ref(1)
      const sortBy = ref("id")
      const perPageOptions = [10, 25, 50, 100]
      const showTable = ref("")

      onMounted(async () => {
        showTable.value = new localService("scout_player_tab").get() || "overview"
        await getTeams()
        await getSeasons()
        await getPositions()
      })

      const getFilteredPlayers = () => {
        return http
          .get(`/scout/competition-formations`, {
            params: {
              foot: foot.value,
              position: position.value,
              team: team.value,
              page: currentPage.value,
              row: perPage.value,
              sortBy: sortBy.value ? sortBy.value : "id",
              isDesc: isSortDirDesc.value,
              name: searchQuery.value,
              coach_review: coachReview.value,
            },
          })
          .then((res) => {
            players.value = res.data.data
            totalItems.value = res.data.count

            return players.value
          })
          .catch((error) => console.log(error))
      }

      const getTestingPlayer = () => {
        return http
          .get(`/filtered-player`, {
            params: {
              foot: foot.value,
              position: position.value,
              team: team.value,
              page: currentPage.value,
              row: perPage.value,
              sortBy: sortBy.value ? sortBy.value : "id",
              isDesc: isSortDirDesc.value,
              isTesting: 1,
              name: searchQuery.value,
            },
          })
          .then((res) => {
            players.value = res.data.data
            totalItems.value = res.data.count

            return players.value
          })
          .catch((error) => console.log(error))
      }
      const getTeams = () => {
        http
          .get(`/team?club=${clubLocal.join(",")}`)
          .then((res) => {
            teams.value = res.data.data
          })
          .catch((error) => console.log(error))
      }

      const getSeasons = () => {
        http
          .get(`/season`)
          .then((res) => {
            seasons.value = res.data
          })
          .catch((error) => console.log(error))
      }

      const getPositions = () => {
        http
          .get(`/position`)
          .then((res) => {
            positionOptions.value = res.data
          })
          .catch((error) => console.log(error))
      }

      const goToPlayer = (id) => {
        router.push({
          name: "scout-player-view",
          params: {
            playerId: id,
          },
        })
      }

      const goToAddPlayer = (id) => {
        router.push({
          name: "scout-player-add",
        })
      }

      const openSidebar = (player) => {
        isSidebarActive.value = true
        form.value.player = player.id
        form.value.data = player
      }

      const transferablePlayers = computed(() => players.value.filter((item) => !item.player))

      const dataMeta = computed(() => {
        const localItemsCount = players.value.length > 0 ? players.value.length : 0
        return {
          from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
          to: perPage.value * (currentPage.value - 1) + localItemsCount,
          of: totalItems.value,
        }
      })

      const refScoutPlayers = ref()
      const refTestingPlayers = ref()

      const showInfoModal = (player) => {
        root.$bvModal
          .msgBoxConfirm(i18n.t("Please confirm that you want to squad the player."), {
            title: i18n.t("Please Confirm"),
            size: "sm",
            okVariant: "primary",
            okTitle: i18n.t("Yes"),
            cancelTitle: i18n.t("No"),
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          })
          .then((value) => {
            if (value) {
              transferTestingToSquad(player.id)
            }
          })
      }

      const transferTestingToSquad = async (playerId) => {
        var { data } = await http.put(`/player/${playerId}/testing`, { isTesting: 0 })
        if (data) {
          refTestingPlayers.value.refresh()
        }
      }

      watch([foot, position, team, searchQuery, coachReview], () => {
        refScoutPlayers.value.refresh()
      })

      watch(perPage, (perPage) => {
        localStorage.setItem("perPage", perPage)
      })
      watch([showTable], () => {
        currentPage.value = 1
        new localService("scout_player_tab", showTable.value)
      })

      return {
        refScoutPlayers,
        fieldsFiltered,
        players,
        goToPlayer,
        isSidebarActive,
        form,
        seasons,
        teams,
        openSidebar,
        goToAddPlayer,
        getFilteredPlayers,

        foot,
        position,
        positionOptions,
        team,
        coachReview,

        isSortDirDesc,
        perPage,
        currentPage,
        searchQuery,
        sortBy,
        perPageOptions,
        dataMeta,
        totalItems,
        transferablePlayers,
        showTable,
        getTestingPlayer,
        showInfoModal,
        refTestingPlayers,
      }
    },
  }
</script>

<style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
</style>
