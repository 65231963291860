export default [
  {
    path: "/sms-log",
    name: "sms-log",
    component: () => import("@/views/sms-log/index.vue"),
    meta: {
      resource: "all",
      action: "manage",
    },
  },
]
