<template>
  <div>
    <b-overlay :show="userCreateLoading" rounded="sm">
      <b-card>
        <validation-observer #default="{}" ref="userAddForm">
          <form @submit.prevent="addSubmit()">
            <div class="card-header p-0 m-0 mb-2">
              <div>
                <b-card-title>
                  <feather-icon icon="UserPlusIcon" size="24" />
                  {{ $t("Add New User") }}
                </b-card-title>
              </div>
            </div>
            <b-row>
              <b-col md="6" xl="4" class="mb-1">
                <label>{{ $t("Username") }}</label>
                <b-form-group>
                  <validation-provider #default="{ errors }" name="userName" rules="required">
                    <b-input
                      v-model="userInitform.userName"
                      :placeholder="$t('Username')"
                      :state="errors[0] ? false : null"
                    ></b-input>
                    <div>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </div>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6" xl="4" class="mb-1">
                <label>{{ $t("First Name") }}</label>
                <b-form-group>
                  <validation-provider #default="{ errors }" name="firstName" rules="required">
                    <b-input
                      v-model="userInitform.firstName"
                      :placeholder="$t('First Name')"
                      :state="errors[0] ? false : null"
                    ></b-input>
                    <div>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </div>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6" xl="4" class="mb-1">
                <label>{{ $t("Last Name") }}</label>
                <b-form-group>
                  <validation-provider #default="{ errors }" name="lastName" rules="required">
                    <b-input
                      v-model="userInitform.lastName"
                      :placeholder="$t('Last Name')"
                      :state="errors[0] ? false : null"
                    ></b-input>
                    <div>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </div>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6" xl="4" class="mb-1">
                <label>{{ $t("Email") }}</label>
                <b-form-group>
                  <validation-provider #default="{ errors }" name="email" rules="required|email">
                    <input
                      v-model="userInitform.email"
                      readonly
                      onfocusout="this.setAttribute('readonly','true');"
                      onfocus="this.removeAttribute('readonly');"
                      autocomplete="off"
                      placeholder="address@email.com"
                      :state="errors[0] ? false : null"
                      class="ready-only-email form-control"
                    />
                    <div>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </div>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6" xl="4" class="mb-1">
                <validation-provider #default="{ errors }" rules="required|min:4" vid="newPassword" name="password">
                  <b-form-group label-for="account-new-password" :label="$t('Password')">
                    <b-input-group class="input-group-merge" :class="errors[0] ? 'is-invalid' : null">
                      <b-form-input
                        id="account-new-password"
                        v-model="userInitform.password"
                        :type="passwordFieldType"
                        name="new-password"
                        :placeholder="$t('Password')"
                        :state="errors[0] ? false : null"
                      />
                      <b-input-group-append is-text>
                        <feather-icon :icon="passwordToggleIconNew" class="cursor-pointer" @click="togglePasswordNew" />
                      </b-input-group-append>
                    </b-input-group>

                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="6" xl="4" class="mb-1">
                <validation-provider #default="{ errors }" name="role" rules="required">
                  <b-form-group>
                    <label for="">{{ $t("role") }}</label>
                    <v-select
                      :searchable="false"
                      :placeholder="$t('role')"
                      v-model="userInitform.role"
                      :options="userInitFormRoleList"
                      :getOptionLabel="(option) => $t('ROLES.' + option.name)"
                    />

                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="6" xl="4" class="mb-1">
                <label>{{ $t("Birth Date") }}</label>
                <b-form-group>
                  <validation-provider #default="{ errors }" name="birthdate">
                    <div>
                      <!-- <b-input v-model="userInitform.birthdate" id="bDate" placeholder="AA/GG/YYYY" :state="errors[0] ? false : null"></b-input> -->
                      <b-form-datepicker
                        :locale="lang"
                        v-model="userInitform.birthDate"
                        id="bDate"
                        placeholder="AA/GG/YYYY"
                      ></b-form-datepicker>
                      <!-- <selectable-date v-model="userInitform.birthDate"></selectable-date> -->
                    </div>
                    <div>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </div>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6" xl="4" class="mb-1">
                <label>{{ $t("Mobile") }}</label>
                <b-form-group>
                  <validation-provider #default="{ errors }" name="mobile">
                    <b-input v-model="userInitform.phone" id="pPhone" placeholder="555 444 33 22"></b-input>
                    <div>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </div>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="6" xl="4" class="mb-1 mt-n2">
                <label></label>
                <b-form-group>
                  <b-button type="submit" variant="primary" class="w-100" submit>{{ $t("Add") }} </b-button>
                </b-form-group>
              </b-col>
            </b-row>
          </form>
        </validation-observer>
      </b-card>
    </b-overlay>
  </div>
</template>
<script>
  import vSelect from "vue-select"
  import { ValidationProvider, ValidationObserver } from "vee-validate"
  import { required, email } from "@validations"
  import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
  import Cleave from "cleave.js"
  import SelectableDate from "@/@core/components/global-components/SelectableDate"
  import i18n from "@/libs/i18n"

  export default {
    name: "UserAddComponent",
    components: {
      ValidationProvider,
      ValidationObserver,
      vSelect,
      ToastificationContent,
      SelectableDate,
    },
    data() {
      return {
        value: "",
        formatted: "",
        selected: "",

        passwordFieldType: "password",
        userInitFormRoleList: [{}],
        userCreateLoading: true,
        userInitform: {
          name: null,
          phone: null,
          email: null,
        },
        lang: i18n.locale,
      }
    },
    computed: {
      passwordToggleIconNew() {
        return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon"
      },
    },
    methods: {
      onContext(ctx) {
        // The date formatted in the locale, or the `label-no - date - selected` string
        this.formatted = ctx.selectedFormatted
        // The following will be an empty string until a valid date is entered
        this.selected = ctx.selectedYMD
      },
      togglePasswordNew() {
        this.passwordFieldType = this.passwordFieldType === "password" ? "text" : "password"
      },
      addSubmit() {
        this.$refs.userAddForm.validate().then((success) => {
          if (!success) {
            return false
          }
          this.userCreateLoading = true
          this.userSendForm = {
            userName: this.userInitform.userName,
            firstName: this.userInitform.firstName,
            lastName: this.userInitform.lastName,
            email: this.userInitform.email,
            phone: this.userInitform.phone,
            password: this.userInitform.password,
            role: this.userInitform.role.id,
            birthDate: this.userInitform.birthDate,
            platform: "client_web",
          }
          this.$http
            .post("/user/register", this.userSendForm)
            .then((succ) => {
              this.userCreateLoading = false
              let newUserId = succ.data.id
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: this.$t("User saved successfully"),
                  icon: "UserIcon",
                  variant: "success",
                },
              })

              this.$router.push({
                name: "user-manage",
                params: {},
              })
            })
            .catch((err) => {
              this.userCreateLoading = false
            })
        })
      },
    },
    props: {},
    mounted() {
      new Cleave("#pPhone", {
        numericOnly: true,
        delimiter: " ",
        blocks: [3, 3, 2, 2],
      })
    },
    created() {
      this.$http.get(`/user_manage/role/list`).then((data) => {
        this.userInitFormRoleList = data.data
      })

      setTimeout(() => {
        this.userCreateLoading = false
      }, 500)
    },
  }
</script>
<style>
  .ready-only-email {
    background-color: #fff !important;
  }
</style>
