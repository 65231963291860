var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('b-media',{attrs:{"no-body":""}},[_c('b-media-aside',[_c('b-link',[_c('b-avatar',{ref:"previewEl",attrs:{"src":_vm.generalData.avatar,"size":"90px","rounded":""}})],1)],1),_c('b-media-body',{staticClass:"mt-75 ml-75"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mb-75 mr-75",attrs:{"variant":"primary","size":"sm"},on:{"click":function($event){return _vm.$refs.refInputEl.$el.click()}}},[_vm._v(" "+_vm._s(_vm.$t("Dosya Seçiniz"))+" ")]),_c('b-form-file',{ref:"refInputEl",attrs:{"browseText":_vm.$t('Browse'),"accept":".jpg, .png, .gif","hidden":true,"plain":""},on:{"input":_vm.inputImageRenderer,"change":_vm.onFileSelected}}),_c('b-card-text',[_vm._v(_vm._s(_vm.$t("File Types"))+" JPG, GIF, PNG. "+_vm._s(_vm.$t("Max file size"))+" 800kB")])],1)],1),_c('validation-observer',{ref:"refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{staticClass:"mt-2",on:{"submit":function($event){$event.preventDefault();handleSubmit(_vm.goSubmitGeneral())}}},[_c('b-row',[_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"rules":"required","name":"userName"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('User Name'),"label-for":"account-username"}},[_c('b-form-input',{attrs:{"placeholder":_vm.$t('User Name'),"name":"username","state":errors[0] ? false : null},model:{value:(_vm.generalData.userName),callback:function ($$v) {_vm.$set(_vm.generalData, "userName", $$v)},expression:"generalData.userName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"rules":"required","name":"firstName"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('First Name'),"label-for":"account-firstName"}},[_c('b-form-input',{attrs:{"name":"firstName","placeholder":"İsim","state":errors[0] ? false : null},model:{value:(_vm.generalData.firstName),callback:function ($$v) {_vm.$set(_vm.generalData, "firstName", $$v)},expression:"generalData.firstName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"rules":"required|email","name":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Email'),"label-for":"account-email"}},[_c('b-form-input',{attrs:{"name":"email","placeholder":"Email","state":errors[0] ? false : null},model:{value:(_vm.generalData.email),callback:function ($$v) {_vm.$set(_vm.generalData, "email", $$v)},expression:"generalData.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":"team"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Team'),"label-for":"account-company"}},[_c('b-form-input',{attrs:{"name":"teamName","placeholder":_vm.$t('Team Name'),"state":errors[0] ? false : null},model:{value:(_vm.generalData.company),callback:function ($$v) {_vm.$set(_vm.generalData, "company", $$v)},expression:"generalData.company"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{staticClass:"mt-75",attrs:{"cols":"12"}},[_c('b-alert',{directives:[{name:"show",rawName:"v-show",value:(!(_vm.generalData.enabled || true)),expression:"!(generalData.enabled || true)"}],staticClass:"mb-50",attrs:{"show":"","variant":"warning"}},[_c('h4',{staticClass:"alert-heading"},[_vm._v(" "+_vm._s(_vm.$t("Confirm your email account"))+" ")]),_c('div',{staticClass:"alert-body"},[_c('b-link',{staticClass:"alert-link"},[_vm._v(" "+_vm._s(_vm.$t("Resend confirmation email"))+" ")])],1)])],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-2 mr-1",attrs:{"variant":"primary","type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t("Save changes"))+" ")])],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }