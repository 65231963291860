<template>
  <div class="py-2">
    <b-overlay :show="loading" rounded="sm">
      <ckeditor
        class="ck-editor-style"
        :editor="editor"
        v-model="textData"
        :config="editorConfig"
        @ready="onEditorReady"
        @paste="onPaste(...arguments, textData)"
      >
      </ckeditor>
    </b-overlay>
  </div>
</template>

<script>
  //CKEDITOR5

  import InlineEditor from "@ckeditor/ckeditor5-editor-inline/src/inlineeditor"
  import SimpleUploadAdapter from "@ckeditor/ckeditor5-upload/src/adapters/simpleuploadadapter"
  import EssentialsPlugin from "@ckeditor/ckeditor5-essentials/src/essentials"
  import BoldPlugin from "@ckeditor/ckeditor5-basic-styles/src/bold"
  import ItalicPlugin from "@ckeditor/ckeditor5-basic-styles/src/italic"
  import LinkPlugin from "@ckeditor/ckeditor5-link/src/link"
  import ParagraphPlugin from "@ckeditor/ckeditor5-paragraph/src/paragraph"

  import Image from "@ckeditor/ckeditor5-image/src/image"
  import ImageToolbar from "@ckeditor/ckeditor5-image/src/imagetoolbar"
  import ImageCaption from "@ckeditor/ckeditor5-image/src/imagecaption"
  import ImageUpload from "@ckeditor/ckeditor5-image/src/imageupload"
  import AutoImage from "@ckeditor/ckeditor5-image/src/autoimage"
  import ImageInsert from "@ckeditor/ckeditor5-image/src/imageinsert"
  import PasteFromOffice from "@ckeditor/ckeditor5-paste-from-office/src/pastefromoffice"

  //CUSTOM
  import CKEditor from "@/libs/custom-ck-editor"
  import { ref, onMounted, watch } from "@vue/composition-api"
  import axios from "@axios"
  import uploadAdapter from "@/services/uploadAdapterService"

  export default {
    components: {
      ckeditor: CKEditor.component,
    },
    directives: {},
    props: {
      editorData: {
        type: String,
      },
    },
    data() {
      return {
        editor: InlineEditor,
        editorConfig: {
          plugins: [
            EssentialsPlugin,
            BoldPlugin,
            ItalicPlugin,
            LinkPlugin,
            ParagraphPlugin,
            Image,
            ImageToolbar,
            ImageCaption,
            ImageUpload,
            AutoImage,
            ImageInsert,
            PasteFromOffice,
          ],

          toolbar: {
            items: ["bold", "italic", "link", "undo", "redo", "insertImage"],
          },
        },
      }
    },
    setup(props, { emit, root }) {
      const loading = ref(false)
      const textData = ref(null)

      const onPaste = async (evt, data, value) => {
        const files = data.dataTransfer.files
        if (files.length > 0) {
          loading.value = true
          let htmlData = data.dataTransfer.getData("text/html")
          if (htmlData) {
            const sources = htmlData
              .match(/<img [^>]*src="[^"]*"[^>]*>/gm)
              .map((x) => x.replace(/.*src="([^"]*)".*/, "$1"))
            const oldUrl = sources[0]
            UploadPastedMedia(files[0], value)
          } else {
            UploadPastedMedia(files[0], value)
          }
        }
      }

      watch([textData], (newv, oldv) => {
        emit("changedCKEditor", newv[0])
      })

      const UploadPastedMedia = (file, value) => {
        value === null ? (value = "") : value
        let axiosData = new FormData()
        axiosData.append("upload", file)
        axios
          .post(process.env.VUE_APP_CDN_UPLOAD_API, axiosData)
          .then((response) => {
            let tempData = value + `<figure class="image"><img src="${response.data.url}"></figure>`
            emit("changedCKEditor", tempData)
            textData.value = tempData
            loading.value = false
          })
          .catch((error) => {
            root.toast("Upload error, please use small image and re-try again", "XIcon", "danger")
            loading.value = false
          })
      }

      // onMounted(() => {
      //   textData.value = props.editorData;
      // });

      const onEditorReady = (editor) => {
        textData.value = props.editorData
        editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
          // console.log(loader.file())
          return new uploadAdapter(loader)
        }
      }

      return {
        onPaste,
        loading,
        textData,
        onEditorReady,
      }
    },
  }
</script>

<style lang="scss">
  .ck-editor-style {
    border: 1px solid rgb(221, 216, 216) !important;
    min-height: 330px;
    max-height: 500px;
  }
</style>
