import { $themeBreakpoints } from "@themeConfig"
import axios from "@axios"

export default {
  namespaced: true,
  state: {
    apps: {},
    windowWidth: 0,
    shallShowOverlay: false,
    teams: [],
    perms: [],
    season: null,
  },
  getters: {
    currentBreakPoint: (state) => {
      const { windowWidth } = state
      if (windowWidth >= $themeBreakpoints.xl) return "xl"
      if (windowWidth >= $themeBreakpoints.lg) return "lg"
      if (windowWidth >= $themeBreakpoints.md) return "md"
      if (windowWidth >= $themeBreakpoints.sm) return "sm"
      return "xs"
    },
    teams: (state) => state.teams,
    season: (state) => (state.season ? state.season : localStorage.getItem("season")),
    perms: (state) => (state.perms ? state.perms : localStorage.getItem("perms")),
    seasons: (state) => (state.seasons ? state.seasons : localStorage.getItem("seasons")),
  },
  mutations: {
    UPDATE_WINDOW_WIDTH(state, val) {
      state.windowWidth = val
    },
    TOGGLE_OVERLAY(state, val) {
      state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay
    },
    SET_TEAMS(state, teams) {
      state.teams = teams
    },
    SET_SEASON(state, id) {
      state.season = id
      localStorage.setItem("season", id)
    },
    SET_SEASONS(state, data) {
      state.seasons = data
      localStorage.setItem("seasons", JSON.stringify(data))
    },
    SET_PERMS(state, data) {
      state.perms = data
      localStorage.setItem("perms", JSON.stringify(data))
    },
  },
  actions: {
    fetchTeams({ commit }, data) {
      return new Promise((resolve, reject) => {
        localStorage.setItem("teams", JSON.stringify(data))
        commit("SET_TEAMS", data)
      })
    },
    fetchSeasons({ commit }, data) {
      return new Promise((resolve, reject) => {
        if (data.length > 0) {
          commit("SET_SEASON", data[0].id)
          commit("SET_SEASONS", data)
        }
      })
    },
    setPerms({ commit }, data) {
      return new Promise((resolve, reject) => {
        commit("SET_PERMS", data)
      })
    },

    setSeason({ commit }, id) {
      return new Promise((resolve, reject) => {
        commit("SET_SEASON", id)
        resolve(id)
      })
    },
  },
}
