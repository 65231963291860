<template>
  <div>
    <div class="card-header p-0 m-0 mb-2">
      <div>
        <b-card-title>
          <feather-icon icon="SettingsIcon" size="24" />
          {{ $t("Competition Events") }}
        </b-card-title>
      </div>
    </div>

    <add-match-timeline
      v-if="competitionForm['homeTeam'] && competitionForm['homeTeam'].select.id"
      :competitionForm="{
        competition: competitionForm.competition,
        location: competitionForm.location,
        homeTeam: competitionForm.homeTeam,
      }"
      :items.sync="getSortEvents"
    ></add-match-timeline>
  </div>
</template>
<script>
  import AddMatchFormationStatTable from "./addMatchFormationStatTable.vue"
  import addMatchTimeline from "./addMatchTimeline.vue"
  export default {
    name: "CompetitionFormationStat",
    components: {
      AddMatchFormationStatTable,
      addMatchTimeline,
    },
    data() {
      return {
        CE: null,
      }
    },
    props: {
      competition: null,
      competitionForm: null,
      helper: null,
    },
    computed: {
      getSortEvents() {
        this.CE = this.competition.competitionEvents.sort((a, b) => {
          return a.minute - b.minute
        })
        return this.CE
      },
    },
    created() {},
    methods: {
      displayToast() {},
    },
    setup(props, { root }) {
      return {}
    },
  }
</script>
<style lang="scss" scoped>
  td {
    input {
      min-width: 5rem;
    }
  }
</style>
