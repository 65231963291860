<template>
  <b-overlay :show="loading" rounded="sm">
    <b-card :title="$t('Add a New Training')">
      <validation-observer ref="trainingInitialDataForm">
        <b-form>
          <b-row>
            <b-col md="4" xl="2">
              <label>{{ $t("Training Name") }}</label>
              <validation-provider #default="{ errors }" name="name" rules="required">
                <b-form-group>
                  <b-input-group>
                    <b-form-input
                      v-model="trainingInitForm.title"
                      :state="errors.length > 0 ? false : null"
                      type="text"
                      :placeholder="$t('Training Name')"
                    />
                  </b-input-group>
                </b-form-group>

                <small class="text-danger mt-1">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>

            <b-col md="4" xl="3">
              <label>{{ $t("Training Date") }}</label>
              <validation-provider #default="{ errors }" name="startDate" rules="required">
                <b-form-group>
                  <b-input-group>
                    <b-form-datepicker
                      :placeholder="$t('Training Date')"
                      :state="errors.length > 0 ? false : null"
                      :obj="trainingInitForm.startDate"
                      v-model="trainingInitForm.startDate"
                      :locale="lang"
                    />
                  </b-input-group>
                </b-form-group>

                <small class="text-danger mt-1">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>

            <b-col md="4" xl="2">
              <label>{{ $t("Time") }}</label>
              <validation-provider #default="{ errors }" name="startTime" rules="required">
                <b-form-group>
                  <b-input-group>
                    <b-form-input
                      v-model="trainingInitForm.startTime"
                      :state="errors.length > 0 ? false : null"
                      type="text"
                      placeholder="HH:mm"
                    />
                    <b-input-group-append>
                      <b-form-timepicker
                        v-model="trainingInitForm.startTime"
                        :state="errors.length > 0 ? false : null"
                        no-close-button
                        button-only
                        button-variant="outline-info"
                        left
                        size="sm"
                        :locale="lang"
                      />
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>

                <small class="text-danger mt-1">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>

            <b-col md="6" xl="5" class="mb-1">
              <label>{{ $t("Location") }}</label>
              <validation-provider #default="{ errors }" name="location" rules="required">
                <b-form-group>
                  <v-select
                    v-model="trainingInitForm.location"
                    @search="searchLocation"
                    :autocomplete="`on`"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="locations"
                    :reduce="(option) => option.id"
                    :getOptionLabel="(option) => option.name"
                  >
                    <template #option="{ name }">
                      <div v-if="name">
                        <span v-if="name"> {{ name }}</span>
                      </div>
                    </template>

                    <template #no-options="{ search }">
                      <div @click="addThisLocation(search)">
                        <feather-icon icon="PlusCircleIcon" size="14" />
                        <span> {{ $t("Do you want to add this item?") }}</span>
                      </div>
                    </template>

                    <template #selected-option="{ name }">
                      <div v-if="name">
                        <span class="text-nowrap" v-b-tooltip.hover :title="name">{{ name.substring(0, 50) }}...</span>
                      </div>
                    </template>
                  </v-select>
                </b-form-group>

                <small class="text-danger mt-1">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
          </b-row>

          <div clas="d-flex items-end justify-content-end">
            <button class="btn btn-primary float-right" v-if="!trainingData" @click.prevent="addInitialTraining">
              {{ $t("Add") }}
            </button>
            <button class="btn btn-primary float-right" v-else @click.prevent="saveTraining">
              {{ $t("Change") }}
            </button>
          </div>
        </b-form>
      </validation-observer>
    </b-card>
  </b-overlay>
</template>
<script>
  import vSelect from "vue-select"
  import flatPickr from "vue-flatpickr-component"
  import { toRefs, onMounted, ref } from "@vue/composition-api"
  import i18n from "@/libs/i18n"
  import TrainingPlugin from "@/views/team/team-sub-pages/training/TrainingPlugin"
  import TrainingStoreModule from "@/views/team/team-sub-pages/training/TrainingStoreModule"

  import { ValidationProvider, ValidationObserver } from "vee-validate"
  import { required, email, confirmed, password } from "@validations"

  export default {
    components: {
      flatPickr,
      vSelect,
      ValidationProvider,
      ValidationObserver,
    },
    props: {
      showSection: {
        default: null,
      },
      trainingData: {
        default: null,
      },
    },
    data() {
      return {
        required,
        nowDate: Date.now(),
      }
    },
    methods: {},
    computed: {},
    created() {
      if (this.routerParams.trainingId) {
        this.trainingInitForm = this.trainingData
        if (this.trainingInitForm.startDate) {
          this.trainingInitForm.startTime = new Date(this.trainingInitForm.startDate).toLocaleTimeString(
            this.$i18n.locale,
            {
              hour: "numeric",
              minute: "numeric",
            },
          )
        }
      }
    },
    setup(props, { emit }) {
      const {
        trainingInitForm,
        saveTraining,
        addInitialTraining,
        locations,
        addThisLocation,
        searchLocation,
        trainingInitialDataForm,
        getLocations,
        loading,
      } = TrainingPlugin(toRefs(props), emit)
      const lang = ref(i18n.locale)

      onMounted(() => {
        getLocations()
      })

      const now = new Date()
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())

      return {
        trainingInitForm,
        saveTraining,
        addInitialTraining,
        trainingInitialDataForm,
        locations,
        addThisLocation,
        searchLocation,
        getLocations,
        loading,
        today,
        lang,
      }
    },
  }
</script>
