var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.competitionForm && _vm.competitionForm.competition && _vm.items)?_c('div',[_c('app-timeline',{staticClass:"center-timelined"},[_vm._l((_vm.items),function(events){return [(events && events.competitionEventType == 'GOAL')?_c('app-timeline-item',{class:{ left: _vm.selectTeam(events), right: !_vm.selectTeam(events) },attrs:{"img":require('@/assets/images/icons/ball.svg')}},[_c('div',{staticClass:"d-flex flex-sm-row flex-column flex-wrap mb-1 mb-sm-0",class:{
            'justify-content-end': _vm.selectTeam(events),
            'justify-content-start': !_vm.selectTeam(events),
          }},[_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm.getEventsMinute(events.minute))+"'")]),_c('div',[(false)?_c('span',{staticClass:"remove badge badge-light-dark",on:{"click":function($event){return _vm.removeEvent(events.id)}}},[_c('feather-icon',{attrs:{"icon":"TrashIcon"}})],1):_vm._e(),_c('h6',[_vm._v(_vm._s(_vm.$t("Goal"))+"!")]),_c('div',{staticClass:"badge badge-primary d-inline-block",domProps:{"textContent":_vm._s(events.mainPlayer && events.mainPlayer.name)}}),_c('br'),(events.subPlayer)?_c('div',{staticClass:"badge badge-light text-dark d-inline-block",domProps:{"textContent":_vm._s(events.subPlayer && events.subPlayer.name)}}):_vm._e()])])]):_vm._e(),(events && events.competitionEventType == 'CHANGE_PLAYER')?_c('app-timeline-item',{class:{ left: _vm.selectTeam(events), right: !_vm.selectTeam(events) },attrs:{"img":require('@/assets/images/icons/playerchange.svg')}},[_c('div',{staticClass:"d-flex flex-sm-row flex-column flex-wrap mb-1 mb-sm-0",class:{
            'justify-content-end': _vm.selectTeam(events),
            'justify-content-start': !_vm.selectTeam(events),
          }},[_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm.getEventsMinute(events.minute))+"'")])]),_c('div',[(false)?_c('span',{staticClass:"remove badge badge-light-dark",on:{"click":function($event){return _vm.removeEvent(events.id)}}},[_c('feather-icon',{attrs:{"icon":"TrashIcon"}})],1):_vm._e(),_c('h6',[_vm._v(_vm._s(_vm.$t("Player Change")))]),_c('div',{staticClass:"badge badge-success d-inline-block",domProps:{"textContent":_vm._s(events.mainPlayer && events.mainPlayer.name)}}),_c('br'),_c('div',{staticClass:"badge badge-light-danger d-inline-block",domProps:{"textContent":_vm._s(events.subPlayer && events.subPlayer.name)}}),_c('br'),_c('div',{staticClass:"badge badge-light-dark d-inline-block",domProps:{"textContent":_vm._s(_vm.$t(events.reason))}})])]):_vm._e(),(events && events.competitionEventType == 'RED_CARD')?_c('app-timeline-item',{class:{ left: _vm.selectTeam(events), right: !_vm.selectTeam(events) },attrs:{"img":require('@/assets/images/icons/redcard.svg')}},[_c('div',{staticClass:"d-flex flex-sm-row flex-column flex-wrap mb-1 mb-sm-0",class:{
            'justify-content-end': _vm.selectTeam(events),
            'justify-content-start': !_vm.selectTeam(events),
          }},[_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm.getEventsMinute(events.minute))+"'")]),_c('div',[(false)?_c('span',{staticClass:"remove badge badge-light-dark",on:{"click":function($event){return _vm.removeEvent(events.id)}}},[_c('feather-icon',{attrs:{"icon":"TrashIcon"}})],1):_vm._e(),_c('h6',[_vm._v(_vm._s(_vm.$t("Red Card")))]),_c('div',{staticClass:"badge badge-light-danger d-inline-block"},[_vm._v(" "+_vm._s(events.mainPlayer && events.mainPlayer.name)+" ")])])])]):_vm._e(),(events && events.competitionEventType == 'YELLOW_CARD')?_c('app-timeline-item',{class:{ left: _vm.selectTeam(events), right: !_vm.selectTeam(events) },attrs:{"img":require('@/assets/images/icons/yellow-card.png')}},[_c('div',{staticClass:"d-flex flex-sm-row flex-column flex-wrap mb-1 mb-sm-0",class:{
            'justify-content-end': _vm.selectTeam(events),
            'justify-content-start': !_vm.selectTeam(events),
          }},[_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm.getEventsMinute(events.minute))+"'")]),_c('div',[(false)?_c('span',{staticClass:"remove badge badge-light-dark",on:{"click":function($event){return _vm.removeEvent(events.id)}}},[_c('feather-icon',{attrs:{"icon":"TrashIcon"}})],1):_vm._e(),_c('h6',[_vm._v(_vm._s(_vm.$t("Yellow Card")))]),_c('div',{staticClass:"badge badge-light-warning d-inline-block"},[_vm._v(" "+_vm._s(events.mainPlayer && events.mainPlayer.name)+" ")])])])]):_vm._e(),(events && events.competitionEventType == 'YELLOW_RED_CARD')?_c('app-timeline-item',{class:{ left: _vm.selectTeam(events), right: !_vm.selectTeam(events) },attrs:{"img":require('@/assets/images/icons/yellow-red-card.png')}},[_c('div',{staticClass:"d-flex flex-sm-row flex-column flex-wrap mb-1 mb-sm-0",class:{
            'justify-content-end': _vm.selectTeam(events),
            'justify-content-start': !_vm.selectTeam(events),
          }},[_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm.getEventsMinute(events.minute))+"'")]),_c('div',[(false)?_c('span',{staticClass:"remove badge badge-light-dark",on:{"click":function($event){return _vm.removeEvent(events.id)}}},[_c('feather-icon',{attrs:{"icon":"TrashIcon"}})],1):_vm._e(),_c('h6',[_vm._v(_vm._s(_vm.$t("Red Card (Double yellow)")))]),_c('div',{staticClass:"badge badge-light-warning d-inline-block"},[_vm._v(" "+_vm._s(events.mainPlayer && events.mainPlayer.name)+" ")])])])]):_vm._e()]}),_c('app-timeline-item',{staticClass:"left",attrs:{"img":require('@/assets/images/icons/finished.svg')}},[_c('div',{staticClass:"d-flex flex-sm-row flex-column flex-wrap mb-1 mb-sm-0",class:{
          'justify-content-end': true,
        }},[_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm.competitionForm.competition.time.select)+"'")]),_c('div',[_c('h6',[_vm._v(_vm._s(_vm.$t("Finished"))+"!")]),_c('h6',{key:_vm.items.length,staticClass:"badge badge-success d-inline-block"},[_vm._v(" "+_vm._s(((_vm.competitionForm.competition.homeScore ? _vm.competitionForm.competition.homeScore : "0") + ":" + (_vm.competitionForm.competition.awayScore ? _vm.competitionForm.competition.awayScore : "0")))+" ")]),_c('br'),_c('div',{staticClass:"badge badge-dark d-inline-block"},[_vm._v(" "+_vm._s(((_vm.competitionForm.location.select.name.substr(0, 18)) + "..."))+" ")])])])])],2)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }