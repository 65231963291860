<template>
  <div>
    <b-card v-if="userData" :key="trigger">
      <b-row>
        <b-col xl="5">
          <div>
            <h3>{{ $t("Player Data") }}</h3>
            <div v-if="radarChart.series[0].data.length > 0 && showRadarChart">
              <vue-apex-charts
                type="radar"
                height="500"
                :options="radarChart.chartOptions"
                :series="radarChart.series"
              />
            </div>
            <div v-else>
              <span> {{ $t("Data not found") }} </span>
            </div>
          </div>

          <div class="my-3">
            <h3 class="mb-1">{{ $t("Training Exercises Breakdown") }}</h3>
            <div v-if="doughnutChart.data.datasets.length > 0">
              <donuth-chart :height="300" :data="doughnutChart.data" :options="doughnutChart.options" class="mb-3" />

              <div v-if="playerLearningOutcomes && playerLearningOutcomes.length">
                <b-card no-body class="border mb-2" v-for="(item, index) in playerLearningOutcomes" :key="index">
                  <b-card-header class="p-1 shadow-sm" style="padding: 7px 15px !important">
                    <b-card-title class="font-medium-2 w-100">
                      <div
                        @click="showOutcomeItems == index ? (showOutcomeItems = null) : (showOutcomeItems = index)"
                        class="d-flex justify-content-between cursor-pointer"
                      >
                        <div>
                          <feather-icon
                            icon="ChevronDownIcon"
                            size="18"
                            v-if="showOutcomeItems == index || showOutcomeItems == 'all'"
                          />
                          <feather-icon icon="ChevronUpIcon" size="18" v-else />
                          <span class="align-middle ml-50">{{ $t(item.category) }} </span>
                        </div>
                        <div>
                          <small>{{ item.categoryDuration }} {{ $t("dk") }}.</small>
                          <span>- {{ Math.floor((item.categoryDuration * 100) / totalDuration) }}%</span>
                        </div>
                      </div>
                    </b-card-title>
                  </b-card-header>
                  <table class="table table-bordered" v-show="showOutcomeItems == index || showOutcomeItems == 'all'">
                    <tbody>
                    <tr v-for="(outcome, oIndex) in item.items" :key="outcome.id">
                      <td style="width: 200px">
                        <div class="d-flex align-items-start justify-content-start">
                          {{ outcome.name }}
                        </div>
                      </td>
                      <td style="width: 100px">
                        <div class="d-flex align-items-start justify-content-end">
                          <small>{{ outcome.duration }} {{ $t("dk") }}.</small>
                          <span class="font-weight-bold"
                          >- {{ Math.floor((outcome.duration * 100) / totalDuration) }}%</span
                          >
                        </div>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </b-card>
              </div>
            </div>
            <div v-else>
              <span>{{ $t("Data not found") }}</span>
            </div>
          </div>

          <div class="my-3">
            <h3 class="mb-1">{{ $t("Player History") }}</h3>

            <div v-if="playerTransferHistories">
              <app-timeline v-for="item in playerTransferHistories" :key="item.id">
                <app-timeline-item :img="item.team.club.logo" v-if="!item.isNationalTeam">
                  <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                    <h6>{{ item.season.name }}</h6>
                    <small class="text-muted">{{ item.endDate | longDate }}</small>
                  </div>
                  <p>{{ item.team.name }}</p>
                </app-timeline-item>
                <app-timeline-item v-else>
                  <template #img>
                    <b-img v-if="!item.isNationalTeam && item.team.club && item.team.club.logo" class="mr-2"
                           :src="item.team.club.logo" rounded="0" width="18" />
                    <country-flag v-else-if="item.isNationalTeam && userData.nation" :country="userData.nation"
                                  size="normal" class="rounded" style="margin-right: -16px; margin-left: -16px;" />
                    <b-img v-else-if="item.isNationalTeam && !userData.nation" class=""
                           :src="`/img/default-club-logo.png`" rounded="0" v-b-tooltip.hover
                           title="Please select player nation"
                           width="24" />
                  </template>

                  <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                    <h6>{{ item.season.name }}</h6>
                    <small class="text-muted">{{ $t('National Team') }}</small>
                  </div>
                  <p>{{ $t('National Team') }}</p>
                </app-timeline-item>
              </app-timeline>
            </div>

            <div v-else>
              <span> {{ $t("Data not found") }} </span>
            </div>
          </div>
        </b-col>
        <b-col xl="7" v-if="playerAbilityTable" data-old-style="overflow: scroll; max-height: 80vh">
          <div class="d-flex justify-content-between">
            <b-button
              size="sm"
              variant="primary"
              class="mb-1"
              @click="showItems != 'all' ? (showItems = 'all') : (showItems = null)"
            >{{ $t("Toggle All") }}
            </b-button
            >
            <span
              class="badge align-content-center align-items-center"
              style="height: 30px; text-align: center; line-height: 23px"
              :class="{
                'bg-info': performances.average >= 1 && performances.average < 2,
                'bg-primary': performances.average >= 2 && performances.average < 3,
                'bg-success': performances.average >= 3,
              }"
            >{{ performances.average }}</span
            >
          </div>
          <b-card no-body class="border mb-2" v-for="(item, index) in Object.values(performances.player)" :key="index">
            <b-card-header class="p-1 shadow-sm" style="padding: 7px 15px !important">
              <b-card-title class="font-medium-2 w-100">
                <div
                  @click="showItems == index ? (showItems = null) : (showItems = index)"
                  class="d-flex justify-content-between cursor-pointer"
                >
                  <div>
                    <feather-icon icon="ChevronDownIcon" size="18" v-if="showItems == index || showItems == 'all'" />
                    <feather-icon icon="ChevronUpIcon" size="18" v-else />
                    <span class="align-middle ml-50">{{ $t(item.parent) }} </span>
                  </div>
                  <span
                    class="h4 m-0"
                    :class="{
                      'text-warning': item.average >= 1 && item.average < 2,
                      'text-primary': item.average >= 3 && item.average < 8,
                      'text-success': item.average >= 3.5,
                    }"
                  >
                    {{ item.average.toFixed(2) }}
                  </span>
                </div>
              </b-card-title>
            </b-card-header>
            <table class="table table-bordered" v-show="showItems == index || showItems == 'all'">
              <thead>
              <tr>
                <td style="width: 300px">
                  <span>{{ $t("Ability") }}</span>
                </td>
                <td style="width: 100px">
                  <div class="d-flex flex-column font-weight-bold">
                    <span>{{ $t("Rate") }}</span>
                  </div>
                </td>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(key, value) in Object.entries(item.items)" :key="value.id">
                <td>
                  <div class="d-flex align-items-start justify-content-start">
                    {{ $t(key[0]) }}
                  </div>
                </td>
                <td>
                  <div class="d-flex align-items-start justify-content-start">
                    <b-form-rating
                      variant="warning"
                      precision="3"
                      no-border
                      readonly
                      :stars="getSetting('star')"
                      :value="getAvg(Object.values(key))"
                    ></b-form-rating>
                    <p style="margin-top: 8px">
                      {{ getAvg(Object.values(key)) }}
                    </p>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </b-card>
        </b-col>

        <b-col xl="7" v-else>
          <span> {{ $t("Data not found") }} </span>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
  import VueApexCharts from "vue-apexcharts"
  import Stadium from "@/views/apps/stadium/Stadium"
  import PlayerCard from "@/views/apps/player-card/PlayerCard"
  import DonuthChart from "@core/components/global-components/DonuthChart"
  import { $themeColors } from "@themeConfig"
  import { useUtils as useI18nUtils } from "@core/libs/i18n"
  import AppTimeline from "@core/components/app-timeline/AppTimeline.vue"
  import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue"
  import { ref, onUnmounted, onMounted } from "@vue/composition-api"
  import i18n from "@/libs/i18n"

  export default {
    components: {
      VueApexCharts,
      Stadium,
      PlayerCard,
      DonuthChart,
      AppTimeline,
      AppTimelineItem,
    },
    props: {
      userData: {
        type: [Array, Object, null],
        default: null,
      },
      playerLearningOutcomes: {
        type: [Array, Object, null],
        default: null,
      },
      playerTransferHistories: {
        type: [Array, Object, null],
        default: () => [],
      },
      performances: {
        type: [Array, Object, null],
        default: null,
      },
      abilities: {
        default: null,
      },
    },

    setup(props, { root }) {
      const showRadarChart = ref(false)
      const playerAbilityTable = ref(false)

      const trigger = ref(0)

      onMounted(async () => {
        await setChartData()
        if (!props.performances) {
          setTimeout(async () => {
            setConditionChartDataPOS()
            setConditionChartDataTeam()
            setConditionChartDataPlayer()

            showRadarChart.value = true
            trigger.value++
          }, 2000)
        } else {
          setConditionChartDataPOS()
          setConditionChartDataTeam()
          setConditionChartDataPlayer()

          showRadarChart.value = true
          trigger.value++
        }
      })

      const totalDuration = ref(null)

      const chartColors = {
        primaryColorShade: "#836AF9",
        yellowColor: "#ffe800",
        successColorShade: "#28dac6",
        warningColorShade: "#ffe802",
        warningLightColor: "#FDAC34",
        infoColorShade: "#299AFF",
        greyColor: "#4F5D70",
        blueColor: "#2c9aff",
        blueLightColor: "#84D0FF",
        greyLightColor: "#EDF1F4",
        tooltipShadow: "rgba(0, 0, 0, 0.25)",
        lineChartPrimary: "#666ee8",
        lineChartDanger: "#ff4961",
        labelColor: "#6e6b7b",
        grid_line_color: "rgba(200, 200, 200, 0.2)",
        color1: "#648FFF",
        color2: "#DC267F",
        color3: "#FFB000",
      }

      const learningOutcomeItemColors = [
        chartColors.primaryColorShade,
        chartColors.yellowColor,
        chartColors.successColorShade,
        chartColors.warningColorShade,
        chartColors.infoColorShade,
        chartColors.infoColoblueColorrShade,
        chartColors.tooltipShadow,
        chartColors.lineChartDanger,
        chartColors.labelColor,
        chartColors.grid_line_color,
      ]

      const doughnutChart = ref({
        options: {
          responsive: true,
          maintainAspectRatio: false,
          responsiveAnimationDuration: 500,
          cutoutPercentage: 60,
          legend: { display: false },
          tooltips: {
            callbacks: {
              label(tooltipItem, data) {
                const label = data.datasets[0].labels[tooltipItem.index] || ""
                const value = data.datasets[0].data[tooltipItem.index]
                const output = ` ${label} : ${value} ${i18n.t("Minute")}`
                return output
              },
            },
            // Updated default tooltip UI
            shadowOffsetX: 1,
            shadowOffsetY: 1,
            shadowBlur: 8,
            shadowColor: chartColors.tooltipShadow,
            backgroundColor: $themeColors.light,
            titleFontColor: $themeColors.dark,
            bodyFontColor: $themeColors.dark,
          },
        },
        data: {
          datasets: [],
        },
      })

      const radarChart = ref({
        series: [
          {
            name: i18n.t("Player Avg."),
            data: props.abilities.map((i) => {
              return { x: i.name, y: 0 }
            }),
          },
          {
            name: i18n.t("Team Avg."),
            data: props.abilities.map((i) => {
              return { x: i.name, y: 0 }
            }),
          },
          {
            name: i18n.t("Position Avg."),
            data: props.abilities.map((i) => {
              return { x: i.name, y: 0 }
            }),
          },
        ],
        chartOptions: {
          chart: {
            toolbar: {
              show: false,
            },
            dropShadow: {
              enabled: true,
              blur: 5,
              left: 1,
              top: 1,
              opacity: 0.002,
            },
          },
          legend: {
            show: true,
            fontSize: "14px",
            fontFamily: "Montserrat",
          },
          yaxis: {
            show: true,
            min: 0,
            max: 10,
            tickAmount: 5,
          },
          colors: [
            chartColors.color1,
            chartColors.color2,
            chartColors.color3,
            chartColors.infoColorShade,
            chartColors.infoColoblueColorrShade,
          ],
          xaxis: {},
          fill: {
            opacity: [0.5, 0.5, 0.5],
          },
          stroke: {
            show: true,
            width: 3,
          },
          markers: {
            size: 5,
          },
          grid: {
            show: false,
          },
        },
      })

      const getAvg = (array) => {
        let avg = 0
        if (array.length > 0) {
          array[1].filter((item) => {
            avg += item.rate
          })
          const num = avg / array[1].length

          return num.toFixed(1)
        } else {
          return "Veri Yok"
        }
      }

      const setChartData = () => {
        if (!props.playerLearningOutcomes) return false
        let dataset = {
          labels: [],
          data: [],
          backgroundColor: [],
          borderWidth: 0,
          pointStyle: "rectRounded",
        }
        props.playerLearningOutcomes.filter((item, index) => {
          dataset.labels.push(item.category)
          dataset.data.push(item.categoryDuration)
          totalDuration.value += item.categoryDuration

          // Kullanılacak renkleri döngüyle seçmek için modulo (%) kullanılır.
          const colorIndex = index % learningOutcomeItemColors.length
          dataset.backgroundColor.push(learningOutcomeItemColors[colorIndex])
        })
        doughnutChart.value.data.datasets.push(dataset)
      }

      const setConditionChartDataPlayer = () => {
        if (!props.performances || (props.performances && !props.performances.player)) {
          return false
        }

        for (let [key, value] of Object.entries(props.performances.player)) {
          let avg = 0
          let itemCount = 0

          Object.values(value.items).filter((item) => {
            itemCount += item.length
            item.forEach((data) => {
              avg += data.rate
            })
          })
          let avgValue = Math.round(avg / itemCount)
          let itemIndex = radarChart.value.series[0].data.findIndex((i) => i.x === value.parent)
          radarChart.value.series[0].data[itemIndex].y = avgValue
        }

        playerAbilityTable.value = true
        trigger.value++
      }

      const setConditionChartDataTeam = () => {
        if (!props.performances || (props.performances && !props.performances.team)) {
          return false
        }

        for (let [key, value] of Object.entries(props.performances.team)) {
          let avg = 0
          let itemCount = 0

          Object.values(value.items).filter((item) => {
            itemCount += item.length
            item.forEach((data) => {
              avg += data.rate
            })
          })
          let avgValue = Math.round(avg / itemCount)
          let itemIndex = radarChart.value.series[1].data.findIndex((i) => i.x === value.parent)
          radarChart.value.series[1].data[itemIndex].y = avgValue
        }
      }

      const setConditionChartDataPOS = () => {
        if (!props.performances || (props.performances && !props.performances.position)) {
          return false
        }

        for (let [key, value] of Object.entries(props.performances.position)) {
          let avg = 0
          let itemCount = 0

          Object.values(value.items).filter((item) => {
            itemCount += item.length
            item.forEach((data) => {
              avg += data.rate
            })
          })
          let avgValue = Math.round(avg / itemCount)
          let itemIndex = radarChart.value.series[2].data.findIndex((i) => i.x === value.parent)
          radarChart.value.series[2].data[itemIndex].y = avgValue
        }
      }

      const showItems = ref(0) // first is Default Open
      const showOutcomeItems = ref(0)

      return {
        showItems,
        showOutcomeItems,
        doughnutChart,
        radarChart,
        totalDuration,
        getAvg,
        showRadarChart,
        playerAbilityTable,
        trigger,
      }
    },
    methods: {},
  }
</script>

<style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
</style>
