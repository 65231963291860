<template>
  <b-card ref="performans" class="perf" v-if="tableItems.length > 0" :title="$t('Performance Monitoring')">
    <vue-good-table
      :columns="tableColumns"
      :rows="tableItems"
      :search-options="{
        enabled: false,
      }"
      :pagination-options="{
        enabled: false,
        perPage: pageLength,
      }"
      :sort-options="{
        enabled: true,
        initialSortBy: { field: 'position', type: 'desc' },
      }"
    >
      <template slot="table-row" slot-scope="props">
        <div v-if="props.column.field == 'player'">
          <player-item-box :player="props.row.player" />
        </div>
        <div v-if="props.column.field == 'position'">
          <b-link
            :to="{
              name: 'team-player',
              params: {
                id: teamId,
                playerId: props.row.player.id,
              },
            }"
          >
            <div class="d-flex align-items-center justify-content-start">
              <div class="ml-1">
                <p class="text-left">
                  <b-badge :variant="`light-${props.row.player.position.color}`">
                    {{ $t(props.row.player.position.name) }}
                  </b-badge>
                </p>
              </div>
            </div>
          </b-link>
        </div>

        <div class="d-flex align-items-center justify-content-center" v-if="props.column.field == 'physical'">
          <p v-b-tooltip.hover :title="$t('Performance Average')">
            {{ getAvg(props.row.physical) }}
          </p>
        </div>
        <div class="d-flex align-items-center justify-content-center" v-if="props.column.field == 'mental'">
          <p v-b-tooltip.hover :title="$t('Performance Average')">
            {{ getAvg(props.row.mental) }}
          </p>
        </div>
        <div class="d-flex align-items-center justify-content-center" v-if="props.column.field == 'tasteful'">
          <p v-b-tooltip.hover :title="$t('Performance Average')">
            {{ getAvg(props.row.tasteful) }}
          </p>
        </div>
        <div class="d-flex align-items-center justify-content-center" v-if="props.column.field == 'technical'">
          <p v-b-tooltip.hover :title="$t('Performance Average')">
            {{ getAvg(props.row.technical) }}
          </p>
        </div>
        <div
          class="d-flex align-items-center justify-content-center"
          v-if="props.column.field == 'technicalGoalKeeper'"
        >
          <p v-b-tooltip.hover :title="$t('Performance Average')">
            {{ getAvg(props.row.technicalGoalKeeper) }}
          </p>
        </div>
        <div class="d-flex align-items-center justify-content-center" v-if="props.column.field == 'tactical'">
          <p v-b-tooltip.hover :title="$t('Performance Average')">
            {{ getAvg(props.row.tactical) }}
          </p>
        </div>
        <div class="d-flex align-items-center justify-content-center" v-if="props.column.field == 'avg'">
          <p v-b-tooltip.hover :title="$t('Performance Average')">
            <b-form-rating
              variant="warning"
              precision="3"
              no-border
              :size="$store.getters['app/currentBreakPoint'] === 'xs' ? 'sm' : 'xl'"
              readonly
              :stars="getSetting('star')"
              :value="getAllAvg(props.row)"
            ></b-form-rating>
          </p>
        </div>
      </template>

      <template slot="pagination-bottom" slot-scope="props">
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              {{ $i18n.t("Showing") }}
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['3', '5', '10']"
              class="mx-1"
              @input="(value) => props.perPageChanged({ currentPerPage: value })"
            />
            <span class="text-nowrap"> {{ props.total }} {{ $i18n.t("Has records") }} </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value) => props.pageChanged({ currentPage: value })"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </b-card>

  <b-card v-else>
    <span> {{ $t("Data not found") }} </span>
  </b-card>
</template>

<script>
  import { VueGoodTable } from "vue-good-table"
  import { useRouter } from "@core/utils/utils"
  import axios from "@axios"
  import i18n from "@/libs/i18n"
  import PlayerItemBox from "@core/components/global-components/PlayerItemBox"

  import { ref, onMounted, computed } from "@vue/composition-api"

  export default {
    components: {
      PlayerItemBox,
      VueGoodTable,
    },
    setup(props, { root }) {
      onMounted(async () => {
        await fetchData()
      })

      const { router, route } = useRouter()
      const tableItems = ref([])
      const tableColumns = computed({
        get: () => [
          {
            field: "player",
            label: i18n.t("Player"),
            filterOptions: {
              enabled: false,
              placeholder: `${i18n.t("Search")} `,
            },
            sortable: false,
          },
          {
            field: "position",
            label: i18n.t("Position"),
            filterOptions: {
              enabled: false,
              placeholder: `${i18n.t("Search")} `,
            },
            sortFn: sorPos,
          },

          {
            field: "physical",
            label: i18n.t("Physical"),
            filterOptions: {
              enabled: false,
              placeholder: `${i18n.t("Search")} `,
            },
            sortFn: sorFn,
          },
          {
            field: "mental",
            label: i18n.t("Mental"),
            filterOptions: {
              enabled: false,
              placeholder: `${i18n.t("Search")} `,
            },
            sortFn: sorFn,
          },
          {
            field: "tasteful",
            label: i18n.t("Tasteful"),
            filterOptions: {
              enabled: false,
              placeholder: `${i18n.t("Search")} `,
            },
            sortFn: sorFn,
          },
          {
            field: "technical",
            label: i18n.t("Technical"),
            filterOptions: {
              enabled: false,
              placeholder: `${i18n.t("Search")} `,
            },
            sortFn: sorFn,
          },
          {
            field: "technicalGoalKeeper",
            label: i18n.t("Technical Goal Keeper"),
            filterOptions: {
              enabled: false,
              placeholder: `${i18n.t("Search")} `,
            },
            sortFn: sorFn,
          },
          {
            field: "tactical",
            label: i18n.t("Tactical"),
            filterOptions: {
              enabled: false,
              placeholder: `${i18n.t("Search")} `,
            },
            sortFn: sorFn,
          },
          {
            field: "avg",
            label: i18n.t("Performance Average"),
            filterOptions: {
              enabled: false,
              placeholder: `${i18n.t("Search")} `,
            },
            sortFn: avgSort,
          },
        ],
      })

      const sorFn = (x, y, col, rowX, rowY) => {
        let xSum = x.rate ? x.rate.reduce((a, b) => a + b, 0) : 0
        let ySum = y.rate ? y.rate.reduce((a, b) => a + b, 0) : 0
        let xAvg = xSum != 0 ? (xSum / x.rate.length).toFixed(1) : 0
        let yAvg = ySum != 0 ? (ySum / y.rate.length).toFixed(1) : 0
        return xAvg < yAvg ? -1 : xAvg > yAvg ? 1 : 0
      }

      const avgSort = (x, y, col, rowX, rowY) => {
        let xAvg = root.getAllAvg(rowX) ?? 0
        let yAvg = root.getAllAvg(rowY) ?? 0
        return xAvg < yAvg ? -1 : xAvg > yAvg ? 1 : 0
      }

      const sorPos = (x, y, col, rowX, rowY) => {
        let xPriority = rowX.player.position.priority ?? 0
        let yPriority = rowY.player.position.priority ?? 0
        return xPriority < yPriority ? -1 : xPriority > yPriority ? 1 : 0
      }

      const pageLength = ref(10)
      const seasonId = ref(Number(router.currentRoute.params.seasonId))
      const teamId = ref(Number(router.currentRoute.params.id))

      const fetchData = () => {
        root._setLoading("performans")
        axios
          .get(`/season/${seasonId.value}/team/${teamId.value}/performance/last`)
          .then((response) => {
            tableItems.value = Object.values(response.data)
            pageLength.value = tableItems.value.length
          })
          .finally(() => {
            root._clearLoading()
          })
      }

      return {
        tableItems,
        tableColumns,
        pageLength,
        teamId,
      }
    },
  }
</script>
<style lang="scss">
  @import "@core/scss/vue/libs/vue-good-table.scss";
  .perf .b-rating-icon {
    font-size: 18px;
    @media (max-width: 767px) {
      font-size: 18px;
    }
  }
</style>
