<template>
  <div :class="[layoutClasses]">
    <div
      v-if="!isNavMenuHidden"
      class="header-navbar navbar-expand-sm navbar navbar-horizontal navbar-light navbar-shadow menu-border"
    >
      <horizontal-nav-menu />
    </div>
  </div>
</template>

<script>
  import AppNavbarHorizontalLayout from "@core/layouts/components/app-navbar/AppNavbarHorizontalLayout.vue"
  import AppNavbarHorizontalLayoutBrand from "@core/layouts/components/app-navbar/AppNavbarHorizontalLayoutBrand.vue"
  import AppFooter from "@core/layouts/components/AppFooter.vue"
  import useAppConfig from "@core/app-config/useAppConfig"
  import { BNavbar } from "bootstrap-vue"
  import { useScrollListener } from "@core/comp-functions/misc/event-listeners"

  import { onUnmounted } from "@vue/composition-api"

  // Content Renderer
  import LayoutContentRendererDefault from "@core/layouts/components/layout-content-renderer/LayoutContentRendererDefault.vue"
  import LayoutContentRendererLeft from "@core/layouts/components/layout-content-renderer/LayoutContentRendererLeft.vue"
  import LayoutContentRendererLeftDetached from "@core/layouts/components/layout-content-renderer/LayoutContentRendererLeftDetached.vue"
  import useLayoutHorizontal from "../../../layout-horizontal/useLayoutHorizontal"
  import HorizontalNavMenu from "../../../layout-horizontal/components/horizontal-nav-menu/HorizontalNavMenu.vue"

  // Vertical Menu
  /* eslint-disable import/order */
  import VerticalNavMenu from "@core/layouts/layout-vertical/components/vertical-nav-menu/VerticalNavMenu.vue"
  import useVerticalLayout from "@core/layouts/layout-vertical/useVerticalLayout"
  import mixinLayoutHorizontal from "../../../layout-horizontal/mixinLayoutHorizontal"
  /* eslint-enable import/order */

  export default {
    components: {
      AppNavbarHorizontalLayout,
      AppNavbarHorizontalLayoutBrand,
      AppFooter,
      HorizontalNavMenu,

      BNavbar,

      // Content Renderer
      LayoutContentRendererDefault,
      LayoutContentRendererLeft,
      LayoutContentRendererLeftDetached,

      // Vertical Menu
      VerticalNavMenu,
    },
    mixins: [mixinLayoutHorizontal],
    computed: {
      layoutContentRenderer() {
        const rendererType = this.$route.meta.contentRenderer
        if (rendererType === "sidebar-left") return "layout-content-renderer-left"
        if (rendererType === "sidebar-left-detached") return "layout-content-renderer-left-detached"
        return "layout-content-renderer-default"
      },
    },
    setup() {
      const { skin, navbarType, footerType, routerTransition, isNavMenuHidden } = useAppConfig()

      // Vertical Menu
      const { isVerticalMenuActive, toggleVerticalMenuActive, overlayClasses, resizeHandler } = useVerticalLayout(
        navbarType,
        footerType,
      )

      // Resize handler
      resizeHandler()
      window.addEventListener("resize", resizeHandler)
      onUnmounted(() => {
        window.removeEventListener("resize", resizeHandler)
      })

      const { navbarMenuTypeClass, layoutClasses, footerTypeClass } = useLayoutHorizontal(
        navbarType,
        footerType,
        isVerticalMenuActive,
      )

      // Scroll Listener
      const { scrolledTo } = useScrollListener()

      return {
        // skin
        skin,

        // Layout
        layoutClasses,

        // Navbar
        navbarType,
        navbarMenuTypeClass,

        // Menu Hidden
        isNavMenuHidden,

        // Router Transition
        routerTransition,

        // Footer
        footerTypeClass,

        // Scroll Listeners
        scrolledTo,

        // Vertical Menu
        isVerticalMenuActive,
        toggleVerticalMenuActive,
        overlayClasses,
      }
    },
  }
</script>

<style lang="scss">
  @import "~@core/scss/base/themes/bordered-layout.scss";
</style>
