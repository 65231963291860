<template>
  <b-overlay variant="white" :show="showLoading" spinner-variant="primary" blur="0" opacity=".75" rounded="sm">
    <b-card no-body>
      <b-card-header class="pb-50">
        <h5>{{ $t("Filter") }}</h5>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col cols="12" md="4" class="mb-md-0 mb-2" v-if="false">
            <label>{{ $t("Role") }}</label>
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              v-model="role"
              :options="roleOptions"
              class="w-100"
              :reduce="(item) => item.id"
              :getOptionLabel="(item) => item.name"
            />
          </b-col>
          <b-col cols="12" md="4" class="mb-md-0 mb-2">
            <label>{{ $t("Status") }}</label>
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              v-model="enabled"
              :options="enabledOptions"
              class="w-100"
              :reduce="(item) => item.value"
              :getOptionLabel="(item) => item.label"
            />
          </b-col>

          <!-- TODO FILTER BY SEASON AND TEAM -->
          <b-col cols="12" md="4" class="mb-md-0 mb-2">
            <label>{{ $t("Season") }}</label>
            <v-select
              style="min-width: 150px"
              v-model="season"
              :options="seasonOptions"
              :getOptionLabel="(option) => option.year"
              :reduce="(d) => d.id"
            ></v-select>
          </b-col>

          <b-col cols="12" md="4" class="mb-md-0 mb-2">
            <label>{{ $t("Team") }}</label>
            <v-select
              style="min-width: 150px"
              v-model="team"
              :options="teamOptions"
              :getOptionLabel="(option) => (option.club ? option.club.name + ' - ' + option.name : option.name)"
              :reduce="(d) => d.id"
            >
              <template v-slot:option="option">
                <span v-if="option.club">
                  <img v-if="option.club" :src="option.club.logo" height="18" />
                  {{ option.club.name }}
                </span>
                {{ option.name }}
              </template>
            </v-select>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <b-card action-collapse>
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <label>{{ $t("Show") }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                :placeholder="$t('Search')"
                type="search"
              />
              <b-button :to="`/user_manage/add`" variant="primary">
                <span class="text-nowrap">
                  {{ $t("Add User") }}
                </span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
      <div class="mt-2 mx-n2">
        <b-table
          ref="refUserListTable"
          :fields="fields"
          :items="getUsers"
          class="position-relative"
          :empty-text="$t('No records found')"
          responsive
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          :sort-desc.sync="isSortDirDesc"
          busy.sync="false"
          :per-page="perPage"
          :current-page="currentPage"
          style="min-height: 400px"
        >
          <!-- Column: User -->
          <template #cell(firstName)="data">
            <b-media vertical-align="center">
              <template #aside>
                <b-avatar
                  size="32"
                  :text="avatarText(data.item.firstName + ' ' + data.item.lastName)"
                  :variant="`light-primary`"
                  :to="{
                    name: 'user-manage-edit',
                    params: { id: data.item.id },
                  }"
                />
              </template>
              <b-link
                :to="{ name: 'user-manage-edit', params: { id: data.item.id } }"
                class="font-weight-bold d-block text-nowrap"
              >
                {{ data.item.firstName }} {{ data.item.lastName }}
              </b-link>
              <small class="text-muted">@{{ data.item.userName }}</small>
            </b-media>
          </template>

          <!-- Column: Role -->
          <template #cell(role)="data">
            <div class="text-nowrap">
              <feather-icon :icon="`UserIcon`" size="18" class="mr-50" :class="`text-primary`" />
              <span class="align-text-top text-capitalize">{{ $t("ROLES." + data.item.role) }}</span>
            </div>
          </template>

          <!-- Column: enabled -->
          <template #cell(enabled)="data">
            <b-badge pill :variant="`light-${resolveUserenabledVariant(data.item.enabled)}`" class="text-capitalize">
              {{ resolveUserenabledText(data.item.enabled) }}
            </b-badge>
          </template>

          <template #cell(lastLoginDate)="data">
            {{ data.value | nativeDate }}
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
              <template #button-content>
                <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
              </template>
              <b-dropdown-item :to="{ name: 'user-manage-edit', params: { id: data.item.id } }">
                <feather-icon icon="FileTextIcon" />
                <span class="align-middle ml-50">{{ $t("Details") }}</span>
              </b-dropdown-item>

              <b-dropdown-item :to="{ name: 'user-manage-edit', params: { id: data.item.id } }">
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">{{ $t("Edit") }}</span>
              </b-dropdown-item>

              <b-dropdown-item @click="goDelete(data.item.id)">
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">{{ $t("Delete") }}</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
              <span class="text-muted">{{
                $t("format.showingData", { from: dataMeta.from, to: dataMeta.to, of: dataMeta.of })
              }}</span>
            </b-col>
            <!-- Pagination -->
            <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalItems"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-card>
  </b-overlay>
</template>

<script>
  import vSelect from "vue-select"
  import i18n from "@/libs/i18n"
  import { ref, watch, onMounted, computed } from "@vue/composition-api"
  import http from "@/libs/axios"
  import store from "@/store"
  import { useRouter } from "@core/utils/utils"
  import TransferSidebar from "@/views/scout/player/transfer-sidebar.vue"
  import FilterList from "@/views/scout/player/filter-list.vue"
  import localService from "@/services/localService"
  import { avatarText } from "@core/utils/filter"

  export default {
    components: {
      vSelect,
      TransferSidebar,
      FilterList,
    },
    methods: {
      goDelete(userId) {
        this.$bvModal
          .msgBoxConfirm(this.$t("Please confirm that you want to delete this."), {
            title: this.$t("Please Confirm"),
            size: "sm",
            okVariant: "primary",
            okTitle: this.$t("Yes"),
            cancelTitle: this.$t("Cancel"),
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          })
          .then((value) => {
            if (value) {
              http
                .delete(`/user/${userId}`)
                .then((res) => {
                  this.toast(i18n.t("Successfully Deleted"), "PlusIcon", "success")
                  this.refUserListTable.refresh()
                })
                .catch((error) => {
                  console.log(error)
                })
            }
          })
      },
    },
    setup(props, { root }) {
      const { router, route } = useRouter()
      const isSidebarActive = ref(false)
      const showLoading = ref(false)
      const users = ref([])
      const refUserListTable = ref(null)

      const fields = computed({
        get: () => [
          { key: "firstName", label: i18n.t("User"), sortable: true },
          { key: "email", label: i18n.t("Email"), sortable: true },
          { key: "role", label: i18n.t("Role"), sortable: false },
          { key: "enabled", label: i18n.t("Status"), sortable: true },
          { key: "lastLoginDate", label: i18n.t("Last Login Date"), sortable: true },
          { key: "actions", label: i18n.t("Actions"), sortable: false },
        ],
      })

      const enabled = ref(false)

      const role = ref(null)
      const roleOptions = ref([])

      const season = ref(null)
      const seasonOptions = JSON.parse(localStorage.getItem("seasons"))

      const team = ref(null)
      const teamOptions = ref([])

      const enabledOptions = computed({
        get: () => [
          { label: i18n.t("Active"), value: 1 },
          { label: i18n.t("Inactive"), value: 0 },
        ],
      })

      const resolveUserenabledVariant = (enabled) => {
        if (enabled === "pending") return "warning"
        if (enabled === true) return "success"
        if (enabled === false) return "secondary"
        return "primary"
      }

      const resolveUserenabledText = (enabled) => {
        if (enabled) return i18n.t("Active")
        if (!enabled) return i18n.t("Inactive")
        return i18n.t("Pending")
      }

      const isSortDirDesc = ref(true)
      const perPage = ref(localStorage.getItem("perPage") || 10)
      const totalItems = ref(24)
      const searchQuery = ref("")
      const currentPage = ref(1)
      const sortBy = ref("id")
      const perPageOptions = [10, 25, 50, 100]

      onMounted(async () => {
        await getTeams()
        enabled.value = new localService("user_manage_role_filter").get() || ""
        season.value = new localService("user_manage_role_season").get() || ""
        team.value = new localService("user_manage_role_team").get() || ""
      })

      const getUsers = (ctx) => {
        console.log(enabled.value)
        if (enabled.value === false) return false
        showLoading.value = true
        sortBy.value
        return http
          .get(`/users`, {
            params: {
              row: perPage.value,
              page: currentPage.value,
              enabled: enabled.value,
              season: season.value,
              team: team.value,
              name: searchQuery.value,
              sortBy: sortBy.value || "id",
              isDesc: isSortDirDesc.value,
            },
          })
          .then((res) => {
            totalItems.value = res.data.count
            users.value = res.data.data
            showLoading.value = false

            return users.value
          })
          .catch((error) => {
            console.log(error)
            showLoading.value = false
          })
      }

      const getTeams = () => {
        http
          .get(`/team`)
          .then((res) => {
            teamOptions.value = res.data.data
          })
          .catch((error) => console.log(error))
      }

      const goToPlayer = (id) => {
        router.push({
          name: "scout-player-view",
          params: {
            playerId: id,
          },
        })
      }

      const goToAddPlayer = (id) => {
        router.push({
          name: "scout-player-add",
        })
      }

      const dataMeta = computed(() => {
        const localItemsCount = users.value.length > 0 ? users.value.length : 0
        return {
          from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
          to: perPage.value * (currentPage.value - 1) + localItemsCount,
          of: totalItems.value,
        }
      })

      watch([role, enabled, season, team], () => {
        refUserListTable.value.refresh()
        new localService("user_manage_role_filter", enabled.value)
        new localService("user_manage_role_season", season.value)
        new localService("user_manage_role_team", team.value)
      })

      watch(searchQuery, (val) => {
        if (val.length > 3) {
          refUserListTable.value.refresh()
        }
      })

      watch([currentPage, perPage], (newv) => {
        showLoading.value = true
      })

      watch(perPage, (perPage) => {
        localStorage.setItem("perPage", perPage)
      })

      return {
        season,
        seasonOptions,
        refUserListTable,
        fields,
        users,
        goToPlayer,
        isSidebarActive,
        goToAddPlayer,
        getUsers,
        team,
        teamOptions,

        role,
        roleOptions,
        enabled,
        enabledOptions,

        // Filter
        avatarText,
        resolveUserenabledVariant,
        resolveUserenabledText,

        isSortDirDesc,
        perPage,
        currentPage,
        searchQuery,
        sortBy,
        perPageOptions,
        dataMeta,
        totalItems,
        showLoading,
      }
    },
  }
</script>

<style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
</style>
