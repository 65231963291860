import http from "@/libs/axios"
import toastService from "./toastService"
import i18n from "@/libs/i18n"

export default class scoutCompetitionService {
  constructor() {}

  async get() {
    return http
      .get("/scout/competition")
      .then((response) => new Promise((resolve) => resolve(response.data)))
      .catch((error) => new Promise((reject) => reject(error)))
  }

  async find(competitionId) {
    return http
      .get(`/scout/competition/${competitionId}`)
      .then((response) => new Promise((resolve) => resolve(response.data)))
      .catch((error) => new Promise((reject) => reject(error)))
  }

  async delete(competitionId) {
    return http
      .delete(`/scout/competition/${competitionId}`)
      .then((response) => new Promise((resolve) => resolve(response.data)))
      .catch((error) => new Promise((reject) => reject(error)))
  }

  async findCompetitionByTeam(seasonId, teamId) {
    return http
      .get(`/scout/competition`)
      .then((response) => new Promise((resolve) => resolve(response.data)))
      .catch((error) => new Promise((reject) => reject(error)))
  }

  async coachReview(requestPayload) {
    return http.post(`/scout/competition/formation`, requestPayload).then((response) => {
      toastService(i18n.t("Successfully Created"), "EditIcon", "success")
      return new Promise((resolve) => resolve(response.data))
    })
  }

  async submitFormationPlugin(competitionId, requestPayload) {
    return http
      .post(`/scout/competition/${competitionId}/formation/plugin`, requestPayload)
      .then((response) => {
        toastService(i18n.t("Successfully Added"), "EditIcon", "success")
        return new Promise((resolve) => resolve(response))
      })
      .catch((err) => new Promise((reject) => reject(err)))
  }

  async event(competition, requestPayload, setTeamFormations) {
    return http.post(`/scout/competition/event`, requestPayload).then((response) => {
      if (response.data == "DELETED") {
        toastService(i18n.t("Successfully Deleted"), "TrashIcon", "info")
        competition.value.competitionEvents = competition.value.competitionEvents.filter(
          (d) => d.id != requestPayload.id,
        )
      }
      if (response.data.id) {
        if (!requestPayload.id) {
          toastService(i18n.t("Successfully Created"), "PlusIcon", "success")
          competition.value.competitionEvents.push(response.data)
        } else {
          toastService(i18n.t("Successfully Updated"), "EditIcon", "success")
          competition.value.competitionEvents = competition.value.competitionEvents.filter(
            (d) => d.id != response.data.id,
          )
          competition.value.competitionEvents.push(response.data)
        }
      }
      setTeamFormations("homeTeam")
      setTeamFormations("awayTeam")
      return new Promise((resolve) => resolve(response.data))
    })
  }

  async formation(requestPayload, player, competition, setTeamFormations) {
    return http.post(`/scout/competition/formation`, requestPayload).then((response) => {
      player.isStarterId = response.data.id
      let findData = competition.value.competitionFormations.filter((d) => d.id == response.data.id)
      if (findData) {
        competition.value.competitionFormations = competition.value.competitionFormations.filter(
          (d) => d.id != response.data.id,
        )
        competition.value.competitionFormations.push(response.data)
      } else {
        competition.value.competitionFormations.push(response.data)
      }
      setTeamFormations("homeTeam")
      setTeamFormations("awayTeam")
      competition.value.formationTrigger++
      toastService(i18n.t("Successfully Updated"), "PlusIcon", "success")
      return new Promise((resolve, reject) => {
        resolve(response.data)
      })
    })
  }
}
