import Vue from "vue"
import { ToastPlugin, ModalPlugin, BootstrapVue } from "bootstrap-vue"
import VueCompositionAPI from "@vue/composition-api"
import i18n from "@/libs/i18n"
import router from "./router"
import store from "./store"
import App from "./App.vue"
import VueMeta from "vue-meta"
import { globalMixins } from "@core/mixins/global"
import wysiwyg from "vue-wysiwyg"
import CountryFlag from 'vue-country-flag'

// Global Components
import "./global-components"

// 3rd party plugins
import "@/libs/axios"
import "@/libs/acl"
import "@/libs/portal-vue"
import "@/libs/clipboard"
import "@/libs/toastification"
// import '@/libs/sweet-alerts'
import "@/libs/vue-select"
// import '@/libs/tour'

Vue.component('country-flag', CountryFlag)


// Axios Mock Adapter
// import '@/@fake-db/db'

// BSV Plugin Registration
Vue.use(VueMeta)
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(BootstrapVue)
Vue.mixin(globalMixins)
Vue.use(wysiwyg, {
  // disable file or image
  hideModules: { image: true, file: true },
})

// Composition API
Vue.use(VueCompositionAPI)

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require("@core/assets/fonts/feather/iconfont.css") // For form-wizard

// import core styles
require("@core/scss/core.scss")

// import assets styles
require("@/assets/scss/style.scss")

Vue.config.productionTip = false

// Vue.mixin(globalMixins);

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app")
