<template>
  <div class="w-100">
    <b-row class="p-1">
      <div class="col-md-7">
        <div v-if="teamProps.team.teamPlayers.length < 1">
          <div class="">
            <div class="alert alert-primary p-2">
              {{ $t("Not found any player") }}
            </div>
          </div>
        </div>
        <div class="table-responsive-lg table-formation" style="" v-if="teamProps.team.teamPlayers.length">
          <b-table
            bordered
            :items="teamProps.team.teamPlayers"
            :fields="[
              { key: 'name', label: $t('Player'), sortable: true, thStyle: { minWidth: '20rem' } },
              { key: 'position', label: $t('Position'), sortable: true },
              { key: 'matchPlayer', label: $t('matchPlayer'), thStyle: { width: '10rem' } },
              { key: 'changePlayer', label: $t('changePlayer'), thStyle: { width: '10rem' } },
              { key: 'injuryPlayer', label: $t('Injury'), thStyle: { width: '10rem' } },
              { key: 'outOffTeam', label: $t('Out of Squad'), thStyle: { width: '10rem' } },
            ]"
            :tbody-tr-class="testingPlayerRowClass"
          >
            <template #head(name)="scope">
              <div class="d-flex justify-content-between" style="line-height: 3">
                <div>
                  <span class="h5">{{ $t("Player") }} </span>
                </div>
                <div v-if="false">
                  <b-dropdown variant="outline-primary" :text="$t('Select Position')">
                    <b-dropdown-item> {{ $t("Midfielder") }} </b-dropdown-item>
                    <b-dropdown-item> {{ $t("Goalkeeper") }} </b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
            </template>
            <template #head(position)="scope">
              {{ $t("Position") }}
            </template>
            <template #head(changePlayer)="scope">
              <div v-b-tooltip.hover :title="$t('Substitute')">
                <img :src="require('@/assets/images/icons/change-player.png')" height="32" />
              </div>
            </template>
            <template #head(matchPlayer)="scope">
              <div v-b-tooltip.hover :title="$t('Squad Player')">
                <img :src="require('@/assets/images/icons/foot.png')" height="32" alt />
              </div>
            </template>
            <template #thead-top="data" v-if="false">
              <b-tr>
                <b-th>
                  <div class="d-flex justify-content-between" style="line-height: 3">
                    <div>
                      <span class="h5">{{ $t("Player") }} </span>
                    </div>
                    <div>
                      <b-dropdown variant="outline-primary" :text="$t('Select Position')">
                        <b-dropdown-item> {{ $t("Midfielder") }} </b-dropdown-item>
                        <b-dropdown-item> {{ $t("Goalkeeper") }} </b-dropdown-item>
                      </b-dropdown>
                    </div>
                  </div>
                </b-th>
                <b-th>{{ $t("Position") }}</b-th>
                <!-- TODO : TRANSLATE -->
                <b-th v-b-tooltip.hover :title="$t('Squad Player')">
                  <img :src="require('@/assets/images/icons/foot.png')" height="32" alt />
                </b-th>
                <b-th v-b-tooltip.hover :title="$t('Substitute')">
                  <img :src="require('@/assets/images/icons/change-player.png')" height="32" />
                </b-th>
                <b-th v-b-tooltip.hover :title="$t('Injury')">
                  {{ $t("Injury") }}
                </b-th>
                <b-th v-b-tooltip.hover :title="$t('Out Of Squad')">
                  {{ $t("Out of Squad") }}
                </b-th>
              </b-tr>
            </template>

            <template #cell(name)="data">
              <div class="d-flex justify-content-start">
                <div>
                  <b-avatar :src="data.item.avatar" :text="avatarText(data.item.name)"></b-avatar>
                </div>
                <div class="ml-1">{{ data.item.name }}</div>
              </div>
            </template>
            <template #cell(position)="data">
              {{ $t(data.item.position.name) }}
            </template>
            <template #cell(matchPlayer)="data">
              <input
                class="formationSelect"
                type="checkbox"
                @change="drawFormationPlugin(data.item, teamProps.team.id)"
                v-model="data.item.isStarterPlayer"
                true-value="1"
                false-value="2"
              />
            </template>
            <template #cell(changePlayer)="data">
              <input
                class="formationSelect"
                type="checkbox"
                @change="drawFormationPlugin(data.item, teamProps.team.id)"
                v-model="data.item.isStarterPlayer"
                true-value="0"
                false-value="2"
              />
            </template>
            <template #cell(injuryPlayer)="data">
              <input
                class="formationSelect"
                type="checkbox"
                @change="drawFormationPlugin(data.item, teamProps.team.id)"
                v-model="data.item.isStarterPlayer"
                true-value="3"
                false-value="2"
              />
            </template>
            <template #cell(outOffTeam)="data">
              <input
                class="formationSelect"
                type="checkbox"
                @change="drawFormationPlugin(data.item, teamProps.team.id)"
                v-model="data.item.isStarterPlayer"
                true-value="2"
                false-value="1"
                checked
              />
            </template>
          </b-table>
          <br />
        </div>
        <div>
          <div class="mt-2">
            <add-formation-table-add-new
              :helper="{ helperFuncs, drawFormationPlugin }"
              :reload-teams="helperFuncs.reloadTeamPlayers"
              :team-id="teamProps.team.id"
              :team-players="teamProps.team.teamPlayers"
            />
          </div>
        </div>
        <div class="table-responsive" v-if="false">
          <table class="table table-bordered">
            <thead>
              <tr>
                <td>
                  <div class="d-flex justify-content-between" style="line-height: 3">
                    <div>
                      <span class="h5">{{ $t("Player") }} </span>
                    </div>
                    <div>
                      <b-dropdown variant="outline-primary" :text="$t('Select Position')">
                        <b-dropdown-item> {{ $t("Midfielder") }} </b-dropdown-item>
                        <b-dropdown-item> {{ $t("Goalkeeper") }} </b-dropdown-item>
                      </b-dropdown>
                    </div>
                  </div>
                </td>
                <td>{{ $t("Position") }}</td>
                <!-- TODO : TRANSLATE -->
                <td v-b-tooltip.hover title="Maç oyuncusu">
                  <img :src="require('@/assets/images/icons/foot.png')" height="32" alt />
                </td>
                <td v-b-tooltip.hover title="Yedek oyuncu">
                  <img :src="require('@/assets/images/icons/change-player.png')" height="32" />
                </td>
                <td v-b-tooltip.hover title="Sakat oyuncu">
                  {{ $t("Injury") }}
                </td>
                <td v-b-tooltip.hover title="Kadro Dışı">
                  {{ $t("K. Dışı") }}
                </td>
              </tr>
            </thead>
            <tbody>
              <!-- pl key: player shorctut -->
              <tr v-for="(pl, index) in teamProps.team.teamPlayers" :key="index">
                <td>
                  <div class="d-flex justify-content-start">
                    <div>
                      <b-avatar :src="pl.avatar" :text="avatarText(pl.name)"></b-avatar>
                    </div>
                    <div class="ml-1">{{ pl.name }}</div>
                  </div>
                </td>
                <td>
                  <span v-if="pl.position" v-text="pl.position.name"></span>
                </td>
                <td style="width: 100px" :tabindex="'pl_' + index">
                  <div class="d-flex justify-content-center">
                    <input
                      class="formationSelect"
                      type="checkbox"
                      @change="drawFormationPlugin(pl, teamProps.team.id)"
                      v-model="pl.isStarterPlayer"
                      true-value="1"
                      false-value="2"
                    />
                  </div>
                </td>
                <td style="width: 100px" :tabindex="'pl_' + index++">
                  <div class="d-flex justify-content-center">
                    <input
                      class="formationSelect"
                      type="checkbox"
                      @change="drawFormationPlugin(pl, teamProps.team.id)"
                      v-model="pl.isStarterPlayer"
                      true-value="0"
                      false-value="2"
                    />
                  </div>
                </td>
                <td style="width: 100px" :tabindex="'pl_' + index++">
                  <div class="d-flex justify-content-center">
                    <input
                      class="formationSelect"
                      type="checkbox"
                      @change="drawFormationPlugin(pl, teamProps.team.id)"
                      v-model="pl.isStarterPlayer"
                      true-value="3"
                      false-value="2"
                    />
                  </div>
                </td>
                <td style="width: 100px" :tabindex="'pl_' + index++">
                  <div class="d-flex justify-content-center">
                    <input
                      class="formationSelect"
                      type="checkbox"
                      @change="drawFormationPlugin(pl, teamProps.team.id)"
                      v-model="pl.isStarterPlayer"
                      true-value="2"
                      false-value="2"
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="col-md-5">
        <formation-plugin
          class="mt-n4"
          v-if="teamProps.team.teamPlayers.length > 0"
          :key="triggerPlugin"
          :competitionFormations.sync="competition.competitionFormations"
          :teamProps="teamProps"
          :helper="helperFuncs"
        />
      </div>
    </b-row>
  </div>
</template>
<script>
  import FormationPlugin from "./FormationPlugin"
  import addFormationTableAddNew from "./addFormationTableAddNew"
  export default {
    components: { FormationPlugin, addFormationTableAddNew },
    props: {
      teamProps: null,
      competition: null,
      competitionForm: null,
      avatarText: null,
      FormationPlugin: null,
      helperFuncs: null,
    },
    data() {
      return {
        triggerPlugin: 0,
      }
    },
    methods: {
      drawFormationPlugin(player, teamId) {
        this.helperFuncs.submitFormation(player, teamId).then((dt) => {
          this.triggerPlugin++
        })
        if (Number(player.isStarterPlayer) != 1 && Number(player.isStarterPlayer) != 0) {
          this.toast(
            this.$t("If the player is excluded from the squad, match events related to the player will be deleted"),
            "UserIcon",
            "warning",
          )
        }
      },
      testingPlayerRowClass(item, type) {
        // different bg color for the testing player
        if (!item || type !== "row") return
        if (item.isTesting && item.isTesting != 0) return "table-secondary"
      },
    },
    setup(props, { root }) {},
    mounted() {},
  }
</script>
<style lang="scss">
  .table-formation {
    @media (max-width: 1384px) and (min-width: 1023px) {
      transform: scale(0.7) translateX(-18.5%) translateY(-10%);
    }
  }
</style>
