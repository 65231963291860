export default [
  // *===============================================---*
  // *--------- SCOUNT COMPETITION  ----------------------------*
  // *===============================================---*
  {
    path: "/scout/competitions",
    name: "scout-competitions",
    component: () => import("@/views/team/team-sub-pages/competition/ScoutShowTeamMatches.vue"),
    meta: {
      resource: "Scout",
      action: "manage",
    },
  },
  {
    path: "/scout/competition/add",
    name: "scout-competition-add",
    component: () => import("@/views/scout/competition/add-match/addMatch.vue"),
    meta: {
      resource: "Scout",
      action: "manage",
    },
  },
  {
    path: "/scout/competition/edit/:competitionId",
    name: "scout-competition-edit",
    component: () => import("@/views/scout/competition/add-match/addMatch.vue"),
    meta: {
      resource: "Scout",
      action: "manage",
    },
  },
  {
    path: "/season/:seasonId/team/:id/scout/competition/analyse/:competitionId",
    name: "scout-competition-analyse",
    component: () => import("@/views/scout/competition/edit-match/matchAnalyse.vue"),
    meta: {
      resource: "Scout",
      action: "manage",
    },
  },
  {
    path: "/scout/competition/:competitionId",
    name: "scout-competition-show",
    component: () => import("@/views/scout/competition/show-match/addMatch.vue"),
    meta: {
      resource: "Scout",
      action: "manage",
    },
  },
  {
    path: "/scout/players",
    name: "scout-players",
    component: () => import("@/views/scout/player/list.vue"),
    meta: {
      resource: "Scout",
      action: "manage",
    },
  },
  {
    path: "/scout/player/add",
    name: "scout-player-add",
    component: () => import("@/views/scout/player/add/PlayerAdd.vue"),
    meta: {
      resource: "Scout",
      action: "manage",
    },
  },
  {
    path: "/scout/player/:playerId",
    name: "scout-player-view",
    component: () => import("@/views/scout/player/view/index.vue"),
    meta: {
      resource: "Scout",
      action: "manage",
    },
  },
  {
    path: "/scout/player/edit/:playerId",
    name: "scout-player-edit",
    component: () => import("@/views/scout/player/edit/index.vue"),
    meta: {
      resource: "Scout",
      action: "manage",
    },
  },
  {
    path: "/scout/player-transfer",
    name: "scout-player-transfer",
    component: () => import("@/views/scout/player/transfer/index.vue"),
    meta: {
      resource: "Scout",
      action: "manage",
    },
  },
  {
    path: "/scout/watch-list",
    name: "scout-watch-list",
    component: () => import("@/views/scout/player/watch-list/index.vue"),
    meta: {
      resource: "Scout",
      action: "manage",
    },
  },
  {
    path: "/scout/dashboard",
    name: "scout-dashboard",
    component: () => import("@/views/scout/dashboard/index.vue"),
    meta: {
      resource: "Scout",
      action: "manage",
    },
  },
  {
    path: "/scout/archivePlayers",
    name: "scout-deleted-players",
    component: () => import("@/views/scout/deletedPlayer/index.vue"),
    meta: {
      resource: "Scout",
      action: "manage",
    },
  },
  {
    path: "/scout/season/:sId/team/:tId/players/:playerId",
    name: "scout-deleted-player",
    component: () => import("@/views/player/player-view/PlayerView.vue"),
    meta: {
      resource: "Scout",
      action: "manage",
    },
  },
]
