<template>
  <div>
    <b-card>
      <validation-observer #default="{ handleSubmit }" ref="addCompBasic">
        <form @submit.prevent="goSubmitInit()">
          <div class="card-header p-0 m-0 mb-2">
            <div>
              <b-card-title>
                <feather-icon icon="CopyIcon" size="24" />
                {{ $t("Add New Competition") }}
              </b-card-title>
            </div>
            <span class="btn btn-primary" @click="goSubmitInit()">
              <i class="code-toggler feather icon-save cursor-pointer"></i>
              {{ $t("Save") }}
            </span>
          </div>
          <b-row>
            <b-col md="6" xl="3" class="mb-1">
              <label>{{ $t("League") }} {{ $t("Select") }}</label>
              <b-form-group>
                <validation-provider #default="{ errors }" name="League" rules="required">
                  <v-select
                    :placeholder="`Lig Seçiniz`"
                    v-model="competitionForm.league.select"
                    :state="errors.length > 0 ? false : null"
                    :options="competitionForm.league.option"
                    :getOptionLabel="(option) => option.name"
                  ></v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6" xl="3" class="mb-1">
              <label class="w-100">
                {{ $t("Competition Date") }}
              </label>
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <flat-pickr
                    v-model="competitionForm.competition.date.date"
                    :searchable="false"
                    class="form-control"
                    :config="{ locale: 'Turkish', enableTime: false, dateFormat: 'd-m-Y' }"
                  />
                  <div>
                    <b-input-group class="inp">
                      <cleave
                        id="competitionForm-time"
                        v-model="competitionForm.competition.date.time"
                        class="form-control height-40 h-40 outline-primary ml-1"
                        :raw="false"
                        :options="{ time: true, timePattern: ['h', 'm'] }"
                        placeholder="hh:mm"
                      />
                      <b-input-group-append>
                        <b-form-timepicker
                          v-model="competitionForm.competition.date.time"
                          size="sm"
                          primary
                          button-only
                          left
                          locale="tr"
                          class="mr-1"
                        />
                      </b-input-group-append>
                    </b-input-group>
                  </div>
                </div>
              </b-form-group>
            </b-col>
            <b-col md="6" xl="2" class="mb-1">
              <label>{{ $t("Competition Time") }}</label>
              <b-form-group>
                <v-select
                  :searchable="false"
                  v-model="competitionForm.competition.time.select"
                  :options="competitionForm.competition.time.option"
                  :reduce="(option) => option"
                  :getOptionLabel="(option) => option"
                />
              </b-form-group>
            </b-col>
            <b-col md="6" xl="4" class="mb-1">
              <label>{{ $t("Location") }}</label>
              <b-form-group>
                <validation-provider #default="{ errors }" name="Location" rules="required">
                  <v-select
                    :placeholder="$t('Location')"
                    v-model="competitionForm.location.select"
                    :state="errors.length > 0 ? false : null"
                    :getOptionLabel="(option) => option.name.substr(0, 24) + '...'"
                    :options="competitionForm.location.option"
                  >
                    <template #no-options="{ search }">
                      <div @click="helperFuncs.addLocation(search)">
                        <feather-icon icon="PlusCircleIcon" size="14" />
                        <span>{{ $t("Do you want to add this item?") }}</span>
                      </div>
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4" xl="5" class="mb-1">
              <label>{{ $t("Home Team") }}</label>
              <b-form-group>
                <validation-provider #default="{ errors }" name="Home Team" rules="required">
                  <v-select
                    :searchable="false"
                    :placeholder="$t('Home Team')"
                    v-model="competitionForm.homeTeam.select"
                    :options="competitionForm.teams.option"
                    :getOptionLabel="(option) => option.name"
                  >
                    <template v-slot:option="option">
                      <span v-if="option.club">
                        <img v-if="option.club" :src="option.club.logo" height="18" />
                        {{ option.club.name }}
                      </span>
                      {{ option.name }}
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="2" xl="1">
              <label>{{ $t("Home Score") }}</label>
              <b-form-group>
                <b-input
                  type="number"
                  v-model="competitionForm.competition.homeScore"
                  :placeholder="$t('Home Score')"
                ></b-input>
              </b-form-group>
            </b-col>
            <b-col md="2" xl="1">
              <label>{{ $t("Away Score") }}</label>
              <b-form-group>
                <b-input
                  type="number"
                  v-model="competitionForm.competition.awayScore"
                  :placeholder="$t('Away Score')"
                ></b-input>
              </b-form-group>
            </b-col>
            <b-col md="4" xl="5" class="mb-1">
              <label>{{ $t("Away Team") }}</label>
              <b-form-group>
                <validation-provider #default="{ errors }" name="Away Team" rules="required">
                  <v-select
                    :searchable="false"
                    :placeholder="$t('Away Team')"
                    v-model="competitionForm.awayTeam.select"
                    :options="competitionForm.teams.option"
                    :getOptionLabel="(option) => option.name"
                  >
                    <template v-slot:option="option">
                      <span v-if="option.club">
                        <img v-if="option.club" :src="option.club.logo" height="18" />
                        {{ option.club.name }}
                      </span>
                      {{ option.name }}
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </form>
      </validation-observer>
    </b-card>
  </div>
</template>
<script>
  import vSelect from "vue-select"
  import flatPickr from "vue-flatpickr-component"
  import Cleave from "vue-cleave-component"

  import { ValidationProvider, ValidationObserver } from "vee-validate"
  import { required } from "@validations"

  export default {
    components: {
      Cleave,
      flatPickr,
      vSelect,
      ValidationProvider,
      ValidationObserver,
    },
    props: {
      showSection: null,
      competitionForm: null,
      helperFuncs: null,
    },
    data() {
      return {
        required,
      }
    },
    methods: {
      goSubmitInit() {
        this.$refs.addCompBasic.validate().then((success) => {
          if (success) {
            this.helperFuncs.submitCompetition()
            this.$bvToast.toast(this.$t("Competition Saved"), {
              title: this.$t("Redirect to competition page"),
              variant: "info",
            })
            this.showSection.addMatchBasicForm = false
            this.showSection.Formation = true
          }
        })
      },
    },
    computed: {},
    created() {
      console.log(new Date(this.competitionForm.competition.date.date))
    },
    setup(props, { root }) {},
  }
</script>
<style lang="scss">
  [class="input-group inp"] {
    box-shadow: unset !important;
  }
</style>

<style lang="scss">
  @import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
