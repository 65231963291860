import http from "@/libs/axios"
import toastService from "./toastService"
import i18n from "@/libs/i18n"

export default class staffService {
  constructor() {}

  async get({ dataId }) {
    return new Promise((resolve, reject) => {
      http
        .get(`/staff/${dataId}`)
        .then((response) => {
          return resolve(response)
        })
        .catch((error) => reject(error))
    })
  }

  async event({ data, dataId }) {
    if (data && dataId) {
      return new Promise((resolve, reject) => {
        console.log(data)
        http
          .put(`/staff/contact/${data.id}`, data)
          .then((response) => {
            toastService(i18n.t("Successfully Updated"), "PlusIcon", "success")
            return resolve(response)
          })
          .catch((error) => reject(error))
      })
    } else if (dataId) {
      return new Promise((resolve, reject) => {
        http
          .delete(`/staff/contact/${dataId}`)
          .then((response) => {
            toastService(i18n.t("Successfully Deleted"), "PlusIcon", "success")
            return resolve(response)
          })
          .catch((error) => reject(error))
      })
    } else if (data) {
      return new Promise((resolve, reject) => {
        http
          .post(`/staff/${data.staff}/contact/create`, data)
          .then((response) => {
            toastService(i18n.t("Successfully Created"), "PlusIcon", "success")
            return resolve(response)
          })
          .catch((error) => reject(error))
      })
    }
  }
}
