<template>
  <ul>
    <component
      :is="resolveNavItemComponent(item)"
      v-for="item in items"
      :key="item.header || item.title"
      :item="item"
      v-b-tooltip.hover.right
      :title="$t(item.title)"
    />
  </ul>
</template>

<script>
  import { resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from "@core/layouts/utils"
  import { provide, ref } from "@vue/composition-api"
  import VerticalNavMenuHeader from "../vertical-nav-menu-header"
  import VerticalNavMenuLink from "../vertical-nav-menu-link/VerticalNavMenuLink.vue"
  import VerticalNavMenuGroup from "../vertical-nav-menu-group/VerticalNavMenuGroup.vue"
  import i18n from "@/libs/i18n"

  export default {
    components: {
      VerticalNavMenuHeader,
      VerticalNavMenuLink,
      VerticalNavMenuGroup,
    },
    props: {
      items: {
        type: Array,
        required: true,
      },
    },
    setup() {
      provide("openGroups", ref([]))

      return {
        resolveNavItemComponent,
      }
    },
    mounted() {
      // console.log(this.items)
    },
  }
</script>

<style>
  .main-menu.menu-light .navigation li.nav-item a:hover > * {
    transform: unset !important;
  }

  .vertical-layout.vertical-menu-modern.menu-collapsed .main-menu {
    width: 90px;
  }
</style>
