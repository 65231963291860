var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-overlay',{attrs:{"show":_vm.userCreateLoading,"rounded":"sm"}},[_c('b-card',[_c('validation-observer',{ref:"userAddForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.addSubmit()}}},[_c('div',{staticClass:"card-header p-0 m-0 mb-2"},[_c('div',[_c('b-card-title',[_c('feather-icon',{attrs:{"icon":"UserPlusIcon","size":"24"}}),_vm._v(" "+_vm._s(_vm.$t("Add New User"))+" ")],1)],1)]),_c('b-row',[_c('b-col',{staticClass:"mb-1",attrs:{"md":"6","xl":"4"}},[_c('label',[_vm._v(_vm._s(_vm.$t("Username")))]),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"userName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input',{attrs:{"placeholder":_vm.$t('Username'),"state":errors[0] ? false : null},model:{value:(_vm.userInitform.userName),callback:function ($$v) {_vm.$set(_vm.userInitform, "userName", $$v)},expression:"userInitform.userName"}}),_c('div',[_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"6","xl":"4"}},[_c('label',[_vm._v(_vm._s(_vm.$t("First Name")))]),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"firstName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input',{attrs:{"placeholder":_vm.$t('First Name'),"state":errors[0] ? false : null},model:{value:(_vm.userInitform.firstName),callback:function ($$v) {_vm.$set(_vm.userInitform, "firstName", $$v)},expression:"userInitform.firstName"}}),_c('div',[_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"6","xl":"4"}},[_c('label',[_vm._v(_vm._s(_vm.$t("Last Name")))]),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"lastName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input',{attrs:{"placeholder":_vm.$t('Last Name'),"state":errors[0] ? false : null},model:{value:(_vm.userInitform.lastName),callback:function ($$v) {_vm.$set(_vm.userInitform, "lastName", $$v)},expression:"userInitform.lastName"}}),_c('div',[_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"6","xl":"4"}},[_c('label',[_vm._v(_vm._s(_vm.$t("Email")))]),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.userInitform.email),expression:"userInitform.email"}],staticClass:"ready-only-email form-control",attrs:{"readonly":"","onfocusout":"this.setAttribute('readonly','true');","onfocus":"this.removeAttribute('readonly');","autocomplete":"off","placeholder":"address@email.com","state":errors[0] ? false : null},domProps:{"value":(_vm.userInitform.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.userInitform, "email", $event.target.value)}}}),_c('div',[_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"6","xl":"4"}},[_c('validation-provider',{attrs:{"rules":"required|min:4","vid":"newPassword","name":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"account-new-password","label":_vm.$t('Password')}},[_c('b-input-group',{staticClass:"input-group-merge",class:errors[0] ? 'is-invalid' : null},[_c('b-form-input',{attrs:{"id":"account-new-password","type":_vm.passwordFieldType,"name":"new-password","placeholder":_vm.$t('Password'),"state":errors[0] ? false : null},model:{value:(_vm.userInitform.password),callback:function ($$v) {_vm.$set(_vm.userInitform, "password", $$v)},expression:"userInitform.password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIconNew},on:{"click":_vm.togglePasswordNew}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"6","xl":"4"}},[_c('validation-provider',{attrs:{"name":"role","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('label',{attrs:{"for":""}},[_vm._v(_vm._s(_vm.$t("role")))]),_c('v-select',{attrs:{"searchable":false,"placeholder":_vm.$t('role'),"options":_vm.userInitFormRoleList,"getOptionLabel":function (option) { return _vm.$t('ROLES.' + option.name); }},model:{value:(_vm.userInitform.role),callback:function ($$v) {_vm.$set(_vm.userInitform, "role", $$v)},expression:"userInitform.role"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"6","xl":"4"}},[_c('label',[_vm._v(_vm._s(_vm.$t("Birth Date")))]),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"birthdate"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',[_c('b-form-datepicker',{attrs:{"locale":_vm.lang,"id":"bDate","placeholder":"AA/GG/YYYY"},model:{value:(_vm.userInitform.birthDate),callback:function ($$v) {_vm.$set(_vm.userInitform, "birthDate", $$v)},expression:"userInitform.birthDate"}})],1),_c('div',[_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"6","xl":"4"}},[_c('label',[_vm._v(_vm._s(_vm.$t("Mobile")))]),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"mobile"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input',{attrs:{"id":"pPhone","placeholder":"555 444 33 22"},model:{value:(_vm.userInitform.phone),callback:function ($$v) {_vm.$set(_vm.userInitform, "phone", $$v)},expression:"userInitform.phone"}}),_c('div',[_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"mb-1 mt-n2",attrs:{"md":"6","xl":"4"}},[_c('label'),_c('b-form-group',[_c('b-button',{staticClass:"w-100",attrs:{"type":"submit","variant":"primary","submit":""}},[_vm._v(_vm._s(_vm.$t("Add"))+" ")])],1)],1)],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }