<template>
  <div>
    <b-card>
      <validation-observer #default="{ handleSubmit }" ref="addCompBasic">
        <form @submit.prevent="goSubmitInit()">
          <div class="card-header p-0 m-0 mb-2">
            <div>
              <b-card-title>
                <feather-icon icon="CopyIcon" size="24" />
                {{ $t("Add New Competition") }}
              </b-card-title>
            </div>
            <span class="btn btn-primary" @click="goSubmitInit()">
              <i class="code-toggler feather icon-save cursor-pointer"></i>
              {{ $t("Save") }}
            </span>
          </div>
          <b-row>
            <b-col md="6" xl="3" class="mb-1">
              <label>{{ $t("Coupe") }}</label>
              <b-form-group>
                <validation-provider #default="{ errors }" name="coupe" rules="required">
                  <v-select
                    :placeholder="$t('Coupe Select')"
                    v-model="competitionForm.league.select"
                    :state="errors.length > 0 ? false : null"
                    :options="competitionForm.league.option"
                    :getOptionLabel="(option) => option.name"
                  >
                    <template #no-options="{ search }">
                      <div @click="helperFuncs.addLeague(search)">
                        <feather-icon icon="PlusCircleIcon" size="14" />
                        <span>{{ $t("Do you want to add this item?") }}</span>
                      </div>
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6" xl="2" class="mb-1">
              <label class="w-100">
                {{ $t("Date") }}
              </label>
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <flat-pickr
                    v-model="competitionForm.competition.date.date"
                    :searchable="false"
                    class="form-control"
                    :config="{
                      locale: 'Turkish',
                      enableTime: false,
                      dateFormat: 'm/d/Y',
                    }"
                  />
                </div>
              </b-form-group>
            </b-col>
            <b-col md="6" xl="2" class="mb-1 ml-n2 mr-n2">
              <label class="w-100 ml-1">
                {{ $t("Hour") }}
              </label>
              <b-input-group class="input-group-merge d-flex justify-content-between">
                <cleave
                  id="competitionForm-time"
                  v-model="competitionForm.competition.date.time"
                  class="form-control height-40 h-40 outline-primary ml-1"
                  :raw="false"
                  :options="{ time: true, timePattern: ['h', 'm'] }"
                  placeholder="hh:mm"
                />
                <b-input-group-append>
                  <b-form-timepicker
                    v-model="competitionForm.competition.date.time"
                    size="sm"
                    primary
                    button-only
                    left
                    locale="tr"
                    class="mr-1"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-col>
            <b-col md="6" xl="2" class="mb-1">
              <label>{{ $t("Duration") }}</label>
              <b-form-group>
                <v-select
                  ref="refDuration"
                  v-model="competitionForm.competition.time.select"
                  :options="competitionForm.competition.time.option"
                  :reduce="(option) => option"
                  :getOptionLabel="(option) => option"
                >
                  <!-- search only number -->
                  <template #search="{ attributes, events }">
                    <input
                      onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                      class="vs__search"
                      v-bind="attributes"
                      v-on="events"
                    />
                  </template>

                  <template #no-options="{ search }">
                    <div role="button" @click="tempDuration(search)">
                      <span>{{ $t("Select") }}: {{ search }}</span>
                    </div>
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
            <b-col md="6" xl="3" class="mb-1 mr-n2">
              <label>{{ $t("Location") }}</label>
              <b-form-group>
                <validation-provider #default="{ errors }" name="Location" rules="required">
                  <v-select
                    class="location-select"
                    :placeholder="$t('Location')"
                    v-model="competitionForm.location.select"
                    :state="errors.length > 0 ? false : null"
                    :getOptionLabel="(option) => option.name"
                    :options="competitionForm.location.option"
                  >
                    <template v-slot:option="option">
                      <span>{{ option.name }}</span>
                    </template>
                    <template #no-options="{ search }">
                      <div @click="helperFuncs.addLocation(search)" style="cursor:pointer">
                        <feather-icon icon="PlusCircleIcon" size="14" />
                        <span class="text-primary">{{ $t("Do you want to add this item?") }}</span>
                      </div>
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4" xl="5" class="mb-1">
              <label class="d-flex justify-content-between"
                >{{ $t("Home Team") }}
                <span>
                  <b-link class="mr-1" v-b-modal.modal-teleport>
                    <feather-icon icon="PlusCircleIcon" size="14" />
                    {{ $t("Add New") }}
                  </b-link>
                  <b-link @click="switchTeam()">
                    {{ $t("Switch") }}
                    <feather-icon icon="RepeatIcon"></feather-icon>
                  </b-link>
                </span>
              </label>
              <b-form-group>
                <validation-provider #default="{ errors }" name="Home Team" rules="required">
                  <v-select
                    :placeholder="$t('Home Team')"
                    v-model="competitionForm.homeTeam.select"
                    :options="competitionForm.teams.option"
                    :getOptionLabel="(option) => (option.club ? option.club.name + ' - ' + option.name : option.name)"
                  >
                    <template v-slot:option="option">
                      <span v-if="option.club">
                        <img v-if="option.club" :src="option.club.logo" height="18" />
                        {{ option.club.name }}
                      </span>
                      {{ option.name }}
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- Create Team -->

              <portal to="destination-title">
                {{ $t("Add New Team") }}
              </portal>
              <portal to="destination">
                <div class="row" style="">
                  <v-select
                    :placeholder="$t('Club Select')"
                    v-model="newTeam.club"
                    :options="clubs"
                    :reduce="(option) => option.id"
                    :getOptionLabel="(option) => option.name"
                    class="col-md-6"
                    maxHeight="100px"
                  >
                    <template v-slot:option="option">
                      <img :src="option.logo" height="18" class="mr-1" />
                      {{ option.name }}
                    </template>
                    <template #no-options="{ search }">
                      <div @click="addClub(search)">
                        <feather-icon icon="PlusCircleIcon" size="14" />
                        <a class="" href="javascript:;">{{ $t("Do you want to add this item?") }}</a>
                      </div>
                    </template>
                  </v-select>
                  <v-select
                    id="teamName"
                    :placeholder="$t('Team Name') + ' U-'"
                    v-model="newTeam.name"
                    :options="teamNames"
                    :clearable="false"
                    maxHeight="100px"
                    class="col-md-6"
                  />
                  <div class="col-md-1">
                    <b-avatar style="margin-top: 16px" :key="newTeam.club" :src="getNewTeamClubLogo" />
                  </div>
                  <div class="col-md-5">
                    <label for="" class="mt-1 w-100">
                      <b-form-file
                        class="w-100"
                        id="quick-club-file"
                        placeholder="Choose a file or drop it here..."
                        drop-placeholder="Drop file here..."
                      ></b-form-file>
                    </label>
                  </div>
                </div>
                <div class="d-flex pb-2">
                  <b-button class="mt-2 px-5" variant="primary" @click="submitTeam('homeTeam')">{{
                    $t("Add New Team")
                  }}</b-button>
                </div>
              </portal>
            </b-col>
            <div class="col-xl-2 col-md-4">
              <p class="text-center mb-0">{{ $t("Score") }}</p>
              <div class="d-flex">
                <b-col md="6" xl="6" class="text-center">
                  <label>{{ $t("Home") }}</label>
                  <b-form-group>
                    <b-input
                      type="number"
                      v-model="competitionForm.competition.homeScore"
                      :placeholder="$t('Home Score')"
                    ></b-input>
                  </b-form-group>
                </b-col>
                <b-col md="6" xl="6" class="text-center">
                  <label>{{ $t("Away") }}</label>
                  <b-form-group>
                    <b-input
                      type="number"
                      v-model="competitionForm.competition.awayScore"
                      :placeholder="$t('Away Score')"
                    ></b-input>
                  </b-form-group>
                </b-col>
              </div>
            </div>
            <b-col md="4" xl="5" class="mb-1">
              <label class="d-flex justify-content-between"
                >{{ $t("Away Team") }}
                <span>
                  <b-link @click="switchTeam()">
                    <feather-icon icon="RepeatIcon"></feather-icon>
                    {{ $t("Switch") }}
                  </b-link>
                  <b-link v-b-modal.modal-teleport class="ml-1">
                    {{ $t("Add New") }}
                    <feather-icon icon="PlusCircleIcon" size="14" />
                  </b-link>
                </span>
              </label>
              <b-form-group>
                <validation-provider #default="{ errors }" name="Away Team" rules="required">
                  <v-select
                    :placeholder="$t('Away Team')"
                    v-model="competitionForm.awayTeam.select"
                    :options="competitionForm.teams.option"
                    :getOptionLabel="(option) => (option.club ? option.club.name + ' - ' + option.name : option.name)"
                  >
                    <template v-slot:option="option">
                      <span v-if="option.club">
                        <img v-if="option.club" :src="option.club.logo" height="18" />
                        {{ option.club.name }}
                      </span>
                      {{ option.name }}
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- Create Team -->
              <div class="d-flex" v-if="activeCreateAwayTeam">
                <v-select
                  :placeholder="$t('Club Select')"
                  v-model="newTeam.club"
                  :options="clubs"
                  :reduce="(option) => option.id"
                  :getOptionLabel="(option) => option.name"
                  class="col-md-6 ml-n1"
                >
                  <template v-slot:option="option">
                    <img :src="option.logo" height="18" class="mr-1" />
                    {{ option.name }}
                  </template>
                </v-select>
                <v-select
                  id="teamName"
                  :placeholder="$t('Team Name') + ' U-'"
                  v-model="newTeam.name"
                  :options="teamNames"
                  :clearable="false"
                  class="col-md-3"
                />
                <b-button class="col-md-3 ml-1" @click="submitTeam('awayTeam')">{{ $t("Add New Team") }}</b-button>
              </div>
            </b-col>
          </b-row>
        </form>
      </validation-observer>
    </b-card>
  </div>
</template>
<script>
  import vSelect from "vue-select"
  import flatPickr from "vue-flatpickr-component"
  import Cleave from "vue-cleave-component"

  import { ValidationProvider, ValidationObserver } from "vee-validate"
  import { required } from "@validations"
  import { ref, onMounted, watch } from "@vue/composition-api"
  import { clubService } from "@/services"
  import router from "@/router"

  export default {
    components: {
      Cleave,
      flatPickr,
      vSelect,
      ValidationProvider,
      ValidationObserver,
    },
    props: {
      showSection: null,
      competitionForm: null,
      helperFuncs: null,
    },
    data() {
      return {
        required,
        teamId: this.$router.currentRoute.params.id,
        teamNames: [...Array(11).keys()].map((i) => "U-" + (i + 9)),
      }
    },
    methods: {
      async addClub(name) {
        let clubLogo = document.getElementById("quick-club-file")

        if (clubLogo.files[0]) {
          let formData = new FormData()
          formData.append("image", clubLogo.files[0])
          this.$http.post("/club/logoUpload", formData).then(async (resp) => {
            let clubServiceApi = new clubService()
            await clubServiceApi.event({
              data: {
                logo: resp.data.url,
                name,
                mainColor: "black",
                subColor: "red",
              },
            })
            this.toast(this.$t("Club added"), "EditIcon", "success")
            this.getClubs()
          })
        } else {
          let clubServiceApi = new clubService()
          await clubServiceApi.event({
            data: {
              logo: null,
              name,
              mainColor: "black",
              subColor: "red",
            },
          })
          this.toast(this.$t("Club added"), "EditIcon", "success")
          this.getClubs()
        }
      },
      goSubmitInit() {
        this.$refs.addCompBasic.validate().then((success) => {
          if (success) {
            this.helperFuncs.submitCompetition()
            this.$bvToast.toast(this.$t("Competition Saved"), {
              title: this.$t("Redirect to competition page"),
              variant: "info",
            })
            this.showSection.addMatchBasicForm = false
            this.showSection.Formation = true
          }
        })
      },
      switchTeam() {
        let home = this.competitionForm.homeTeam.select
        let away = this.competitionForm.awayTeam.select
        this.competitionForm.homeTeam.select = away
        this.competitionForm.awayTeam.select = home
      },
      teamControl(nv, ov) {
        let home = this.competitionForm.homeTeam.select
        let away = this.competitionForm.awayTeam.select
        if (home?.id != ov?.id && away?.id != ov?.id && this.$router.currentRoute.params.competitionId) {
          this.toast(this.$t("Match-related data may be deleted if you save the team change"), "EditIcon", "warning")
        }
      },
      tempDuration(duration) {
        if (!this.competitionForm.competition.time.option.includes(duration)) {
          this.competitionForm.competition.time.option.push(duration)
          // sort ascending order
          this.competitionForm.competition.time.option.sort(function (a, b) {
            return a - b
          })
          this.competitionForm.competition.time.select = duration
          // close dropdown
          this.$refs.refDuration.searchEl.blur()
        }
      },
    },
    computed: {},
    created() {},
    setup(props, { root }) {
      const seasonId = ref(Number(router.currentRoute.params.seasonId))
      const clubs = ref([])
      const newTeam = ref({
        name: null,
        club: null,
        season: [seasonId.value],
      })
      const createTeam = ref(false)
      const activeCreateAwayTeam = ref(false)
      const activeCreateHomeTeam = ref(false)

      const clubSer = new clubService()

      onMounted(async () => {
        await getClubs()
      })

      const getClubs = () => {
        clubSer.get().then((response) => {
          clubs.value = response.data
        })
      }

      const submitTeam = async (teamSide) => {
        props.helperFuncs.addTeam(newTeam.value, teamSide, () => {
          root.$bvModal.hide("modal-teleport")
        })

        activeCreateHomeTeam.value = false
        activeCreateAwayTeam.value = false
      }

      watch([activeCreateHomeTeam, activeCreateAwayTeam], ([newHomeTeam, newAwayTeam], [oldHomeTeam, oldAwayTeam]) => {
        if (newHomeTeam && !oldHomeTeam) {
          activeCreateAwayTeam.value = false
        } else if (newAwayTeam && !oldAwayTeam) {
          activeCreateHomeTeam.value = false
        }
        newTeam.value = { name: null, club: null, season: [seasonId.value] }
      })

      return {
        getClubs,
        clubs,
        newTeam,
        createTeam,
        submitTeam,
        activeCreateAwayTeam,
        activeCreateHomeTeam,
      }
    },
    watch: {
      "competitionForm.awayTeam.select"(nv, ov) {
        this.teamControl(nv, ov)
      },
      "competitionForm.homeTeam.select"(nv, ov) {
        this.teamControl(nv, ov)
      },
      "competitionForm.league.select"(nv, ov) {
        if (nv != null && nv.id != ov?.id && nv.competitionDuration != null) {
          this.competitionForm.competition.time.select = nv.competitionDuration
        }
      },
    },
  }
</script>
<style lang="scss">
  .location-select {
    .vs__dropdown-menu > li,
    .vs__selected-options {
      span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap !important;
        display: block;
        max-width: calc(100% - 20px); /* change this to `- 40px` if you're supporting a `clearable` field; I was not */
      }
    }
    .vs__selected-options > span {
      position: absolute;
    }
  }
</style>

<style lang="scss">
  @import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
