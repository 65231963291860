<template>
  <div>
    <div class="card-header p-0 m-0 mb-2">
      <div>
        <b-card-title>
          <feather-icon icon="SettingsIcon" size="24" />
          {{ $t("Team") }}
        </b-card-title>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6" style="zoom: 0.7">
        <add-match-formation-table
          :helperFuncs="helperFuncs"
          :teamProps="competition.homeTeam"
          :competition="competition"
          :competitionForm="competitionForm"
          :avatarText="avatarText"
        />
      </div>
      <div class="col-md-6" style="zoom: 0.7">
        <add-match-formation-table
          :helperFuncs="helperFuncs"
          :teamProps="competition.awayTeam"
          :competition="competition"
          :competitionForm="competitionForm"
          :avatarText="avatarText"
        />
      </div>
      <div class="col-md-12">
        <div class="formation-plugin-all">
          <div class="homeTeam">
            <formation-plugin
              v-if="competition.homeTeam.team.teamPlayers.length > 0"
              :key="triggerPlugin"
              :competitionFormations.sync="competition.competitionFormations"
              :teamProps="competition.homeTeam"
              :helper="helperFuncs"
            />
          </div>
          <div class="awayTeam">
            <formation-plugin
              v-if="competition.awayTeam.team.teamPlayers.length > 0"
              :key="triggerPlugin"
              :competitionFormations.sync="competition.competitionFormations"
              :teamProps="competition.awayTeam"
              :helper="helperFuncs"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { avatarText } from "@core/utils/filter"
  import AddMatchFormationTable from "./addMatchFormationTable.vue"
  import FormationPlugin from "./FormationPlugin"
  export default {
    components: { AddMatchFormationTable, FormationPlugin },
    name: "CompetitionFormationEdit",
    props: {
      competitionForm: null,
      competition: null,
      helperFuncs: null,
      showSection: null,
    },
    data() {
      return {
        compFilter: "all",
        triggerPlugin: 0,
      }
    },
    computed: {},
    async created() {
      /**
       * get competition formations
       * set competition teams...
       */
      setTimeout(() => {
        this.triggerPlugin++
      }, 2500)
    },
    setup(props, { root }) {
      return {
        avatarText,
      }
    },
  }
</script>
<style>
  input.formationSelect {
    width: 30px;
    height: 30px;
  }

  .add-match-formation .nav-pills .nav-link.active {
    /* background: unset; */
  }

  .homeTeam .imgbg {
    transform: rotate(90deg);
  }
  .homeTeam {
    position: absolute;
    left: 0;
    top: 20px;
  }
  .awayTeam .imgbg {
    transform: rotate(-90deg);
  }
  .awayTeam {
    position: absolute;
    right: 0;
    top: 20px;
  }
  .formation-plugin-all {
    border: 1px solid transparent;
    width: 990px;
    margin: 0 auto;
    position: relative;
    min-height: 550px;
    margin-bottom: 2rem;
    margin-top: -1rem;
  }
  @media (max-width: 1200px) {
    .formation-plugin-all {
      zoom: 0.8;
    }
  }
  @media (max-width: 1050px) {
    .formation-plugin-all {
      zoom: 0.6;
    }
  }
  @media (max-width: 850px) {
    .formation-plugin-all {
      zoom: 0.4;
    }
  }
</style>
