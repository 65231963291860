<template>
  <b-card v-if="data" no-body class="card-revenue-budget" style="min-height: 410px">
    <b-row class="mx-0">
      <b-col md="8" class="revenue-report-wrapper">
        <div class="d-sm-flex justify-content-between align-items-center mb-3">
          <h4 class="card-title mb-50 mb-sm-0">
            {{ $t(data.title) }}
          </h4>
          <div class="d-flex align-items-center">
            <div class="d-flex align-items-center mr-2">
              <span class="bullet bullet-primary svg-font-small-3 mr-50 cursor-pointer" />
              <span>{{ $t("Participation") }}</span>
            </div>
            <div class="d-flex align-items-center ml-75">
              <span class="bullet bullet-warning svg-font-small-3 mr-50 cursor-pointer" />
              <span>{{ $t("Discontinuous") }}</span>
            </div>
          </div>
        </div>
        <!-- chart -->
        <vue-apex-charts
          v-if="data.radialChartData.series.length > 0"
          id="revenue-report-chart"
          type="bar"
          height="230"
          :options="trainingParticipantChartOptions"
          :series="data.participanData.series"
        />
        <div v-else class="d-flex justify-content-center align-items-center mt-2">
          <span class="text-center"> {{ $t("Serp PointS Not Found") }}</span>
        </div>
      </b-col>

      <b-col md="4" class="budget-wrapper">
        <div class="row">
          <div class="d-flex flex-column align-items-start">
            <div class="d-flex align-items-start">
              <span class="bullet bullet-primary svg-font-small-3 mr-50 cursor-pointer" />
              <span
                >{{ data.radialChartData.participiantCount }}
                {{ data.type === "team" ? $t("Participation") : $t("Participation") }}</span
              >
            </div>

            <div class="d-flex align-items-start mt-2">
              <span class="bullet bullet-warning svg-font-small-3 mr-50 cursor-pointer" />
              <span
                >{{ data.radialChartData.unParticipiantCount }}
                {{ data.type === "team" ? $t("Discontinuous") : $t("Discontinuous") }}</span
              >
            </div>

            <div class="d-flex align-items-center justify-items-center mt-2 mb-2">
              <h4 class="card-title mb-50 mb-sm-0">
                {{ $t("Participation Rate") }}
              </h4>
            </div>

            <div class="d-flex align-items-center mx-n2">
              <vue-apex-charts
                v-if="show"
                type="radialBar"
                height="200"
                :options="goalOverviewRadialBar"
                :series="data.radialChartData.series"
              />

              <div v-else class="d-flex justify-content-center align-items-center mt-2">
                <span class="text-center"> {{ $t("Serp PointS Not Found") }}</span>
              </div>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
  import { BCard, BRow, BCol, BDropdown, BDropdownItem, BButton } from "bootstrap-vue"
  import VueApexCharts from "vue-apexcharts"
  import { $themeColors } from "@themeConfig"
  import Ripple from "vue-ripple-directive"

  export default {
    components: {
      VueApexCharts,
      BDropdown,
      BDropdownItem,
      BCard,
      BButton,
      BRow,
      BCol,
    },
    directives: {
      Ripple,
    },
    props: {
      data: {
        type: Object,
        default: () => {},
      },
    },
    computed: {
      show() {
        return this.data.radialChartData.series.length > 0
          ? this.data.radialChartData.participiantCount == this.data.radialChartData.unParticipiantCount
            ? this.data.radialChartData.unParticipiantCount != 0
            : true
          : false
      },
    },
    data() {
      return {
        revenue_report: {},
        trainingParticipantChartOptions: {
          chart: {
            stacked: true,
            type: "bar",
            toolbar: { show: false },
          },
          grid: {
            padding: {
              top: -20,
              bottom: -10,
            },
            yaxis: {
              lines: { show: false },
            },
          },
          xaxis: {
            categories: this.data.teams,
            labels: {
              style: {
                colors: "#6E6B7B",
                fontSize: "0.86rem",
                fontFamily: "Montserrat",
              },
            },
            axisTicks: {
              show: false,
            },
            axisBorder: {
              show: false,
            },
          },
          legend: {
            show: false,
          },
          dataLabels: {
            enabled: false,
          },
          colors: [$themeColors.primary, $themeColors.warning],
          plotOptions: {
            bar: {
              columnWidth: "17%",
              endingShape: "rounded",
            },
            distributed: true,
          },
          yaxis: {
            labels: {
              style: {
                colors: "#6E6B7B",
                fontSize: "0.86rem",
                fontFamily: "Montserrat",
              },
            },
          },
        },
        goalOverviewRadialBar: {
          chart: {
            sparkline: {
              enabled: true,
            },
            dropShadow: {
              enabled: true,
              blur: 3,
              left: 1,
              top: 1,
              opacity: 0.1,
            },
          },
          colors: ["#51e5a8"],
          plotOptions: {
            radialBar: {
              offsetY: -10,
              startAngle: -150,
              endAngle: 150,
              hollow: {
                size: "77%",
              },
              track: {
                background: "#ebe9f1",
                strokeWidth: "50%",
              },
              dataLabels: {
                name: {
                  show: false,
                },
                value: {
                  color: "#5e5873",
                  fontSize: "2.86rem",
                  fontWeight: "600",
                },
              },
            },
          },
          fill: {
            type: "gradient",
            gradient: {
              shade: "dark",
              type: "horizontal",
              shadeIntensity: 0.5,
              gradientToColors: [$themeColors.success],
              inverseColors: true,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100],
            },
          },
          stroke: {
            lineCap: "round",
          },
          grid: {
            padding: {
              bottom: 30,
            },
          },
        },
      }
    },
  }
</script>
