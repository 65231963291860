import axios from "@axios"
import i18n from "@/libs/i18n"

let colors = ["primary", "success", "danger", "warning", "info", "dark"]
const teams =
  JSON.parse(localStorage.getItem("teams")).map((item, i) => {
    return {
      ...item,
      color: colors[i] || colors.at(-1),
    }
  }) || []
const getTeams = Object.values(teams)
  .map((data) => ({ label: data.name, param: Number(data.id) }))
  .map((item, i) => {
    return {
      ...item,
      color: colors[i] || colors.at(-1),
    }
  })
const getSelectedTeams = Object.values(teams).map((data) => Number(data.id))

const calendarTypes = [
  { id: "training", text: "Training", color: "warning", value: false },
  { id: "competition", text: "Competition", color: "success", value: false },
]

export default {
  namespaced: true,
  state: {
    teams: teams,
    colors,
    calendarOptions: getTeams,
    calendarTypes,
    selectedDates: new Date().toISOString().substr(0, 10),
    selectedCalendars: getSelectedTeams,
  },
  getters: {},
  mutations: {
    SET_SELECTED_EVENTS(state, val) {
      state.selectedCalendars = val
    },
    SET_SELECTED_EVENTS_TYPE(state, val) {
      if (val == "clear") {
        calendarTypes.map((d) => (d.value = false))
        state.calendarTypes = calendarTypes

        return
      }

      calendarTypes.find((d) => d.id == val).value = true
      state.calendarTypes = calendarTypes
    },
    SET_DATE(state, val) {
      state.selectedDates = val
    },
  },
  actions: {
    fetchEvents(ctx, { teamId, seasonId, calendars, types, startDate, endDate }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/season/${seasonId}/events`, {
            params: {
              team: calendars != "" ? calendars.join(",") : teamId,
              type: types != "" ? types : "",
              startDate: startDate != "" ? startDate : "",
              endDate: endDate != "" ? endDate : "",
            },
          })
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    addEvent(ctx, { teamId, seasonId, event }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/team/${teamId}/season/${seasonId}/events`, event)
          .then((response) => resolve({ event: response.data }))
          .catch((error) => reject(error))
      })
    },
    updateEvent(ctx, { event }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`https://602b8a2fef26b40017f145a3.mockapi.io/api/calendar/${event.id}`, event)
          .post(`/team/${teamId}/season/${seasonId}/events/${event.id}`, event)
          .then((response) => resolve({ event: response.data }))
          .catch((error) => reject(error))
      })
    },
    removeEvent(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`https://602b8a2fef26b40017f145a3.mockapi.io/api/calendar/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
  },
}
