<template>
  <div>
    <div class="card-header p-0 m-0 mb-2">
      <div>
        <b-card-title>
          <feather-icon icon="SettingsIcon" size="24" />
          {{ $t("Competition Analysis") }}
        </b-card-title>
      </div>
    </div>
    <div>
      <div class="row">
        <b-col md="6">
          <h2>{{ $t("Pre Match") }}</h2>
          <b-row>
            <b-col lg="10">
              <b-form-file
                :browse-text="$t('Browse')"
                style="overflow: hidden"
                id="pre-competition"
                v-model="file"
                name="file"
                ref="pre"
                :placeholder="$t('You can select file from here or just move to here.')"
                :drop-placeholder="$t('Move to here...')"
              />
            </b-col>
            <b-col>
              <b-button :disabled="preLoading" @click="createPreCompetitionAnalysis(file)" variant="success">
                <feather-icon v-if="!preLoading" size="12" icon="PlusIcon"></feather-icon>
                <b-spinner v-if="preLoading" small class="align-middle"></b-spinner>
              </b-button>
            </b-col>
          </b-row>
          <b-row>
            <b-col :md="10" class="mt-2">
              <b-row>
                <b-col :md="3" v-for="item in preFiles" :key="item.id">
                  <div @click="openFile(item)" class="card icon-card cursor-pointer text-center">
                    <div class="file-boxes shadow-lg">
                      <feather-icon size="32" icon="FileIcon" class="mt-2"></feather-icon>
                      <span class="d-block my-1">{{ item.name }}</span>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
        <div class="col-md-6">
          <h2>{{ $t("Post Match") }}</h2>
          <b-row>
            <b-col lg="10">
              <b-form-file
                :browse-text="$t('Browse')"
                style="overflow: hidden"
                id="post-competition"
                v-model="file"
                name="file"
                ref="post"
                :placeholder="$t('You can select file from here or just move to here.')"
                :drop-placeholder="$t('Move to here...')"
              />
            </b-col>
            <b-col>
              <b-button :disabled="postLoading" @click="createPostCompetitionAnalysis(file)" variant="primary">
                <feather-icon v-if="!postLoading" size="12" icon="PlusIcon"></feather-icon>
                <b-spinner v-if="postLoading" small class="align-middle"></b-spinner>
              </b-button>
            </b-col>
          </b-row>
          <b-row>
            <b-col :md="10" class="mt-2">
              <b-row>
                <b-col :md="3" v-for="item in postFiles" :key="item.id">
                  <div @click="openFile(item)" class="card icon-card cursor-pointer text-center">
                    <div class="file-boxes shadow-lg">
                      <feather-icon size="32" icon="FileIcon" class="mt-2"></feather-icon>
                      <span class="d-block my-1">{{ item.name }}</span>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { avatarText } from "@core/utils/filter"
  export default {
    components: {},
    name: "CompetitionAnalysisEdit",
    props: {
      competitionForm: null,
      competition: null,
      helperFuncs: null,
      showSection: null,
    },
    data() {
      return {
        file: [],
        preFiles: [],
        postFiles: [],
        avatarText,
        preLoading: false,
        postLoading: false,
      }
    },
    methods: {
      openFile(item) {
        window.open(item.file)
      },
      createPostCompetitionAnalysis() {
        if (!this.$refs.post.files.length > 0) return false
        this.postLoading = true
        let file = this.$refs.post.files[0]
        let formData = new FormData()
        formData.append("file", file)
        this.$http
          .post(`/scout/competition/analysis/post/${this.routerParams.competitionId}`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            this.postFiles.push(response.data)
          })
          .finally(() => {
            this.postLoading = false
            this.$refs.post.reset()
          })
      },
      createPreCompetitionAnalysis() {
        if (!this.$refs.pre.files.length > 0) return false
        this.preLoading = true
        let file = this.$refs.pre.files[0]
        let formData = new FormData()
        formData.append("file", file)
        this.$http
          .post(`/scout/competition/analysis/pre/${this.routerParams.competitionId}`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            this.preFiles.push(response.data)
          })
          .finally(() => {
            this.preLoading = false
            this.$refs.pre.reset()
          })
      },
      getFilesByType() {},
    },
    computed: {},
    async beforeMount() {
      this.competition.formationTrigger = 0
    },
    created() {
      this.$http.get(`/scout/competition/${this.routerParams.competitionId}/analysis`).then((res) => {
        if (res) {
          res.data.filter((item) => {
            if (item.isPre === 1) {
              this.preFiles.push(item)
            } else {
              this.postFiles.push(item)
            }
          })
        }
      })
    },
  }
</script>
<style>
  input.formationSelect {
    width: 30px;
    height: 30px;
  }

  .add-match-formation .nav-pills .nav-link.active {
    /* background: unset; */
  }
</style>
