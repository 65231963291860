<template>
  <div v-if="competitions">
    <vue-good-table
      :columns="columns"
      :rows="Object.values(competitions)"
      :sort-options="{
        enabled: true,
        initialSortBy: { field: 'competitionDate', type: 'desc' },
      }"
    >
      <template slot="table-row" slot-scope="props">
        <!-- Column: Status -->

        <span v-if="props.column.field === 'competitionDate'">
          {{ props.row.competitionDate | nativeDateTime }}
        </span>

        <span v-else-if="props.column.field === 'competition'">
          <div
            class="d-flex justify-content-start p-2"
            @click="goToMatch(props.row.cFTeamId, props.row.competitionId)"
            style="cursor: pointer"
          >
            <!-- home team -->
            <div class="d-flex justify-content-between">
              <span class="teamText mr-1">{{ props.row.homeTeamName + " " + props.row.homeClubName }}</span>
              <img :src="props.row.homeTeamLogo" height="24" alt="" />
            </div>
            <!-- match score -->
            <div>
              <b-badge
                :variant="props.row.win == -1 ? 'danger' : props.row.win == 1 ? 'success' : 'dark'"
                class="mx-1"
                v-if="props.row.homeScore != undefined"
              >
                {{ props.row.homeScore + `-` + props.row.awayScore }}
              </b-badge>
              <!-- clock icon -->
              <span class="icon-time mx-1" v-if="props.row.awayScore == undefined">
                <feather-icon icon="ClockIcon" size="1.5x" scale="1" />
              </span>
            </div>

            <!-- away team -->
            <div class="d-flex justify-content-between">
              <img :src="props.row.awayTeamLogo" height="24" alt="" />
              <span class="teamText ml-1"> {{ props.row.awayTeamName + " " + props.row.awayClubName }} </span>
            </div>
          </div>
        </span>

        <span v-if="props.column.field === 'isStarter'">
          {{ props.row.isStarter === 1 ? "As Kadro" : "Yedek" }}
        </span>

        <span v-if="props.column.field === 'coachReview'">
          <div class="d-flex align-items-center justify-content-center pt-1">
            <p class="" v-b-tooltip.hover :title="props.row.coachReview">
              <b-form-rating
                variant="warning"
                precision="3"
                no-border
                readonly
                :stars="getSetting('star')"
                :value="props.row.coachReview"
              ></b-form-rating>
            </p>
          </div>
        </span>

        <!-- <span v-if="props.column.field === 'playerReview'">
					{{ props.row.playerReview }}
				</span> -->
        <span v-if="props.column.field === 'RED_CARD'">
          {{ props.row.RED_CARD ? props.row.RED_CARD.length : null }}
        </span>
        <span v-if="props.column.field === 'YELLOW_CARD'">
          {{ props.row.YELLOW_CARD ? props.row.YELLOW_CARD.length : null }}
        </span>
        <span v-if="props.column.field === 'YELLOW_RED_CARD'">
          {{ props.row.YELLOW_RED_CARD ? props.row.YELLOW_RED_CARD.length : null }}
        </span>
        <span v-if="props.column.field === 'oks'">
          <!-- PLAYER TOTAL IN THE GAME -->
          {{ Number.isNaN(parseInt(props.row.oks)) ? 0 : parseInt(props.row.oks) }}
        </span>
        <span v-if="props.column.field === 'ASSIST'">
          {{ props.row.ASSIST ? props.row.ASSIST.length : null }}
        </span>
        <span v-if="props.column.field === 'GOAL'">
          {{ props.row.GOAL ? props.row.GOAL.length : null }}
        </span>
        <span v-if="props.column.field === 'duration'">
          {{ props.row.duration }}
        </span>

        <!-- <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span> -->
      </template>
    </vue-good-table>
  </div>
</template>

<script>
  import { VueGoodTable } from "vue-good-table"

  export default {
    components: {
      VueGoodTable,
    },
    props: {
      competitions: {
        type: [Object, Array],
      },
    },
    data() {
      return {
        pageLength: 3,
        dir: false,
        columns: [
          {
            label: this.$i18n.t("Date"),
            field: "competitionDate",
            filterOptions: {
              enabled: false,
              placeholder: this.$i18n.t("Search"),
            },
          },
          {
            label: this.$i18n.t("Match"),
            field: "competition",
            filterOptions: {
              enabled: false,
              //placeholder: this.$i18n.t('Search'),
            },
          },
          {
            label: this.$i18n.t("Match Role"),
            field: "isStarter",
            type: "number",
            filterOptions: {
              enabled: false,
            },
          },
          /*{
					label: this.$i18n.t("Player (sERP)"),
					field: "playerReview",
					type: "number",
					filterOptions: {
						enabled: false,
					},
				},*/
          {
            label: this.$i18n.t("Coach Review"),
            field: "coachReview",
            type: "number",
            filterOptions: {
              enabled: false,
            },
          },
          {
            label: this.$i18n.t("Red Card"),
            field: "RED_CARD",
            type: "number",
            filterOptions: {
              enabled: false,
            },
          },
          {
            label: this.$i18n.t("Yellow Card"),
            field: "YELLOW_CARD",
            type: "number",
            filterOptions: {
              enabled: false,
            },
          },
          {
            label: this.$i18n.t("Yellow Red Card"),
            field: "YELLOW_RED_CARD",
            type: "number",
            filterOptions: {
              enabled: false,
            },
          },
          {
            label: this.$i18n.t("TP"),
            field: "oks",
            type: "number",
            filterOptions: {
              enabled: false,
            },
          },
          {
            label: this.$i18n.t("Assist"),
            type: "number",
            field: "ASSIST",
            filterOptions: {
              enabled: false,
            },
          },
          {
            label: this.$i18n.t("Goal"),
            field: "GOAL",
            type: "number",
            filterOptions: {
              enabled: false,
            },
          },
          {
            label: this.$i18n.t("Duration"),
            field: "duration",
            type: "number",
            filterOptions: {
              enabled: false,
            },
          },
          {
            label: this.$i18n.t("Video"),
            field: "video",
            filterOptions: {
              enabled: false,
            },
          },
        ],
        rows: [],
        searchTerm: "",
      }
    },
    methods: {
      goToMatch(teamId, compId) {
        this.$router.push({
          name: "competition-edit",
          params: {
            id: teamId,
            competitionId: compId,
          },
        })
      },
    },
  }
</script>

<style lang="scss">
  .welcome-dashboard-history.row {
    tr {
      cursor: pointer;
      transition: all 300ms;
      outline: 0;

      &:hover {
        background: #eee;
      }
    }

    .teamText {
      width: 10rem;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .stad,
    .teamText {
      white-space: nowrap;
      width: 6rem;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .teamText {
      width: 8rem;
    }
  }
</style>
