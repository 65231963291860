<template>
  <b-nav-item>
    <b-button id="closest-season" secondary right size="sm" disabled>
      {{ season.name }}
    </b-button>
  </b-nav-item>
</template>
<script>
  export default {
    data() {
      return {
        season: null,
      }
    },
    beforeMount() {
      this.season = this.getClosestSeason()
    },
  }
</script>
