<template>
  <b-sidebar
    id="sidebar-add-cont"
    :visible="isSidebarActive"
    bg-variant="white"
    @shown="$emit('update:isSidebarActive', true)"
    @hidden="
      $emit('update:isSidebarActive', false)
      formTrigger++
    "
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
  >
    <template #default="{ hide }">
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">{{ $t("Comment") }}</h5>
        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver" :key="formTrigger">
        <!-- Form -->
        <b-form v-if="player" class="p-2" @submit.prevent="handleSubmit(submit())" @reset.prevent="resetForm">
          <!-- Staff Season -->
          <validation-provider #default="{ errors }" name="comment" rules="required">
            <b-form-group :label="$t('Comment')" label-for="season">
              <b-textarea
                id="name"
                :placeholder="$t('Please enter comment')"
                v-model="form.comment"
                :state="errors.length > 0 ? false : null"
              />

              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button variant="primary" class="mr-2" type="submit">{{ $t("Add") }}</b-button>
            <b-button type="button" variant="outline-secondary" ref="hideClick" @click="hide">{{
              $t("Cancel")
            }}</b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
  import { ValidationProvider, ValidationObserver } from "vee-validate"
  import { required, alphaNum, email } from "@validations"
  import { ref, onMounted, computed, watch } from "@vue/composition-api"
  import { useRouter } from "@core/utils/utils"
  import http from "@/libs/axios"
  import i18n from "@/libs/i18n"

  export default {
    components: {
      ValidationProvider,
      ValidationObserver,
    },
    model: {
      prop: "isSidebarActive",
      event: "update:is-add-cont-active",
    },
    props: {
      isSidebarActive: {
        type: Boolean,
        required: true,
      },
      player: {
        default: null,
      },
      form: {
        type: Object,
        default: () => {},
      },
    },
    data() {
      return {
        required,
        formTrigger: 0,
      }
    },
    setup(props, { emit, root }) {
      const { router, route } = useRouter()
      const blanForm = {}

      const playerId = ref(Number(router.currentRoute.params.playerId))
      const seasonId = ref(Number(router.currentRoute.params.seasonId))
      const formTrigger = ref(0)

      const submit = () => {
        if (props.form.id) {
          update()
        } else {
          create()
        }
      }

      const create = (data) => {
        props.form.player = Number(router.currentRoute.params.playerId)
        http
          .post(`scout/player/review`, props.form)
          .then((res) => {
            //player.value = res.data;
            root.toast(i18n.t("Successfully Created"), "PlusIcon", "success")
            location.reload()
          })
          .catch((error) => console.log(error))
      }
      const update = (data) => {
        props.form.player = Number(router.currentRoute.params.playerId)
        http
          .put(`/scout/player/review/${props.form.id}`, props.form)
          .then((res) => {
            root.toast(i18n.t("Successfully Updated"), "PlusIcon", "success")
            location.reload()
          })
          .catch((error) => console.log(error))
      }

      return {
        submit,
        update,
        required,
        formTrigger,
      }
    },
  }
</script>

<style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";

  #add-new-user-sidebar {
    .vs__dropdown-menu {
      max-height: 200px !important;
    }
  }
</style>
