<template>
  <b-overlay variant="white" :show="showLoading" spinner-variant="primary" blur="0" opacity=".75" rounded="sm">
    <b-card no-body>
      <b-card-header class="pb-50">
        <h5>{{ $t("Filter") }}</h5>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col cols="12" md="3" class="mb-md-0 mb-2">
            <label>{{ $t("Club") }}</label>
            <v-select
              v-model="club"
              :options="clubs"
              class="w-100"
              :reduce="(item) => item.id"
              :getOptionLabel="(item) => item.name"
            />
          </b-col>
          <b-col cols="12" md="3" class="mb-md-0 mb-2">
            <label>{{ $t("Season") }}</label>
            <v-select
              v-model="season"
              :options="seasons"
              class="w-100"
              :reduce="(item) => item.id"
              :getOptionLabel="(item) => item.name"
            />
          </b-col>
          <b-col cols="12" md="3" class="mb-md-0 mb-2">
            <label>{{ $t("Age") }}</label>
            <v-select
              v-model="age"
              :reduce="(option) => option.value"
              :options="[
                { label: $t('20'), value: '20' },
                { label: $t('19'), value: '19' },
                { label: $t('18'), value: '18' },
                { label: $t('17'), value: '17' },
                { label: $t('16'), value: '16' },
                { label: $t('15'), value: '15' },
              ]"
            ></v-select>
          </b-col>
          <b-col cols="12" md="3" class="mb-md-0 mb-2">
            <label>{{ $t("Creation Time") }}</label>
            <v-select
              v-model="isPre"
              :options="preOptions"
              class="w-100"
              :reduce="(item) => item.id"
              :getOptionLabel="(item) => item.name"
            />
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <b-card action-collapse>
      <div class="mt-2 mx-n2">
        <b-table
          ref="refAnalysisTable"
          :fields="fields"
          :items="refetchData"
          class="position-relative"
          :empty-text="$t('No records found')"
          responsive
          primary-key="id"
          show-empty
          busy.sync="false"
          style="min-height: 400px"
          :sort-by.sync="sortBy"
          :sort-desc.sync="isSortDirDesc"
          :per-page="perPage"
          :current-page="currentPage"
        >
          <template #table-busy>
            <div class="text-center my-2">
              <b-spinner class="align-middle"></b-spinner>
            </div>
          </template>

          <!-- Column: League -->
          <template #cell(league)="data">
            <div class="d-flex justify-content-start">
              <div class="h5" style="line-height: 1.5">
                <b>{{ data.item.competition.league.name }}</b>
              </div>
            </div>
          </template>

          <!-- Column: Season -->
          <template #cell(season)="data">
            <div class="d-flex justify-content-start">
              <div class="h5" style="line-height: 1.5">
                <b>{{ data.item.competition.season.name }}</b>
              </div>
            </div>
          </template>

          <!-- Column: File -->
          <template #cell(file)="data">
            <div class="d-flex justify-content-start">
              <div class="h5" style="line-height: 1.5">
                {{ data.item.name }}
              </div>
            </div>
          </template>

          <!-- Column: Status -->
          <template #cell(status)="data">
            <div class="d-flex justify-content-start">
              <div class="h5" style="line-height: 1.5">
                {{ preOptions.find((o) => o.id === data.item.isPre).name }}
              </div>
            </div>
          </template>

          <!-- Column: Match -->
          <template #cell(match)="data">
            <div class="d-flex justify-content-start" @click="goToMatch(data.item.competition)">
              <div class="d-flex justify-content-start">
                <span class="teamText mr-1"
                  >{{ data.item.competition.homeTeam.name + " " }}
                  <span
                    v-if="data.item.competition.homeTeam.club && data.item.competition.homeTeam.club.name"
                    v-text="data.item.competition.homeTeam.club.name"
                  ></span>
                </span>
                <img
                  v-if="data.item.competition.homeTeam.club && data.item.competition.homeTeam.club.logo"
                  :src="data.item.competition.homeTeam.club.logo"
                  height="24"
                  alt
                />
              </div>
              <div>
                <span
                  class="badge mx-1 px-1"
                  v-if="data.item.competition.homeScore != undefined"
                  :class="{
                    'badge-dark text-light': true,
                  }"
                  >{{ data.item.competition.homeScore }} - {{ data.item.competition.awayScore }}</span
                >
                <!-- clock icon -->
                <span class="icon-time mx-1 px-1" v-if="data.item.competition.awayScore == undefined">
                  <feather-icon icon="ClockIcon" size="24" scale="1" />
                </span>
              </div>
              <!-- away team -->
              <div class="d-flex justify-content-start">
                <img
                  v-if="data.item.competition.awayTeam.club && data.item.competition.awayTeam.club.logo"
                  :src="data.item.competition.awayTeam.club.logo"
                  height="24"
                  alt
                />
                <span class="teamText ml-1"
                  >{{ data.item.competition.awayTeam.name + " " }}
                  <span
                    v-if="data.item.competition.awayTeam.club && data.item.competition.awayTeam.club.name"
                    v-text="data.item.competition.awayTeam.club.name"
                  ></span>
                </span>
              </div>
            </div>
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <div class="d-flex justify-content-start" @click="openFile(data.item)">
              <b-link class="btn btn-primary">
                <i class="code-toggler feather icon-eye cursor-pointer"></i>
              </b-link>
            </div>
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
              <label>{{ $t("Show") }}</label>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
              />

              <span class="text-muted">
                {{ $t("format.showingData", { from: dataMeta.from, to: dataMeta.to, of: dataMeta.of }) }}
              </span>
            </b-col>

            <!-- Pagination -->
            <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalItems"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-card>
  </b-overlay>
</template>

<script>
  import vSelect from "vue-select"
  import i18n from "@/libs/i18n"
  import { ref, watch, onMounted, computed } from "@vue/composition-api"
  import http from "@/libs/axios"

  export default {
    components: {
      vSelect,
    },
    setup() {
      const showLoading = ref(false)
      const isSortDirDesc = ref(true)
      const perPage = ref(localStorage.getItem("perPage") || 10)
      const totalItems = ref(24)
      const searchQuery = ref("")
      const currentPage = ref(1)
      const sortBy = ref("id")
      const perPageOptions = [10, 25, 50, 100]

      const user = JSON.parse(localStorage.getItem("userData"))
      const fields = computed({
        get: () => [
          { key: "league", label: i18n.t("League"), sortable: true },
          { key: "season", label: i18n.t("Season"), sortable: true },
          { key: "file", label: i18n.t("File") },
          { key: "status", label: i18n.t("Status"), sortable: true },
          { key: "Match", label: i18n.t("Match"), sortable: true },
          { key: "actions", label: i18n.t("Actions") },
        ],
      })

      const preOptions = computed({
        get: () => [
          { name: i18n.t("Pre Match"), id: 1 },
          { name: i18n.t("Post Match"), id: 0 },
        ],
      })

      const club = ref(null)
      const season = ref(null)
      const age = ref(null)
      const isPre = ref(null)

      const clubs = ref([])
      const seasons = ref([])
      const analysisFiles = ref([])

      onMounted(async () => {
        await getClubs()
        await getSeasons()
      })

      const openFile = (item) => {
        window.open(item.file)
      }

      const getClubs = () => {
        http
          .get(`/club`)
          .then((res) => {
            clubs.value = res.data
          })
          .catch((error) => console.log(error))
      }

      const getSeasons = () => {
        http
          .get(`/season`)
          .then((res) => {
            seasons.value = res.data
          })
          .catch((error) => console.log(error))
      }

      const refetchData = () => {
        showLoading.value = true
        return http
          .get(`/competition/analysis-filter`, {
            params: {
              pre: isPre.value,
              season: season.value,
              age: age.value,
              club: club.value,
              page: currentPage.value,
              row: perPage.value,
            },
          })
          .then((res) => {
            analysisFiles.value = res.data.data
            totalItems.value = res.data.count
            showLoading.value = false
            return analysisFiles.value
          })
          .catch((error) => {
            showLoading.value = false
            console.log(error)
          })
      }

      watch([club, age, season, isPre, currentPage], () => {
        refetchData()
      })

      watch(perPage, (perPage) => {
        localStorage.setItem("perPage", perPage)
      })

      const dataMeta = computed(() => {
        const localItemsCount = analysisFiles.value.length > 0 ? analysisFiles.value.length : 0
        return {
          from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
          to: perPage.value * (currentPage.value - 1) + localItemsCount,
          of: totalItems.value,
        }
      })

      return {
        club,
        age,
        season,
        isPre,

        clubs,
        seasons,
        analysisFiles,

        fields,
        preOptions,

        user,
        showLoading,
        openFile,

        isSortDirDesc,
        perPage,
        totalItems,
        searchQuery,
        currentPage,
        sortBy,
        perPageOptions,
        dataMeta,
        refetchData,
      }
    },
  }
</script>
