export default [
  {
    path: "/error-404",
    name: "error-404",
    component: () => import("@/views/error/Error404.vue"),
    meta: {
      layout: "full",
      resource: "Guest",
      action: "read",
    },
  },

  {
    path: "/login",
    name: "login",
    component: () => import("@/views/pages/authentication/Login.vue"),
    meta: {
      layout: "full",
      resource: "Guest",
      redirectIfLoggedIn: true,
    },
  },

  {
    path: "/register",
    name: "register",
    component: () => import("@/views/pages/authentication/Register.vue"),
    meta: {
      layout: "full",
      resource: "Guest",
      redirectIfLoggedIn: true,
    },
  },

  {
    path: "/forgot-password",
    name: "forgot-password",
    component: () => import("@/views/pages/authentication/ForgotPassword.vue"),
    meta: {
      layout: "full",
      resource: "Guest",
      redirectIfLoggedIn: true,
    },
  },

  {
    path: "/reset-password/:hash",
    name: "reset-password",
    component: () => import("@/views/pages/authentication/ResetPassword.vue"),
    meta: {
      layout: "full",
      resource: "Guest",
      redirectIfLoggedIn: true,
    },
  },

  {
    path: "/pages/miscellaneous/coming-soon",
    name: "misc-coming-soon",
    component: () => import("@/views/pages/miscellaneous/ComingSoon.vue"),
    meta: {
      layout: "full",
      redirectIfLoggedIn: false,
    },
  },
  {
    path: "/pages/miscellaneous/not-authorized",
    name: "misc-not-authorized",
    component: () => import("@/views/pages/miscellaneous/NotAuthorized.vue"),
    meta: {
      layout: "full",
      resource: "Guest",
    },
  },

  {
    path: "/parent-access",
    name: "parent-access",
    component: () => import("@/views/pages/authentication/ParentCommunication.vue"),
    meta: {
      layout: "full",
      resource: "Guest",
      redirectIfLoggedIn: true,
    },
  },
]
