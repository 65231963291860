<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Forgot Password v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <!-- logo -->
          <img :src="require('@teamLogoPath')" height="90" />
        </b-link>

        <b-card-title class="mb-1"> {{ $t("Forgot Password") }} ? 🔒 </b-card-title>
        <b-card-text class="mb-2">
          {{ $t(`Enter your email and we'll send you instructions to reset your password`) }}
        </b-card-text>

        <!-- form -->
        <validation-observer ref="simpleRules">
          <b-form class="auth-forgot-password-form mt-2" @submit.prevent="validationForm">
            <!-- email -->
            <b-form-group label="Email" label-for="forgot-password-email">
              <validation-provider #default="{ errors }" name="Email" rules="required|email">
                <b-form-input
                  id="forgot-password-email"
                  v-model="userEmail"
                  :state="errors.length > 0 ? false : null"
                  name="forgot-password-email"
                  placeholder="antrenor@gmail.com"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <b-button variant="primary" block type="submit">
              {{ $t("Send reset link") }}
            </b-button>
          </b-form>
        </validation-observer>

        <b-card-text class="text-center mt-2">
          <b-link :to="{ name: 'login' }"> <feather-icon icon="ChevronLeftIcon" /> {{ $t("Back to login") }} </b-link>
        </b-card-text>
      </b-card>
      <!-- /Forgot Password v1 -->
    </div>
  </div>
</template>

<script>
  import { ValidationProvider, ValidationObserver } from "vee-validate"
  import { BCard, BLink, BCardText, BCardTitle, BFormGroup, BFormInput, BForm, BButton } from "bootstrap-vue"
  import { required, email } from "@validations"
  import axios from "@/libs/axios"
  import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
  import i18n from "@/libs/i18n"

  export default {
    components: {
      BCard,
      BLink,
      BCardText,
      BCardTitle,
      BFormGroup,
      BFormInput,
      BButton,
      BForm,
      ValidationProvider,
      ValidationObserver,
    },
    data() {
      return {
        userEmail: "",
        // validation
        required,
        email,
      }
    },
    methods: {
      validationForm() {
        this.$refs.simpleRules.validate().then((success) => {
          if (success) {
            axios.post("/forgot-password", {
              email: this.userEmail,
              platform: "client_web",
            })

            this.$toast({
              component: ToastificationContent,
              props: {
                title: i18n.t("Renewal Email Sent!"),
                icon: "EditIcon",
                variant: "success",
              },
            })
            this.$router.push({ name: "/login" })
          }
        })
      },
    },
  }
</script>

<style lang="scss">
  @import "@core/scss/vue/pages/page-auth.scss";

  .brand-logo svg {
    height: 120px;
    width: auto !important;
  }
</style>
