<template>
  <b-overlay :show="loading" rounded="sm">
    <div v-if="items.length > 0">
      <b-card class="w-100">
        <b-row>
          <b-col lg="11">
            <div class="d-flex mt-n1">
              <div class="custom-header-text d-flex align-items-center justify-content: space-between;">
                <b-link
                  :to="{
                    name: 'team-player',
                    params: {
                      id: routerParams.id,
                    },
                  }"
                  class="d-flex"
                >
                  <b-avatar :src="items[0].avatar" size="55px" />
                  <div class="d-block ml-1">
                    <p class="small mt-0 mb-0 text-left">{{ items[0].name }}</p>
                    <b-badge pill :variant="items[0].position.color" style="font-size: 11px">
                      {{ $t(items[0].position.name) }}
                    </b-badge>
                  </div>
                </b-link>
                <div class="ml-1">
                  {{ $t("Measurement Date") }}
                  <b-form-datepicker id="testDate" class="w-100" v-model="selectedDate" :locale="lang" :max="maxDate" />
                </div>
              </div>
            </div>
          </b-col>
          <b-col lg="1" sm="12" :class="$store.getters['app/currentBreakPoint'] === 'xs' ? 'my-2' : null"> </b-col>
          <b-col lg="6" v-if="$store.getters['app/currentBreakPoint'] === 'xs'">
            <b-form-group>
              <v-select
                v-model="category"
                :autocomplete="`on`"
                :options="categories"
                :reduce="(option) => option.slug"
                :getOptionLabel="(option) => option.name"
              >
              </v-select>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>
      <b-row>
        <b-col lg="12" sm="12" order="2" order-md="1">
          <span v-for="(x, index) in categories" :key="index">
            <vue-good-table
              :ref="table + index"
              :columns="[
                {
                  field: 'player',
                  label: $t('Player'),
                  sortable: false,
                  width: '250px',
                },
                {
                  field: 'value',
                  label: x.text,
                  sortable: false,
                  width: '300px',
                  hidden: false,
                  thClass: 'text-center',
                },
              ]"
              :rows="items"
              :isLoading.sync="loading"
              class="mt-1 only-player"
              :search-options="{
                enabled: false,
              }"
              :pagination-options="{
                enabled: false,
                perPage: pageLength,
              }"
            >
              <template slot="table-row" slot-scope="props">
                <div v-if="props.column.field == 'player'" style="width: 140px">
                  <div class="d-flex flex-column align-items-start">
                    <div class="d-flex align-items-start">
                      {{ $t(x.name) }}
                    </div>
                  </div>
                </div>

                <div v-else-if="props.column.field == 'value'">
                  <div>
                    <data-input
                      :dateChanged="dateChanged"
                      :date="date"
                      :player="props.row"
                      :category="x.slug"
                      @setLoading="setLoading"
                      :input-type="x.type"
                    />
                  </div>
                  <p v-show="false">
                    <!-- {{ (props.column.label = x.text) }} -->
                  </p>
                </div>
                <div v-else>
                  {{ props.formattedRow[props.column.field] }}
                </div>
              </template>

              <template slot="pagination-bottom" slot-scope="props">
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap">
                      {{ $t("Showing") }}
                    </span>
                    <b-form-select
                      v-model="pageLength"
                      :options="['3', '5', '10']"
                      class="mx-1"
                      @input="(value) => props.perPageChanged({ currentPerPage: value })"
                    />
                    <span class="text-nowrap"> {{ props.total }} {{ $t("Has records") }} </span>
                  </div>
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="props.total"
                      :per-page="pageLength"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @input="(value) => props.pageChanged({ currentPage: value })"
                    >
                      <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18" />
                      </template>
                      <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18" />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>
          </span>
        </b-col>

        <b-col lg="4" sm="12" order="1" order-md="1" v-if="$store.getters['app/currentBreakPoint'] !== 'xs' && false">
          <b-card>
            <p class="h4 my-2">{{ $t("Categories") }}</p>

            <b-form-radio
              v-for="(item, index) in categories"
              :key="index"
              v-model="category"
              :value="item.name"
              stacked
              name="radio-options"
            >
              <div class="d-flex" :class="showItemValue(item.name) ? 'text-primary' : ''">
                {{ item.text }}
                <p class="small" style="margin-left: 5px">
                  {{ showItemValue(item.name) ? "(" + showItemValue(item.name) + ")" : "" }}
                </p>
              </div>
            </b-form-radio>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </b-overlay>
</template>

<script>
  import { useRouter } from "@core/utils/utils"
  import i18n from "@/libs/i18n"
  import vSelect from "vue-select"
  import { VueGoodTable } from "vue-good-table"
  import { useToast } from "vue-toastification/composition"
  import store from "@/store"
  import testStoreModule from "./testStoreModule"
  import { computed, onMounted, ref, watch } from "@vue/composition-api"
  import flatPickr from "vue-flatpickr-component"
  import axios from "@axios"
  import DataInput from "./Input"

  export default {
    components: {
      vSelect,
      VueGoodTable,
      DataInput,
      flatPickr,
    },
    setup(props, { emit, root }) {
      const { router, route } = useRouter()
      const toast = useToast()

      const STORE_MODULE_NAME = "team-test"

      // Register module
      if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, testStoreModule)

      // onUnmounted(() => {
      //   if (store.hasModule(STORE_MODULE_NAME))
      //     store.unregisterModule(STORE_MODULE_NAME);
      // });

      onMounted(async () => {
        await getCategories()
        await fetchDateOptions()
        await checkIfThereADate()
        await getDailyData()
        await getPlayers()
      })

      const now = new Date()
      const maxDate = new Date(now.getFullYear(), now.getMonth(), now.getDate())
      const date = ref(new Date())
      const selectedDate = ref(router.currentRoute.params.date)
      const dateOptions = ref(null)
      const lang = ref(i18n.locale)

      watch(selectedDate, (value) => {
        if (value != router.currentRoute.params.date) {
          router.push({ name: "team-player-tests-enter-date", params: { date: value } })
          date.value = router.currentRoute.params.date
        }
      })

      const fetchDateOptions = async () => {
        await axios
          .get(
            `/season/${router.currentRoute.params.seasonId}/test/player/${router.currentRoute.params.playerId}/group-by-date`,
          )
          .then((response) => {
            dateOptions.value = Object.keys(response.data)
          })
          .catch((error) => {
            console.log(error)
          })
      }

      const category = ref("height")
      const categories = ref([])
      const table = ref(null)
      const items = ref([])
      const dateChanged = ref(false)
      const team = ref(null)
      const loading = ref(false)
      const setData = ref(false)
      const showEditForm = ref(false)
      const showLogs = ref(false)
      const pageLength = ref(10)
      const fields = ref([
        {
          field: "player",
          label: i18n.t("Player"),
          sortable: false,
          width: "250px",
        },
        {
          field: "value",
          label: i18n.t("Value"),
          sortable: false,
          width: "300px",
          hidden: false,
          thClass: "text-center",
        },
      ])

      const getFields = (labelName) => {
        console.log(fields)
        fields.value[1].label = labelName
        return fields
      }

      const config = ref({
        dateFormat: "Y-m-d",
        enable: [new Date()],
        maxDate: new Date(),
        locale: i18n.locale, // locale for this instance only
      })
      const dataDays = ref([])
      const pickerEvents = ["onDayCreate"]

      const getPlayers = async () => {
        setLoading(true, "Fetching Data...")
        axios.get(`/player/${router.currentRoute.params.playerId}`).then((response) => {
          items.value = [response.data]
          setLoading(false, "Fetched Data...")
        })
      }

      const setLoading = (value, text = null) => {
        loading.value = value
        if (showLogs.value === true && text !== null) {
          console.log(text)
        }
      }

      const getCategories = async () => {
        axios.get("test-item").then((response) => {
          categories.value = response.data

          // comment move to end
          let commentIndex = categories.value
            .map(function (e) {
              return e.slug
            })
            .indexOf("comment")
          if (commentIndex > -1) categories.value.push(categories.value.splice(commentIndex, 1)[0])
        })
      }

      const getFormattedDate = (date) => {
        let tempMonth = date.getMonth() + 1
        let month = tempMonth < 10 ? "0" + tempMonth.toString() : tempMonth
        let day = date.getDate()
        let year = date.getFullYear()
        return year + `-` + month + `-` + day
      }

      const onDayCreate = (dObj, dStr, fp, dayElem) => {
        if (dataDays.value[getFormattedDate(dayElem.dateObj)]) {
        }
      }

      const fetchTests = () => {
        axios
          .get(`test/team/${router.currentRoute.params.id}/group-by-date`)
          .then((response) => {
            config.value.enable = Object.keys(response.data)
          })
          .catch((error) => {
            console.log(error)
          })
      }

      watch(category, (val) => {
        setLoading(true, "wait...")
        scrollUp()
        setTimeout(() => {
          setLoading(false, "wait...")
        }, 500)
      })

      watch(date, (val, oldVal) => {
        setLoading(true, "wait...")
        getDailyData()
        scrollUp()
        setTimeout(() => {
          setLoading(false, "wait...")
        }, 1200)
      })

      const scrollUp = () => {
        const scrollEl = document.getElementsByClassName("vgt-responsive")[0]
        scrollEl.scrollTo({
          top: 0,
          behavior: "smooth",
        })
      }

      const getLabel = (name) => {
        return categories.value.find((item) => item.slug === name)
      }

      const getDailyData = () => {
        store
          .dispatch("team-test/fetch", {
            id: parseInt(router.currentRoute.params.id),
            date: date.value,
          })
          .then((response) => {
            if (response.data.length > 0) {
              dateChanged.value = !dateChanged.value
            } else {
              dateChanged.value = !dateChanged.value
            }
          })
      }

      const checkIfThereADate = () => {
        if (router.currentRoute.params.date) {
          date.value = router.currentRoute.params.date
        }
      }

      const getDate = (date) => {
        date = new Date(date)
        let month = date.getMonth() + 1
        let day = date.getDate()
        let year = date.getFullYear()
        return day + `/` + month + `/` + year
      }

      const teamTests = computed(() => store.state["team-test"].items)

      const showItemValue = (name) => {
        let data = null
        teamTests.value.find((item) => {
          if (item.name === name && parseInt(router.currentRoute.params.playerId) === item.player.id) {
            data = item
          }
        })
        if (data) return data.value
      }

      return {
        table,
        fields,
        dateChanged,
        items,
        category,
        categories,
        team,
        loading,
        pageLength,
        getLabel,
        date,
        maxDate,
        getDate,
        showEditForm,
        setData,
        setLoading,
        config,
        pickerEvents,
        onDayCreate,
        showItemValue,
        getFields,
        selectedDate,
        dateOptions,
        lang,
      }
    },
  }
</script>

<style>
  .custom-header-text {
    margin-top: 10px;
    margin-right: 5px;
    font-size: 18px;
  }
  .vgt-left-align {
    @media (max-width: 767px) {
      max-width: 120px;
    }
  }
  .custom-radio {
    font-size: 28px;
  }
  .custom-control-label {
    font-size: 28px;
  }
</style>
<style lang="scss">
  @import "@core/scss/vue/libs/vue-good-table.scss";
  @import "@core/scss/vue/libs/vue-flatpicker.scss";
  .only-player {
    table {
      thead {
        tr {
          display: none;
        }
      }
      tbody {
        tr {
          td {
            padding-bottom: 0.75em !important;
          }
        }
      }
    }
  }
  #testDate__dialog_ div[aria-disabled] {
    background-color: #cacaca73 !important;
  }
</style>
