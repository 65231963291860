<template>
  <svg
    id="Capa_1"
    enable-background="new 0 0 512 512"
    viewBox="0 0 512 512"
    width="48"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <g />
      <g>
        <path
          d="m474.95 180.73c-19.1-34.61-30.45-83.99-41.86-109.59-2.01-4.53-4.03-8.31-6.09-11.14-11.75-16.128-26.646-25.752-45.227-33.288h-39.925l-15.618-15.461h-140.461l-15.618 15.461h-39.925c-18.58 7.536-33.476 17.16-45.226 33.288-2.06 2.83-4.08 6.61-6.09 11.14-11.41 25.6-22.76 74.98-41.86 109.59-1.96 3.55-1.53 7.94 1.04 11.08 7.58 9.28 26.45 26.57 62.59 28.09 3.81.16 7.39-1.88 9.21-5.24l13.71-25.35c4.41 45.12 8.4 96.44 8.4 134.69 0 50.18-10.86 106.07-19.87 143.77 6.283 4.277 12.768 8.279 19.434 11.994h38.102l16.028 22.447c14.886 4.244 30.323 7.179 46.183 8.662l24.123-24.998 24.123 24.998c15.86-1.483 31.297-4.418 46.183-8.662l16.028-22.447h38.102c6.666-3.716 13.152-7.717 19.434-11.994-9.01-37.7-19.87-93.59-19.87-143.77 0-38.25 3.99-89.57 8.4-134.69l13.71 25.35c1.82 3.36 5.4 5.4 9.21 5.24 36.14-1.52 55.01-18.81 62.59-28.09 2.57-3.14 3-7.53 1.04-11.08z"
          v-bind:fill="teamColor.second"
        />
      </g>
      <g>
        <path
          d="m381.773 26.712c-4.08-1.655-8.331-3.212-12.773-4.712-9.354-3.163-18.896-7.09-27.145-10.749h-15.625c-2.011 42.268-10.254 83.874-10.23 126.349.022 39.467 0 78.933 0 118.4s.022 78.933 0 118.4c-.024 42.961 8.418 85.043 10.305 127.811 19.042-5.428 37.18-13 54.13-22.447-8.845-35.029-24.409-68.217-24.435-105.364-.027-39.467 0-78.933 0-118.4s-.028-78.933 0-118.4c.027-39.116 17.271-73.797 25.773-110.888z"
          v-bind:fill="teamColor.first"
        />
      </g>
      <g>
        <path
          d="m280.015 11.251h-48.03c.93 81.597 4.015 163.073 4.015 244.749 0 85.053-3.35 169.891-4.123 254.873 7.942.743 15.987 1.127 24.123 1.127s16.181-.384 24.123-1.127c-.773-84.982-4.123-169.82-4.123-254.873 0-81.676 3.085-163.152 4.015-244.749z"
          v-bind:fill="teamColor.first"
        />
      </g>
      <g>
        <path
          d="m185.769 11.251h-15.625c-8.248 3.659-17.79 7.586-27.144 10.749-4.442 1.5-8.693 3.057-12.773 4.712 8.502 37.091 25.746 71.772 25.773 110.888.028 39.467 0 78.933 0 118.4s.027 78.933 0 118.4c-.026 37.146-15.59 70.335-24.435 105.364 16.951 9.448 35.088 17.019 54.13 22.447 1.887-42.768 10.329-84.85 10.305-127.811-.022-39.467 0-78.933 0-118.4s-.022-78.933 0-118.4c.024-42.475-8.219-84.081-10.231-126.349z"
          v-bind:fill="teamColor.first"
        />
      </g>
      <g>
        <path
          d="m388.4 189.315 13.711 25.344c1.817 3.359 5.396 5.399 9.213 5.239-6.027-5.975-9.145-67.781-10.487-104.967-.089-2.474-3.707-2.613-3.986-.154z"
          v-bind:fill="teamColor.first"
        />
      </g>
      <g>
        <path
          d="m123.6 189.315-13.711 25.344c-1.817 3.359-5.396 5.399-9.213 5.239 6.027-5.975 9.145-67.781 10.487-104.967.089-2.474 3.707-2.613 3.986-.154z"
          v-bind:fill="teamColor.first"
        />
      </g>
      <g>
        <path
          d="m328.269 4.961c-3.322-1.604-6.801-2.823-10.37-3.654h-123.798c-3.569.831-7.048 2.05-10.37 3.654-3.775 1.823-8.426 4.004-13.579 6.29 11.414 16.478 38.168 45.249 85.848 45.249s74.434-28.771 85.848-45.249c-5.153-2.286-9.804-4.467-13.579-6.29z"
          v-bind:fill="teamColor.first"
        />
      </g>
      <g>
        <path
          d="m256 56.5c40.932 0 58.971-46.741 61.899-55.193-3.707-.863-7.511-1.307-11.343-1.307h-50.556-50.556c-3.832 0-7.636.444-11.343 1.307 2.928 8.452 20.967 55.193 61.899 55.193z"
          fill="black"
        />
      </g>
    </g>
  </svg>
</template>
<script>
  export default {
    props: {
      teamColor: null,
    },
  }
</script>
