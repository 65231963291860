<template>
  <div>
    <div class="d-flex justify-content-end">
      <feather-icon
        size="16"
        icon="InfoIcon"
        v-b-tooltip.hover
        class="pull-up mr-1"
        :title="$t('Last 5 Training Load Tooltip')"
      />
    </div>
    <h5 class="font-weight-bolder ml-1">
      {{ $t("Last 5 Training Load") }}
    </h5>

    <vue-apex-charts
      v-if="tempdata.length > 0"
      height="100"
      :options="statisticsOrder.chartOptions"
      :series="statisticsOrder.series"
    />
    <div v-else class="d-flex justify-content-center align-items-center mt-2">
      <span class="text-center"> {{ $t("Serp PointS Not Found") }}</span>
    </div>
  </div>
</template>

<script>
  import { BCard, VBTooltip } from "bootstrap-vue"
  import VueApexCharts from "vue-apexcharts"
  import { $themeColors } from "@themeConfig"

  const $barColor = "#f3f3f3"

  export default {
    components: {
      BCard,
      VueApexCharts,
    },
    directives: {
      "b-tooltip": VBTooltip,
    },
    props: {
      playerSerpScores: {
        type: [Array, null],
        default: () => [],
      },
    },
    data() {
      return {
        statisticsOrder: {
          series: [],
          chartOptions: {
            chart: {
              type: "bar",
              stacked: true,
              toolbar: {
                show: false,
              },
              zoom: {
                enabled: false,
              },
            },
            grid: {
              show: false,
              padding: {
                left: 0,
                right: 0,
                top: -15,
                bottom: 0,
              },
            },
            plotOptions: {
              bar: {
                horizontal: false,
                columnWidth: "15%",
                startingShape: "rounded",
                colors: {
                  backgroundBarColors: [$barColor, $barColor, $barColor, $barColor, $barColor],
                  backgroundBarRadius: 5,
                },
              },
            },
            legend: {
              show: false,
            },
            dataLabels: {
              enabled: false,
            },
            colors: [$themeColors.warning],

            xaxis: {
              categories: [],
              labels: {
                show: true,
              },
              axisBorder: {
                show: false,
              },
              axisTicks: {
                show: false,
              },
            },
            yaxis: {
              show: false,
            },
            tooltip: {
              x: {
                show: false,
              },
            },
          },
        },
        tempdata: [],
      }
    },
    methods: {
      setChartData() {
        let newArr = []
        let categories = []
        if (!this.sortedPlayerSerpScores) return false
        this.sortedPlayerSerpScores.map((item) => {
          newArr.push(item.trainerSerpScore)
          if (item.created_at) {
            categories.push(this.getDate(item.training.startDate))
          }
        })
        this.statisticsOrder.series.push({
          name: "Serp Puanı",
          data: newArr,
        })
        this.tempdata = newArr

        //Object.assign(this.height.chartOptions.xaxis.categories, categories);
        this.statisticsOrder.chartOptions.xaxis.categories = categories
      },
      getDate(date) {
        date = new Date(date)
        let month = date.getMonth() + 1
        let day = date.getDate()
        return day + `/` + month
      },
    },
    computed: {
      sortedPlayerSerpScores() {
        return this.playerSerpScores.sort((a, b) => b.id - a.id).slice(0, 5)
      },
    },
    created() {
      this.setChartData()
    },
  }
</script>
<style lang="scss" scoped>
  div.training {
    transform: scale(0.9) translateX(-10px);
  }
</style>
