var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-link',{staticClass:"d-flex-custom-pname",attrs:{"to":{
    name: 'team-player',
    params: {
      playerId: _vm.getPlayer.id,
      seasonId: _vm.currentSeasonId,
      id: _vm.currentTeamId,
    },
  }}},[_c('b-avatar',{staticClass:"mr-1",attrs:{"src":_vm.getPlayer.avatar,"size":"35"}}),_c('span',{class:{ small: _vm.small }},[_vm._v(_vm._s(_vm.getPlayer.name))]),_c('br'),(_vm.isNationalTeamLive)?_c('b-badge',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:(_vm.getSwappedTextForNational),expression:"getSwappedTextForNational",modifiers:{"hover":true}}],staticClass:"ml-2",attrs:{"variant":"info"}},[_c('div',{staticClass:"d-flex align-items-center",staticStyle:{"gap":"5px"}},[_c('span',[_c('img',{attrs:{"src":require("@/assets/images/icons/flight.png"),"width":"20"}})])])]):(_vm.isNationalTeam)?_c('b-badge',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:(_vm.getSwappedTextForNational),expression:"getSwappedTextForNational",modifiers:{"hover":true}}],staticClass:"ml-2",attrs:{"variant":"info"}},[_c('div',{staticClass:"d-flex align-items-center",staticStyle:{"gap":"5px"}},[(_vm.getPlayer.nation)?_c('country-flag',{staticClass:"rounded",attrs:{"country":_vm.getPlayer.nation,"size":"small"}}):_c('b-img',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip"}],attrs:{"src":"/img/default-club-logo.png","rounded":"0","title":_vm.$t('Please select nation for player'),"width":"20"}})],1)]):(_vm.isSwapped)?_c('b-badge',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:(_vm.getSwappedText),expression:"getSwappedText",modifiers:{"hover":true}}],staticClass:"ml-2",attrs:{"variant":_vm.isSwapped.swappedFinish ? 'dark' : 'success'}},[_vm._v(" "+_vm._s(_vm.$t("Swapped"))+" "+_vm._s(_vm.isSwapped.prevTeam.team && _vm.isSwapped.prevTeam.team.toString().substr(0, 4))+" ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }