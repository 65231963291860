<template>
  <div>
    <preview-info-bar :training="training" />
    <b-card>
      <div class="card-header p-0 m-0 mb-2">
        <div>
          <b-card-title>
            <feather-icon icon="CopyIcon" size="24" />
            {{ training.title }}
          </b-card-title>
        </div>
      </div>
      <div>
        <div v-if="training.trainingBlocks" :type="'border'" :set="(tmpTitles = [])">
          <!-- TODO : TRANSLATE -->
          <b-modal
            v-model="showImgModal"
            id="modal-goal-add"
            ok-only
            :ok-title="$t('Save')"
            centered
            :title="$t('Media Content')"
          >
            <span v-html="imgModal"></span>
            <template #modal-footer>
              <button type="button" class="btn btn-primary" @click="showImgModal = false">{{ $t("Close") }}</button>
            </template>
          </b-modal>

          <b-card
            v-for="(tb, index) in training.trainingBlocks"
            :key="tb.id"
            :set="(tmpTitles[index] = tb.title.split(' '))"
            :title="`${tmpTitles[index][0] + ' ' + $t(tmpTitles[index][1])} (${tb.trainingItems.length})`"
            class="p-0"
          >
            <p>{{ tb.description }}</p>
            <b-row>
              <b-col md="4" class="training-items" v-for="tit in tb.trainingItems" :key="tit.id">
                <div class="border p-1">
                  <h5 class="mb-1">
                    {{ tit.name }}
                    <span class="badge badge-warning">{{ tit.duration }} dk</span>
                  </h5>

                  <div class="row">
                    <div class="col-md-6">
                      <p v-text="tit.description"></p>
                    </div>
                    <div class="col-md-6">
                      <span v-for="(itemMedias, index) in tit.trainingItemMedia" :key="index">
                        <span
                          v-if="itemMedias.type == 'image'"
                          class="border w-100 medias"
                          @click="
                            imgModal = itemMedias.path
                            showImgModal = true
                          "
                          v-html="itemMedias.path"
                        ></span>
                        <span
                          v-if="itemMedias.type == 'video'"
                          class="border w-100 medias"
                          @click="
                            imgModal = itemMedias.path
                            showImgModal = true
                          "
                          v-html="itemMedias.path"
                        ></span>
                      </span>
                    </div>
                  </div>
                  <small class="text-secondary" v-if="tit.learningOutcomes">
                    <div class="d-flex">
                      <span
                        class="badge badge-primary mr-1"
                        v-for="learn in tit.learningOutcomes"
                        :key="learn.id"
                        v-text="learn.name"
                      ></span>
                    </div>
                  </small>
                </div>
              </b-col>
            </b-row>
          </b-card>
        </div>
      </div>
    </b-card>

    <b-card>
      <template #header>
        <h4 class="mb-0">{{ $t("Participants") }}</h4>
      </template>
      <div>
        <PreviewTrainingPlayerTest />
      </div>
    </b-card>
    <!-- VIDEOS --->
    <b-card :title="$t('Videos')" class="mt-2">
      <b-row v-if="getMedias(training.trainingMedia, 'video').length">
        <b-col lg="4" class="px-2" v-for="video in getMedias(training.trainingMedia, 'video')" :key="video.id">
          <iframe
            class="mx-1"
            width="380"
            height="300"
            :src="video.path"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </b-col>
      </b-row>
      <div v-else class="alert alert-warning p-2">{{ $t("Data not found") }}</div>
    </b-card>
  </div>
</template>
<script>
  import AppCollapse from "@core/components/app-collapse/AppCollapse.vue"
  import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue"
  import PreviewInfoBar from "./PreviewInfoBar.vue"
  import PreviewTrainingPlayerTest from "./PreviewTrainingPlayerTest.vue"

  export default {
    components: {
      PreviewInfoBar,
      AppCollapse,
      AppCollapseItem,
      PreviewTrainingPlayerTest,
    },
    data() {
      return {
        training: {},
        showImgModal: false,
        imgModal: false,
      }
    },
    methods: {
      getTraining() {
        this.$http
          // TODO : GET TRAINING FROM API
          // .get(`https://run.mocky.io/v3/241914e8-89a4-42c3-9063-5992fab18a4b`)
          .get(`/training/${this.routerParams.trainingId}`)
          .then((response) => {
            this.training = response.data
          })
          .catch((err) => {
            // TODO : TRANSLATE
            this.toast($t("Data not found"), "EyeIcon", "danger")
          })
      },
    },
    beforeMount() {
      this.getTraining()
    },
    setup(props, { root }) {
      const getMedias = (media, type) => {
        if (!media) return []
        return Object.values(media).filter((item) => {
          return item.type == type
        })
      }
      return {
        getMedias,
      }
    },
  }
</script>
<style lang="scss">
  .collapse-default {
    all: unset !important;
  }
  span {
    figure {
      img {
        max-width: 100%;
        width: 100%;
      }
    }
  }
  .training-items {
    .row + small {
      position: absolute;
      left: 2rem;
      bottom: 1.5rem;
    }
    .border {
      box-shadow: 0 0 15px rgba(1, 1, 1, 0.04);
      height: 300px;
      overflow: scroll;
      @media (max-width: 768px) {
        margin-bottom: 1rem;
      }
    }
  }
  .medias {
    video,
    iframe {
      width: 100%;
    }
  }
</style>
