// import http from '@/libs/axios'
// import i18n from '@/libs/i18n'
// import toastService from './toastService'
import firebase from "firebase/app"
import "firebase/firestore"

export default class chatService {
  constructor() {
    if (!this.db) {
      const { Timestamp, GeoPoint } = firebase.firestore
      this.db = firebase
        .initializeApp({
          authDomain: "localhost",
          projectId: "convapp-pr-0109",
          apiKey: "AIzaSyCrWY0Y5SX_9N7-OJy2h4swuF5pY1-eV7M",
        })
        .firestore()
      this.sub = null
      this.msgs = []
      this.msg = {}
    }
    // // do something with documents
    // db.collection("academy")
    //     .doc("general")
    //     .onSnapshot((doc) => {
    //         console.log("Current data: ", doc.data());
    //     });
  }

  async query(logic, userId, senderId, limit) {
    return new Promise((resolve, reject) => {
      return this.db
        .collection("academy")
        .where("userId", logic, userId)
        .where("senderId", logic, senderId)
        .orderBy("order", "desc")
        .limit(limit)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            var t = doc.data()
            t.time = t.time.seconds
            // console.log(t)
          })
          return resolve(querySnapshot)
        })
        .catch((err) => reject(err))
    })
  }

  async get(userId, senderId) {
    if (userId.toString().includes("group_")) return this.getGroup(userId)
    var data = []
    return new Promise(async (resolve, reject) => {
      var data1 = await this.query("==", Number(userId), Number(senderId), 15)
      if (userId != senderId) var data2 = await this.query("==", Number(senderId), Number(userId), 15)
      if (userId == senderId) var data2 = []

      var querySnapshotArr = [data1, data2]
      var data = []
      querySnapshotArr.forEach((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          var t = doc.data()
          t.time = t.time.seconds
          data.push(t)
        })
      })

      setTimeout(() => {
        data = data.sort(function (a, b) {
          return a.order - b.order
        })
        return resolve(data)
      }, 10)
    })
  }

  async getGroup(userId) {
    return new Promise((resolve, reject) => {
      var groupChatData = []
      return this.db
        .collection("academy")
        .where("userId", "==", userId)
        .limit(30)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            var t = doc.data()
            t.time = t.time.seconds
            groupChatData.push(t)
          })

          return resolve(groupChatData)
        })
        .catch((err) => reject(err))
    })
  }

  async send(message, contactId) {
    // console.log(message)

    message.time = new Date().getTime()
    message.order = new Date().getTime()

    return new Promise((resolve, reject) => {
      return this.db
        .collection("academy")
        .doc(this.generateName(contactId))
        .set(message, { merge: true })
        .then((data) => {
          return resolve(message)
        })
        .catch((err) => reject("ERROR: SEND MSG, " + err))
    })
  }

  async groupHandle(userId, handleCallback) {
    // IF SUBSCRIBE, WHEN CHANGE GO TO UNSUBSCRIBE
    // https://firebase.google.com/docs/firestore/query-data/listen#detach_a_listener
    if (this.sub != null) this.sub()
    // https://firebase.google.com/docs/firestore/query-data/listen#listen_to_multiple_documents_in_a_collection
    return new Promise((resolve, reject) => {
      this.sub = this.db
        .collection("academy")
        .where("userId", "==", userId)
        .orderBy("order", "desc")
        .limit(1)
        .onSnapshot((querySnapshot) => {
          querySnapshot.docs.forEach((dt) => {
            if (this.msg != dt.data()) this.msg = dt.data()
            handleCallback(dt.data())
          })
        })

      return this.sub
    })
  }

  async handle(userId, senderId, handleCallback) {
    // IF SUBSCRIBE, WHEN CHANGE GO TO UNSUBSCRIBE
    // https://firebase.google.com/docs/firestore/query-data/listen#detach_a_listener
    if (this.sub != null) this.sub()
    // https://firebase.google.com/docs/firestore/query-data/listen#listen_to_multiple_documents_in_a_collection
    return new Promise((resolve, reject) => {
      this.sub = this.db
        .collection("academy")
        .where("userId", "==", parseInt(userId))
        .where("senderId", "==", parseInt(senderId))
        .orderBy("order", "desc")
        .limit(1)
        .onSnapshot((querySnapshot) => {
          querySnapshot.docs.forEach((dt) => {
            if (this.msg != dt.data()) this.msg = dt.data()
            handleCallback(dt.data())
          })
        })

      return this.sub
    })
  }

  generateName(name) {
    this.get(name)
    return "u" + name + "" + new Date().getTime()
  }

  uniqId(length) {
    var text = ""
    var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"

    for (var i = 0; i < length; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length))
    }

    return text
  }
}
