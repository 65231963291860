<template>
  <div>
    <input
      :placeholder="inputPlaceHolder"
      :class="inputType == 'number' ? 'number-input' : ''"
      v-model="value"
      @change="changeValue"
      class="form-control comment"
    />
  </div>
</template>

<script>
  import { ref, watch, onUnmounted, onMounted, computed, onBeforeMount } from "@vue/composition-api"
  import Cleave from "cleave.js"

  import axios from "@axios"
  import { useRouter } from "@core/utils/utils"
  import store from "@/store"
  import testStoreModule from "./testStoreModule"
  import { useToast } from "vue-toastification/composition"
  import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
  import i18n from "@/libs/i18n"

  export default {
    components: {},
    props: {
      player: {
        type: Object,
        default: {},
      },
      dateChanged: {
        type: Boolean,
        default: false,
      },
      category: {
        type: String,
        default: "height",
      },
      title: {
        type: String,
        default: "",
      },
      date: {
        type: [Date, String],
        default: new Date(),
      },
      inputType: {
        type: String,
        default: "text",
      },
      inputPlaceHolder: {
        type: String,
        default: "",
      },
    },
    mounted() {
      Array.from(document.getElementsByClassName("number-input")).forEach(function (el) {
        new Cleave(el, {
          numeral: true,
          numericOnly: true,
          numeralPositiveOnly: true,
          numeralDecimalMark: ",",
          delimiter: ".",
        })
      })
    },
    setup(props, { emit, root }) {
      const { router, route } = useRouter()

      const STORE_MODULE_NAME = "team-test"

      // Register module
      if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, testStoreModule)

      const toast = useToast()
      const value = ref(null)
      const seasonId = ref(Number(router.currentRoute.params.seasonId))
      const teamId = ref(Number(router.currentRoute.params.id))
      const options = {
        creditCard: {
          creditCard: true,
        },
        date: {
          date: true,
          delimiter: "-",
          datePattern: ["Y", "m", "d"],
        },
        phone: {
          phone: true,
          phoneRegionCode: "US",
        },
        time: {
          time: true,
          timePattern: ["h", "m", "s"],
        },
        number: {
          numeral: true,
          numericOnly: true,
          numeralDecimalMark: ",",
          delimiter: ".",
        },
        block: {
          blocks: [4, 3, 3],
          uppercase: true,
        },
        delimiter: {
          delimiter: "·",
          blocks: [3, 3, 3],
          uppercase: true,
        },
        customDelimiter: {
          delimiters: [".", ".", "-"],
          blocks: [3, 3, 3, 2],
          uppercase: true,
        },
        prefix: {
          prefix: "+63",
          blocks: [3, 3, 3, 4],
          uppercase: true,
        },
      }

      const changeValue = () => {
        let object = {
          date: props.date,
          player: props.player.id,
          value: value.value,
          name: props.category,
          title: props.title,
          team: parseInt(router.currentRoute.params.id),
          season: parseInt(router.currentRoute.params.seasonId),
        }
        //emit("setLoading", true);

        const item = checkIfThereIsARecord(props.player.id, props.category, "check")
        if (item && object.value) {
          store.dispatch("team-test/update", { id: item.id, object: object }).then((response) => {
            checkIfThereIsARecord(props.player.id, props.category, "set", response.data)
            // emit("setLoading", false);
            toast({
              component: ToastificationContent,
              position: "bottom-right",
              props: {
                title: i18n.t("Successfully Saved"),
                icon: "CheckIcon",
                variant: "success",
              },
            })
          })
        } else if (item) {
          store.dispatch("team-test/delete", item.id).then((response) => {
            checkIfThereIsARecord(props.player.id, props.category, "set", response.data)
            // emit("setLoading", false);
            toast({
              component: ToastificationContent,
              position: "bottom-right",
              props: {
                title: i18n.t("Successfully Deleted"),
                icon: "CheckIcon",
                variant: "success",
              },
            })
          })
        } else {
          store.dispatch("team-test/add", { season: seasonId.value, object: object }).then((response) => {
            checkIfThereIsARecord(props.player.id, props.category, "set", response.data)
            //emit("setLoading", false);
            toast({
              component: ToastificationContent,
              position: "bottom-right",
              props: {
                title: i18n.t("Successfully Added"),
                icon: "CheckIcon",
                variant: "success",
              },
            })
            if (router.currentRoute.name == "team-tests-enter") {
              router.push({
                name: "team-tests-enter-date",
                params: {
                  ...router.currentRoute.params,
                  date: object.date,
                },
              })
            }
          })
        }
      }

      const getItems = computed(() => store.getters["team-test/items"])
      const items = computed(() => store.state["team-test"].items)

      const checkIfThereIsARecord = (playerId, itemName, type, data) => {
        if (getItems.value.length === 0 && type === "set") return (value.value = null)
        return getItems.value.find((item) => {
          if (item.player.id === playerId && item.name === itemName) {
            if (type === "set") {
              return (value.value = data ? data.value : item.value)
            }
            return item
          } else {
            if (type === "set") {
              return (value.value = null)
            }

            return null
          }
        })
      }

      watch(
        () => props.category,
        (val, oldVal) => {
          if (val !== oldVal) {
            checkIfThereIsARecord(props.player.id, val, "set")
          }
        },
      )

      watch(
        () => props.dateChanged,
        (val, oldVal) => {
          checkIfThereIsARecord(props.player.id, props.category, "set")
        },
      )

      onMounted(() => {
        checkIfThereIsARecord(props.player.id, props.category, "set")
      })

      return { value, changeValue, items, options }
    },
  }
</script>

<style lang="scss">
  .comment {
    @media (max-width: 767px) {
      min-width: 320px;
    }
  }
</style>
