<template>
  <b-sidebar
    id="sidebar-add-transfer-history"
    :visible="isAddTransferHistoryActive"
    bg-variant="white"
    @shown="$emit('update:isAddTransferHistoryActive', true)"
    @hidden="
      $emit('update:isAddTransferHistoryActive', false)
      formTrigger++
    "
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
  >
    <template #default="{ hide }">
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">{{ $t("Transfer History") }}</h5>
        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
      </div>
      <div></div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver" rules="required" :key="formTrigger">
        <!-- Form -->
        <b-form v-if="playerTransferHistoryForm" class="p-2" @submit.prevent="handleSubmit(goSubmitTransferHistory())">
          <validation-provider #default="{ errors }" name="season" rules="required">
            <b-form-group>
              <label label-for="season-name" class="d-block">{{ $t("Season") }}</label>
              <v-select
                :placeholder="$t('Please select a season')"
                v-model="playerTransferHistoryForm.season"
                :state="errors.length > 0 ? false : null"
                :options="seasons"
                :reduce="(option) => option.id"
                :getOptionLabel="(option) => option.name"
              >
              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <validation-provider #default="{ errors }" name="club" rules="required">
            <b-form-group>
              <label label-for="club-name" class="d-block">{{ $t("Club") }}</label>
              <v-select
                :placeholder="$t('Club Select')"
                v-model="playerTransferHistoryForm.club"
                :state="errors.length > 0 ? false : null"
                :options="[...clubs, { id: 0, name: $t('National Team')}]"
                :reduce="(option) => option.id"
                :getOptionLabel="(option) => option.name"
              >
                <template v-slot:option="option">
                  <country-flag v-if="option.id === 0" :country="playerData.nation"
                      size="small" class="rounded" style="" />
                  <img v-if="option.logo" :src="option.logo" height="18" class="mr-1" />
                  {{ option.name }}
                </template>
              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <validation-provider #default="{ errors }" name="team" rules="required" v-if="!isNationalTeamInput">
            <b-form-group>
              <label label-for="team-name" class="d-block">{{ $t("Team") }}</label>
              <v-select
                :placeholder="$t('Please select a team')"
                v-model="playerTransferHistoryForm.team"
                :state="errors.length > 0 ? false : null"
                :options="teams"
                :reduce="(option) => option.id"
                :getOptionLabel="(option) => option.name"
              >
                <template v-slot:option="option">
                  <span v-if="option.club">
                    <img :src="option.club.logo" height="18" />
                    {{ option.club.name }}
                  </span>
                  {{ option.name }}
                </template>
              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <b-row>
            <b-col md="12" xl="6">
              <validation-provider #default="{ errors }" name="startDate" rules="required">
                <b-form-group>
                  <label class="w-100">
                    {{ $t("Start Date") }}
                  </label>
                  <div class="d-flex justify-content-between">
                    <flat-pickr
                      v-model="playerTransferHistoryForm.startDate"
                      class="form-control"
                      :config="{
                        locale: user.lang.value == 'fr' ? French : user.lang.value == 'tr' ? Turkish : null,
                        enableTime: false,
                        defaultDate: new Date(),
                        altFormat: 'F j, Y',
                      }"
                    />
                  </div>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col md="12" xl="6">
              <validation-provider #default="{ errors }" name="endDate">
                <b-form-group>
                  <label class="w-100">
                    {{ $t("End Date") }}
                  </label>
                  <div class="d-flex justify-content-between">
                    <flat-pickr
                      v-model="playerTransferHistoryForm.endDate"
                      class="form-control"
                      :config="{
                        locale: user.lang.value == 'fr' ? French : user.lang.value == 'tr' ? Turkish : null,
                        enableTime: false,
                        altFormat: 'F j, Y',
                        maxDate: false
                      }"
                    />
                  </div>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" type="submit">{{
                $t("Add")
              }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              ref="hideClick"
              @click="hide"
            >{{ $t("Cancel") }}
            </b-button
            >
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
  import { ValidationProvider, ValidationObserver } from "vee-validate"
  import { ref, onMounted, onBeforeMount } from "@vue/composition-api"
  import { required, alphaNum, email } from "@validations"
  import formValidation from "@core/comp-functions/forms/form-validation"
  import Ripple from "vue-ripple-directive"
  import vSelect from "vue-select"
  import axios from "@axios"
  import flatPickr from "vue-flatpickr-component"
  import { BFormCheckbox } from "bootstrap-vue"
  import Cleave from "vue-cleave-component"
  // eslint-disable-next-line import/no-extraneous-dependencies
  import "cleave.js/dist/addons/cleave-phone.us"
  import { Turkish } from "flatpickr/dist/l10n/tr.js"
  import { French } from "flatpickr/dist/l10n/fr.js"
  import router from "@/router"
  import { teamService } from "@/services"

  export default {
    components: {
      vSelect,
      Cleave,
      flatPickr,

      // Form Validation
      ValidationProvider,
      ValidationObserver,
      BFormCheckbox,
    },
    directives: {
      Ripple,
    },
    model: {
      prop: "isAddTransferHistoryActive",
      event: "update:is-add-transfer-history-active",
    },
    props: {
      isAddTransferHistoryActive: {
        type: Boolean,
        required: true,
      },
      playerData: {
        default: null,
      },
      submitTransferHistory: {
        required: true,
      },
      updateTransferHistory: {
        default: null,
      },
      Data: {
        default: null,
      },
      playerTransferHistoryForm: {
        default: null,
      },
    },
    data() {
      return {
        required,
        formTrigger: 0,
      }
    },
    computed: {
      isNationalTeamInput() {
        return this.playerTransferHistoryForm.club === 0
      },
    },
    watch: {
      Data: function(newData, oldData) {
        // console.log(newData)
        if (newData) {
          this.playerTransferHistoryForm = newData
          // if(this.playerTransferHistoryForm?.isNationalTeam){
          //   this.playerTransferHistoryForm.club = 0
          // }
        }
      },
      isAddTransferHistoryActive: function(oldVal, newVal) {
        this.playerTransferHistoryForm.startDate = this.playerTransferHistoryForm.startDate
          ? this.playerTransferHistoryForm.startDate
          : new Date()


      },
      playerTransferHistoryForm(v) {
        if (v.isNationalTeam) {
          this.playerTransferHistoryForm.club = 0
          this.playerTransferHistoryForm.team = 0
        }
      },
    },
    methods: {
      goSubmitTransferHistory() {
        this.$refs.refFormObserver.validate().then((success) => {
          if (success) {
            if (this.isNationalTeamInput) {
              this.playerTransferHistoryForm.team = 0
            }
            if (this.playerTransferHistoryForm.endDate <= 0) this.playerTransferHistoryForm.endDate = null
            if (this.playerTransferHistoryForm.id) {
              this.updateTransferHistory(this.playerTransferHistoryForm)
            } else {
              this.submitTransferHistory(this.playerTransferHistoryForm)
            }
            this.$refs.hideClick.click()
          }
        })
      },
    },

    setup(props, { emit, root }) {
      const seasons = ref([])
      const teams = ref([])
      const clubs = ref([])
      const user = JSON.parse(localStorage.getItem("userData"))
      const teamSer = new teamService()
      const now = new Date()
      const maxDate = new Date(now.getFullYear(), now.getMonth(), now.getDate())

      onBeforeMount(async () => {
        let team = await axios.get("/team/" + router.currentRoute.params.id)
        let club = team.data.club.id && (await axios.get("/club/" + team.data.club.id))
        clubs.value = [club.data]

        await getSeasons()
        await getTeams()
      })
      const getSeasons = () => {
        axios.get("/season").then((response) => {
          seasons.value = response.data
        })
      }
      const getTeams = () => {
        teamSer.get({ club: clubs.value[0]?.id }).then((response) => {
          teams.value = response.data.data
        })
      }

      const { refFormObserver, getValidationState, resetForm } = formValidation()

      return {
        refFormObserver,
        getValidationState,
        resetForm,

        seasons,
        clubs,
        teams,
        user,
        Turkish,
        French,
        maxDate,
      }
    },
  }
</script>

<style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";

  #add-new-user-sidebar {
    .vs__dropdown-menu {
      max-height: 200px !important;
    }
  }
</style>
