<template>
  <b-overlay variant="white" :show="showLoading" spinner-variant="primary" blur="0" opacity=".75" rounded="sm">
    <b-row v-if="userData">
      <b-col xl="9" class="player-top-bar-info">
        <b-card class="pb-0">
          <b-row class="topbar">
            <b-col md="4">
              <div class="d-flex" @click="editPlayer()">
                <div class="p-0">
                  <b-avatar
                    size="110"
                    :src="userData != undefined ? userData.avatar : null"
                    :text="avatarText(userData.name)"
                    :variant="`light-primary`"
                    rounded
                  />
                </div>
                <!--  -->
                <div style="z-index: 2">
                  <b-link class="mt-0 mb-0 ml-1 d-block h4"
                    >{{ userData.name }}
                    <feather-icon v-b-tooltip.hover :title="$t('Edit')" size="12" icon="EditIcon" />
                  </b-link>
                  <span class="mt-0 ml-1 d-block"
                    >{{ userData.birthdate | nativeDate }} ({{ getAge(userData.birthdate) }})</span
                  >
                  <span class="mt-0 ml-1 d-block" v-if="false"
                    >{{ $t("license") }}: {{ userData.licenseNo || "-" }}</span
                  >
                  <div class="p-1 d-flex">
                    <span class="badge badge-primary mr-1 col">{{ $t(userData.position.name) }}</span>
                    <span class="badge badge-dark col"
                      >{{
                        userData.foot === 1 ? $t("Right Foot") : userData.foot === 0 ? $t("Left Foot") : $t("Both Feet")
                      }}
                    </span>
                  </div>
                  <div class="p-1 mt-n2">
                    <b-badge
                      v-if="isNationalTeam"
                      class=""
                      variant="info"
                      v-b-tooltip.hover
                      :title="
                        !isNationalTeam.swappedFinish
                          ? `${$t('Currently playing for national team to')} ${new Date(
                              isNationalTeam.startDate,
                            ).toLocaleDateString()} & ${new Date(isNationalTeam.endDate).toLocaleDateString()}`
                          : `${$t('Played for national team between in')} ${new Date(
                              isNationalTeam.startDate,
                            ).toLocaleDateString()} & ${new Date(isNationalTeam.endDate).toLocaleDateString()}`
                      "
                    >
                      <div class="d-flex align-items-center" style="gap: 5px">
                        <country-flag
                          v-if="userData.nation && isNationalTeam.swappedFinish"
                          :country="userData.nation"
                          size="small"
                          class="rounded"
                          style=""
                        />
                        <b-img
                          v-else-if="isNationalTeam.swappedFinish"
                          class="mr-2 mx-auto"
                          :src="`/img/default-club-logo.png`"
                          rounded="0"
                          v-b-tooltip
                          :title="$t('Please select nation for player')"
                          width="20"
                        />
                        <img
                          v-else-if="!isNationalTeam.swappedFinish"
                          :src="require(`@/assets/images/icons/flight.png`)"
                          width="20"
                        />
                      </div>
                    </b-badge>
                  </div>
                </div>
              </div>
              <div v-if="positions">
                <p class="badge badge-secondary m-0">
                  <span style="margin-left: 5px" v-for="(pId, index) in userData.availablePositions" :key="index">
                    <span>{{ $t(positions.find((x) => x.id == pId).name) }}</span>
                    <span v-if="index != userData.availablePositions.length - 1"> - </span>
                  </span>
                </p>
              </div>

              <div class="d-flex justify-content-start mt-2">
                <b-link
                  class="w-25 badge p-1 badge-light-primary mb-1 mb-lg-0 ovh"
                  v-b-tooltip.hover
                  :title="$t('Player Compare')"
                  :to="{ path: '/compare-player' }"
                >
                  <feather-icon icon="UserIcon" size="24" />
                </b-link>
                <b-link
                  class="badge p-1 badge-light-primary w-25 ml-2"
                  v-b-tooltip.hover
                  :title="$t('Team Compare')"
                  :to="{ path: '/compare-team' }"
                >
                  <feather-icon icon="RepeatIcon" size="24" />
                </b-link>
                <b-dropdown
                  :text="$t('Season')"
                  size="sm"
                  class="w-50 ml-1 mb-1 mb-lg-0 btn-light-primary"
                  variant="primary"
                  v-if="routerParams.seasonId"
                >
                  <b-dropdown-item
                    v-for="x in uniqueTransferHistories"
                    :key="x.id"
                    class="w-100"
                    @click="
                      routerParams.seasonId
                        ? goPlayer({
                            name: 'team-player',
                            params: { seasonId: x.season.id, id: x.team.id, playerId: userData.id },
                          })
                        : goPlayer({
                            name: 'scout-deleted-player',
                            params: { sId: x.season.id, tId: x.team.id, playerId: userData.id },
                          })
                    "
                  >
                    {{ x.season.year }} {{ x.team.club && x.team.club.name.substr(0, 5) }} {{ x.team.name }}
                    <b-badge v-if="x.isSwapped">
                      <feather-icon icon="RepeatIcon" size="24" />
                    </b-badge>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </b-col>

            <!-- KATILIM -->
            <b-col md="4" v-if="attendance">
              <training-participate :attendance="attendance" />
            </b-col>

            <!-- SON 5 ANTREANMAN -->
            <b-col md="4" v-if="attendance">
              <training-performance :playerSerpScores="attendance" />
            </b-col>
          </b-row>
        </b-card>
      </b-col>

      <!-- BOY & KILO -->
      <b-col xl="3" class="player-top-bar-phy">
        <b-card
          class="p-0"
          v-if="!showLoading && getOffsetHeight && userData && attendance"
          :style="{ height: getOffsetHeight }"
        >
          <div class="w-100">
            <physics-chart :user="userData" :tests="tests" />
          </div>
        </b-card>
      </b-col>
    </b-row>

    <b-card>
      <b-tabs
        v-model="tabIndex"
        class="position-relative"
        content-class=" mt-1 mt-md-0 w-100"
        pills
        nav-wrapper-class="col-md-12 col-12 tab-title-for-player"
        nav-class="nav-left"
      >
        <b-tab>
          <template #title>
            <span class="d-none d-sm-inline">{{ $t("Summary") }}</span>
          </template>
          <div v-if="showPlayerAccount">
            <player-account
              :performances="performances"
              :user-data="userData"
              :playerLearningOutcomes="playerLearningOutcomes"
              :playerTransferHistories="playerTransferHistories"
              :abilities="abilities"
              class="pt-75"
            />
          </div>
        </b-tab>

        <!-- Tab: History -->
        <b-tab>
          <template #title>
            <span class="d-none d-sm-inline">{{ $t("Performance") }}</span>
          </template>
          <player-performance
            :playerPerformances="{ performance: playerPerformances }"
            :player="userData"
            class="pt-75 mx-n2"
          />
        </b-tab>

        <!-- Tab: Profile -->
        <b-tab>
          <template #title>
            <span class="d-none d-sm-inline">{{ $t("Tests") }}</span>
          </template>
          <player-test :tests="tests" class="pt-75 mx-n2" />
        </b-tab>

        <!-- Tab: Profile -->
        <b-tab>
          <template #title>
            <span class="d-none d-sm-inline">{{ $t("Matches") }}</span>
          </template>
          <player-matches :competitions="competitions" class="pt-75 mx-n2" />
        </b-tab>

        <b-tab>
          <template #title>
            <span class="d-none d-sm-inline">{{ $t("Trainings") }}</span>
          </template>
          <player-trainings class="pt-75 mx-n2" />
        </b-tab>

        <!-- Tab: Comment / reviews -->
        <b-tab>
          <template #title>
            <span class="d-none d-sm-inline">{{ $t("Reviews") }}</span>
          </template>
          <player-comment :reviews="playerReviews" class="pt-75 mx-n2" />
        </b-tab>

        <b-button-group class="player-profile-action-buttons position-absolute position-top-0 position-right-0">
          <template v-if="playerTransferHistories && activeTransfer && getSetting('swap')">
            <b-button size="sm" variant="link" no-caret @click="openSwapModal()">
              <feather-icon icon="RepeatIcon" size="24" />
            </b-button>
            <!-- vertical line-->
            <span></span>
          </template>

          <template v-if="getSetting('communication')">
            <b-button size="sm" variant="link" no-caret @click="openShareHistoryModal()">
              <feather-icon icon="ArchiveIcon" size="24" />
            </b-button>
            <b-dropdown size="sm" variant="link" no-caret>
              <template #button-content>
                <feather-icon icon="Share2Icon" size="24" />
              </template>
              <b-dropdown-item :to="{ name: 'team-player-share-scout' }">{{ $t("With Scout") }}</b-dropdown-item>
              <b-dropdown-item :to="{ name: 'team-player-share-parent' }">{{ $t("With Parent") }}</b-dropdown-item>
            </b-dropdown>
          </template>
        </b-button-group>
      </b-tabs>
    </b-card>

    <b-modal size="xl" id="share-history" ref="shareHistoryModal" hide-footer centered :title="$t('Share History')">
      <div class="d-flex justify-content-end">
        <b-table
          ref="refDataTable"
          :fields="fields"
          :items="fetchShareHistory"
          class="position-relative"
          :empty-text="$t('No records found')"
          responsive
          primary-key="id"
          show-empty
          busy.sync="false"
          style="min-height: 400px"
        >
          <template #table-busy>
            <div class="text-center my-2">
              <b-spinner class="align-middle"></b-spinner>
            </div>
          </template>

          <template #row-details="row">
            <b-card>
              <b-row class="mb-2">
                <b-col sm="3" class="text-sm-right"
                  ><b>{{ $t("Subject") }}</b></b-col
                >
                <b-col>{{ row.item.request.subject }}</b-col>
              </b-row>
              <b-row class="mb-2">
                <b-col sm="3" class="text-sm-right"
                  ><b>{{ $t("Message") }}</b></b-col
                >
                <b-col>
                  <span class="card card-body border border-1">
                    <span v-html="row.item.request.message"></span>
                  </span>
                </b-col>
              </b-row>
              <b-row class="mb-1" v-if="row.item.request.files.length > 0">
                <b-col sm="3" class="text-sm-right"
                  ><b>{{ $t("Files") }}</b></b-col
                >
                <b-col>
                  <template v-for="(file, index) in row.item.request.files">
                    <a :href="file.url" target="_blank">{{ index + 1 + ". " + $t("File") + " " }}</a>
                  </template>
                </b-col>
              </b-row>
            </b-card>
          </template>

          <template #cell(user)="data">
            <span class="text-nowrap">{{ data.value.firstName + " " + data.value.lastName }}</span>
          </template>

          <template #cell(created_at)="data">
            <span>{{ data.value | nativeDateTime }}</span>
          </template>

          <template #cell(receiverType)="data">
            <span>{{ $t(data.value) }}</span>
          </template>

          <template #cell(status)="data">
            <span>
              <feather-icon
                :icon="data.value ? 'CheckIcon' : 'XIcon'"
                :color="data.value ? 'green' : 'red'"
                v-b-tooltip.hover.top="data.value ? $t('Successfully Shared') : $t('Successfully Unshared')"
                size="16"
              />
            </span>
          </template>

          <template #cell(actions)="row">
            <b-button size="sm" @click="row.toggleDetails" class="mr-2">
              <feather-icon icon="EyeIcon" size="12" />
            </b-button>
          </template>
        </b-table>
      </div>
    </b-modal>

    <b-modal
      ref="swapModal"
      :title="$t('Swap Team')"
      :no-close-on-backdrop="showLoading"
      :hide-header-close="showLoading"
      hide-footer
    >
      <b-card-text>
        <validation-observer v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(submitSwapData)">
            <validation-provider #default="{ errors }" name="team" rules="required">
              <b-form-group :label="$t('Swap To')">
                <v-select
                  transition=""
                  :placeholder="$t('Team')"
                  v-model="swapFormData.team"
                  :clearable="false"
                  :state="errors.length > 0 ? false : null"
                  :options="activeClubTeams"
                  :reduce="(option) => option.id"
                  :getOptionLabel="(option) => option.club.name + ' / ' + option.name"
                >
                  <template v-slot:option="option">
                    <img :src="option.club.logo" height="18" class="mr-1" />
                    {{ option.name }}
                  </template>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>

            <validation-provider #default="{ errors }" name="startDate" rules="required">
              <b-form-group>
                <label class="w-100">
                  {{ $t("Start Date") }}
                </label>
                <div class="d-flex justify-content-between">
                  <flat-pickr
                    v-model="swapFormData.startDate"
                    class="form-control"
                    :config="{
                      enableTime: false,
                      locale: user.lang.value == 'fr' ? French : user.lang.value == 'tr' ? Turkish : null,
                      enableTime: false,
                      defaultDate: new Date(),
                      altFormat: 'F j, Y',
                    }"
                  />
                </div>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>

            <validation-provider #default="{ errors }" name="endDate" rules="required">
              <b-form-group>
                <label class="w-100">
                  {{ $t("End Date") }}
                </label>
                <div class="d-flex justify-content-between">
                  <flat-pickr
                    v-model="swapFormData.endDate"
                    class="form-control"
                    :config="{
                      enableTime: false,
                      locale: user.lang.value == 'fr' ? French : user.lang.value == 'tr' ? Turkish : null,
                      enableTime: false,
                      altFormat: 'F j, Y',
                    }"
                  />
                </div>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>

            <button type="submit" class="btn btn-primary float-right" :disabled="showLoading">
              <b-spinner v-if="showLoading" small style="margin-bottom: 3px" />
              {{ $t("Save") }}
            </button>
          </form>
        </validation-observer>
      </b-card-text>
    </b-modal>
  </b-overlay>
</template>

<script>
  import { ValidationProvider, ValidationObserver } from "vee-validate"
  import { required } from "@validations"
  import { avatarText } from "@core/utils/filter"
  import axios from "@axios"
  import { ref, onUnmounted, onMounted, computed, watch } from "@vue/composition-api"
  import router from "@/router"
  import store from "@/store"
  import playerStoreModule from "../playerStoreModule"
  import PlayerAccount from "./PlayerAccount.vue"
  import PlayerMatches from "./PlayerMatches.vue"
  import PlayerHistory from "./PlayerHistory.vue"
  import PlayerTrainings from "./PlayerTrainings.vue"
  import PlayerPerformance from "./PlayerPerformance.vue"
  import PlayerComment from "./PlayerComment.vue"
  import PlayerTest from "./PlayerTest.vue"
  import PlayerCard from "@/views/apps/player-card/PlayerCard"
  import localService from "@/services/localService.js"

  import TrainingParticipate from "./components/TrainingParticipate.vue"
  import TrainingPerformance from "./components/TrainingPerformance.vue"
  import PhysicsChart from "./components/PhysicsChart.vue"

  import { useToast } from "vue-toastification/composition"
  import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
  import i18n from "@/libs/i18n"
  import { teamService } from "@/services"

  import { Turkish } from "flatpickr/dist/l10n/tr.js"
  import { French } from "flatpickr/dist/l10n/fr.js"
  import flatPickr from "vue-flatpickr-component"
  import vSelect from "vue-select"

  export default {
    components: {
      PlayerAccount,
      PlayerTest,
      PlayerHistory,
      PlayerPerformance,
      PlayerCard,
      PlayerMatches,
      TrainingParticipate,
      TrainingPerformance,
      PlayerTrainings,
      PhysicsChart,
      PlayerComment,
      flatPickr,
      vSelect,
      ValidationProvider,
      ValidationObserver,
    },
    data() {
      return {
        historyData: [],
      }
    },
    computed: {
      getOffsetHeight() {
        let breakPoint = this.$store.getters["app/currentBreakPoint"]
        if (breakPoint == "sm" || breakPoint == "xs") return "auto"

        return document.querySelector(".player-top-bar-info .card")
          ? document.querySelector(".player-top-bar-info .card").offsetHeight + "px"
          : false
      },
      isNationalTeam() {
        return this.userData.playerTransferHistories.find((d) => d.isNationalTeam)
      },
    },
    methods: {
      editPlayer() {
        this.$router.push({
          name: "team-player-edit",
          params: {
            seasonId: this.seasonId,
            id: this.teamId,
            playerId: this.routerParams.playerId,
          },
        })
      },
    },
    async beforeMount() {},
    mounted() {
      /*  this.$nextTick(() => {
        let currentBreakPoint  = this.$store.getters['app/currentBreakPoint'];
        if(currentBreakPoint != 'xs' || currentBreakPoint != 'sm' && document.querySelector('.player-top-bar-info .card')){
          let getHeight = document.querySelector('.player-top-bar-info .card').offsetHeight;
          document.querySelector('.player-top-bar-phy .card').style.height = getHeight + 'px';
        }
      },14000)*/
    },
    setup(props, { root }) {
      const showLoading = ref(false)
      const showPlayerAccount = ref(false)
      const userData = ref(null)
      const attendance = ref(null)
      const performances = ref(null)
      const playerPerformances = ref(null)
      const playerLearningOutcomes = ref(null)
      const playerTransferHistories = ref(null)
      const tests = ref(null)
      const competitions = ref(null)
      const toast = useToast()
      const seasonId = ref(Number(router.currentRoute.params.seasonId || router.currentRoute.params.sId))
      const teamId = ref(Number(router.currentRoute.params.id || router.currentRoute.params.tId))
      const playerId = ref(Number(router.currentRoute.params.playerId))
      const abilities = ref(null)
      const playerReviews = ref([])
      const STORE_MODULE_NAME = "app-player"
      const positions = ref(null)
      const tabIndex = ref(0)
      const uniqueTransferHistories = ref([])
      const shareHistoryModal = ref(null)
      const swapModal = ref(null)
      const shareHistoryData = ref([])
      const activeClubTeams = ref([])
      const teamSer = new teamService()
      const activeTransfer = ref(null)
      const user = JSON.parse(localStorage.getItem("userData"))
      const swapFormData = ref({
        season: null,
        club: null,
        team: null,
        startDate: new Date(),
        endDate: null,
      })

      const fields = computed({
        get: () => [
          {
            key: "user",
            label: i18n.t("From"),
          },
          {
            key: "email",
            label: i18n.t("To"),
          },
          {
            key: "receiverType",
            label: i18n.t("Type"),
          },
          {
            key: "request.reportType",
            label: i18n.t("Report Type"),
          },
          {
            key: "created_at",
            label: i18n.t("Date"),
          },
          {
            key: "status",
            label: i18n.t("Status"),
          },
          {
            key: "actions",
            label: i18n.t("Actions"),
          },
        ],
      })

      tabIndex.value = new localService("player_tab_index").get() || 0
      new localService("player_tab_index", "")

      // Register module
      if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, playerStoreModule)

      // UnRegister on leave
      onUnmounted(() => {
        if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
      })

      onMounted(async () => {
        showLoading.value = true
        getPositions()
        await fetchAbilities()
        await fetchPlayer()
        await fetchUserAttendance()
        await fetchUserPerformances()
        await fetchUserTest()
        await fetchUserCompetitions()
        await getchPlayerPerformances()
        await fetchPlayerAllReviews()
        await getTeams(activeTransfer.value?.club.id)
      })

      watch(activeClubTeams, (val) => {
        swapFormData.value.season = activeTransfer.value?.season.id
        swapFormData.value.club = activeTransfer.value?.club.id
      })

      const getTeams = (clubId) => {
        if (clubId == null) {
          return false
        }
        teamSer.get({ club: clubId }).then((response) => {
          activeClubTeams.value = response.data.data.filter((i) => i.id != activeTransfer.value?.team.id)
        })
      }

      const getPositions = async () => {
        const { data } = await axios.get("/position")
        positions.value = data
      }

      const fetchAbilities = async () => {
        await axios
          .get(`/ability`)
          .then((response) => {
            abilities.value = response.data
          })
          .catch((error) => {
            console.log(error)
          })
      }

      const fetchPlayerAllReviews = () => {
        axios
          .get(`/season/${seasonId.value}/player/${router.currentRoute.params.playerId}/reviews`)
          .then((response) => {
            playerReviews.value = response.data
          })
          .catch((error) => {
            console.log(error)
          })
      }

      const fetchPlayer = async () => {
        await axios
          .get(`/player/${router.currentRoute.params.playerId}`)
          .then((response) => {
            userData.value = response.data
            playerTransferHistories.value = response.data.playerTransferHistories
            uniqueTransferHistories.value = response.data.playerTransferHistories.filter(
              (value, index, self) =>
                self.findIndex(
                  (y) => y.club.id === value.club.id && y.team.id === value.team.id && y.season.id === value.season.id,
                ) == index,
            )
            activeTransfer.value = playerTransferHistories.value.find(
              (i) => i.isActive === true && i.isSwapped === false,
            )
          })
          .catch((error) => {
            console.log(error)
          })
      }

      const fetchUserAttendance = () => {
        showLoading.value = true
        axios
          .get(`/season/${seasonId.value}/training-attendant/player/${router.currentRoute.params.playerId}`)
          .then((response) => {
            ;(attendance.value = response.data.trainingAttendants),
              (playerLearningOutcomes.value = Object.values(
                response.data.playerLearningOutcomes.reduce(function (acc, veri) {
                  var kategori = veri.category.name

                  if (!acc[kategori]) {
                    acc[kategori] = {
                      category: kategori,
                      items: [],
                      categoryDuration: 0,
                    }
                  }

                  acc[kategori].items.push(veri)
                  acc[kategori].categoryDuration += veri.duration

                  return acc
                }, {}),
              ))
            showPlayerAccount.value = true
            showLoading.value = false
          })
          .catch((error) => {
            showLoading.value = false
            console.log(error)
          })
      }

      const getAge = (birthDate) => {
        var birthDate = new Date(birthDate)
        var now = new Date()
        let otherDate = new Date(now.getFullYear(), now.getMonth(), now.getDate())

        var years = otherDate.getFullYear() - birthDate.getFullYear()

        if (
          otherDate.getMonth() < birthDate.getMonth() ||
          (otherDate.getMonth() == birthDate.getMonth() && otherDate.getDate() < birthDate.getDate())
        ) {
          years--
        }

        return years
      }

      const fetchUserTest = () => {
        axios
          .get(`/season/${seasonId.value}/test/player/${router.currentRoute.params.playerId}`)
          .then((response) => {
            tests.value = response.data
          })
          .catch((error) => {
            console.log(error)
          })
      }

      const fetchUserPerformances = () => {
        axios
          .get(
            `/season/${seasonId.value}/performance/ability-item/team/${teamId.value}/player/${router.currentRoute.params.playerId}`,
          )
          .then((response) => {
            performances.value = response.data
          })
          .catch((error) => {
            console.log(error)
          })
      }

      const getchPlayerPerformances = () => {
        axios
          .get(
            `/season/${seasonId.value}/performance/team/${teamId.value}/player/${router.currentRoute.params.playerId}`,
          )
          .then((response) => {
            playerPerformances.value = response.data
          })
          .catch((error) => {
            console.log(error)
          })
      }

      const fetchUserCompetitions = () => {
        axios
          .get(`/season/${seasonId.value}/competition/player/${router.currentRoute.params.playerId}`)
          .then((response) => {
            competitions.value = response.data
          })
          .catch((error) => {
            console.log(error)
          })
      }

      const fetchShareHistory = () => {
        return axios
          .get(`/communication/response-form/${playerId.value}`)
          .then((response) => {
            shareHistoryData.value = response.data
            return response.data
          })
          .catch((error) => {
            console.log(error)
          })
      }

      const openShareHistoryModal = () => {
        shareHistoryModal.value.show()
      }

      const openSwapModal = () => {
        swapModal.value.show()
      }

      const submitSwapData = () => {
        showLoading.value = true
        axios
          .post(`/player/${playerId.value}/swap`, swapFormData.value)
          .then((response) => {
            swapModal.value.hide()
            root.toast(i18n.t("Successfully swapped"))
            fetchPlayer()
          })
          .finally(() => {
            showLoading.value = false
          })
      }

      // TODO: retun router
      const goPlayer = (data) => {
        router.push(data)
        location.reload()
      }

      return {
        goPlayer,
        avatarText,
        showLoading,
        showPlayerAccount,
        userData,
        attendance,
        getAge,
        tests,
        playerLearningOutcomes,
        playerTransferHistories,
        competitions,
        performances,
        playerPerformances,
        abilities,
        playerReviews,
        teamId,
        seasonId,
        positions,
        tabIndex,
        uniqueTransferHistories,
        shareHistoryModal,
        openShareHistoryModal,
        swapModal,
        openSwapModal,
        fetchShareHistory,
        shareHistoryData,
        fields,
        activeClubTeams,
        user,
        swapFormData,
        Turkish,
        French,
        submitSwapData,
        activeTransfer,
      }
    },
  }
</script>

<style lang="scss">
  .tabs > [class="col-md-12 col-12 tab-title-for-player"] {
    padding-left: 0;
  }
</style>

<style scoped lang="scss">
  @media (min-width: 700px) {
    .topbar > .col-md-4 {
      border-left: 1px solid #ddd;

      &:first-child {
        border-left: 0;
      }
    }

    .p-0 > div {
      padding: 0;
    }
  }

  .ovh {
    overflow: hidden;
  }
</style>

<style lang="scss">
  @import "@core/scss/vue/libs/vue-good-table.scss";
</style>
