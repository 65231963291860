export default [
  {
    path: "/notify",
    name: "team-notify",
    component: () => import("@/views/team/team-sub-pages/team-todo/Todo.vue"),
    meta: {
      resource: "ACL",
      action: "read",
      contentRenderer: "sidebar-left",
      contentClass: "todo-application",
    },
  },
  {
    path: "/apps/notify/:filter",
    name: "team-notify-filter",
    component: () => import("@/views/team/team-sub-pages/team-todo/Todo.vue"),
    meta: {
      contentRenderer: "sidebar-left",
      contentClass: "todo-application",
      navActiveLink: "apps-team-todo",
    },
    beforeEnter(to, _, next) {
      if (["important", "completed", "deleted"].includes(to.params.filter)) next()
      else next({ name: "error-404" })
    },
  },
  {
    path: "/notify/tag/:tag",
    name: "team-notify-tag",
    component: () => import("@/views/team/team-sub-pages/team-todo/Todo.vue"),
    meta: {
      contentRenderer: "sidebar-left",
      contentClass: "todo-application",
      navActiveLink: "apps-team-todo",
    },
    beforeEnter(to, _, next) {
      if (["team", "low", "medium", "high", "update"].includes(to.params.tag)) next()
      else next({ name: "error-404" })
    },
  },
]
