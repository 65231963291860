export default [
  {
    path: "/i/t/:hash",
    name: "invite-training",
    component: () => import("@/views/pages/authentication/InviteTraining.vue"),
    meta: {
      layout: "full",
      resource: "Guest",
    },
  },

  {
    path: "/i/p/:hash",
    name: "invite-performance",
    component: () => import("@/views/pages/authentication/InvitePerformance.vue"),
    meta: {
      layout: "full",
      resource: "Guest",
    },
  },

  {
    path: "/i/m/p/:hash",
    name: "match-performance",
    component: () => import("@/views/pages/authentication/MatchPerformance.vue"),
    meta: {
      layout: "full",
      resource: "Guest",
    },
  },
]
