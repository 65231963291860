<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-lg-flex">
      <navbar-menu-items />
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <closest-season v-if="routerName == 'dashboard-team-players'" />
      <season v-else />
      <locale />
      <notification-dropdown v-if="$store.getters['app/currentBreakPoint'] !== 'xs'" />
      <user-dropdown />
    </b-navbar-nav>
  </div>
</template>

<script>
  import { BLink, BNavbarNav } from "bootstrap-vue"

  import Locale from "./components/Locale.vue"
  import Season from "./components/Season.vue"
  import ClosestSeason from "./components/ClosestSeason.vue"
  import NotificationDropdown from "./components/NotificationDropdown.vue"
  import UserDropdown from "./components/UserDropdown.vue"
  import NavbarMenuItems from "./components/NavbarMenuItems.vue"

  export default {
    components: {
      BLink,

      // Navbar Components
      BNavbarNav,
      Locale,
      Season,
      ClosestSeason,
      NotificationDropdown,
      UserDropdown,
      NavbarMenuItems,
    },
    props: {
      toggleVerticalMenuActive: {
        type: Function,
        default: () => {},
      },
    },
    data() {
      return {
        routerName: null,
      }
    },
    beforeMount() {
      this.routerName = this.$router.currentRoute.name
    },
    watch: {
      $route: function (to, from) {
        this.routerName = to?.name
      },
    },
  }
</script>

<style></style>
