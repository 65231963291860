<template>
  <div>
    <b-overlay :show="userCreateLoading" rounded="sm">
      <b-card v-show="userInitform.userName">
        <validation-observer #default="{}" ref="userAddForm">
          <form @submit.prevent="addSubmit()">
            <div class="card-header p-0 m-0 mb-2">
              <div>
                <b-card-title>
                  <feather-icon icon="UserPlusIcon" size="24" />
                  {{ $t("Edit User") }}
                </b-card-title>
              </div>
            </div>
            <b-row>
              <b-col md="6" xl="4" class="mb-1">
                <label>{{ $t("Username") }}</label>
                <b-form-group>
                  <validation-provider #default="{ errors }" name="userName" rules="required">
                    <b-input v-model="userInitform.userName" :placeholder="$t('Username')"></b-input>
                    <div>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </div>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6" xl="4" class="mb-1">
                <label>{{ $t("First Name") }}</label>
                <b-form-group>
                  <validation-provider #default="{ errors }" name="firstName" rules="required">
                    <b-input v-model="userInitform.firstName" :placeholder="$t('First Name')"></b-input>
                    <div>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </div>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6" xl="4" class="mb-1">
                <label>{{ $t("Last Name") }}</label>
                <b-form-group>
                  <validation-provider #default="{ errors }" name="lastName" rules="required">
                    <b-input v-model="userInitform.lastName" :placeholder="$t('Last Name')"></b-input>
                    <div>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </div>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="6" xl="4" class="mb-1">
                <label>{{ $t("Email") }}</label>
                <b-form-group>
                  <validation-provider #default="{ errors }" name="email" rules="required|email">
                    <input
                      v-model="userInitform.email"
                      readonly
                      onfocusout="this.setAttribute('readonly','true');"
                      onfocus="this.removeAttribute('readonly');"
                      autocomplete="off"
                      placeholder="address@email.com"
                      :state="errors[0] ? false : null"
                      class="ready-only-email form-control"
                    />
                    <div>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </div>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6" xl="4" class="mb-1">
                <validation-provider #default="{ errors }" rules="min:4" vid="newPassword" name="password">
                  <b-form-group label-for="account-new-password" :label="$t('Password')">
                    <b-input-group class="input-group-merge" :class="errors[0] ? 'is-invalid' : null">
                      <b-form-input
                        id="account-new-password"
                        v-model="userInitform.password"
                        :type="passwordFieldType"
                        name="new-password"
                        :placeholder="$t('Password')"
                        :state="errors[0] ? false : null"
                      />
                      <b-input-group-append is-text>
                        <feather-icon :icon="passwordToggleIconNew" class="cursor-pointer" @click="togglePasswordNew" />
                      </b-input-group-append>
                    </b-input-group>

                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="6" xl="4" class="mb-1">
                <validation-provider #default="{ errors }" name="role" rules="required">
                  <b-form-group>
                    <label for="">{{ $t("role") }}</label>
                    <v-select
                      :searchable="true"
                      :placeholder="$t('role')"
                      v-model="userInitform.role"
                      :options="userInitFormRoleList"
                      :getOptionLabel="(option) => (option.name ? $t('ROLES.' + option.name) : $t('ROLES.' + option))"
                      :reduce="(option) => (option.id ? option.id : null)"
                    >
                    </v-select>

                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="6" xl="4" class="mb-1">
                <label>{{ $t("Birth Date") }}</label>
                <b-form-group>
                  <validation-provider #default="{ errors }" name="birthdate" rules="required">
                    <div>
                      <b-form-datepicker
                        :locale="lang"
                        v-model="userInitform.birthDate"
                        id="bDate"
                        placeholder="AA/GG/YYYY"
                      ></b-form-datepicker>
                      <!-- <selectable-date v-model="userInitform.birthDate"></selectable-date> -->
                    </div>
                    <div>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </div>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6" xl="4" class="mb-1">
                <label>{{ $t("Mobile") }}</label>
                <b-form-group>
                  <validation-provider #default="{ errors }" name="mobile">
                    <b-input v-model="userInitform.phone" id="pPhone" placeholder="555 444 33 22"></b-input>
                    <div>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </div>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6" xl="4" class="mb-1">
                <label>{{ $t("Active") }}</label>
                <b-form-group>
                  <validation-provider #default="{ errors }" name="active" rules="required">
                    <b-form-checkbox v-model="userInitform.enabled" switch></b-form-checkbox>
                    <div>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </div>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="6" xl="4" class="mt-n2">
                <label></label>
                <b-form-group>
                  <b-button type="submit" variant="primary" class="w-100" submit>{{ $t("Save") }}</b-button>
                </b-form-group>
              </b-col>
            </b-row>
          </form>
        </validation-observer>
      </b-card>

      <b-card :title="$t('Authorize')">
        <validation-observer #default="{}" ref="userRole">
          <b-row v-for="(userRoles, index) in setUserGroup" :key="userRoles.id">
            <b-col md="4">
              <b-form-group>
                <label for="">{{ $t("Team") }}</label>
                <validation-provider #default="{ errors }" name="team" rules="required">
                  <v-select
                    :searchable="true"
                    :placeholder="$t('Team')"
                    v-model="userRoles.team"
                    :options="userInitFormTeamList"
                    :clearable="false"
                    :getOptionLabel="
                      (option) => {
                        return (option.club ? option.club.name + ' - ' : '') + option.name
                      }
                    "
                  >
                    <template v-slot:option="option">
                      <span v-if="option.club">
                        <img v-if="option.club" :src="option.club.logo" height="18" />
                        {{ option.club.name }}
                      </span>
                      {{ option.name }}
                    </template>
                  </v-select>
                  <div>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </div>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group>
                <label for="">{{ $t("Season") }}</label>
                <validation-provider #default="{ errors }" name="season" rules="required">
                  <v-select
                    :searchable="true"
                    :placeholder="$t('Season')"
                    v-model="userRoles.season"
                    :options="userInitFormSeasonList"
                    :clearable="false"
                    :getOptionLabel="(option) => option.name"
                  >
                    <template v-slot:option="option">
                      {{ option.name }}
                    </template>
                  </v-select>
                  <div>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </div>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group>
                <label for="">{{ $t("role") }}</label>
                <validation-provider #default="{ errors }" name="role" rules="required">
                  <v-select
                    :searchable="true"
                    :placeholder="$t('role')"
                    v-model="userRoles.role"
                    :options="userInitFormRoleList"
                    :clearable="false"
                    :getOptionLabel="(option) => $t('ROLES.' + option.name)"
                  >
                  </v-select>
                  <div>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </div>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group>
                <label for=""></label>
                <div class="d-flex">
                  <b-button variant="primary" class="w-100" @click="submitUserRole(userRoles, index)">
                    <feather-icon icon="SaveIcon"></feather-icon>
                  </b-button>
                  <b-button variant="warning" class="ml-1 w-100" @click="removeUserRole(userRoles.id)">
                    <feather-icon icon="TrashIcon"></feather-icon>
                  </b-button>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
        <b-button variant="primary" @click="setUserGroup.push([])">{{ $t("Add New") }}</b-button>
      </b-card>

      <div v-if="setUserGroup">
        <div v-for="(userPerms, index) in setUserGroup" :key="userPerms.id">
          <div v-if="userPerms.id">
            <b-card-actions
              :actionCollapse="true"
              :collapsed="index != 0"
              ref="cardAction"
              :title="`${$t('Permissions')} ${userPerms.team.club ? userPerms.team.club.name : ''} ${
                userPerms.team.name
              } ${userPerms.season.year}`"
            >
              <b-form-group> </b-form-group>
              <b-table
                responsive
                :items="userPerms.permissionsData"
                hover
                :fields="[
                  { key: 'module', label: $t('Modül') },
                  { key: 'read', label: $t('Read') },
                  { key: 'write', label: $t('Write') },
                  { key: 'manage', label: $t('Manage') },
                ]"
                class="border"
              >
                <template #head(module)="data">
                  <div v-b-tooltip.hover :title="$t('Module Name')">{{ $t("Module") }}</div>
                </template>

                <template #head(read)="data">
                  <div v-b-tooltip.hover :title="$t('Read Access')">{{ $t("Read") }}</div>
                </template>

                <template #head(write)="data">
                  <div v-b-tooltip.hover :title="$t('Write Access')">{{ $t("Write") }}</div>
                </template>

                <template #head(manage)="data">
                  <div v-b-tooltip.hover :title="$t('Manage Access')">
                    <span class="d-flex" :key="getAllIndexIsTrue(index)">
                      <div class="custom-control custom-checkbox"><input type="checkbox" @change="selectAll(index)" class="custom-control-input" value="true" :checked="getAllIndexIsTrue(index)" id="__BVID__1806_ALLBOX"><label class="custom-control-label" for="__BVID__1806_ALLBOX"></label></div>
                      <span> {{ $t("Manage") }}</span>
                    </span>
                  </div>
                </template>

                <template #cell(module)="data">
                  {{ $t("permission." + data.value) }}<br /><small>
                    {{ $t("permission.desc." + data.value) }}
                  </small></template
                >
                <template #cell()="data">
                  <b-form-checkbox
                    v-model="data.item[data.field.key]"
                    @change="permissionControl(userPerms.id, data, data.field.key)"
                  />
                </template>
              </b-table>
            </b-card-actions>
          </div>
        </div>
      </div>
    </b-overlay>
  </div>
</template>
<script>
  import vSelect from "vue-select"
  import { ValidationProvider, ValidationObserver, validate } from "vee-validate"
  import { required, email } from "@validations"
  import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
  import Cleave from "cleave.js"
  import BCardActions from "@core/components/b-card-actions/BCardActions.vue"
  import SelectableDate from "@/@core/components/global-components/SelectableDate"
  import i18n from "@/libs/i18n"

  export default {
    name: "UserAddComponent",
    components: {
      BCardActions,
      ValidationProvider,
      ValidationObserver,
      vSelect,
      ToastificationContent,
      SelectableDate,
    },
    computed: {
      passwordToggleIconNew() {
        return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon"
      },
    },
    data() {
      return {
        selectAllState:false,
        passwordFieldType: "password",
        userCreateLoading: true,
        userInitform: {
          name: null,
          phone: null,
          email: null,
          birthDate: null,
        },
        userInitFormRoleList: [{}],
        userInitFormPermsList: [{}],
        userInitFormSeasonList: [{}],
        userInitFormTeamList: [{}],
        setUserGroup: [],
        permissionsData: [
          {
            module: "Sistem Yönetimi",
            read: false,
            write: false,
            manage: false,
          },
        ],
        lang: i18n.locale,
      }
    },

    methods: {
      getAllIndexIsTrue(index) {
        let data = this.setUserGroup[index].permissionsData
        let isTrue = true
        data.forEach((item) => {
          if (item.manage == false) isTrue = false
        })
        return isTrue
      },
      selectAll(index) {
        window.Notiflix.Loading.pulse()

        let deselect = true
        const check = this.getAllIndexIsTrue(index)
        if(check) deselect = false

        this.selectAllState = true
        const els = document
          .querySelectorAll("table:nth-child(1)")
          [index].querySelectorAll(" tr td:nth-child(4) input")

          els.forEach((item,index) => {
              item.checked = deselect
              item.dispatchEvent(new Event("change"))

              if(index+1 == els.length){
                setTimeout(()=>{
        window.Notiflix.Loading.remove()
          this.toast(this.$t("Saved successfully"), "EditIcon", "success")
        this.selectAllState = false
                },els.length*200)
              }
          })
      },
      togglePasswordNew() {
        this.passwordFieldType = this.passwordFieldType === "password" ? "text" : "password"
      },
      addSubmit() {
        this.$refs.userAddForm.validate().then((success) => {
          if (!success) {
            return false
          }
          this.userCreateLoading = true
          this.userSendForm = {
            userName: this.userInitform.userName,
            firstName: this.userInitform.firstName,
            lastName: this.userInitform.lastName,
            email: this.userInitform.email,
            phone: this.userInitform.phone,
            enabled: this.userInitform.enabled,
            birthDate: this.userInitform.birthDate,
            password: this.userInitform.password,
            role: this.userInitform.role,
            platform: "client_web",
          }
          if (this.userSendForm.password == "") delete this.userSendForm.password
          if (Number.isNaN(Number(this.userInitform.role))) delete this.userSendForm.role
          this.$http
            .put(`/user/${this.routerParams.id}`, this.userSendForm)
            .then((succ) => {
              this.loading = false
              let newUserId = succ.data.id
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: this.$t("Successfully Updated"),
                  icon: "UserIcon",
                  variant: "success",
                },
              })
              this.userCreateLoading = false

              // this.$router.push({
              //   name: "user-manage",
              //   params: {},
              // });
            })
            .catch((err) => {
              this.userCreateLoading = false
            })
        })
      },
      async permissionControl(userRoleId, getData, action) {
        let RouteId = Number(this.routerParams.id)
        if (action == "manage") {
          getData.item.write = false
          getData.item.read = false
        }
        if (action != "manage") {
          getData.item.manage = false
        }
        if (getData.item.id)
          return this.editPermissionControl(getData, userRoleId, getData.item.id, getData.item.permsId, action, RouteId)
        let payload = {
          user: RouteId,
          permission: getData.item.permsId,
          userRole: userRoleId,
          action: action,
        }
        let { data } = await this.$http.post(`/user_manage/role/permission`, payload)

        if(!this.selectAllState){
          this.toast(this.$t("Saved successfully"), "EditIcon", "success")
        }

        if (data.id) getData.item.id = data.id
      },
      async editPermissionControl(getData, userRoleId, itemId, permsId, action, RouteId) {
        setTimeout(async () => {
          let payload = {
            user: RouteId,
            permission: permsId,
            userRole: userRoleId,
            action: action,
            status: getData.item[action],
          }
          if (getData.item[action] == false) getData.item.id = undefined
          let { data } = await this.$http.put(`/user_manage/role/permission/${itemId}`, payload)
        if(!this.selectAllState){
          this.toast(this.$t("Saved successfully"), "EditIcon", "success")
        }
        }, 1000)
      },

      submitUserRole(userRole, index) {
        this.$refs.userRole.validate().then((success) => {
          if (success) {
            let RouteId = Number(this.routerParams.id)
            if (userRole.id) return this.editUserRole(userRole)
            let payload = {
              user: RouteId,
              season: userRole.season.id,
              team: userRole.team.id,
              role: userRole.role.id,
              isActive: true,
            }
            this.$http.post(`/user_manage/role/create`, payload).then((res) => {
              this.toast(this.$t("Successfully Created"), "EditIcon", "success")
              // TODO: SET ID TO SETUSERGROUP OBJECT
              location.reload()
            })
          }
        })
      },

      removeUserRole(userRoleId) {
        this.$bvModal
          .msgBoxConfirm(this.$t("Please confirm that you want to delete this."), {
            title: this.$t("Please Confirm"),
            size: "sm",
            okVariant: "primary",
            okTitle: this.$t("Yes"),
            cancelTitle: this.$t("No"),
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          })
          .then((value) => {
            if (value) {
              this.$http.delete(`/user_manage/role/delete/${userRoleId}`).then((res) => {
                this.toast(this.$t("Successfully Deleted"), "PlusIcon", "success")
                // TODO: REMOVE ID TO SETUSERGROUP OBJECT
                location.reload()
              })
            }
          })
      },
      async editUserRole(userRole) {
        let RouteId = Number(this.routerParams.id)
        try {
          let payload = {
            user: RouteId,
            season: userRole.season.id,
            team: userRole.team.id,
            role: userRole.role.id,
            isActive: true,
          }
          let { data } = await this.$http.put(`/user_manage/role/update/${userRole.id}`, payload)
          let item = this.setUserGroup.findIndex((it) => it.id == data.id)
          this.setUserGroup[item] = data
          this.toast(this.$t("Successfully Updated"), "EditIcon", "success")
        } catch (error) {
          this.toast(this.$t("Error"), "EditIcon", "success")
        }
      },
      permissionsKey(defaultPermissionsFromDB, userRoleIndex) {
        this.$http.get(`/user_manage/role/perms/list`).then((data) => {
          let items = data.data
          this.permissionsData = []
          this.setUserGroup.forEach((ind) => {
            if (ind.permissionsData.length == 0) {
              Object.values(items).map((itemData) => {
                ind.permissionsData.push({
                  permsId: itemData.id,
                  module: itemData.name,
                  read: false,
                  write: false,
                  manage: false,
                })
              })
            }
          })

          defaultPermissionsFromDB.forEach((dbData) => {
            /**
             * find perms
             * for set Id
             */
            let findUserRolePerms = this.setUserGroup[userRoleIndex].permissionsData.filter(
              (d) => d.permsId == dbData.permission.id,
            )
            if (findUserRolePerms) {
              findUserRolePerms[0].id = dbData.id
              if (dbData.action == "write") findUserRolePerms[0].write = true
              if (dbData.action == "read") findUserRolePerms[0].read = true
              if (dbData.action == "manage") {
                findUserRolePerms[0].manage = true
                findUserRolePerms[0].write = false
                findUserRolePerms[0].read = false
              }
            }
          })
        })
      },
    },
    props: {},
    beforeMount() {
      let RouteId = this.routerParams.id
      this.$http.get(`/user/${RouteId}`).then((data) => {
        this.userInitform = data.data
        Object.values(data.data.userRole).map((item, userRoleIndex) => {
          this.setUserGroup.push({
            team: item.team,
            season: item.season,
            role: item.role,
            id: item.id,
            permissionsData: [],
          })
          this.permissionsKey(item.userPermissions, userRoleIndex)
        })
      })
      this.$http.get(`/user_manage/role/list`).then((res) => {
        this.userInitFormRoleList = res.data
      })
      this.$http.get(`/season`).then((res) => {
        this.userInitFormSeasonList = res.data
      })
      this.$http.get(`/team`).then((res) => {
        this.userInitFormTeamList = res.data.data
      })
    },
    mounted() {
      new Cleave("#pPhone", {
        numericOnly: true,
        delimiter: " ",
        blocks: [3, 3, 2, 2],
      })
      // new Cleave("#bDate", {
      //   date: true,
      //   datePattern: ["m", "d", "Y"],
      //   delimiter: "/",
      // });
    },
    created() {
      setTimeout(() => {
        this.userCreateLoading = false
      }, 500)
    },
  }
</script>
<style>
  .ready-only-email {
    background-color: #fff !important;
  }
</style>
