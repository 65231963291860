<template>
  <div v-if="competitions">
    <vue-good-table :columns="columns" class="player-view-matches" :rows="Object.values(competitions)">
      <template slot="table-row" slot-scope="props">
        <!-- Column: Status -->

        <span v-if="props.column.field === 'date'">
          {{ props.row.competitionDate | nativeDateTime }}
        </span>

        <span v-else-if="props.column.field === 'competition'">
          <div class="d-flex justify-content-start cursor-pointer" @click="goToMatch(props.row)">
            <!-- home team -->
            <div class="d-flex justify-content-between">
              <span class="teamText mr-1">{{ props.row.homeTeamName }}</span>
              <img :src="props.row.homeTeamLogo" height="24" alt="" />
            </div>
            <!-- match score -->
            <div>
              <b-badge variant="success" class="mx-1" v-if="props.row.homeScore != undefined">
                {{ props.row.homeScore + `-` + props.row.awayScore }}
              </b-badge>
              <!-- clock icon -->
              <span class="icon-time mx-1" v-if="props.row.awayScore == undefined">
                <feather-icon icon="ClockIcon" size="1.5x" scale="1" />
              </span>
            </div>

            <!-- away team -->
            <div class="d-flex justify-content-between">
              <img :src="props.row.awayTeamLogo" height="24" alt="" />
              <span class="teamText ml-1"> {{ props.row.awayTeamName }} </span>
            </div>
          </div>
        </span>

        <span v-if="props.column.field === 'position'">
          {{ props.row.position }}
        </span>

        <span v-if="props.column.field === 'coachReview'">
          {{ props.row.coachReview }}
        </span>
        <span v-if="props.column.field === 'coachComment'">
          {{ props.row.coachComment }}
        </span>

        <!-- <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span> -->
      </template>
    </vue-good-table>
  </div>
</template>

<script>
  import { VueGoodTable } from "vue-good-table"

  export default {
    components: {
      VueGoodTable,
    },
    props: {
      competitions: {
        type: [Object, Array],
      },
    },
    data() {
      return {
        pageLength: 3,
        dir: false,
        columns: [
          {
            label: this.$i18n.t("Date"),
            field: "date",
            filterOptions: {
              enabled: false,
              placeholder: this.$i18n.t("Search"),
            },
          },
          {
            label: this.$i18n.t("Match"),
            field: "competition",
            filterOptions: {
              enabled: false,
              //placeholder: this.$i18n.t('Search'),
            },
          },
          {
            label: this.$i18n.t("position"),
            field: "position",
            filterOptions: {
              enabled: false,
            },
          },
          {
            label: this.$i18n.t("Coach Review"),
            field: "coachReview",
            filterOptions: {
              enabled: false,
            },
          },
          {
            label: this.$i18n.t("Coach Comment"),
            field: "coachComment",
            filterOptions: {
              enabled: false,
            },
          },
        ],
        rows: [],
        searchTerm: "",
      }
    },
    methods: {
      getDate(date) {
        let dateFormat = new Date(date)
        let dateFormat1 =
          dateFormat.toLocaleDateString(this.$i18n.locale, {
            weekday: "short",
            year: "numeric",
            month: "short",
            day: "numeric",
          }) +
          " " +
          dateFormat.toLocaleTimeString(this.$i18n.locale, {
            hour: "numeric",
            minute: "numeric",
          }) //toLocaleTime("tr-TR").substring(-3, 5);
        return dateFormat1
      },
      goToMatch(comp) {
        this.$router.push({
          name: "scout-competition-edit",
          params: {
            competitionId: comp.competitionId,
          },
        })
      },
    },
  }
</script>

<style lang="scss">
  .welcome-dashboard-history.row {
    tr {
      cursor: pointer;
      transition: all 300ms;
      outline: 0;

      &:hover {
        background: #eee;
      }
    }

    .teamText {
      width: 10rem;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .stad,
    .teamText {
      white-space: nowrap;
      width: 6rem;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .teamText {
      width: 8rem;
    }
  }
  .player-view-matches {
    .vgt-left-align {
      padding-bottom: 0.75rem !important;
    }
  }
</style>
