<template>
  <div>
    <team-list-add-new
      :is-add-new-user-sidebar-active.sync="isAddNewTeamSidebarActive"
      :role-options="roleOptions"
      :plan-options="planOptions"
      @refetch-data="refetchData"
    />

    <!-- Filters -->
    <team-list-filters
      :role-filter.sync="roleFilter"
      :plan-filter.sync="planFilter"
      :status-filter.sync="statusFilter"
      :role-options="roleOptions"
      :plan-options="planOptions"
      :status-options="statusOptions"
    />

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <label>{{ $t("Show") }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>{{ $t("entries") }}</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="Search..." />
              <b-button variant="primary" @click="isAddNewTeamSidebarActive = true">
                <span class="text-nowrap">{{ $t("Add Team") }}</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refTeamListTable"
        class="position-relative"
        :items="tableItems"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: Id -->
        <template #cell(id)="data">
          <b-link :to="{ name: 'show-team', params: { id: data.item.id } }" class="font-weight-bold">
            #{{ data.value }}
          </b-link>
        </template>

        <!-- Column: Avatar -->
        <template #cell(avatar)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar size="32" :src="data.item.avatar" />
            </template>
          </b-media>
        </template>

        <!-- Column: Staff -->
        <template #cell(staff)="data">
          <b-media vertical-align="center">
            <span class="font-weight-bold d-block text-nowrap">
              {{ $t("Antrenor") }}: <small class="text-muted">{{ data.item.staff.antrenor }}</small>
            </span>
            <span class="font-weight-bold d-block text-nowrap">
              {{ $t("Assistant Coach") }}: <small class="text-muted">{{ data.item.staff.yardimciAntrenor }}</small>
            </span>
            <span class="font-weight-bold d-block text-nowrap">
              Kondisyoner: <small class="text-muted">{{ data.item.staff.kondisyoner }}</small>
            </span>
            <span class="font-weight-bold d-block text-nowrap">
              {{ $t("Goalkeeping Coach") }}: <small class="text-muted">{{ data.item.staff.kaleciAntrenoru }}</small>
            </span>
          </b-media>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <!-- Dropdown -->
            <b-dropdown variant="link" toggle-class="p-0" no-caret :right="$store.state.appConfig.isRTL">
              <template #button-content>
                <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
              </template>
              <b-dropdown-item :to="{ name: 'apps-invoice-edit', params: { id: data.item.id } }">
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">{{ $t("Edit") }}</span>
              </b-dropdown-item>
              <b-dropdown-item @click="deleteItem(data.item.id)">
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">{{ $t("Delete") }}</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <span class="text-muted">{{
              $t("format.showingData", { from: dataMeta.from, to: dataMeta.to, of: dataMeta.of })
            }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalTeams"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
  import {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
  } from "bootstrap-vue"
  import vSelect from "vue-select"
  import store from "@/store"
  import { ref, onUnmounted } from "@vue/composition-api"
  import { avatarText } from "@core/utils/filter"
  import TeamListFilters from "./TeamListFilters.vue"
  import TeamListAddNew from "./TeamListAddNew.vue"
  import { computed } from "@vue/composition-api"

  import useTeamList from "../../useTeamList"
  import teamStoreModule from "../../teamStoreModule"

  export default {
    components: {
      TeamListFilters,
      TeamListAddNew,

      BCard,
      BRow,
      BCol,
      BFormInput,
      BButton,
      BTable,
      BMedia,
      BAvatar,
      BLink,
      BBadge,
      BDropdown,
      BDropdownItem,
      BPagination,

      vSelect,
    },
    methods: {},
    setup(props, { emit, root }) {
      const STORE_MODULE_NAME = "app-team"
      if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, teamStoreModule)
      onUnmounted(() => {
        if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
      })

      const isAddNewTeamSidebarActive = ref(false)

      const roleOptions = computed({
        get: () => [
          { label: i18n.t("Admin"), value: "admin" },
          { label: i18n.t("Author"), value: "author" },
          { label: i18n.$t("Editor"), value: "editor" },
          { label: i18n.t("Maintainer"), value: "maintainer" },
          { label: i18n.t("Subscriber"), value: "subscriber" },
        ],
      })

      const planOptions = computed({
        get: () => [
          { label: i18n.t("Soccer"), value: "soccer" },
          { label: i18n.t("Basketball"), value: "basketball" },
          { label: i18n.t("Volleyball"), value: "volleyball" },
        ],
      })

      const statusOptions = computed({
        get: () => [
          { label: i18n.t("Pending"), value: "pending" },
          { label: i18n.t("Active"), value: "active" },
          { label: i18n.t("Inactive"), value: "inactive" },
        ],
      })

      const teamPersonalDataKeys = {
        boy: "cm",
        kilo: "kg",
        yaş: "",
      }

      const {
        tableColumns,
        perPage,
        currentPage,
        totalTeams,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refTeamListTable,
        refetchData,

        // UI
        resolveTeamRoleVariant,
        resolveTeamRoleIcon,
        resolveTeamStatusVariant,

        // Extra Filters
        roleFilter,
        planFilter,
        statusFilter,
        tableItems,
        deleteItem,
      } = useTeamList()

      return {
        // Sidebar
        isAddNewTeamSidebarActive,

        tableColumns,
        perPage,
        currentPage,
        totalTeams,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refTeamListTable,
        refetchData,

        // Filter
        avatarText,

        // UI
        resolveTeamRoleVariant,
        resolveTeamRoleIcon,
        resolveTeamStatusVariant,

        roleOptions,
        planOptions,
        statusOptions,

        // Extra Filters
        roleFilter,
        planFilter,
        statusFilter,

        teamPersonalDataKeys,
        deleteItem,
        tableItems,
      }
    },
  }
</script>

<style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }
</style>

<style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
</style>
