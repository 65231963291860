<template>
  <b-nav-item-dropdown @hidden="read()" class="dropdown-notification mr-25" menu-class="dropdown-menu-media" right>
    <template #button-content>
      <feather-icon :badge="systemNotLength" badge-classes="bg-danger" class="text-body" icon="BellIcon" size="21" />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          {{ $t("Notifications") }}
        </h4>
        <b-badge pill variant="light-primary">{{ systemNotLength }} {{ $t("New") }}</b-badge>
      </div>
    </li>

    <!-- Notifications -->
    <vue-perfect-scrollbar
      v-if="systemNotLength"
      :settings="perfectScrollbarSettings"
      class="scroll-area"
      v-once
      tagname="div"
    >
      <!-- TODO: go to link ... -->
      <b-link v-for="(notification, index) in systemNotifications" :key="index">
        <b-media>
          <template #aside>
            <b-avatar size="32" :variant="'primary'">
              <feather-icon icon="ChevronRightIcon" size="18" />
            </b-avatar>
          </template>
          <p class="media-heading">
            <span class="font-weight-bolder">{{ notification.title }}</span>
          </p>
          <small class="notification-text fw-bold">{{ notification.content }}</small>
        </b-media>
      </b-link>
    </vue-perfect-scrollbar>
    <div v-if="!systemNotLength" class="p-2">{{ $t("No pending notification.") }}</div>

    <!-- Cart Footer -->
    <li class="dropdown-menu-footer">
      <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" block :to="{ name: 'team-notify' }">{{
        $t("Read all notifications")
      }}</b-button>
    </li>
  </b-nav-item-dropdown>
</template>

<script>
  import store from "@/store"
  import { ref, watch, computed, onUnmounted } from "@vue/composition-api"
  import VuePerfectScrollbar from "vue-perfect-scrollbar"
  import Ripple from "vue-ripple-directive"
  import todoStoreModule from "@/views/team/team-sub-pages/team-todo/todoStoreModule"

  export default {
    components: {
      VuePerfectScrollbar,
    },
    directives: {
      Ripple,
    },
    setup() {
      const TODO_APP_STORE_MODULE_NAME = "app-todo"

      // Register module
      if (!store.hasModule(TODO_APP_STORE_MODULE_NAME))
        store.registerModule(TODO_APP_STORE_MODULE_NAME, todoStoreModule)

      // UnRegister on leave
      onUnmounted(() => {
        if (store.hasModule(TODO_APP_STORE_MODULE_NAME)) store.unregisterModule(TODO_APP_STORE_MODULE_NAME)
      })

      const systemNotifications = ref(null)
      const systemNotLength = ref(0)
      const systemNotIds = ref([])
      const userdata = JSON.parse(localStorage.getItem("userData")) || null

      const fetchTasks = () => {
        if (userdata) {
          store.dispatch("app-todo/fetchUserTasks").then((response) => {
            if (response.data && response.data.length > 0) {
              systemNotifications.value = response.data
              systemNotLength.value = response.data.length
              systemNotIds.value = response.data.map((i) => i.id)
            }
          })
        }
      }

      fetchTasks()

      const read = () => {
        if (userdata) {
          store.dispatch("app-todo/readNotification", { notificationIds: systemNotIds.value }).then((response) => {
            systemNotLength.value = 0
          })
        }
      }

      const perfectScrollbarSettings = {
        maxScrollbarLength: 60,
        wheelPropagation: false,
      }

      return {
        systemNotifications,
        systemNotLength,
        perfectScrollbarSettings,
        read,
      }
    },
  }
</script>

<style>
  .fw-bold {
    color: #666 !important;
  }
  .scroll-area .media {
    padding: 1rem;
  }
</style>
