<template>
  <div>
    <b-card>
      <div class="mx-n2">
        <b-table-simple hover responsive ref="refTeamCompetitionTable">
          <b-thead>
            <b-tr>
              <b-th>{{ $t("Team") }}</b-th>
              <b-th>{{ $t("Attribute") }}</b-th>
              <b-th>
                <span v-b-tooltip.hover="$t('Match played')">
                  {{ $t("OMS") }}
                </span>
              </b-th>
              <b-th>
                <span v-b-tooltip.hover="`${$t('Win')} / ${$t('Draw')} / ${$t('Loss')}`">
                  {{ $t("W/D/L") }}
                </span>
              </b-th>
              <b-th>
                <span v-b-tooltip.hover="$t('Goal scored') + ' / ' + $t('Conceded goal')">
                  {{ $t("GS") + " / " + $t("CG") }}
                </span>
              </b-th>
              <b-th>
                <span v-b-tooltip.hover="$t('Yellow Card')">
                  {{ $t("YC") }} <img :src="require('@/assets/images/icons/yellow-card.png')" height="24" alt />
                </span>
              </b-th>
              <b-th>
                <span v-b-tooltip.hover="$t('Red Card from 2nd Yellow')">
                  2.{{ $t("YC") }} <img :src="require('@/assets/images/icons/yellow-red-card.png')" height="24" alt />
                </span>
              </b-th>
              <b-th>
                <span v-b-tooltip.hover="$t('Red Card')">
                  {{ $t("RC") }} <img :src="require('@/assets/images/icons/red-card.png')" height="24" alt />
                </span>
              </b-th>
              <b-th>
                <span v-b-tooltip.hover="$t('Points Total')"> TP </span>
              </b-th>
              <b-th>
                <span v-b-tooltip.hover="$t('Goals per competition')">
                  {{ $t("GA") }}
                </span>
              </b-th>
              <b-th>
                <span v-b-tooltip.hover="$t('Goals conceded per competition')">
                  {{ $t("GCA") }}
                </span>
              </b-th>
              <b-th>
                <span v-b-tooltip.hover="$t('Points per game')">
                  {{ $t("PPG") }}
                </span>
              </b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <template v-if="loading">
              <b-tr class="b-table-busy-slot">
                <b-td colspan="12">
                  <div class="text-center my-2">
                    <b-spinner class="align-middle"></b-spinner>
                  </div>
                </b-td>
              </b-tr>
            </template>
            <template v-else v-for="(data, index) in competititons">
              <b-tr
                :class="{ border: index2 == 2 && index != competititons.length - 1 }"
                v-for="(data2, index2) in data"
                :key="`${index}-${index2}`"
              >
                <b-td v-if="index2 == 0" rowspan="3">{{ data[0].team.name }}</b-td>
                <b-td class="text-capitalize">{{
                  data2.side == "home" ? $t("home area") : data2.side == "away" ? $t("away area") : $t(data2.side)
                }}</b-td>
                <b-td>{{ data2.competition.TOTAL }}</b-td>
                <b-td>{{ data2.competition.WIN + "/" + data2.competition.DRAW + "/" + data2.competition.LOSE }}</b-td>
                <b-td>{{ data2.event.GOAL_AND_INCOMES.GOAL + "/" + data2.event.GOAL_AND_INCOMES.INCOME }}</b-td>
                <b-td>{{ data2.event.YELLOW_CARD }}</b-td>
                <b-td>{{ data2.event.YELLOW_RED_CARD }}</b-td>
                <b-td>{{ data2.event.RED_CARD }}</b-td>
                <b-td>{{ data2.points.TOTAL }}</b-td>
                <b-td
                  ><b>{{ data2.event.GOAL_AND_INCOMES_AVERAGE.GOAL }}</b></b-td
                >
                <b-td
                  ><b>{{ data2.event.GOAL_AND_INCOMES_AVERAGE.INCOME }}</b></b-td
                >
                <b-td
                  ><b>{{ data2.points.AVERAGE }}</b></b-td
                >
              </b-tr>
            </template>
          </b-tbody>
        </b-table-simple>
      </div>
    </b-card>
  </div>
</template>

<script>
  import vSelect from "vue-select"
  import { ref, watch, computed, onMounted } from "@vue/composition-api"
  import { teamService } from "@/services"

  export default {
    components: {
      vSelect,
    },
    data() {
      return {}
    },
    methods: {},
    mounted() {},
    setup(props, { root }) {
      const competititons = ref([])
      const refTeamCompetitionTable = ref(null)
      const loading = ref(false)
      const teamSer = new teamService()

      const localSeason = ref(localStorage.getItem("season"))
      const localTeamIds = ref(
        JSON.parse(localStorage.getItem("teams"))
          .map((d) => d.id)
          .join(),
      )

      const getCompetititons = async () => {
        loading.value = true
        await teamSer
          .getTeamsCompetitionStatistics(localSeason.value, {
            team: localTeamIds.value,
            officialLeague: true,
          })
          .then((res) => {
            competititons.value = res
          })
          .catch((error) => {
            console.log(error)
          })
          .finally(() => (loading.value = false))
      }

      onMounted(async () => {
        await getCompetititons()
      })

      return {
        refTeamCompetitionTable,
        competititons,
        loading,
        getCompetititons,
      }
    },
  }
</script>

<style scoped>
  .border {
    border-bottom: solid !important;
  }
</style>
