<template>
  <b-overlay :show="loading" rounded="sm">
    <validation-observer ref="addTrainingLibraryForm">
      <b-row>
        <b-col lg="3">
          <b-card>
            <validation-provider #default="{ errors }" name="trainingName" rules="required">
              <b-form-group :label="$t('Drill Name')" label-for="name">
                <b-form-input
                  v-model="trainingLibrary.name"
                  :state="errors.length > 0 ? false : null"
                  id="name"
                  :placeholder="$t('Drill Name')"
                />
              </b-form-group>
              <small class="text-danger mt-1">{{ errors[0] }}</small>
            </validation-provider>

            <validation-provider #default="{ errors }" name="duration">
              <b-form-group :label="$t('Planned Duration')" label-for="name">
                <b-input-group :append="$t('dk')" class="mb-1">
                  <b-form-input
                    class="w-75"
                    v-model.number="trainingLibrary.duration"
                    :state="errors.length > 0 ? false : null"
                    id="duration"
                    type="number"
                  />
                </b-input-group>
              </b-form-group>

              <small class="text-danger mt-1">{{ errors[0] }}</small>
            </validation-provider>

            <validation-provider #default="{ errors }" name="exercises" rules="required">
              <b-form-group :label="$t('Exercises')" v-if="learningOutcomes">
                <!-- <v-select
              ref="librarySelectInput"
              v-model="trainingLibrary.learningOutcomes"
              multiple
              push-tags
              @search="searchLearningOutcome"
              :filter="filterOptions"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="learningOutcomes"
              :reduce="(option) => option.id" -->
                <v-select
                  ref="librarySelectInput"
                  v-model="trainingLibrary.learningOutcomes"
                  multiple
                  push-tags
                  :state="errors.length > 0 ? false : null"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="Object.values(learningOutcomes)"
                  :reduce="(option) => option"
                  :getOptionLabel="(option) => option.name"
                >
                  <!-- <template #option="{ name, learningOutcomeCategory }">
                <div v-if="name">
                  <cite> {{ learningOutcomeCategory.name }} > {{ name }}</cite>
                </div>
              </template> -->

                  <template #selected-option="{ name }">
                    <div v-if="name">
                      <cite> {{ name }}</cite>
                    </div>
                  </template>

                  <template #no-options="{ search, searching }">
                    <span> {{ $t("No Result") }}</span>
                  </template>
                </v-select>
              </b-form-group>

              <small class="text-danger mt-1">{{ errors[0] }}</small>
            </validation-provider>

            <validation-provider #default="{ errors }" name="description" rules="required">
              <b-form-group :label="$t('Description')" label-for="description">
                <div class="d-flex">
                  <b-form-textarea
                    id="description"
                    v-model="trainingLibrary.description"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('Enter Description')"
                    rows="4"
                  />
                </div>
              </b-form-group>

              <small class="text-danger mt-1">{{ errors[0] }}</small>
            </validation-provider>
          </b-card>
        </b-col>
        <b-col lg="9">
          <b-row>
            <b-col md="12">
              <b-card>
                <b-form-group :label="$t('Content')">
                  <ck-editor-component
                    v-for="item in trainingLibrary.trainingItemTemplateMedia"
                    :key="item.id"
                    :state="item.path == null ? null : false"
                    :editorData="item.path"
                    @loading="setLoading($event)"
                    @changedCKEditor="onCKEdtiorChanged"
                  />
                </b-form-group>
              </b-card>
            </b-col>

            <b-card :title="$t('Videos')" class="m-1 w-100">
              {{ $t("Please save item then upload the video") }}
            </b-card>
          </b-row>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <div class="d-flex align-items-center justify-content-end">
            <b-button v-if="!loading" @click="addNewTemplate" variant="primary" class="w-25">{{ $t("Add") }}</b-button>
            <b-button v-else variant="primary" class="w-25">{{ $t("Adding ...") }}</b-button>
          </div>
        </b-col>
      </b-row>
    </validation-observer>
  </b-overlay>
</template>

<script>
  import { quillEditor } from "vue-quill-editor"
  import vSelect from "vue-select"
  // eslint-disable-next-line
  import "quill/dist/quill.core.css"
  // eslint-disable-next-line
  import "quill/dist/quill.snow.css"
  // eslint-disable-next-line
  import "quill/dist/quill.bubble.css"

  import TrainingPlugin from "@/views/team/team-sub-pages/training/TrainingPlugin"

  import CkEditorComponent from "@/views/team/team-sub-pages/training/components/CkEditorComponent.vue"

  import { useToast } from "vue-toastification/composition"
  import i18n from "@/libs/i18n"
  import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"

  import { ValidationProvider, ValidationObserver } from "vee-validate"
  import { required, email, confirmed, password } from "@validations"

  import { toRefs, onUnmounted, onMounted, ref, nextTick } from "@vue/composition-api"
  import axios from "@axios"

  import { useRouter } from "@core/utils/utils"

  export default {
    components: {
      ValidationProvider,
      ValidationObserver,
      quillEditor,
      vSelect,
      CkEditorComponent,
    },
    directives: {},

    setup(props, { emit, root }) {
      const { getLearningOutcomes, learningOutcomes } = TrainingPlugin(toRefs(props), emit)

      const toast = useToast()
      const { router, route } = useRouter()
      const loading = ref(false)
      const addTrainingLibraryForm = ref(null)

      // const setTextValue = (event) => {
      //   console.log(event);
      // };
      const editorOption = {
        theme: "bubble",
        placeholder: "drill giriniz",
      }
      const librarySelectInput = ref(null)
      //const learningOutcomes = ref([]);
      const searchLearningOutcome = (query, vm, kl) => {
        if (query.length < 2) return false
        axios.get(`/learning-outcome/search/${query}`).then((response) => {
          learningOutcomes.value = response.data
        })
      }

      const onCKEdtiorChanged = (event) => {
        // let image = document.querySelector(".image img")["src"];
        if (event) {
          nextTick(() => {
            Object.assign(trainingLibrary.value.trainingItemTemplateMedia[0], {
              path: event,
              is_cover_image: null,
            })
          })
        }
      }

      const setLoading = (event) => {
        loading.value = event
      }

      const filterOptions = (options, search) => {
        return options.filter((item) => {
          if (item) {
            if (item.name.toLowerCase().includes(search.toLowerCase())) return item
          }
        })
      }

      const addNewTemplate = () => {
        loading.value = true
        removeCategoriesFromSelectedItems()
        addTrainingLibraryForm.value.validate().then((success) => {
          if (success) {
            trainingLibrary.value.duration = Number(trainingLibrary.value.duration)
            axios
              .post(`/training-item-template`, trainingLibrary.value)
              .then((response) => {
                toast({
                  component: ToastificationContent,
                  props: {
                    title: i18n.t("Successfully Created"),
                    icon: "CheckIcon",
                    variant: "success",
                  },
                })
                router.push({
                  name: "team-training-library-edit",
                  params: {
                    templateID: response.data.id,
                  },
                })
              })
              .catch((err) => {
                loading.value = false
              })
          } else {
            loading.value = false
          }
        })
      }

      const removeCategoriesFromSelectedItems = () => {
        let newArr = []
        trainingLibrary.value.learningOutcomes.filter((item) => {
          newArr.push({
            id: item.id,
            name: item.name,
          })
        })
        return (trainingLibrary.value.learningOutcomes = newArr)
      }

      const trainingLibrary = ref({
        name: "",
        duration: 30,
        description: "",
        learningOutcomes: [],
        trainingItemTemplateMedia: [
          {
            path: null,
            is_cover_image: null,
          },
        ],
      })

      onMounted(() => {
        getLearningOutcomes()
      })

      return {
        learningOutcomes,
        trainingLibrary,
        searchLearningOutcome,
        librarySelectInput,
        filterOptions,
        editorOption,
        addNewTemplate,
        loading,
        required,
        addTrainingLibraryForm,
        onCKEdtiorChanged,
        setLoading,
      }
    },
  }
</script>

<style lang="scss">
  .ql-tooltip {
    z-index: 900;
  }
  .quill-editor {
    border: 0.1px solid rgba(190, 190, 190, 0.397);
    border-radius: 5px;
  }
  .top-seperated-drills {
    margin-bottom: 5px;
  }

  .ck-editor-style {
    border: 1px solid rgb(221, 216, 216) !important;
    min-height: 290px;
  }
</style>
