import { ref, watch, computed, onUnmounted } from "@vue/composition-api"
import { useRouter } from "@core/utils/utils"

// Notification
import { useToast } from "vue-toastification/composition"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
import store from "@/store"

export default function useTeamList() {
  const { router, route } = useRouter()

  // Use toast
  const toast = useToast()

  const refTeamListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: "id", label: "#", sortable: true },
    { key: "homeOwnerTeam", sortable: true },
    { key: "deplasmanTeam", sortable: true },
    { key: "branch", sortable: true },
    { key: "lastEventTime", sortable: true },
    { key: "lastEvent", sortable: true },
    { key: "actions" },
  ]

  const perPage = ref(localStorage.getItem("perPage") || 10)
  const totalItems = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref("")
  const sortBy = ref("id")
  const isSortDirDesc = ref(true)
  const statusFilter = ref(null)
  const teamId = ref(router.currentRoute.params.id)

  const dataMeta = computed(() => {
    const localItemsCount = refTeamListTable.value ? refTeamListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalItems.value,
    }
  })

  const refFetchData = () => {
    refTeamListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, statusFilter], () => {
    refFetchData()
  })

  watch(perPage, (perPage) => {
    localStorage.setItem("perPage", perPage)
  })

  const fetchItems = (ctx, callback) => {
    store
      .dispatch("app-match/fetchItems", {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        status: statusFilter.value,
      })
      .then((response) => {
        const { items, total } = response.data
        totalItems.value = total
        callback(items)
      })
      .catch((error) => {
        console.log(error)
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching invoices' list",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        })
      })
  }

  return {
    fetchItems,
    tableColumns,
    perPage,
    currentPage,
    totalItems,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refTeamListTable,
    statusFilter,
    refFetchData,
    teamId,
  }
}
