import Vue from "vue"
import VueRouter from "vue-router"
import store from "@/store"
// Routes
import { canNavigate } from "@/libs/acl/routeProtection"
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from "@/auth/utils"
import apps from "./routes/apps"
import scout from "./routes/scout"
import dashboard from "./routes/dashboard"
import teams from "./routes/teams"
import notify from "./routes/notify"
import match from "./routes/match"
import season from "./routes/season"
import league from "./routes/league"
import players from "./routes/players"
import admin from "./routes/admin"
import pages from "./routes/pages"
import settings from "./routes/settings"
import toastService from "@/services/toastService"
import i18n from "@/libs/i18n"
import job from "./routes/job"
import smsLog from "./routes/sms-log"

Vue.use(VueRouter)

let findTeamId = JSON.parse(localStorage.getItem("teams")) ? JSON.parse(localStorage.getItem("teams"))[0].id : null
const allSeasons = JSON.parse(localStorage.getItem("seasons")) || {}
const seasonId = localStorage.getItem("season")
const allTeams = JSON.parse(localStorage.getItem("teams")) || {}
const userData = JSON.parse(localStorage.getItem("userData")) || {}
let ability = null
if (userData) ability = userData.ability || null

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return {
      x: 0,
      y: 0,
    }
  },
  routes: [
    {
      path: "/",
      redirect: {
        name: "team-players",
        params: {
          seasonId: seasonId,
          id: findTeamId,
        },
      },
    },
    ...apps,
    ...dashboard,
    ...teams,
    ...match,
    ...season,
    ...league,
    ...players,
    ...admin,
    ...pages,
    ...settings,
    ...scout,
    ...notify,
    ...job,
    ...smsLog,
    {
      path: "*",
      redirect: "error-404",
    },
  ],
})
router.beforeEach((to, _, next) => {
  let teamAndSeason = true
  if (to.params.id && to.params.seasonId && ability) {
    const seasonId = Number(to.params.seasonId) || null
    const teamId = Number(to.params.id) || null

    if (to.meta.resource) {
      let authControlWithSeasonTeam =
        Object.values(ability).find((abilityData) => {
          if (
            seasonId == abilityData.season &&
            teamId == abilityData.team &&
            abilityData.action == "manage" &&
            abilityData.subject == "all"
          ) {
            return true
          } else {
            return (
              seasonId == abilityData.season &&
              teamId == abilityData.team &&
              abilityData.subject == to.meta.resource &&
              (abilityData.action == "manage" || to.meta.action == abilityData.action)
            )
          }
        }) !== undefined
      teamAndSeason = authControlWithSeasonTeam
    } else {
      let seasonControl = Object.values(ability).find((seasonsData) => seasonId == seasonsData.season) !== undefined
      let teamControl = Object.values(ability).find((teamsData) => teamId == teamsData.team) !== undefined
      teamAndSeason = seasonControl && teamControl
    }
  }
  const isLoggedIn = isUserLoggedIn()

  if (!canNavigate(to)) {
    // Redirect to login if not logged in

    if (!isLoggedIn)
      return next({
        name: "login",
      })
    // If logged in => not authorized
    toastService(i18n.t("You are not authorized"), "UserIcon", "danger")
    if (_.name == null) return next({ name: "dashboard-antrenor" })
    if (_.name != null) return next({ name: _.name, params: _.params })
  }
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData()
    next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  }
  if (!teamAndSeason) {
    toastService(i18n.t("You are not authorized"), "UserIcon", "danger")
    if (_.name == null) return next({ name: "dashboard-antrenor" })
    if (_.name != null) return next({ name: _.name, params: _.params })
  }
  if (to.params.id && to.params.seasonId) {
    /**
     * set store
     */
    store.dispatch("app/setSeason", to.params.seasonId).then((response) => {
      localStorage.setItem("season", to.params.seasonId)
    })
  }

  return next()
})
export default router
