<template>
  <div class="card card-body">
    <b-modal
      @ok="handleOk"
      :ok-title="$t('Add')"
      :cancel-title="$t('Cancel')"
      id="modal-copy"
      ref="isCopyModalOpen"
      :title="$t('Copy')"
      centered
      :ok-disabled="copying"
      :cancel-disabled="copying"
      :no-close-on-backdrop="copying"
      :hide-header-close="copying"
      modal-header-close="false"
    >
      <b-overlay :show="copying" rounded="sm">
        <div>
          <b-form>
            <b-row>
              <b-col md="12">
                <label>{{ $t("Training Name") }}</label>
                <b-form-group>
                  <b-input-group>
                    <b-form-input v-model="trainingModalData.title" type="text" :placeholder="$t('Training Name')" />
                  </b-input-group>
                </b-form-group>
              </b-col>

              <b-col md="8">
                <label>{{ $t("Training Date") }}</label>
                <b-form-group>
                  <b-input-group>
                    <b-form-datepicker
                      :placeholder="$t('Training Date')"
                      :obj="trainingModalData.startDate"
                      v-model="trainingModalData.startDate"
                      :locale="lang"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>

              <b-col md="4">
                <label>{{ $t("Start Time") }}</label>
                <b-form-group>
                  <b-input-group>
                    <b-form-input v-model="trainingModalData.startTime" type="text" placeholder="HH:mm" />
                    <b-input-group-append>
                      <b-form-timepicker
                        v-model="trainingModalData.startTime"
                        no-close-button
                        button-only
                        button-variant="outline-info"
                        left
                        size="sm"
                        locale="tr"
                      />
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <label>{{ $t("Season") }}</label>
                <b-form-group>
                  <v-select
                    v-model="trainingModalData.season"
                    :options="avaibleSeasons"
                    :reduce="(option) => option.id"
                    :getOptionLabel="(option) => option.name"
                    :clearable="false"
                  >
                  </v-select>
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </div>
      </b-overlay>
    </b-modal>
    <div class="card-header p-0 m-0 mb-2">
      <div>
        <b-card-title> </b-card-title>
      </div>
      <div>
        <div>
          <div class="d-flex justify-content-center">
            <training-navigation />
          </div>
        </div>
      </div>
    </div>
    <b-row>
      <b-col lg="4">
        <div class="block">
          <p class="text-">{{ $t("Training Name") }}</p>
          <p class="h5">{{ training.title }}</p>
        </div>
      </b-col>

      <b-col lg="4">
        <div class="block">
          <p class="">{{ $t("Training Date") }}</p>
          <p class="h5">
            {{ training.startDate | longDateTime }} -
            {{ parseTime(training.endDate) }}
          </p>
        </div>
      </b-col>

      <b-col lg="4">
        <div class="block">
          <p>{{ $t("Location") }}</p>
          <p class="h5">
            {{ training.location ? training.location.name : null }}
          </p>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
  import { avatarText } from "@core/utils/filter"
  import { ref } from "@vue/composition-api"
  import { useRouter } from "@core/utils/utils"
  import i18n from "@/libs/i18n"
  import TrainingPlugin from "@/views/team/team-sub-pages/training/TrainingPlugin"
  import vSelect from "vue-select"
  import TrainingNavigation from "../../edit-training/TrainingNavigation.vue"

  export default {
    components: { vSelect, TrainingNavigation },
    props: {
      training: null,
    },
    methods: {
      handleOk(bvModalEvt) {
        // Prevent modal from closing
        bvModalEvt.preventDefault()
        this.copyTraining(this.trainingModalData)
      },
    },
    setup() {
      const { router, route } = useRouter()
      const teamId = ref(router.currentRoute.params.id)
      const trainingId = ref(router.currentRoute.params.trainingId)
      const lang = ref(i18n.locale)

      const parseTime = (date) => {
        if (!date) return ""
        let dateFormat = new Date(date)
        let dateFormat1 = dateFormat.toLocaleTimeString(i18n.locale, {
          hour: "numeric",
          minute: "numeric",
        })

        return dateFormat1
      }

      const { isCopyModalOpen, copying, copyTraining, showTrainingCopyModal, trainingModalData, avaibleSeasons } =
        TrainingPlugin()

      return {
        avatarText,
        teamId,
        trainingId,
        parseTime,
        isCopyModalOpen,
        copying,
        copyTraining,
        showTrainingCopyModal,
        trainingModalData,
        avaibleSeasons,
        lang,
      }
    },
  }
</script>
<style lang="scss" scoped>
  ._col.score {
    margin-top: 0rem;
  }

  .info-bar-match {
    ._card-body {
      padding: 1.5rem 0 0 0;
    }

    .card {
      margin-bottom: 0.5rem;
    }

    .teamInfo {
      .badge {
        margin: 0;
      }
    }

    ._col:first-child {
      .d-flex > div:first-child {
        margin-right: 1rem;
        text-align: right;
      }
    }

    ._col:last-child {
      .d-flex > div:last-child {
        margin-left: 1rem;
        text-align: left;
      }
    }
  }
</style>
