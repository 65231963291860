<template>
  <div class="sidebar-left">
    <div class="sidebar">
      <div class="sidebar-content todo-sidebar">
        <div class="todo-app-menu">
          <vue-perfect-scrollbar :settings="perfectScrollbarSettings" class="sidebar-menu-list scroll-area mt-2">
            <b-list-group class="list-group-filters">
              <b-list-group-item
                v-for="filter in taskFilters"
                :key="filter.title + $route.path"
                :to="filter.route"
                @click="$emit('update:selected-tags', filter.requestType)"
              >
                <!-- <feather-icon :icon="filter.icon" size="18" class="mr-75" /> -->
                <span class="align-text-bottom line-height-1">{{ filter.title }}</span>
              </b-list-group-item>
            </b-list-group>
          </vue-perfect-scrollbar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import VuePerfectScrollbar from "vue-perfect-scrollbar"
  import { BButton, BListGroup, BListGroupItem } from "bootstrap-vue"
  import { isDynamicRouteActive } from "@core/utils/utils"
  import Ripple from "vue-ripple-directive"
  import i18n from "@/libs/i18n"

  export default {
    directives: {
      Ripple,
    },
    components: {
      BButton,
      BListGroup,
      BListGroupItem,
      VuePerfectScrollbar,
    },
    created() {
      setTimeout(() => {}, 1500)
    },
    props: { selectedTags: null },
    model: {},
    computed: {},
    setup() {
      const perfectScrollbarSettings = {
        maxScrollbarLength: 60,
      }
      const taskFilters = [
        {
          title: i18n.t("All"),
          requestType: "wait",
          icon: "MailIcon",
          route: { name: "team-notify" },
        },
        {
          title: i18n.t("Match"),
          requestType: "competition",
          icon: "StarIcon",
          route: { name: "team-notify", params: { filter: "competition" } },
        },
        {
          title: i18n.t("Antrenman"),
          requestType: "training",
          icon: "CheckIcon",
          route: { name: "team-notify", params: { filter: "training" } },
        },
        {
          title: i18n.t("Birth Date Event"),
          requestType: "birth_date",
          icon: "TrashIcon",
          route: { name: "team-notify", params: { filter: "birth_date" } },
        },
        {
          title: i18n.t("Archive"),
          requestType: "archive",
          icon: "TrashIcon",
          route: { name: "team-notify", params: { filter: "archive" } },
        },
      ]

      return {
        perfectScrollbarSettings,
        taskFilters,
        isDynamicRouteActive,
      }
    },
  }
</script>

<style></style>
