<template>
  <div v-if="competitionForm && competitionForm.competition && items">
    <app-timeline class="center-timelined">
      <template v-for="events in items">
        <app-timeline-item
          :img="require('@/assets/images/icons/ball.svg')"
          v-if="events && events.competitionEventType == 'GOAL'"
          :class="{ left: selectTeam(events), right: !selectTeam(events) }"
        >
          <div
            class="d-flex flex-sm-row flex-column flex-wrap mb-1 mb-sm-0"
            :class="{
              'justify-content-end': selectTeam(events),
              'justify-content-start': !selectTeam(events),
            }"
          >
            <small class="text-muted">{{ getEventsMinute(events.minute) }}'</small>
            <div>
              <span @click="removeEvent(events.id)" v-if="false" class="remove badge badge-light-dark">
                <feather-icon icon="TrashIcon"></feather-icon>
              </span>
              <h6>{{ $t("Goal") }}!</h6>
              <div
                class="badge badge-primary d-inline-block"
                v-text="events.mainPlayer && events.mainPlayer.name"
              ></div>
              <br />
              <div
                class="badge badge-light text-dark d-inline-block"
                v-if="events.subPlayer"
                v-text="events.subPlayer && events.subPlayer.name"
              ></div>

            </div>
          </div>
        </app-timeline-item>
        <app-timeline-item
          :img="require('@/assets/images/icons/playerchange.svg')"
          v-if="events && events.competitionEventType == 'CHANGE_PLAYER'"
          :class="{ left: selectTeam(events), right: !selectTeam(events) }"
        >
          <div
            class="d-flex flex-sm-row flex-column flex-wrap mb-1 mb-sm-0"
            :class="{
              'justify-content-end': selectTeam(events),
              'justify-content-start': !selectTeam(events),
            }"
          >
            <small class="text-muted">{{ getEventsMinute(events.minute) }}'</small>
          </div>
          <div>
            <span @click="removeEvent(events.id)" v-if="false" class="remove badge badge-light-dark">
              <feather-icon icon="TrashIcon"></feather-icon>
            </span>
            <h6>{{ $t("Player Change") }}</h6>
            <div class="badge badge-success d-inline-block" v-text="events.mainPlayer && events.mainPlayer.name" />
            <br />
            <div class="badge badge-light-danger d-inline-block" v-text="events.subPlayer && events.subPlayer.name" />
            <br />
            <div class="badge badge-light-dark d-inline-block" v-text="$t(events.reason)" />
          </div>
        </app-timeline-item>
        <app-timeline-item
          :img="require('@/assets/images/icons/redcard.svg')"
          v-if="events && events.competitionEventType == 'RED_CARD'"
          :class="{ left: selectTeam(events), right: !selectTeam(events) }"
        >
          <div
            class="d-flex flex-sm-row flex-column flex-wrap mb-1 mb-sm-0"
            :class="{
              'justify-content-end': selectTeam(events),
              'justify-content-start': !selectTeam(events),
            }"
          >
            <small class="text-muted">{{ getEventsMinute(events.minute) }}'</small>
            <div>
              <span @click="removeEvent(events.id)" v-if="false" class="remove badge badge-light-dark">
                <feather-icon icon="TrashIcon"></feather-icon>
              </span>
              <h6>{{ $t("Red Card") }}</h6>
              <div class="badge badge-light-danger d-inline-block">
                {{ events.mainPlayer && events.mainPlayer.name }}
              </div>
            </div>
          </div>
        </app-timeline-item>
        <app-timeline-item
          :img="require('@/assets/images/icons/yellow-card.png')"
          v-if="events && events.competitionEventType == 'YELLOW_CARD'"
          :class="{ left: selectTeam(events), right: !selectTeam(events) }"
        >
          <div
            class="d-flex flex-sm-row flex-column flex-wrap mb-1 mb-sm-0"
            :class="{
              'justify-content-end': selectTeam(events),
              'justify-content-start': !selectTeam(events),
            }"
          >
            <small class="text-muted">{{ getEventsMinute(events.minute) }}'</small>
            <div>
              <span @click="removeEvent(events.id)" v-if="false" class="remove badge badge-light-dark">
                <feather-icon icon="TrashIcon"></feather-icon>
              </span>
              <h6>{{ $t("Yellow Card") }}</h6>
              <div class="badge badge-light-warning d-inline-block">
                {{ events.mainPlayer && events.mainPlayer.name }}
              </div>
            </div>
          </div>
        </app-timeline-item>
        <app-timeline-item
          :img="require('@/assets/images/icons/yellow-red-card.png')"
          v-if="events && events.competitionEventType == 'YELLOW_RED_CARD'"
          :class="{ left: selectTeam(events), right: !selectTeam(events) }"
        >
          <div
            class="d-flex flex-sm-row flex-column flex-wrap mb-1 mb-sm-0"
            :class="{
              'justify-content-end': selectTeam(events),
              'justify-content-start': !selectTeam(events),
            }"
          >
            <small class="text-muted">{{ getEventsMinute(events.minute) }}'</small>
            <div>
              <span @click="removeEvent(events.id)" v-if="false" class="remove badge badge-light-dark">
                <feather-icon icon="TrashIcon"></feather-icon>
              </span>
              <h6>{{ $t("Red Card (Double yellow)") }}</h6>
              <div class="badge badge-light-warning d-inline-block">
                {{ events.mainPlayer && events.mainPlayer.name }}
              </div>
            </div>
          </div>
        </app-timeline-item>
      </template>
      <app-timeline-item :img="require('@/assets/images/icons/finished.svg')" class="left">
        <div
          class="d-flex flex-sm-row flex-column flex-wrap mb-1 mb-sm-0"
          :class="{
            'justify-content-end': true,
          }"
        >
          <small class="text-muted">{{ competitionForm.competition.time.select }}'</small>
          <div>
            <h6>{{ $t("Finished") }}!</h6>
            <h6 class="badge badge-success d-inline-block" :key="items.length">
              {{
                `${competitionForm.competition.homeScore ? competitionForm.competition.homeScore : "0"}:${
                  competitionForm.competition.awayScore ? competitionForm.competition.awayScore : "0"
                }`
              }}
            </h6>
            <br />
            <div class="badge badge-dark d-inline-block">
              {{ `${competitionForm.location.select.name.substr(0, 18)}...` }}
            </div>
          </div>
        </div>
      </app-timeline-item>
    </app-timeline>
  </div>
</template>
<script>
  import AppTimeline from "@core/components/app-timeline/AppTimeline.vue"
  import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue"
  export default {
    props: { items: null, competitionForm: null },
    components: {
      AppTimeline,
      AppTimelineItem,
    },
    created() {},
    methods: {
      getScore(score) {
        if (score && Number(score) != NaN && score > -1) {
          return Number(score)
        }
        return 0
      },
      getEventsMinute(minute) {
        if (Number(minute) > Number(this.competitionForm.competition.time.select)) {
          var extraTimeCalc = Number(minute) - Number(this.competitionForm.competition.time.select)
          return this.competitionForm.competition.time.select + "+" + extraTimeCalc
        }
        return minute
      },
      selectTeam(event) {
        if (event && event.team) {
          if (this.competitionForm.homeTeam.select.id == event.team.id) {
            return true
          } else {
            return false
          }
        }
        return true
      },
      removeEvent() {
        this.$bvModal
          .msgBoxConfirm(this.$t("Please confirm that you want to delete this."), {
            title: this.$t("Please Confirm"),
            size: "sm",
            okVariant: "primary",
            okTitle: this.$t("Yes"),
            cancelTitle: this.$t("Cancel"),
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          })
          .then((value) => {
            if (value) {
              this.$bvToast.toast($t("Deleted"), { title: "Silindi" })
            }
          })
      },
    },
  }
</script>
<style lang="scss">
  .app-timeline.center-timelined {
    .timeline-item {
      width: 50%;

      &:hover .remove {
        visibility: visible;
      }
      &.left {
        border-left: 0;
        border-right: 1px solid #ebe9f1;
        text-align: right;
        padding-right: 1rem;
        .remove {
          margin-left: -3rem;
        }

        .timeline-item-icon {
          right: -12px;
          left: auto;
        }

        .timeline-item-point {
          left: auto;
          right: -5px;
        }
      }

      &.right {
        border-left: 1px solid #ebe9f1;
        text-align: left;
        padding-left: 1rem;
        margin-left: calc(50% - 1px);

        .timeline-item-icon {
          left: -12px;
        }

        .timeline-item-point {
          left: -5px;
        }
      }

      small {
        position: absolute;
        top: -20px;
      }

      &:last-of-type {
        border: unset;
      }

      &:last-of-type:after {
        left: unset;
        right: 0px;
      }

      .timeline-item-icon.img {
        border: 0;
      }
    }
  }
</style>
