import store from "@/store"
import { computed, reactive, onMounted } from "@vue/composition-api"
export default function useGetTeams() {
  const teams = reactive([
    {
      header: "Teams",
      action: "read",
      resource: "ACL",
    },
    {
      title: "Squad Management",
      icon: "MonitorIcon",
      route: "dashboard-team-players",
      action: "read",
      resource: "ACL",
    },
  ])
  const getTeams = computed(() => {
    const teams = store.getters["app/teams"]
    const localTeams = JSON.parse(localStorage.getItem("teams"))
    if (teams.length > 0) {
      return setArray(stateTeams)
    } else {
      return setArray(localTeams)
    }
    //
  })
  const setArray = (localTeams) => {
    if (localTeams) {
      localTeams.forEach((element) => {
        teams.push({
          title: element.name,
          icon: null,
          route: "team-players",
          slug: element.id,
          action: "read",
          resource: "ACL",
        })
      })
    }
    return teams
  }
  return {
    getTeams,
    getTeams,
    teams,
  }
}
// export default [{
//     header: 'Teams',
//   },
//   {
//     title: 'U-19',
//     icon: null,
//     route: 'team-overview',
//     slug: 1,
//     action: 'read',
//     resource: 'all',
//   },
//   {
//     title: 'U-17',
//     icon: null,
//     route: 'team-overview',
//     slug: 2
//   },
//   {
//     title: 'U-16',
//     icon: null,
//     route: 'team-overview',
//     slug: 3
//   },
//   {
//     title: 'U-15',
//     icon: null,
//     route: 'team-overview',
//     slug: 4
//   },
//   {
//     title: 'U-14',
//     icon: null,
//     route: 'team-overview',
//     slug: 5
//   },
// ]
// export default {
//   created() {
//     console.log('sss')
//   }
// }
