<template>
  <div class="my-2">
    <b-overlay variant="white" :show="loading" spinner-variant="primary" blur="0" opacity=".75" rounded="sm">
      <b-card no-body>
        <b-table :fields="fields" striped class="border" :items="trainings" responsive="lg">
          <!-- general table td -->
          <template #cell(id)="data">
            <div @click="goToItem(data.value)">
              <a class="d-block h6 font-weight-bold">#{{ data.value }}</a>
            </div>
          </template>

          <template #cell(training.startDate)="data" style="width: 120px">
            <div>
              <b-link class="text-dark">
                {{ data.item.training.startDate | nativeDateTime }}
              </b-link>
            </div>
          </template>
          <template #cell(title)="data" style="width: 120px">
            <div>
              <b-link @click="goToItem(data.item.training.id)" class="text-dark">
                <span class="text-nowrap">{{ data.item.training.title }}</span>
              </b-link>
            </div>
          </template>

          <template #cell(trainingBlocks)="data">
            <div v-for="block in data.item.training.trainingBlocks" :key="block.id" class="outcome-div">
              <div v-for="trainingItem in block.trainingItems" :key="trainingItem.id">
                <ul class="outcome-ul" v-for="(loc, index) in trainingItem.learningOutcomes" :key="index">
                  <li class="text-nowrap text-small py-0" v-b-tooltip.hover v-if="loc.name" :title="loc.name">
                    {{ loc.name && loc.name.substring(0, 10) }}...
                  </li>
                </ul>
              </div>
            </div>
          </template>

          <template #cell(attend)="data">
            <div>
              <b-badge :variant="data.item.attendant ? 'success' : 'danger'">{{
                data.item.attendant ? $t("Attended") : data.item.reason && data.item.reason.name
              }}</b-badge>
            </div>
          </template>

          <template #cell(playerSerpScore)="data">
            <span class="text-nowrap" v-if="data.value > 0">{{ data.value }}</span>
          </template>

          <template #cell(duration)="data">
            <span class="text-nowrap">{{ data.value }}</span>
          </template>
        </b-table>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
  import { useRouter } from "@core/utils/utils"
  import axios from "@axios"
  import store from "@/store"
  import i18n from "@/libs/i18n"
  import { ref, reactive, watch, computed, toRefs, onUnmounted, onBeforeMount } from "@vue/composition-api"
  export default {
    components: {},
    directives: {},

    setup(props, { emit, root }) {
      const { router, route } = useRouter()

      const loading = ref(false)

      const fields = computed({
        get: () => [
          {
            key: "training.startDate",
            label: i18n.t("Date"),
            sortable: true,
            thStyle: { width: "250px", "min-width": "250px", "max-width": "250px" },
          },
          // A column that needs custom formatting
          {
            key: "title",
            label: i18n.t("Training Title"),
            thStyle: { width: "250px", "min-width": "250px", "max-width": "270px" },
          },

          // A regular column
          {
            key: "trainingBlocks",
            label: i18n.t("Exercises"),
            thStyle: { "max-width": "80px" },
          },
          {
            key: "attend",
            label: i18n.t("Attendance"),
            thStyle: { width: "150px", "min-width": "150px", "max-width": "180px" },
          },
          {
            key: "playerSerpScore",
            label: i18n.t("player") + " (s-RPE)",
            thStyle: { width: "150px", "min-width": "150px", "max-width": "180px" },
          },
          {
            key: "duration",
            label: i18n.t("Duration"),
            sortable: true,
            thStyle: { width: "180px", "min-width": "180px", "max-width": "200px" },
          },
        ],
      })

      const trainings = ref([])

      const seasonId = ref(Number(router.currentRoute.params.seasonId || router.currentRoute.params.sId))
      const teamId = ref(Number(router.currentRoute.params.id || router.currentRoute.params.tId))

      onBeforeMount(() => {
        getTrainings()
      })

      const getTrainings = () => {
        loading.value = true
        axios
          .get(`/season/${seasonId.value}/training-attendant/player/attends/${router.currentRoute.params.playerId}`)
          .then((response) => {
            trainings.value = response.data
            loading.value = false
          })
      }

      const goToItem = (id) => {
        router.push({
          name: "team-training-preview",
          params: {
            id: teamId.value,
            trainingId: id,
            seasonId: seasonId.value,
          },
        })
      }

      return {
        trainings,
        fields,
        getTrainings,
        loading,
        goToItem,
      }
    },
  }
</script>

<style lang="scss">
  .per-page-selector {
    width: 90px;
  }

  .right-top {
    position: absolute;
    right: 2rem;
    top: 1rem;
  }

  .embed-video {
    iframe {
      max-width: 100%;
      max-height: 30vh;
    }
  }
  .outcome-ul {
    margin-top: 1px;
    margin-bottom: 0px;
    font-size: 13px;
  }
  .outcome-div {
    padding-right: 0px;
    margin-left: -25px;
  }
</style>
