import { ref, watch, computed } from "@vue/composition-api"

import {
  seasonService,
  teamService,
  leagueService,
  positionService,
  clubService,
  locationService,
  departmentService,
  jobService,
  abilityItemService,
  testItemService,
  learningOutcomeService,
  learningOutcomeCategoryService,
} from "@/services"

export default function SettingPlugin(props, { emit, root }) {
  const refTeamListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: "id", label: "#", sortable: true },
    { key: "homeOwnerTeam", sortable: true },
    { key: "deplasmanTeam", sortable: true },
    { key: "branch", sortable: true },
    { key: "lastEventTime", sortable: true },
    { key: "lastEvent", sortable: true },
    { key: "actions" },
  ]

  const perPage = ref(localStorage.getItem("perPage") || 10)
  const totalItems = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref("")
  const sortBy = ref("id")
  const isSortDirDesc = ref(true)
  const statusFilter = ref(null)

  const seasonSer = new seasonService()
  const teamSer = new teamService()
  const positionSer = new positionService()
  const leagueSer = new leagueService()
  const clubSer = new clubService()
  const locationSer = new locationService()
  const departmentSer = new departmentService()
  const jobSer = new jobService()
  const abilityItemSer = new abilityItemService()
  const testItemSer = new testItemService()
  const learningOutcomeSer = new learningOutcomeService()
  const learningOutcomeCategorySer = new learningOutcomeCategoryService()

  const dataMeta = computed(() => {
    const localItemsCount = refTeamListTable.value ? refTeamListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalItems.value,
    }
  })

  // const teams = computed(() => {
  //   return store.getters['app-team/teams']
  // })

  const refFetchData = () => {
    refTeamListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, statusFilter], () => {
    refFetchData()
  })

  const getSeasons = (ctx, callback) => {
    seasonSer
      .get()
      .then((response) => {
        callback(response.data)
      })
      .catch((error) => {
        console.log(error)
        root.toast(i18n.t("Error fetching season list"), "AlertTriangleIcon", "danger")
      })
  }

  const getTeams = (ctx, callback) => {
    teamSer
      .get()
      .then((response) => {
        callback(response.data.data)
      })
      .catch((error) => {
        console.log(error)
        root.toast(i18n.t("Error Fetching Data"), "AlertTriangleIcon", "danger")
      })
  }

  const getLeagues = (ctx, callback) => {
    leagueSer
      .get()
      .then((response) => {
        callback(response.data)
      })
      .catch((error) => {
        console.log(error)
        root.toast(i18n.t("Error Fetching Data"), "AlertTriangleIcon", "danger")
      })
  }

  const getPositions = (ctx, callback) => {
    positionSer
      .get()
      .then((response) => {
        callback(response.data)
      })
      .catch((error) => {
        console.log(error)
        root.toast(i18n.t("Error Fetching Data"), "AlertTriangleIcon", "danger")
      })
  }

  const getClubs = (ctx, callback) => {
    clubSer
      .get()
      .then((response) => {
        callback(response.data)
      })
      .catch((error) => {
        console.log(error)
        root.toast(i18n.t("Error Fetching Data"), "AlertTriangleIcon", "danger")
      })
  }

  const getLocations = (ctx, callback) => {
    locationSer
      .get()
      .then((response) => {
        callback(response.data)
      })
      .catch((error) => {
        console.log(error)
        root.toast(i18n.t("Error Fetching Data"), "AlertTriangleIcon", "danger")
      })
  }

  const getDepartments = (ctx, callback) => {
    departmentSer
      .get()
      .then((response) => {
        callback(response.data)
      })
      .catch((error) => {
        console.log(error)
        root.toast(i18n.t("Error Fetching Data"), "AlertTriangleIcon", "danger")
      })
  }

  const getJobs = (ctx, callback) => {
    jobSer
      .get()
      .then((response) => {
        callback(response.data)
      })
      .catch((error) => {
        console.log(error)
        root.toast(i18n.t("Error Fetching Data"), "AlertTriangleIcon", "danger")
      })
  }

  const getAbilityItems = (ctx, callback) => {
    abilityItemSer
      .get()
      .then((response) => {
        callback(response.data)
      })
      .catch((error) => {
        console.log(error)
        root.toast(i18n.t("Error Fetching Data"), "AlertTriangleIcon", "danger")
      })
  }

  const getTestItems = (ctx, callback) => {
    testItemSer
      .get()
      .then((response) => {
        callback(response.data)
      })
      .catch((error) => {
        console.log(error)
        root.toast(i18n.t("Error Fetching Data"), "AlertTriangleIcon", "danger")
      })
  }

  const getLearningOutcomes = (ctx, callback) => {
    learningOutcomeSer
      .getNative({ deleted: -1 })
      .then((response) => {
        callback(response.data)
      })
      .catch((error) => {
        console.log(error)
        root.toast(i18n.t("Error Fetching Data"), "AlertTriangleIcon", "danger")
      })
  }

  const getLearningOutcomeCategories = (ctx, callback) => {
    learningOutcomeCategorySer
      .get()
      .then((response) => {
        callback(response.data)
      })
      .catch((error) => {
        console.log(error)
        root.toast(i18n.t("Error Fetching Data"), "AlertTriangleIcon", "danger")
      })
  }

  watch(perPage, (perPage) => {
    localStorage.setItem("perPage", perPage)
  })

  return {
    getSeasons,
    getTeams,
    getLeagues,
    getPositions,
    getClubs,
    getLocations,
    getDepartments,
    getJobs,
    getAbilityItems,
    getTestItems,
    getLearningOutcomes,
    getLearningOutcomeCategories,
    tableColumns,
    perPage,
    currentPage,
    totalItems,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refTeamListTable,
    statusFilter,
    refFetchData,
  }
}
