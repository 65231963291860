import http from "@/libs/axios"
import toastService from "./toastService"
import i18n from "@/libs/i18n"

export default class learningOutcomeService {
  constructor() {}

  async postContact(data, playerId) {
    return new Promise((resolve, reject) => {
      http
        .post(`/player/${playerId}/contact`, data)
        .then((response) => {
          toastService(i18n.t("Successfully Created"), "PlusIcon", "success")
          return resolve(response)
        })
        .catch((error) => reject(error))
    })
  }

  async postTransfer(data, playerId) {
    return new Promise((resolve, reject) => {
      http
        .post(`/player/${playerId}/transfer-history`, data)
        .then((response) => resolve(response))
        .catch((error) => reject(error))
    })
  }

  async createPlayer(data) {
    return new Promise((resolve, reject) => {
      http
        .post(`/player/create-player-fast`, data)
        .then((response) => resolve(response))
        .catch((error) => {
          return reject(error)
        })
    })
  }

  async deletePlayer(data, playerId) {
    return new Promise((resolve, reject) => {
      http
        .put(`/player/${playerId}/delete`, data)
        .then((response) => resolve(response))
        .catch((error) => reject(error))
    })
  }

  async uploadAvatar(data, playerId) {
    let formData = new FormData()
    formData.append("image", data)

    return new Promise((resolve, reject) => {
      http
        .post(`/player/${playerId}/avatarUpload`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => resolve(response))
        .catch((error) => {
          return reject(error)
        })
    })
  }

  async putPlayerTesting(data, playerId) {
    return new Promise((resolve, reject) => {
      http
        .put(`/player/${playerId}/testing`, data)
        .then((response) => resolve(response))
        .catch((error) => reject(error))
    })
  }
}
