<template>
  <div>
    <div v-if="reviews && reviews.length > 0" class="table-responsive">
      <b-table ref="selectableTable" selectable sticky-header="400px" :items="reviews" :fields="fields" class="mb-0">
        <template #cell(type)="data">
          {{ $t(data.item.type) }}
        </template>
        <template #cell(created_at)="data">
          {{ data.item.created_at | nativeDate }}
        </template>
        <template #cell(user)="data">
          {{ data.item.user }}
        </template>
        <template #cell(comment)="data">
          {{ data.item.comment }}
        </template>
      </b-table>
    </div>
    <div v-else>
      <div class="alert alert-warning p-2 m-2">
        {{ $t("No comment") }}
      </div>
    </div>
  </div>
</template>

<script>
  import { onUnmounted, ref } from "@vue/composition-api"

  import { VueGoodTable } from "vue-good-table"
  import i18n from "@/libs/i18n"
  import { useRouter } from "@core/utils/utils"
  import axios from "@/libs/axios"

  export default {
    components: {
      VueGoodTable,
    },
    props: {
      reviews: {
        default: [],
        require: true,
      },
    },

    setup(http) {
      const fields = [
        {
          key: "created_at",
          label: i18n.t("Date"),
          sortable: true,
          thStyle: { width: "200px", "min-width": "200px", "max-width": "220px" },
        },
        {
          key: "comment",
          label: i18n.t("Comment"),
          sortable: false,
          thStyle: { width: "200px", "min-width": "200px", "max-width": "220px" },
        },
        {
          key: "user",
          label: i18n.t("User"),
          sortable: false,
          thStyle: { width: "200px", "min-width": "200px", "max-width": "220px" },
        },
        {
          key: "type",
          label: i18n.t("Review"),
          sortable: false,
          thStyle: { width: "50px", "min-width": "50px", "max-width": "50px" },
        },
      ]

      return {
        fields,
      }
    },
  }
</script>
<style lang="scss">
  @import "@core/scss/vue/libs/vue-good-table.scss";
</style>
