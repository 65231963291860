import { ref, watch } from "@vue/composition-api"
import i18n from "@/libs/i18n"

// import store from '@/store'

export default function useTaskHandler(props, emit) {
  // ------------------------------------------------
  // taskLocal
  // ------------------------------------------------
  const taskLocal = ref(JSON.parse(JSON.stringify(props.task.value)))
  const resetTaskLocal = () => {
    taskLocal.value = JSON.parse(JSON.stringify(props.task.value))
  }
  watch(props.task, () => {
    resetTaskLocal()
  })

  // ------------------------------------------------
  // isEventHandlerSidebarActive
  // * Clear form if sidebar is closed
  // ! We can hide it using @hidden event
  // ------------------------------------------------
  // watch(props.isEventHandlerSidebarActive, val => {
  //   // ? Don't reset event till transition is finished
  //   if (!val) {
  //     setTimeout(() => {
  //       clearForm.value()
  //     }, 350)
  //   }
  // })

  const onSubmit = () => {
    const taskData = taskLocal

    // * If event has id => Edit Event
    // Emit event for add/update event
    if (props.task.value.id) emit("update-task", taskData.value)
    else emit("add-task", taskData.value)

    // Close sidebar
    emit("update:is-task-handler-sidebar-active", false)
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  // ------------------------------------------------
  // guestOptions
  // ------------------------------------------------

  /* eslint-enable global-require */

  const resolveAvatarVariant = (tags) => {
    if (tags.includes("high")) return "primary"
    if (tags.includes("medium")) return "warning"
    if (tags.includes("low")) return "success"
    if (tags.includes("update")) return "danger"
    if (tags.includes("team")) return "info"
    return "primary"
  }

  const tagOptions = [
    { label: i18n.t("Team"), value: "team" },
    { label: i18n.t("TagLow"), value: "low" },
    { label: i18n.t("TagMedium"), value: "medium" },
    { label: i18n.t("TagHigh"), value: "high" },
    { label: i18n.t("TagUpdate"), value: "update" },
  ]

  return {
    taskLocal,
    resetTaskLocal,

    // UI
    resolveAvatarVariant,
    tagOptions,
    onSubmit,
  }
}
