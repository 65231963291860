<template>
  <div>
    <preview-info-bar :training="training" />

    <div class="alert alert-warning p-2" v-if="attends.length == 0">
      <p>{{ $t("Data Waiting..") }}</p>
    </div>
    <div class="table-responsive" v-else>
      <b-card no-body>
        <div class="d-block m-2">
          <div class="float-right">
            <b-button :disabled="sending" @click="sendNotify()" variant="primary" class="right-top">{{
              $t("Send a Review Notice to Players")
            }}</b-button>
          </div>
        </div>
        <b-card-body class="mt-1">
          <div class="table-responsive">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <td style="width: 300px">
                    <div class="font-weight-bold">{{ $t("Player") }}</div>
                  </td>
                  <td style="width: 100px">
                    <div class="d-flex flex-column font-weight-bold">
                      <span>{{ $t("Serp") }}</span>
                      <span>({{ $t("Player") }})</span>
                    </div>
                  </td>
                  <td style="width: 250px">
                    <div class="d-flex flex-column font-weight-bold">
                      <span>{{ $t("Serp Comment") }}</span>
                      <span>({{ $t("Player") }})</span>
                    </div>
                  </td>
                  <td style="width: 350px">
                    <div class="d-flex flex-column font-weight-bold">
                      <span>{{ $t("General Impression") }}</span>
                    </div>
                  </td>
                  <td>
                    <div class="d-flex flex-column font-weight-bold">
                      <span>{{ $t("Additional Comments") }}</span>
                    </div>
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(attend, index) in attends" :key="index">
                  <td style="height: 80px">
                    <div class="d-flex align-items-center justify-content-start">
                      <b-avatar
                        size="40"
                        :text="avatarText(attend.player.name)"
                        :src="attend.player.avatar ? attend.player.avatar : null"
                      ></b-avatar>
                      <div class="d-block ml-1 mt-1">
                        <p class="small mt-0 text-left">
                          {{ attend.player.name }}
                        </p>
                        <p class="mt-n1 text-left">
                          <b-badge pill :variant="attend.player.position.color">
                            {{ $t(attend.player.position.name) }}
                          </b-badge>
                        </p>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="d-flex align-items-center justify-content-center">
                      <input
                        :tabindex="index"
                        placeholder=""
                        v-model.number="attend.playerSerpScore"
                        disabled
                        type="number"
                        class="form-control w-75"
                      />
                    </div>
                  </td>
                  <td>
                    <div class="d-flex align-items-center justify-content-center">
                      <span v-text="attend.playerSerpComment"></span>
                    </div>
                  </td>

                  <td class>
                    <div class="d-flex">
                      <div class="mr-1">
                        <b-form-rating
                          variant="warning"
                          :stars="getSetting('star')"
                          @change="changeSerpScore(attend, attend.player.id, index)"
                          v-model="attend.trainerSerpScore"
                        ></b-form-rating>
                      </div>
                      <div>
                        <input
                          :tabindex="index"
                          placeholder=""
                          @change="changeSerpScore(attend, attend.player.id, index)"
                          v-model.number="attend.trainerSerpScore"
                          min="0"
                          max="10"
                          type="number"
                          class="form-control w-100"
                        />
                      </div>
                    </div>
                  </td>
                  <td>
                    <input
                      :tabindex="index"
                      placeholder=""
                      @change="changeSerpScore(attend, attend.player.id, index)"
                      v-model="attend.trainerComment"
                      type="text"
                      class="form-control w-100"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </b-card-body>
      </b-card>
    </div>
  </div>
</template>
<script>
  import vSelect from "vue-select"
  import { avatarText } from "@core/utils/filter"
  import { ref, onBeforeMount } from "@vue/composition-api"
  import axios from "@axios"
  import { useRouter } from "@core/utils/utils"
  import BCardActions from "@core/components/b-card-actions/BCardActions.vue"
  import { useToast } from "vue-toastification/composition"
  import i18n from "@/libs/i18n"
  import PreviewInfoBar from "../detail/preview/PreviewInfoBar.vue"

  export default {
    components: { vSelect, BCardActions, PreviewInfoBar },
    props: {},
    setup(props, { root }) {
      const { route, router } = useRouter()
      const toast = useToast()
      const attends = ref([])
      const training = ref([])
      const sending = ref(false)
      const user = JSON.parse(localStorage.getItem("userData"))
      const fetchTrainingDetails = () => {
        axios
          .get(`/training/${router.currentRoute.params.trainingId}`)
          .then((response) => {
            training.value = response.data
          })
          .catch((err) => {
            root.toast(i18n.t("Response not found"), "CheckIcon", "success")
          })
      }
      const fetchPlayers = () => {
        axios.get(`/training-attendant/training/${router.currentRoute.params.trainingId}`).then((response) => {
          let dt = Object.values(response.data).filter((attend) => {
            // TODO: change position name control
            if (
              (user.role != "gk_coach" && attend.player.position.name != "Kaleci") ||
              (user.role == "gk_coach" && attend.player.position.name == "Kaleci")
            ) {
              return attend
            }
          })
          attends.value = dt
        })
      }
      const sendNotify = () => {
        sending.value = true
        root.toast(i18n.t("Notification is being sent"), "UserIcon", "info")
        axios
          .post(`/training/${router.currentRoute.params.trainingId}/performance/notification`)
          .then((response) => {
            root.toast(i18n.t("Notification invitation sent"), "CheckIcon", "success")
          })
          .catch((err) => {
            root.toast(i18n.t("Failed to send notification invitation"), "AlertCircle", "danger")
          })
          .finally(() => {
            setTimeout(() => {
              sending.value = false
            }, 5000)
          })
      }
      const changeSerpScore = (attend, playerId, index) => {
        axios
          .put(`/training/attendant/${attend.id}`, {
            trainerComment: attend.trainerComment,
            trainerSerpScore: attend.trainerSerpScore,
          })
          .then((response) => {
            Object.assign(attends.value[index].attend, response.data)
            root.toast(i18n.t("Successfully Saved"), "CheckIcon", "success")
          })
          .catch(() => {})
      }

      onBeforeMount(async () => {
        await fetchTrainingDetails()
        await fetchPlayers()
      })

      return {
        avatarText,
        attends,
        changeSerpScore,
        training,
        sendNotify,
        sending,
      }
    },
  }
</script>
<style lang="css" scoped>
  .table td:nth-child(3) {
    padding: 0.72rem 1rem;
  }
</style>
