<template>
  <div>
    <b-link
      class="btn btn-sm btn-primary mr-1"
      v-if="nav[0]"
      :to="{ params: { trainingId: nav[0].id } }"
      variant="primary"
      @click="refreshPage"
    >
      <feather-icon v-b-tooltip.hover :title="$t('Navigation')" size="24" icon="ArrowLeftIcon" />
    </b-link>
    <b-link
      class="btn btn-sm btn-primary mr-1"
      v-if="nav[1]"
      :to="{ params: { trainingId: nav[1].id } }"
      @click="refreshPage"
      variant="primary"
    >
      <feather-icon v-b-tooltip.hover :title="$t('Navigation')" size="24" icon="ArrowRightIcon" />
    </b-link>
    <b-link class="btn btn-sm btn-primary mr-1" :to="{ name: 'team-training-attenders' }" variant="primary">
      <feather-icon v-b-tooltip.hover :title="$t('Attenders')" size="24" icon="UsersIcon" />
    </b-link>
    <b-link class="btn btn-sm btn-primary mr-1" :to="{ name: 'team-training-edit' }" variant="primary">
      <feather-icon v-b-tooltip.hover :title="$t('Edit')" size="24" icon="EditIcon" />
    </b-link>
    <!-- <b-link class="btn btn-sm btn-primary mr-1" style="margin-left: 5px"> -->
    <!-- <feather-icon v-b-tooltip.hover :title="$t('Copy')" @click="showTrainingCopyModal(training)" size="21" icon="CopyIcon" /> -->
    <!-- </b-link> -->
    <b-link class="btn btn-sm btn-primary mr-1" :to="{ name: 'team-serp-score' }" variant="primary">
      <feather-icon v-b-tooltip.hover :title="$t('Test')" size="24" icon="ActivityIcon" />
    </b-link>
    <b-link class="btn btn-sm btn-primary mr-1" :to="{ name: 'team-training-preview' }" variant="primary">
      <feather-icon v-b-tooltip.hover :title="$t('Preview')" size="24" icon="EyeIcon" />
    </b-link>
    <b-link class="btn btn-sm btn-primary" :to="{ name: 'team-training-print' }" variant="primary">
      <feather-icon v-b-tooltip.hover :title="$t('Print')" size="24" icon="PrinterIcon" />
    </b-link>
  </div>
</template>
<script>
  export default {
    props: {},
    data() {
      return {
        nav: {},
      }
    },
    async beforeMount() {
      let { data } = await this.$http.get("/training/find-nav/" + this.routerParams.trainingId)
      this.nav = data
    },
    watch: {
      //   TODO : DOES NOT WORK
      // $router: async function (newValue) {
      //   let { data } = await this.$http.get("/training/find-nav/" + this.routerParams.trainingId);
      //   this.nav = data;
      // },
    },
    methods: {
      refreshPage: () => {
        setTimeout(() => {
          location.reload()
        }, 50)
      },
    },
  }
</script>
