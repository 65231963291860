<template>
  <b-link
    class="d-flex-custom-pname"
    :to="{
      name: 'team-player',
      params: {
        playerId: getPlayer.id,
        seasonId: currentSeasonId,
        id: currentTeamId,
      },
    }"
  >
    <b-avatar class="mr-1" :src="getPlayer.avatar" size="35" />
    <span :class="{ small: small }">{{ getPlayer.name }}</span>
    <br />
    <b-badge v-if="isNationalTeamLive" class="ml-2" v-b-tooltip.hover="getSwappedTextForNational" variant="info">
      <div class="d-flex align-items-center" style="gap: 5px">
        <span>
          <img :src="require(`@/assets/images/icons/flight.png`)" width="20" />
        </span>
      </div>
    </b-badge>
    <b-badge v-else-if="isNationalTeam" class="ml-2" v-b-tooltip.hover="getSwappedTextForNational" variant="info">
      <div class="d-flex align-items-center" style="gap: 5px">
        <country-flag v-if="getPlayer.nation" :country="getPlayer.nation" size="small" class="rounded" style="" />
        <b-img
          v-else
          :src="`/img/default-club-logo.png`"
          rounded="0"
          v-b-tooltip
          :title="$t('Please select nation for player')"
          width="20"
        />
      </div>
    </b-badge>
    <b-badge
      v-else-if="isSwapped"
      class="ml-2"
      v-b-tooltip.hover="getSwappedText"
      :variant="isSwapped.swappedFinish ? 'dark' : 'success'"
    >
      {{ $t("Swapped") }} {{ isSwapped.prevTeam.team && isSwapped.prevTeam.team.toString().substr(0, 4) }}
    </b-badge>
  </b-link>
</template>
<script>
  export default {
    props: {
      player: {
        type: Object,
        // eslint-disable-next-line vue/require-valid-default-prop
        default: {
          id: "",
          name: "Loading",
          avatar: "",
        },
      },
      small: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      getPlayer() {
        return this.player.player ? this.player.player : this.player
      },
      getSwappedText() {
        let prevTeam = this.isSwapped.prevTeam.team ? this.isSwapped.prevTeam.team : null
        return `${this.$t("format.swappedFromTeam", { msg: prevTeam })} - ${new Date(
          this.isSwapped.startDate,
        ).toLocaleDateString()}
        - ${new Date(this.isSwapped.endDate).toLocaleDateString()}`
      },
      getSwappedTextForNational() {
        return this.isNationalTeamLive
          ? this.$t("Currently playing for national team to") +
              " " +
              new Date(this.isSwappedNational.startDate).toLocaleDateString() +
              " - " +
              (this.isSwappedNational.endDate ? new Date(this.isSwappedNational.endDate).toLocaleDateString() : "")
          : this.$t("Played for national team between in") +
              " " +
              new Date(this.isSwappedNational.startDate).toLocaleDateString() +
              " - " +
              (this.isSwappedNational.endDate ? new Date(this.isSwappedNational.endDate).toLocaleDateString() : "")
      },
      currentTeamId() {
        return (
          Number(this.$router.currentRoute.params.teamId) ||
          Number(this.$router.currentRoute.params.id || this.player.teamId)
        )
      },
      currentSeasonId() {
        return (
          Number(this.$router.currentRoute.params.seasonId) ||
          Number(this.$router.currentRoute.params.sid || this.player.seasonId)
        )
      },
      isSwapped() {
        if (!this.getPlayer.playerTransferHistories) return false
        let isSwappedVal = Object.values(this.getPlayer?.playerTransferHistories)?.find(
          (it) =>
            it.isSwapped &&
            it.teamId === this.currentTeamId &&
            it.season.id === this.currentSeasonId &&
            !it.isNationalTeam,
        )
        return isSwappedVal
      },
      isSwappedNational() {
        if (!this.getPlayer.playerTransferHistories) return false
        let isSwappedVal = Object.values(this.getPlayer?.playerTransferHistories)?.find(
          (it) =>
            it.isSwapped &&
            it.teamId === this.currentTeamId &&
            it.season.id === this.currentSeasonId &&
            it.isNationalTeam,
        )
        return isSwappedVal
      },
      isNationalTeamLive() {
        return this.getPlayer?.playerTransferHistories?.find((d) => d.isNationalTeam && !d.swappedFinish)
      },
      isNationalTeam() {
        return this.getPlayer?.playerTransferHistories?.find((d) => d.isNationalTeam && d.swappedFinish)
      },
    },
  }
</script>

<style>
  .d-flex-custom-pname {
    display: flex !important;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    align-items: flex-start;
    justify-content: flex-start;
  }
</style>
